// If you need any extar configuration find the website given the file 
// Link: https://ant.design/docs/react/customize-theme // Here we can customize our ant design with theme and there is token.

export const CONSTANT = {
    BORDER_RADIUS: 6,
    PRIMARY: '#0B3D5B',
    PRIMARY_500: '#07678C',
    PRIMARY_HOVER: '#07668cDD',
    PRIMARY_DISABLED: '#07668c80',
    SECONDARY: '#8BDDFF',
    SECONDARY_HOVER: '#8BDDFFDD',
    SECONDARY_DISABLED: '#8BDDFF80',
    WARNING: "#e26100",
    WARNING_HOVER: "#e26100DD",
    WARNING_DISABLED: "#ff7d1c",
    WARNING_BORDER: "#ff7d1c",
    WARNING_BORDER_DISABLED: "#ff7d1c",
    WHITE: '#FFF',
    BLACK: '#000',
    BLUE:"#63D1FF",
    Z_INDEX_BASE: 100000,
    Z_INDEX_POPUP_BASE: 100000,
    
    BORDER_DEFAULT:"#528CB5",

    BUTTON_PRIMARY_BORDER:"#114A6B",
    BUTTON_DEFAULT_BG:"#07678C",
    BUTTON_DEFAULT_BORDER:"#0C79A4",
    BUTTON_SOLID_TEXT_COLOR: '#8BDDFF',
    BUTTON_DISABLED_TEXT_COLOR: '#8BDDFF',
    BUTTON_DISABLED_BG_COLOR: '#0B3D5B',
    BUTTON_DISABLED_BORDER_COLOR: '#0B3D5B',
    BUTTON_SECONDARY_BG:"#6c757d",
    BUTTON_SECONDARY_BORDER:"#7e8c93",
    BUTTON_SECONDARY_BG_HOVER:"#5e6670",
    BUTTON_SECONDARY_BORDER_HOVER:"#6e7a81"
}


export const ant = {
    token: {
        colorPrimary: CONSTANT.PRIMARY,
        colorInfo: CONSTANT.PRIMARY,
        colorWarning: CONSTANT.WARNING,
        borderRadius: CONSTANT.BORDER_RADIUS,
        zIndexBase: CONSTANT.Z_INDEX_BASE,
        zIndexPopupBase: CONSTANT.Z_INDEX_POPUP_BASE
    },
    components: {
        Input: {
            colorTextDisabled: CONSTANT.WHITE,
            activeBorderColor:CONSTANT.BLUE,
            borderColor:CONSTANT.BORDER_DEFAULT,
            hoverBorderColor:CONSTANT.BLUE,
            activeShadow:"0 0 0 2px rgba(5, 145, 255, 0.1)"
        },
        Select: {
            colorText: CONSTANT.BLUE,
            colorFillTertiary: CONSTANT.PRIMARY,
            lineWidth: 3,
            lineType: 'solid',
            borderColor:CONSTANT.BORDER_DEFAULT,
            colorTextSecondary: CONSTANT.BLACK,
            colorTextQuaternary: CONSTANT.SECONDARY,
            optionSelectedColor: CONSTANT.BLUE,
            colorTextPlaceholder: CONSTANT.BLACK,
            colorBgElevated: CONSTANT.PRIMARY,
            optionSelectedBg:CONSTANT.PRIMARY_500,
            optionSelectedColor:CONSTANT.BLUE,
            activeBorderColor:CONSTANT.BLUE,
            hoverBorderColor:CONSTANT.BLUE

        },
        Button: {
            lineWidth: 3,
            lineType: 'solid',
            borderColor:CONSTANT.BORDER_DEFAULT,
            borderColorDisabled: CONSTANT.BUTTON_DISABLED_BORDER_COLOR,
            colorTextDisabled: CONSTANT.BUTTON_DISABLED_TEXT_COLOR,
            colorBgContainerDisabled: CONSTANT.BUTTON_DISABLED_BG_COLOR,
            defaultBg: CONSTANT.BUTTON_DEFAULT_BG,
            primaryColor:"#8BDDFF",
            defaultColor: "#8BDDFF",
            solidTextColor:CONSTANT.BUTTON_SOLID_TEXT_COLOR,
            colorBgContainer: "rgb(7,103,140)",
            colorLink: CONSTANT.SECONDARY
        },
        Menu:{
            colorBgContainer:"#000"
        },
        Form: {
            labelColor: CONSTANT.SECONDARY
        },
        Checkbox: {
            colorTextDisabled: CONSTANT.SECONDARY_DISABLED
        },
        DatePicker:{
            colorPrimary:CONSTANT.PRIMARY_500,
            borderColor:CONSTANT.BORDER_DEFAULT,
            hoverBorderColor:CONSTANT.BLUE,
            activeBorderColor:CONSTANT.BLUE,
            activeShadow:"0 0 0 2px rgba(5, 145, 255, 0.1)"
            
        }
    }
}
