import { StyledButton } from "./styles";


export const BUTTON_VARIANT = {
  PRIMARY: "solid",
  DARK: "solid",
  TEXT: "text",
  DASHED: "dashed",
  TEXT_DARK: "text",
  TEXT_ACCENT: "text",
  ACCENT: "accent",
  SECONDARY: "secondary",
};


// Add
export const TYPE_VARIANT = {
  SOLID: "solid",
  OUTLINED: "outlined",
  DASHED: "dashed",
  FILLED: "filled",
  TEXT: "text",
  LINK: "link",

};


const Button = ({ children,
  className,
  onClick,
  htmlType = "button",
  variant = "solid", // outlined | dashed | solid | filled | text | link
  type = "primary", // primary | dashed | link | text | default
  color = "primary", // default | primary | danger
  disabled = false,
  ...props }) => {
  return (
    <StyledButton
      className={`${className} ${color}`}
      onClick={onClick}
      htmlType={htmlType}
      variant={variant}
      type={type}
      color={color}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
