import 'bootstrap/dist/css/bootstrap.min.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import SmoothScrolling from './common/elements/SmoothScrolling';
import GlobalContextProvider from './contexts/GlobalContextProvider';
import './index.css';
import router from './routes';
import store from './store.js';
import { CLIENT_ID, DOMAIN } from './util/common_variable';
import { ConfigProvider, theme } from 'antd';
import { ant as antCustomTheme } from "./util/ant_var";

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ConfigProvider theme={antCustomTheme}>
      <Provider store={store}>
        <SmoothScrolling>
          <GlobalContextProvider>
            <RouterProvider router={router} />
          </GlobalContextProvider>
        </SmoothScrolling>
      </Provider>
    </ConfigProvider>
  </StrictMode>
);
