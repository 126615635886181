// import { clientParams, refLists } from "./common_variable";
import { useClientContext } from "@/contexts/ClientparamsContext";
import { useRefContext } from "@/contexts/RefListContext";
import * as Yup from "yup";

// const clientParams = JSON.parse(localStorage.getItem('clientParam'));
// console.log("CP", clientParams);

// const refLists = JSON.parse(localStorage.getItem('RefList'));
// console.log("RF", refLists);


/**
 * Converts an array of date components into a formatted date string "DD/MM/YYYY".
 * @param {Array<number>} dateArray - The array containing [year, month (1-indexed), day, hours (optional), minutes (optional)]
 * @returns {string} - The formatted date string or an empty string if input is invalid.
 * Example:
 * IN: dateArray = [2024, 11, 8, 0, 0]
 * OUT: "08/11/2024"
 */
const dateArrayToString = (dateArray) => {
    // Check if dateArray is an array with at least [year, month, day]
    if (Array.isArray(dateArray) && dateArray.length >= 3) {
        // Create a Date object from the array
        const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3] || 0, dateArray[4] || 0);

        // Format the date as "DD/MM/YYYY"
        const day = String(date.getDate()).padStart(2, '0');  // Pad single-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Pad single-digit month
        const year = date.getFullYear();

        // Combine into desired format
        return `${day}/${month}/${year}`;
    }

    return '';  // Return an empty string if input is invalid
}




/**
 * Checks if the object is empty.
 * @param {Object} obj - The object to check.
 * @returns {boolean} - Returns true if the object is empty, false otherwise.
 */
const isEmptyObject = (obj) => {

    if (obj === null || typeof obj === 'undefined') return true;
    if (Object.keys(obj)?.length === 0) {
        return true;
    }
    return Object.keys(obj).every((o) => obj[o] === '')
}


/**
 * Converts an object to an array of key-value pairs with custom labels.
 * @param {Object} obj - The object to convert.
 * @param {String} keyLabel - The label for the key in the resulting objects.
 * @param {String} valueLabel - The label for the value in the resulting objects.
 * @returns {Array} - An array of objects with custom key-value labels.
 */
const objectToArray = (obj, keyLabel = "key", valueLabel = "value") => {
    if (Object.keys(obj).length === 0) {
        return [];
    }

    return Object.keys(obj)
        .filter(key => obj[key] !== '') // Filter out keys with empty values
        .map(key => ({
            [keyLabel]: key,
            [valueLabel]: obj[key]
        }));
};

/**
 * Converts an object to a query string, supporting nested objects.
 * 
 * @param {Object} obj - The object to convert to query parameters.
 * @param {String} [prefix] - Optional prefix for nested keys.
 * @returns {String} - The resulting query string.
 */
const toQueryString = (obj, prefix) => {
    const queryString = Object.keys(obj).map(key => {
        const value = obj[key];
        const prefixedKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === 'object' && value !== null) {
            return toQueryString(value, prefixedKey);
        } else {
            return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
        }
    });

    return queryString.join('&');
}

/**
 * Checks if a variable is non-empty.
 * 
 * A variable is considered non-empty if it is not undefined, null, or an empty string.
 * 
 * @param {*} value - The variable to check.
 * @returns {boolean} - Returns true if the variable is non-empty; otherwise, false.
 */
const isNonEmptyVariable = (value) => {
    return (typeof value !== 'undefined' && value !== null && value !== '')
}

/**
 * Creates a debounced version of a function that delays its execution 
 * until after a specified wait time has passed since it was last called.
 * 
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The delay in milliseconds to wait before invoking the function.
 * @returns {Function} - A debounced function that delays calling `func` until after `wait` milliseconds.
 */
const debounce = (func, wait) => {
    let timeout;

    return function (...args) {
        const context = this;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(context, args);
        }, wait);
    };
}

/**
 * Generates an array of numbers from 1 to the given number.
 *
 * @param {number|string} number - The number up to which the array should be generated. 
 *                                 Can be a number or a string representation of a number.
 * @returns {number[]} - An array containing numbers from 1 to the given number.
 */
const generateArray = (number) => {
    // Ensure the input is a number by parsing it into an integer.
    number = parseInt(number);

    // Create an array of the specified length (number), 
    // then use Array.from to fill it with numbers from 1 to `number`.
    return Array.from({ length: number }, (_, i) => i + 1);
}

/**
 * To diplay component if it has permission 
 *
 * @param {string} paramContext - This arguments is used to check with condition if it is there or not 
 * @returns {Boolean} - It will returns boolean value  
 */
const checkField = (paramContext) => {

    const { clientParam } = useClientContext();
    return clientParam?.find((cp) => cp?.paramContext === paramContext && cp?.paramName === 'Display')?.paramValue
}

/**
 * Generates a validation schema by using Yup validator
 *
 * @param {string} paramContext - This arguments is used to build validator 
 * @returns {Object} - An object will return with entire schema that contains all matched conditions
 */
const checkFieldValidation = (paramContext, paramType = 'text', label = '') => {
    const { clientParam } = useClientContext();
    return new Promise((resolve, reject) => {
        let schema = Yup; // Initial Schema Obj

        switch (paramType) {
            case 'text': schema = schema.string(); break;
            case 'number': schema = schema.number(); break;
            default: schema = schema.string(); break;
        }
        const clientParamValidation = clientParam?.filter(
            (cp) => cp?.paramContext === paramContext
        );
        let isValid = true;
        clientParamValidation?.forEach((cpv, index) => {
            if (cpv?.paramName === "Required" && cpv?.paramValue) {
                isValid = false;
                schema = schema.required(`${label || 'This field'} is required`); // Add required validation
            }

            if (cpv?.paramName === "RegularExpression") {
                if (cpv?.paramValue?.includes("||")) {
                    const [pattern, message] = cpv?.paramValue?.split("||") || ['', ''];
                    schema = schema.matches(new RegExp(pattern), message || "Invalid format"); // Add regex validation
                }
            }

            if (index === clientParamValidation?.length - 1) {
                if (isValid) {
                    schema = schema.optional();
                }
                // console.log(schema)
                resolve(schema);
            }
        })
    })

};

/**
 * Retrieves a list of options from the reference list based on the provided key.
 * - Includes a default "Select" option at the beginning of the list.
 * - Sorts the retrieved items in descending order by `Sequence_Number`.
 * - Handles cases where the reference list or its items are undefined gracefully.
 *
 * @param {string} key - The name of the reference list to search for.
 * @returns {Array<Object>} - An array of objects representing the options, 
 *                            with a default "Select" option included at the start.
 *
 */
const getOptionsFromRefList = (key) => {

    const { refList: refLists } = useRefContext();
    console.log(refLists);

    const refList = refLists?.find((rl) => rl?.refListName === key);

    // Default item to add to the list
    const defaultItem = {
        value: -1,
        enabled: true,
        Disabled: true,
        label: 'Select'
    };

    // Extract and sort items, adding the default item at the start
    let sortedItems = refList?.refListItems?.filter((rli) => rli?.enabled) || []
    // ?.sort((a, b) => b?.sequenceNumber - a?.sequenceNumber) || [];
    sortedItems = sortedItems?.map((aa) => {
        return {
            value: aa?.refListItemId,
            enabled: aa?.enabled,
            label: aa?.longString || aa?.defaultString
        }
    })

    return [defaultItem, ...sortedItems];
};

/**
 * Maps SQL data types to their corresponding HTML input types for form rendering.
 *
 * - Supports a variety of SQL data types, including character, numeric, date/time, and boolean types.
 * - Decides the most appropriate HTML input type based on SQL type characteristics.
 * - Provides a fallback to "text" for unsupported or unknown SQL types.
 *
 * @param {string} sqlType - The SQL data type to be mapped (e.g., "varchar(255)", "int", "datetime").
 * @returns {string} - The corresponding HTML input type (e.g., "text", "number", "date", "textarea").
 *
 * Example:
 * Input: sqlType = "varchar(255)"
 * Output: "text"
 *
 * Input: sqlType = "datetime"
 * Output: "datetime-local"
 *
 * Notes:
 * - Character types ("char", "varchar") are mapped to "text" or "textarea" based on their size.
 * - Integer and floating-point types are mapped to "number".
 * - Boolean types ("bit") are mapped to "select" (can be customized for "checkbox").
 * - Date/time types are mapped to respective HTML5 input types ("date", "datetime-local").
 */
const getSQLTypeToHTMLType = (sqlType) => {
    let type = ''; // Default to empty

    // Handle character types
    if (sqlType?.toLowerCase()?.includes('char')) {
        // Extract size within parentheses, e.g., varchar(255)
        const sizeMatch = sqlType?.match(/\((\d+)\)/); // Regular expression to extract size
        const size = sizeMatch ? parseInt(sizeMatch[1]) : null;

        // Decide between text or textarea
        type = size && size > 255 ? 'textarea' : 'text';
    }
    // Handle integer types
    else if (sqlType?.toLowerCase()?.includes('int')) {
        type = 'number';
    }
    // Handle boolean types
    else if (sqlType?.toLowerCase()?.includes('bit')) {
        type = 'select'; // Changed to checkbox for boolean values
    }
    // Handle datetime types
    else if (sqlType?.toLowerCase()?.includes('datetime')) {
        type = 'datetime-local'; // HTML5 datetime input
    }
    // Handle date types
    else if (sqlType?.toLowerCase()?.includes('date')) {
        type = 'date';
    }

    // Handle floating point or decimal numbers
    else if (
        sqlType?.toLowerCase()?.includes('float') ||
        sqlType?.toLowerCase()?.includes('decimal')
    ) {
        type = 'number'; // Number type can handle decimals
    }
    // Handle large text fields
    else if (sqlType?.toLowerCase()?.includes('text')) {
        type = 'textarea';
    }
    // Fallback to text if no match
    else {
        type = 'text';
    }

    return type;
};

/**
 * Handles setting the "touched" state for all fields in a form.
 * - If there are validation errors, all fields with errors are marked as touched.
 * - Otherwise, all fields in the form are marked as touched.
 *
 * @param {Object} ref - A reference to the form object, typically passed from a form library like Formik.
 * 
 * Example Usage:
 * handleFormForSetTouched(formRef);
 */
const handleFormForSetTouched = (ref) => {
    if (!ref || !ref.current) return; // Ensure the ref object is valid.

    const { errors, values, setTouched } = ref.current;

    // Determine which fields to mark as touched
    const fieldsToMark = !isEmptyObject(errors) ? errors : values;

    // Set all relevant fields as touched (true by default, can be customized)
    setTouched(
        Object.keys(fieldsToMark).reduce((acc, key) => {
            acc[key] = true; // Mark the field as touched (or false if needed)
            return acc;
        }, {})
    );
};


/**
 * Converts an array of fields into an object by dynamically validating each field.
 * 
 * - Uses `checkFieldValidation` to validate each field asynchronously.
 * - Aggregates the results into a single object where keys are column names and values are the validation rules or configurations.
 * - Handles errors gracefully using `Promise.allSettled`.
 * 
 * @param {Array} fieldArray - An array of field objects, each containing `columnName`, `tableName`, `type`, and `label`.
 * @returns {Promise<Object>} - A Promise that resolves to an object with column names as keys and validation results as values.
 * 
 * Example Input:
 * [
 *   { columnName: "username", tableName: "users", type: "string", label: "Username" },
 *   { columnName: "email", tableName: "users", type: "email", label: "Email" }
 * ]
 * 
 * Example Output:
 * {
 *   username: "validation rule for username",
 *   email: "validation rule for email"
 * }
 */
const arrayToObject = async (fieldArray) => {
    try {
        // Validate each field asynchronously and collect results
        const validationResults = await Promise.allSettled(
            fieldArray?.map(async (field) => {
                const validationRule = await checkFieldValidation(
                    `${field?.tableName}:${field?.columnName}`,
                    field?.type,
                    field?.label
                );
                return { [field?.columnName]: validationRule };
            })
        );

        // Aggregate results into a single object
        const resultObject = validationResults.reduce((acc, result) => {
            if (result.status === "fulfilled" && result.value) {
                return { ...acc, ...result.value };
            }
            // Optional: Handle rejected promises
            return acc;
        }, {});

        return resultObject;
    } catch (error) {
        throw new Error(`Error processing field validations: ${error.message}`);
    }
};



/**
 * To diplay component if it has permission 
 *
 * @param {string} paramContext - This arguments is used to check with condition if it is there or not 
 * @returns {Boolean} - It will returns boolean value  
 */
const checkRequiredField = (paramContext) => {
    const { clientParam } = useClientContext();
    return clientParam?.find((cp) => cp?.paramContext === paramContext && cp?.paramName === 'Required')?.paramValue
}


export {
    dateArrayToString,
    isEmptyObject,
    objectToArray,
    toQueryString,
    isNonEmptyVariable,
    debounce,
    generateArray,
    checkField,
    checkFieldValidation,
    getSQLTypeToHTMLType,
    getOptionsFromRefList,
    handleFormForSetTouched,
    arrayToObject,
    checkRequiredField
}