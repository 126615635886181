import add from "@/assets/add.svg";
import edit from "@/assets/edit.svg";
import trashSolid from "@/assets/trash-solid.svg";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";
import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import Table from "@/common/elements/Table";
import { Flex } from "@/common/styles";
import { useState } from "react";
import DocsModal, { MODE } from "./DocsModal";
import { InmateDetailsTableContainer, TableHeader } from "./styles";

const DocsTableHeaders = [
  { key: "documentName", text: "Document Name" },
  { key: "comments", text: "Comments" },
  { key: "actions", text: "Actions" },
];

const Docs = ({ formData, onUpdateForm }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [documentModalMode, setDocumentModalMode] = useState(MODE.NEW);
  const [documentEditData, setDocumentEditData] = useState({});
  const [editIndex, setEditIndex] = useState(-1);

  const onModalHide = () => {
    setIsModalShown(false);
    setDocumentModalMode(MODE.NEW);
    setDocumentEditData({});
    setEditIndex(-1);
  };

  const onAddClick = () => {
    setIsModalShown(true);
  };

  const onDocumentDeleteClick = () => { };

  const onDocumentEditClick = (document, index) => {
    setDocumentModalMode(MODE.EDIT);
    setDocumentEditData(document);
    setEditIndex(index);
    setIsModalShown(true);
  };

  return (
    <>
      {JSON.stringify({ formData })}
      <InmateDetailsTableContainer>
        <TableHeader>
          <h6>List of items taken</h6>
          <Button variant="dark" className="add-btn" onClick={onAddClick}>
            <img alt="Add Icon" src={add} /> &nbsp; Add
          </Button>
        </TableHeader>
        <Table
          headers={DocsTableHeaders}
          data={
            formData?.docs?.map((document, index) => ({
              ...document,
              actions: (
                <Flex gap="10px">
                  <Button
                    variant={BUTTON_VARIANT.TEXT_DARK}
                    style={{ padding: "unset" }}
                    onClick={() => onDocumentEditClick(document, index)}
                  >
                    <img alt="Edit Icon" src={edit} />
                  </Button>
                  <Button
                    variant={BUTTON_VARIANT.TEXT_DARK}
                    style={{ padding: "unset" }}
                    onClick={onDocumentDeleteClick}
                  >
                    <img alt="Edit Icon" src={trashSolid} />
                  </Button>
                </Flex>
              ),
            })) || []
          }
          variant={INPUT_VARIANT.INTAKE_FORM}
          style={{ padding: "unset" }}
        />
      </InmateDetailsTableContainer>

      <DocsModal
        show={isModalShown}
        mode={documentModalMode}
        document={documentEditData}
        documentIndex={editIndex}
        onHide={onModalHide}
        onChange={(value, options) => onUpdateForm({ target: { name: "docs", value } }, { ...options, isMulti: true })}
      />
    </>
  );
};

export default Docs;
