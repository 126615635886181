import { Dropdown } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { CascaderMenuToggler, StyledCascaderMenu, StyledCascaderLabel } from './styles';
import arrowDownBlueSm from '@/assets/arrow-down-blue-sm.svg';

const CascaderMenu = ({
  items,
  placeholder = "Select an item",
  label = "",
  trigger = "click", // 'click' | 'hover' | 'contextMenu'
  arrow = false,
  disabled = false,
  iconOnlyMenu = false,
  open = null,
  onOpenChange,
  onSelect,
  menuClassname = "",
  togglerClass = "",
  placement = "bottomLeft",
  showSelectedLabel = true,
  togglerIcon = <img className="arrow-img" src={arrowDownBlueSm} />,
  defaultValue = null, // Accept a default key
}) => {
  const [selectedLabel, setSelectedLabel] = useState(placeholder);

  // Recursive function to find an item by key
  const findItemByKey = useCallback((items, key) => {
    for (let item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findItemByKey(item.children, key);
        if (found) return found;
      }
    }
    return null;
  }, []);

  // Initialize with default value
  useEffect(() => {
    if (defaultValue) {
      const defaultItem = findItemByKey(items, defaultValue);
      if (defaultItem) {
        setSelectedLabel(defaultItem.label);
      }
    }
  }, [defaultValue, items, findItemByKey]);

  // Handle selection change
  const handleItemClick = useCallback(
    (e) => {
      const selectedItem = findItemByKey(items, e.key);
      if (selectedItem) {
        setSelectedLabel(selectedItem.label);
      }
      if (onSelect) onSelect(e);
    },
    [items, onSelect, findItemByKey]
  );

  return (
    <StyledCascaderMenu>
      {label && <StyledCascaderLabel>{label}</StyledCascaderLabel>}

      <Dropdown
        menu={{
          rootClassName: `cascader-dropdown-menu ${menuClassname}`,
          items,
          theme: "dark",
          onClick: handleItemClick,
        }}
        destroyPopupOnHide
        open={open}
        trigger={[trigger]}
        disabled={disabled}
        onOpenChange={onOpenChange}
        placement={placement}
      >
        <CascaderMenuToggler
          onClick={(e) => e.preventDefault()}
          className={`${togglerClass}`}
        >
          {!iconOnlyMenu && (
            <span
              className="selection-text"
              title={showSelectedLabel ? selectedLabel : placeholder}>
              {showSelectedLabel ? selectedLabel : placeholder}
            </span>
          )}

          {togglerIcon && <i>{togglerIcon}</i>}
        </CascaderMenuToggler>
      </Dropdown>
    </StyledCascaderMenu>
  );
};

export default CascaderMenu;
