import { Table as RBTable } from "react-bootstrap";
import styled from "styled-components";

export const StyledTable = styled(RBTable)`
  &.intake-form {
    --bs-table-border-color: transparent;
  }
  &._ {
    --bs-table-striped-bg: #ddf4ff;
    --bs-table-bg: #fefefe;
    --bs-table-border-color: #a3e5ff;
    margin-bottom: unset;

    thead tr th {
      background: #76c4e8;
      box-shadow: inset 0 1px 0 0 #91dcff, inset 1px 0 0 0 rgba(7, 103, 140, 0.16);
      font-weight: 500;
      font-size: 14px;
      color: #1f4971;
      letter-spacing: 0;
      text-transform: uppercase;
    }

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          td {
            --bs-table-bg-type: #2c78a980;
            --bs-table-bg-state: #2c78a980;
          }
        }

        td {
          transition: all 0.3s ease-in-out;
        }

        &.selected {
          td {
            --bs-table-bg-type: #2c78a9;
            background-color: #2c78a9 !important;
            color: white;
            border-color: #5292ab;
          }
        }
      }
    }
  }
`;

export const Pagination = styled.div`
 margin: 1rem 0;
 display: flex;
 justify-content: space-between;
`;

export const PaginationControls = styled.div`
    display: flex;
    align-items: baseline;
    .btn.disabled, .btn:disabled, fieldset:disabled .btn{
    background-color: #07678c;
    }
`
export const PaginationInfo = styled.div``;

export const PaginationRowLimit = styled.div`
    display: flex;
`;

export const PaginationButtonGroup = styled.div`
    display: flex
`

export const InputPageChange = styled.div`
    margin-left: .5rem
`

export const NoDataFound = styled.tr`
  cursor: auto;
  text-align: center;
`
