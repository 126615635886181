import { createContext, useMemo, useState } from "react";

export const ConfigControlContext = createContext(null); // Use `null` for clarity when no value is provided.

const ConfigControlProvider = ({ children }) => {
  const [undoAction, setUndoAction] = useState(() => () => {
    alert("Undo Action Triggered");
  });
  const [redoAction, setRedoAction] = useState(() => () => {
    alert("Redo Action Triggered");
  });
  const [saveAction, setSaveAction] = useState(() => () => {
    alert("Save Action Triggered");
  });
  const [cancelAction, setCancelAction] = useState(() => () => {
    alert("Cancel Action Triggered");
  });

 
  const actions = useMemo(
    () => ({ undoAction, redoAction, saveAction, cancelAction }),
    [undoAction, redoAction, saveAction, cancelAction]
  );

  const setActions = useMemo(
    () => ({
      undoAction: setUndoAction,
      redoAction: setRedoAction,
      saveAction: setSaveAction,
      cancelAction: setCancelAction,
    }),
    [] 
  );

  return (
    <ConfigControlContext.Provider value={[actions, setActions]}>
      {children}
    </ConfigControlContext.Provider>
  );
};

export default ConfigControlProvider;
