import { PERSON } from "@/components/InmateDetails/Personal";
import { labels } from "./label";

export const DOMAIN = "dev-e578z005mx6gvs5k.us.auth0.com";
export const CLIENT_ID = "1E6cqNqtSDPL5QvAwk1TXgVIBPbT2dbR";

export const RACE_OPTIONS = [
    {
        label: `Select  ${labels.race}`,
        value: "",
        disabled: true,
    },
    {
        label: "White",
        value: "W",
    },
    {
        label: "Black",
        value: "B",
    },
    {
        label: "Others",
        value: "U",
    },
];

export const GENDER_OPTIONS = [
    { value: "", label: `Select  ${labels.gender}`, disabled: true },
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "U", label: "Other" },
];

export const SUFFIX_OPTIONS = [
    { value: "", label: `Select  ${labels.suffix}`, disabled: true },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "JR", label: "JR" },
    { value: "SR", label: "SR" },
];

export const STATES = [
    {
        value: "",
        label: `Select  ${labels.state}`,
        disabled: true,
    },
    {
        value: "AK",
        label: "Alaska",
    },
    {
        value: "AL",
        label: "Alabama",
    },
    {
        value: "AR",
        label: "Arkansas",
    },
    {
        value: "AZ",
        label: "Arizona",
    },
    {
        value: "CA",
        label: "California",
    },
    {
        value: "CO",
        label: "Colorado",
    },
    {
        value: "CT",
        label: "Connecticut",
    },
    {
        value: "DC",
        label: "District of Columbia",
    },
    {
        value: "DE",
        label: "Delaware",
    },
    {
        value: "FL",
        label: "Florida",
    },
    {
        value: "GA",
        label: "Georgia",
    },
    {
        value: "HI",
        label: "Hawaii",
    },
    {
        value: "IA",
        label: "Iowa",
    },
    {
        value: "ID",
        label: "Idaho",
    },
    {
        value: "IL",
        label: "Illinois",
    },
    {
        value: "IN",
        label: "Indiana",
    },
    {
        value: "KS",
        label: "Kansas",
    },
    {
        value: "KY",
        label: "Kentucky",
    },
    {
        value: "LA",
        label: "Louisiana",
    },
    {
        value: "MA",
        label: "Massachusetts",
    },
    {
        value: "MD",
        label: "Maryland",
    },
    {
        value: "ME",
        label: "Maine",
    },
    {
        value: "MI",
        label: "Michigan",
    },
    {
        value: "MN",
        label: "Minnesota",
    },
    {
        value: "MO",
        label: "Missouri",
    },
    {
        value: "MS",
        label: "Mississippi",
    },
    {
        value: "MT",
        label: "Montana",
    },
    {
        value: "NC",
        label: "North Carolina",
    },
    {
        value: "ND",
        label: "North Dakota",
    },
    {
        value: "NE",
        label: "Nebraska",
    },
    {
        value: "NH",
        label: "New Hampshire",
    },
    {
        value: "NJ",
        label: "New Jersey",
    },
    {
        value: "NM",
        label: "New Mexico",
    },
    {
        value: "NV",
        label: "Nevada",
    },
    {
        value: "NY",
        label: "New York",
    },
    {
        value: "OH",
        label: "Ohio",
    },
    {
        value: "OK",
        label: "Oklahoma",
    },
    {
        value: "OR",
        label: "Oregon",
    },
    {
        value: "PA",
        label: "Pennsylvania",
    },
    {
        value: "PR",
        label: "Puerto Rico",
    },
    {
        value: "RI",
        label: "Rhode Island",
    },
    {
        value: "SC",
        label: "South Carolina",
    },
    {
        value: "SD",
        label: "South Dakota",
    },
    {
        value: "TN",
        label: "Tennessee",
    },
    {
        value: "TX",
        label: "Texas",
    },
    {
        value: "UT",
        label: "Utah",
    },
    {
        value: "VA",
        label: "Virginia",
    },
    {
        value: "VT",
        label: "Vermont",
    },
    {
        value: "WA",
        label: "Washington",
    },
    {
        value: "WI",
        label: "Wisconsin",
    },
    {
        value: "WV",
        label: "West Virginia",
    },
    {
        value: "WY",
        label: "Wyoming",
    },
    {
        value: "VI",
        label: "Virgin Islands",
    },
];

export const MOVE_OPTIONS = [
    { value: "", label: "Select MoveTo", disabled: true },
    { value: "In Jail", label: "In Jail" },
    { value: "Loan", label: "Loan" },
    { value: "Perm Housing Change", label: "Perm Housing Change" },
    { value: "Transport", label: "Transport" },
];

export const JAIL_OPTIONS = [
    { value: "", label: "Select Type", disabled: true },
    { value: "Cell", label: "Cell" },
    { value: "Holding", label: "Holding" },
];

export const STATUS_OPTIONS = [
    { value: "", label: "Select Status", disabled: true },
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
];

export const REASON_OPTIONS = [
    { value: "", label: "Select Reason", disabled: true },
    { value: "Medical", label: "Medical" },
    { value: "Administrative", label: "Administrative" },
];

export const LOAN_OPTIONS = [
    { value: "", label: "Select Loan", disabled: true },
    { value: "Activity Request", label: "Activity Request" },
    { value: "Booking", label: "Booking" },
    { value: "Court", label: "Court" },
];

export const DESTINATIONS = [
    { value: "", label: "Select Destination", disabled: true },
    { value: "test", label: "test" },
];

export const clientParams = [
    {
        paramName: 'Display',
        paramContext: 'Bio:LName',
        paramValue: true
    }, {
        paramName: 'RegularExpression',
        paramContext: 'Bio:LName',
        paramValue: "^[A-Za-z0-9 ,'-]+$||This fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only."
    }, {
        paramName: 'Required',
        paramContext: 'Bio:LName',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'Bio:FName',
        paramValue: true
    }, {
        paramName: 'RegularExpression',
        paramContext: 'Bio:FName',
        paramValue: "^[A-Za-z0-9 ,'-]+$||This fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only."
    }, {
        paramName: 'Required',
        paramContext: 'Bio:FName',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'Bio:MName',
        paramValue: true
    }, {
        paramName: 'RegularExpression',
        paramContext: 'Bio:MName',
        paramValue: "^[A-Za-z0-9 ,'-]+$||This fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only."
    },
    {
        paramName: 'Display',
        paramContext: 'Bio:Suffix',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'Bio:EnrolleeID',
        paramValue: true
    }, {
        paramName: 'RegularExpression',
        paramContext: 'Bio:EnrolleeID',
        paramValue: "^[0-9]+$||This field allows numbers only."
    }, {
        paramName: 'Required',
        paramContext: 'Bio:EnrolleeID',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'Bio:Race',
        paramValue: true
    }, {
        paramName: 'Required',
        paramContext: 'Bio:Race',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'Bio:Sex',
        paramValue: true
    }, {
        paramName: 'Required',
        paramContext: 'Bio:Sex',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'Bio:Height',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'Bio:DOB',
        paramValue: true
    }, {
        paramName: 'Required',
        paramContext: 'Bio:DOB',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'ArrestReports:Agency',
        paramValue: true
    }, {
        paramName: 'Required',
        paramContext: 'ArrestReports:Agency',
        paramValue: true
    }, {
        paramName: 'Display',
        paramContext: 'ArrestReports:Officer',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'ArrestReports:BookingOfficer',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'ArrestReports:incarcerationDateTime',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'ArrestReports:Inmate_Type_ID',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'ArrestReports:Controlling_Agency_Case_Number',
        paramValue: true
    },
    {
        paramName: 'Display',
        paramContext: 'ArrestReports:Controlling_Agency_ORI_ID',
        paramValue: true
    },
];

export const extraFields = {
    PersonalInfo: [
        {
            columnName: "Aadhar_U",
            type: "varchar(10)", // Maybe it comes number, bit and etc,
            tableName: "BioExtra",
        },
        // More colum returns if exist
    ],
    arrestInfo: [
        {
            columnName: "fbi_U",
            type: "varchar(10)", // Maybe it comes number, bit and etc,
            tableName: "ArrestExtra",
        },
        // More colum returns if exist
    ],
    BookingInfo: [
        {
            columnName: "pan_U",
            type: "varchar(10)", // Maybe it comes number, bit and etc,
            tableName: "BookingExtra",
        },
        // More colum returns if exist
    ],
};

export const staticFields = {
    personalInfo: {
        [PERSON.INMATE]: [
            {
                columnName: "LName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "Last Name",
            },
            {
                columnName: "FName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "First Name",
            },
            {
                columnName: "MName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "Middle Name",
            },
            {
                columnName: "Suffix",
                type: "bit",
                tableName: "Bio",
                label: "Suffix",
            },
            {
                columnName: "EnrolleeID",
                type: "number",
                tableName: "Bio",
                label: "Iris ID",
            },
            {
                columnName: "Race",
                type: "bit",
                tableName: "Bio",
                label: "Race",
            },
            {
                columnName: "Sex",
                type: "bit",
                tableName: "Bio",
                label: "Gender",
            },
            {
                columnName: "Height",
                type: "number",
                tableName: "Bio",
                label: "Height",
            },
            {
                columnName: "DOB",
                type: "datetime",
                tableName: "Bio",
                label: "DOB",
            },
        ],
        [PERSON.VISITOR]: [
            {
                columnName: "LName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "Last Name",
            },
            {
                columnName: "FName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "First Name",
            },
            {
                columnName: "MName",
                type: "varchar(10)",
                tableName: "Bio",
                label: "Middle Name",
            },
            {
                columnName: "Suffix",
                type: "varchar(10)",
                tableName: "Bio",
                label: "Suffix",
            },
            {
                columnName: "Sex",
                type: "bit",
                tableName: "Bio",
                label: "Gender",
            },
            {
                columnName: "DOB",
                type: "datetime",
                tableName: "Bio",
                label: "DOB",
            },
        ],
    },
    arrest_book_info: [
        {
            refListName: "Controlling_Agency",
            columnName: "Agency",
            type: "bit",
            tableName: "ArrestReports",
            label: "Arresting ORI",
        },
        {
            refListName: "",
            columnName: "Officer",
            type: "varchar(20)",
            tableName: "ArrestReports",
            label: "Arresting Officer",
        },
        {
            refListName: "",
            columnName: "BookingOfficer",
            type: "bit",
            tableName: "ArrestReports",
            label: "Booking Officer",
        },
        {
            refListName: "",
            columnName: "incarcerationDateTime",
            type: "datetime",
            tableName: "ArrestReports",
            label: "Incarceration Date & Time",
        },
        {
            refListName: "Inmate_Type",
            columnName: "Inmate_Type_ID",
            type: "bit",
            tableName: "ArrestReports",
            label: "Inmate Type",
        },
        {
            refListName: "",
            columnName: "Controlling_Agency_Case_Number",
            type: "varchar(20)",
            tableName: "ArrestReports",
            label: "Controlling Agency Number",
        },
        {
            refListName: "Controlling_Agency",
            columnName: "Controlling_Agency_ORI_ID",
            type: "bit",
            tableName: "ArrestReports",
            label: "Controlling Agency",
        },
    ],
};

export const refLists = [
    {
        "RefListID": 1,
        "refListName": "Accident_Event",
        "refListItems": [
            {
                "refListItemId": 1,
                "enabled": true,
                "defaultString": "Animal {45}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2,
                "enabled": true,
                "defaultString": "Animal With Rider {87}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3,
                "enabled": true,
                "defaultString": "Barricade {63}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4,
                "enabled": true,
                "defaultString": "Boulder {73}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5,
                "enabled": true,
                "defaultString": "Breakaway Light {81}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 6,
                "enabled": true,
                "defaultString": "Breakaway Sign {77}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7,
                "enabled": true,
                "defaultString": "Bridge Abutment {84}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 8,
                "enabled": true,
                "defaultString": "Bridge Rail {64}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 9,
                "enabled": true,
                "defaultString": "Building {71}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 10,
                "enabled": true,
                "defaultString": "Crash Cushion {52}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 11,
                "enabled": true,
                "defaultString": "Culvert Headwall {65}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 12,
                "enabled": true,
                "defaultString": "Curbing {66}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 13,
                "enabled": true,
                "defaultString": "Ditch {74}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 14,
                "enabled": true,
                "defaultString": "Fence {72}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 15,
                "enabled": true,
                "defaultString": "Fire / Explosion {02}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 16,
                "enabled": true,
                "defaultString": "Fire Hydrant {56}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 17,
                "enabled": true,
                "defaultString": "Foreign Material in Road {90}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 18,
                "enabled": true,
                "defaultString": "Gas Inhalation {04}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 19,
                "enabled": true,
                "defaultString": "Gas Line {62}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 20,
                "enabled": true,
                "defaultString": "Guardrail {51}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 21,
                "enabled": true,
                "defaultString": "Guy Wire {80}",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 22,
                "enabled": true,
                "defaultString": "Immersion {03}",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 23,
                "enabled": true,
                "defaultString": "Jackknifed {07}",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 24,
                "enabled": true,
                "defaultString": "Mailbox(es) {61}",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 25,
                "enabled": true,
                "defaultString": "Manhole {78}",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 26,
                "enabled": true,
                "defaultString": "Median Barrier {68}",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 27,
                "enabled": true,
                "defaultString": "Non Breakaway Sign {59}",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 28,
                "enabled": true,
                "defaultString": "Non-Breakaway Light {54}",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 29,
                "enabled": true,
                "defaultString": "None {97}",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 30,
                "enabled": true,
                "defaultString": "Non-Parked Vehicle {20}",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 31,
                "enabled": true,
                "defaultString": "Other {12}",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 32,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 33,
                "enabled": true,
                "defaultString": "Other Fixed Object {76}",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 34,
                "enabled": true,
                "defaultString": "Overhead Object {82}",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 35,
                "enabled": true,
                "defaultString": "Overpass / Underpass {75}",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 36,
                "enabled": true,
                "defaultString": "Overturned {01}",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 37,
                "enabled": true,
                "defaultString": "Parked Vehicle {30}",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 38,
                "enabled": true,
                "defaultString": "Parts / Cargo Fell From Moving Vehicle {08}",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 39,
                "enabled": true,
                "defaultString": "Pedal Cyclist {40}",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 40,
                "enabled": true,
                "defaultString": "Pedestrian(s) {15}",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 41,
                "enabled": true,
                "defaultString": "Pier or Column {57}",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 42,
                "enabled": true,
                "defaultString": "Pothole {93}",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 43,
                "enabled": true,
                "defaultString": "Retaining Wall {67}",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 44,
                "enabled": true,
                "defaultString": "Road / Bridge Collapsed {06}",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 45,
                "enabled": true,
                "defaultString": "Sideslope {69}",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 46,
                "enabled": true,
                "defaultString": "Spill {05}",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 47,
                "enabled": true,
                "defaultString": "Telephone Booth {79}",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 48,
                "enabled": true,
                "defaultString": "Trailer Hitch Came Loose {09}",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 49,
                "enabled": true,
                "defaultString": "Tree {55}",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 50,
                "enabled": true,
                "defaultString": "Utility Pole {53}",
                "longString": "",
                "sequenceNumber": 50
            }
        ]
    },
    {
        "RefListID": 2,
        "refListName": "Accident_Lighting",
        "refListItems": [
            {
                "refListItemId": 51,
                "enabled": true,
                "defaultString": "Darkess - Road Lit {5}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 52,
                "enabled": true,
                "defaultString": "Darkess - Road Not Lit {4}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 53,
                "enabled": true,
                "defaultString": "Dawn {2}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 54,
                "enabled": true,
                "defaultString": "Daylight {1}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 55,
                "enabled": true,
                "defaultString": "Dusk {3}",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 3,
        "refListName": "Accident_Locale",
        "refListItems": [
            {
                "refListItemId": 56,
                "enabled": true,
                "defaultString": "Mfg or Industrial {4}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 57,
                "enabled": true,
                "defaultString": "Open Country {1}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 58,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 59,
                "enabled": true,
                "defaultString": "Playground {6}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 60,
                "enabled": true,
                "defaultString": "Residential {2}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 61,
                "enabled": true,
                "defaultString": "School {5}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 62,
                "enabled": true,
                "defaultString": "Shop or Business {3}",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 4,
        "refListName": "Accident_Person_Type",
        "refListItems": [
            {
                "refListItemId": 63,
                "enabled": true,
                "defaultString": "Driver",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 64,
                "enabled": true,
                "defaultString": "Passenger",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 65,
                "enabled": true,
                "defaultString": "Pedestrian",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 66,
                "enabled": true,
                "defaultString": "Witness",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 5,
        "refListName": "Accident_Property_Damage",
        "refListItems": [
            {
                "refListItemId": 67,
                "enabled": true,
                "defaultString": "Light {2}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 68,
                "enabled": true,
                "defaultString": "Moderate {3}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 69,
                "enabled": true,
                "defaultString": "Non-visible {1}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 70,
                "enabled": true,
                "defaultString": "Severe {4}",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 6,
        "refListName": "Accident_Weather",
        "refListItems": [
            {
                "refListItemId": 71,
                "enabled": true,
                "defaultString": "Clear {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 72,
                "enabled": true,
                "defaultString": "Cloudy {2}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 73,
                "enabled": true,
                "defaultString": "Crosswind {6}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 74,
                "enabled": true,
                "defaultString": "Fog {7}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 75,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 76,
                "enabled": true,
                "defaultString": "Rain {3}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 77,
                "enabled": true,
                "defaultString": "Sleet / Hail {5}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 78,
                "enabled": true,
                "defaultString": "Snow {4}",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 7,
        "refListName": "AccidentDUITestType",
        "refListItems": [
            {
                "refListItemId": 79,
                "enabled": true,
                "defaultString": "Blood Test {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 80,
                "enabled": true,
                "defaultString": "Breath Test {2}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 81,
                "enabled": true,
                "defaultString": "No Test {9}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 82,
                "enabled": true,
                "defaultString": "Refused Test {5}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 83,
                "enabled": true,
                "defaultString": "Unable to Admin {4}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 84,
                "enabled": true,
                "defaultString": "Urine Test {3}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 8,
        "refListName": "AccidentInjuryType",
        "refListItems": [
            {
                "refListItemId": 85,
                "enabled": true,
                "defaultString": "Bruise/Abrasion/Swelling {B}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 86,
                "enabled": true,
                "defaultString": "Killed {K}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 87,
                "enabled": true,
                "defaultString": "Not visible/Pain, Faint {C}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 88,
                "enabled": true,
                "defaultString": "Visible/Carried from scene {A}",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 9,
        "refListName": "AccidentReportCounty",
        "refListItems": [
            {
                "refListItemId": 89,
                "enabled": true,
                "defaultString": "Autauga {04}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 90,
                "enabled": true,
                "defaultString": "Baldwin {05}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 91,
                "enabled": true,
                "defaultString": "Barbour {06}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 92,
                "enabled": true,
                "defaultString": "Bibb {07}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 93,
                "enabled": true,
                "defaultString": "Blount {08}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 94,
                "enabled": true,
                "defaultString": "Bullock {09}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 95,
                "enabled": true,
                "defaultString": "Butler {10}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 96,
                "enabled": true,
                "defaultString": "Calhoun {11}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 97,
                "enabled": true,
                "defaultString": "Chambers {12}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 98,
                "enabled": true,
                "defaultString": "Cherokee {13}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 99,
                "enabled": true,
                "defaultString": "Chilton {14}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 100,
                "enabled": true,
                "defaultString": "Choctaw {15}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 101,
                "enabled": true,
                "defaultString": "Clark {16}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 102,
                "enabled": true,
                "defaultString": "Clay {17}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 103,
                "enabled": true,
                "defaultString": "Cleburne {18}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 104,
                "enabled": true,
                "defaultString": "Coffee {19}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 105,
                "enabled": true,
                "defaultString": "Colbert {20}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 106,
                "enabled": true,
                "defaultString": "Conecuh {21}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 107,
                "enabled": true,
                "defaultString": "Coosa {22}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 108,
                "enabled": true,
                "defaultString": "Covington {23}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 109,
                "enabled": true,
                "defaultString": "Crenshaw {24}",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 110,
                "enabled": true,
                "defaultString": "Cullman {25}",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 111,
                "enabled": true,
                "defaultString": "Dale {26}",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 112,
                "enabled": true,
                "defaultString": "Dallas {27}",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 113,
                "enabled": true,
                "defaultString": "DeKalb {28}",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 114,
                "enabled": true,
                "defaultString": "Elmore {29}",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 115,
                "enabled": true,
                "defaultString": "Escambia {30}",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 116,
                "enabled": true,
                "defaultString": "Etowah {31}",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 117,
                "enabled": true,
                "defaultString": "Fayette {32}",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 118,
                "enabled": true,
                "defaultString": "Franklin {33}",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 119,
                "enabled": true,
                "defaultString": "Geneva {34}",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 120,
                "enabled": true,
                "defaultString": "Greene {35}",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 121,
                "enabled": true,
                "defaultString": "Hale {36}",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 122,
                "enabled": true,
                "defaultString": "Henry {37}",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 123,
                "enabled": true,
                "defaultString": "Houston {38}",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 124,
                "enabled": true,
                "defaultString": "Jackson {39}",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 125,
                "enabled": true,
                "defaultString": "Jefferson {01}",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 126,
                "enabled": true,
                "defaultString": "Lamar {40}",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 127,
                "enabled": true,
                "defaultString": "Lauderdale {41}",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 128,
                "enabled": true,
                "defaultString": "Lawrence {42}",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 129,
                "enabled": true,
                "defaultString": "Lee {43}",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 130,
                "enabled": true,
                "defaultString": "Limestone {44}",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 131,
                "enabled": true,
                "defaultString": "Lowndes {45}",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 132,
                "enabled": true,
                "defaultString": "Macon {46}",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 133,
                "enabled": true,
                "defaultString": "Madison {47}",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 134,
                "enabled": true,
                "defaultString": "Marengo {48}",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 135,
                "enabled": true,
                "defaultString": "Marion {49}",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 136,
                "enabled": true,
                "defaultString": "Marshall {50}",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 137,
                "enabled": true,
                "defaultString": "Mobile {02}",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 138,
                "enabled": true,
                "defaultString": "Monroe {51}",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 139,
                "enabled": true,
                "defaultString": "Montgomery {03}",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 140,
                "enabled": true,
                "defaultString": "Morgan {52}",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 141,
                "enabled": true,
                "defaultString": "Perry {53}",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 142,
                "enabled": true,
                "defaultString": "Pickens {54}",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 143,
                "enabled": true,
                "defaultString": "Pike {55}",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 144,
                "enabled": true,
                "defaultString": "Randolph {56}",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 145,
                "enabled": true,
                "defaultString": "Russell {57}",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 146,
                "enabled": true,
                "defaultString": "Shelby {59}",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 147,
                "enabled": true,
                "defaultString": "St Clair {58}",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 148,
                "enabled": true,
                "defaultString": "Sumter {60}",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 149,
                "enabled": true,
                "defaultString": "Talladega {61}",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 150,
                "enabled": true,
                "defaultString": "Tallapoosa {62}",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 151,
                "enabled": true,
                "defaultString": "Tuscaloosa {63}",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 152,
                "enabled": true,
                "defaultString": "Walker {64}",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 153,
                "enabled": true,
                "defaultString": "Washington {65}",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 154,
                "enabled": true,
                "defaultString": "Wilcox {66}",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 155,
                "enabled": true,
                "defaultString": "Winston {67}",
                "longString": "",
                "sequenceNumber": 67
            }
        ]
    },
    {
        "RefListID": 10,
        "refListName": "Activity_Status",
        "refListItems": [
            {
                "refListItemId": 156,
                "enabled": true,
                "defaultString": "Complete",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 157,
                "enabled": true,
                "defaultString": "Scheduled",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 11,
        "refListName": "Activity_Type",
        "refListItems": [
            {
                "refListItemId": 158,
                "enabled": true,
                "defaultString": "Interview",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 159,
                "enabled": true,
                "defaultString": "TelCall",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 160,
                "enabled": true,
                "defaultString": "TelCon",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3427,
                "enabled": true,
                "defaultString": "Surveillance",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3428,
                "enabled": true,
                "defaultString": "Undercover",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3429,
                "enabled": true,
                "defaultString": "Special Ops",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 12,
        "refListName": "Additional_Homicide",
        "refListItems": [
            {
                "refListItemId": 161,
                "enabled": true,
                "defaultString": "Criminal attacked a civilian",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 162,
                "enabled": true,
                "defaultString": "Criminal attacked officer and different officer killed criminal",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 163,
                "enabled": true,
                "defaultString": "Criminal attacked officer and that officer killed criminal",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 164,
                "enabled": true,
                "defaultString": "Criminal attempted flight from a crime",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 165,
                "enabled": true,
                "defaultString": "Criminal killed in commission of a crime",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 166,
                "enabled": true,
                "defaultString": "Criminal resisted arrest",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 167,
                "enabled": true,
                "defaultString": "Unable to determine/Not enough information",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 13,
        "refListName": "Address_Book_Type",
        "refListItems": [
            {
                "refListItemId": 168,
                "enabled": true,
                "defaultString": "Business",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 169,
                "enabled": true,
                "defaultString": "Person",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 14,
        "refListName": "Address_Entry_Type",
        "refListItems": [
            {
                "refListItemId": 170,
                "enabled": true,
                "defaultString": "Attorney",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 171,
                "enabled": true,
                "defaultString": "Employee",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 172,
                "enabled": true,
                "defaultString": "Sheriff",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 173,
                "enabled": true,
                "defaultString": "Vendor",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3566,
                "enabled": true,
                "defaultString": "Judge",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 15,
        "refListName": "AdmGCICEntry",
        "refListItems": [
            {
                "refListItemId": 174,
                "enabled": true,
                "defaultString": "Article",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 175,
                "enabled": true,
                "defaultString": "Boat",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 176,
                "enabled": true,
                "defaultString": "Gun",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 177,
                "enabled": true,
                "defaultString": "Missing Persons",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 178,
                "enabled": true,
                "defaultString": "Securities",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 179,
                "enabled": true,
                "defaultString": "Vehicle",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 180,
                "enabled": true,
                "defaultString": "Warrant",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 16,
        "refListName": "AdultClubs",
        "refListItems": [
            {
                "refListItemId": 181,
                "enabled": true,
                "defaultString": "24K",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 182,
                "enabled": true,
                "defaultString": "Body Tap",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 183,
                "enabled": true,
                "defaultString": "Cheetah",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 184,
                "enabled": true,
                "defaultString": "Clermont Lounge",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 185,
                "enabled": true,
                "defaultString": "Mousetrap",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 17,
        "refListName": "Affiliation",
        "refListItems": "NULL"
    },
    {
        "RefListID": 18,
        "refListName": "Agency",
        "refListItems": [
            {
                "refListItemId": 186,
                "enabled": true,
                "defaultString": "APD",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 187,
                "enabled": true,
                "defaultString": "MNPD",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 188,
                "enabled": true,
                "defaultString": "TCSO",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 19,
        "refListName": "Aggravated_Assault",
        "refListItems": [
            {
                "refListItemId": 189,
                "enabled": true,
                "defaultString": "Argument",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 190,
                "enabled": true,
                "defaultString": "Assault on Officer(s)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 191,
                "enabled": true,
                "defaultString": "Child Playing with Weapon",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 192,
                "enabled": true,
                "defaultString": "Criminal Killed by Officer",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 193,
                "enabled": true,
                "defaultString": "Criminal Killing (Private Citizen)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 194,
                "enabled": true,
                "defaultString": "Drug Dealing",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 195,
                "enabled": true,
                "defaultString": "Gangland",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 196,
                "enabled": true,
                "defaultString": "Gun-Cleaning Accident",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 197,
                "enabled": true,
                "defaultString": "Hunting Accident",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 198,
                "enabled": true,
                "defaultString": "Juvenile Gang",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 199,
                "enabled": true,
                "defaultString": "Lover's Quarrel",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 200,
                "enabled": true,
                "defaultString": "Mercy Killing",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 201,
                "enabled": true,
                "defaultString": "Other Circumstances",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 202,
                "enabled": true,
                "defaultString": "Other Felony Involved",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 203,
                "enabled": true,
                "defaultString": "Other Negligent Killing",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 204,
                "enabled": true,
                "defaultString": "Other Negligent Weapon Handling",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 205,
                "enabled": true,
                "defaultString": "Unknown Circumstances",
                "longString": "",
                "sequenceNumber": 17
            }
        ]
    },
    {
        "RefListID": 20,
        "refListName": "Alarm_Category",
        "refListItems": [
            {
                "refListItemId": 206,
                "enabled": true,
                "defaultString": "Business",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 207,
                "enabled": true,
                "defaultString": "Residential",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 21,
        "refListName": "Alarm_Company",
        "refListItems": [
            {
                "refListItemId": 208,
                "enabled": true,
                "defaultString": "AAA Security",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 209,
                "enabled": true,
                "defaultString": "Ackerman Security",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 210,
                "enabled": true,
                "defaultString": "ADT",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 211,
                "enabled": true,
                "defaultString": "HouseSecure",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 22,
        "refListName": "AlarmEventDescription",
        "refListItems": [
            {
                "refListItemId": 212,
                "enabled": true,
                "defaultString": "Burglary",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 213,
                "enabled": true,
                "defaultString": "Fire",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 214,
                "enabled": true,
                "defaultString": "User Triggered",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 23,
        "refListName": "AlarmManufacturer",
        "refListItems": [
            {
                "refListItemId": 215,
                "enabled": true,
                "defaultString": "Frigidaire",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 216,
                "enabled": true,
                "defaultString": "Raytheon",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 217,
                "enabled": true,
                "defaultString": "Westinghouse",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 24,
        "refListName": "Alcohol",
        "refListItems": [
            {
                "refListItemId": 218,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 219,
                "enabled": true,
                "defaultString": "Unk",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 220,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 25,
        "refListName": "Armed_With",
        "refListItems": [
            {
                "refListItemId": 221,
                "enabled": true,
                "defaultString": "Club/Blackjack/Brass Knuckles",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 222,
                "enabled": true,
                "defaultString": "Firearm",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 223,
                "enabled": true,
                "defaultString": "Firearm [Automatic]",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 224,
                "enabled": true,
                "defaultString": "Handgun",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 225,
                "enabled": true,
                "defaultString": "Handgun [Automatic]",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 226,
                "enabled": true,
                "defaultString": "Lethal Cutting Instrument",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 227,
                "enabled": true,
                "defaultString": "Other Firearm",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 228,
                "enabled": true,
                "defaultString": "Other Firearm [Automatic]",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 229,
                "enabled": true,
                "defaultString": "Rifle",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 230,
                "enabled": true,
                "defaultString": "Shotgun",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 231,
                "enabled": true,
                "defaultString": "Unarmed",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 26,
        "refListName": "Arrest_Type",
        "refListItems": [
            {
                "refListItemId": 232,
                "enabled": true,
                "defaultString": "On-View",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 233,
                "enabled": true,
                "defaultString": "Summoned/Cited",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 234,
                "enabled": true,
                "defaultString": "Taken into Custody",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 27,
        "refListName": "ArsonTarget",
        "refListItems": [
            {
                "refListItemId": 235,
                "enabled": true,
                "defaultString": "All Other",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 236,
                "enabled": true,
                "defaultString": "Community/Public",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 237,
                "enabled": true,
                "defaultString": "Industrial/Mfg",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 238,
                "enabled": true,
                "defaultString": "Motor Vehicles",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 239,
                "enabled": true,
                "defaultString": "Other Commercial",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 240,
                "enabled": true,
                "defaultString": "Other Mobile",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 241,
                "enabled": true,
                "defaultString": "Other Residential",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 242,
                "enabled": true,
                "defaultString": "Other Structure",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 243,
                "enabled": true,
                "defaultString": "Single-Occupancy",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 244,
                "enabled": true,
                "defaultString": "Storage",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 28,
        "refListName": "AssaultActivity",
        "refListItems": [
            {
                "refListItemId": 245,
                "enabled": true,
                "defaultString": "All Other",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 246,
                "enabled": true,
                "defaultString": "Ambush",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 247,
                "enabled": true,
                "defaultString": "Burglaries",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 248,
                "enabled": true,
                "defaultString": "Civil Disorder",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 249,
                "enabled": true,
                "defaultString": "Disturbance",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 250,
                "enabled": true,
                "defaultString": "Investigation",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 251,
                "enabled": true,
                "defaultString": "Mentally Deranged",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 252,
                "enabled": true,
                "defaultString": "Other Arrests",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 253,
                "enabled": true,
                "defaultString": "Prisoners",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 254,
                "enabled": true,
                "defaultString": "Robberies",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 255,
                "enabled": true,
                "defaultString": "Traffic",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 29,
        "refListName": "AssaultAssignment",
        "refListItems": [
            {
                "refListItemId": 256,
                "enabled": true,
                "defaultString": "Detective",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 257,
                "enabled": true,
                "defaultString": "One - Man",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 258,
                "enabled": true,
                "defaultString": "One-Man",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 259,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 260,
                "enabled": true,
                "defaultString": "Two - Man",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 261,
                "enabled": true,
                "defaultString": "Two-Man",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 30,
        "refListName": "Assigned_Type",
        "refListItems": [
            {
                "refListItemId": 262,
                "enabled": true,
                "defaultString": "Inmate",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 263,
                "enabled": true,
                "defaultString": "Staff",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 31,
        "refListName": "Associate_Type",
        "refListItems": [
            {
                "refListItemId": 264,
                "enabled": true,
                "defaultString": "Board Member",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 265,
                "enabled": true,
                "defaultString": "Office Manager",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 266,
                "enabled": true,
                "defaultString": "Officer",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 267,
                "enabled": true,
                "defaultString": "Owner",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 32,
        "refListName": "Association_Type",
        "refListItems": [
            {
                "refListItemId": 268,
                "enabled": true,
                "defaultString": "Church",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 269,
                "enabled": true,
                "defaultString": "Gang",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 270,
                "enabled": true,
                "defaultString": "Militia",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 33,
        "refListName": "Bankruptcy_Chapter",
        "refListItems": [
            {
                "refListItemId": 271,
                "enabled": true,
                "defaultString": "11",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 272,
                "enabled": true,
                "defaultString": "13",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 273,
                "enabled": true,
                "defaultString": "7",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 34,
        "refListName": "Bankruptcy_Location",
        "refListItems": [
            {
                "refListItemId": 274,
                "enabled": true,
                "defaultString": "Monroe Court 1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 275,
                "enabled": true,
                "defaultString": "Monroe Court 2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 276,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 35,
        "refListName": "Bankruptcy_Status",
        "refListItems": [
            {
                "refListItemId": 277,
                "enabled": true,
                "defaultString": "Discharged",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 278,
                "enabled": true,
                "defaultString": "Dismissed",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 279,
                "enabled": true,
                "defaultString": "Open",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 512,
        "refListName": "Charges",
        "refListItems": ""
    },
    {
        "RefListID": 37,
        "refListName": "Bias",
        "refListItems": [
            {
                "refListItemId": 284,
                "enabled": true,
                "defaultString": "Racial",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 285,
                "enabled": true,
                "defaultString": "Religious",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 286,
                "enabled": true,
                "defaultString": "Sexual",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 38,
        "refListName": "Bias_Motivation",
        "refListItems": [
            {
                "refListItemId": 287,
                "enabled": true,
                "defaultString": "American Indian/Alaskan",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 288,
                "enabled": true,
                "defaultString": "Asian/Pacific Islander",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 289,
                "enabled": true,
                "defaultString": "Atheist/Agnostic",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 290,
                "enabled": true,
                "defaultString": "Bisexual",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 291,
                "enabled": true,
                "defaultString": "Black",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 292,
                "enabled": true,
                "defaultString": "Catholic",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 293,
                "enabled": true,
                "defaultString": "Female Homosexual (Lesbian)",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 294,
                "enabled": true,
                "defaultString": "Heterosexual",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 295,
                "enabled": true,
                "defaultString": "Hispanic",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 296,
                "enabled": true,
                "defaultString": "Homosexual (Gays & Lesbians)",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 297,
                "enabled": true,
                "defaultString": "Islamic",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 298,
                "enabled": true,
                "defaultString": "Jewish",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 299,
                "enabled": true,
                "defaultString": "Male Homosexual (Gay)",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 300,
                "enabled": true,
                "defaultString": "Mental Disability",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 301,
                "enabled": true,
                "defaultString": "Multi-Racial Group",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 302,
                "enabled": true,
                "defaultString": "Multi-Religious Group",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 303,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 304,
                "enabled": true,
                "defaultString": "Other Ethnicity/National",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 305,
                "enabled": true,
                "defaultString": "Other Religion",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 306,
                "enabled": true,
                "defaultString": "Physical Disability",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 307,
                "enabled": true,
                "defaultString": "Protestant",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 308,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 309,
                "enabled": true,
                "defaultString": "White",
                "longString": "",
                "sequenceNumber": 23
            }
        ]
    },
    {
        "RefListID": 39,
        "refListName": "Biohazardous_Type",
        "refListItems": [
            {
                "refListItemId": 310,
                "enabled": true,
                "defaultString": "Airborne Pathogen",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 311,
                "enabled": true,
                "defaultString": "Blood",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 312,
                "enabled": true,
                "defaultString": "Bodily Fluids",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 313,
                "enabled": true,
                "defaultString": "Tissue",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 513,
        "refListName": "Arrest_City",
        "refListItems": ""
    },
    {
        "RefListID": 41,
        "refListName": "Blood_Type",
        "refListItems": [
            {
                "refListItemId": 316,
                "enabled": true,
                "defaultString": "A-",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 317,
                "enabled": true,
                "defaultString": "A+",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 318,
                "enabled": true,
                "defaultString": "AB-",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 319,
                "enabled": true,
                "defaultString": "AB+",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 320,
                "enabled": true,
                "defaultString": "B-",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 321,
                "enabled": true,
                "defaultString": "B+",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 322,
                "enabled": true,
                "defaultString": "O-",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 323,
                "enabled": true,
                "defaultString": "O+",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 16104,
                "enabled": true,
                "defaultString": "U",
                "longString": "",
                "sequenceNumber": 0
            }
        ]
    },
    {
        "RefListID": 42,
        "refListName": "BloodType",
        "refListItems": [
            {
                "refListItemId": 324,
                "enabled": true,
                "defaultString": "A-",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 325,
                "enabled": true,
                "defaultString": "A+",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 326,
                "enabled": true,
                "defaultString": "AB-",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 327,
                "enabled": true,
                "defaultString": "AB+",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 328,
                "enabled": true,
                "defaultString": "B-",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 329,
                "enabled": true,
                "defaultString": "B+",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 330,
                "enabled": true,
                "defaultString": "O-",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 331,
                "enabled": true,
                "defaultString": "O+",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 16103,
                "enabled": true,
                "defaultString": "U",
                "longString": "",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 43,
        "refListName": "BondType",
        "refListItems": [
            {
                "refListItemId": 332,
                "enabled": false,
                "defaultString": "Cash",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 333,
                "enabled": false,
                "defaultString": "Property",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3632,
                "enabled": false,
                "defaultString": "Recognizance",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3633,
                "enabled": false,
                "defaultString": "Combined",
                "longString": "Use this bond type if the bond covers multiple charges.",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5179,
                "enabled": true,
                "defaultString": "Body-Only",
                "longString": "The judge has said that the inmate will be held until further notice; cannot bond out.",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5180,
                "enabled": true,
                "defaultString": "Bail or Fine",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5181,
                "enabled": true,
                "defaultString": "Bail and Fine",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5182,
                "enabled": true,
                "defaultString": "No bail or fine",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5183,
                "enabled": true,
                "defaultString": "Fine",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5184,
                "enabled": true,
                "defaultString": "Bail",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5022,
                "enabled": false,
                "defaultString": "With 10%",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5023,
                "enabled": false,
                "defaultString": "No 10%",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 566,
        "refListName": "Problem_Status",
        "refListItems": [
            {
                "refListItemId": 16184,
                "enabled": true,
                "defaultString": "Open",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16185,
                "enabled": true,
                "defaultString": "Resolved",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 45,
        "refListName": "Business_Relation_Type",
        "refListItems": [
            {
                "refListItemId": 337,
                "enabled": true,
                "defaultString": "Contact",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 338,
                "enabled": true,
                "defaultString": "Employed",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 339,
                "enabled": true,
                "defaultString": "Owned",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 46,
        "refListName": "BusinessBusiness_Relation_Type",
        "refListItems": [
            {
                "refListItemId": 340,
                "enabled": true,
                "defaultString": "Management",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 341,
                "enabled": true,
                "defaultString": "Premise",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 342,
                "enabled": true,
                "defaultString": "Proximate",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 47,
        "refListName": "Cancellation_Reason",
        "refListItems": [
            {
                "refListItemId": 343,
                "enabled": true,
                "defaultString": "Court Ordered",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 344,
                "enabled": true,
                "defaultString": "Sheriff Ordered",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 345,
                "enabled": true,
                "defaultString": "Visitor Cancelled",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 48,
        "refListName": "CarryReason",
        "refListItems": [
            {
                "refListItemId": 346,
                "enabled": true,
                "defaultString": "Employment",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 347,
                "enabled": true,
                "defaultString": "Gun Collector",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 348,
                "enabled": true,
                "defaultString": "Hunting/Fishing",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 349,
                "enabled": true,
                "defaultString": "Protection",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 350,
                "enabled": true,
                "defaultString": "Target Shooting",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 49,
        "refListName": "Case_Offense_Code",
        "refListItems": [
            {
                "refListItemId": 351,
                "enabled": true,
                "defaultString": "Arson",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 352,
                "enabled": true,
                "defaultString": "Homicide",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 50,
        "refListName": "Case_Type",
        "refListItems": [
            {
                "refListItemId": 353,
                "enabled": true,
                "defaultString": "Arson",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 354,
                "enabled": true,
                "defaultString": "ASSAULT",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 355,
                "enabled": true,
                "defaultString": "BURGLARY",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 356,
                "enabled": true,
                "defaultString": "DRUG INVEST",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 357,
                "enabled": true,
                "defaultString": "HOMICIDE",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 358,
                "enabled": true,
                "defaultString": "Murder",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 359,
                "enabled": true,
                "defaultString": "OTHER",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 360,
                "enabled": true,
                "defaultString": "Rape",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 361,
                "enabled": true,
                "defaultString": "THEFT",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 362,
                "enabled": true,
                "defaultString": "TRAFFIC",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 363,
                "enabled": true,
                "defaultString": "UNDERCOVER",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 51,
        "refListName": "CashAccountingTransactionType",
        "refListItems": [
            {
                "refListItemId": 364,
                "enabled": true,
                "defaultString": "Payment",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 365,
                "enabled": true,
                "defaultString": "Purchase",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 567,
        "refListName": "TransactionTypeCashAcc",
        "refListItems": [
            {
                "refListItemId": 16186,
                "enabled": true,
                "defaultString": "Brady Bill Check(s)",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 53,
        "refListName": "Cells_Holding",
        "refListItems": [
            {
                "refListItemId": 374,
                "enabled": false,
                "defaultString": "H-1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 375,
                "enabled": false,
                "defaultString": "H-2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 376,
                "enabled": false,
                "defaultString": "H-3",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15468,
                "enabled": true,
                "defaultString": "Dayroom E",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 15471,
                "enabled": true,
                "defaultString": "Intake",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 15499,
                "enabled": true,
                "defaultString": "Dayroom W",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 15545,
                "enabled": true,
                "defaultString": "ESB",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 19250,
                "enabled": true,
                "defaultString": "H-140"
            },
            {
                "refListItemId": 16524,
                "enabled": true,
                "defaultString": "ACB&R",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 19249,
                "enabled": true,
                "defaultString": "H-138"
            },
            {
                "refListItemId": 19251,
                "enabled": true,
                "defaultString": "H-145"
            },
            {
                "refListItemId": 19252,
                "enabled": true,
                "defaultString": "H-139"
            }
        ]
    },
    {
        "RefListID": 54,
        "refListName": "Cells_Isolation",
        "refListItems": [
            {
                "refListItemId": 377,
                "enabled": true,
                "defaultString": "I-001",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 378,
                "enabled": true,
                "defaultString": "I-002",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 379,
                "enabled": true,
                "defaultString": "I-003",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 380,
                "enabled": true,
                "defaultString": "I-004",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 55,
        "refListName": "Certificate_Reason",
        "refListItems": "NULL"
    },
    {
        "RefListID": 568,
        "refListName": "LevyType",
        "refListItems": [
            {
                "refListItemId": 16187,
                "enabled": true,
                "defaultString": "Personal Property",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 569,
        "refListName": "BookingQueueStep",
        "refListItems": [
            {
                "refListItemId": 16188,
                "enabled": true,
                "defaultString": "01 Int (Intake)",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16698,
                "enabled": true,
                "defaultString": "02 Med (Medical)",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16699,
                "enabled": true,
                "defaultString": "03 CLS (Classification)",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 16700,
                "enabled": true,
                "defaultString": "04 Chg (Charges)",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 16701,
                "enabled": true,
                "defaultString": "05 ASN (Associations)",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 16702,
                "enabled": true,
                "defaultString": "06 JF (Flags)",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 16703,
                "enabled": true,
                "defaultString": "07 V (Victims)",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 16704,
                "enabled": true,
                "defaultString": "08 BC (Booking Complete)",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16705,
                "enabled": true,
                "defaultString": "09 MS (Mugshot)",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 16706,
                "enabled": true,
                "defaultString": "10 HSG (Housing)",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 16707,
                "enabled": true,
                "defaultString": "11 CP (Remove from BQ)",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 621,
        "refListName": "Watch_Group",
        "refListItems": [
            {
                "refListItemId": 18148,
                "enabled": true,
                "defaultString": "U1/U2/U3/PG2",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18149,
                "enabled": true,
                "defaultString": "U4/U5/U6/PG4",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18150,
                "enabled": true,
                "defaultString": "Court",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18151,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18152,
                "enabled": true,
                "defaultString": "Book/Sp Mgt",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18153,
                "enabled": true,
                "defaultString": "Visiting",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18154,
                "enabled": true,
                "defaultString": "Out of Facility",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18155,
                "enabled": true,
                "defaultString": "K Pod",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18156,
                "enabled": true,
                "defaultString": "L Pod",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18157,
                "enabled": true,
                "defaultString": "C Pod",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18158,
                "enabled": true,
                "defaultString": "D Pod",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18159,
                "enabled": true,
                "defaultString": "FL1",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18160,
                "enabled": true,
                "defaultString": "FL2",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 18161,
                "enabled": true,
                "defaultString": "FL3",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18162,
                "enabled": true,
                "defaultString": "Seg/CL RM/Int",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 59,
        "refListName": "CitationCharges",
        "refListItems": [
            {
                "refListItemId": 462,
                "enabled": true,
                "defaultString": "Disorderly Conduct",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 463,
                "enabled": true,
                "defaultString": "Disturbing the Peace",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 464,
                "enabled": true,
                "defaultString": "Driving Under the Influence",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 465,
                "enabled": true,
                "defaultString": "Excessive Lane Changes",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 466,
                "enabled": true,
                "defaultString": "Reckless Driving",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 467,
                "enabled": true,
                "defaultString": "Speeding",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 60,
        "refListName": "CitationType",
        "refListItems": [
            {
                "refListItemId": 468,
                "enabled": true,
                "defaultString": "Non-Traffic",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 469,
                "enabled": true,
                "defaultString": "Parking",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 470,
                "enabled": true,
                "defaultString": "Traffic",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 471,
                "enabled": true,
                "defaultString": "Warning",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3788,
                "enabled": true,
                "defaultString": "Criminal",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 61,
        "refListName": "Civil_Paper_Document_Type",
        "refListItems": [
            {
                "refListItemId": 472,
                "enabled": false,
                "defaultString": "Breach of Contract",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 473,
                "enabled": false,
                "defaultString": "Citation",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 474,
                "enabled": false,
                "defaultString": "Complaint",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 475,
                "enabled": false,
                "defaultString": "Complaint for Damages",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 476,
                "enabled": false,
                "defaultString": "Complaint on Open Account",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 477,
                "enabled": false,
                "defaultString": "Contempt of Court",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 478,
                "enabled": false,
                "defaultString": "Court Order",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 479,
                "enabled": false,
                "defaultString": "Court Summaries",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 480,
                "enabled": false,
                "defaultString": "Dispossessory",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 481,
                "enabled": false,
                "defaultString": "Divorce",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 482,
                "enabled": false,
                "defaultString": "Foreclosure",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 483,
                "enabled": false,
                "defaultString": "Garnishment",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 484,
                "enabled": false,
                "defaultString": "Juvenile Summons",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 485,
                "enabled": false,
                "defaultString": "Main In",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 486,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 487,
                "enabled": false,
                "defaultString": "Rule Nisi",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 488,
                "enabled": true,
                "defaultString": "Statement of Claim",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 489,
                "enabled": false,
                "defaultString": "Suit on Account",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 490,
                "enabled": false,
                "defaultString": "Support/Modification",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 491,
                "enabled": false,
                "defaultString": "Temporary Protective Order",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 492,
                "enabled": false,
                "defaultString": "Writ of Possession",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5382,
                "enabled": true,
                "defaultString": "Affidavit/Order Supp Proceed",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5383,
                "enabled": true,
                "defaultString": "Cancellation of Contract/Deed",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5384,
                "enabled": true,
                "defaultString": "Domestic",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5385,
                "enabled": true,
                "defaultString": "Execution/Bank",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5386,
                "enabled": true,
                "defaultString": "Execution/Third",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5387,
                "enabled": true,
                "defaultString": "Execution/Wage",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5388,
                "enabled": true,
                "defaultString": "Execution",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5389,
                "enabled": true,
                "defaultString": "Findings of Fact",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 5390,
                "enabled": true,
                "defaultString": "Garnishment Summons",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5391,
                "enabled": true,
                "defaultString": "Harassment",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5392,
                "enabled": true,
                "defaultString": "Interrogatories",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 5393,
                "enabled": true,
                "defaultString": "Letter",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 5394,
                "enabled": true,
                "defaultString": "Land Title Summons",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 5395,
                "enabled": true,
                "defaultString": "not mor for sale,home des not",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 5396,
                "enabled": true,
                "defaultString": "Motion",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 5397,
                "enabled": true,
                "defaultString": "Notice of Motion",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 5398,
                "enabled": true,
                "defaultString": "Notice of Proposed Order",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 5399,
                "enabled": true,
                "defaultString": "Notice of Sale",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 5400,
                "enabled": true,
                "defaultString": "Notice",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 5401,
                "enabled": true,
                "defaultString": "Order and Notice",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 5402,
                "enabled": true,
                "defaultString": "Order",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 5403,
                "enabled": true,
                "defaultString": "Order to Show Cause",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 5404,
                "enabled": true,
                "defaultString": "Petition",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 5405,
                "enabled": true,
                "defaultString": "Replevin Order",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 5406,
                "enabled": true,
                "defaultString": "Redemption",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 5407,
                "enabled": true,
                "defaultString": "Request for Production of Doc",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 5408,
                "enabled": true,
                "defaultString": "Sheriff's Sale",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 5409,
                "enabled": true,
                "defaultString": "Summons and Complaint",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 5410,
                "enabled": true,
                "defaultString": "Summons and Petition",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 5411,
                "enabled": true,
                "defaultString": "Subpoena (C.A.)",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 5412,
                "enabled": true,
                "defaultString": "Summons and Complaint/Detainer",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 5413,
                "enabled": true,
                "defaultString": "Subpoena (P.D.)",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 5414,
                "enabled": true,
                "defaultString": "Subpoena",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 5415,
                "enabled": true,
                "defaultString": "Summons",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 5416,
                "enabled": true,
                "defaultString": "Writ of Attachment",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 5417,
                "enabled": true,
                "defaultString": "Writ of Recovery",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 25009,
                "enabled": true,
                "defaultString": "Sales Mortgage",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 24408,
                "enabled": true,
                "defaultString": "Parentage Summons (CCSD 0612)"
            }
        ]
    },
    {
        "RefListID": 62,
        "refListName": "Civil_Paper_Type",
        "refListItems": [
            {
                "refListItemId": 493,
                "enabled": true,
                "defaultString": "Civil Complaint",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 494,
                "enabled": true,
                "defaultString": "Criminal Complaint",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 495,
                "enabled": true,
                "defaultString": "Hearing Date",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4288,
                "enabled": true,
                "defaultString": "Real Estate",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4289,
                "enabled": true,
                "defaultString": "Personal Property",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 63,
        "refListName": "CivilPaperStatus",
        "refListItems": [
            {
                "refListItemId": 496,
                "enabled": true,
                "defaultString": "Closed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 497,
                "enabled": true,
                "defaultString": "Open",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3789,
                "enabled": true,
                "defaultString": "Returned",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 64,
        "refListName": "ClearanceReason",
        "refListItems": [
            {
                "refListItemId": 498,
                "enabled": true,
                "defaultString": "Cleared by Arrest",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 499,
                "enabled": true,
                "defaultString": "Exceptionally Cleared",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 500,
                "enabled": true,
                "defaultString": "Unfounded",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 65,
        "refListName": "Complexion",
        "refListItems": [
            {
                "refListItemId": 501,
                "enabled": false,
                "defaultString": "ALB",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 502,
                "enabled": false,
                "defaultString": "BLK",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 503,
                "enabled": true,
                "defaultString": "DBR",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 504,
                "enabled": false,
                "defaultString": "DRK",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 505,
                "enabled": true,
                "defaultString": "FAR",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 506,
                "enabled": true,
                "defaultString": "LBR",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 507,
                "enabled": false,
                "defaultString": "LGT",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 508,
                "enabled": true,
                "defaultString": "MBR",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 509,
                "enabled": true,
                "defaultString": "MED",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 510,
                "enabled": true,
                "defaultString": "OLV",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 511,
                "enabled": true,
                "defaultString": "RUD",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 512,
                "enabled": true,
                "defaultString": "SAL",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 513,
                "enabled": false,
                "defaultString": "YEL",
                "longString": "",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 66,
        "refListName": "Confiscation_Reason",
        "refListItems": [
            {
                "refListItemId": 514,
                "enabled": true,
                "defaultString": "Conditions of Parole",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 515,
                "enabled": true,
                "defaultString": "Judge's Order",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 516,
                "enabled": true,
                "defaultString": "Personal Request",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 517,
                "enabled": true,
                "defaultString": "Protection from Abuse",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 518,
                "enabled": true,
                "defaultString": "Sentence",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 67,
        "refListName": "Contributing_Circumstances",
        "refListItems": [
            {
                "refListItemId": 519,
                "enabled": true,
                "defaultString": "Avoid Object / Person / Vehicle {07}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 520,
                "enabled": true,
                "defaultString": "D.U.I. {17}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 521,
                "enabled": true,
                "defaultString": "Defective Equipment {16}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 522,
                "enabled": true,
                "defaultString": "Driver Condition {22}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 523,
                "enabled": true,
                "defaultString": "Driver Not In Control {27}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 524,
                "enabled": true,
                "defaultString": "Fail to Heed Sign / Signal {12}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 525,
                "enabled": true,
                "defaultString": "Failure to Yield Right of Way {21}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 526,
                "enabled": true,
                "defaultString": "Following Too Close {04}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 527,
                "enabled": true,
                "defaultString": "Illegal / Improper Parking {33}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 528,
                "enabled": true,
                "defaultString": "Improper / No Signal {11}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 529,
                "enabled": true,
                "defaultString": "Improper Attachment {20}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 530,
                "enabled": true,
                "defaultString": "Improper Backing {09}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 531,
                "enabled": true,
                "defaultString": "Improper Driving for the Environment {13}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 532,
                "enabled": true,
                "defaultString": "Improper Lane Change / Usage {02}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 533,
                "enabled": true,
                "defaultString": "Improper Load / Size {19}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 534,
                "enabled": true,
                "defaultString": "Improper Passing {01}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 535,
                "enabled": true,
                "defaultString": "Improper Turn / U-Turn {03}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 536,
                "enabled": true,
                "defaultString": "Inoperative Traffic Controls {10}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 537,
                "enabled": true,
                "defaultString": "Load Shift {28}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 538,
                "enabled": true,
                "defaultString": "Misjudged Stopping Distance {05}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 539,
                "enabled": true,
                "defaultString": "None {97}",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 540,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 541,
                "enabled": true,
                "defaultString": "Over Speed Limit {06}",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 542,
                "enabled": true,
                "defaultString": "Parts / Cargo from Vehicle {29}",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 543,
                "enabled": true,
                "defaultString": "Pedestrian Under Influence {32}",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 544,
                "enabled": true,
                "defaultString": "Pedestrian Violation {30}",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 545,
                "enabled": true,
                "defaultString": "Road Defect {14}",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 546,
                "enabled": true,
                "defaultString": "Under Minimal Speed Limit {18}",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 547,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 548,
                "enabled": true,
                "defaultString": "Unseen Object / Person / Vehicle {08}",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 549,
                "enabled": true,
                "defaultString": "Vehicle Left Road {26}",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 550,
                "enabled": true,
                "defaultString": "Vehicle Pushed / Towed by Vehicle {24}",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 551,
                "enabled": true,
                "defaultString": "Vehicle Pushed by Person {25}",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 552,
                "enabled": true,
                "defaultString": "Vehicle Weight / Height / Length {31}",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 553,
                "enabled": true,
                "defaultString": "Vision Obstruction {15}",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 554,
                "enabled": true,
                "defaultString": "Wrong Side of Road {23}",
                "longString": "",
                "sequenceNumber": 36
            }
        ]
    },
    {
        "RefListID": 68,
        "refListName": "Contributing_Defect",
        "refListItems": [
            {
                "refListItemId": 555,
                "enabled": true,
                "defaultString": "Brakes {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 556,
                "enabled": true,
                "defaultString": "Cargo {13}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 557,
                "enabled": true,
                "defaultString": "Exhaust {6}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 558,
                "enabled": true,
                "defaultString": "Fuel System {14}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 559,
                "enabled": true,
                "defaultString": "Lights {7}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 560,
                "enabled": true,
                "defaultString": "None {97}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 561,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 562,
                "enabled": true,
                "defaultString": "Power Plant {3}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 563,
                "enabled": true,
                "defaultString": "Restraint System {10}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 564,
                "enabled": true,
                "defaultString": "Steering {2}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 565,
                "enabled": true,
                "defaultString": "Suspension {4}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 566,
                "enabled": true,
                "defaultString": "Tires {5}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 567,
                "enabled": true,
                "defaultString": "Truck Coupling {12}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 568,
                "enabled": true,
                "defaultString": "Turn Signal {8}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 569,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 570,
                "enabled": true,
                "defaultString": "Wheels {11}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 571,
                "enabled": true,
                "defaultString": "Windows/Windshield {9}",
                "longString": "",
                "sequenceNumber": 17
            }
        ]
    },
    {
        "RefListID": 69,
        "refListName": "Contributing_Road_Defect",
        "refListItems": [
            {
                "refListItemId": 572,
                "enabled": true,
                "defaultString": "Holes, Bumps, etc. {3}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 573,
                "enabled": true,
                "defaultString": "None {4}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 574,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 575,
                "enabled": true,
                "defaultString": "Shoulders High {2}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 576,
                "enabled": true,
                "defaultString": "Shoulders Low {1}",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 70,
        "refListName": "Control_Access_Highway_Location",
        "refListItems": [
            {
                "refListItemId": 577,
                "enabled": true,
                "defaultString": "Entrance Ramp {4}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 578,
                "enabled": true,
                "defaultString": "Exit Ramp {5}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 579,
                "enabled": true,
                "defaultString": "Frontage Road {2}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 580,
                "enabled": true,
                "defaultString": "Interchange {3}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 581,
                "enabled": true,
                "defaultString": "Main Road {1}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 582,
                "enabled": true,
                "defaultString": "N/A {6}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 71,
        "refListName": "Country",
        "refListItems": [
            {
                "refListItemId": 15957,
                "enabled": true,
                "defaultString": "Somalia"
            },
            {
                "refListItemId": 3709,
                "enabled": true,
                "defaultString": "Spain",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 3710,
                "enabled": true,
                "defaultString": "Sri Lanka",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 3711,
                "enabled": true,
                "defaultString": "Suriname",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 3712,
                "enabled": true,
                "defaultString": "Sweden",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 3713,
                "enabled": true,
                "defaultString": "Switzerland",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 3714,
                "enabled": true,
                "defaultString": "Taiwan",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 3715,
                "enabled": true,
                "defaultString": "Thailand",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 3716,
                "enabled": true,
                "defaultString": "Trinidad and Tobago",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 3717,
                "enabled": true,
                "defaultString": "Turkey",
                "longString": "",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 3718,
                "enabled": true,
                "defaultString": "Turks and Caicos Islands",
                "longString": "",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 3719,
                "enabled": true,
                "defaultString": "United Kingdom",
                "longString": "",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 3720,
                "enabled": true,
                "defaultString": "United States",
                "longString": "",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 3721,
                "enabled": true,
                "defaultString": "Uruguay",
                "longString": "",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 3722,
                "enabled": true,
                "defaultString": "Venezuela",
                "longString": "",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 3723,
                "enabled": true,
                "defaultString": "Vietnam",
                "longString": "",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 3724,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 3725,
                "enabled": true,
                "defaultString": "Cuba",
                "longString": "",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 3640,
                "enabled": true,
                "defaultString": "Anguilla",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3641,
                "enabled": true,
                "defaultString": "Antigua and Barbuda",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3642,
                "enabled": true,
                "defaultString": "Argentina",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3643,
                "enabled": true,
                "defaultString": "Aruba",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3644,
                "enabled": true,
                "defaultString": "Australia",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3645,
                "enabled": true,
                "defaultString": "Austria",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3646,
                "enabled": true,
                "defaultString": "Bahamas",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3647,
                "enabled": true,
                "defaultString": "Bangladesh",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3648,
                "enabled": true,
                "defaultString": "Barbados",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3649,
                "enabled": true,
                "defaultString": "Belgium",
                "longString": "",
                "sequenceNumber": 0
            },
            {
                "refListItemId": 3650,
                "enabled": true,
                "defaultString": "Bermuda",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3651,
                "enabled": true,
                "defaultString": "Bolivia",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3652,
                "enabled": true,
                "defaultString": "Brazil",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3653,
                "enabled": true,
                "defaultString": "British Virg Isl",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3654,
                "enabled": true,
                "defaultString": "Bulgaria",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3655,
                "enabled": true,
                "defaultString": "Canada",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3656,
                "enabled": true,
                "defaultString": "Cayman Islands",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3657,
                "enabled": true,
                "defaultString": "Chile",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3658,
                "enabled": true,
                "defaultString": "China",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3659,
                "enabled": true,
                "defaultString": "Colombia",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3660,
                "enabled": true,
                "defaultString": "Croatia",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3661,
                "enabled": true,
                "defaultString": "Cyprus",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3662,
                "enabled": true,
                "defaultString": "Czech Republic",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3663,
                "enabled": true,
                "defaultString": "Denmark",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3664,
                "enabled": true,
                "defaultString": "Dominica",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3665,
                "enabled": true,
                "defaultString": "Ecuador",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3666,
                "enabled": true,
                "defaultString": "Egypt",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3667,
                "enabled": true,
                "defaultString": "Estonia",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3668,
                "enabled": true,
                "defaultString": "Finland",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3669,
                "enabled": true,
                "defaultString": "France",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3670,
                "enabled": true,
                "defaultString": "Germany",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3671,
                "enabled": true,
                "defaultString": "Greece",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3672,
                "enabled": true,
                "defaultString": "Grenada",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3673,
                "enabled": true,
                "defaultString": "Guyana",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3674,
                "enabled": true,
                "defaultString": "Hong Kong",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3675,
                "enabled": true,
                "defaultString": "Hungary",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3676,
                "enabled": true,
                "defaultString": "India",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3677,
                "enabled": true,
                "defaultString": "Indonesia",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3678,
                "enabled": true,
                "defaultString": "Ireland",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 3679,
                "enabled": true,
                "defaultString": "Israel",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 3680,
                "enabled": true,
                "defaultString": "Italy",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 3681,
                "enabled": true,
                "defaultString": "Jamaica",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 3682,
                "enabled": true,
                "defaultString": "Japan",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 3683,
                "enabled": true,
                "defaultString": "Korea, Rep of",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 3684,
                "enabled": true,
                "defaultString": "Latvia",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 3685,
                "enabled": true,
                "defaultString": "Lithuania",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 3686,
                "enabled": true,
                "defaultString": "Luxembourg",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 3687,
                "enabled": true,
                "defaultString": "Malaysia",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 3688,
                "enabled": true,
                "defaultString": "Mexico",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 3689,
                "enabled": true,
                "defaultString": "Montserrat",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 3690,
                "enabled": true,
                "defaultString": "Netherlands",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 3691,
                "enabled": true,
                "defaultString": "Netherlands Antilles",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 3692,
                "enabled": true,
                "defaultString": "New Zealand",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 3693,
                "enabled": true,
                "defaultString": "Norway",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 3694,
                "enabled": true,
                "defaultString": "Pakistan",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 3695,
                "enabled": true,
                "defaultString": "Paraguay",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 3696,
                "enabled": true,
                "defaultString": "Peru",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 3697,
                "enabled": true,
                "defaultString": "Philippines",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 3698,
                "enabled": true,
                "defaultString": "Poland",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 3699,
                "enabled": true,
                "defaultString": "Portugal",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 3700,
                "enabled": true,
                "defaultString": "Romania",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 3701,
                "enabled": true,
                "defaultString": "Russian Fed",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 3702,
                "enabled": true,
                "defaultString": "Saint Kitts and Nevis",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 3703,
                "enabled": true,
                "defaultString": "Saint Lucia",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 3704,
                "enabled": true,
                "defaultString": "Saint Vincent",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 3705,
                "enabled": true,
                "defaultString": "Singapore",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 3706,
                "enabled": true,
                "defaultString": "Slovakia",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 3707,
                "enabled": true,
                "defaultString": "Slovenia",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 3708,
                "enabled": true,
                "defaultString": "South Africa",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 4290,
                "enabled": true,
                "defaultString": "Kenya",
                "longString": "",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 16106,
                "enabled": true,
                "defaultString": "Laos"
            },
            {
                "refListItemId": 16107,
                "enabled": true,
                "defaultString": "Iran"
            },
            {
                "refListItemId": 16108,
                "enabled": true,
                "defaultString": "Iraq"
            },
            {
                "refListItemId": 16111,
                "enabled": true,
                "defaultString": "Algeria"
            },
            {
                "refListItemId": 16112,
                "enabled": true,
                "defaultString": "Angola"
            },
            {
                "refListItemId": 16113,
                "enabled": true,
                "defaultString": "Botswana"
            },
            {
                "refListItemId": 16114,
                "enabled": true,
                "defaultString": "Chad"
            },
            {
                "refListItemId": 16115,
                "enabled": true,
                "defaultString": "Djibouti"
            },
            {
                "refListItemId": 16116,
                "enabled": true,
                "defaultString": "Ethiopia"
            },
            {
                "refListItemId": 16117,
                "enabled": true,
                "defaultString": "Ghana"
            },
            {
                "refListItemId": 16118,
                "enabled": true,
                "defaultString": "Guinea"
            },
            {
                "refListItemId": 16119,
                "enabled": true,
                "defaultString": "Liberia"
            },
            {
                "refListItemId": 16120,
                "enabled": true,
                "defaultString": "Libya"
            },
            {
                "refListItemId": 16121,
                "enabled": true,
                "defaultString": "Madagascar"
            },
            {
                "refListItemId": 16122,
                "enabled": true,
                "defaultString": "Malawi"
            },
            {
                "refListItemId": 16123,
                "enabled": true,
                "defaultString": "Morocco"
            },
            {
                "refListItemId": 16124,
                "enabled": true,
                "defaultString": "Mozambique"
            },
            {
                "refListItemId": 16125,
                "enabled": true,
                "defaultString": "Namibia"
            },
            {
                "refListItemId": 16126,
                "enabled": true,
                "defaultString": "Nigeria"
            },
            {
                "refListItemId": 16127,
                "enabled": true,
                "defaultString": "Rwanda"
            },
            {
                "refListItemId": 16128,
                "enabled": true,
                "defaultString": "Senegal"
            },
            {
                "refListItemId": 16129,
                "enabled": true,
                "defaultString": "Sierra Leone"
            },
            {
                "refListItemId": 16130,
                "enabled": true,
                "defaultString": "Sudan"
            },
            {
                "refListItemId": 16131,
                "enabled": true,
                "defaultString": "Tanzania"
            },
            {
                "refListItemId": 16132,
                "enabled": true,
                "defaultString": "Tunisia"
            },
            {
                "refListItemId": 16133,
                "enabled": true,
                "defaultString": "Uganda"
            },
            {
                "refListItemId": 16134,
                "enabled": true,
                "defaultString": "Zambia"
            },
            {
                "refListItemId": 16135,
                "enabled": true,
                "defaultString": "Zanzibar"
            },
            {
                "refListItemId": 16136,
                "enabled": true,
                "defaultString": "Zimbabwe"
            }
        ]
    },
    {
        "RefListID": 571,
        "refListName": "Shift",
        "refListItems": [
            {
                "refListItemId": 16191,
                "enabled": true,
                "defaultString": "First",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16192,
                "enabled": true,
                "defaultString": "Second",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16193,
                "enabled": true,
                "defaultString": "Third",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 73,
        "refListName": "CourtName",
        "refListItems": [
            {
                "refListItemId": 591,
                "enabled": true,
                "defaultString": "Federal",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 592,
                "enabled": true,
                "defaultString": "Juvenile",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 593,
                "enabled": true,
                "defaultString": "Magistrate",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 594,
                "enabled": true,
                "defaultString": "Municipal",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 595,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 596,
                "enabled": true,
                "defaultString": "Probate",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 597,
                "enabled": true,
                "defaultString": "State",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 598,
                "enabled": true,
                "defaultString": "Superior",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 24400,
                "enabled": true,
                "defaultString": "Circuit"
            }
        ]
    },
    {
        "RefListID": 74,
        "refListName": "CrimeSceneType",
        "refListItems": [
            {
                "refListItemId": 599,
                "enabled": true,
                "defaultString": "Arson",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 600,
                "enabled": true,
                "defaultString": "Assault",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 601,
                "enabled": true,
                "defaultString": "Auto Accident",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 602,
                "enabled": true,
                "defaultString": "Breaking and Entering",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 603,
                "enabled": true,
                "defaultString": "Burglary",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 604,
                "enabled": true,
                "defaultString": "Damage to Property",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 605,
                "enabled": true,
                "defaultString": "Domestic",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 606,
                "enabled": true,
                "defaultString": "Homicide",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 607,
                "enabled": true,
                "defaultString": "Robbery",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 608,
                "enabled": true,
                "defaultString": "Sexual Offense",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 75,
        "refListName": "Criminal_Complaint_Type",
        "refListItems": [
            {
                "refListItemId": 609,
                "enabled": true,
                "defaultString": "Local",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 610,
                "enabled": true,
                "defaultString": "NCIC",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 76,
        "refListName": "CriminalActivity",
        "refListItems": [
            {
                "refListItemId": 611,
                "enabled": true,
                "defaultString": "Buying/Receiving",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 612,
                "enabled": true,
                "defaultString": "Cultivating/Manufacturing/Publishing",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 613,
                "enabled": true,
                "defaultString": "Distributing/Selling",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 614,
                "enabled": true,
                "defaultString": "Exploiting Children",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 615,
                "enabled": true,
                "defaultString": "Juvenile Gang",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 616,
                "enabled": true,
                "defaultString": "No Gang",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 617,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 618,
                "enabled": true,
                "defaultString": "Operating/Promoting/Assisting",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 619,
                "enabled": true,
                "defaultString": "Other Gang",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 620,
                "enabled": true,
                "defaultString": "Possessing/Concealing",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 621,
                "enabled": true,
                "defaultString": "Transporting/Transmitting/Importing",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 622,
                "enabled": true,
                "defaultString": "Using/Consuming",
                "longString": "",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 77,
        "refListName": "CrimType",
        "refListItems": [
            {
                "refListItemId": 623,
                "enabled": true,
                "defaultString": "Assault",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 624,
                "enabled": true,
                "defaultString": "Theft",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 78,
        "refListName": "Damage_Severity",
        "refListItems": [
            {
                "refListItemId": 625,
                "enabled": true,
                "defaultString": "Disabled",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 626,
                "enabled": true,
                "defaultString": "Non Visible",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 627,
                "enabled": true,
                "defaultString": "Not Disabled",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 79,
        "refListName": "Damaged_Area",
        "refListItems": [
            {
                "refListItemId": 628,
                "enabled": true,
                "defaultString": "Attachment {11}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 629,
                "enabled": true,
                "defaultString": "Front {1}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 630,
                "enabled": true,
                "defaultString": "Left-Front {8}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 631,
                "enabled": true,
                "defaultString": "Left-Rear {6}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 632,
                "enabled": true,
                "defaultString": "Left-Side {7}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 633,
                "enabled": true,
                "defaultString": "NA {95}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 634,
                "enabled": true,
                "defaultString": "Rear {5}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 635,
                "enabled": true,
                "defaultString": "Right-Front {2}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 636,
                "enabled": true,
                "defaultString": "Right-Rear {4}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 637,
                "enabled": true,
                "defaultString": "Right-Side {3}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 638,
                "enabled": true,
                "defaultString": "Top {9}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 639,
                "enabled": true,
                "defaultString": "Under-Carriage {10}",
                "longString": "",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 80,
        "refListName": "Demographics_Type",
        "refListItems": [
            {
                "refListItemId": 640,
                "enabled": true,
                "defaultString": "Adults",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 641,
                "enabled": true,
                "defaultString": "Children over 12",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 642,
                "enabled": true,
                "defaultString": "Children under 12",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 643,
                "enabled": true,
                "defaultString": "Mixed",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 81,
        "refListName": "Denial_Reason",
        "refListItems": [
            {
                "refListItemId": 644,
                "enabled": true,
                "defaultString": "Can't pay fees",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 645,
                "enabled": true,
                "defaultString": "Failed tests",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 646,
                "enabled": true,
                "defaultString": "No cab company",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 647,
                "enabled": true,
                "defaultString": "Permit previously revoked",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 648,
                "enabled": true,
                "defaultString": "Underage",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 82,
        "refListName": "DenialReason",
        "refListItems": [
            {
                "refListItemId": 649,
                "enabled": true,
                "defaultString": "Can't pay fees",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 650,
                "enabled": true,
                "defaultString": "Danger to Public Safety",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 651,
                "enabled": true,
                "defaultString": "Dishonorable Discharge",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 652,
                "enabled": true,
                "defaultString": "Failed tests",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 653,
                "enabled": true,
                "defaultString": "False Info/ Application",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 654,
                "enabled": true,
                "defaultString": "Incomplete Paperwork",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 655,
                "enabled": true,
                "defaultString": "No cab company",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 656,
                "enabled": true,
                "defaultString": "Not County Resident",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 657,
                "enabled": true,
                "defaultString": "Not Recommended/State",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 658,
                "enabled": true,
                "defaultString": "Permit previously revoked",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 659,
                "enabled": true,
                "defaultString": "PIC Denial",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 660,
                "enabled": true,
                "defaultString": "Prev. Committed",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 661,
                "enabled": true,
                "defaultString": "Prev. Conviction",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 662,
                "enabled": true,
                "defaultString": "Probation/Parole",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 663,
                "enabled": true,
                "defaultString": "Underage",
                "longString": "",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 83,
        "refListName": "Deposit_Type",
        "refListItems": [
            {
                "refListItemId": 664,
                "enabled": true,
                "defaultString": "Cash",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 665,
                "enabled": true,
                "defaultString": "Check",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 666,
                "enabled": false,
                "defaultString": "Money Order",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5202,
                "enabled": false,
                "defaultString": "Mileage",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5201,
                "enabled": false,
                "defaultString": "Account Transfer",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 84,
        "refListName": "DepositType",
        "refListItems": [
            {
                "refListItemId": 667,
                "enabled": true,
                "defaultString": "Cash",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 668,
                "enabled": true,
                "defaultString": "Check",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 669,
                "enabled": false,
                "defaultString": "Money Order",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 85,
        "refListName": "Direction",
        "refListItems": [
            {
                "refListItemId": 670,
                "enabled": true,
                "defaultString": "East {E}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 671,
                "enabled": true,
                "defaultString": "North {N}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 672,
                "enabled": true,
                "defaultString": "Not On Road {A}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 673,
                "enabled": true,
                "defaultString": "South {S}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 674,
                "enabled": true,
                "defaultString": "Unknown {Unk}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 675,
                "enabled": true,
                "defaultString": "West {W}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 86,
        "refListName": "Disposal_Type",
        "refListItems": [
            {
                "refListItemId": 676,
                "enabled": true,
                "defaultString": "Auction",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 677,
                "enabled": true,
                "defaultString": "Destroy",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 678,
                "enabled": true,
                "defaultString": "Lost",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 679,
                "enabled": true,
                "defaultString": "Return to Owner",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 680,
                "enabled": true,
                "defaultString": "Transfer to Other Agency",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 87,
        "refListName": "DispositionStatus",
        "refListItems": [
            {
                "refListItemId": 681,
                "enabled": true,
                "defaultString": "Dismissed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 682,
                "enabled": true,
                "defaultString": "Failure to Appear",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 683,
                "enabled": true,
                "defaultString": "Fine Paid",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 684,
                "enabled": true,
                "defaultString": "Jail Time",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 685,
                "enabled": true,
                "defaultString": "Probation",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 686,
                "enabled": true,
                "defaultString": "Sent to State Court",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 88,
        "refListName": "Distance_From_Intersection_Unit",
        "refListItems": [
            {
                "refListItemId": 687,
                "enabled": true,
                "defaultString": "feet",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 688,
                "enabled": true,
                "defaultString": "miles",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 90,
        "refListName": "Division",
        "refListItems": [
            {
                "refListItemId": 693,
                "enabled": true,
                "defaultString": "Administration",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 694,
                "enabled": true,
                "defaultString": "CID",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 695,
                "enabled": true,
                "defaultString": "Jail",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 696,
                "enabled": true,
                "defaultString": "Patrol",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 697,
                "enabled": true,
                "defaultString": "Warrants",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 91,
        "refListName": "DL_Class",
        "refListItems": [
            {
                "refListItemId": 698,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 699,
                "enabled": true,
                "defaultString": "AM",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 700,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 701,
                "enabled": true,
                "defaultString": "BM",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 702,
                "enabled": true,
                "defaultString": "C1",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 703,
                "enabled": true,
                "defaultString": "C1M",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 704,
                "enabled": true,
                "defaultString": "C2",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 705,
                "enabled": true,
                "defaultString": "C2M",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 706,
                "enabled": true,
                "defaultString": "D",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 707,
                "enabled": true,
                "defaultString": "DM",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 708,
                "enabled": true,
                "defaultString": "N",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 92,
        "refListName": "DL_Status",
        "refListItems": [
            {
                "refListItemId": 709,
                "enabled": true,
                "defaultString": "Admin Disqual {A}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 710,
                "enabled": true,
                "defaultString": "Cancelled {X}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 711,
                "enabled": true,
                "defaultString": "Current/Valid {C}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 712,
                "enabled": true,
                "defaultString": "Denied {D}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 713,
                "enabled": true,
                "defaultString": "Expired {E}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 714,
                "enabled": true,
                "defaultString": "Fraudulent {F}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 715,
                "enabled": true,
                "defaultString": "Left State {L}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 716,
                "enabled": true,
                "defaultString": "NA/Unlicensed {N}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 717,
                "enabled": true,
                "defaultString": "Revoked {R}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 718,
                "enabled": true,
                "defaultString": "Suspended {S}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 719,
                "enabled": true,
                "defaultString": "Test Required {T}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 720,
                "enabled": true,
                "defaultString": "Unknown {U}",
                "longString": "",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 93,
        "refListName": "DLClass",
        "refListItems": [
            {
                "refListItemId": 721,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 722,
                "enabled": true,
                "defaultString": "AM",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 723,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 724,
                "enabled": true,
                "defaultString": "BM",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 725,
                "enabled": true,
                "defaultString": "C1",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 726,
                "enabled": true,
                "defaultString": "C1M",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 727,
                "enabled": true,
                "defaultString": "C2",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 728,
                "enabled": true,
                "defaultString": "C2M",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 729,
                "enabled": true,
                "defaultString": "D",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 730,
                "enabled": true,
                "defaultString": "DM",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 731,
                "enabled": true,
                "defaultString": "N",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 95,
        "refListName": "Drug_Measurement_Type",
        "refListItems": [
            {
                "refListItemId": 737,
                "enabled": true,
                "defaultString": "Dosage Units",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 738,
                "enabled": true,
                "defaultString": "Fluid Ounce",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 739,
                "enabled": true,
                "defaultString": "Gallon",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 740,
                "enabled": true,
                "defaultString": "Gram",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 741,
                "enabled": true,
                "defaultString": "Kilogram",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 742,
                "enabled": true,
                "defaultString": "Liter",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 743,
                "enabled": true,
                "defaultString": "Milliliter",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 744,
                "enabled": true,
                "defaultString": "Not Reported",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 745,
                "enabled": true,
                "defaultString": "Number of Plants",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 746,
                "enabled": true,
                "defaultString": "Ounce",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 747,
                "enabled": true,
                "defaultString": "Pound",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 96,
        "refListName": "Drug_Type",
        "refListItems": [
            {
                "refListItemId": 748,
                "enabled": true,
                "defaultString": "Alcohol",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 749,
                "enabled": true,
                "defaultString": "Amphetamine/Meth",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 750,
                "enabled": true,
                "defaultString": "Barbiturates",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 751,
                "enabled": true,
                "defaultString": "Cocaine",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 752,
                "enabled": true,
                "defaultString": "Crack Cocaine",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 753,
                "enabled": true,
                "defaultString": "Hashish",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 754,
                "enabled": true,
                "defaultString": "Heroin",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 755,
                "enabled": true,
                "defaultString": "LSD",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 756,
                "enabled": true,
                "defaultString": "Marijuana",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 757,
                "enabled": true,
                "defaultString": "Morphine",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 758,
                "enabled": true,
                "defaultString": "Opium",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 759,
                "enabled": true,
                "defaultString": "Other Depressants",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 760,
                "enabled": true,
                "defaultString": "Other Drugs",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 761,
                "enabled": true,
                "defaultString": "Other Hallucinogens",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 762,
                "enabled": true,
                "defaultString": "Other Narcotics",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 763,
                "enabled": true,
                "defaultString": "Other Stimulants",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 764,
                "enabled": true,
                "defaultString": "PCP",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 765,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 18
            }
        ]
    },
    {
        "RefListID": 97,
        "refListName": "DUITestType",
        "refListItems": [
            {
                "refListItemId": 766,
                "enabled": true,
                "defaultString": "Blood",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 767,
                "enabled": true,
                "defaultString": "Breath",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 768,
                "enabled": true,
                "defaultString": "No Test",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 769,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 770,
                "enabled": true,
                "defaultString": "Refused Test",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 771,
                "enabled": true,
                "defaultString": "Unable to Administer",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 772,
                "enabled": true,
                "defaultString": "Urine",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 98,
        "refListName": "Ejection",
        "refListItems": [
            {
                "refListItemId": 773,
                "enabled": true,
                "defaultString": "Fully {F}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 774,
                "enabled": true,
                "defaultString": "Not {N}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 775,
                "enabled": true,
                "defaultString": "Not Applicable {A}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 776,
                "enabled": true,
                "defaultString": "Partially {P}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 777,
                "enabled": true,
                "defaultString": "Trapped {T}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 778,
                "enabled": true,
                "defaultString": "Unknown {U}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 99,
        "refListName": "Endorsement_Code",
        "refListItems": [
            {
                "refListItemId": 779,
                "enabled": true,
                "defaultString": "Double/Triple {T}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 780,
                "enabled": true,
                "defaultString": "Hazardous Materials {H}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 781,
                "enabled": true,
                "defaultString": "Hazardous/Tank {X}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 782,
                "enabled": true,
                "defaultString": "Passenger {P}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 783,
                "enabled": true,
                "defaultString": "Tank Vehicle {N}",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 100,
        "refListName": "EPCase_Status",
        "refListItems": [
            {
                "refListItemId": 784,
                "enabled": true,
                "defaultString": "Closed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 785,
                "enabled": true,
                "defaultString": "Open",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 101,
        "refListName": "Ethnicity",
        "refListItems": [
            {
                "refListItemId": 786,
                "enabled": true,
                "defaultString": "H",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 787,
                "enabled": true,
                "defaultString": "N",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 102,
        "refListName": "Event_Location",
        "refListItems": [
            {
                "refListItemId": 788,
                "enabled": true,
                "defaultString": "Driveway {4}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 789,
                "enabled": true,
                "defaultString": "In Intersection {6}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 790,
                "enabled": true,
                "defaultString": "Median {3}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 791,
                "enabled": true,
                "defaultString": "Off Roadway {2}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 792,
                "enabled": true,
                "defaultString": "On Roadway {1}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 793,
                "enabled": true,
                "defaultString": "Private Road/Property {5}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 103,
        "refListName": "Evidence_Technician",
        "refListItems": "NULL"
    },
    {
        "RefListID": 104,
        "refListName": "EvidenceTechnician",
        "refListItems": [
            {
                "refListItemId": 794,
                "enabled": true,
                "defaultString": "Cater (116)",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 795,
                "enabled": true,
                "defaultString": "Ericcson (114)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 796,
                "enabled": true,
                "defaultString": "Hepburn (115)",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 797,
                "enabled": true,
                "defaultString": "Lyons (112)",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 798,
                "enabled": true,
                "defaultString": "Martin (117)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 799,
                "enabled": true,
                "defaultString": "Smith (113)",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 105,
        "refListName": "Extradition_Area",
        "refListItems": [
            {
                "refListItemId": 5528,
                "enabled": true,
                "defaultString": "Limited (See MIS)",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 801,
                "enabled": false,
                "defaultString": "MN Only",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 802,
                "enabled": false,
                "defaultString": "Tri-State",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5529,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4214,
                "enabled": true,
                "defaultString": "Full",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4215,
                "enabled": true,
                "defaultString": "No Extradition",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 106,
        "refListName": "Eyes",
        "refListItems": [
            {
                "refListItemId": 804,
                "enabled": false,
                "defaultString": "ALB",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 805,
                "enabled": true,
                "defaultString": "BLK",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 806,
                "enabled": true,
                "defaultString": "BLU",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 807,
                "enabled": true,
                "defaultString": "BRO",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 808,
                "enabled": true,
                "defaultString": "GRN",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 809,
                "enabled": true,
                "defaultString": "GRY",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 810,
                "enabled": true,
                "defaultString": "HAZ",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 811,
                "enabled": true,
                "defaultString": "MAR",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 812,
                "enabled": true,
                "defaultString": "PNK",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 813,
                "enabled": true,
                "defaultString": "XXX",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4934,
                "enabled": true,
                "defaultString": "MUL",
                "longString": "Multicolored",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 108,
        "refListName": "Filing_Party_Type",
        "refListItems": [
            {
                "refListItemId": 819,
                "enabled": true,
                "defaultString": "Attorney",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 820,
                "enabled": true,
                "defaultString": "Pro Se",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 821,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 109,
        "refListName": "First_Aid_By",
        "refListItems": [
            {
                "refListItemId": 822,
                "enabled": true,
                "defaultString": "Ambulance Attended {A}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 823,
                "enabled": true,
                "defaultString": "Doctor {D}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 824,
                "enabled": true,
                "defaultString": "None {N}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 825,
                "enabled": true,
                "defaultString": "Other {O}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 826,
                "enabled": true,
                "defaultString": "Paramedic {M}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 827,
                "enabled": true,
                "defaultString": "Police {P}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 828,
                "enabled": true,
                "defaultString": "Unknown {U}",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 110,
        "refListName": "Gang_Activity",
        "refListItems": [
            {
                "refListItemId": 829,
                "enabled": true,
                "defaultString": "Juvenile Gang",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 830,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 831,
                "enabled": true,
                "defaultString": "Other Gang",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 111,
        "refListName": "Gang_Classification",
        "refListItems": [
            {
                "refListItemId": 832,
                "enabled": true,
                "defaultString": "Confirmed Gang Member",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 833,
                "enabled": true,
                "defaultString": "No Suspicion of Gang Membership",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 834,
                "enabled": true,
                "defaultString": "Possible Gang Member",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 112,
        "refListName": "Gang_Name",
        "refListItems": [
            {
                "refListItemId": 835,
                "enabled": true,
                "defaultString": "Bloods",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 836,
                "enabled": true,
                "defaultString": "Crips",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 837,
                "enabled": true,
                "defaultString": "Italian Mafia",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 838,
                "enabled": true,
                "defaultString": "Mexican Mafia",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 839,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 113,
        "refListName": "GangQuestions",
        "refListItems": [
            {
                "refListItemId": 840,
                "enabled": true,
                "defaultString": "01|Wearing gang colors, gang clothing, gang paraphernalia?|1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 841,
                "enabled": true,
                "defaultString": "02|Possession of commerical gang related publications?|1",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 842,
                "enabled": true,
                "defaultString": "03|Known contact with confirmed gang members?|1",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 843,
                "enabled": true,
                "defaultString": "04|Consistent observed contact with confirmed gang members?|2",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 844,
                "enabled": true,
                "defaultString": "05|Particpiating in photo with confirmed gang member?|2",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 845,
                "enabled": true,
                "defaultString": "06|Use of hand signs, possession/use of sumbols, logos, graffiti that clearly indicate gang affilation?|3",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 846,
                "enabled": true,
                "defaultString": "07|Possession of gang documents - roster, procedures, by-laws, etc?|3",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 847,
                "enabled": true,
                "defaultString": "08|Sending/receiving correspondence to/from confirmed gang members?|3",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 848,
                "enabled": true,
                "defaultString": "09|Outside jurisdiction information/documents?|5",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 849,
                "enabled": true,
                "defaultString": "10|Subject's victims or targets of crime are members of a rival gang?|5",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 850,
                "enabled": true,
                "defaultString": "11|Gang tattoos/brands?|8",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 851,
                "enabled": true,
                "defaultString": "12|Participation in gang publications - submitting articles, illustrations, etc.?|8",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 852,
                "enabled": true,
                "defaultString": "13|Named a gang member in correspondence?|8",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 853,
                "enabled": true,
                "defaultString": "14|Subject identified as a gang member by another gang member?|8",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 854,
                "enabled": true,
                "defaultString": "15|Subject involved in criminal gang incidents?|8",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 855,
                "enabled": true,
                "defaultString": "16|Subject's name appears on a gang roster, hit list, or gang related graffiti?|8",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 856,
                "enabled": true,
                "defaultString": "17|Self admission?|9",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 857,
                "enabled": true,
                "defaultString": "18|Subject identified as a gang member by a reliable informant?|9",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 858,
                "enabled": true,
                "defaultString": "19|Confirmation through outside agency gang unit?|10",
                "longString": "",
                "sequenceNumber": 19
            }
        ]
    },
    {
        "RefListID": 114,
        "refListName": "Gender",
        "refListItems": [
            {
                "refListItemId": 859,
                "enabled": true,
                "defaultString": "Both",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 860,
                "enabled": true,
                "defaultString": "Female",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 861,
                "enabled": true,
                "defaultString": "Male",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 116,
        "refListName": "Gun_Color",
        "refListItems": [
            {
                "refListItemId": 865,
                "enabled": true,
                "defaultString": "Black",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 866,
                "enabled": true,
                "defaultString": "Blue Steel",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 867,
                "enabled": true,
                "defaultString": "Gun Metal Gray",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 868,
                "enabled": true,
                "defaultString": "Nickel-Plated",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 869,
                "enabled": true,
                "defaultString": "Silver",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 117,
        "refListName": "Gun_Style",
        "refListItems": [
            {
                "refListItemId": 870,
                "enabled": true,
                "defaultString": "Air Powered",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 871,
                "enabled": true,
                "defaultString": "Bolt Action",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 872,
                "enabled": true,
                "defaultString": "Full-Automatic",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 873,
                "enabled": true,
                "defaultString": "Lever",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 874,
                "enabled": true,
                "defaultString": "Pump",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 875,
                "enabled": true,
                "defaultString": "Revolver",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 876,
                "enabled": true,
                "defaultString": "Semi-Automatic",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 118,
        "refListName": "Gun_Type",
        "refListItems": [
            {
                "refListItemId": 877,
                "enabled": true,
                "defaultString": "Handgun",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 878,
                "enabled": true,
                "defaultString": "Rifle",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 879,
                "enabled": true,
                "defaultString": "Shotgun",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 119,
        "refListName": "GunPermitStatus",
        "refListItems": [
            {
                "refListItemId": 880,
                "enabled": true,
                "defaultString": "Approved",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 881,
                "enabled": true,
                "defaultString": "Deceased",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 882,
                "enabled": true,
                "defaultString": "Denied",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 883,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 884,
                "enabled": true,
                "defaultString": "Revoked",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 120,
        "refListName": "Hair",
        "refListItems": [
            {
                "refListItemId": 885,
                "enabled": true,
                "defaultString": "BLK",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 886,
                "enabled": true,
                "defaultString": "BLN",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 887,
                "enabled": true,
                "defaultString": "BRO",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 888,
                "enabled": true,
                "defaultString": "GRY",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 889,
                "enabled": true,
                "defaultString": "RED",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 890,
                "enabled": true,
                "defaultString": "SDY",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 891,
                "enabled": true,
                "defaultString": "WHI",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 892,
                "enabled": true,
                "defaultString": "XXX",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3741,
                "enabled": false,
                "defaultString": "BLD",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3742,
                "enabled": false,
                "defaultString": "BLU",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3743,
                "enabled": false,
                "defaultString": "GRN",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3744,
                "enabled": false,
                "defaultString": "ONG",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3745,
                "enabled": false,
                "defaultString": "PNK",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3746,
                "enabled": false,
                "defaultString": "PLE",
                "longString": "",
                "sequenceNumber": 14
            }
        ]
    },
    {
        "RefListID": 122,
        "refListName": "Hazardous_Cargo",
        "refListItems": [
            {
                "refListItemId": 897,
                "enabled": true,
                "defaultString": "Corrosive Material {9}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 898,
                "enabled": true,
                "defaultString": "Explosive {2}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 899,
                "enabled": true,
                "defaultString": "Flammable Solids {5}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 900,
                "enabled": true,
                "defaultString": "Flammable/Combustible Liquid {4}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 901,
                "enabled": true,
                "defaultString": "Gas {3}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 902,
                "enabled": true,
                "defaultString": "None {1}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 903,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 904,
                "enabled": true,
                "defaultString": "Oxidizer/Peroxide {6}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 905,
                "enabled": true,
                "defaultString": "Poison {7}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 906,
                "enabled": true,
                "defaultString": "Radioactive Material {8}",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 123,
        "refListName": "Hazardous_Type",
        "refListItems": [
            {
                "refListItemId": 907,
                "enabled": true,
                "defaultString": "Acid",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 908,
                "enabled": true,
                "defaultString": "Explosive",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 909,
                "enabled": true,
                "defaultString": "Flammable",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 124,
        "refListName": "Highway_Classification",
        "refListItems": [
            {
                "refListItemId": 910,
                "enabled": true,
                "defaultString": "County {C}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 911,
                "enabled": true,
                "defaultString": "Federal {F}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 912,
                "enabled": true,
                "defaultString": "Interstate {I}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 913,
                "enabled": true,
                "defaultString": "Municipal {M}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 914,
                "enabled": true,
                "defaultString": "Other {O}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 915,
                "enabled": true,
                "defaultString": "Private Property {P}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 916,
                "enabled": true,
                "defaultString": "State {S}",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 125,
        "refListName": "Hold_Status",
        "refListItems": [
            {
                "refListItemId": 917,
                "enabled": true,
                "defaultString": "Cleared",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 918,
                "enabled": true,
                "defaultString": "Hold for Transfer",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 919,
                "enabled": true,
                "defaultString": "Not Cleared",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 126,
        "refListName": "Housed",
        "refListItems": [
            {
                "refListItemId": 920,
                "enabled": true,
                "defaultString": "A BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 921,
                "enabled": true,
                "defaultString": "B BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 922,
                "enabled": true,
                "defaultString": "BOOK & REL",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 923,
                "enabled": true,
                "defaultString": "C BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 924,
                "enabled": true,
                "defaultString": "D BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 925,
                "enabled": true,
                "defaultString": "E BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 926,
                "enabled": true,
                "defaultString": "F BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 927,
                "enabled": true,
                "defaultString": "G BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 928,
                "enabled": true,
                "defaultString": "H BLOCK (IN)",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 929,
                "enabled": true,
                "defaultString": "HOLD 137 (IN)",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 930,
                "enabled": true,
                "defaultString": "HOLD 140 (IN)",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 931,
                "enabled": true,
                "defaultString": "HOLD 141 (IN)",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 932,
                "enabled": true,
                "defaultString": "HOLD 147 (IN)",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 933,
                "enabled": true,
                "defaultString": "HOLD 148 (IN)",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 934,
                "enabled": true,
                "defaultString": "ISOL 413 (IN)",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 935,
                "enabled": true,
                "defaultString": "ISOL 415 (IN)",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 936,
                "enabled": true,
                "defaultString": "ISOL 418 (IN)",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 937,
                "enabled": true,
                "defaultString": "ISOL 423 (IN)",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 938,
                "enabled": true,
                "defaultString": "ISOL 425 (IN)",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 939,
                "enabled": true,
                "defaultString": "LOAN (IN)",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 940,
                "enabled": true,
                "defaultString": "RELEASED",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 941,
                "enabled": true,
                "defaultString": "TRUSTEE (IN)",
                "longString": "",
                "sequenceNumber": 22
            }
        ]
    },
    {
        "RefListID": 403,
        "refListName": "Image_Type",
        "refListItems": [
            {
                "refListItemId": 4181,
                "enabled": true,
                "defaultString": "Incident Report Back",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 4158,
                "enabled": true,
                "defaultString": "Fingerprint-Intake",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4159,
                "enabled": true,
                "defaultString": "Mark",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4160,
                "enabled": true,
                "defaultString": "Mug-Front",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4161,
                "enabled": true,
                "defaultString": "Mug-Other",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4162,
                "enabled": true,
                "defaultString": "Mug-Side",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4163,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4164,
                "enabled": true,
                "defaultString": "Scar",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4165,
                "enabled": true,
                "defaultString": "Tattoo",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4166,
                "enabled": true,
                "defaultString": "Injury",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4167,
                "enabled": true,
                "defaultString": "Accident-Report-Front",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4168,
                "enabled": true,
                "defaultString": "Accident-Report-Back",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4169,
                "enabled": true,
                "defaultString": "Fingerprint-Release",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 4170,
                "enabled": true,
                "defaultString": "Mug-Release",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4171,
                "enabled": true,
                "defaultString": "Arrest Report",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4172,
                "enabled": true,
                "defaultString": "Court Paperwork",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4173,
                "enabled": true,
                "defaultString": "Crime Scene Photo",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4174,
                "enabled": true,
                "defaultString": "Evidence Photo",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4175,
                "enabled": true,
                "defaultString": "Doctor's Order",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4176,
                "enabled": true,
                "defaultString": "Prescription",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4177,
                "enabled": true,
                "defaultString": "Medical History",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4178,
                "enabled": true,
                "defaultString": "Vehicle Photo",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 4179,
                "enabled": true,
                "defaultString": "Accident Scene Photo",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 4180,
                "enabled": true,
                "defaultString": "Incident Report Front",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 4182,
                "enabled": true,
                "defaultString": "Initial Property Photo",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 4183,
                "enabled": true,
                "defaultString": "Final Property Photo",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 24960,
                "enabled": true,
                "defaultString": "Jail Incident Photo",
                "sequenceNumber": 27
            }
        ]
    },
    {
        "RefListID": 129,
        "refListName": "ICRPropertyType",
        "refListItems": [
            {
                "refListItemId": 950,
                "enabled": true,
                "defaultString": "Clothing, Furs",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 951,
                "enabled": true,
                "defaultString": "Consumables",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 952,
                "enabled": true,
                "defaultString": "Currency, Notes, etc,",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 953,
                "enabled": true,
                "defaultString": "Firearms",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 954,
                "enabled": true,
                "defaultString": "Household Goods",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 955,
                "enabled": true,
                "defaultString": "Jewelry, Prec. Metals",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 956,
                "enabled": true,
                "defaultString": "Livestock",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 957,
                "enabled": true,
                "defaultString": "Miscellaneous",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 958,
                "enabled": true,
                "defaultString": "Office Equipment",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 959,
                "enabled": true,
                "defaultString": "Stolen Vehicle-Local",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 960,
                "enabled": true,
                "defaultString": "TVs, Radios, Etc.",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 130,
        "refListName": "Image_Loc",
        "refListItems": [
            {
                "refListItemId": 961,
                "enabled": true,
                "defaultString": "Disc Abdom",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 962,
                "enabled": true,
                "defaultString": "Disc Back",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 963,
                "enabled": true,
                "defaultString": "Disc Buttk",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 964,
                "enabled": true,
                "defaultString": "Disc Chest",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 965,
                "enabled": true,
                "defaultString": "Disc Groin",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 966,
                "enabled": true,
                "defaultString": "Disc Head",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 967,
                "enabled": true,
                "defaultString": "Disc L Arm",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 968,
                "enabled": true,
                "defaultString": "Disc L Ft",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 969,
                "enabled": true,
                "defaultString": "Disc L Hnd",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 970,
                "enabled": true,
                "defaultString": "Disc L Leg",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 971,
                "enabled": true,
                "defaultString": "Disc L Wrs",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 972,
                "enabled": true,
                "defaultString": "Disc R Arm",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 973,
                "enabled": true,
                "defaultString": "Disc R Ft",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 974,
                "enabled": true,
                "defaultString": "Disc R Hnd",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 975,
                "enabled": true,
                "defaultString": "Disc R Leg",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 976,
                "enabled": true,
                "defaultString": "Disc R Wrs",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 977,
                "enabled": true,
                "defaultString": "SC EAR",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 978,
                "enabled": true,
                "defaultString": "SC ELBOW",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 979,
                "enabled": true,
                "defaultString": "SC EYE",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 980,
                "enabled": true,
                "defaultString": "SC FACE",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 981,
                "enabled": true,
                "defaultString": "SC FGR",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 982,
                "enabled": true,
                "defaultString": "SC HAND",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 983,
                "enabled": true,
                "defaultString": "SC HEAD",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 984,
                "enabled": true,
                "defaultString": "SC KNEE",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 985,
                "enabled": true,
                "defaultString": "SC SHLD",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 986,
                "enabled": true,
                "defaultString": "SC THIGH",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 987,
                "enabled": true,
                "defaultString": "SC UP LIP",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 988,
                "enabled": true,
                "defaultString": "SC WRIST",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 989,
                "enabled": true,
                "defaultString": "SC-L-BACK",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 990,
                "enabled": true,
                "defaultString": "SC-L-CHEST",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 991,
                "enabled": true,
                "defaultString": "SC-L-FACE",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 992,
                "enabled": true,
                "defaultString": "SC-L-FOOT",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 993,
                "enabled": true,
                "defaultString": "SC-L-LARM",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 994,
                "enabled": true,
                "defaultString": "SC-L-LLEG",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 995,
                "enabled": true,
                "defaultString": "SC-L-NECK",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 996,
                "enabled": true,
                "defaultString": "SC-L-RARM",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 997,
                "enabled": true,
                "defaultString": "SC-L-RLEG",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 998,
                "enabled": true,
                "defaultString": "SC-L-STOM",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 999,
                "enabled": true,
                "defaultString": "SC-S-BACK",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 1000,
                "enabled": true,
                "defaultString": "SC-S-CHEST",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 1001,
                "enabled": true,
                "defaultString": "SC-S-FACE",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 1002,
                "enabled": true,
                "defaultString": "SC-S-FOOT",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 1003,
                "enabled": true,
                "defaultString": "SC-S-LARM",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 1004,
                "enabled": true,
                "defaultString": "SC-S-LLEG",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 1005,
                "enabled": true,
                "defaultString": "SC-S-NECK",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 1006,
                "enabled": true,
                "defaultString": "SC-S-RARM",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 1007,
                "enabled": true,
                "defaultString": "SC-S-RLEG",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 1008,
                "enabled": true,
                "defaultString": "SC-S-STOM",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 1009,
                "enabled": true,
                "defaultString": "TAT FACE",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 1010,
                "enabled": true,
                "defaultString": "TAT GROIN",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 1011,
                "enabled": true,
                "defaultString": "TAT HEAD",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 1012,
                "enabled": true,
                "defaultString": "TAT UP ARM",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 1013,
                "enabled": true,
                "defaultString": "TAT UR ARM",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 1014,
                "enabled": true,
                "defaultString": "TAT-BACK",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 1015,
                "enabled": true,
                "defaultString": "TAT-BUTT",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 1016,
                "enabled": true,
                "defaultString": "TAT-CHEST",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 1017,
                "enabled": true,
                "defaultString": "TAT-FACE",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 1018,
                "enabled": true,
                "defaultString": "TAT-L-ARM",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 1019,
                "enabled": true,
                "defaultString": "TAT-L-FOOT",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 1020,
                "enabled": true,
                "defaultString": "TAT-L-HAND",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 1021,
                "enabled": true,
                "defaultString": "TAT-L-LEG",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 1022,
                "enabled": true,
                "defaultString": "TAT-R-ARM",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 1023,
                "enabled": true,
                "defaultString": "TAT-R-FOOT",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 1024,
                "enabled": true,
                "defaultString": "TAT-R-HAND",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 1025,
                "enabled": true,
                "defaultString": "TAT-R-LEG",
                "longString": "",
                "sequenceNumber": 65
            }
        ]
    },
    {
        "RefListID": 404,
        "refListName": "Classifications",
        "refListItems": [
            {
                "refListItemId": 4184,
                "enabled": true,
                "defaultString": "Maximum",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4185,
                "enabled": true,
                "defaultString": "Minimum",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4186,
                "enabled": true,
                "defaultString": "Medium",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 132,
        "refListName": "IncidentType",
        "refListItems": [
            {
                "refListItemId": 1034,
                "enabled": true,
                "defaultString": "Abandoned vehicle {S30}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1035,
                "enabled": true,
                "defaultString": "Accident - No investigation {S10}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1036,
                "enabled": true,
                "defaultString": "Accident- Fatal (Private) {S16}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1037,
                "enabled": true,
                "defaultString": "Accident- Fatal {S13}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1038,
                "enabled": true,
                "defaultString": "Accident- Injury ( Private) {S15}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1039,
                "enabled": true,
                "defaultString": "Accident- Injury {S12}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1040,
                "enabled": true,
                "defaultString": "Accident- No Injury (Private) {S14}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1041,
                "enabled": true,
                "defaultString": "Accident- No Injury {S11}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1042,
                "enabled": true,
                "defaultString": "Alarm Activation/Drop {S31}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1043,
                "enabled": true,
                "defaultString": "All other crimes {26BB}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1044,
                "enabled": true,
                "defaultString": "Arson-all other struct inhab {09G1}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1045,
                "enabled": true,
                "defaultString": "Arson-all other struct uninhab {09G2}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1046,
                "enabled": true,
                "defaultString": "Arson-comml (store,etc)inh {09E1}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1047,
                "enabled": true,
                "defaultString": "Arson-comml (store,etc)uninh {09E2}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1048,
                "enabled": true,
                "defaultString": "Arson-commun (church,etc) uninh {09F2}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1049,
                "enabled": true,
                "defaultString": "Arson-commun (church,etc)inh {09F1}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1050,
                "enabled": true,
                "defaultString": "Arson-indust,etc inhab {09D1}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1051,
                "enabled": true,
                "defaultString": "Arson-indust,etc uninhab {09D2}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1052,
                "enabled": true,
                "defaultString": "Arson-motor veh inhab {09H1}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1053,
                "enabled": true,
                "defaultString": "Arson-motor veh uninhab {09H2}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1054,
                "enabled": true,
                "defaultString": "Arson-other (crops, timber, etc) {09J2}",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1055,
                "enabled": true,
                "defaultString": "Arson-other mobile inhab {09I1}",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1056,
                "enabled": true,
                "defaultString": "Arson-other mobile uninhab {09I2}",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 1057,
                "enabled": true,
                "defaultString": "Arson-other residence inhab {09B1}",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 1058,
                "enabled": true,
                "defaultString": "Arson-other residence uninhab {09B2}",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 1059,
                "enabled": true,
                "defaultString": "Arson-sing occ house inhab {09A1}",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 1060,
                "enabled": true,
                "defaultString": "Arson-sing occ house uninhab {09A2}",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 1061,
                "enabled": true,
                "defaultString": "Arson-storage/oth res inhab {09C1}",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 1062,
                "enabled": true,
                "defaultString": "Arson-storage/oth res uninhab {09C2}",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 1063,
                "enabled": true,
                "defaultString": "Assault - hands, fists, feet, etc.{04D}",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 1064,
                "enabled": true,
                "defaultString": "Assault ofcr-firearm {04A1}",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 1065,
                "enabled": true,
                "defaultString": "Assault ofcr-hands, fists, etc {04D1}",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 1066,
                "enabled": true,
                "defaultString": "Assault ofcr-knife/cutting {04B1}",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 1067,
                "enabled": true,
                "defaultString": "Assault ofcr-oth asslt/not aggr inc harrass {04E1}",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 1068,
                "enabled": true,
                "defaultString": "Assault ofcr-oth dang/weapon {04C1}",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 1069,
                "enabled": true,
                "defaultString": "Assault to rape/attempt {02B}",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 1070,
                "enabled": true,
                "defaultString": "Assault-firearm {04A}",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 1071,
                "enabled": true,
                "defaultString": "Assault-knife/cutting {04B}",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 1072,
                "enabled": true,
                "defaultString": "Assault-oth asslt/not aggr inc harrass {04E}",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 1073,
                "enabled": true,
                "defaultString": "Assault-oth dang/weapon {04C}",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 1074,
                "enabled": true,
                "defaultString": "Assist from DA {S33}",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 1075,
                "enabled": true,
                "defaultString": "Assist from FD - Farrell {S32}",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 1076,
                "enabled": true,
                "defaultString": "Assist from FD - Hermitage {S32}",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 1077,
                "enabled": true,
                "defaultString": "Assist from FD - other {S32}",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 1078,
                "enabled": true,
                "defaultString": "Assist from FD - Sharon {S32}",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 1079,
                "enabled": true,
                "defaultString": "Assist from FD - Shenango {S32}",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 1080,
                "enabled": true,
                "defaultString": "Assist from FD - Wheatland {S32}",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 1081,
                "enabled": true,
                "defaultString": "Assist from FD - WMS {S32}",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 1082,
                "enabled": true,
                "defaultString": "Assist from Federal {S33}",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 1083,
                "enabled": true,
                "defaultString": "Assist from Hermitage {S33}",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 1084,
                "enabled": true,
                "defaultString": "Assist from Mental Health {S32}",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 1085,
                "enabled": true,
                "defaultString": "Assist from other (law enf) {S33}",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 1086,
                "enabled": true,
                "defaultString": "Assist from other (non law enf) {S32}",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 1087,
                "enabled": true,
                "defaultString": "Assist from Parole & Probation {S33}",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 1088,
                "enabled": true,
                "defaultString": "Assist from PSP {S33}",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 1089,
                "enabled": true,
                "defaultString": "Assist from Sharon {S33}",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 1090,
                "enabled": true,
                "defaultString": "Assist from Sharpsville {S33}",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 1091,
                "enabled": true,
                "defaultString": "Assist from Shenango {S33}",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 1092,
                "enabled": true,
                "defaultString": "Assist from Sheriff {S33}",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 1093,
                "enabled": true,
                "defaultString": "Assist to Ambulance {S32}",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 1094,
                "enabled": true,
                "defaultString": "Assist to DA {S33}",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 1095,
                "enabled": true,
                "defaultString": "Assist to FD - Farrell {S32}",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 1096,
                "enabled": true,
                "defaultString": "Assist to FD - Hermitage {S32}",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 1097,
                "enabled": true,
                "defaultString": "Assist to FD - other {S32}",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 1098,
                "enabled": true,
                "defaultString": "Assist to FD - Sharon {S32}",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 1099,
                "enabled": true,
                "defaultString": "Assist to FD - Shenango {S32}",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 1100,
                "enabled": true,
                "defaultString": "Assist to FD - Wheatland {S32}",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 1101,
                "enabled": true,
                "defaultString": "Assist to FD - WMS {S32}",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 1102,
                "enabled": true,
                "defaultString": "Assist to Federal {S33}",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 1103,
                "enabled": true,
                "defaultString": "Assist to Hermitage {S33}",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 1104,
                "enabled": true,
                "defaultString": "Assist to Mental Health {S32}",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 1105,
                "enabled": true,
                "defaultString": "Assist to other (law enf) {S33}",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 1106,
                "enabled": true,
                "defaultString": "Assist to other (non law enf) {S32}",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 1107,
                "enabled": true,
                "defaultString": "Assist to Parole & Probation {S33}",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 1108,
                "enabled": true,
                "defaultString": "Assist to PSP {S33}",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 1109,
                "enabled": true,
                "defaultString": "Assist to Sharon {S33}",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 1110,
                "enabled": true,
                "defaultString": "Assist to Sharpsville {S33}",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 1111,
                "enabled": true,
                "defaultString": "Assist to Shenango {S33}",
                "longString": "",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 1112,
                "enabled": true,
                "defaultString": "Assist to Sheriff {S33}",
                "longString": "",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 1113,
                "enabled": true,
                "defaultString": "Attempt burglary-non res/day {05C5}",
                "longString": "",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 1114,
                "enabled": true,
                "defaultString": "Attempt burglary-non res/night {05C4}",
                "longString": "",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 1115,
                "enabled": true,
                "defaultString": "Attempt burglary-non res/time unk {05C6}",
                "longString": "",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 1116,
                "enabled": true,
                "defaultString": "Attempt burglary-res/day {05C2}",
                "longString": "",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 1117,
                "enabled": true,
                "defaultString": "Attempt burglary-res/night {05C1}",
                "longString": "",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 1118,
                "enabled": true,
                "defaultString": "Attempt burglary-res/time unk {05C3}",
                "longString": "",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 1119,
                "enabled": true,
                "defaultString": "Bribery {26A}",
                "longString": "",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 1120,
                "enabled": true,
                "defaultString": "Burglary-force-non res/day {05A5}",
                "longString": "",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 1121,
                "enabled": true,
                "defaultString": "Burglary-force-non res/night {05A4}",
                "longString": "",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 1122,
                "enabled": true,
                "defaultString": "Burglary-force-non res/time unk {05A6}",
                "longString": "",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 1123,
                "enabled": true,
                "defaultString": "Burglary-force-res/day {05A2}",
                "longString": "",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 1124,
                "enabled": true,
                "defaultString": "Burglary-force-res/night {05A1}",
                "longString": "",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 1125,
                "enabled": true,
                "defaultString": "Burglary-force-res/time unk {05A3}",
                "longString": "",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 1126,
                "enabled": true,
                "defaultString": "Burglary-no force-non res/day {05B5}",
                "longString": "",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 1127,
                "enabled": true,
                "defaultString": "Burglary-no force-non res/night {05B4}",
                "longString": "",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 1128,
                "enabled": true,
                "defaultString": "Burglary-no force-non res/time unk {05B6}",
                "longString": "",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 1129,
                "enabled": true,
                "defaultString": "Burglary-no force-res/day {05B2}",
                "longString": "",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 1130,
                "enabled": true,
                "defaultString": "Burglary-no force-res/night {05B1}",
                "longString": "",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 1131,
                "enabled": true,
                "defaultString": "Burglary-no force-res/time unk {05B3}",
                "longString": "",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 1132,
                "enabled": true,
                "defaultString": "Causing or risking catastrophe {26B}",
                "longString": "",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 1133,
                "enabled": true,
                "defaultString": "Civil Complaint {S34}",
                "longString": "",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 1134,
                "enabled": true,
                "defaultString": "Contempt of court {26C}",
                "longString": "",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 1135,
                "enabled": true,
                "defaultString": "Corruption of minors {26D}",
                "longString": "",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 1136,
                "enabled": true,
                "defaultString": "Criminal coercion {26E}",
                "longString": "",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 1137,
                "enabled": true,
                "defaultString": "Criminal trespassing {26F}",
                "longString": "",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 1138,
                "enabled": true,
                "defaultString": "Cruelty to animals {26G}",
                "longString": "",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 1139,
                "enabled": true,
                "defaultString": "Curfew {28}",
                "longString": "",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 1140,
                "enabled": true,
                "defaultString": "Disabled Vehicle {S35}",
                "longString": "",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 1141,
                "enabled": true,
                "defaultString": "Disorderly conduct {24A}",
                "longString": "",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 1142,
                "enabled": true,
                "defaultString": "Disposition Not Rprtd By Ofcr {DNR}",
                "longString": "",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 1143,
                "enabled": true,
                "defaultString": "Dog laws {26H}",
                "longString": "",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 1144,
                "enabled": true,
                "defaultString": "Domestic {S36}",
                "longString": "",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 1145,
                "enabled": true,
                "defaultString": "Driving under the influence {21}",
                "longString": "",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 1146,
                "enabled": true,
                "defaultString": "Drug-marijuana (possess) {18F}",
                "longString": "",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 1147,
                "enabled": true,
                "defaultString": "Drug-marijuana (sale/manuf) {18B}",
                "longString": "",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 1148,
                "enabled": true,
                "defaultString": "Drug-opium/cocaine (possess) {18E}",
                "longString": "",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 1149,
                "enabled": true,
                "defaultString": "Drug-opium/cocaine (sale/manuf) {18A}",
                "longString": "",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 1150,
                "enabled": true,
                "defaultString": "Drug-other (possess) {18H}",
                "longString": "",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 1151,
                "enabled": true,
                "defaultString": "Drug-other (sale/manuf) {18D}",
                "longString": "",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 1152,
                "enabled": true,
                "defaultString": "Drug-synthetic (possess) {18G}",
                "longString": "",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 1153,
                "enabled": true,
                "defaultString": "Drug-synthetic (sale/manuf) {18C}",
                "longString": "",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 1154,
                "enabled": true,
                "defaultString": "Embezzlement {12}",
                "longString": "",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 1155,
                "enabled": true,
                "defaultString": "Escape {26I}",
                "longString": "",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 1156,
                "enabled": true,
                "defaultString": "Escort/ Money {S37}",
                "longString": "",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 1157,
                "enabled": true,
                "defaultString": "Escort/Other {S38}",
                "longString": "",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 1158,
                "enabled": true,
                "defaultString": "Extortion {26J}",
                "longString": "",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 1159,
                "enabled": true,
                "defaultString": "False Alarms {24C}",
                "longString": "",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 1160,
                "enabled": true,
                "defaultString": "False imprisonment {26K}",
                "longString": "",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 1161,
                "enabled": true,
                "defaultString": "False reports to law enf {26M}",
                "longString": "",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 1162,
                "enabled": true,
                "defaultString": "False swearing {26L}",
                "longString": "",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 1163,
                "enabled": true,
                "defaultString": "Fingerprints/Other {S40}",
                "longString": "",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 1164,
                "enabled": true,
                "defaultString": "Fingerprints/Retail Theft {S39}",
                "longString": "",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 1165,
                "enabled": true,
                "defaultString": "Fireworks {26N}",
                "longString": "",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 1166,
                "enabled": true,
                "defaultString": "Fish/game laws {26O}",
                "longString": "",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 1167,
                "enabled": true,
                "defaultString": "Foot Patrol {S53}",
                "longString": "",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 1168,
                "enabled": true,
                "defaultString": "Forgery {10}",
                "longString": "",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 1169,
                "enabled": true,
                "defaultString": "Found Property {S41}",
                "longString": "",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 1170,
                "enabled": true,
                "defaultString": "Fraud-all other fraud {11F}",
                "longString": "",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 1171,
                "enabled": true,
                "defaultString": "Fraud-bad checks {11A}",
                "longString": "",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 1172,
                "enabled": true,
                "defaultString": "Fraud-credit cards {11B}",
                "longString": "",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 1173,
                "enabled": true,
                "defaultString": "Fraud-theft by deception {11D}",
                "longString": "",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 1174,
                "enabled": true,
                "defaultString": "Fraud-theft of leased property {11E}",
                "longString": "",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 1175,
                "enabled": true,
                "defaultString": "Fraud-theft of service {11C}",
                "longString": "",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 1176,
                "enabled": true,
                "defaultString": "Gambling-all other gambling {19C}",
                "longString": "",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 1177,
                "enabled": true,
                "defaultString": "Gambling-bookmaking {19A}",
                "longString": "",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 1178,
                "enabled": true,
                "defaultString": "Gambling-numbering and lottery {19B}",
                "longString": "",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 1179,
                "enabled": true,
                "defaultString": "H&S-Animal Complaint {S20}",
                "longString": "",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 1180,
                "enabled": true,
                "defaultString": "H&S-Death Investigation {S21}",
                "longString": "",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 1181,
                "enabled": true,
                "defaultString": "H&S-Drug Overdose {S22}",
                "longString": "",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 1182,
                "enabled": true,
                "defaultString": "H&S-Injury Investigation {S23}",
                "longString": "",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 1183,
                "enabled": true,
                "defaultString": "H&S-Suicide {S25}",
                "longString": "",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 1184,
                "enabled": true,
                "defaultString": "H&S-Suicide/Attempt {S24}",
                "longString": "",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 1185,
                "enabled": true,
                "defaultString": "Harrassment by communication {24B}",
                "longString": "",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 1186,
                "enabled": true,
                "defaultString": "Hindering apprehension {26P}",
                "longString": "",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 1187,
                "enabled": true,
                "defaultString": "Homicide of police officer {0111}",
                "longString": "",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 1188,
                "enabled": true,
                "defaultString": "Insecure Property {S42}",
                "longString": "",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 1189,
                "enabled": true,
                "defaultString": "Kidnapping {26Q}",
                "longString": "",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 1190,
                "enabled": true,
                "defaultString": "Liquor Law violations {22}",
                "longString": "",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 1191,
                "enabled": true,
                "defaultString": "Local ordinance {26R}",
                "longString": "",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 1192,
                "enabled": true,
                "defaultString": "Local Ordinance {S43}",
                "longString": "",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 1193,
                "enabled": true,
                "defaultString": "Lockout {S44}",
                "longString": "",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 1194,
                "enabled": true,
                "defaultString": "Loitering and prowling at night {25A}",
                "longString": "",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 1195,
                "enabled": true,
                "defaultString": "Lost Property {S45}",
                "longString": "",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 1196,
                "enabled": true,
                "defaultString": "Manslaughter by negl {012}",
                "longString": "",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 1197,
                "enabled": true,
                "defaultString": "Manslaughter by negl of pol ofc {0122}",
                "longString": "",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 1198,
                "enabled": true,
                "defaultString": "Missing Person-Adult {S46}",
                "longString": "",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 1199,
                "enabled": true,
                "defaultString": "Missing Person-Juvenile {S47}",
                "longString": "",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 1200,
                "enabled": true,
                "defaultString": "Murder/non negl manslaughter {011}",
                "longString": "",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 1201,
                "enabled": true,
                "defaultString": "Noise Complaint {S48}",
                "longString": "",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 1202,
                "enabled": true,
                "defaultString": "Notification {S49}",
                "longString": "",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 1203,
                "enabled": true,
                "defaultString": "Obscene materials {26S}",
                "longString": "",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 1204,
                "enabled": true,
                "defaultString": "Offenses against family/children {20}",
                "longString": "",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 1205,
                "enabled": true,
                "defaultString": "Other, see comments {S00}",
                "longString": "",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 1206,
                "enabled": true,
                "defaultString": "Parking Complaint {S50}",
                "longString": "",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 1207,
                "enabled": true,
                "defaultString": "Perjury {26T}",
                "longString": "",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 1208,
                "enabled": true,
                "defaultString": "PFA Service {S51}",
                "longString": "",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 1209,
                "enabled": true,
                "defaultString": "Police Information {S52}",
                "longString": "",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 1210,
                "enabled": true,
                "defaultString": "Poss of burglary tools {26U}",
                "longString": "",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 1211,
                "enabled": true,
                "defaultString": "Poss of drug paraphenalia {26V}",
                "longString": "",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 1212,
                "enabled": true,
                "defaultString": "Premise Checks {S53}",
                "longString": "",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 1213,
                "enabled": true,
                "defaultString": "Property Damage {S54}",
                "longString": "",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 1214,
                "enabled": true,
                "defaultString": "Prostitution {16}",
                "longString": "",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 1215,
                "enabled": true,
                "defaultString": "Public drunkeness {23}",
                "longString": "",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 1216,
                "enabled": true,
                "defaultString": "Rape by force {02A}",
                "longString": "",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 1217,
                "enabled": true,
                "defaultString": "Recovered Stolen Property {S55}",
                "longString": "",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 1218,
                "enabled": true,
                "defaultString": "Riot {26W}",
                "longString": "",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 1219,
                "enabled": true,
                "defaultString": "Road Condition {S56}",
                "longString": "",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 1220,
                "enabled": true,
                "defaultString": "Robbery-firearm-bank {03A6}",
                "longString": "",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 1221,
                "enabled": true,
                "defaultString": "Robbery-firearm-chain, conv {03A4}",
                "longString": "",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 1222,
                "enabled": true,
                "defaultString": "Robbery-firearm-comml {03A2}",
                "longString": "",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 1223,
                "enabled": true,
                "defaultString": "Robbery-firearm-misc {03A7}",
                "longString": "",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 1224,
                "enabled": true,
                "defaultString": "Robbery-firearm-res {03A5}",
                "longString": "",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 1225,
                "enabled": true,
                "defaultString": "Robbery-firearm-serv/station {03A3}",
                "longString": "",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 1226,
                "enabled": true,
                "defaultString": "Robbery-firearm-street {03A1}",
                "longString": "",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 1227,
                "enabled": true,
                "defaultString": "Robbery-knife-bank {03B6}",
                "longString": "",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 1228,
                "enabled": true,
                "defaultString": "Robbery-knife-chain, conv {03B4}",
                "longString": "",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 1229,
                "enabled": true,
                "defaultString": "Robbery-knife-comml {03B2}",
                "longString": "",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 1230,
                "enabled": true,
                "defaultString": "Robbery-knife-misc {03B7}",
                "longString": "",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 1231,
                "enabled": true,
                "defaultString": "Robbery-knife-res {03B5}",
                "longString": "",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 1232,
                "enabled": true,
                "defaultString": "Robbery-knife-serv/station {03B3}",
                "longString": "",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 1233,
                "enabled": true,
                "defaultString": "Robbery-knife-street {03B1}",
                "longString": "",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 1234,
                "enabled": true,
                "defaultString": "Robbery-oth weap-bank {03C6}",
                "longString": "",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 1235,
                "enabled": true,
                "defaultString": "Robbery-oth weap-chain, conv {03C4}",
                "longString": "",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 1236,
                "enabled": true,
                "defaultString": "Robbery-oth weap-comml {03C2}",
                "longString": "",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 1237,
                "enabled": true,
                "defaultString": "Robbery-oth weap-misc {03C7}",
                "longString": "",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 1238,
                "enabled": true,
                "defaultString": "Robbery-oth weap-res {03C5}",
                "longString": "",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 1239,
                "enabled": true,
                "defaultString": "Robbery-oth weap-serv/station {03C3}",
                "longString": "",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 1240,
                "enabled": true,
                "defaultString": "Robbery-oth weap-street {03C1}",
                "longString": "",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 1241,
                "enabled": true,
                "defaultString": "Robbery-strng arm-bank {03D6}",
                "longString": "",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 1242,
                "enabled": true,
                "defaultString": "Robbery-strng arm-chain, conv/store {03D4}",
                "longString": "",
                "sequenceNumber": 209
            },
            {
                "refListItemId": 1243,
                "enabled": true,
                "defaultString": "Robbery-strng arm-comml {03D2}",
                "longString": "",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 1244,
                "enabled": true,
                "defaultString": "Robbery-strng arm-misc {03D7}",
                "longString": "",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 1245,
                "enabled": true,
                "defaultString": "Robbery-strng arm-res {03D5}",
                "longString": "",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 1246,
                "enabled": true,
                "defaultString": "Robbery-strng arm-serv/station {03D3}",
                "longString": "",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 1247,
                "enabled": true,
                "defaultString": "Robbery-strng arm-street {03D1}",
                "longString": "",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 1248,
                "enabled": true,
                "defaultString": "Runaway female - under 18 {29A}",
                "longString": "",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 1249,
                "enabled": true,
                "defaultString": "Runaway male - under 18 {29B}",
                "longString": "",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 1250,
                "enabled": true,
                "defaultString": "Sale and use of air rifles {26X}",
                "longString": "",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 1251,
                "enabled": true,
                "defaultString": "Scattering rubbish {26Y}",
                "longString": "",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 1252,
                "enabled": true,
                "defaultString": "Sex offense-all other (ex. 02, 16) {17H}",
                "longString": "",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 1253,
                "enabled": true,
                "defaultString": "Sex offense-incest {17E}",
                "longString": "",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 1254,
                "enabled": true,
                "defaultString": "Sex offense-indecent exposure {17A}",
                "longString": "",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 1255,
                "enabled": true,
                "defaultString": "Sex offense-invol deviate sex inter {17D}",
                "longString": "",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 1256,
                "enabled": true,
                "defaultString": "Sex offense-open lewdness {17B}",
                "longString": "",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 1257,
                "enabled": true,
                "defaultString": "Sex offense-sodomy {17F}",
                "longString": "",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 1258,
                "enabled": true,
                "defaultString": "Sex offense-statutory rape {17G}",
                "longString": "",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 1259,
                "enabled": true,
                "defaultString": "Sex offense-vol deviate sex inter {17C}",
                "longString": "",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 1260,
                "enabled": true,
                "defaultString": "Stolen property-receiving {13}",
                "longString": "",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 1261,
                "enabled": true,
                "defaultString": "Subpoena Service {S57}",
                "longString": "",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 1262,
                "enabled": true,
                "defaultString": "Suspicious Act/Vehicle {S62}",
                "longString": "",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 1263,
                "enabled": true,
                "defaultString": "Theft-all others $200 + {06I1}",
                "longString": "",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 1264,
                "enabled": true,
                "defaultString": "Theft-all others $50 under {06I3}",
                "longString": "",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 1265,
                "enabled": true,
                "defaultString": "Theft-all others $50-$199 {06I2}",
                "longString": "",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 1266,
                "enabled": true,
                "defaultString": "Theft-auto parts/access $200 + {06E1}",
                "longString": "",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 1267,
                "enabled": true,
                "defaultString": "Theft-auto parts/access $50 under {06E3}",
                "longString": "",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 1268,
                "enabled": true,
                "defaultString": "Theft-auto parts/access $50-$199 {06E2}",
                "longString": "",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 1269,
                "enabled": true,
                "defaultString": "Theft-bicycles $200 + {06F1}",
                "longString": "",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 1270,
                "enabled": true,
                "defaultString": "Theft-bicycles $50 under {06F3}",
                "longString": "",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 1271,
                "enabled": true,
                "defaultString": "Theft-bicycles $50-$199 {06F2}",
                "longString": "",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 1272,
                "enabled": true,
                "defaultString": "Theft-coin op mach $200 + {06H1}",
                "longString": "",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 1273,
                "enabled": true,
                "defaultString": "Theft-coin op mach $50 under {06H3}",
                "longString": "",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 1274,
                "enabled": true,
                "defaultString": "Theft-coin op mach $50-$199 {06H2}",
                "longString": "",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 1275,
                "enabled": true,
                "defaultString": "Theft-from bldg $200 + {06G1}",
                "longString": "",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 1276,
                "enabled": true,
                "defaultString": "Theft-from bldg $50 under {06G3}",
                "longString": "",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 1277,
                "enabled": true,
                "defaultString": "Theft-from bldg $50-$199 {06G2}",
                "longString": "",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 1278,
                "enabled": true,
                "defaultString": "Theft-from motor veh $200 + {06D1}",
                "longString": "",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 1279,
                "enabled": true,
                "defaultString": "Theft-from motor veh $50 under {06D3}",
                "longString": "",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 1280,
                "enabled": true,
                "defaultString": "Theft-from motor veh $50-$199 {06D2}",
                "longString": "",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 1281,
                "enabled": true,
                "defaultString": "Theft-mv automobiles {07A}",
                "longString": "",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 1282,
                "enabled": true,
                "defaultString": "Theft-mv other vehicles {07C}",
                "longString": "",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 1283,
                "enabled": true,
                "defaultString": "Theft-mv trucks/buses {07B}",
                "longString": "",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 1284,
                "enabled": true,
                "defaultString": "Theft-pocket picking $200 + {06A1}",
                "longString": "",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 1285,
                "enabled": true,
                "defaultString": "Theft-pocket picking $50 under {06A3}",
                "longString": "",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 1286,
                "enabled": true,
                "defaultString": "Theft-pocket picking $50-$199 {06A2}",
                "longString": "",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 1287,
                "enabled": true,
                "defaultString": "Theft-purse snatch $200 + {06B1}",
                "longString": "",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 1288,
                "enabled": true,
                "defaultString": "Theft-purse snatch $50 under {06B3}",
                "longString": "",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 1289,
                "enabled": true,
                "defaultString": "Theft-purse snatch $50-$199 {06B2}",
                "longString": "",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 1290,
                "enabled": true,
                "defaultString": "Theft-retail $200 + {06C1}",
                "longString": "",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 1291,
                "enabled": true,
                "defaultString": "Theft-retail $50 under {06C3}",
                "longString": "",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 1292,
                "enabled": true,
                "defaultString": "Theft-retail $50-$199 {06C2}",
                "longString": "",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 1293,
                "enabled": true,
                "defaultString": "Traffic Pursuit {S59}",
                "longString": "",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 1294,
                "enabled": true,
                "defaultString": "Traffic Stop {S58}",
                "longString": "",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 1295,
                "enabled": true,
                "defaultString": "Truancy {S60}",
                "longString": "",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 1296,
                "enabled": true,
                "defaultString": "Unauth use of motor vehicle {26Z}",
                "longString": "",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 1297,
                "enabled": true,
                "defaultString": "Unlawful restraint {26AA}",
                "longString": "",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 1298,
                "enabled": true,
                "defaultString": "Vagrancy - all other {25B}",
                "longString": "",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 1299,
                "enabled": true,
                "defaultString": "Vandalism {14}",
                "longString": "",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 1300,
                "enabled": true,
                "defaultString": "Warrant Service {S61}",
                "longString": "",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 1301,
                "enabled": true,
                "defaultString": "Weapons - firearm violation {15}",
                "longString": "",
                "sequenceNumber": 268
            }
        ]
    },
    {
        "RefListID": 133,
        "refListName": "Injury_Type",
        "refListItems": [
            {
                "refListItemId": 1302,
                "enabled": true,
                "defaultString": "Apparent Broken Bones",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1303,
                "enabled": true,
                "defaultString": "Apparent Minor Injury",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1304,
                "enabled": true,
                "defaultString": "Broken Bone",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1305,
                "enabled": true,
                "defaultString": "Bruise",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1306,
                "enabled": true,
                "defaultString": "Laceration",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1307,
                "enabled": true,
                "defaultString": "Loss of Teeth",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1308,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1309,
                "enabled": true,
                "defaultString": "Other Major Injury",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1310,
                "enabled": true,
                "defaultString": "Possible Internal Injury",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1311,
                "enabled": true,
                "defaultString": "Severe Laceration",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1312,
                "enabled": true,
                "defaultString": "Unconciousness",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 405,
        "refListName": "Scheduled_Booking_Clearance_Reason",
        "refListItems": [
            {
                "refListItemId": 4187,
                "enabled": true,
                "defaultString": "Met terms of probation",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4188,
                "enabled": true,
                "defaultString": "No show",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4189,
                "enabled": true,
                "defaultString": "Court ruling",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4190,
                "enabled": true,
                "defaultString": "Death",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4191,
                "enabled": true,
                "defaultString": "Incarcerated elsewhere",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4192,
                "enabled": true,
                "defaultString": "Incarcerated",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 15964,
                "enabled": true,
                "defaultString": "Conditions Met",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 15967,
                "enabled": true,
                "defaultString": "Cleared w/CFTS"
            }
        ]
    },
    {
        "RefListID": 135,
        "refListName": "InmateDrugFrequency",
        "refListItems": [
            {
                "refListItemId": 1317,
                "enabled": true,
                "defaultString": "Biweekly",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1318,
                "enabled": true,
                "defaultString": "Daily",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1319,
                "enabled": true,
                "defaultString": "Monthly",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1320,
                "enabled": true,
                "defaultString": "Twice a year",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1321,
                "enabled": true,
                "defaultString": "Weekly",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 136,
        "refListName": "Intersection_Related",
        "refListItems": [
            {
                "refListItemId": 1322,
                "enabled": true,
                "defaultString": "1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1323,
                "enabled": true,
                "defaultString": "2",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 137,
        "refListName": "InterviewDisposition",
        "refListItems": [
            {
                "refListItemId": 1324,
                "enabled": true,
                "defaultString": "Booked",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1325,
                "enabled": true,
                "defaultString": "Parents",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1326,
                "enabled": true,
                "defaultString": "Released",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1327,
                "enabled": true,
                "defaultString": "Ticketed",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 138,
        "refListName": "Inventory_Discard_Reason",
        "refListItems": [
            {
                "refListItemId": 1328,
                "enabled": true,
                "defaultString": "Cleared of charges",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1329,
                "enabled": true,
                "defaultString": "Court allows sale of weapons",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 139,
        "refListName": "InventoryItemStatus",
        "refListItems": [
            {
                "refListItemId": 1330,
                "enabled": true,
                "defaultString": "Checked In",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1331,
                "enabled": true,
                "defaultString": "Checked Out",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1332,
                "enabled": true,
                "defaultString": "Disposed",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 140,
        "refListName": "Judge_Name",
        "refListItems": [
            {
                "refListItemId": 1333,
                "enabled": true,
                "defaultString": "Bevington",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1334,
                "enabled": true,
                "defaultString": "Jones",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1335,
                "enabled": true,
                "defaultString": "Lieptrot",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1336,
                "enabled": true,
                "defaultString": "Out of County",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1337,
                "enabled": true,
                "defaultString": "Smith",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1338,
                "enabled": true,
                "defaultString": "Whitlock",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 141,
        "refListName": "Jurisdiction",
        "refListItems": [
            {
                "refListItemId": 1339,
                "enabled": true,
                "defaultString": "Hampton",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1340,
                "enabled": true,
                "defaultString": "Locust Grove",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1341,
                "enabled": true,
                "defaultString": "McDonough",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1342,
                "enabled": true,
                "defaultString": "Stockbridge",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 142,
        "refListName": "Juror_Excuse",
        "refListItems": [
            {
                "refListItemId": 1343,
                "enabled": true,
                "defaultString": "Financial hardship",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1344,
                "enabled": true,
                "defaultString": "Job related",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1345,
                "enabled": true,
                "defaultString": "Personal tragedy",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1346,
                "enabled": true,
                "defaultString": "Vacation",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 143,
        "refListName": "Juror_Resolution",
        "refListItems": [
            {
                "refListItemId": 1347,
                "enabled": true,
                "defaultString": "Did Not Serve",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1348,
                "enabled": true,
                "defaultString": "Excused",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1349,
                "enabled": true,
                "defaultString": "Served",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 144,
        "refListName": "JuvenileDisposition",
        "refListItems": [
            {
                "refListItemId": 1350,
                "enabled": true,
                "defaultString": "Criminal/Adult Court",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1351,
                "enabled": true,
                "defaultString": "Juvenile Court",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1352,
                "enabled": true,
                "defaultString": "Other Police Agency",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1353,
                "enabled": true,
                "defaultString": "Welfare Agency",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1354,
                "enabled": true,
                "defaultString": "Within Department",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 145,
        "refListName": "LanguageList",
        "refListItems": [
            {
                "refListItemId": 1355,
                "enabled": true,
                "defaultString": "Afrikaans",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1356,
                "enabled": true,
                "defaultString": "Albanian",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1357,
                "enabled": true,
                "defaultString": "Amharic",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1358,
                "enabled": true,
                "defaultString": "Arabic",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1359,
                "enabled": true,
                "defaultString": "Armenian",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1360,
                "enabled": true,
                "defaultString": "Basque",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1361,
                "enabled": true,
                "defaultString": "Belarusian",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1362,
                "enabled": true,
                "defaultString": "Bengali",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1363,
                "enabled": true,
                "defaultString": "Bulgarian",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1364,
                "enabled": true,
                "defaultString": "Burmese",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1365,
                "enabled": true,
                "defaultString": "Catalan",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1366,
                "enabled": true,
                "defaultString": "Chinese",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1367,
                "enabled": true,
                "defaultString": "Creole",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1368,
                "enabled": true,
                "defaultString": "Croatian",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1369,
                "enabled": true,
                "defaultString": "Czech",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1370,
                "enabled": true,
                "defaultString": "Danish",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1371,
                "enabled": true,
                "defaultString": "Dari",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1372,
                "enabled": true,
                "defaultString": "Dutch",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1373,
                "enabled": true,
                "defaultString": "Dzongkha",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1374,
                "enabled": true,
                "defaultString": "English",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1375,
                "enabled": true,
                "defaultString": "Esperanto",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1376,
                "enabled": true,
                "defaultString": "Estonian",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1377,
                "enabled": true,
                "defaultString": "Faroese",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 1378,
                "enabled": true,
                "defaultString": "Farsi",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 1379,
                "enabled": true,
                "defaultString": "Finnish",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 1380,
                "enabled": true,
                "defaultString": "French",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 1381,
                "enabled": true,
                "defaultString": "Gaelic",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 1382,
                "enabled": true,
                "defaultString": "Galician",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 1383,
                "enabled": true,
                "defaultString": "German",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 1384,
                "enabled": true,
                "defaultString": "Greek",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 1385,
                "enabled": true,
                "defaultString": "Hebrew",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 1386,
                "enabled": true,
                "defaultString": "Hindi",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 1387,
                "enabled": true,
                "defaultString": "Hungarian",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 1388,
                "enabled": true,
                "defaultString": "Icelandic",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 1389,
                "enabled": true,
                "defaultString": "Indonesian",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 1390,
                "enabled": true,
                "defaultString": "Inuktitut(Eskimo)",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 1391,
                "enabled": true,
                "defaultString": "Italian",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 1392,
                "enabled": true,
                "defaultString": "Japanese",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 1393,
                "enabled": true,
                "defaultString": "Khmer",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 1394,
                "enabled": true,
                "defaultString": "Klingon",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 1395,
                "enabled": true,
                "defaultString": "Korean",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 1396,
                "enabled": true,
                "defaultString": "Kurdish",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 1397,
                "enabled": true,
                "defaultString": "Laotian",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 1398,
                "enabled": true,
                "defaultString": "Lappish",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 1399,
                "enabled": true,
                "defaultString": "Latvian",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 1400,
                "enabled": true,
                "defaultString": "Lithuanian",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 1401,
                "enabled": true,
                "defaultString": "Macedonian",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 1402,
                "enabled": true,
                "defaultString": "Malay",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 1403,
                "enabled": true,
                "defaultString": "Maltese",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 1404,
                "enabled": true,
                "defaultString": "Nepali",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 1405,
                "enabled": true,
                "defaultString": "Norwegian",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 1406,
                "enabled": true,
                "defaultString": "Pashto",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 1407,
                "enabled": true,
                "defaultString": "Polish",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 1408,
                "enabled": true,
                "defaultString": "Portuguese",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 1409,
                "enabled": true,
                "defaultString": "Romanian",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 1410,
                "enabled": true,
                "defaultString": "Russian",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 1411,
                "enabled": true,
                "defaultString": "Scots",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 1412,
                "enabled": true,
                "defaultString": "Serbian",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 1413,
                "enabled": true,
                "defaultString": "Slovak",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 1414,
                "enabled": true,
                "defaultString": "Slovenian",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 1415,
                "enabled": true,
                "defaultString": "Somali",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 1416,
                "enabled": true,
                "defaultString": "Spanish",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 1417,
                "enabled": true,
                "defaultString": "Swahili",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 1418,
                "enabled": true,
                "defaultString": "Swedish",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 1419,
                "enabled": true,
                "defaultString": "Tagalog-Filipino",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 1420,
                "enabled": true,
                "defaultString": "Tajik",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 1421,
                "enabled": true,
                "defaultString": "Tamil",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 1422,
                "enabled": true,
                "defaultString": "Thai",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 1423,
                "enabled": true,
                "defaultString": "Tibetan",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 1424,
                "enabled": true,
                "defaultString": "Tiginya",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 1425,
                "enabled": true,
                "defaultString": "Tongan",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 1426,
                "enabled": true,
                "defaultString": "Turkish",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 1427,
                "enabled": true,
                "defaultString": "Turkmen",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 1428,
                "enabled": true,
                "defaultString": "Ukrainian",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 1429,
                "enabled": true,
                "defaultString": "Urdu",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 1430,
                "enabled": true,
                "defaultString": "Uzbek",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 1431,
                "enabled": true,
                "defaultString": "Vietnamese",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 1432,
                "enabled": true,
                "defaultString": "Welsh",
                "longString": "",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 16109,
                "enabled": true,
                "defaultString": "Hmong"
            }
        ]
    },
    {
        "RefListID": 146,
        "refListName": "Leoka_Activity",
        "refListItems": [
            {
                "refListItemId": 1433,
                "enabled": true,
                "defaultString": "All other activity",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1434,
                "enabled": true,
                "defaultString": "Ambush - no warning",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1435,
                "enabled": true,
                "defaultString": "Attempting other arrests",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1436,
                "enabled": true,
                "defaultString": "Burglaries in progress/pursuing suspects",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1437,
                "enabled": true,
                "defaultString": "Civil disorder",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1438,
                "enabled": true,
                "defaultString": "Handling/transporting prisoners",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1439,
                "enabled": true,
                "defaultString": "Investigating suspicious persons",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1440,
                "enabled": true,
                "defaultString": "Offender mentally deranged",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1441,
                "enabled": true,
                "defaultString": "Responding to disturbance",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1442,
                "enabled": true,
                "defaultString": "Robberies in progress/pursuing suspects",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1443,
                "enabled": true,
                "defaultString": "Traffic stops/pursuits",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 147,
        "refListName": "Level_of_Education",
        "refListItems": "NULL"
    },
    {
        "RefListID": 148,
        "refListName": "LicenseClass",
        "refListItems": [
            {
                "refListItemId": 1444,
                "enabled": true,
                "defaultString": "Combo",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1445,
                "enabled": true,
                "defaultString": "Food",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 149,
        "refListName": "LicenseDenialReason",
        "refListItems": [
            {
                "refListItemId": 1446,
                "enabled": true,
                "defaultString": "Sample",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 150,
        "refListName": "LicenseStatus",
        "refListItems": [
            {
                "refListItemId": 1447,
                "enabled": true,
                "defaultString": "Approved",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1448,
                "enabled": true,
                "defaultString": "Denied",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 151,
        "refListName": "LicenseType",
        "refListItems": [
            {
                "refListItemId": 1449,
                "enabled": true,
                "defaultString": "Adult Ent",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1450,
                "enabled": true,
                "defaultString": "Fortune",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1451,
                "enabled": true,
                "defaultString": "Massage",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1452,
                "enabled": true,
                "defaultString": "Private Vendor",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1453,
                "enabled": true,
                "defaultString": "Sample",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 445,
        "refListName": "CompletionStatus",
        "refListItems": [
            {
                "refListItemId": 4508,
                "enabled": true,
                "defaultString": "COMPLETED",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4509,
                "enabled": true,
                "defaultString": "PENDING",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4510,
                "enabled": true,
                "defaultString": "ABANDONED",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15544,
                "enabled": true,
                "defaultString": "RETURNED",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 153,
        "refListName": "Locker",
        "refListItems": [
            {
                "refListItemId": 1458,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1459,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1460,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1461,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1462,
                "enabled": true,
                "defaultString": "05",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1463,
                "enabled": true,
                "defaultString": "06",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1464,
                "enabled": true,
                "defaultString": "07",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1465,
                "enabled": true,
                "defaultString": "08",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1466,
                "enabled": true,
                "defaultString": "09",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1467,
                "enabled": true,
                "defaultString": "10",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1468,
                "enabled": true,
                "defaultString": "11",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1469,
                "enabled": true,
                "defaultString": "12",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1470,
                "enabled": true,
                "defaultString": "13",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1471,
                "enabled": true,
                "defaultString": "14",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1472,
                "enabled": true,
                "defaultString": "15",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1473,
                "enabled": true,
                "defaultString": "16",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1474,
                "enabled": true,
                "defaultString": "17",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1475,
                "enabled": true,
                "defaultString": "18",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1476,
                "enabled": true,
                "defaultString": "19",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1477,
                "enabled": true,
                "defaultString": "20",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1478,
                "enabled": true,
                "defaultString": "21",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1479,
                "enabled": true,
                "defaultString": "22",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1480,
                "enabled": true,
                "defaultString": "23",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 1481,
                "enabled": true,
                "defaultString": "24",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 1482,
                "enabled": true,
                "defaultString": "25",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 1483,
                "enabled": true,
                "defaultString": "26",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 1484,
                "enabled": true,
                "defaultString": "27",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 1485,
                "enabled": true,
                "defaultString": "28",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 1486,
                "enabled": true,
                "defaultString": "29",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 1487,
                "enabled": true,
                "defaultString": "30",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 1488,
                "enabled": true,
                "defaultString": "31",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 1489,
                "enabled": true,
                "defaultString": "32",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 1490,
                "enabled": true,
                "defaultString": "33",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 1491,
                "enabled": true,
                "defaultString": "34",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 1492,
                "enabled": true,
                "defaultString": "35",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 1493,
                "enabled": true,
                "defaultString": "36",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 1494,
                "enabled": true,
                "defaultString": "37",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 1495,
                "enabled": true,
                "defaultString": "38",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 1496,
                "enabled": true,
                "defaultString": "39",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 1497,
                "enabled": true,
                "defaultString": "40",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 1498,
                "enabled": true,
                "defaultString": "41",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 1499,
                "enabled": true,
                "defaultString": "42",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 1500,
                "enabled": true,
                "defaultString": "43",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 1501,
                "enabled": true,
                "defaultString": "44",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 1502,
                "enabled": true,
                "defaultString": "45",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 1503,
                "enabled": true,
                "defaultString": "46",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 1504,
                "enabled": true,
                "defaultString": "47",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 1505,
                "enabled": true,
                "defaultString": "48",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 1506,
                "enabled": true,
                "defaultString": "49",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 1507,
                "enabled": true,
                "defaultString": "50",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 1508,
                "enabled": true,
                "defaultString": "51",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 1509,
                "enabled": true,
                "defaultString": "52",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 1510,
                "enabled": true,
                "defaultString": "53",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 1511,
                "enabled": true,
                "defaultString": "54",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 1512,
                "enabled": true,
                "defaultString": "55",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 1513,
                "enabled": true,
                "defaultString": "56",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 1514,
                "enabled": true,
                "defaultString": "57",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 1515,
                "enabled": true,
                "defaultString": "58",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 1516,
                "enabled": true,
                "defaultString": "59",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 1517,
                "enabled": true,
                "defaultString": "60",
                "longString": "",
                "sequenceNumber": 60
            }
        ]
    },
    {
        "RefListID": 154,
        "refListName": "Maintenance_Type",
        "refListItems": [
            {
                "refListItemId": 1518,
                "enabled": true,
                "defaultString": "Cleaning",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1519,
                "enabled": true,
                "defaultString": "General",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 155,
        "refListName": "Maneuver",
        "refListItems": [
            {
                "refListItemId": 1520,
                "enabled": true,
                "defaultString": "Avoid Object in Road {20}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1521,
                "enabled": true,
                "defaultString": "Backing {81}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1522,
                "enabled": true,
                "defaultString": "Change Lanes - Left {07}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1523,
                "enabled": true,
                "defaultString": "Change Lanes - Right {08}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1524,
                "enabled": true,
                "defaultString": "Enter Parked Position {70}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1525,
                "enabled": true,
                "defaultString": "Exiting Private Road/Property {21}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1526,
                "enabled": true,
                "defaultString": "Go Straight - Left Turn Lane {05}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1527,
                "enabled": true,
                "defaultString": "Go Straight - Right Turn Lane {06}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1528,
                "enabled": true,
                "defaultString": "Go Straight Ahead {01}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1529,
                "enabled": true,
                "defaultString": "Left Turn {14}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1530,
                "enabled": true,
                "defaultString": "Merge - Left {09}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1531,
                "enabled": true,
                "defaultString": "Merge - Right {10}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1532,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1533,
                "enabled": true,
                "defaultString": "Parked - Illegally {72}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1534,
                "enabled": true,
                "defaultString": "Parked - Legally {71}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1535,
                "enabled": true,
                "defaultString": "Pass on Left {02}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1536,
                "enabled": true,
                "defaultString": "Pass on One-Way Street {03}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1537,
                "enabled": true,
                "defaultString": "Pass on Right {04}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1538,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride Across Road {64}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1539,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride against Traffic in Road {62}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1540,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride against Traffic off Road {63}",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1541,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride In Bike Path {65}",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1542,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride with Traffic in Road {60}",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 1543,
                "enabled": true,
                "defaultString": "Pedal Cycle Ride with Traffic off Road {61}",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 1544,
                "enabled": true,
                "defaultString": "Pushed by Pedestrian {87}",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 1545,
                "enabled": true,
                "defaultString": "Pushed by Vehicle {86}",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 1546,
                "enabled": true,
                "defaultString": "Right Turn {13}",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 1547,
                "enabled": true,
                "defaultString": "Slowing/Stopping {18}",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 1548,
                "enabled": true,
                "defaultString": "Start from Park {16}",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 1549,
                "enabled": true,
                "defaultString": "Start in Traffic {17}",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 1550,
                "enabled": true,
                "defaultString": "Stopped in Traffic {19}",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 1551,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 1552,
                "enabled": true,
                "defaultString": "U-Turn {15}",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 1553,
                "enabled": true,
                "defaultString": "Wrong Side of Road {11}",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 1554,
                "enabled": true,
                "defaultString": "Wrong Way - One-Way {12}",
                "longString": "",
                "sequenceNumber": 35
            }
        ]
    },
    {
        "RefListID": 156,
        "refListName": "Material_In_Roadway",
        "refListItems": [
            {
                "refListItemId": 1555,
                "enabled": true,
                "defaultString": "Dirt {4}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1556,
                "enabled": true,
                "defaultString": "Gravel {5}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1557,
                "enabled": true,
                "defaultString": "None {1}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1558,
                "enabled": true,
                "defaultString": "Oil/Petrol {6}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1559,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1560,
                "enabled": true,
                "defaultString": "Rocks {2}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1561,
                "enabled": true,
                "defaultString": "Trees/Limbs {3}",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 157,
        "refListName": "Material_Source",
        "refListItems": [
            {
                "refListItemId": 1562,
                "enabled": true,
                "defaultString": "Already in road; fell from vehicle {4}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1563,
                "enabled": true,
                "defaultString": "Dropped from vehicle {3}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1564,
                "enabled": true,
                "defaultString": "Natural environment {2}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1565,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1566,
                "enabled": true,
                "defaultString": "Unknown {9}",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 158,
        "refListName": "MO",
        "refListItems": [
            {
                "refListItemId": 1567,
                "enabled": true,
                "defaultString": "Force",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1568,
                "enabled": true,
                "defaultString": "No Force",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 159,
        "refListName": "NA",
        "refListItems": [
            {
                "refListItemId": 1569,
                "enabled": true,
                "defaultString": "N/A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1570,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1571,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 160,
        "refListName": "NIBSJuvenileDisposition",
        "refListItems": [
            {
                "refListItemId": 1572,
                "enabled": true,
                "defaultString": "Referred to Other Authorities",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 161,
        "refListName": "Non_Visible_Injury",
        "refListItems": [
            {
                "refListItemId": 1573,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1574,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 574,
        "refListName": "CountyOfResidence",
        "refListItems": [
            {
                "refListItemId": 16202,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 163,
        "refListName": "Officer",
        "refListItems": [
            {
                "refListItemId": 1578,
                "enabled": true,
                "defaultString": "OFFICER 1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1579,
                "enabled": true,
                "defaultString": "OFFICER 2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1580,
                "enabled": true,
                "defaultString": "OFFICER 3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 164,
        "refListName": "Opposing_Lanes_Separated_By",
        "refListItems": [
            {
                "refListItemId": 1581,
                "enabled": true,
                "defaultString": "Broken painted line {3}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1582,
                "enabled": true,
                "defaultString": "Concrete barrier {5}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1583,
                "enabled": true,
                "defaultString": "Fence {7}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1584,
                "enabled": true,
                "defaultString": "Metal guard rail {6}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1585,
                "enabled": true,
                "defaultString": "None {97}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1586,
                "enabled": true,
                "defaultString": "Other barrier {98}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1587,
                "enabled": true,
                "defaultString": "Paved surface {1}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1588,
                "enabled": true,
                "defaultString": "Solid painted line {4}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1589,
                "enabled": true,
                "defaultString": "Unpaved surface {2}",
                "longString": "",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 165,
        "refListName": "Originating_Agency_Type",
        "refListItems": [
            {
                "refListItemId": 1590,
                "enabled": true,
                "defaultString": "Monroe Co",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1591,
                "enabled": true,
                "defaultString": "Out of County",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1592,
                "enabled": true,
                "defaultString": "Out of State",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 166,
        "refListName": "Param_Name",
        "refListItems": [
            {
                "refListItemId": 1593,
                "enabled": true,
                "defaultString": "<fee name>_Amount",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1594,
                "enabled": true,
                "defaultString": "<fee name>_LateFee",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1595,
                "enabled": true,
                "defaultString": "<fee name>_ProRateForRenewal",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1596,
                "enabled": true,
                "defaultString": "AllowExpirationDateEdit",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1597,
                "enabled": true,
                "defaultString": "AllowSaveWithNegativeBalance",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1598,
                "enabled": true,
                "defaultString": "ApprovalCheckBoxes",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1599,
                "enabled": true,
                "defaultString": "CheckPhotoOnStatus",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1600,
                "enabled": true,
                "defaultString": "ExpirationDateAlgorithmInitial",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1601,
                "enabled": true,
                "defaultString": "ExpirationDateAlgorithmRenewal",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1602,
                "enabled": true,
                "defaultString": "FeeStringApprove",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1603,
                "enabled": true,
                "defaultString": "FeeStringInitial",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1604,
                "enabled": true,
                "defaultString": "FeeStringPickup",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1605,
                "enabled": true,
                "defaultString": "FeeStringRenew",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1606,
                "enabled": true,
                "defaultString": "NumDaysBeforeRenewal",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1607,
                "enabled": true,
                "defaultString": "NumSupplementalColumns",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1608,
                "enabled": true,
                "defaultString": "PermitDescriptionNumber",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1609,
                "enabled": true,
                "defaultString": "PhotoRequiredEveryXDays",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1610,
                "enabled": true,
                "defaultString": "RenewRequiresReapproval",
                "longString": "",
                "sequenceNumber": 18
            }
        ]
    },
    {
        "RefListID": 167,
        "refListName": "Payment_Period",
        "refListItems": [
            {
                "refListItemId": 1611,
                "enabled": true,
                "defaultString": "BiWeekly",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1612,
                "enabled": true,
                "defaultString": "Every 3 months",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1613,
                "enabled": true,
                "defaultString": "Monthly",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1614,
                "enabled": true,
                "defaultString": "Weekly",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 168,
        "refListName": "Pedestrian_Action",
        "refListItems": [
            {
                "refListItemId": 1615,
                "enabled": true,
                "defaultString": "Cross/Enter - Intersection {01}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1616,
                "enabled": true,
                "defaultString": "Cross/Enter - Other {02}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1617,
                "enabled": true,
                "defaultString": "Get On/Off Vehicle {06}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1618,
                "enabled": true,
                "defaultString": "In Road - Other {10}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1619,
                "enabled": true,
                "defaultString": "In Road - Other Work {08)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1620,
                "enabled": true,
                "defaultString": "In Road - Playing {09}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1621,
                "enabled": true,
                "defaultString": "Not In Road {11}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1622,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1623,
                "enabled": true,
                "defaultString": "Push/Work on Vehicle {07}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1624,
                "enabled": true,
                "defaultString": "Stand in Roadway {05}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1625,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1626,
                "enabled": true,
                "defaultString": "Walk in Road - Against Traffic {04}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1627,
                "enabled": true,
                "defaultString": "Walk in Road - With Traffic {03}",
                "longString": "",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 169,
        "refListName": "Permit_Type",
        "refListItems": [
            {
                "refListItemId": 1628,
                "enabled": true,
                "defaultString": "Gun Permit",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1629,
                "enabled": true,
                "defaultString": "License To Sell Precious Metals",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1630,
                "enabled": true,
                "defaultString": "Pistol Dealer Permit",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 170,
        "refListName": "PermitsHoldReason",
        "refListItems": [
            {
                "refListItemId": 1631,
                "enabled": true,
                "defaultString": "Need to have conference",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1632,
                "enabled": true,
                "defaultString": "Non-payment of insurance",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 171,
        "refListName": "PermitStatus",
        "refListItems": [
            {
                "refListItemId": 1633,
                "enabled": true,
                "defaultString": "Approved",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1634,
                "enabled": true,
                "defaultString": "Denied",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1635,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1636,
                "enabled": true,
                "defaultString": "Picked Up",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1637,
                "enabled": true,
                "defaultString": "Revoked",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1638,
                "enabled": true,
                "defaultString": "Suspended",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 172,
        "refListName": "Person_Condition",
        "refListItems": [
            {
                "refListItemId": 1639,
                "enabled": true,
                "defaultString": "Apparently Asleep {2}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1640,
                "enabled": true,
                "defaultString": "Fatigued {3}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1641,
                "enabled": true,
                "defaultString": "Ill {4}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1642,
                "enabled": true,
                "defaultString": "No Defect {1}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1643,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1644,
                "enabled": true,
                "defaultString": "Unknown {9}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 173,
        "refListName": "Person_Relation_Type",
        "refListItems": [
            {
                "refListItemId": 1645,
                "enabled": true,
                "defaultString": "Contact",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1646,
                "enabled": true,
                "defaultString": "Employee",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1647,
                "enabled": true,
                "defaultString": "Manager",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1648,
                "enabled": true,
                "defaultString": "Owner",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 174,
        "refListName": "PersonToServeCity",
        "refListItems": [
            {
                "refListItemId": 1649,
                "enabled": false,
                "defaultString": "Abernant",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1650,
                "enabled": false,
                "defaultString": "Barnesville",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1651,
                "enabled": false,
                "defaultString": "Brookwood",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1652,
                "enabled": false,
                "defaultString": "Buhl",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1653,
                "enabled": false,
                "defaultString": "Coaling",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1654,
                "enabled": false,
                "defaultString": "Coker",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1655,
                "enabled": false,
                "defaultString": "Cottondale",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1656,
                "enabled": false,
                "defaultString": "Duncanville",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1657,
                "enabled": false,
                "defaultString": "Echola",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1658,
                "enabled": false,
                "defaultString": "Elrod",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1659,
                "enabled": false,
                "defaultString": "Fosters",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1660,
                "enabled": false,
                "defaultString": "Knoxville",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1661,
                "enabled": false,
                "defaultString": "Locust Grove",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1662,
                "enabled": false,
                "defaultString": "McCalla",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1663,
                "enabled": false,
                "defaultString": "Northport",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1664,
                "enabled": false,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1665,
                "enabled": false,
                "defaultString": "Peterson",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1666,
                "enabled": false,
                "defaultString": "Ralph",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1667,
                "enabled": false,
                "defaultString": "Samantha",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1668,
                "enabled": false,
                "defaultString": "Stockbridge",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1669,
                "enabled": false,
                "defaultString": "Tuscaloosa",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1670,
                "enabled": false,
                "defaultString": "Vance",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1671,
                "enabled": false,
                "defaultString": "West Blocton",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 24976,
                "enabled": true,
                "defaultString": "ANOKA",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 24977,
                "enabled": true,
                "defaultString": "ANDOVER",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 24978,
                "enabled": true,
                "defaultString": "BLAINE",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 24979,
                "enabled": true,
                "defaultString": "BETHEL",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 24980,
                "enabled": true,
                "defaultString": "EAST BETHEL",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 24981,
                "enabled": true,
                "defaultString": "COON RAPIDS",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 24982,
                "enabled": true,
                "defaultString": "COLONIAL HEIGHTS",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 24983,
                "enabled": true,
                "defaultString": "CIRCLE PINES",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 24984,
                "enabled": true,
                "defaultString": "CENTERVILLE",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 24985,
                "enabled": true,
                "defaultString": "COLUMBUS",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 24986,
                "enabled": true,
                "defaultString": "FRIDLEY",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 24987,
                "enabled": true,
                "defaultString": "FOREST LAKE",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 24988,
                "enabled": true,
                "defaultString": "HUGO",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 24989,
                "enabled": true,
                "defaultString": "HAM LAKE",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 24990,
                "enabled": true,
                "defaultString": "HILLTOP",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 24991,
                "enabled": true,
                "defaultString": "LINO LAKES",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 24992,
                "enabled": true,
                "defaultString": "LEXINGTON",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 24993,
                "enabled": true,
                "defaultString": "LINWOOD",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 24994,
                "enabled": true,
                "defaultString": "STACY",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 24995,
                "enabled": true,
                "defaultString": "NOWTHEN",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 24996,
                "enabled": true,
                "defaultString": "ELK RIVER",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 24997,
                "enabled": true,
                "defaultString": "BURNS",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 24998,
                "enabled": true,
                "defaultString": "OAK GROVE",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 24999,
                "enabled": true,
                "defaultString": "CEDAR",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 25000,
                "enabled": true,
                "defaultString": "RAMSEY",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 25001,
                "enabled": true,
                "defaultString": "SPRING LAKE PARK",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 25002,
                "enabled": true,
                "defaultString": "ST. FRANCIS",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 25003,
                "enabled": true,
                "defaultString": "SODERVILLE",
                "sequenceNumber": 51
            }
        ]
    },
    {
        "RefListID": 575,
        "refListName": "CityOfResidence",
        "refListItems": [
            {
                "refListItemId": 16203,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 176,
        "refListName": "PersonToServeZip",
        "refListItems": [
            {
                "refListItemId": 1676,
                "enabled": true,
                "defaultString": "30228",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1677,
                "enabled": true,
                "defaultString": "30248",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1678,
                "enabled": true,
                "defaultString": "30281",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 177,
        "refListName": "Plea",
        "refListItems": [
            {
                "refListItemId": 1679,
                "enabled": true,
                "defaultString": "Guilty",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1680,
                "enabled": true,
                "defaultString": "Nolo Cont'd",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1681,
                "enabled": true,
                "defaultString": "Not Guilty",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 178,
        "refListName": "PoliceDept",
        "refListItems": [
            {
                "refListItemId": 1682,
                "enabled": true,
                "defaultString": "Barrett Township PD",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1683,
                "enabled": true,
                "defaultString": "Delaware Water Gap PD",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1684,
                "enabled": true,
                "defaultString": "PA State Police Fern Ridge",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1685,
                "enabled": true,
                "defaultString": "PA State Police Lehighton",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1686,
                "enabled": true,
                "defaultString": "PA State Police Swiftwater",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1687,
                "enabled": true,
                "defaultString": "Pocono Mtn PD",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1688,
                "enabled": true,
                "defaultString": "Pocono Township PD",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1689,
                "enabled": true,
                "defaultString": "Stroud Area Regional PD",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 179,
        "refListName": "PreciousMetalCategory",
        "refListItems": [
            {
                "refListItemId": 1690,
                "enabled": true,
                "defaultString": "Business",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1691,
                "enabled": true,
                "defaultString": "Individual",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 181,
        "refListName": "Proficiency_Level",
        "refListItems": "NULL"
    },
    {
        "RefListID": 182,
        "refListName": "PropertyColor",
        "refListItems": [
            {
                "refListItemId": 1698,
                "enabled": true,
                "defaultString": "Black",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1699,
                "enabled": true,
                "defaultString": "Blue",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1700,
                "enabled": true,
                "defaultString": "Brown",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1702,
                "enabled": true,
                "defaultString": "Gold-In-Color",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1703,
                "enabled": true,
                "defaultString": "Gray",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1704,
                "enabled": true,
                "defaultString": "Green",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1705,
                "enabled": true,
                "defaultString": "Orange",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1706,
                "enabled": true,
                "defaultString": "Pink",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1707,
                "enabled": true,
                "defaultString": "Purple",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1708,
                "enabled": true,
                "defaultString": "Red",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1710,
                "enabled": true,
                "defaultString": "White",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1711,
                "enabled": true,
                "defaultString": "Yellow",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3748,
                "enabled": true,
                "defaultString": "Silver-In-Color",
                "longString": "",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 183,
        "refListName": "PropertyType",
        "refListItems": [
            {
                "refListItemId": 1712,
                "enabled": true,
                "defaultString": "Boots",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1713,
                "enabled": true,
                "defaultString": "Bracelet",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1714,
                "enabled": true,
                "defaultString": "Camera",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1715,
                "enabled": true,
                "defaultString": "Credit Card",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1717,
                "enabled": true,
                "defaultString": "Earrings",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1718,
                "enabled": true,
                "defaultString": "Gun",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1719,
                "enabled": true,
                "defaultString": "Hat",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1720,
                "enabled": true,
                "defaultString": "Jacket",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1721,
                "enabled": true,
                "defaultString": "Jewelry Misc.",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1722,
                "enabled": true,
                "defaultString": "Keys",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1723,
                "enabled": true,
                "defaultString": "Knife",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1724,
                "enabled": true,
                "defaultString": "Necklace",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1725,
                "enabled": true,
                "defaultString": "Pager",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1726,
                "enabled": true,
                "defaultString": "Palm Pilot",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1727,
                "enabled": true,
                "defaultString": "Pants",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1728,
                "enabled": true,
                "defaultString": "Phone",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1729,
                "enabled": true,
                "defaultString": "Purse",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1730,
                "enabled": true,
                "defaultString": "Ring",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1731,
                "enabled": true,
                "defaultString": "Shirt",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1732,
                "enabled": true,
                "defaultString": "Shoes",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 1733,
                "enabled": true,
                "defaultString": "Sunglasses",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 1734,
                "enabled": true,
                "defaultString": "Wallet",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3749,
                "enabled": true,
                "defaultString": "Watch",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3750,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3751,
                "enabled": true,
                "defaultString": "Sneakers",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3752,
                "enabled": true,
                "defaultString": "Shorts",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3753,
                "enabled": true,
                "defaultString": "Sandals",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3754,
                "enabled": true,
                "defaultString": "Belt",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3755,
                "enabled": true,
                "defaultString": "Dress",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3756,
                "enabled": true,
                "defaultString": "Skirt",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3757,
                "enabled": true,
                "defaultString": "Bra",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3758,
                "enabled": true,
                "defaultString": "Sweatpants",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3759,
                "enabled": true,
                "defaultString": "Sweatshirt",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3760,
                "enabled": true,
                "defaultString": "Sweater",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3761,
                "enabled": true,
                "defaultString": "Hairpiece",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3762,
                "enabled": true,
                "defaultString": "Backpack",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3792,
                "enabled": true,
                "defaultString": "Socks",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3793,
                "enabled": true,
                "defaultString": "Jeans",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 5024,
                "enabled": true,
                "defaultString": "No Shoes",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5025,
                "enabled": true,
                "defaultString": "Identification (MN)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5026,
                "enabled": true,
                "defaultString": "Identification (Other)",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 184,
        "refListName": "Quality",
        "refListItems": [
            {
                "refListItemId": 1735,
                "enabled": true,
                "defaultString": "Excellent",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1736,
                "enabled": true,
                "defaultString": "Fair",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1737,
                "enabled": true,
                "defaultString": "Good",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1738,
                "enabled": true,
                "defaultString": "Poor",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 185,
        "refListName": "Race",
        "refListItems": [
            {
                "refListItemId": 1739,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1740,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1741,
                "enabled": true,
                "defaultString": "I",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1742,
                "enabled": true,
                "defaultString": "U",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1743,
                "enabled": true,
                "defaultString": "W",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 186,
        "refListName": "Rank",
        "refListItems": [
            {
                "refListItemId": 1744,
                "enabled": true,
                "defaultString": "Captain",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1745,
                "enabled": true,
                "defaultString": "Chief Deputy",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1746,
                "enabled": false,
                "defaultString": "Chief of Police",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1747,
                "enabled": false,
                "defaultString": "Colonel",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1748,
                "enabled": true,
                "defaultString": "Corporal",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1749,
                "enabled": false,
                "defaultString": "Deputy Chief",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1750,
                "enabled": true,
                "defaultString": "Lieutenant",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1751,
                "enabled": false,
                "defaultString": "Major",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1752,
                "enabled": true,
                "defaultString": "Sergeant",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1753,
                "enabled": true,
                "defaultString": "Sheriff",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1754,
                "enabled": false,
                "defaultString": "Recruit",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5449,
                "enabled": true,
                "defaultString": "Dep.",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 5450,
                "enabled": false,
                "defaultString": "Sgt.",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 5451,
                "enabled": false,
                "defaultString": "Lt.",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 5452,
                "enabled": false,
                "defaultString": "Capt.",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 5453,
                "enabled": false,
                "defaultString": "Inv.",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 5454,
                "enabled": true,
                "defaultString": "CO",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 15983,
                "enabled": true,
                "defaultString": "Detective"
            }
        ]
    },
    {
        "RefListID": 187,
        "refListName": "Rank_Commission",
        "refListItems": [
            {
                "refListItemId": 1755,
                "enabled": true,
                "defaultString": "Commission1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1756,
                "enabled": true,
                "defaultString": "Commission2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1757,
                "enabled": true,
                "defaultString": "Commission3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 188,
        "refListName": "Rank_Status",
        "refListItems": [
            {
                "refListItemId": 1758,
                "enabled": true,
                "defaultString": "Status1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1759,
                "enabled": true,
                "defaultString": "Status2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1760,
                "enabled": true,
                "defaultString": "Status3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 189,
        "refListName": "Reason_For_Return",
        "refListItems": [
            {
                "refListItemId": 1761,
                "enabled": true,
                "defaultString": "Dissolution",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1762,
                "enabled": true,
                "defaultString": "Served",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 190,
        "refListName": "Reason_For_Transfer",
        "refListItems": [
            {
                "refListItemId": 1763,
                "enabled": true,
                "defaultString": "COURT -LOCAL",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1764,
                "enabled": true,
                "defaultString": "CRIME LAB",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1765,
                "enabled": true,
                "defaultString": "Reason 1",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1766,
                "enabled": true,
                "defaultString": "Reason 2",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1767,
                "enabled": true,
                "defaultString": "Reason 3",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1768,
                "enabled": true,
                "defaultString": "Reason 4",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1769,
                "enabled": true,
                "defaultString": "RETURNED TO OWNER",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1770,
                "enabled": true,
                "defaultString": "SUBPOENA",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1771,
                "enabled": true,
                "defaultString": "TRANSFER",
                "longString": "",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 191,
        "refListName": "Reason_For_Visit",
        "refListItems": [
            {
                "refListItemId": 1772,
                "enabled": true,
                "defaultString": "Inmate Visitation",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1773,
                "enabled": true,
                "defaultString": "Pick Up Property",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 11679,
                "enabled": true,
                "defaultString": "Vendor",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 11680,
                "enabled": true,
                "defaultString": "Other, see comments",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 192,
        "refListName": "ReinstateReason",
        "refListItems": [
            {
                "refListItemId": 1774,
                "enabled": true,
                "defaultString": "Reason 1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1775,
                "enabled": true,
                "defaultString": "Reason 2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1776,
                "enabled": true,
                "defaultString": "Reason 3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 193,
        "refListName": "ReportType",
        "refListItems": [
            {
                "refListItemId": 1777,
                "enabled": true,
                "defaultString": "Disciplinary",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1778,
                "enabled": true,
                "defaultString": "Grievance",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16105,
                "enabled": true,
                "defaultString": "No Contact"
            },
            {
                "refListItemId": 15990,
                "enabled": true,
                "defaultString": "Medical"
            },
            {
                "refListItemId": 15991,
                "enabled": true,
                "defaultString": "Informational"
            }
        ]
    },
    {
        "RefListID": 194,
        "refListName": "Resident_Status",
        "refListItems": [
            {
                "refListItemId": 1779,
                "enabled": true,
                "defaultString": "This Jurisdiction",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1780,
                "enabled": true,
                "defaultString": "State",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1781,
                "enabled": true,
                "defaultString": "Out of State",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1782,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 195,
        "refListName": "Resource_Status",
        "refListItems": [
            {
                "refListItemId": 1783,
                "enabled": true,
                "defaultString": "Limited Public Access",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1784,
                "enabled": true,
                "defaultString": "Public Access",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1785,
                "enabled": true,
                "defaultString": "Public Access Denied",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 196,
        "refListName": "Resource_Type",
        "refListItems": [
            {
                "refListItemId": 1786,
                "enabled": true,
                "defaultString": "Government owned",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1787,
                "enabled": true,
                "defaultString": "Private property",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 197,
        "refListName": "Restriction_Code",
        "refListItems": [
            {
                "refListItemId": 1788,
                "enabled": true,
                "defaultString": "Automatic Transmission {G}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1789,
                "enabled": true,
                "defaultString": "Built Up Brakes {N}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1790,
                "enabled": true,
                "defaultString": "Built Up Seat {O}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1791,
                "enabled": true,
                "defaultString": "Corrective Lenses {A}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1792,
                "enabled": true,
                "defaultString": "Daylight Only {E}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1793,
                "enabled": true,
                "defaultString": "Hand Controls {F}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1794,
                "enabled": true,
                "defaultString": "Hearing Impaired {J}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1795,
                "enabled": true,
                "defaultString": "Identification Card Only {Z}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1796,
                "enabled": true,
                "defaultString": "Intrastate Commerce Only {W}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1797,
                "enabled": true,
                "defaultString": "Learners License {Y}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1798,
                "enabled": true,
                "defaultString": "Left Foot Accelerator {P}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1799,
                "enabled": true,
                "defaultString": "Mechanical Signals {R}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1800,
                "enabled": true,
                "defaultString": "Motorcylce {C}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1801,
                "enabled": true,
                "defaultString": "Motor-driven Cycles {B}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1802,
                "enabled": true,
                "defaultString": "Outside Mirror - Left {D}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1803,
                "enabled": true,
                "defaultString": "Outside Mirror - Right & Left {I}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1804,
                "enabled": true,
                "defaultString": "Outside Mirror - Right {H}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1805,
                "enabled": true,
                "defaultString": "Power Brakes {M}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1806,
                "enabled": true,
                "defaultString": "Power Steering {L}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1807,
                "enabled": true,
                "defaultString": "School Bus or Class D {S}",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 1808,
                "enabled": true,
                "defaultString": "Vehicle w/o Airbrakes {K}",
                "longString": "",
                "sequenceNumber": 21
            }
        ]
    },
    {
        "RefListID": 198,
        "refListName": "Revocation_Reason",
        "refListItems": [
            {
                "refListItemId": 1809,
                "enabled": true,
                "defaultString": "Convicted of felony",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1810,
                "enabled": true,
                "defaultString": "Hearing panel",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1811,
                "enabled": true,
                "defaultString": "Major complaint",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 199,
        "refListName": "RevokedReason",
        "refListItems": [
            {
                "refListItemId": 1812,
                "enabled": true,
                "defaultString": "Criminal Charges",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1813,
                "enabled": true,
                "defaultString": "PFA",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1814,
                "enabled": true,
                "defaultString": "Stupidity",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 200,
        "refListName": "Road_Character",
        "refListItems": [
            {
                "refListItemId": 1815,
                "enabled": true,
                "defaultString": "Curve-down grade {6}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1816,
                "enabled": true,
                "defaultString": "Curve-hillcrest {8}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1817,
                "enabled": true,
                "defaultString": "Curve-level {5}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1818,
                "enabled": true,
                "defaultString": "Curve-up grade {7}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1819,
                "enabled": true,
                "defaultString": "Straight-down grade {2}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1820,
                "enabled": true,
                "defaultString": "Straight-hillcrest {4}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1821,
                "enabled": true,
                "defaultString": "Straight-level {1}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1822,
                "enabled": true,
                "defaultString": "Straight-up grade {3}",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 201,
        "refListName": "Road_Condition",
        "refListItems": [
            {
                "refListItemId": 1823,
                "enabled": true,
                "defaultString": "Dry {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1824,
                "enabled": true,
                "defaultString": "Icy {3}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1825,
                "enabled": true,
                "defaultString": "Muddy {5}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1826,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1827,
                "enabled": true,
                "defaultString": "Snowy/Slushy {4}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1828,
                "enabled": true,
                "defaultString": "Wet {2}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 202,
        "refListName": "Safety_Equipment",
        "refListItems": [
            {
                "refListItemId": 1829,
                "enabled": true,
                "defaultString": "Air Bags - Deployed, Belts Not Used {43}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1830,
                "enabled": true,
                "defaultString": "Air Bags - Deployed, Belts Used {41}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1831,
                "enabled": true,
                "defaultString": "Air Bags - Not Deployed, Belts Not Used {44}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1832,
                "enabled": true,
                "defaultString": "Air Bags - Not Deployed, Belts Used {42}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1833,
                "enabled": true,
                "defaultString": "Child Restraint - Child Restraint Used {81}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1834,
                "enabled": true,
                "defaultString": "Child Restraint - None Used {83}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1835,
                "enabled": true,
                "defaultString": "Child Restraint - Other Restraint Used {82}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1836,
                "enabled": true,
                "defaultString": "Lap/Shoulder Harness - Both Used {24}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1837,
                "enabled": true,
                "defaultString": "Lap/Shoulder Harness - Lap Only Used {21}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1838,
                "enabled": true,
                "defaultString": "Lap/Shoulder Harness - Neither Used {22}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1839,
                "enabled": true,
                "defaultString": "Lap/Shoulder Harness - Shoulder Only Used {23}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1840,
                "enabled": true,
                "defaultString": "Lap-Belt Only - Fastened {11}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1841,
                "enabled": true,
                "defaultString": "Lap-Belt Only - Not Fastened {12}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1842,
                "enabled": true,
                "defaultString": "Motorcycle Helmet - None Used {31}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1843,
                "enabled": true,
                "defaultString": "Motorcycle Helmet - Used {32}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 1844,
                "enabled": true,
                "defaultString": "None {01}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 1845,
                "enabled": true,
                "defaultString": "Not Applicable {95}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 1846,
                "enabled": true,
                "defaultString": "Pedal Cyclist/Pedestrian - Contr Clothing {91}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 1847,
                "enabled": true,
                "defaultString": "Pedal Cyclist/Pedestrian - Non Contr Clothing {91}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 1848,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 20
            }
        ]
    },
    {
        "RefListID": 203,
        "refListName": "School",
        "refListItems": "NULL"
    },
    {
        "RefListID": 204,
        "refListName": "Seating_Position",
        "refListItems": [
            {
                "refListItemId": 1849,
                "enabled": true,
                "defaultString": "Back Center {8}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1850,
                "enabled": true,
                "defaultString": "Back Left {7}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1851,
                "enabled": true,
                "defaultString": "Back Right {9}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1852,
                "enabled": true,
                "defaultString": "Driver {1}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1853,
                "enabled": true,
                "defaultString": "Front Center {2}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1854,
                "enabled": true,
                "defaultString": "Front Side {3}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1855,
                "enabled": true,
                "defaultString": "Middle Center {5}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1856,
                "enabled": true,
                "defaultString": "Middle Left {4}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 1857,
                "enabled": true,
                "defaultString": "Middle Right {6}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 1858,
                "enabled": true,
                "defaultString": "Motorcycle Back {11}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 1859,
                "enabled": true,
                "defaultString": "Motorcycle Front {10}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 1860,
                "enabled": true,
                "defaultString": "Occ. of non-motorized vehicle {14}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 1861,
                "enabled": true,
                "defaultString": "Pedestrian {12}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 1862,
                "enabled": true,
                "defaultString": "Rider of domestic animal {13}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 1863,
                "enabled": true,
                "defaultString": "Victim other circumstances {15}",
                "longString": "",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 205,
        "refListName": "SectorN_DT",
        "refListItems": [
            {
                "refListItemId": 1864,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 206,
        "refListName": "SectorN_NC",
        "refListItems": [
            {
                "refListItemId": 1865,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1866,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1867,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1868,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1869,
                "enabled": true,
                "defaultString": "05",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 207,
        "refListName": "SectorN_NE",
        "refListItems": [
            {
                "refListItemId": 1870,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1871,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1872,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1873,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1874,
                "enabled": true,
                "defaultString": "05",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1875,
                "enabled": true,
                "defaultString": "06",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 208,
        "refListName": "SectorN_NW",
        "refListItems": [
            {
                "refListItemId": 1876,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1877,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1878,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1879,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1880,
                "enabled": true,
                "defaultString": "05",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1881,
                "enabled": true,
                "defaultString": "06",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 209,
        "refListName": "SectorN_OC",
        "refListItems": [
            {
                "refListItemId": 1882,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 210,
        "refListName": "SectorN_SC",
        "refListItems": [
            {
                "refListItemId": 1883,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1884,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1885,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 211,
        "refListName": "SectorN_SE",
        "refListItems": [
            {
                "refListItemId": 1886,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1887,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1888,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1889,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 212,
        "refListName": "SectorN_SW",
        "refListItems": [
            {
                "refListItemId": 1890,
                "enabled": true,
                "defaultString": "01",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1891,
                "enabled": true,
                "defaultString": "02",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1892,
                "enabled": true,
                "defaultString": "03",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1893,
                "enabled": true,
                "defaultString": "04",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1894,
                "enabled": true,
                "defaultString": "05",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1895,
                "enabled": true,
                "defaultString": "06",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1896,
                "enabled": true,
                "defaultString": "07",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 213,
        "refListName": "SectorRegion",
        "refListItems": [
            {
                "refListItemId": 1897,
                "enabled": true,
                "defaultString": "DT",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1898,
                "enabled": true,
                "defaultString": "NC",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1899,
                "enabled": true,
                "defaultString": "NE",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1900,
                "enabled": true,
                "defaultString": "NW",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1901,
                "enabled": true,
                "defaultString": "OC",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1902,
                "enabled": true,
                "defaultString": "SC",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1903,
                "enabled": true,
                "defaultString": "SE",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1904,
                "enabled": true,
                "defaultString": "SW",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 214,
        "refListName": "SectorSuffix",
        "refListItems": [
            {
                "refListItemId": 1905,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1906,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1907,
                "enabled": true,
                "defaultString": "H",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1908,
                "enabled": true,
                "defaultString": "P",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1909,
                "enabled": true,
                "defaultString": "R",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1910,
                "enabled": true,
                "defaultString": "S",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 215,
        "refListName": "Sex",
        "refListItems": [
            {
                "refListItemId": 1911,
                "enabled": true,
                "defaultString": "F",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1912,
                "enabled": true,
                "defaultString": "M",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1913,
                "enabled": true,
                "defaultString": "U",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 216,
        "refListName": "SexOffendersOffenseClass",
        "refListItems": [
            {
                "refListItemId": 1914,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1915,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1916,
                "enabled": true,
                "defaultString": "C",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 217,
        "refListName": "Sheriff_Class",
        "refListItems": [
            {
                "refListItemId": 1917,
                "enabled": true,
                "defaultString": "1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1918,
                "enabled": true,
                "defaultString": "2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1919,
                "enabled": true,
                "defaultString": "3",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1920,
                "enabled": true,
                "defaultString": "4",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1921,
                "enabled": true,
                "defaultString": "5",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 1922,
                "enabled": true,
                "defaultString": "6",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 1923,
                "enabled": true,
                "defaultString": "7",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 1924,
                "enabled": true,
                "defaultString": "8",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 218,
        "refListName": "Skin",
        "refListItems": [
            {
                "refListItemId": 1925,
                "enabled": true,
                "defaultString": "DK",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1926,
                "enabled": true,
                "defaultString": "LT",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1927,
                "enabled": true,
                "defaultString": "MD",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 219,
        "refListName": "Special_Handling",
        "refListItems": [
            {
                "refListItemId": 1928,
                "enabled": true,
                "defaultString": "Biohazardous",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1929,
                "enabled": true,
                "defaultString": "Hazardous",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1930,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 220,
        "refListName": "Staff_Assignment",
        "refListItems": [
            {
                "refListItemId": 1931,
                "enabled": true,
                "defaultString": "Fired",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1932,
                "enabled": true,
                "defaultString": "Hired",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1933,
                "enabled": true,
                "defaultString": "Laid off",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 1934,
                "enabled": true,
                "defaultString": "Service1",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 1935,
                "enabled": true,
                "defaultString": "Service2",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 221,
        "refListName": "Staff_Assignment_Type",
        "refListItems": [
            {
                "refListItemId": 1936,
                "enabled": true,
                "defaultString": "Type1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1937,
                "enabled": true,
                "defaultString": "Type2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1938,
                "enabled": true,
                "defaultString": "Type3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 222,
        "refListName": "Staff_Service_Type",
        "refListItems": [
            {
                "refListItemId": 1939,
                "enabled": true,
                "defaultString": "Type1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1940,
                "enabled": true,
                "defaultString": "Type2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1941,
                "enabled": true,
                "defaultString": "Type3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 223,
        "refListName": "Staff_Skill_Level",
        "refListItems": [
            {
                "refListItemId": 1942,
                "enabled": true,
                "defaultString": "Beginner",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1943,
                "enabled": true,
                "defaultString": "Expert",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1944,
                "enabled": true,
                "defaultString": "Intermediate",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 224,
        "refListName": "Staff_Skill_Name",
        "refListItems": [
            {
                "refListItemId": 1945,
                "enabled": true,
                "defaultString": "Skill 1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 1946,
                "enabled": true,
                "defaultString": "Skill 2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 1947,
                "enabled": true,
                "defaultString": "Skill 3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 526,
        "refListName": "Agency_Contexts",
        "refListItems": [
            {
                "refListItemId": 16005,
                "enabled": true,
                "defaultString": "Controlling",
                "longString": "Use the Agency list for Controlling",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16006,
                "enabled": true,
                "defaultString": "Arresting",
                "longString": "Use the Agency list for Housing",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 227,
        "refListName": "Storage_Instructions",
        "refListItems": [
            {
                "refListItemId": 2459,
                "enabled": true,
                "defaultString": "Air Dry",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2460,
                "enabled": true,
                "defaultString": "None",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2461,
                "enabled": true,
                "defaultString": "Refrigerate",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 228,
        "refListName": "StrangerToStranger",
        "refListItems": [
            {
                "refListItemId": 2462,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2463,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2464,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 229,
        "refListName": "Structure_Type",
        "refListItems": [
            {
                "refListItemId": 2465,
                "enabled": true,
                "defaultString": "Brick",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2466,
                "enabled": true,
                "defaultString": "Concrete",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2467,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2468,
                "enabled": true,
                "defaultString": "Stone",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2469,
                "enabled": true,
                "defaultString": "Wood",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 230,
        "refListName": "Subject_Identified",
        "refListItems": [
            {
                "refListItemId": 2470,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2471,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 231,
        "refListName": "Subject_Located",
        "refListItems": [
            {
                "refListItemId": 2472,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2473,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 232,
        "refListName": "Suffix",
        "refListItems": [
            {
                "refListItemId": 2474,
                "enabled": true,
                "defaultString": "II",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2475,
                "enabled": true,
                "defaultString": "III",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2476,
                "enabled": true,
                "defaultString": "IV",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2477,
                "enabled": true,
                "defaultString": "JR",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2478,
                "enabled": true,
                "defaultString": "SR",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 233,
        "refListName": "Surface_Construction",
        "refListItems": [
            {
                "refListItemId": 2479,
                "enabled": true,
                "defaultString": "Asphalt {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2480,
                "enabled": true,
                "defaultString": "Brick {3}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2481,
                "enabled": true,
                "defaultString": "Concrete {2}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2482,
                "enabled": true,
                "defaultString": "Other {8}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2483,
                "enabled": true,
                "defaultString": "Unpaved {4}",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 234,
        "refListName": "Suspected_Drug_Type",
        "refListItems": [
            {
                "refListItemId": 2484,
                "enabled": true,
                "defaultString": "Crack Cocaine",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2485,
                "enabled": true,
                "defaultString": "Heroin",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2486,
                "enabled": true,
                "defaultString": "Marijuana",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2487,
                "enabled": true,
                "defaultString": "Meth",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 235,
        "refListName": "Suspension_Reason",
        "refListItems": [
            {
                "refListItemId": 2488,
                "enabled": true,
                "defaultString": "Hearing Panel",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2489,
                "enabled": true,
                "defaultString": "Moving Violations",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2490,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2491,
                "enabled": true,
                "defaultString": "Too Many Complaints",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 236,
        "refListName": "TaxiCompany",
        "refListItems": [
            {
                "refListItemId": 2492,
                "enabled": true,
                "defaultString": "Checker Cab",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2493,
                "enabled": true,
                "defaultString": "Fleet",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2494,
                "enabled": true,
                "defaultString": "Some Good Drivin",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2495,
                "enabled": true,
                "defaultString": "Yellow Cab",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 237,
        "refListName": "TaxiPermitCharges",
        "refListItems": [
            {
                "refListItemId": 2496,
                "enabled": true,
                "defaultString": "Altering Bureua Issued Documentation",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2497,
                "enabled": true,
                "defaultString": "Carrying Non-Paying Passengers",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2498,
                "enabled": true,
                "defaultString": "Color Code",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2499,
                "enabled": true,
                "defaultString": "Company Violation Drivers Permit",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2500,
                "enabled": true,
                "defaultString": "CPNC Holder/Co. Affiliation",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2501,
                "enabled": true,
                "defaultString": "Disorderly Conduct",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2502,
                "enabled": true,
                "defaultString": "Display of Permit",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2503,
                "enabled": true,
                "defaultString": "Dress Code",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2504,
                "enabled": true,
                "defaultString": "English Deficiency",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2505,
                "enabled": true,
                "defaultString": "Expired Correction Slip (Driver, CPNC, Co)",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2506,
                "enabled": true,
                "defaultString": "Expired Permit",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2507,
                "enabled": true,
                "defaultString": "Failure to Cooperate",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2508,
                "enabled": true,
                "defaultString": "Failure to Provide Air Conditioning",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2509,
                "enabled": true,
                "defaultString": "Failure to Transfer Permit",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2510,
                "enabled": true,
                "defaultString": "Impeding Traffic (Traffic Court)/Blocking Curb Lane/Blocking Crosswalk",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2511,
                "enabled": true,
                "defaultString": "Interior Requirements",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2512,
                "enabled": true,
                "defaultString": "Line Jumping Airport",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2513,
                "enabled": true,
                "defaultString": "Map",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 2514,
                "enabled": true,
                "defaultString": "Most Direct Route/Destination",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 2515,
                "enabled": true,
                "defaultString": "No CPNC Issued",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 2516,
                "enabled": true,
                "defaultString": "No Driver's License (Traffic Court)",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 2517,
                "enabled": true,
                "defaultString": "No Inspection Sticker (Driver, CPNC, Co)",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 2518,
                "enabled": true,
                "defaultString": "No insurance",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 2519,
                "enabled": true,
                "defaultString": "No Insurance Sticker (Driver, CPNC, Co)",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 2520,
                "enabled": true,
                "defaultString": "No Permit issued",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 2521,
                "enabled": true,
                "defaultString": "No Taxi Meter",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 2522,
                "enabled": true,
                "defaultString": "No Trunk Space",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 2523,
                "enabled": true,
                "defaultString": "No Two Communication",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 2524,
                "enabled": true,
                "defaultString": "Non-City Taxicab",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 2525,
                "enabled": true,
                "defaultString": "Office Not Staffed",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 2526,
                "enabled": true,
                "defaultString": "Overcharge",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 2527,
                "enabled": true,
                "defaultString": "Passenger Abuse",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 2528,
                "enabled": true,
                "defaultString": "Passenger's Right Sign",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 2529,
                "enabled": true,
                "defaultString": "Picking Up Top Level (Airport Ord)",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 2530,
                "enabled": true,
                "defaultString": "Seating Capacity",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 2531,
                "enabled": true,
                "defaultString": "Trip Refusal",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 2532,
                "enabled": true,
                "defaultString": "Trip Sheets",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 2533,
                "enabled": true,
                "defaultString": "Unsealed Meter/Impr. Meter (Driver, CPNC, Co)",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 2534,
                "enabled": true,
                "defaultString": "Use of Stand or Line Jumping",
                "longString": "",
                "sequenceNumber": 39
            }
        ]
    },
    {
        "RefListID": 238,
        "refListName": "TaxiPermitDisposition",
        "refListItems": [
            {
                "refListItemId": 2535,
                "enabled": true,
                "defaultString": "Dismissed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2536,
                "enabled": true,
                "defaultString": "Guilty",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2537,
                "enabled": true,
                "defaultString": "Guilty/Fine Susp.",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2538,
                "enabled": true,
                "defaultString": "Not Guilty",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 239,
        "refListName": "TaxiPermitInspector",
        "refListItems": [
            {
                "refListItemId": 2539,
                "enabled": true,
                "defaultString": "Howard",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2540,
                "enabled": true,
                "defaultString": "Jones",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2541,
                "enabled": true,
                "defaultString": "Richards",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2542,
                "enabled": true,
                "defaultString": "Smith",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 240,
        "refListName": "TaxiPermitsHoldReason",
        "refListItems": [
            {
                "refListItemId": 2543,
                "enabled": true,
                "defaultString": "Need to have conference",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2544,
                "enabled": true,
                "defaultString": "Non-payment of insurance",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 241,
        "refListName": "TaxiPermitStatus",
        "refListItems": [
            {
                "refListItemId": 2545,
                "enabled": true,
                "defaultString": "Active",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2546,
                "enabled": true,
                "defaultString": "Denied",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2547,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2548,
                "enabled": true,
                "defaultString": "Revoked",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2549,
                "enabled": true,
                "defaultString": "Suspended",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 242,
        "refListName": "TaxiPermitType",
        "refListItems": [
            {
                "refListItemId": 2550,
                "enabled": true,
                "defaultString": "Carriage",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2551,
                "enabled": true,
                "defaultString": "Taxicab",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 244,
        "refListName": "Temporary_Location",
        "refListItems": [
            {
                "refListItemId": 2561,
                "enabled": true,
                "defaultString": "1st Floor Safe",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2562,
                "enabled": true,
                "defaultString": "2nd Floor Gun Box",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2563,
                "enabled": true,
                "defaultString": "2nd Floor Safe",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 245,
        "refListName": "Traffic_Control",
        "refListItems": [
            {
                "refListItemId": 2564,
                "enabled": true,
                "defaultString": "Flagger {11}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2565,
                "enabled": true,
                "defaultString": "Flashing beacon {7}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2566,
                "enabled": true,
                "defaultString": "Lane control device {10}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2567,
                "enabled": true,
                "defaultString": "No passing zone {12}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2568,
                "enabled": true,
                "defaultString": "None {97}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2569,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2570,
                "enabled": true,
                "defaultString": "Pedestrian control {5}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2571,
                "enabled": true,
                "defaultString": "Police officer {1}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2572,
                "enabled": true,
                "defaultString": "RR cross bucks/pave. mark {4}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2573,
                "enabled": true,
                "defaultString": "RR crossing gates {2}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2574,
                "enabled": true,
                "defaultString": "RR flashing lights {3}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2575,
                "enabled": true,
                "defaultString": "Stop sign {8}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2576,
                "enabled": true,
                "defaultString": "Traffic signal {6}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2577,
                "enabled": true,
                "defaultString": "Yield sign {9}",
                "longString": "",
                "sequenceNumber": 14
            }
        ]
    },
    {
        "RefListID": 246,
        "refListName": "Trafficway_Lanes",
        "refListItems": [
            {
                "refListItemId": 2578,
                "enabled": true,
                "defaultString": "1 lane {1}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2579,
                "enabled": true,
                "defaultString": "2 lanes {2}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2580,
                "enabled": true,
                "defaultString": "3 lanes {3}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2581,
                "enabled": true,
                "defaultString": "4 lanes {4}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2582,
                "enabled": true,
                "defaultString": "5 lanes {5}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2583,
                "enabled": true,
                "defaultString": "6 lanes or more {6}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 247,
        "refListName": "Transaction_Type",
        "refListItems": [
            {
                "refListItemId": 2584,
                "enabled": true,
                "defaultString": "Checkin",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2585,
                "enabled": true,
                "defaultString": "Checkout",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2586,
                "enabled": true,
                "defaultString": "CONDEMNED",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2587,
                "enabled": true,
                "defaultString": "DESTROYED",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2588,
                "enabled": true,
                "defaultString": "DISPOSED",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 249,
        "refListName": "TrialType",
        "refListItems": [
            {
                "refListItemId": 2591,
                "enabled": true,
                "defaultString": "Court Adjudicated",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2592,
                "enabled": true,
                "defaultString": "Jury",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 251,
        "refListName": "Trustee_Type",
        "refListItems": [
            {
                "refListItemId": 2598,
                "enabled": true,
                "defaultString": "A",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2599,
                "enabled": true,
                "defaultString": "B",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 252,
        "refListName": "Type_Of_Ownership",
        "refListItems": [
            {
                "refListItemId": 2600,
                "enabled": true,
                "defaultString": "Corporation",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2601,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2602,
                "enabled": true,
                "defaultString": "Partnership",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2603,
                "enabled": true,
                "defaultString": "Sole-Proprietor",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 253,
        "refListName": "TypeOfService",
        "refListItems": [
            {
                "refListItemId": 2604,
                "enabled": true,
                "defaultString": "Corporation",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2605,
                "enabled": true,
                "defaultString": "Non Est",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2606,
                "enabled": true,
                "defaultString": "Notorious",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2607,
                "enabled": true,
                "defaultString": "Personal",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2608,
                "enabled": true,
                "defaultString": "Tack and Nail",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 254,
        "refListName": "Unk",
        "refListItems": [
            {
                "refListItemId": 2609,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2610,
                "enabled": true,
                "defaultString": "Unk",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2611,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 255,
        "refListName": "USState",
        "refListItems": [
            {
                "refListItemId": 2612,
                "enabled": true,
                "defaultString": "AK",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2613,
                "enabled": true,
                "defaultString": "AL",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2614,
                "enabled": true,
                "defaultString": "AR",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2615,
                "enabled": true,
                "defaultString": "AZ",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2616,
                "enabled": true,
                "defaultString": "CA",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2617,
                "enabled": true,
                "defaultString": "CO",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2618,
                "enabled": true,
                "defaultString": "CT",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2619,
                "enabled": true,
                "defaultString": "DC",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2620,
                "enabled": true,
                "defaultString": "DE",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2621,
                "enabled": true,
                "defaultString": "FL",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2622,
                "enabled": true,
                "defaultString": "GA",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2623,
                "enabled": true,
                "defaultString": "HI",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2624,
                "enabled": true,
                "defaultString": "IA",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2625,
                "enabled": true,
                "defaultString": "ID",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2626,
                "enabled": true,
                "defaultString": "IL",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2627,
                "enabled": true,
                "defaultString": "IN",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2628,
                "enabled": true,
                "defaultString": "KS",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2629,
                "enabled": true,
                "defaultString": "KY",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 2630,
                "enabled": true,
                "defaultString": "LA",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 2631,
                "enabled": true,
                "defaultString": "MA",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 2632,
                "enabled": true,
                "defaultString": "MD",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 2633,
                "enabled": true,
                "defaultString": "ME",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 2634,
                "enabled": true,
                "defaultString": "MI",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 2635,
                "enabled": true,
                "defaultString": "MN",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 2636,
                "enabled": true,
                "defaultString": "MO",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 2637,
                "enabled": true,
                "defaultString": "MS",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 2638,
                "enabled": true,
                "defaultString": "MT",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 2639,
                "enabled": true,
                "defaultString": "NC",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 2640,
                "enabled": true,
                "defaultString": "ND",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 2641,
                "enabled": true,
                "defaultString": "NE",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 2642,
                "enabled": true,
                "defaultString": "NH",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 2643,
                "enabled": true,
                "defaultString": "NJ",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 2644,
                "enabled": true,
                "defaultString": "NM",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 2645,
                "enabled": true,
                "defaultString": "NV",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 2646,
                "enabled": true,
                "defaultString": "NY",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 2647,
                "enabled": true,
                "defaultString": "OH",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 2648,
                "enabled": true,
                "defaultString": "OK",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 2649,
                "enabled": true,
                "defaultString": "OR",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 2650,
                "enabled": true,
                "defaultString": "PA",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 2651,
                "enabled": true,
                "defaultString": "PR",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 2652,
                "enabled": true,
                "defaultString": "RI",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 2653,
                "enabled": true,
                "defaultString": "SC",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 2654,
                "enabled": true,
                "defaultString": "SD",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 2655,
                "enabled": true,
                "defaultString": "TN",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 2656,
                "enabled": true,
                "defaultString": "TX",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 2657,
                "enabled": true,
                "defaultString": "UT",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 2658,
                "enabled": true,
                "defaultString": "VA",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 2659,
                "enabled": true,
                "defaultString": "VT",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 2660,
                "enabled": true,
                "defaultString": "WA",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 2661,
                "enabled": true,
                "defaultString": "WI",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 2662,
                "enabled": true,
                "defaultString": "WV",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 2663,
                "enabled": true,
                "defaultString": "WY",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 15428,
                "enabled": true,
                "defaultString": "VI",
                "longString": "",
                "sequenceNumber": 53
            }
        ]
    },
    {
        "RefListID": 256,
        "refListName": "Vehicle_Attachment",
        "refListItems": [
            {
                "refListItemId": 2664,
                "enabled": true,
                "defaultString": "4-Wheel Trailer {5}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2665,
                "enabled": true,
                "defaultString": "Boat Trailer {6}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2666,
                "enabled": true,
                "defaultString": "Camper Trailer {7}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2667,
                "enabled": true,
                "defaultString": "Double Trailer {11}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2668,
                "enabled": true,
                "defaultString": "Mobile Home {2}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2669,
                "enabled": true,
                "defaultString": "None {1}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2670,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2671,
                "enabled": true,
                "defaultString": "Pole Trailer {10}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2672,
                "enabled": true,
                "defaultString": "Semi Trailer {3}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2673,
                "enabled": true,
                "defaultString": "Tanker {9}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2674,
                "enabled": true,
                "defaultString": "Towed Vehicle {8}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2675,
                "enabled": true,
                "defaultString": "Utility Trailer {4}",
                "longString": "",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 257,
        "refListName": "Vehicle_Body",
        "refListItems": [
            {
                "refListItemId": 2676,
                "enabled": true,
                "defaultString": "2-Door {2D}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2677,
                "enabled": true,
                "defaultString": "4-Door {4D}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2678,
                "enabled": true,
                "defaultString": "Convertible {CV}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2679,
                "enabled": true,
                "defaultString": "N/A {NA}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2680,
                "enabled": true,
                "defaultString": "Other {OT}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2681,
                "enabled": true,
                "defaultString": "Unknown {UN}",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 258,
        "refListName": "Vehicle_Type",
        "refListItems": [
            {
                "refListItemId": 2682,
                "enabled": true,
                "defaultString": "ATV {19}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2683,
                "enabled": true,
                "defaultString": "Auto {1}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2684,
                "enabled": true,
                "defaultString": "Bus - Commercial {7}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2685,
                "enabled": true,
                "defaultString": "Bus - Other {9}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2686,
                "enabled": true,
                "defaultString": "Bus - Schoolbus {8}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2687,
                "enabled": true,
                "defaultString": "Farm Machine {14}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2688,
                "enabled": true,
                "defaultString": "Mobile Home/RV {18}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2689,
                "enabled": true,
                "defaultString": "Moped {11}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2690,
                "enabled": true,
                "defaultString": "Motorcycle {10}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2691,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2692,
                "enabled": true,
                "defaultString": "Pedal Cycle {13}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2693,
                "enabled": true,
                "defaultString": "Pickup {3}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2694,
                "enabled": true,
                "defaultString": "Ridden Animal {17}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2695,
                "enabled": true,
                "defaultString": "Road Equipment 16}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2696,
                "enabled": true,
                "defaultString": "Scooter {12}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2697,
                "enabled": true,
                "defaultString": "Station Wagon {2}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2698,
                "enabled": true,
                "defaultString": "Train {15}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2699,
                "enabled": true,
                "defaultString": "Truck - Other {6}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 2700,
                "enabled": true,
                "defaultString": "Truck/Tractor {5}",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 2701,
                "enabled": true,
                "defaultString": "Van {4}",
                "longString": "",
                "sequenceNumber": 20
            }
        ]
    },
    {
        "RefListID": 259,
        "refListName": "Vehicle_Usage",
        "refListItems": [
            {
                "refListItemId": 2702,
                "enabled": true,
                "defaultString": "Agriculture {8}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2703,
                "enabled": true,
                "defaultString": "Ambulance/Paramedical {4}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2704,
                "enabled": true,
                "defaultString": "Bus/Passenger Transport {12}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2705,
                "enabled": true,
                "defaultString": "Construction {3}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2706,
                "enabled": true,
                "defaultString": "Driver Training {2}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2707,
                "enabled": true,
                "defaultString": "Firefighting {13}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2708,
                "enabled": true,
                "defaultString": "Military {5}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2709,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2710,
                "enabled": true,
                "defaultString": "Other Business {11}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2711,
                "enabled": true,
                "defaultString": "Personal {1}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2712,
                "enabled": true,
                "defaultString": "Police {10}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2713,
                "enabled": true,
                "defaultString": "Taxi {6}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2714,
                "enabled": true,
                "defaultString": "Transport Property {7}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2715,
                "enabled": true,
                "defaultString": "Wrecker/Tow {9}",
                "longString": "",
                "sequenceNumber": 14
            }
        ]
    },
    {
        "RefListID": 261,
        "refListName": "VehicleTransmissionType",
        "refListItems": [
            {
                "refListItemId": 2748,
                "enabled": true,
                "defaultString": "Automatic",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2749,
                "enabled": true,
                "defaultString": "Manual",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2750,
                "enabled": true,
                "defaultString": "Spd.",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 263,
        "refListName": "VictimNotification_Status",
        "refListItems": [
            {
                "refListItemId": 2754,
                "enabled": true,
                "defaultString": "Not Notified",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2755,
                "enabled": true,
                "defaultString": "Notified",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 264,
        "refListName": "Visa_Type",
        "refListItems": "NULL"
    },
    {
        "RefListID": 265,
        "refListName": "Visible_Injury",
        "refListItems": [
            {
                "refListItemId": 2756,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2757,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 266,
        "refListName": "Vision_Obscured_By",
        "refListItems": [
            {
                "refListItemId": 2758,
                "enabled": true,
                "defaultString": "Blinded by headlights {13}",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2759,
                "enabled": true,
                "defaultString": "Blinded by sunlight {10}",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2760,
                "enabled": true,
                "defaultString": "Blowing snow/sand {4}",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2761,
                "enabled": true,
                "defaultString": "Buildings {1}",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2762,
                "enabled": true,
                "defaultString": "Curve in road {6}",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2763,
                "enabled": true,
                "defaultString": "Dust {12}",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2764,
                "enabled": true,
                "defaultString": "Embankment {14}",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2765,
                "enabled": true,
                "defaultString": "Fire/smoke {11}",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2766,
                "enabled": true,
                "defaultString": "Fog {7}",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2767,
                "enabled": true,
                "defaultString": "Hillcrest {5}",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2768,
                "enabled": true,
                "defaultString": "Moving vehicle(s) {9}",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2769,
                "enabled": true,
                "defaultString": "Not obscured {97}",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2770,
                "enabled": true,
                "defaultString": "Other {98}",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2771,
                "enabled": true,
                "defaultString": "Parked vehicle {8}",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2772,
                "enabled": true,
                "defaultString": "Rain on windshield {15}",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2773,
                "enabled": true,
                "defaultString": "Signboard {2}",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2774,
                "enabled": true,
                "defaultString": "Snow on windshield {16}",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2775,
                "enabled": true,
                "defaultString": "Trees, crops, bushes {3}",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 2776,
                "enabled": true,
                "defaultString": "Unknown {99}",
                "longString": "",
                "sequenceNumber": 19
            }
        ]
    },
    {
        "RefListID": 267,
        "refListName": "WarrantType",
        "refListItems": [
            {
                "refListItemId": 5206,
                "enabled": true,
                "defaultString": "CP",
                "longString": "Civil Process",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5207,
                "enabled": true,
                "defaultString": "FE",
                "longString": "Felony",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5208,
                "enabled": true,
                "defaultString": "GM",
                "longString": "Gross Misdemeanor",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5209,
                "enabled": true,
                "defaultString": "JD",
                "longString": "Juvenile",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5210,
                "enabled": true,
                "defaultString": "MS",
                "longString": "Misdemeanor",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2777,
                "enabled": false,
                "defaultString": "BE",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2778,
                "enabled": false,
                "defaultString": "JU",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2779,
                "enabled": false,
                "defaultString": "PA",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2780,
                "enabled": false,
                "defaultString": "PR",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 268,
        "refListName": "WeaponType",
        "refListItems": [
            {
                "refListItemId": 2781,
                "enabled": true,
                "defaultString": "Gun",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2782,
                "enabled": true,
                "defaultString": "Hands/Fist, Etc.",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2783,
                "enabled": true,
                "defaultString": "Knife Cutting Tool",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2784,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 269,
        "refListName": "YesNoBig",
        "refListItems": [
            {
                "refListItemId": 2785,
                "enabled": true,
                "defaultString": "No",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2786,
                "enabled": true,
                "defaultString": "Yes",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 270,
        "refListName": "InterfaceTypes",
        "refListItems": [
            {
                "refListItemId": 2787,
                "enabled": true,
                "defaultString": "YesNo",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2788,
                "enabled": true,
                "defaultString": "TextBox",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2789,
                "enabled": true,
                "defaultString": "BigText",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2790,
                "enabled": true,
                "defaultString": "Select",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2791,
                "enabled": true,
                "defaultString": "Date",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4245,
                "enabled": true,
                "defaultString": "Signature",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 271,
        "refListName": "DrugType",
        "refListItems": [
            {
                "refListItemId": 2792,
                "enabled": true,
                "defaultString": "Amphetamine/Meth",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2793,
                "enabled": true,
                "defaultString": "Barbiturates",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2794,
                "enabled": true,
                "defaultString": "Cocaine",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2795,
                "enabled": true,
                "defaultString": "Crack Cocaine",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2796,
                "enabled": true,
                "defaultString": "Hashish",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2797,
                "enabled": true,
                "defaultString": "Heroin",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2798,
                "enabled": true,
                "defaultString": "LSD",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2799,
                "enabled": true,
                "defaultString": "Marijuana",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2800,
                "enabled": true,
                "defaultString": "Morphine",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2801,
                "enabled": true,
                "defaultString": "Opium",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2802,
                "enabled": true,
                "defaultString": "Other Depressants",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2803,
                "enabled": true,
                "defaultString": "Other Drugs",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2804,
                "enabled": true,
                "defaultString": "Other Hallucinogens",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2805,
                "enabled": true,
                "defaultString": "Other Narcotics",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2806,
                "enabled": true,
                "defaultString": "Other Stimulants",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2807,
                "enabled": true,
                "defaultString": "PCP",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2808,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2809,
                "enabled": true,
                "defaultString": "Alcohol",
                "sequenceNumber": 18
            }
        ]
    },
    {
        "RefListID": 272,
        "refListName": "FlagContexts",
        "refListItems": [
            {
                "refListItemId": 2810,
                "enabled": true,
                "defaultString": "Jackets",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 273,
        "refListName": "FlagTypes",
        "refListItems": [
            {
                "refListItemId": 2811,
                "enabled": true,
                "defaultString": "W",
                "longString": "Warrants",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2812,
                "enabled": true,
                "defaultString": "C",
                "longString": "Civil Papers",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2813,
                "enabled": true,
                "defaultString": "!",
                "longString": "DANGER",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2814,
                "enabled": true,
                "defaultString": "J",
                "longString": "In Jail",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2815,
                "enabled": true,
                "defaultString": "G",
                "longString": "Gang Member",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2816,
                "enabled": true,
                "defaultString": "M",
                "longString": "Medical",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2817,
                "enabled": true,
                "defaultString": "N",
                "longString": "Notes",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 274,
        "refListName": "CashAccountingContexts",
        "refListItems": [
            {
                "refListItemId": 2818,
                "enabled": true,
                "defaultString": "Civil Papers",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2819,
                "enabled": true,
                "defaultString": "Bookings",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2820,
                "enabled": true,
                "defaultString": "Permits",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2821,
                "enabled": true,
                "defaultString": "Warrants",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24970,
                "enabled": true,
                "defaultString": "Trust",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 444,
        "refListName": "Category",
        "refListItems": [
            {
                "refListItemId": 5423,
                "enabled": true,
                "defaultString": "Attempted Service",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5424,
                "enabled": true,
                "defaultString": "Commissions",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5425,
                "enabled": true,
                "defaultString": "Copies",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5426,
                "enabled": true,
                "defaultString": "Deputies Time",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5427,
                "enabled": true,
                "defaultString": "Levy",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5428,
                "enabled": true,
                "defaultString": "No Service",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5429,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5430,
                "enabled": true,
                "defaultString": "Posting Fee",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5431,
                "enabled": true,
                "defaultString": "Preparation",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5432,
                "enabled": true,
                "defaultString": "Public Sale",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5433,
                "enabled": true,
                "defaultString": "Redemption",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5434,
                "enabled": true,
                "defaultString": "Service Fee",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 5435,
                "enabled": true,
                "defaultString": "Sales Lien",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5436,
                "enabled": true,
                "defaultString": "Sales Mortgage",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5437,
                "enabled": true,
                "defaultString": "Sales Execution",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 4490,
                "enabled": false,
                "defaultString": "Commissary",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4491,
                "enabled": false,
                "defaultString": "Medical",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4492,
                "enabled": false,
                "defaultString": "Judgment",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4493,
                "enabled": true,
                "defaultString": "Refund",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4494,
                "enabled": true,
                "defaultString": "Fine",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4495,
                "enabled": false,
                "defaultString": "Application Fee",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4496,
                "enabled": false,
                "defaultString": "Photo Fee",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4497,
                "enabled": false,
                "defaultString": "Document Service",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4498,
                "enabled": true,
                "defaultString": "Payment",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4499,
                "enabled": true,
                "defaultString": "Deposit",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4500,
                "enabled": false,
                "defaultString": "Deputation",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4501,
                "enabled": false,
                "defaultString": "Docket & Recording",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4502,
                "enabled": false,
                "defaultString": "Faxes",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4503,
                "enabled": true,
                "defaultString": "Mileage",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4504,
                "enabled": false,
                "defaultString": "Notarization",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4505,
                "enabled": false,
                "defaultString": "Postage",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4506,
                "enabled": false,
                "defaultString": "Publication",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4507,
                "enabled": true,
                "defaultString": "Advance Fee Received",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 24971,
                "enabled": false,
                "defaultString": "Redemption Fund Received",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 24972,
                "enabled": false,
                "defaultString": "Redemption Fund Paid",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 24973,
                "enabled": true,
                "defaultString": "Trust Account Transfer",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 24974,
                "enabled": true,
                "defaultString": "Levy Payment",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 24975,
                "enabled": true,
                "defaultString": "JE Trust Transfer",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 15976,
                "enabled": true,
                "defaultString": "Advanced Fee",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 15980,
                "enabled": false,
                "defaultString": "Post Dated Transaction",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 15987,
                "enabled": true,
                "defaultString": "Wire Transfer",
                "sequenceNumber": 36
            }
        ]
    },
    {
        "RefListID": 276,
        "refListName": "Distribution_Type",
        "refListItems": [
            {
                "refListItemId": 2839,
                "enabled": true,
                "defaultString": "Inmate",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2840,
                "enabled": true,
                "defaultString": "Staff",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 277,
        "refListName": "Served_To",
        "refListItems": [
            {
                "refListItemId": 2841,
                "enabled": true,
                "defaultString": "PERSON NAMED",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2842,
                "enabled": true,
                "defaultString": "OTHER PERSON",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4936,
                "enabled": true,
                "defaultString": "NO PERSON",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15979,
                "enabled": true,
                "defaultString": "POSTED",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 278,
        "refListName": "ServiceType",
        "refListItems": [
            {
                "refListItemId": 2843,
                "enabled": true,
                "defaultString": "POST PROPERTY",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2844,
                "enabled": true,
                "defaultString": "PERSON IN CHARGE",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2845,
                "enabled": false,
                "defaultString": "CERTIFIED MAIL",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2846,
                "enabled": false,
                "defaultString": "1ST CLASS MAIL",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2847,
                "enabled": false,
                "defaultString": "DEPUTIZED",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2848,
                "enabled": false,
                "defaultString": "PUBLICATION",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2849,
                "enabled": true,
                "defaultString": "ALTERNATE SERVICE",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2850,
                "enabled": true,
                "defaultString": "PERSONAL SERVICE ONLY",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3785,
                "enabled": false,
                "defaultString": "Corporation",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3786,
                "enabled": false,
                "defaultString": "Non Est",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3787,
                "enabled": false,
                "defaultString": "Notorious",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 25011,
                "enabled": true,
                "defaultString": "Eviction",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 15978,
                "enabled": true,
                "defaultString": "Abode Service",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 279,
        "refListName": "ServiceStatus",
        "refListItems": [
            {
                "refListItemId": 2851,
                "enabled": true,
                "defaultString": "PENDING",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2852,
                "enabled": false,
                "defaultString": "ABANDONED",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2853,
                "enabled": true,
                "defaultString": "SERVED",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15543,
                "enabled": true,
                "defaultString": "RETURNED",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 280,
        "refListName": "Fingerprint_Key",
        "refListItems": [
            {
                "refListItemId": 2854,
                "enabled": true,
                "defaultString": "1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2855,
                "enabled": true,
                "defaultString": "2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2856,
                "enabled": true,
                "defaultString": "3",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2857,
                "enabled": true,
                "defaultString": "4",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2858,
                "enabled": true,
                "defaultString": "5",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2859,
                "enabled": true,
                "defaultString": "6",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2860,
                "enabled": true,
                "defaultString": "7",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2861,
                "enabled": true,
                "defaultString": "8",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2862,
                "enabled": true,
                "defaultString": "9",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2863,
                "enabled": true,
                "defaultString": "10",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2864,
                "enabled": true,
                "defaultString": "11",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 2865,
                "enabled": true,
                "defaultString": "12",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 2866,
                "enabled": true,
                "defaultString": "13",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 2867,
                "enabled": true,
                "defaultString": "14",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 2868,
                "enabled": true,
                "defaultString": "15",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 2869,
                "enabled": true,
                "defaultString": "16",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 2870,
                "enabled": true,
                "defaultString": "17",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 2871,
                "enabled": true,
                "defaultString": "18",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 2872,
                "enabled": true,
                "defaultString": "19",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 2873,
                "enabled": true,
                "defaultString": "20",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 2874,
                "enabled": true,
                "defaultString": "21",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 2875,
                "enabled": true,
                "defaultString": "22",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 2876,
                "enabled": true,
                "defaultString": "23",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 2877,
                "enabled": true,
                "defaultString": "24",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 2878,
                "enabled": true,
                "defaultString": "25",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 2879,
                "enabled": true,
                "defaultString": "26",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 2880,
                "enabled": true,
                "defaultString": "27",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 2881,
                "enabled": true,
                "defaultString": "28",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 2882,
                "enabled": true,
                "defaultString": "29",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 2883,
                "enabled": true,
                "defaultString": "30",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 2884,
                "enabled": true,
                "defaultString": "31",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 2885,
                "enabled": true,
                "defaultString": "32",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 2886,
                "enabled": true,
                "defaultString": "33",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 2887,
                "enabled": true,
                "defaultString": "34",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 2888,
                "enabled": true,
                "defaultString": "35",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 2889,
                "enabled": true,
                "defaultString": "36",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 2890,
                "enabled": true,
                "defaultString": "37",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 2891,
                "enabled": true,
                "defaultString": "38",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 2892,
                "enabled": true,
                "defaultString": "39",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 2893,
                "enabled": true,
                "defaultString": "40",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 2894,
                "enabled": true,
                "defaultString": "41",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 2895,
                "enabled": true,
                "defaultString": "42",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 2896,
                "enabled": true,
                "defaultString": "43",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 2897,
                "enabled": true,
                "defaultString": "44",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 2898,
                "enabled": true,
                "defaultString": "45",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 2899,
                "enabled": true,
                "defaultString": "46",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 2900,
                "enabled": true,
                "defaultString": "47",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 2901,
                "enabled": true,
                "defaultString": "48",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 2902,
                "enabled": true,
                "defaultString": "49",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 2903,
                "enabled": true,
                "defaultString": "50",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 2904,
                "enabled": true,
                "defaultString": "51",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 2905,
                "enabled": true,
                "defaultString": "52",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 2906,
                "enabled": true,
                "defaultString": "53",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 2907,
                "enabled": true,
                "defaultString": "54",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 2908,
                "enabled": true,
                "defaultString": "55",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 2909,
                "enabled": true,
                "defaultString": "56",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 2910,
                "enabled": true,
                "defaultString": "57",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 2911,
                "enabled": true,
                "defaultString": "58",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 2912,
                "enabled": true,
                "defaultString": "59",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 2913,
                "enabled": true,
                "defaultString": "60",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 2914,
                "enabled": true,
                "defaultString": "61",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 2915,
                "enabled": true,
                "defaultString": "62",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 2916,
                "enabled": true,
                "defaultString": "63",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 2917,
                "enabled": true,
                "defaultString": "64",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 2918,
                "enabled": true,
                "defaultString": "65",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 2919,
                "enabled": true,
                "defaultString": "66",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 2920,
                "enabled": true,
                "defaultString": "67",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 2921,
                "enabled": true,
                "defaultString": "68",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 2922,
                "enabled": true,
                "defaultString": "69",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 2923,
                "enabled": true,
                "defaultString": "70",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 2924,
                "enabled": true,
                "defaultString": "71",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 2925,
                "enabled": true,
                "defaultString": "72",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 2926,
                "enabled": true,
                "defaultString": "73",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 2927,
                "enabled": true,
                "defaultString": "74",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 2928,
                "enabled": true,
                "defaultString": "75",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 2929,
                "enabled": true,
                "defaultString": "76",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 2930,
                "enabled": true,
                "defaultString": "77",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 2931,
                "enabled": true,
                "defaultString": "78",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 2932,
                "enabled": true,
                "defaultString": "79",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 2933,
                "enabled": true,
                "defaultString": "80",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 2934,
                "enabled": true,
                "defaultString": "81",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 2935,
                "enabled": true,
                "defaultString": "82",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 2936,
                "enabled": true,
                "defaultString": "83",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 2937,
                "enabled": true,
                "defaultString": "84",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 2938,
                "enabled": true,
                "defaultString": "85",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 2939,
                "enabled": true,
                "defaultString": "86",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 2940,
                "enabled": true,
                "defaultString": "87",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 2941,
                "enabled": true,
                "defaultString": "88",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 2942,
                "enabled": true,
                "defaultString": "89",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 2943,
                "enabled": true,
                "defaultString": "90",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 2944,
                "enabled": true,
                "defaultString": "91",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 2945,
                "enabled": true,
                "defaultString": "92",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 2946,
                "enabled": true,
                "defaultString": "93",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 2947,
                "enabled": true,
                "defaultString": "94",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 2948,
                "enabled": true,
                "defaultString": "95",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 2949,
                "enabled": true,
                "defaultString": "96",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 2950,
                "enabled": true,
                "defaultString": "97",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 2951,
                "enabled": true,
                "defaultString": "98",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 2952,
                "enabled": true,
                "defaultString": "99",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 2953,
                "enabled": true,
                "defaultString": "100",
                "sequenceNumber": 100
            }
        ]
    },
    {
        "RefListID": 281,
        "refListName": "Fingerprint_Major_Value",
        "refListItems": [
            {
                "refListItemId": 2954,
                "enabled": true,
                "defaultString": "I",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2955,
                "enabled": true,
                "defaultString": "L",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2956,
                "enabled": true,
                "defaultString": "M",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2957,
                "enabled": true,
                "defaultString": "O",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2958,
                "enabled": true,
                "defaultString": "S",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 282,
        "refListName": "Fingerprint_Secondary_Value",
        "refListItems": [
            {
                "refListItemId": 2959,
                "enabled": true,
                "defaultString": "R",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2960,
                "enabled": true,
                "defaultString": "U",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2961,
                "enabled": true,
                "defaultString": "W",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 283,
        "refListName": "Fingerprint_SubSecondary_Value",
        "refListItems": [
            {
                "refListItemId": 2962,
                "enabled": true,
                "defaultString": "I",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2963,
                "enabled": true,
                "defaultString": "M",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2964,
                "enabled": true,
                "defaultString": "O",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 284,
        "refListName": "QuestionSideEffectTypes",
        "refListItems": [
            {
                "refListItemId": 2965,
                "enabled": true,
                "defaultString": "Add Points",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2966,
                "enabled": true,
                "defaultString": "Subtract Points",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2967,
                "enabled": true,
                "defaultString": "Set Jacket Flag",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2968,
                "enabled": true,
                "defaultString": "Clear Jacket Flag",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2969,
                "enabled": true,
                "defaultString": "Hilite On",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2970,
                "enabled": true,
                "defaultString": "Hilite Off",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 16194,
                "enabled": true,
                "defaultString": "Set Queue Step",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 16195,
                "enabled": true,
                "defaultString": "Clear Queue Step",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 285,
        "refListName": "YesNo",
        "refListItems": [
            {
                "refListItemId": 2971,
                "enabled": true,
                "defaultString": "Y",
                "longString": "Yes",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2972,
                "enabled": true,
                "defaultString": "N",
                "longString": "No",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 286,
        "refListName": "Inmate_Associate_Type",
        "refListItems": ""
    },
    {
        "RefListID": 287,
        "refListName": "Imp_Veh_Batch_Menu",
        "refListItems": [
            {
                "refListItemId": 2976,
                "enabled": true,
                "defaultString": "Destroy",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2977,
                "enabled": true,
                "defaultString": "Sell",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 288,
        "refListName": "EP_Batch_Menu",
        "refListItems": [
            {
                "refListItemId": 2978,
                "enabled": true,
                "defaultString": "Clear for Disposal",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2979,
                "enabled": true,
                "defaultString": "Dispose",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 289,
        "refListName": "Attributes_DataBinding",
        "refListItems": [
            {
                "refListItemId": 2980,
                "enabled": true,
                "defaultString": "BoundFieldSource",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2981,
                "enabled": true,
                "defaultString": "BoundFieldName",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2982,
                "enabled": true,
                "defaultString": "DataBound",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 290,
        "refListName": "Attributes_SelectBoxes",
        "refListItems": [
            {
                "refListItemId": 2983,
                "enabled": true,
                "defaultString": "OptionSourceName",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2984,
                "enabled": true,
                "defaultString": "OptionSourceType",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2985,
                "enabled": true,
                "defaultString": "OptionToRemove",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2986,
                "enabled": true,
                "defaultString": "DisplayFieldName",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2987,
                "enabled": true,
                "defaultString": "DisplayFieldSource",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2988,
                "enabled": true,
                "defaultString": "StartingContext",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 2989,
                "enabled": true,
                "defaultString": "ValueFieldName",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 2990,
                "enabled": true,
                "defaultString": "CollectionSource",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 2991,
                "enabled": true,
                "defaultString": "IsLoaded",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 2992,
                "enabled": true,
                "defaultString": "GetsContextFrom",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 2993,
                "enabled": true,
                "defaultString": "DrivesContextFor",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3580,
                "enabled": true,
                "defaultString": "FilterString",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 291,
        "refListName": "Attributes_MercuryAware",
        "refListItems": [
            {
                "refListItemId": 2994,
                "enabled": true,
                "defaultString": "DisableInBrowse",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 2995,
                "enabled": true,
                "defaultString": "InitialValue",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 2996,
                "enabled": true,
                "defaultString": "InitialDisplayText",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 2997,
                "enabled": true,
                "defaultString": "RequiresAllBits",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 2998,
                "enabled": true,
                "defaultString": "RequiresAnyBits",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 2999,
                "enabled": true,
                "defaultString": "EnabledRecModStates",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3000,
                "enabled": true,
                "defaultString": "HiddenRecModStates",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3001,
                "enabled": true,
                "defaultString": "EventToFire",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 292,
        "refListName": "Security_Rule_Applies_To",
        "refListItems": [
            {
                "refListItemId": 3002,
                "enabled": true,
                "defaultString": "STAFF",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3003,
                "enabled": true,
                "defaultString": "GROUPS",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3004,
                "enabled": true,
                "defaultString": "STAFF AND GROUPS",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 293,
        "refListName": "RecordModificationState",
        "refListItems": [
            {
                "refListItemId": 3005,
                "enabled": true,
                "defaultString": "New",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3006,
                "enabled": true,
                "defaultString": "Modify",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3007,
                "enabled": true,
                "defaultString": "Browse",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3008,
                "enabled": true,
                "defaultString": "FullSearch",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3009,
                "enabled": true,
                "defaultString": "ExitOnly",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3010,
                "enabled": true,
                "defaultString": "QuickSearch",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 294,
        "refListName": "VehicleMake",
        "refListItems": [
            {
                "refListItemId": 3011,
                "enabled": true,
                "defaultString": "Acura",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3012,
                "enabled": true,
                "defaultString": "AlfaRomeo",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3013,
                "enabled": true,
                "defaultString": "Audi",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3014,
                "enabled": true,
                "defaultString": "BMW",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3015,
                "enabled": true,
                "defaultString": "Buick",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3016,
                "enabled": true,
                "defaultString": "Cadillac",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3017,
                "enabled": true,
                "defaultString": "Chevrolet",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3018,
                "enabled": true,
                "defaultString": "Chrysler",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3019,
                "enabled": true,
                "defaultString": "Daewoo",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3020,
                "enabled": true,
                "defaultString": "Daihatsu",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3021,
                "enabled": true,
                "defaultString": "Datsun",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3022,
                "enabled": true,
                "defaultString": "Dodge",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3023,
                "enabled": true,
                "defaultString": "Eagle",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3024,
                "enabled": true,
                "defaultString": "Ferrari",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3025,
                "enabled": true,
                "defaultString": "Ford",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3026,
                "enabled": true,
                "defaultString": "Geo",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3027,
                "enabled": true,
                "defaultString": "GMC",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3028,
                "enabled": true,
                "defaultString": "Honda",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3029,
                "enabled": true,
                "defaultString": "Hyundai",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3030,
                "enabled": true,
                "defaultString": "Infiniti",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3031,
                "enabled": true,
                "defaultString": "Jaguar",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3032,
                "enabled": true,
                "defaultString": "Jeep",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3033,
                "enabled": true,
                "defaultString": "Kia",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3034,
                "enabled": true,
                "defaultString": "Land Rover",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3035,
                "enabled": true,
                "defaultString": "Lexus",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3036,
                "enabled": true,
                "defaultString": "Lincoln",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3037,
                "enabled": true,
                "defaultString": "Mazda",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3038,
                "enabled": true,
                "defaultString": "Mercedes",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3039,
                "enabled": true,
                "defaultString": "Mercury",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3040,
                "enabled": true,
                "defaultString": "Mitsubishi",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3041,
                "enabled": true,
                "defaultString": "Nissan",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3042,
                "enabled": true,
                "defaultString": "Oldsmobile",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3043,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3044,
                "enabled": true,
                "defaultString": "Peugeot",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3045,
                "enabled": true,
                "defaultString": "Plymouth",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3046,
                "enabled": true,
                "defaultString": "Pontiac",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3047,
                "enabled": true,
                "defaultString": "Porsche",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3048,
                "enabled": true,
                "defaultString": "Saab",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3049,
                "enabled": true,
                "defaultString": "Saturn",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 3050,
                "enabled": true,
                "defaultString": "Subaru",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 3051,
                "enabled": true,
                "defaultString": "Suzuki",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 3052,
                "enabled": true,
                "defaultString": "Toyota",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 3053,
                "enabled": true,
                "defaultString": "Volkswagon",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 3054,
                "enabled": true,
                "defaultString": "Volvo",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 3466,
                "enabled": true,
                "defaultString": "American Motors",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 3467,
                "enabled": true,
                "defaultString": "Asuna",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 3468,
                "enabled": true,
                "defaultString": "Austin",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 3469,
                "enabled": true,
                "defaultString": "Capri",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 3470,
                "enabled": true,
                "defaultString": "Fiat",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 3471,
                "enabled": true,
                "defaultString": "Freightliner",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 3472,
                "enabled": true,
                "defaultString": "Fwd",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 3473,
                "enabled": true,
                "defaultString": "Geio",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 3474,
                "enabled": true,
                "defaultString": "GM",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 3475,
                "enabled": true,
                "defaultString": "Harley Davidson",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 3476,
                "enabled": true,
                "defaultString": "Hino",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 3477,
                "enabled": true,
                "defaultString": "International",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 3478,
                "enabled": true,
                "defaultString": "Isuzu",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 3479,
                "enabled": true,
                "defaultString": "Iveco",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 3480,
                "enabled": true,
                "defaultString": "Jensen",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 3481,
                "enabled": true,
                "defaultString": "Kawasaki",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 3482,
                "enabled": true,
                "defaultString": "Kenworth",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 3483,
                "enabled": true,
                "defaultString": "Lancia",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 3484,
                "enabled": true,
                "defaultString": "Mack",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 3485,
                "enabled": true,
                "defaultString": "Merkur",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 3486,
                "enabled": true,
                "defaultString": "MG",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 3487,
                "enabled": true,
                "defaultString": "Opel",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 3488,
                "enabled": true,
                "defaultString": "Oshkosh",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 3489,
                "enabled": true,
                "defaultString": "Passport",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 3490,
                "enabled": true,
                "defaultString": "Peterbilt",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 3491,
                "enabled": true,
                "defaultString": "Renault",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 3492,
                "enabled": true,
                "defaultString": "SSI",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 3493,
                "enabled": true,
                "defaultString": "Sterling",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 3494,
                "enabled": true,
                "defaultString": "Triumph",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 3495,
                "enabled": true,
                "defaultString": "White",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 3496,
                "enabled": true,
                "defaultString": "WhiteGMC",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 3497,
                "enabled": true,
                "defaultString": "Winnebago",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 3498,
                "enabled": true,
                "defaultString": "Yamaha",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 3499,
                "enabled": true,
                "defaultString": "Yugo",
                "sequenceNumber": 78
            }
        ]
    },
    {
        "RefListID": 295,
        "refListName": "Special_Lists",
        "refListItems": [
            {
                "refListItemId": 3055,
                "enabled": true,
                "defaultString": "Modules",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3056,
                "enabled": true,
                "defaultString": "ReferenceLists",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3057,
                "enabled": true,
                "defaultString": "AppContexts",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3058,
                "enabled": true,
                "defaultString": "DBSchemas",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 296,
        "refListName": "SecurityTypes",
        "refListItems": [
            {
                "refListItemId": 3059,
                "enabled": true,
                "defaultString": "View",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3060,
                "enabled": true,
                "defaultString": "Add",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3061,
                "enabled": true,
                "defaultString": "Modify",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3062,
                "enabled": true,
                "defaultString": "Delete",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3063,
                "enabled": true,
                "defaultString": "Reports",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3064,
                "enabled": true,
                "defaultString": "Lock",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3065,
                "enabled": true,
                "defaultString": "Find",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3066,
                "enabled": true,
                "defaultString": "TableView",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3067,
                "enabled": true,
                "defaultString": "Unlock",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3068,
                "enabled": true,
                "defaultString": "Print",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3069,
                "enabled": true,
                "defaultString": "All Rights",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3070,
                "enabled": true,
                "defaultString": "No Rights",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 297,
        "refListName": "TempModuleNames",
        "refListItems": [
            {
                "refListItemId": 3071,
                "enabled": true,
                "defaultString": "Accident Reports",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3072,
                "enabled": true,
                "defaultString": "Address Book",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3073,
                "enabled": true,
                "defaultString": "Agency Property",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3074,
                "enabled": true,
                "defaultString": "Audit Log New",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3075,
                "enabled": true,
                "defaultString": "Bankruptcy",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3076,
                "enabled": true,
                "defaultString": "Bookings New",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3077,
                "enabled": true,
                "defaultString": "Bookings Summary",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3078,
                "enabled": true,
                "defaultString": "Case Management",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3079,
                "enabled": true,
                "defaultString": "Citations New",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3080,
                "enabled": true,
                "defaultString": "Civil Papers New",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3081,
                "enabled": true,
                "defaultString": "Config",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3082,
                "enabled": true,
                "defaultString": "Evidence & Property",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3083,
                "enabled": true,
                "defaultString": "Field Interviews",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3084,
                "enabled": true,
                "defaultString": "Impounded Vehicles",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3085,
                "enabled": true,
                "defaultString": "Intelligence",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3086,
                "enabled": true,
                "defaultString": "Investigator's View",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3087,
                "enabled": true,
                "defaultString": "Lineups",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3088,
                "enabled": true,
                "defaultString": "NIBRS",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3089,
                "enabled": true,
                "defaultString": "NIBRS Submission",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3090,
                "enabled": true,
                "defaultString": "Pawned Property",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3091,
                "enabled": true,
                "defaultString": "Permits",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3092,
                "enabled": true,
                "defaultString": "Property Room",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3093,
                "enabled": true,
                "defaultString": "Security",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3094,
                "enabled": true,
                "defaultString": "Sex Offenders",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3095,
                "enabled": true,
                "defaultString": "Staff",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3096,
                "enabled": true,
                "defaultString": "Staff Groups",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3097,
                "enabled": true,
                "defaultString": "UCR",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3098,
                "enabled": true,
                "defaultString": "Visitor Log",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3099,
                "enabled": true,
                "defaultString": "Warrants New",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3100,
                "enabled": true,
                "defaultString": "Weapon Confiscation",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3454,
                "enabled": true,
                "defaultString": "Export Data",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3795,
                "enabled": true,
                "defaultString": "Users",
                "sequenceNumber": 32
            }
        ]
    },
    {
        "RefListID": 299,
        "refListName": "SecurityEntitiesFunctional",
        "refListItems": [
            {
                "refListItemId": 3105,
                "enabled": true,
                "defaultString": "Report",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3106,
                "enabled": true,
                "defaultString": "Module",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3107,
                "enabled": true,
                "defaultString": "Submodule",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3108,
                "enabled": true,
                "defaultString": "TableView",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3109,
                "enabled": true,
                "defaultString": "Arbitrary",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3110,
                "enabled": true,
                "defaultString": "Field",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 300,
        "refListName": "OptionSourceTypes",
        "refListItems": [
            {
                "refListItemId": 3111,
                "enabled": true,
                "defaultString": "Reference List",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3112,
                "enabled": true,
                "defaultString": "LinkedObjSource",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3113,
                "enabled": true,
                "defaultString": "Special",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 301,
        "refListName": "SpecialOptionSources",
        "refListItems": [
            {
                "refListItemId": 3114,
                "enabled": true,
                "defaultString": "Modules",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3115,
                "enabled": true,
                "defaultString": "Reports",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3116,
                "enabled": true,
                "defaultString": "LinkedObjectSources",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3117,
                "enabled": true,
                "defaultString": "TableViews",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3118,
                "enabled": true,
                "defaultString": "ReferenceLists",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3119,
                "enabled": true,
                "defaultString": "Questionnaires",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3120,
                "enabled": true,
                "defaultString": "FeeDescriptions",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3121,
                "enabled": true,
                "defaultString": "EmptyList",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3122,
                "enabled": true,
                "defaultString": "AppContexts",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3123,
                "enabled": true,
                "defaultString": "DBSchemas",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3124,
                "enabled": true,
                "defaultString": "GlobalCriteria",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 298,
        "refListName": "SecurityCollectionType",
        "refListItems": [
            {
                "refListItemId": 3101,
                "enabled": true,
                "defaultString": "Personal",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3102,
                "enabled": true,
                "defaultString": "Group",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3103,
                "enabled": true,
                "defaultString": "Global",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3104,
                "enabled": true,
                "defaultString": "System",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 302,
        "refListName": "PCIEvents",
        "refListItems": [
            {
                "refListItemId": 3610,
                "enabled": true,
                "defaultString": "Add Comment",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 3125,
                "enabled": true,
                "defaultString": "SetRecordModificationState",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3126,
                "enabled": true,
                "defaultString": "ValueChanged",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3127,
                "enabled": true,
                "defaultString": "ChangeModule",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3128,
                "enabled": true,
                "defaultString": "DisableButtons",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3129,
                "enabled": true,
                "defaultString": "EnableButtons",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3130,
                "enabled": true,
                "defaultString": "Exit",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3131,
                "enabled": true,
                "defaultString": "Reports",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3132,
                "enabled": true,
                "defaultString": "Help",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3133,
                "enabled": true,
                "defaultString": "Lock",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3134,
                "enabled": true,
                "defaultString": "Unlock",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3135,
                "enabled": true,
                "defaultString": "FindDropDownMenu",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3136,
                "enabled": true,
                "defaultString": "ThumbsDropDownMenu",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3137,
                "enabled": true,
                "defaultString": "Modify",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3138,
                "enabled": true,
                "defaultString": "Cancel",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3139,
                "enabled": true,
                "defaultString": "Save",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3140,
                "enabled": true,
                "defaultString": "Table",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3141,
                "enabled": true,
                "defaultString": "New",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3142,
                "enabled": true,
                "defaultString": "Prev",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3143,
                "enabled": true,
                "defaultString": "Next",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3144,
                "enabled": true,
                "defaultString": "Last",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3145,
                "enabled": true,
                "defaultString": "First",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3146,
                "enabled": true,
                "defaultString": "GotoRecord",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3147,
                "enabled": true,
                "defaultString": "Find",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3148,
                "enabled": true,
                "defaultString": "Restore",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3149,
                "enabled": true,
                "defaultString": "SelectionChanged",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3150,
                "enabled": true,
                "defaultString": "LoadError",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3151,
                "enabled": true,
                "defaultString": "Logout",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3152,
                "enabled": true,
                "defaultString": "ModuleLoaded",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3153,
                "enabled": true,
                "defaultString": "NewUser",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3154,
                "enabled": true,
                "defaultString": "SetFocusToModuleName",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3155,
                "enabled": true,
                "defaultString": "Delete",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3156,
                "enabled": true,
                "defaultString": "Full Search",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3157,
                "enabled": true,
                "defaultString": "Search Go",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3158,
                "enabled": true,
                "defaultString": "Search Cancel",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3159,
                "enabled": true,
                "defaultString": "Search Count",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3160,
                "enabled": true,
                "defaultString": "Abandon Session",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3430,
                "enabled": true,
                "defaultString": "Session Timeout",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3581,
                "enabled": true,
                "defaultString": "Expunge Record",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3796,
                "enabled": true,
                "defaultString": "NewDropDownMenu",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 3797,
                "enabled": true,
                "defaultString": "SelectPageSection",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 4463,
                "enabled": true,
                "defaultString": "DisableActions",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 4464,
                "enabled": true,
                "defaultString": "EnableActions",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 4219,
                "enabled": true,
                "defaultString": "FacilityLogViewer",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 4885,
                "enabled": true,
                "defaultString": "Search Results",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 4886,
                "enabled": true,
                "defaultString": "SuperSearch",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 4937,
                "enabled": true,
                "defaultString": "LiveScan",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 4938,
                "enabled": true,
                "defaultString": "SetFocusToSubModuleName",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 24967,
                "enabled": true,
                "defaultString": "Verify Iris",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 24968,
                "enabled": true,
                "defaultString": "OnBase",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 24947,
                "enabled": true,
                "defaultString": "OnDemandCount",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 24950,
                "enabled": true,
                "defaultString": "KeyboardShortcuts",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 24957,
                "enabled": true,
                "defaultString": "Refresh",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 25010,
                "enabled": true,
                "defaultString": "IRISHistory",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 15542,
                "enabled": true,
                "defaultString": "SuperDuperSearch",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 24337,
                "enabled": true,
                "defaultString": "Messages",
                "sequenceNumber": 52
            }
        ]
    },
    {
        "RefListID": 303,
        "refListName": "SecurityRuleTypes",
        "refListItems": [
            {
                "refListItemId": 3161,
                "enabled": true,
                "defaultString": "Record",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3162,
                "enabled": true,
                "defaultString": "Criteria",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3163,
                "enabled": true,
                "defaultString": "Functional",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 304,
        "refListName": "SecuritySpecialConstants",
        "refListItems": [
            {
                "refListItemId": 3164,
                "enabled": true,
                "defaultString": "SystemGroup",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3165,
                "enabled": true,
                "defaultString": "AgencyGlobalGroup",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3166,
                "enabled": true,
                "defaultString": "SystemStaffRecord",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 308,
        "refListName": "Vehicle_Role",
        "refListItems": [
            {
                "refListItemId": 3181,
                "enabled": true,
                "defaultString": "Owner",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3182,
                "enabled": true,
                "defaultString": "Passenger",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 306,
        "refListName": "Aircraft_Type",
        "refListItems": [
            {
                "refListItemId": 3169,
                "enabled": true,
                "defaultString": "Glider",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3170,
                "enabled": true,
                "defaultString": "Balloon",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3171,
                "enabled": true,
                "defaultString": "Rotorcraft",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3172,
                "enabled": true,
                "defaultString": "Blimp/Dirigible",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3173,
                "enabled": true,
                "defaultString": "Fixed Wing",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 307,
        "refListName": "Aircraft_Engine_Type",
        "refListItems": [
            {
                "refListItemId": 3174,
                "enabled": true,
                "defaultString": "None",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3175,
                "enabled": true,
                "defaultString": "Reciprocating",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3176,
                "enabled": true,
                "defaultString": "Turbo Prop",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3177,
                "enabled": true,
                "defaultString": "Turbo Shaft",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3178,
                "enabled": true,
                "defaultString": "Turbo Jet",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3179,
                "enabled": true,
                "defaultString": "Turbine Air Generator",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3180,
                "enabled": true,
                "defaultString": "Ramjet",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 309,
        "refListName": "BioQuickSearchJuveniles",
        "refListItems": [
            {
                "refListItemId": 3183,
                "enabled": true,
                "defaultString": "Only Juveniles",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3184,
                "enabled": true,
                "defaultString": "No Juveniles",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 310,
        "refListName": "AuditLog_Action",
        "refListItems": [
            {
                "refListItemId": 3186,
                "enabled": true,
                "defaultString": "View",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3187,
                "enabled": true,
                "defaultString": "Create",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3188,
                "enabled": true,
                "defaultString": "Modify",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3189,
                "enabled": true,
                "defaultString": "Delete",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3190,
                "enabled": true,
                "defaultString": "Search",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3191,
                "enabled": true,
                "defaultString": "Report",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3192,
                "enabled": true,
                "defaultString": "Table View",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3193,
                "enabled": true,
                "defaultString": "Log On",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3194,
                "enabled": true,
                "defaultString": "Log Out",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3798,
                "enabled": true,
                "defaultString": "Legacy Conversion",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4312,
                "enabled": true,
                "defaultString": "External Interface",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4939,
                "enabled": true,
                "defaultString": "Error",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 16240,
                "enabled": true,
                "defaultString": "Print",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 311,
        "refListName": "UserSecurityTokens",
        "refListItems": [
            {
                "refListItemId": 3195,
                "enabled": true,
                "defaultString": "@MYSTAFFID",
                "longString": "The current user's staff id",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3196,
                "enabled": true,
                "defaultString": "@MYDEPARTMENT",
                "longString": "The current user's Department",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3197,
                "enabled": true,
                "defaultString": "@MYLEVEL",
                "longString": "The current user's Security Level",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3799,
                "enabled": true,
                "defaultString": "@MYAGENCY",
                "longString": "The current user's Agency (used in multi-facility installations)",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 312,
        "refListName": "Security_Level",
        "refListItems": [
            {
                "refListItemId": 3198,
                "enabled": true,
                "defaultString": "1",
                "longString": "Security Level 1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3199,
                "enabled": true,
                "defaultString": "2",
                "longString": "Security Level 2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3200,
                "enabled": true,
                "defaultString": "3",
                "longString": "Security Level 3",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3201,
                "enabled": true,
                "defaultString": "4",
                "longString": "Security Level 4",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3202,
                "enabled": true,
                "defaultString": "5",
                "longString": "Security Level 5",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 313,
        "refListName": "Storage_Location_1",
        "refListItems": [
            {
                "refListItemId": 3203,
                "enabled": true,
                "defaultString": "01",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3204,
                "enabled": true,
                "defaultString": "02",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3205,
                "enabled": true,
                "defaultString": "03",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3206,
                "enabled": true,
                "defaultString": "04",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3207,
                "enabled": true,
                "defaultString": "05",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3431,
                "enabled": true,
                "defaultString": "Inmate",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 314,
        "refListName": "Storage_Location_2",
        "refListItems": [
            {
                "refListItemId": 3208,
                "enabled": true,
                "defaultString": "101",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3209,
                "enabled": true,
                "defaultString": "102",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3210,
                "enabled": true,
                "defaultString": "103",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3211,
                "enabled": true,
                "defaultString": "104",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3212,
                "enabled": true,
                "defaultString": "105",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3213,
                "enabled": true,
                "defaultString": "201",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3214,
                "enabled": true,
                "defaultString": "202",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3215,
                "enabled": true,
                "defaultString": "203",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3216,
                "enabled": true,
                "defaultString": "204",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3217,
                "enabled": true,
                "defaultString": "301",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3218,
                "enabled": true,
                "defaultString": "302",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3219,
                "enabled": true,
                "defaultString": "303",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5020,
                "enabled": true,
                "defaultString": "Clothing",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5021,
                "enabled": true,
                "defaultString": "Property",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 315,
        "refListName": "Storage_Location_3",
        "refListItems": [
            {
                "refListItemId": 3220,
                "enabled": true,
                "defaultString": "A-1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3221,
                "enabled": true,
                "defaultString": "A-2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3222,
                "enabled": true,
                "defaultString": "A-3",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3223,
                "enabled": true,
                "defaultString": "A-4",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3224,
                "enabled": true,
                "defaultString": "A-5",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3225,
                "enabled": true,
                "defaultString": "B-1",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3226,
                "enabled": true,
                "defaultString": "B-2",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3227,
                "enabled": true,
                "defaultString": "B-3",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3228,
                "enabled": true,
                "defaultString": "B-4",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3229,
                "enabled": true,
                "defaultString": "C-1",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3230,
                "enabled": true,
                "defaultString": "C-2",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3231,
                "enabled": true,
                "defaultString": "C-3",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 316,
        "refListName": "Search_Methods",
        "refListItems": [
            {
                "refListItemId": 3232,
                "enabled": true,
                "defaultString": "Full Search",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3233,
                "enabled": true,
                "defaultString": "Quick Search",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4887,
                "enabled": true,
                "defaultString": "Search Results",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 317,
        "refListName": "Search_Type",
        "refListItems": [
            {
                "refListItemId": 3234,
                "enabled": true,
                "defaultString": "Exact",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3235,
                "enabled": true,
                "defaultString": "Sounds Like",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3236,
                "enabled": true,
                "defaultString": "Fuzzy",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16004,
                "enabled": true,
                "defaultString": "Starts With",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 318,
        "refListName": "Vehicle_Held",
        "refListItems": [
            {
                "refListItemId": 3237,
                "enabled": true,
                "defaultString": "None",
                "longString": "No vehicles seized or held in arrest",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3238,
                "enabled": true,
                "defaultString": "Stored",
                "longString": "Vehicle stored as result of arrest",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3239,
                "enabled": true,
                "defaultString": "Impounded",
                "longString": "Vehicle impounded as result of arrest",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 319,
        "refListName": "Image_Manipulation",
        "refListItems": [
            {
                "refListItemId": 3240,
                "enabled": true,
                "defaultString": "Capture Picture",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3241,
                "enabled": true,
                "defaultString": "Browse For Image",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3242,
                "enabled": true,
                "defaultString": "Enter PhotoID",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3243,
                "enabled": true,
                "defaultString": "Brightness",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3244,
                "enabled": true,
                "defaultString": "Contrast",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3245,
                "enabled": true,
                "defaultString": "Hue",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3246,
                "enabled": true,
                "defaultString": "Convert to Grayscale",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3247,
                "enabled": true,
                "defaultString": "Rotate 90 Clockwise",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3248,
                "enabled": true,
                "defaultString": "Rotate 90 Counter-Clockwise",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3249,
                "enabled": true,
                "defaultString": "Select Twain Source",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 320,
        "refListName": "Release_Type",
        "refListItems": [
            {
                "refListItemId": 3250,
                "enabled": true,
                "defaultString": "Out on Bail",
                "longString": "Released on Bail",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3251,
                "enabled": true,
                "defaultString": "Released on Own Recognizance",
                "longString": "Released on Own Recognizance",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3252,
                "enabled": true,
                "defaultString": "No Formal Charge",
                "longString": "Released with No Formal Charges",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3253,
                "enabled": true,
                "defaultString": "Other",
                "longString": "Other Release Type",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 552,
        "refListName": "UCRIncidentTypes",
        "refListItems": [
            {
                "refListItemId": 16068,
                "enabled": true,
                "defaultString": "Subject to change",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 323,
        "refListName": "NIBRSIncidentTypes",
        "refListItems": [
            {
                "refListItemId": 3261,
                "enabled": true,
                "defaultString": "Murder / Nonnegligent Manslaughter {09A}",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3262,
                "enabled": true,
                "defaultString": "Negligent Manslaughter {09B}",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3263,
                "enabled": true,
                "defaultString": "Justifiable Homicide {09C}",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3264,
                "enabled": true,
                "defaultString": "Kidnapping / Abduction {100}",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3265,
                "enabled": true,
                "defaultString": "Forcible Rape {11A}",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3266,
                "enabled": true,
                "defaultString": "Forcible Sodomy {11B}",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3267,
                "enabled": true,
                "defaultString": "Sexual Assault with an Object {11C}",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3268,
                "enabled": true,
                "defaultString": "Forcible Fondling {11D}",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3269,
                "enabled": true,
                "defaultString": "Robbery {120}",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3270,
                "enabled": true,
                "defaultString": "Aggravated Assault {13A}",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3271,
                "enabled": true,
                "defaultString": "Simple Assault {13B}",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3272,
                "enabled": true,
                "defaultString": "Intimidation {13C}",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3273,
                "enabled": true,
                "defaultString": "Arson {200}",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3274,
                "enabled": true,
                "defaultString": "Extortion / Blackmail {210}",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3275,
                "enabled": true,
                "defaultString": "Burglary / Breaking & Entering {220}",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3276,
                "enabled": true,
                "defaultString": "Pocket-Picking {23A}",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3277,
                "enabled": true,
                "defaultString": "Purse-Snatching {23B}",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3278,
                "enabled": true,
                "defaultString": "Shoplifting {23C}",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3279,
                "enabled": true,
                "defaultString": "Theft From Building {23D}",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3280,
                "enabled": true,
                "defaultString": "Theft From Coin-Operated machine or Device {23E}",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3281,
                "enabled": true,
                "defaultString": "Theft From Motor Vehicle {23F}",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3282,
                "enabled": true,
                "defaultString": "Theft of Motor Vehicle Parts or Accessories {23G}",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3283,
                "enabled": true,
                "defaultString": "All Other Larceny {23H}",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3284,
                "enabled": true,
                "defaultString": "Motor Vehicle Theft {240}",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3285,
                "enabled": true,
                "defaultString": "Counterfeiting / Forgery {250}",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3286,
                "enabled": true,
                "defaultString": "False Pretenses / Swindle / Confidence Game {26A}",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3287,
                "enabled": true,
                "defaultString": "Credit Card / Automatic Teller Machine Fraud {26B}",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3288,
                "enabled": true,
                "defaultString": "Impersonation {26C}",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3289,
                "enabled": true,
                "defaultString": "Welfare Fraud {26D}",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3290,
                "enabled": true,
                "defaultString": "Wire Fraud {26E}",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3291,
                "enabled": true,
                "defaultString": "Embezzlement {270}",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3292,
                "enabled": true,
                "defaultString": "Stolen Property {280}",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3293,
                "enabled": true,
                "defaultString": "Destruction / Damage / Vandalism of Property {290}",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3294,
                "enabled": true,
                "defaultString": "Drug / Narcotic Violations {35A}",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3295,
                "enabled": true,
                "defaultString": "Drug Equipment Violations {35B}",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3296,
                "enabled": true,
                "defaultString": "Incest {36A}",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3297,
                "enabled": true,
                "defaultString": "Statuatory Rape {36B}",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3298,
                "enabled": true,
                "defaultString": "Pornography / Obscene Material {370}",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 3299,
                "enabled": true,
                "defaultString": "Betting / Wagering {39A}",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 3300,
                "enabled": true,
                "defaultString": "Operating / Promoting / Assisting Gambling {39B}",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 3301,
                "enabled": true,
                "defaultString": "Gambling Equipment Violations {39C}",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 3302,
                "enabled": true,
                "defaultString": "Sports Tampering {39D}",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 3303,
                "enabled": true,
                "defaultString": "Prostitution {40A}",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 3304,
                "enabled": true,
                "defaultString": "Assisting or Promoting Prostitution {40B}",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 3305,
                "enabled": true,
                "defaultString": "Bribery {510}",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 3306,
                "enabled": true,
                "defaultString": "Weapon Law Violations {520}",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 3307,
                "enabled": true,
                "defaultString": "Bad Checks {90A}",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 3308,
                "enabled": true,
                "defaultString": "Curfew / Loitering / Vagrancy Violations {90B}",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 3309,
                "enabled": true,
                "defaultString": "Disorderly Conduct {90C}",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 3310,
                "enabled": true,
                "defaultString": "Driving under the Influence {90D}",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 3311,
                "enabled": true,
                "defaultString": "Drunkenness {90E}",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 3312,
                "enabled": true,
                "defaultString": "Family Offenses, Nonviolent {90F}",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 3313,
                "enabled": true,
                "defaultString": "Liquor Law Violations {90G}",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 3314,
                "enabled": true,
                "defaultString": "Peeping Tom {90H}",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 3315,
                "enabled": true,
                "defaultString": "Runaway {90I}",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 3316,
                "enabled": true,
                "defaultString": "Trespass of Real Property {90J}",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 3317,
                "enabled": true,
                "defaultString": "All Other Offenses {90Z}",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 3455,
                "enabled": true,
                "defaultString": "Not NIBRS Reportable - See State Charge {DNR}",
                "sequenceNumber": 59
            }
        ]
    },
    {
        "RefListID": 324,
        "refListName": "ActionPaneItemTypes",
        "refListItems": [
            {
                "refListItemId": 3318,
                "enabled": true,
                "defaultString": "Button",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3319,
                "enabled": true,
                "defaultString": "DropDown",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3320,
                "enabled": true,
                "defaultString": "DropDownButton",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3321,
                "enabled": true,
                "defaultString": "IFrame",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 325,
        "refListName": "Suspected_of_Using",
        "refListItems": [
            {
                "refListItemId": 3322,
                "enabled": true,
                "defaultString": "None",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3323,
                "enabled": true,
                "defaultString": "Alcohol",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3324,
                "enabled": true,
                "defaultString": "Drugs",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3325,
                "enabled": true,
                "defaultString": "Computer",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 326,
        "refListName": "Victim_Type",
        "refListItems": [
            {
                "refListItemId": 3326,
                "enabled": true,
                "defaultString": "Business",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3327,
                "enabled": true,
                "defaultString": "Financial Institution",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3328,
                "enabled": true,
                "defaultString": "Government",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3329,
                "enabled": true,
                "defaultString": "Individual",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3330,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3331,
                "enabled": true,
                "defaultString": "Religious Organization",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3332,
                "enabled": true,
                "defaultString": "Society/Public",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3333,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 327,
        "refListName": "Address_Type",
        "refListItems": [
            {
                "refListItemId": 3334,
                "enabled": true,
                "defaultString": "Physical",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3335,
                "enabled": true,
                "defaultString": "Mailing",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3336,
                "enabled": true,
                "defaultString": "Temporary",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3800,
                "enabled": true,
                "defaultString": "Previous",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 340,
        "refListName": "Property_Type",
        "refListItems": [
            {
                "refListItemId": 3514,
                "enabled": true,
                "defaultString": "Clothes/Furs",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3515,
                "enabled": true,
                "defaultString": "Computer Hardware/Software",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3516,
                "enabled": true,
                "defaultString": "Consumable Goods",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3517,
                "enabled": true,
                "defaultString": "Credit/Debit Cards",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3518,
                "enabled": true,
                "defaultString": "Drug/Narcotic Equipment",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3519,
                "enabled": true,
                "defaultString": "Drugs/Narcotics",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3520,
                "enabled": true,
                "defaultString": "Electronics",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3521,
                "enabled": true,
                "defaultString": "Farm Equipment",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3522,
                "enabled": true,
                "defaultString": "Firearms",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3523,
                "enabled": true,
                "defaultString": "Gambling Equipment",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3524,
                "enabled": true,
                "defaultString": "Heavy Construction/Industrial Equipment",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3525,
                "enabled": true,
                "defaultString": "Household Goods",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3526,
                "enabled": true,
                "defaultString": "Jewelry/Precious Metals",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3527,
                "enabled": true,
                "defaultString": "Livestock",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3528,
                "enabled": true,
                "defaultString": "Merchandise",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3529,
                "enabled": true,
                "defaultString": "Money",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3530,
                "enabled": true,
                "defaultString": "Negotiable Instruments",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3531,
                "enabled": true,
                "defaultString": "Nonnegotiable Instruments",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3532,
                "enabled": true,
                "defaultString": "Office-type Equipment",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3533,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3534,
                "enabled": true,
                "defaultString": "Other Motor Vehicles",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3535,
                "enabled": true,
                "defaultString": "Purses/Handbags/Wallets",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3536,
                "enabled": true,
                "defaultString": "Radios/TVs/VCRs",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3537,
                "enabled": true,
                "defaultString": "Recordings-Audio/Visual",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3538,
                "enabled": true,
                "defaultString": "Recreational Vehicles",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3539,
                "enabled": true,
                "defaultString": "Structures-Industrial/Manufacturing",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3540,
                "enabled": true,
                "defaultString": "Structures-Other",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 3541,
                "enabled": true,
                "defaultString": "Structures-Other Commercial/Business",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 3542,
                "enabled": true,
                "defaultString": "Structures-Other Dwellings",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 3543,
                "enabled": true,
                "defaultString": "Structures-Public/Community",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 3544,
                "enabled": true,
                "defaultString": "Structures-Single Occupancy Dwellings",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 3545,
                "enabled": true,
                "defaultString": "Structures-Storage",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 3546,
                "enabled": true,
                "defaultString": "Tools",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 3547,
                "enabled": true,
                "defaultString": "Trucks",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 3548,
                "enabled": true,
                "defaultString": "Vehicle Parts/Accessories",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 3549,
                "enabled": true,
                "defaultString": "Watercraft",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 3550,
                "enabled": true,
                "defaultString": "Rape Kit",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 3509,
                "enabled": true,
                "defaultString": "Aircraft",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3510,
                "enabled": true,
                "defaultString": "Alcohol",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3511,
                "enabled": true,
                "defaultString": "Automobiles",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3512,
                "enabled": true,
                "defaultString": "Bicycles",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3513,
                "enabled": true,
                "defaultString": "Buses",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3551,
                "enabled": true,
                "defaultString": "Tissue Specimen",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 3552,
                "enabled": true,
                "defaultString": "Blood Specimen",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 3553,
                "enabled": true,
                "defaultString": "Bodily Fluids Specimen",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 3554,
                "enabled": true,
                "defaultString": "Hair Specimen",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 3555,
                "enabled": true,
                "defaultString": "Latent Fingerprint",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 3556,
                "enabled": true,
                "defaultString": "Firearm Cartridge/Casing",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 3557,
                "enabled": true,
                "defaultString": "Fiber Specimen",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 3558,
                "enabled": true,
                "defaultString": "Footwear Impression",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 3559,
                "enabled": true,
                "defaultString": "Photographs/Film",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 3560,
                "enabled": true,
                "defaultString": "Knife",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 3561,
                "enabled": true,
                "defaultString": "Bow",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 3562,
                "enabled": true,
                "defaultString": "Cross Bow",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 3563,
                "enabled": true,
                "defaultString": "Oriental Weapons",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 3564,
                "enabled": true,
                "defaultString": "Blowgun",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 3565,
                "enabled": true,
                "defaultString": "Stick/Baseball Bat/Club",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 4243,
                "enabled": true,
                "defaultString": "Boats/Watercraft",
                "sequenceNumber": 58
            }
        ]
    },
    {
        "RefListID": 329,
        "refListName": "Agency_Property_Category",
        "refListItems": [
            {
                "refListItemId": 3378,
                "enabled": true,
                "defaultString": "Clothing",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3379,
                "enabled": true,
                "defaultString": "Weapons",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3380,
                "enabled": true,
                "defaultString": "Radios",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3381,
                "enabled": true,
                "defaultString": "Vehicles",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3382,
                "enabled": true,
                "defaultString": "Personal Protection",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3383,
                "enabled": true,
                "defaultString": "Restraints",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3384,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 330,
        "refListName": "InterviewReason",
        "refListItems": [
            {
                "refListItemId": 3385,
                "enabled": true,
                "defaultString": "Subj Observed in Known Drug Area",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3386,
                "enabled": true,
                "defaultString": "Suspicious Activity",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3387,
                "enabled": true,
                "defaultString": "Known Drug User",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3388,
                "enabled": true,
                "defaultString": "Possible Witness to Crime",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3389,
                "enabled": true,
                "defaultString": "Loitering in High Crime Area",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3390,
                "enabled": true,
                "defaultString": "Loitering in Prostitution Area",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 331,
        "refListName": "Move_Reason",
        "refListItems": [
            {
                "refListItemId": 3391,
                "enabled": true,
                "defaultString": "Initial Booking",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3392,
                "enabled": true,
                "defaultString": "Personal Request",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3393,
                "enabled": true,
                "defaultString": "Receive Inmate",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3394,
                "enabled": true,
                "defaultString": "Discplinary",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3582,
                "enabled": false,
                "defaultString": "Transport Cancelled",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3583,
                "enabled": true,
                "defaultString": "Routine Move",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3764,
                "enabled": true,
                "defaultString": "Pending Pickup",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3765,
                "enabled": true,
                "defaultString": "Pending Discharge",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3766,
                "enabled": true,
                "defaultString": "Reclassification",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3767,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3768,
                "enabled": true,
                "defaultString": "Suicide Watch",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4313,
                "enabled": true,
                "defaultString": "Fingerprinting",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4314,
                "enabled": true,
                "defaultString": "Fingerprint/Court",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4315,
                "enabled": true,
                "defaultString": "Court",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 16055,
                "enabled": true,
                "defaultString": "Cell Assignment"
            },
            {
                "refListItemId": 4964,
                "enabled": true,
                "defaultString": "Initial Classification",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 332,
        "refListName": "VehicleColor",
        "refListItems": [
            {
                "refListItemId": 3395,
                "enabled": true,
                "defaultString": "Beige",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3396,
                "enabled": true,
                "defaultString": "Black",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3397,
                "enabled": true,
                "defaultString": "Blue",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3398,
                "enabled": true,
                "defaultString": "Bronze",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3399,
                "enabled": true,
                "defaultString": "Brown",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3400,
                "enabled": true,
                "defaultString": "Burgundy",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3401,
                "enabled": true,
                "defaultString": "Camouflage",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3402,
                "enabled": true,
                "defaultString": "Champagne",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3403,
                "enabled": true,
                "defaultString": "Chrome",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3404,
                "enabled": true,
                "defaultString": "Cream",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3405,
                "enabled": true,
                "defaultString": "Dark Blue",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3406,
                "enabled": true,
                "defaultString": "Dark Green",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3407,
                "enabled": true,
                "defaultString": "Gold",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3408,
                "enabled": true,
                "defaultString": "Gray",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3409,
                "enabled": true,
                "defaultString": "Green",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3410,
                "enabled": true,
                "defaultString": "Light Blue",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3411,
                "enabled": true,
                "defaultString": "Light Green",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3412,
                "enabled": true,
                "defaultString": "Maroon",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3413,
                "enabled": true,
                "defaultString": "Multi",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3414,
                "enabled": true,
                "defaultString": "Navy",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3415,
                "enabled": true,
                "defaultString": "Orange",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3416,
                "enabled": true,
                "defaultString": "Pink",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 3417,
                "enabled": true,
                "defaultString": "Primed",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3418,
                "enabled": true,
                "defaultString": "Purple",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 3419,
                "enabled": true,
                "defaultString": "Red",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 3420,
                "enabled": true,
                "defaultString": "Silver",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 3421,
                "enabled": true,
                "defaultString": "Tan",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 3422,
                "enabled": true,
                "defaultString": "Teal",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 3423,
                "enabled": true,
                "defaultString": "Turquoise",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 3424,
                "enabled": true,
                "defaultString": "White",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 3425,
                "enabled": true,
                "defaultString": "Wine",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 3426,
                "enabled": true,
                "defaultString": "Yellow",
                "sequenceNumber": 32
            }
        ]
    },
    {
        "RefListID": 337,
        "refListName": "StaffProperty_Category",
        "refListItems": [
            {
                "refListItemId": 3456,
                "enabled": true,
                "defaultString": "Uniforms: Basic Issue",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3457,
                "enabled": true,
                "defaultString": "Weapon: Basic Issue",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3458,
                "enabled": true,
                "defaultString": "Leather: Basic Issue",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3459,
                "enabled": true,
                "defaultString": "Other Equipment Issued",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3460,
                "enabled": true,
                "defaultString": "Special Equipment Issued",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3461,
                "enabled": true,
                "defaultString": "Special Issue Clothing",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3462,
                "enabled": true,
                "defaultString": "Special Weapons Issued",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 338,
        "refListName": "StaffProperty_Product",
        "refListItems": [
            {
                "refListItemId": 3463,
                "enabled": true,
                "defaultString": "Product 1",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3464,
                "enabled": true,
                "defaultString": "Product 2",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3465,
                "enabled": true,
                "defaultString": "Product 3",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 339,
        "refListName": "PropertyContexts",
        "refListItems": [
            {
                "refListItemId": 3500,
                "enabled": true,
                "defaultString": "Accident Reports",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3501,
                "enabled": true,
                "defaultString": "NIBRS",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3502,
                "enabled": true,
                "defaultString": "Pawned Property",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3503,
                "enabled": true,
                "defaultString": "Agency Property",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3504,
                "enabled": true,
                "defaultString": "ImpoundedVehicles",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3505,
                "enabled": true,
                "defaultString": "Inventory Items",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3506,
                "enabled": true,
                "defaultString": "Evidence & Property",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3507,
                "enabled": true,
                "defaultString": "InmateProperty",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3508,
                "enabled": true,
                "defaultString": "Weapon Confiscation",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 344,
        "refListName": "Charges_Attributes",
        "refListItems": [
            {
                "refListItemId": 3611,
                "enabled": true,
                "defaultString": "Speeding",
                "longString": "Add this attribute with a value of True if you want the Speeding section of Citations to appear when this charge is selected.",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3584,
                "enabled": true,
                "defaultString": "Forgery",
                "longString": "Add this attribute with a value of True if you want the Check Forgery section of Warrants to appear when this charge is selected.",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3769,
                "enabled": true,
                "defaultString": "PointsCurrent",
                "longString": "This is the number of points used for classification purposes if the charge is a current charge.",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3770,
                "enabled": true,
                "defaultString": "PointsPrevious",
                "longString": "This is the number of points used for classification purposes if the charge is a previous charge.",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4220,
                "enabled": true,
                "defaultString": "RequireOriginalCharge",
                "longString": "Add this attribute with a value of True if you want the Original Charge section in Warrants to appear when this charge is selected.",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4465,
                "enabled": true,
                "defaultString": "RequireAdditionalChargeInfo",
                "longString": "Add this attribute with a value of True if you want at least 6 characters in the Additional Charge Info field in Warrants to be required (for Anoka).",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 333,
        "refListName": "Activity_Reason",
        "refListItems": [
            {
                "refListItemId": 3432,
                "enabled": true,
                "defaultString": "Initial Interview",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3433,
                "enabled": true,
                "defaultString": "Re-Interview",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 334,
        "refListName": "ExportLinkedObjectMethod",
        "refListItems": [
            {
                "refListItemId": 3434,
                "enabled": true,
                "defaultString": "Not Set",
                "longString": "Indicates that the method has not been set for the link",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3435,
                "enabled": true,
                "defaultString": "GUPK",
                "longString": "Globally Unique Primary Key (ex: standard refListItems have a preassigned ID that is guaranteed unique across all databases).",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3436,
                "enabled": true,
                "defaultString": "AKFS",
                "longString": "Alternate Key Field Search in target database (ex: when a Jacket is imported via the data bridge, we have to do an alternate key field search in the target database to find a jacket).",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3437,
                "enabled": true,
                "defaultString": "GUAK",
                "longString": "Globally Unique Alternate Key (ex: Staff records have a traditional IDENTITY column, but we will also create a GAUK that can be used to find the object in the target database).",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3629,
                "enabled": true,
                "defaultString": "GUAK_FORCE_CREATE",
                "longString": "Same as GUAK, except that a link that uses this method will always export GUAKs as GUAK rather than GUAK_AKFS, which will force the DataBridge to create the object (with just the GUAK) rather than raising an error.",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 335,
        "refListName": "Charge_Contexts",
        "refListItems": [
            {
                "refListItemId": 3438,
                "enabled": true,
                "defaultString": "Bookings",
                "longString": "Use the Charge list for Bookings",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3439,
                "enabled": true,
                "defaultString": "Warrants",
                "longString": "Use the Charge list for Warrants",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3801,
                "enabled": true,
                "defaultString": "Citations",
                "longString": "Use the Charge list for Citations",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15997,
                "enabled": true,
                "defaultString": "UCR",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 336,
        "refListName": "Establishment_Type",
        "refListItems": [
            {
                "refListItemId": 3440,
                "enabled": true,
                "defaultString": "Manufacturer",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3441,
                "enabled": true,
                "defaultString": "Wholesaler",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3442,
                "enabled": true,
                "defaultString": "Retail Package",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3443,
                "enabled": true,
                "defaultString": "Food Store",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3444,
                "enabled": true,
                "defaultString": "Night Club",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3445,
                "enabled": true,
                "defaultString": "Restaurant",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3446,
                "enabled": true,
                "defaultString": "Lounge",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3447,
                "enabled": true,
                "defaultString": "Private Club",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3448,
                "enabled": true,
                "defaultString": "Perf. Arts Theater",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3449,
                "enabled": true,
                "defaultString": "Customer Dancing",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3450,
                "enabled": true,
                "defaultString": "Live Entertainment",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3451,
                "enabled": true,
                "defaultString": "Adult Entertainment",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3452,
                "enabled": true,
                "defaultString": "Convention Center",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3453,
                "enabled": true,
                "defaultString": "Importers",
                "sequenceNumber": 14
            }
        ]
    },
    {
        "RefListID": 341,
        "refListName": "Housing_Type",
        "refListItems": [
            {
                "refListItemId": 3567,
                "enabled": true,
                "defaultString": "Cell",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3568,
                "enabled": true,
                "defaultString": "Holding",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3569,
                "enabled": false,
                "defaultString": "Isolation",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 342,
        "refListName": "Inmate_Location",
        "refListItems": [
            {
                "refListItemId": 3570,
                "enabled": true,
                "defaultString": "In Jail",
                "longString": "This means that the inmate is in some cell in the jail (either a normal housing cell, a holding cell or an isolation cell currently).",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3571,
                "enabled": true,
                "defaultString": "Loan",
                "longString": "This means that the agency still has custody of the inmate (they are still on the count), but they are at a location other than a cell (court, doctor's office, etc.).",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3572,
                "enabled": true,
                "defaultString": "Transport",
                "longString": "This means that the agency still has custody of the inmate (they are still on the count), but they are being (temporarily) transported from one facility within the agency to another.",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 15971,
                "enabled": true,
                "defaultString": "Perm Housing Change",
                "longString": "This means that the agency still has custody of the inmate (they are still on the count), but they are being transported from one facility within the agency to another. Until they arrive, they will be on the count of the original location.",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 555,
        "refListName": "PersonType",
        "refListItems": [
            {
                "refListItemId": 16089,
                "enabled": true,
                "defaultString": "COMPLAINANT",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16090,
                "enabled": true,
                "defaultString": "OFFENDER",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16091,
                "enabled": true,
                "defaultString": "VICTIM",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16092,
                "enabled": true,
                "defaultString": "WITNESS",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 345,
        "refListName": "StaffProperty_Status",
        "refListItems": [
            {
                "refListItemId": 3585,
                "enabled": true,
                "defaultString": "Temporary",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3586,
                "enabled": true,
                "defaultString": "Permanent",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3587,
                "enabled": true,
                "defaultString": "Returned",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 346,
        "refListName": "Movement_Status",
        "refListItems": [
            {
                "refListItemId": 3588,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "The movement entry has not yet happened",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3589,
                "enabled": true,
                "defaultString": "Complete",
                "longString": "The movement entry has happened.",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3590,
                "enabled": true,
                "defaultString": "Cancelled",
                "longString": "A scheduled movement entry was cancelled.",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3591,
                "enabled": true,
                "defaultString": "In Transit",
                "longString": "This status should be used when the movement from one place to another within the jail may take a while, so that the agency can keep track of the inmate better.",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 347,
        "refListName": "Work_Release_Assignment",
        "refListItems": [
            {
                "refListItemId": 3592,
                "enabled": true,
                "defaultString": "Job",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3593,
                "enabled": true,
                "defaultString": "School",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 348,
        "refListName": "YesNoUnknown",
        "refListItems": [
            {
                "refListItemId": 3594,
                "enabled": true,
                "defaultString": "Y",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3595,
                "enabled": true,
                "defaultString": "N",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3596,
                "enabled": true,
                "defaultString": "U",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 349,
        "refListName": "Inmate_Movement_Rule_Type",
        "refListItems": [
            {
                "refListItemId": 3597,
                "enabled": true,
                "defaultString": "Keep Separate",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3598,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3599,
                "enabled": true,
                "defaultString": "Common Rules",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 350,
        "refListName": "Keep_Separate_Type",
        "refListItems": [
            {
                "refListItemId": 5527,
                "enabled": true,
                "defaultString": "Juveniles",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3600,
                "enabled": true,
                "defaultString": "Population",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3601,
                "enabled": true,
                "defaultString": "Specific Person",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3771,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 351,
        "refListName": "Inmate_Movement_Common",
        "refListItems": [
            {
                "refListItemId": 5525,
                "enabled": true,
                "defaultString": "Requires Escort",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5526,
                "enabled": true,
                "defaultString": "No Inter-Facility Transfer",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3602,
                "enabled": true,
                "defaultString": "Move with sergeant",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3603,
                "enabled": true,
                "defaultString": "Move with K-9",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 352,
        "refListName": "AuditLogRange",
        "refListItems": [
            {
                "refListItemId": 3604,
                "enabled": true,
                "defaultString": "Last 2 Weeks",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3605,
                "enabled": true,
                "defaultString": "Last 4 Weeks",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3606,
                "enabled": true,
                "defaultString": "Last 6 Weeks",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3607,
                "enabled": true,
                "defaultString": "All Entries",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3608,
                "enabled": true,
                "defaultString": "Last 24 Hours",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 24963,
                "enabled": true,
                "defaultString": "Summary",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 360,
        "refListName": "Comment_Log_Category",
        "refListItems": [
            {
                "refListItemId": 15960,
                "enabled": false,
                "defaultString": "Started Inmate Workers",
                "longString": ""
            },
            {
                "refListItemId": 15961,
                "enabled": false,
                "defaultString": "Blocked From Inmate Worker",
                "longString": ""
            },
            {
                "refListItemId": 15962,
                "enabled": false,
                "defaultString": "Giving Minor Rule Violation",
                "longString": ""
            },
            {
                "refListItemId": 3802,
                "enabled": false,
                "defaultString": "Tour",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3803,
                "enabled": true,
                "defaultString": "Inspection",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3804,
                "enabled": false,
                "defaultString": "Meal Pass",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3805,
                "enabled": false,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3806,
                "enabled": false,
                "defaultString": "Community Service",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3807,
                "enabled": false,
                "defaultString": "Behavioral",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3808,
                "enabled": false,
                "defaultString": "HUBER Hours",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3809,
                "enabled": true,
                "defaultString": "Head Count",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3810,
                "enabled": true,
                "defaultString": "Medical",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 16273,
                "enabled": true,
                "defaultString": "Character Observations",
                "longString": "",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 16274,
                "enabled": true,
                "defaultString": "Medical Watch",
                "longString": "",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 16275,
                "enabled": true,
                "defaultString": "Diabetic Watch",
                "longString": "",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 4246,
                "enabled": true,
                "defaultString": "Officer Report",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 16056,
                "enabled": true,
                "defaultString": "Review Post Orders",
                "longString": ""
            },
            {
                "refListItemId": 6881,
                "enabled": false,
                "defaultString": "Add Charges",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 6882,
                "enabled": false,
                "defaultString": "Ambulance Tranport",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 6883,
                "enabled": false,
                "defaultString": "Any Gang Activities",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 6884,
                "enabled": false,
                "defaultString": "Attempted To Book",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 6885,
                "enabled": true,
                "defaultString": "Attorney Visit",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 6886,
                "enabled": false,
                "defaultString": "Bail Paid",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 6887,
                "enabled": false,
                "defaultString": "Bail/Bond/Fine Received",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 6888,
                "enabled": true,
                "defaultString": "Bailiff Back With Inmates",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 6889,
                "enabled": false,
                "defaultString": "Bond Posted",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 6890,
                "enabled": false,
                "defaultString": "Booking",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 6891,
                "enabled": false,
                "defaultString": "Canteen Inmate Worker Detail",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 6892,
                "enabled": false,
                "defaultString": "Cell Assignment",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 6893,
                "enabled": false,
                "defaultString": "Cell Reassignment",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 6894,
                "enabled": false,
                "defaultString": "Check 36/48 Hr Log",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 6895,
                "enabled": false,
                "defaultString": "Check Housing Logs",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 6896,
                "enabled": false,
                "defaultString": "Check Housing Logs And Inspec",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 6897,
                "enabled": false,
                "defaultString": "Check Inmate In Holding",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 6898,
                "enabled": false,
                "defaultString": "Check Of Holding Area",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 6899,
                "enabled": false,
                "defaultString": "Check Out Date Report",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 6900,
                "enabled": false,
                "defaultString": "Check Perimeter Of Builiding",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 6901,
                "enabled": true,
                "defaultString": "Check/Verify Money Count",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 6902,
                "enabled": false,
                "defaultString": "Checked Housing Logs",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 6903,
                "enabled": false,
                "defaultString": "Classification Of Prisoner",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 6904,
                "enabled": true,
                "defaultString": "Clergy Visit",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 6905,
                "enabled": false,
                "defaultString": "Court-Out Of County",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 6906,
                "enabled": false,
                "defaultString": "Dist/Exchange Inmate Laundry",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 6907,
                "enabled": false,
                "defaultString": "Dist/Pick Up Inmate Mail",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 6908,
                "enabled": true,
                "defaultString": "Distribute Canteen",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 6909,
                "enabled": true,
                "defaultString": "Distribute Meals",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 6910,
                "enabled": false,
                "defaultString": "Distributed Canteen",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 6911,
                "enabled": true,
                "defaultString": "Duress Alarm",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 6912,
                "enabled": true,
                "defaultString": "Emergency Call",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 6913,
                "enabled": true,
                "defaultString": "End Tour Of Duty",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 6914,
                "enabled": false,
                "defaultString": "Escape/Attempted Escape",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 6915,
                "enabled": true,
                "defaultString": "Facility Supplies Received",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 6916,
                "enabled": false,
                "defaultString": "Fine Paid",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 6917,
                "enabled": true,
                "defaultString": "Fire Alarm",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 6918,
                "enabled": false,
                "defaultString": "Fto Duties",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 6919,
                "enabled": false,
                "defaultString": "Furlough-Appt",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 6920,
                "enabled": false,
                "defaultString": "Housing Release",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 6921,
                "enabled": false,
                "defaultString": "Huber Returning Inmate",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 6922,
                "enabled": true,
                "defaultString": "Inmate Account Maintenance",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 6923,
                "enabled": true,
                "defaultString": "Inmate Civil Paper Service",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 6924,
                "enabled": true,
                "defaultString": "Inmate Court Movement",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 6925,
                "enabled": false,
                "defaultString": "Inmate Court Service Visit",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 6926,
                "enabled": false,
                "defaultString": "Inmate Disipline Hearing",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 6927,
                "enabled": true,
                "defaultString": "Inmate For Sick Call",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 6928,
                "enabled": false,
                "defaultString": "Inmate Janitor Detail",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 6929,
                "enabled": false,
                "defaultString": "Inmate Kitchen Detail",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 6930,
                "enabled": false,
                "defaultString": "Inmate Laundry Detail",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 6931,
                "enabled": true,
                "defaultString": "Inmate Line Up",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 6932,
                "enabled": false,
                "defaultString": "Inmate Property",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 6933,
                "enabled": true,
                "defaultString": "Inmate Refused To Be Booked",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 6934,
                "enabled": true,
                "defaultString": "Inmate Release",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 6935,
                "enabled": true,
                "defaultString": "Inmate Return To Unit",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 6936,
                "enabled": false,
                "defaultString": "Inmate Special Work Detail",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 6937,
                "enabled": true,
                "defaultString": "Inmate To Education",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 6938,
                "enabled": true,
                "defaultString": "Inmate To Library",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 6939,
                "enabled": true,
                "defaultString": "Inmate To Program",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 6940,
                "enabled": true,
                "defaultString": "Inmate To Recreation",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 6941,
                "enabled": true,
                "defaultString": "Inmate Transported",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 6942,
                "enabled": false,
                "defaultString": "Inmate Violation Warning",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 6943,
                "enabled": false,
                "defaultString": "Inmate Well-Being Check",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 6944,
                "enabled": false,
                "defaultString": "Investigator Vist",
                "longString": "",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 6945,
                "enabled": true,
                "defaultString": "Key Assignment",
                "longString": "",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 6946,
                "enabled": true,
                "defaultString": "Location Assignment",
                "longString": "",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 6947,
                "enabled": true,
                "defaultString": "Lockdown",
                "longString": "",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 6948,
                "enabled": false,
                "defaultString": "Maintenance On 10 Printer",
                "longString": "",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 6949,
                "enabled": false,
                "defaultString": "Med Key In/Out",
                "longString": "",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 6950,
                "enabled": true,
                "defaultString": "Medical Emergency",
                "longString": "",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 6951,
                "enabled": false,
                "defaultString": "Medical Health Assesment",
                "longString": "",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 6952,
                "enabled": false,
                "defaultString": "Miscellaneous Duties",
                "longString": "",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 6953,
                "enabled": true,
                "defaultString": "Money Transfer",
                "longString": "",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 6954,
                "enabled": false,
                "defaultString": "Move Prisoner",
                "longString": "",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 6955,
                "enabled": true,
                "defaultString": "Narc Meds Out Of Cabinet",
                "longString": "",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 6956,
                "enabled": true,
                "defaultString": "On Duty",
                "longString": "",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 6957,
                "enabled": false,
                "defaultString": "Oxygen Tank  Inspection",
                "longString": "",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 6958,
                "enabled": false,
                "defaultString": "Passing Medication To Inmates",
                "longString": "",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 6959,
                "enabled": false,
                "defaultString": "Perform Inmate Pat Search",
                "longString": "",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 6960,
                "enabled": false,
                "defaultString": "Perform Inmate Strip  Search",
                "longString": "",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 6961,
                "enabled": true,
                "defaultString": "Personal Inmate Visit",
                "longString": "",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 6962,
                "enabled": true,
                "defaultString": "Phone Call Granted To Inmate",
                "longString": "",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 6963,
                "enabled": true,
                "defaultString": "Prepare Post Assignments",
                "longString": "",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 6964,
                "enabled": false,
                "defaultString": "Prisoners Violation",
                "longString": "",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 6965,
                "enabled": false,
                "defaultString": "Psycholgist/Psychiatrist Vist",
                "longString": "",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 6966,
                "enabled": false,
                "defaultString": "Public Fingerprints",
                "longString": "",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 6967,
                "enabled": true,
                "defaultString": "Public Tour",
                "longString": "",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 6968,
                "enabled": false,
                "defaultString": "Radio Assignment",
                "longString": "",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 6969,
                "enabled": false,
                "defaultString": "Re-Book",
                "longString": "",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 6970,
                "enabled": true,
                "defaultString": "Review Evaluation/Employee",
                "longString": "",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 6971,
                "enabled": false,
                "defaultString": "Review Memos And Reports",
                "longString": "",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 6972,
                "enabled": true,
                "defaultString": "Run Booking List/Affidavits",
                "longString": "",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 6973,
                "enabled": false,
                "defaultString": "Security Alarm",
                "longString": "",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 6974,
                "enabled": false,
                "defaultString": "Service Vender Pass Issued",
                "longString": "",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 6975,
                "enabled": false,
                "defaultString": "Service Vendor Facility Visit",
                "longString": "",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 6976,
                "enabled": false,
                "defaultString": "Shakedown Perfomed",
                "longString": "",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 6977,
                "enabled": false,
                "defaultString": "Sharp Kitchen Utensil Inspect",
                "longString": "",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 6978,
                "enabled": false,
                "defaultString": "Squad In Sallyport",
                "longString": "",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 6979,
                "enabled": true,
                "defaultString": "Supervisor Briefing",
                "longString": "",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 6980,
                "enabled": false,
                "defaultString": "Supplies Distributed",
                "longString": "",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 6981,
                "enabled": false,
                "defaultString": "Test Of Jail Activity Log",
                "longString": "",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 6982,
                "enabled": false,
                "defaultString": "Tool Maint. Inspection",
                "longString": "",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 6983,
                "enabled": false,
                "defaultString": "Tour Living Units",
                "longString": "",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 6984,
                "enabled": true,
                "defaultString": "Tour Lockdown Inmates",
                "longString": "",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 6985,
                "enabled": true,
                "defaultString": "Tour Medical Area",
                "longString": "",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 6986,
                "enabled": true,
                "defaultString": "Tour Segregation",
                "longString": "",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 6987,
                "enabled": false,
                "defaultString": "Tour Special Managment Unit",
                "longString": "",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 6988,
                "enabled": false,
                "defaultString": "Type Memo",
                "longString": "",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 6989,
                "enabled": false,
                "defaultString": "Type Reports",
                "longString": "",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 6990,
                "enabled": false,
                "defaultString": "Use Phca To Assign A Cell",
                "longString": "",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 6991,
                "enabled": false,
                "defaultString": "Vehicle Clear The Sally Port",
                "longString": "",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 6992,
                "enabled": true,
                "defaultString": "Visitor Pass Issued",
                "longString": "",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 6993,
                "enabled": false,
                "defaultString": "Walkin For Court",
                "longString": "",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 6994,
                "enabled": false,
                "defaultString": "Working On Outdate Info",
                "longString": "",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 6995,
                "enabled": false,
                "defaultString": "PJI",
                "longString": "",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 15963,
                "enabled": false,
                "defaultString": "Giving Major Rule Violation",
                "longString": ""
            },
            {
                "refListItemId": 16304,
                "enabled": true,
                "defaultString": "Tour Special Management Unit",
                "longString": "",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 16305,
                "enabled": false,
                "defaultString": "Inmates Wellbeing Check",
                "longString": "",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 16306,
                "enabled": true,
                "defaultString": "Tour Booking Area",
                "longString": "",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 16307,
                "enabled": true,
                "defaultString": "Tour Visiting Area",
                "longString": "",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 16276,
                "enabled": true,
                "defaultString": "Security Risk",
                "longString": "",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 16277,
                "enabled": true,
                "defaultString": "Substance Withdrawals",
                "longString": "",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 16278,
                "enabled": true,
                "defaultString": "Suicide Watch",
                "longString": "",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 16279,
                "enabled": true,
                "defaultString": "Security Inspection",
                "longString": "",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 16280,
                "enabled": true,
                "defaultString": "Daily Inspection",
                "longString": "",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 16281,
                "enabled": true,
                "defaultString": "Unit Shakedown",
                "longString": "",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 16282,
                "enabled": false,
                "defaultString": "Inmates well being check",
                "longString": "",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 16283,
                "enabled": true,
                "defaultString": "Misc. Duties",
                "longString": "",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 16284,
                "enabled": true,
                "defaultString": "Perform Pat Search",
                "longString": "",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 16285,
                "enabled": true,
                "defaultString": "Perform Strip Search",
                "longString": "",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 16286,
                "enabled": true,
                "defaultString": "Shakedown Performed",
                "longString": "",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 16287,
                "enabled": true,
                "defaultString": "Medical Key In/Out",
                "longString": "",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 16288,
                "enabled": true,
                "defaultString": "Ambulance Transport",
                "longString": "",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 16289,
                "enabled": true,
                "defaultString": "Vehicle in Sallyport",
                "longString": "",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 16290,
                "enabled": true,
                "defaultString": "Vehicle clear Sallyport",
                "longString": "",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 16291,
                "enabled": true,
                "defaultString": "Check 36/48 hour log",
                "longString": "",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 16292,
                "enabled": true,
                "defaultString": "Check Housing Logs and Inspection",
                "longString": "",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 16293,
                "enabled": true,
                "defaultString": "Tour Facility",
                "longString": "",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 16294,
                "enabled": true,
                "defaultString": "Check Outdate Report",
                "longString": "",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 16295,
                "enabled": true,
                "defaultString": "Check Perimeter of Building",
                "longString": "",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 16296,
                "enabled": true,
                "defaultString": "Review Memos/Reports",
                "longString": "",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 16297,
                "enabled": true,
                "defaultString": "Type Memos/Reports",
                "longString": "",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 16298,
                "enabled": true,
                "defaultString": "Employee Meeting/Evaluation",
                "longString": "",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 16299,
                "enabled": true,
                "defaultString": "Attempt to Book",
                "longString": "",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 16300,
                "enabled": true,
                "defaultString": "Distribute/Exchange Laundry",
                "longString": "",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 16301,
                "enabled": true,
                "defaultString": "Distribute/Pickup Mail",
                "longString": "",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 16302,
                "enabled": true,
                "defaultString": "Inmate Discipline Hearing",
                "longString": "",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 16303,
                "enabled": true,
                "defaultString": "Passing Medications to Inmates",
                "longString": "",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 19246,
                "enabled": true,
                "defaultString": "Well-Being Check"
            },
            {
                "refListItemId": 19248,
                "enabled": true,
                "defaultString": "Inmate Status Change"
            }
        ]
    },
    {
        "RefListID": 354,
        "refListName": "Schedule_Type",
        "refListItems": [
            {
                "refListItemId": 3617,
                "enabled": true,
                "defaultString": "Monday through Friday",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3618,
                "enabled": true,
                "defaultString": "Monday, Wednesday, Friday",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3619,
                "enabled": true,
                "defaultString": "Tuesday, Thursday",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3620,
                "enabled": true,
                "defaultString": "Weekend",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3621,
                "enabled": true,
                "defaultString": "Every Day",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3622,
                "enabled": true,
                "defaultString": "Monday",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3623,
                "enabled": true,
                "defaultString": "Tuesday",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3624,
                "enabled": true,
                "defaultString": "Wednesday",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3625,
                "enabled": true,
                "defaultString": "Thursday",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3626,
                "enabled": true,
                "defaultString": "Friday",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3627,
                "enabled": true,
                "defaultString": "Saturday",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3628,
                "enabled": true,
                "defaultString": "Sunday",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 355,
        "refListName": "Area_Stolen",
        "refListItems": "NULL"
    },
    {
        "RefListID": 356,
        "refListName": "PermitIssueType",
        "refListItems": [
            {
                "refListItemId": 3772,
                "enabled": true,
                "defaultString": "New",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3773,
                "enabled": true,
                "defaultString": "Renew",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3774,
                "enabled": true,
                "defaultString": "Duplicate",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 357,
        "refListName": "PermitCharges",
        "refListItems": [
            {
                "refListItemId": 3775,
                "enabled": true,
                "defaultString": "Permit Charge 1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3776,
                "enabled": true,
                "defaultString": "Permit Charge 2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3777,
                "enabled": true,
                "defaultString": "Permit Charge 3",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 358,
        "refListName": "PermitInspector",
        "refListItems": [
            {
                "refListItemId": 3778,
                "enabled": true,
                "defaultString": "Inspector 1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3779,
                "enabled": true,
                "defaultString": "Inspector 2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3780,
                "enabled": true,
                "defaultString": "Inspector 3",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 359,
        "refListName": "PermitDisposition",
        "refListItems": [
            {
                "refListItemId": 3781,
                "enabled": true,
                "defaultString": "Dismissed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3782,
                "enabled": true,
                "defaultString": "Guilty",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3783,
                "enabled": true,
                "defaultString": "Guilty/Fine Susp.",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3784,
                "enabled": true,
                "defaultString": "Not Guilty",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 361,
        "refListName": "Clearance_Reason",
        "refListItems": [
            {
                "refListItemId": 3811,
                "enabled": true,
                "defaultString": "Bond Paid",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3812,
                "enabled": true,
                "defaultString": "Fine Paid",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3813,
                "enabled": true,
                "defaultString": "No Extradition",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3814,
                "enabled": true,
                "defaultString": "Charge Dropped",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 362,
        "refListName": "Reason_Held",
        "refListItems": [
            {
                "refListItemId": 3823,
                "enabled": true,
                "defaultString": "Board - other agency",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3824,
                "enabled": true,
                "defaultString": "Board - INS",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4466,
                "enabled": true,
                "defaultString": "Hold for Other County",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4467,
                "enabled": true,
                "defaultString": "Hold for MN DOC",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4468,
                "enabled": true,
                "defaultString": "Hold for Federal Agency",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4469,
                "enabled": true,
                "defaultString": "Hold for MN Agency",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 4470,
                "enabled": true,
                "defaultString": "Hold for Probation",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 4471,
                "enabled": true,
                "defaultString": "Hold for Other State",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 3815,
                "enabled": true,
                "defaultString": "Probable Cause",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3816,
                "enabled": true,
                "defaultString": "Ticket",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3817,
                "enabled": true,
                "defaultString": "Warrant",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3818,
                "enabled": false,
                "defaultString": "Bench Warrant",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3819,
                "enabled": false,
                "defaultString": "Straight Time",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3820,
                "enabled": true,
                "defaultString": "Judge Ordered",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3821,
                "enabled": true,
                "defaultString": "Book and release",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3822,
                "enabled": true,
                "defaultString": "Corrections Want",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3825,
                "enabled": true,
                "defaultString": "Board - US Marshals",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3826,
                "enabled": true,
                "defaultString": "Board - State",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3827,
                "enabled": false,
                "defaultString": "Bond Revoked",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3828,
                "enabled": false,
                "defaultString": "Weekend Commitment",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3829,
                "enabled": true,
                "defaultString": "Huber Violation",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3830,
                "enabled": false,
                "defaultString": "Probation Violation",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3831,
                "enabled": true,
                "defaultString": "Warrant - Family Court",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4472,
                "enabled": true,
                "defaultString": "Writ",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 4940,
                "enabled": true,
                "defaultString": "Sentenced",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 16054,
                "enabled": true,
                "defaultString": "Governors Want",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 19893,
                "enabled": true,
                "defaultString": "PC - Lesser Included Charge",
                "sequenceNumber": 27
            }
        ]
    },
    {
        "RefListID": 363,
        "refListName": "Legal_Status",
        "refListItems": [
            {
                "refListItemId": 5203,
                "enabled": false,
                "defaultString": "Juvenile Adjudicated",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5204,
                "enabled": false,
                "defaultString": "Juvenile Committed",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5205,
                "enabled": false,
                "defaultString": "Juvenile Pre-Adjudicated",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3832,
                "enabled": true,
                "defaultString": "Adult Pre-Sentenced",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3833,
                "enabled": true,
                "defaultString": "Adult Pre-Trial",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3834,
                "enabled": true,
                "defaultString": "Adult Sentenced",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3835,
                "enabled": true,
                "defaultString": "NA/Unknown",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 364,
        "refListName": "Charge_Modifier",
        "refListItems": [
            {
                "refListItemId": 3836,
                "enabled": false,
                "defaultString": "Violation of Probation",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3837,
                "enabled": false,
                "defaultString": "Violation of Parole",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3838,
                "enabled": true,
                "defaultString": "Conspiracy to Commit",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3839,
                "enabled": true,
                "defaultString": "Aiding/Abetting",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3840,
                "enabled": false,
                "defaultString": "Failure to Appear",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4226,
                "enabled": true,
                "defaultString": "Facilitation of",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4227,
                "enabled": true,
                "defaultString": "Solicitation of",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4228,
                "enabled": true,
                "defaultString": "Threat to Commit",
                "longString": "",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 365,
        "refListName": "Dominant_Hand",
        "refListItems": [
            {
                "refListItemId": 3841,
                "enabled": true,
                "defaultString": "Left-Handed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3842,
                "enabled": true,
                "defaultString": "Right-Handed",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3843,
                "enabled": true,
                "defaultString": "Ambidextrous",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3844,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 562,
        "refListName": "UCRPersonChargeCode",
        "refListItems": [
            {
                "refListItemId": 16099,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 450,
        "refListName": "NIBRS_ORI",
        "refListItems": [
            {
                "refListItemId": 4889,
                "enabled": true,
                "defaultString": "XX1234567",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 368,
        "refListName": "Release_Reason",
        "refListItems": [
            {
                "refListItemId": 3855,
                "enabled": true,
                "defaultString": "Bail Supplied",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3856,
                "enabled": true,
                "defaultString": "Charges Dropped",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3857,
                "enabled": true,
                "defaultString": "Case Dismissed",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3858,
                "enabled": true,
                "defaultString": "Not Guilty",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3859,
                "enabled": true,
                "defaultString": "Death - Homicide",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3860,
                "enabled": true,
                "defaultString": "Death - Other",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3861,
                "enabled": true,
                "defaultString": "Death - Suicide",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3862,
                "enabled": true,
                "defaultString": "Escape from Facility",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3863,
                "enabled": true,
                "defaultString": "Escape from Temp Release",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3864,
                "enabled": true,
                "defaultString": "Escape while in Transit",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 3865,
                "enabled": true,
                "defaultString": "Indefinite Furlough",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 3866,
                "enabled": true,
                "defaultString": "Illness/Hospital",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 3867,
                "enabled": true,
                "defaultString": "Paid Fine",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 3868,
                "enabled": true,
                "defaultString": "Personal / Parental Recognizance",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 3869,
                "enabled": true,
                "defaultString": "Rel. To Another Authority",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 3870,
                "enabled": true,
                "defaultString": "Rel. To Another Facility",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 3871,
                "enabled": true,
                "defaultString": "Rel. To Another State",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 3872,
                "enabled": true,
                "defaultString": "Rel. Due To Overcrowding",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 3873,
                "enabled": true,
                "defaultString": "Runaway",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 3874,
                "enabled": true,
                "defaultString": "Sentence Served",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 3875,
                "enabled": true,
                "defaultString": "Temp/Cond. Release",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 3876,
                "enabled": false,
                "defaultString": "Rel. To Another Agency",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 25006,
                "enabled": true,
                "defaultString": "Transitional Housing",
                "sequenceNumber": 23
            }
        ]
    },
    {
        "RefListID": 563,
        "refListName": "UCROffenseChar",
        "refListItems": [
            {
                "refListItemId": 16100,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 556,
        "refListName": "IncidentLocationCode",
        "refListItems": [
            {
                "refListItemId": 16093,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 371,
        "refListName": "Medication_Type",
        "refListItems": [
            {
                "refListItemId": 3885,
                "enabled": true,
                "defaultString": "Narcotic",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3886,
                "enabled": true,
                "defaultString": "Antibiotic",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3887,
                "enabled": true,
                "defaultString": "Pain Reliever",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3888,
                "enabled": true,
                "defaultString": "Anti-inflammatory",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3889,
                "enabled": true,
                "defaultString": "Muscle Relaxant",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 372,
        "refListName": "Comment_Log_Scope",
        "refListItems": [
            {
                "refListItemId": 3890,
                "enabled": true,
                "defaultString": "Facility",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3891,
                "enabled": true,
                "defaultString": "Location",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3892,
                "enabled": true,
                "defaultString": "Inmate",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16270,
                "enabled": true,
                "defaultString": "Inmate Watch",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16271,
                "enabled": true,
                "defaultString": "Location Watch",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 373,
        "refListName": "General_Appearance",
        "refListItems": [
            {
                "refListItemId": 3893,
                "enabled": true,
                "defaultString": "Neat, Well-Dressed",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3894,
                "enabled": true,
                "defaultString": "Dirty",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3895,
                "enabled": true,
                "defaultString": "Uniformed",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3896,
                "enabled": true,
                "defaultString": "Unusual Jewelry",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3897,
                "enabled": true,
                "defaultString": "Unusual Walk",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3898,
                "enabled": true,
                "defaultString": "Ragged",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3899,
                "enabled": true,
                "defaultString": "Casual",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3900,
                "enabled": true,
                "defaultString": "Normal",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3901,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3902,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 557,
        "refListName": "ORI",
        "refListItems": [
            {
                "refListItemId": 16094,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 449,
        "refListName": "NIBRS_Agency",
        "refListItems": [
            {
                "refListItemId": 4888,
                "enabled": true,
                "defaultString": "City, State",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 376,
        "refListName": "Sanction_Type",
        "refListItems": [
            {
                "refListItemId": 3917,
                "enabled": true,
                "defaultString": "Lose Good Time Days",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3918,
                "enabled": true,
                "defaultString": "Lockdown",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3919,
                "enabled": true,
                "defaultString": "Lose Visitation Rights ",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3920,
                "enabled": true,
                "defaultString": "Lose HUBER priviliges",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3921,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3922,
                "enabled": true,
                "defaultString": "No recreation",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3923,
                "enabled": true,
                "defaultString": "No library",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3924,
                "enabled": true,
                "defaultString": "No canteen",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3925,
                "enabled": true,
                "defaultString": "Delayed release",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3926,
                "enabled": true,
                "defaultString": "Transfer to Jail",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 377,
        "refListName": "ActionRequested",
        "refListItems": [
            {
                "refListItemId": 3927,
                "enabled": true,
                "defaultString": "Lose Good Time Days",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3928,
                "enabled": true,
                "defaultString": "Lockdown",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3929,
                "enabled": true,
                "defaultString": "Lose Visitation Rights ",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3930,
                "enabled": true,
                "defaultString": "Lose HUBER priviliges",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3931,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3932,
                "enabled": true,
                "defaultString": "No recreation",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3933,
                "enabled": true,
                "defaultString": "No library",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3934,
                "enabled": true,
                "defaultString": "No canteen",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 378,
        "refListName": "Program_Name",
        "refListItems": [
            {
                "refListItemId": 3935,
                "enabled": false,
                "defaultString": "GED",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3936,
                "enabled": false,
                "defaultString": "AA",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3937,
                "enabled": false,
                "defaultString": "NA",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3938,
                "enabled": false,
                "defaultString": "Religion",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3939,
                "enabled": true,
                "defaultString": "Library",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3940,
                "enabled": true,
                "defaultString": "Recreation",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 3941,
                "enabled": false,
                "defaultString": "Anger Management",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 3942,
                "enabled": true,
                "defaultString": "Parenting",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 3943,
                "enabled": false,
                "defaultString": "Money Management",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 3944,
                "enabled": true,
                "defaultString": "Law Library",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 15430,
                "enabled": false,
                "defaultString": "Nutrition Class",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 15431,
                "enabled": true,
                "defaultString": "Computer Lab",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 15432,
                "enabled": true,
                "defaultString": "ROP",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 15433,
                "enabled": false,
                "defaultString": "Counseling",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 15434,
                "enabled": true,
                "defaultString": "CWS",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 15435,
                "enabled": false,
                "defaultString": "Treatment",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 15475,
                "enabled": false,
                "defaultString": "247",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 15476,
                "enabled": false,
                "defaultString": "276",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 15477,
                "enabled": false,
                "defaultString": "285",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 15478,
                "enabled": false,
                "defaultString": "292",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 15479,
                "enabled": false,
                "defaultString": "449",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 15480,
                "enabled": false,
                "defaultString": "450",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 15481,
                "enabled": false,
                "defaultString": "451",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 15482,
                "enabled": false,
                "defaultString": "EDU",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 15483,
                "enabled": false,
                "defaultString": "LIB",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 15484,
                "enabled": false,
                "defaultString": "REC",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 15984,
                "enabled": false,
                "defaultString": "Other Program"
            },
            {
                "refListItemId": 15985,
                "enabled": false,
                "defaultString": "Drawing Club"
            },
            {
                "refListItemId": 15986,
                "enabled": true,
                "defaultString": "Choice and Change"
            },
            {
                "refListItemId": 16308,
                "enabled": false,
                "defaultString": "Alcoholics Anonymous",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 16475,
                "enabled": true,
                "defaultString": "A.A",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 16476,
                "enabled": true,
                "defaultString": "Addiction Recovery",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 16477,
                "enabled": true,
                "defaultString": "Bible Study",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 16478,
                "enabled": true,
                "defaultString": "Church Service",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 16479,
                "enabled": true,
                "defaultString": "COG Thinking",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 16480,
                "enabled": true,
                "defaultString": "GED Class",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 16481,
                "enabled": true,
                "defaultString": "GED Testing",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 16482,
                "enabled": true,
                "defaultString": "Indiv. Spiritual Counselor",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 16483,
                "enabled": true,
                "defaultString": "MADD",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 16484,
                "enabled": true,
                "defaultString": "N.A.",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 16485,
                "enabled": true,
                "defaultString": "RTP",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 16486,
                "enabled": true,
                "defaultString": "Visiting",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 16487,
                "enabled": true,
                "defaultString": "Other - see comments",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 16488,
                "enabled": true,
                "defaultString": "C.M.A",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 16489,
                "enabled": true,
                "defaultString": "Drawing Lab",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 16490,
                "enabled": true,
                "defaultString": "Journalism",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 16491,
                "enabled": true,
                "defaultString": "Orientation",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 16492,
                "enabled": true,
                "defaultString": "Salvation Army",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 16493,
                "enabled": true,
                "defaultString": "Teen Challenge",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 16494,
                "enabled": true,
                "defaultString": "Circuit Training",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 16495,
                "enabled": true,
                "defaultString": "Yoga",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 16496,
                "enabled": true,
                "defaultString": "Health Education",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 16497,
                "enabled": true,
                "defaultString": "Haircuts/Hair Clippers",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 16498,
                "enabled": true,
                "defaultString": "Job Training",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 16499,
                "enabled": true,
                "defaultString": "See comments",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 16500,
                "enabled": true,
                "defaultString": "PL2-AA",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 16501,
                "enabled": true,
                "defaultString": "PL2-NA",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 16502,
                "enabled": true,
                "defaultString": "PL2-Choice& Change",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 16503,
                "enabled": true,
                "defaultString": "PL2-Drawing Lab",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 16504,
                "enabled": true,
                "defaultString": "PL2-GED Class",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 16505,
                "enabled": true,
                "defaultString": "PL2-GED Testing",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 16506,
                "enabled": true,
                "defaultString": "PL2-Journalism",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 16507,
                "enabled": true,
                "defaultString": "PL2-Individual Spiritual Counseling",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 16508,
                "enabled": true,
                "defaultString": "PL2-Orientation",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 16509,
                "enabled": true,
                "defaultString": "PL2-Parenting",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 16510,
                "enabled": true,
                "defaultString": "PL2-Other – See Comments",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 16511,
                "enabled": true,
                "defaultString": "PL2-Recreation",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 16512,
                "enabled": true,
                "defaultString": "PL2-Church Service",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 16513,
                "enabled": true,
                "defaultString": "PL2-Catholic Service",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 16514,
                "enabled": true,
                "defaultString": "PL2-Native American Service",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 16515,
                "enabled": true,
                "defaultString": "PL4-AA",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 16516,
                "enabled": true,
                "defaultString": "PL4-NA",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 16517,
                "enabled": true,
                "defaultString": "PL4-Bible Study",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 16518,
                "enabled": true,
                "defaultString": "PL4-Teen Challenge",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 16519,
                "enabled": true,
                "defaultString": "PL4-Salvation Army",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 16520,
                "enabled": true,
                "defaultString": "PL4-Individual Spiritual Counseling",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 16521,
                "enabled": true,
                "defaultString": "PL4-Orientation",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 16522,
                "enabled": true,
                "defaultString": "PL4-Other – See Comments",
                "sequenceNumber": 78
            }
        ]
    },
    {
        "RefListID": 379,
        "refListName": "Scheduled_Event_Type",
        "refListItems": [
            {
                "refListItemId": 3945,
                "enabled": true,
                "defaultString": "Job Schedule",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3946,
                "enabled": true,
                "defaultString": "Activity Request",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3947,
                "enabled": true,
                "defaultString": "Medical",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 380,
        "refListName": "One_Time_Status",
        "refListItems": [
            {
                "refListItemId": 3948,
                "enabled": true,
                "defaultString": "Pending",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3949,
                "enabled": true,
                "defaultString": "Complete",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3950,
                "enabled": true,
                "defaultString": "Canceled",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 381,
        "refListName": "Workhouse_Status",
        "refListItems": [
            {
                "refListItemId": 3951,
                "enabled": true,
                "defaultString": "HUBER",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3952,
                "enabled": true,
                "defaultString": "Trustee",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3953,
                "enabled": true,
                "defaultString": "Student",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24969,
                "enabled": true,
                "defaultString": "C Class",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 25004,
                "enabled": true,
                "defaultString": "Transitional Housing",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 382,
        "refListName": "Warrant_Clearance_Reason",
        "refListItems": [
            {
                "refListItemId": 3954,
                "enabled": true,
                "defaultString": "RPR",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3955,
                "enabled": true,
                "defaultString": "Bail Paid",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3956,
                "enabled": true,
                "defaultString": "Fine Paid",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3957,
                "enabled": true,
                "defaultString": "Arrested",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3958,
                "enabled": true,
                "defaultString": "Quashed",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 383,
        "refListName": "Comment_Log_Type",
        "refListItems": [
            {
                "refListItemId": 3959,
                "enabled": true,
                "defaultString": "Jail",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3960,
                "enabled": true,
                "defaultString": "Medical",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 384,
        "refListName": "Medical_Log_Category",
        "refListItems": [
            {
                "refListItemId": 3961,
                "enabled": true,
                "defaultString": "Mantoux Test",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3962,
                "enabled": true,
                "defaultString": "Asthma",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3963,
                "enabled": true,
                "defaultString": "Health Assessment",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 385,
        "refListName": "Medical_Staff_Type",
        "refListItems": [
            {
                "refListItemId": 3964,
                "enabled": true,
                "defaultString": "Nurse",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3965,
                "enabled": true,
                "defaultString": "Doctor",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3966,
                "enabled": true,
                "defaultString": "Psychiatrist",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3967,
                "enabled": true,
                "defaultString": "Deputy",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 386,
        "refListName": "Priority",
        "refListItems": [
            {
                "refListItemId": 3968,
                "enabled": true,
                "defaultString": "High",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3969,
                "enabled": true,
                "defaultString": "Medium",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3970,
                "enabled": true,
                "defaultString": "Low",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 387,
        "refListName": "Ailments",
        "refListItems": [
            {
                "refListItemId": 3971,
                "enabled": true,
                "defaultString": "Diabetes",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3972,
                "enabled": true,
                "defaultString": "STI",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3973,
                "enabled": true,
                "defaultString": "Minor Wound",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3974,
                "enabled": true,
                "defaultString": "Major Wound",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3975,
                "enabled": true,
                "defaultString": "Tuberculosis",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3976,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 388,
        "refListName": "Medical_Education",
        "refListItems": [
            {
                "refListItemId": 3977,
                "enabled": true,
                "defaultString": "Prescription Instructions",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3978,
                "enabled": true,
                "defaultString": "Wound Care",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3979,
                "enabled": true,
                "defaultString": "Depression",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3980,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 389,
        "refListName": "Patient_Response",
        "refListItems": [
            {
                "refListItemId": 3981,
                "enabled": true,
                "defaultString": "Understood instructions, likely to follow",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3982,
                "enabled": true,
                "defaultString": "Understood instructions, unlikely to follow",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3983,
                "enabled": true,
                "defaultString": "Trouble following instructions",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3984,
                "enabled": true,
                "defaultString": "Resistant to plan",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 390,
        "refListName": "Medical_Visit_Outcome",
        "refListItems": [
            {
                "refListItemId": 3985,
                "enabled": true,
                "defaultString": "Written Response",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3986,
                "enabled": true,
                "defaultString": "Insufficient Time",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3987,
                "enabled": true,
                "defaultString": "Interrupted",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3988,
                "enabled": true,
                "defaultString": "Completed",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3989,
                "enabled": true,
                "defaultString": "Canceled By Inmate",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3990,
                "enabled": true,
                "defaultString": "Canceled By Physician",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 391,
        "refListName": "Visit_Location",
        "refListItems": [
            {
                "refListItemId": 3991,
                "enabled": true,
                "defaultString": "In-House",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3992,
                "enabled": true,
                "defaultString": "Outpatient",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3993,
                "enabled": true,
                "defaultString": "911",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 392,
        "refListName": "Medical_Authorizations",
        "refListItems": [
            {
                "refListItemId": 3994,
                "enabled": true,
                "defaultString": "Medical Cell",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 3995,
                "enabled": true,
                "defaultString": "Single Cell",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 3996,
                "enabled": true,
                "defaultString": "Lower Bunk",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 3997,
                "enabled": true,
                "defaultString": "Lower Level",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 3998,
                "enabled": true,
                "defaultString": "Ice Pack",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 3999,
                "enabled": true,
                "defaultString": "Ace Wraps",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4000,
                "enabled": true,
                "defaultString": "Extra Pillow(s)",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4001,
                "enabled": true,
                "defaultString": "Activity Restrictions",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4002,
                "enabled": true,
                "defaultString": "Rest",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4003,
                "enabled": true,
                "defaultString": "Hot Pack",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4004,
                "enabled": true,
                "defaultString": "Gauze",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4005,
                "enabled": true,
                "defaultString": "Elastic Socks",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4006,
                "enabled": true,
                "defaultString": "Wound Care Supplies",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4007,
                "enabled": true,
                "defaultString": "Foot Soak Supplies",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4008,
                "enabled": true,
                "defaultString": "Wheelchair",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4009,
                "enabled": true,
                "defaultString": "Walker",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4010,
                "enabled": true,
                "defaultString": "Crutches",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4011,
                "enabled": true,
                "defaultString": "Cane",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4012,
                "enabled": true,
                "defaultString": "Prosthetic Device",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4013,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 20
            }
        ]
    },
    {
        "RefListID": 393,
        "refListName": "Medical_Isolation",
        "refListItems": [
            {
                "refListItemId": 4014,
                "enabled": true,
                "defaultString": "Airborne Precautions",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4015,
                "enabled": true,
                "defaultString": "Droplet Precautions",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4016,
                "enabled": true,
                "defaultString": "Contact Precautions",
                "longString": "",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 394,
        "refListName": "Anoka_MN_Counties",
        "refListItems": [
            {
                "refListItemId": 4017,
                "enabled": true,
                "defaultString": "Aitkin",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4018,
                "enabled": true,
                "defaultString": "Anoka",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4019,
                "enabled": true,
                "defaultString": "Becker",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4020,
                "enabled": true,
                "defaultString": "Beltrami",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4021,
                "enabled": true,
                "defaultString": "Benton",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4022,
                "enabled": true,
                "defaultString": "Bigstone",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4023,
                "enabled": true,
                "defaultString": "Blue Earth",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4024,
                "enabled": true,
                "defaultString": "Brown",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4025,
                "enabled": true,
                "defaultString": "Carlton",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4026,
                "enabled": true,
                "defaultString": "Carver",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4027,
                "enabled": true,
                "defaultString": "Cass",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4028,
                "enabled": true,
                "defaultString": "Chippewa",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4029,
                "enabled": true,
                "defaultString": "Chisago",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4030,
                "enabled": true,
                "defaultString": "Clay",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4031,
                "enabled": true,
                "defaultString": "Clearwater",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4032,
                "enabled": true,
                "defaultString": "Cook",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4033,
                "enabled": true,
                "defaultString": "Cottonwood",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4034,
                "enabled": true,
                "defaultString": "Crow Wing",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4035,
                "enabled": true,
                "defaultString": "Dakota",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4036,
                "enabled": true,
                "defaultString": "Dodge",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4037,
                "enabled": true,
                "defaultString": "Douglas",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 4038,
                "enabled": true,
                "defaultString": "Faribault",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 4039,
                "enabled": true,
                "defaultString": "Fillmore",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 4040,
                "enabled": true,
                "defaultString": "Freeborn",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 4041,
                "enabled": true,
                "defaultString": "Goodhue",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 4042,
                "enabled": true,
                "defaultString": "Grant",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 4043,
                "enabled": true,
                "defaultString": "Hennepin",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 4044,
                "enabled": true,
                "defaultString": "Houston",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 4045,
                "enabled": true,
                "defaultString": "Hubbard",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 4046,
                "enabled": true,
                "defaultString": "Isanti",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 4047,
                "enabled": true,
                "defaultString": "Itasca",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 4048,
                "enabled": true,
                "defaultString": "Jackson",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 4049,
                "enabled": true,
                "defaultString": "Kanabec",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 4050,
                "enabled": true,
                "defaultString": "Kandiyohi",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 4051,
                "enabled": true,
                "defaultString": "Kittson",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 4052,
                "enabled": true,
                "defaultString": "Koochinching",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 4053,
                "enabled": true,
                "defaultString": "Lac Qui Parle",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 4054,
                "enabled": true,
                "defaultString": "Lake",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 4055,
                "enabled": true,
                "defaultString": "Lake of the Woods",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 4056,
                "enabled": true,
                "defaultString": "Lesuer",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 4057,
                "enabled": true,
                "defaultString": "Lincoln",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 4058,
                "enabled": true,
                "defaultString": "Lyon",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 4059,
                "enabled": true,
                "defaultString": "Mahnomen",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 4060,
                "enabled": true,
                "defaultString": "Marshall",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 4061,
                "enabled": true,
                "defaultString": "Martin",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 4062,
                "enabled": true,
                "defaultString": "Mcleod",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 4063,
                "enabled": true,
                "defaultString": "Meeker",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 4064,
                "enabled": true,
                "defaultString": "Mille Lacs",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 4065,
                "enabled": true,
                "defaultString": "Morrison",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 4066,
                "enabled": true,
                "defaultString": "Mower",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 4067,
                "enabled": true,
                "defaultString": "Murray",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 4068,
                "enabled": true,
                "defaultString": "Nicollet",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 4069,
                "enabled": true,
                "defaultString": "Nobles",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 4070,
                "enabled": true,
                "defaultString": "Non-Minnesota Cty",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 4071,
                "enabled": true,
                "defaultString": "Norman",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 4072,
                "enabled": true,
                "defaultString": "Olmsted",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 4073,
                "enabled": true,
                "defaultString": "Ottertail",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 4074,
                "enabled": true,
                "defaultString": "Pennington",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 4075,
                "enabled": true,
                "defaultString": "Pine",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 4076,
                "enabled": true,
                "defaultString": "Pipestone",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 4077,
                "enabled": true,
                "defaultString": "Polk",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 4078,
                "enabled": true,
                "defaultString": "Pope",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 4079,
                "enabled": true,
                "defaultString": "Ramsey",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 4080,
                "enabled": true,
                "defaultString": "Red Lake",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 4081,
                "enabled": true,
                "defaultString": "Red Wood",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 4082,
                "enabled": true,
                "defaultString": "Renville",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 4083,
                "enabled": true,
                "defaultString": "Rice",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 4084,
                "enabled": true,
                "defaultString": "Rock",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 4085,
                "enabled": true,
                "defaultString": "Roseau",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 4086,
                "enabled": true,
                "defaultString": "Scott",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 4087,
                "enabled": true,
                "defaultString": "Sherburne",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 4088,
                "enabled": true,
                "defaultString": "Sibley",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 4089,
                "enabled": true,
                "defaultString": "St. Louis",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 4090,
                "enabled": true,
                "defaultString": "Stearns",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 4091,
                "enabled": true,
                "defaultString": "Steele",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 4092,
                "enabled": true,
                "defaultString": "Stevens",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 4093,
                "enabled": true,
                "defaultString": "Swift",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 4094,
                "enabled": true,
                "defaultString": "Todd",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 4095,
                "enabled": true,
                "defaultString": "Traverse",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 4096,
                "enabled": true,
                "defaultString": "Wabasha",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 4097,
                "enabled": true,
                "defaultString": "Wadena",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 4098,
                "enabled": true,
                "defaultString": "Waseca",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 4099,
                "enabled": true,
                "defaultString": "Washington",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 4100,
                "enabled": true,
                "defaultString": "Watonowan",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 4101,
                "enabled": true,
                "defaultString": "Wilkin",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 4102,
                "enabled": true,
                "defaultString": "Winona",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 4103,
                "enabled": true,
                "defaultString": "Wright",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 4104,
                "enabled": true,
                "defaultString": "Yellow Medicine",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 4105,
                "enabled": true,
                "defaultString": "Other State",
                "sequenceNumber": 89
            }
        ]
    },
    {
        "RefListID": 395,
        "refListName": "Anoka_Res_Country_Cd",
        "refListItems": [
            {
                "refListItemId": 4106,
                "enabled": true,
                "defaultString": "CA",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4107,
                "enabled": true,
                "defaultString": "US",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4108,
                "enabled": true,
                "defaultString": "MX",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 396,
        "refListName": "Marital_Status",
        "refListItems": [
            {
                "refListItemId": 4109,
                "enabled": true,
                "defaultString": "Divorced",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4110,
                "enabled": true,
                "defaultString": "Married",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4111,
                "enabled": true,
                "defaultString": "Single",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4112,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4113,
                "enabled": true,
                "defaultString": "Widowed",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 558,
        "refListName": "HowReceived",
        "refListItems": [
            {
                "refListItemId": 16095,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 559,
        "refListName": "UCRStatus",
        "refListItems": [
            {
                "refListItemId": 16096,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 399,
        "refListName": "Anoka_S3_Custody_Risk",
        "refListItems": [
            {
                "refListItemId": 4136,
                "enabled": true,
                "defaultString": "Maximum",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4137,
                "enabled": true,
                "defaultString": "Medium",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4138,
                "enabled": true,
                "defaultString": "Minimum",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 560,
        "refListName": "UCRPropertyType",
        "refListItems": [
            {
                "refListItemId": 16097,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 401,
        "refListName": "Hold_Location_Cd",
        "refListItems": [
            {
                "refListItemId": 4145,
                "enabled": true,
                "defaultString": "Community Work Release",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4146,
                "enabled": true,
                "defaultString": "Outside Secure Perimeter",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4147,
                "enabled": true,
                "defaultString": "Secure Perimeter / Secure Exit",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4148,
                "enabled": true,
                "defaultString": "Secure Perimeter / Uncontrolled Exit",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4149,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 402,
        "refListName": "Image_Type_Contexts",
        "refListItems": [
            {
                "refListItemId": 4150,
                "enabled": true,
                "defaultString": "Accident Reports",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4151,
                "enabled": true,
                "defaultString": "Bio",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4152,
                "enabled": true,
                "defaultString": "Bookings",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4153,
                "enabled": true,
                "defaultString": "Case Management",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4154,
                "enabled": true,
                "defaultString": "Distinguishing Characteristics",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4155,
                "enabled": true,
                "defaultString": "Medical Visits",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4156,
                "enabled": true,
                "defaultString": "Incident Reports",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4157,
                "enabled": true,
                "defaultString": "Property Photos",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 24959,
                "enabled": true,
                "defaultString": "Jail Incident Photos",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 561,
        "refListName": "UCRRecoveredPropertyCode",
        "refListItems": [
            {
                "refListItemId": 16098,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 564,
        "refListName": "UCRChargeCode",
        "refListItems": [
            {
                "refListItemId": 16101,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 565,
        "refListName": "UCRArrestDisposition",
        "refListItems": [
            {
                "refListItemId": 16102,
                "enabled": true,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 576,
        "refListName": "TownshipOfResidence",
        "refListItems": "NULL"
    },
    {
        "RefListID": 577,
        "refListName": "DisplayFieldName",
        "refListItems": [
            {
                "refListItemId": 16204,
                "enabled": true,
                "defaultString": "defaultString",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16205,
                "enabled": true,
                "defaultString": "longString",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 406,
        "refListName": "NewOptions_Bookings",
        "refListItems": [
            {
                "refListItemId": 4193,
                "enabled": true,
                "defaultString": "Inmate On Count",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4194,
                "enabled": true,
                "defaultString": "Scheduled Booking",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 635,
        "refListName": "Inmate_Property_Storage_Location_1",
        "refListItems": [
            {
                "refListItemId": 19311,
                "enabled": false,
                "defaultString": "In Transit",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 19312,
                "enabled": true,
                "defaultString": "Locker",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 19313,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 19314,
                "enabled": true,
                "defaultString": "M Locker",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 19315,
                "enabled": true,
                "defaultString": "Bin",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 19316,
                "enabled": true,
                "defaultString": "Secured",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 19317,
                "enabled": true,
                "defaultString": "W Locker",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 19318,
                "enabled": true,
                "defaultString": "Boarding",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 636,
        "refListName": "Inmate_Property_Storage_Location_2",
        "refListItems": [
            {
                "refListItemId": 19677,
                "enabled": true,
                "defaultString": "0359",
                "sequenceNumber": 359
            },
            {
                "refListItemId": 19413,
                "enabled": true,
                "defaultString": "0095",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 19568,
                "enabled": true,
                "defaultString": "0250",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 19569,
                "enabled": true,
                "defaultString": "0251",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 19570,
                "enabled": true,
                "defaultString": "0252",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 19572,
                "enabled": true,
                "defaultString": "0254",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 19573,
                "enabled": true,
                "defaultString": "0255",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 19574,
                "enabled": true,
                "defaultString": "0256",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 19575,
                "enabled": true,
                "defaultString": "0257",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 19576,
                "enabled": true,
                "defaultString": "0258",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 19577,
                "enabled": true,
                "defaultString": "0259",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 19578,
                "enabled": true,
                "defaultString": "0260",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 19579,
                "enabled": true,
                "defaultString": "0261",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 19580,
                "enabled": true,
                "defaultString": "0262",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 19581,
                "enabled": true,
                "defaultString": "0263",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 19582,
                "enabled": true,
                "defaultString": "0264",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 19583,
                "enabled": true,
                "defaultString": "0265",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 19584,
                "enabled": true,
                "defaultString": "0266",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 19585,
                "enabled": true,
                "defaultString": "0267",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 19587,
                "enabled": true,
                "defaultString": "0269",
                "sequenceNumber": 269
            },
            {
                "refListItemId": 19588,
                "enabled": true,
                "defaultString": "0270",
                "sequenceNumber": 270
            },
            {
                "refListItemId": 19589,
                "enabled": true,
                "defaultString": "0271",
                "sequenceNumber": 271
            },
            {
                "refListItemId": 19590,
                "enabled": true,
                "defaultString": "0272",
                "sequenceNumber": 272
            },
            {
                "refListItemId": 19591,
                "enabled": true,
                "defaultString": "0273",
                "sequenceNumber": 273
            },
            {
                "refListItemId": 19592,
                "enabled": true,
                "defaultString": "0274",
                "sequenceNumber": 274
            },
            {
                "refListItemId": 19593,
                "enabled": true,
                "defaultString": "0275",
                "sequenceNumber": 275
            },
            {
                "refListItemId": 19594,
                "enabled": true,
                "defaultString": "0276",
                "sequenceNumber": 276
            },
            {
                "refListItemId": 19595,
                "enabled": true,
                "defaultString": "0277",
                "sequenceNumber": 277
            },
            {
                "refListItemId": 19596,
                "enabled": true,
                "defaultString": "0278",
                "sequenceNumber": 278
            },
            {
                "refListItemId": 19597,
                "enabled": true,
                "defaultString": "0279",
                "sequenceNumber": 279
            },
            {
                "refListItemId": 19598,
                "enabled": true,
                "defaultString": "0280",
                "sequenceNumber": 280
            },
            {
                "refListItemId": 19599,
                "enabled": true,
                "defaultString": "0281",
                "sequenceNumber": 281
            },
            {
                "refListItemId": 19600,
                "enabled": true,
                "defaultString": "0282",
                "sequenceNumber": 282
            },
            {
                "refListItemId": 19601,
                "enabled": true,
                "defaultString": "0283",
                "sequenceNumber": 283
            },
            {
                "refListItemId": 19602,
                "enabled": true,
                "defaultString": "0284",
                "sequenceNumber": 284
            },
            {
                "refListItemId": 19603,
                "enabled": true,
                "defaultString": "0285",
                "sequenceNumber": 285
            },
            {
                "refListItemId": 19604,
                "enabled": true,
                "defaultString": "0286",
                "sequenceNumber": 286
            },
            {
                "refListItemId": 19605,
                "enabled": true,
                "defaultString": "0287",
                "sequenceNumber": 287
            },
            {
                "refListItemId": 19606,
                "enabled": true,
                "defaultString": "0288",
                "sequenceNumber": 288
            },
            {
                "refListItemId": 19607,
                "enabled": true,
                "defaultString": "0289",
                "sequenceNumber": 289
            },
            {
                "refListItemId": 19608,
                "enabled": true,
                "defaultString": "0290",
                "sequenceNumber": 290
            },
            {
                "refListItemId": 19609,
                "enabled": true,
                "defaultString": "0291",
                "sequenceNumber": 291
            },
            {
                "refListItemId": 19610,
                "enabled": true,
                "defaultString": "0292",
                "sequenceNumber": 292
            },
            {
                "refListItemId": 19611,
                "enabled": true,
                "defaultString": "0293",
                "sequenceNumber": 293
            },
            {
                "refListItemId": 19612,
                "enabled": true,
                "defaultString": "0294",
                "sequenceNumber": 294
            },
            {
                "refListItemId": 19613,
                "enabled": true,
                "defaultString": "0295",
                "sequenceNumber": 295
            },
            {
                "refListItemId": 19614,
                "enabled": true,
                "defaultString": "0296",
                "sequenceNumber": 296
            },
            {
                "refListItemId": 19615,
                "enabled": true,
                "defaultString": "0297",
                "sequenceNumber": 297
            },
            {
                "refListItemId": 19616,
                "enabled": true,
                "defaultString": "0298",
                "sequenceNumber": 298
            },
            {
                "refListItemId": 19617,
                "enabled": true,
                "defaultString": "0299",
                "sequenceNumber": 299
            },
            {
                "refListItemId": 19618,
                "enabled": true,
                "defaultString": "0300",
                "sequenceNumber": 300
            },
            {
                "refListItemId": 19619,
                "enabled": true,
                "defaultString": "0301",
                "sequenceNumber": 301
            },
            {
                "refListItemId": 19620,
                "enabled": true,
                "defaultString": "0302",
                "sequenceNumber": 302
            },
            {
                "refListItemId": 19621,
                "enabled": true,
                "defaultString": "0303",
                "sequenceNumber": 303
            },
            {
                "refListItemId": 19622,
                "enabled": true,
                "defaultString": "0304",
                "sequenceNumber": 304
            },
            {
                "refListItemId": 19623,
                "enabled": true,
                "defaultString": "0305",
                "sequenceNumber": 305
            },
            {
                "refListItemId": 19624,
                "enabled": true,
                "defaultString": "0306",
                "sequenceNumber": 306
            },
            {
                "refListItemId": 19625,
                "enabled": true,
                "defaultString": "0307",
                "sequenceNumber": 307
            },
            {
                "refListItemId": 19626,
                "enabled": true,
                "defaultString": "0308",
                "sequenceNumber": 308
            },
            {
                "refListItemId": 19627,
                "enabled": true,
                "defaultString": "0309",
                "sequenceNumber": 309
            },
            {
                "refListItemId": 19628,
                "enabled": true,
                "defaultString": "0310",
                "sequenceNumber": 310
            },
            {
                "refListItemId": 19629,
                "enabled": true,
                "defaultString": "0311",
                "sequenceNumber": 311
            },
            {
                "refListItemId": 19630,
                "enabled": true,
                "defaultString": "0312",
                "sequenceNumber": 312
            },
            {
                "refListItemId": 19631,
                "enabled": true,
                "defaultString": "0313",
                "sequenceNumber": 313
            },
            {
                "refListItemId": 19632,
                "enabled": true,
                "defaultString": "0314",
                "sequenceNumber": 314
            },
            {
                "refListItemId": 19633,
                "enabled": true,
                "defaultString": "0315",
                "sequenceNumber": 315
            },
            {
                "refListItemId": 19634,
                "enabled": true,
                "defaultString": "0316",
                "sequenceNumber": 316
            },
            {
                "refListItemId": 19635,
                "enabled": true,
                "defaultString": "0317",
                "sequenceNumber": 317
            },
            {
                "refListItemId": 19636,
                "enabled": true,
                "defaultString": "0318",
                "sequenceNumber": 318
            },
            {
                "refListItemId": 19637,
                "enabled": true,
                "defaultString": "0319",
                "sequenceNumber": 319
            },
            {
                "refListItemId": 19638,
                "enabled": true,
                "defaultString": "0320",
                "sequenceNumber": 320
            },
            {
                "refListItemId": 19639,
                "enabled": true,
                "defaultString": "0321",
                "sequenceNumber": 321
            },
            {
                "refListItemId": 19640,
                "enabled": true,
                "defaultString": "0322",
                "sequenceNumber": 322
            },
            {
                "refListItemId": 19641,
                "enabled": true,
                "defaultString": "0323",
                "sequenceNumber": 323
            },
            {
                "refListItemId": 19642,
                "enabled": true,
                "defaultString": "0324",
                "sequenceNumber": 324
            },
            {
                "refListItemId": 19643,
                "enabled": true,
                "defaultString": "0325",
                "sequenceNumber": 325
            },
            {
                "refListItemId": 19644,
                "enabled": true,
                "defaultString": "0326",
                "sequenceNumber": 326
            },
            {
                "refListItemId": 19645,
                "enabled": true,
                "defaultString": "0327",
                "sequenceNumber": 327
            },
            {
                "refListItemId": 19646,
                "enabled": true,
                "defaultString": "0328",
                "sequenceNumber": 328
            },
            {
                "refListItemId": 19647,
                "enabled": true,
                "defaultString": "0329",
                "sequenceNumber": 329
            },
            {
                "refListItemId": 19648,
                "enabled": true,
                "defaultString": "0330",
                "sequenceNumber": 330
            },
            {
                "refListItemId": 19649,
                "enabled": true,
                "defaultString": "0331",
                "sequenceNumber": 331
            },
            {
                "refListItemId": 19650,
                "enabled": true,
                "defaultString": "0332",
                "sequenceNumber": 332
            },
            {
                "refListItemId": 19651,
                "enabled": true,
                "defaultString": "0333",
                "sequenceNumber": 333
            },
            {
                "refListItemId": 19652,
                "enabled": true,
                "defaultString": "0334",
                "sequenceNumber": 334
            },
            {
                "refListItemId": 19653,
                "enabled": true,
                "defaultString": "0335",
                "sequenceNumber": 335
            },
            {
                "refListItemId": 19654,
                "enabled": true,
                "defaultString": "0336",
                "sequenceNumber": 336
            },
            {
                "refListItemId": 19655,
                "enabled": true,
                "defaultString": "0337",
                "sequenceNumber": 337
            },
            {
                "refListItemId": 19656,
                "enabled": true,
                "defaultString": "0338",
                "sequenceNumber": 338
            },
            {
                "refListItemId": 19657,
                "enabled": true,
                "defaultString": "0339",
                "sequenceNumber": 339
            },
            {
                "refListItemId": 19658,
                "enabled": true,
                "defaultString": "0340",
                "sequenceNumber": 340
            },
            {
                "refListItemId": 19659,
                "enabled": true,
                "defaultString": "0341",
                "sequenceNumber": 341
            },
            {
                "refListItemId": 19660,
                "enabled": true,
                "defaultString": "0342",
                "sequenceNumber": 342
            },
            {
                "refListItemId": 19661,
                "enabled": true,
                "defaultString": "0343",
                "sequenceNumber": 343
            },
            {
                "refListItemId": 19662,
                "enabled": true,
                "defaultString": "0344",
                "sequenceNumber": 344
            },
            {
                "refListItemId": 19663,
                "enabled": true,
                "defaultString": "0345",
                "sequenceNumber": 345
            },
            {
                "refListItemId": 19664,
                "enabled": true,
                "defaultString": "0346",
                "sequenceNumber": 346
            },
            {
                "refListItemId": 19665,
                "enabled": true,
                "defaultString": "0347",
                "sequenceNumber": 347
            },
            {
                "refListItemId": 19666,
                "enabled": true,
                "defaultString": "0348",
                "sequenceNumber": 348
            },
            {
                "refListItemId": 19667,
                "enabled": true,
                "defaultString": "0349",
                "sequenceNumber": 349
            },
            {
                "refListItemId": 19668,
                "enabled": true,
                "defaultString": "0350",
                "sequenceNumber": 350
            },
            {
                "refListItemId": 19669,
                "enabled": true,
                "defaultString": "0351",
                "sequenceNumber": 351
            },
            {
                "refListItemId": 19670,
                "enabled": true,
                "defaultString": "0352",
                "sequenceNumber": 352
            },
            {
                "refListItemId": 19671,
                "enabled": true,
                "defaultString": "0353",
                "sequenceNumber": 353
            },
            {
                "refListItemId": 19672,
                "enabled": true,
                "defaultString": "0354",
                "sequenceNumber": 354
            },
            {
                "refListItemId": 19673,
                "enabled": true,
                "defaultString": "0355",
                "sequenceNumber": 355
            },
            {
                "refListItemId": 19674,
                "enabled": true,
                "defaultString": "0356",
                "sequenceNumber": 356
            },
            {
                "refListItemId": 19675,
                "enabled": true,
                "defaultString": "0357",
                "sequenceNumber": 357
            },
            {
                "refListItemId": 19676,
                "enabled": true,
                "defaultString": "0358",
                "sequenceNumber": 358
            },
            {
                "refListItemId": 19586,
                "enabled": true,
                "defaultString": "0268",
                "sequenceNumber": 268
            },
            {
                "refListItemId": 19678,
                "enabled": true,
                "defaultString": "0360",
                "sequenceNumber": 360
            },
            {
                "refListItemId": 19679,
                "enabled": true,
                "defaultString": "0361",
                "sequenceNumber": 361
            },
            {
                "refListItemId": 19680,
                "enabled": true,
                "defaultString": "0362",
                "sequenceNumber": 362
            },
            {
                "refListItemId": 19681,
                "enabled": true,
                "defaultString": "0363",
                "sequenceNumber": 363
            },
            {
                "refListItemId": 19682,
                "enabled": true,
                "defaultString": "0364",
                "sequenceNumber": 364
            },
            {
                "refListItemId": 19683,
                "enabled": true,
                "defaultString": "0365",
                "sequenceNumber": 365
            },
            {
                "refListItemId": 19684,
                "enabled": true,
                "defaultString": "0366",
                "sequenceNumber": 366
            },
            {
                "refListItemId": 19685,
                "enabled": true,
                "defaultString": "C101",
                "sequenceNumber": 367
            },
            {
                "refListItemId": 19686,
                "enabled": true,
                "defaultString": "NPRO",
                "sequenceNumber": 368
            },
            {
                "refListItemId": 19687,
                "enabled": true,
                "defaultString": "W001",
                "sequenceNumber": 369
            },
            {
                "refListItemId": 19688,
                "enabled": true,
                "defaultString": "W002",
                "sequenceNumber": 370
            },
            {
                "refListItemId": 19689,
                "enabled": true,
                "defaultString": "W003",
                "sequenceNumber": 371
            },
            {
                "refListItemId": 19690,
                "enabled": true,
                "defaultString": "W004",
                "sequenceNumber": 372
            },
            {
                "refListItemId": 19691,
                "enabled": true,
                "defaultString": "W005",
                "sequenceNumber": 373
            },
            {
                "refListItemId": 19692,
                "enabled": true,
                "defaultString": "W006",
                "sequenceNumber": 374
            },
            {
                "refListItemId": 19693,
                "enabled": true,
                "defaultString": "W007",
                "sequenceNumber": 375
            },
            {
                "refListItemId": 19694,
                "enabled": true,
                "defaultString": "W008",
                "sequenceNumber": 376
            },
            {
                "refListItemId": 19695,
                "enabled": true,
                "defaultString": "W009",
                "sequenceNumber": 377
            },
            {
                "refListItemId": 19696,
                "enabled": true,
                "defaultString": "W010",
                "sequenceNumber": 378
            },
            {
                "refListItemId": 19697,
                "enabled": true,
                "defaultString": "W011",
                "sequenceNumber": 379
            },
            {
                "refListItemId": 19698,
                "enabled": true,
                "defaultString": "W012",
                "sequenceNumber": 380
            },
            {
                "refListItemId": 19699,
                "enabled": true,
                "defaultString": "W013",
                "sequenceNumber": 381
            },
            {
                "refListItemId": 19700,
                "enabled": true,
                "defaultString": "W014",
                "sequenceNumber": 382
            },
            {
                "refListItemId": 19701,
                "enabled": true,
                "defaultString": "W015",
                "sequenceNumber": 383
            },
            {
                "refListItemId": 19702,
                "enabled": true,
                "defaultString": "W016",
                "sequenceNumber": 384
            },
            {
                "refListItemId": 19703,
                "enabled": true,
                "defaultString": "W017",
                "sequenceNumber": 385
            },
            {
                "refListItemId": 19704,
                "enabled": true,
                "defaultString": "W018",
                "sequenceNumber": 386
            },
            {
                "refListItemId": 19705,
                "enabled": true,
                "defaultString": "W019",
                "sequenceNumber": 387
            },
            {
                "refListItemId": 19706,
                "enabled": true,
                "defaultString": "W020",
                "sequenceNumber": 388
            },
            {
                "refListItemId": 19707,
                "enabled": true,
                "defaultString": "W021",
                "sequenceNumber": 389
            },
            {
                "refListItemId": 19708,
                "enabled": true,
                "defaultString": "W022",
                "sequenceNumber": 390
            },
            {
                "refListItemId": 19709,
                "enabled": true,
                "defaultString": "W023",
                "sequenceNumber": 391
            },
            {
                "refListItemId": 19710,
                "enabled": true,
                "defaultString": "W024",
                "sequenceNumber": 392
            },
            {
                "refListItemId": 19711,
                "enabled": true,
                "defaultString": "W025",
                "sequenceNumber": 393
            },
            {
                "refListItemId": 19712,
                "enabled": true,
                "defaultString": "W026",
                "sequenceNumber": 394
            },
            {
                "refListItemId": 19713,
                "enabled": true,
                "defaultString": "W027",
                "sequenceNumber": 395
            },
            {
                "refListItemId": 19714,
                "enabled": true,
                "defaultString": "W028",
                "sequenceNumber": 396
            },
            {
                "refListItemId": 19715,
                "enabled": true,
                "defaultString": "W029",
                "sequenceNumber": 397
            },
            {
                "refListItemId": 19716,
                "enabled": true,
                "defaultString": "W030",
                "sequenceNumber": 398
            },
            {
                "refListItemId": 19717,
                "enabled": true,
                "defaultString": "W031",
                "sequenceNumber": 399
            },
            {
                "refListItemId": 19718,
                "enabled": true,
                "defaultString": "W032",
                "sequenceNumber": 400
            },
            {
                "refListItemId": 19719,
                "enabled": true,
                "defaultString": "W033",
                "sequenceNumber": 401
            },
            {
                "refListItemId": 19720,
                "enabled": true,
                "defaultString": "W034",
                "sequenceNumber": 402
            },
            {
                "refListItemId": 19721,
                "enabled": true,
                "defaultString": "W035",
                "sequenceNumber": 403
            },
            {
                "refListItemId": 19722,
                "enabled": true,
                "defaultString": "W036",
                "sequenceNumber": 404
            },
            {
                "refListItemId": 19723,
                "enabled": true,
                "defaultString": "W037",
                "sequenceNumber": 405
            },
            {
                "refListItemId": 19724,
                "enabled": true,
                "defaultString": "W038",
                "sequenceNumber": 406
            },
            {
                "refListItemId": 19725,
                "enabled": true,
                "defaultString": "W039",
                "sequenceNumber": 407
            },
            {
                "refListItemId": 19726,
                "enabled": true,
                "defaultString": "W040",
                "sequenceNumber": 408
            },
            {
                "refListItemId": 19727,
                "enabled": true,
                "defaultString": "W041",
                "sequenceNumber": 409
            },
            {
                "refListItemId": 19728,
                "enabled": true,
                "defaultString": "W042",
                "sequenceNumber": 410
            },
            {
                "refListItemId": 19729,
                "enabled": true,
                "defaultString": "W043",
                "sequenceNumber": 411
            },
            {
                "refListItemId": 19730,
                "enabled": true,
                "defaultString": "W044",
                "sequenceNumber": 412
            },
            {
                "refListItemId": 19731,
                "enabled": true,
                "defaultString": "W045",
                "sequenceNumber": 413
            },
            {
                "refListItemId": 19732,
                "enabled": true,
                "defaultString": "W046",
                "sequenceNumber": 414
            },
            {
                "refListItemId": 19733,
                "enabled": true,
                "defaultString": "W047",
                "sequenceNumber": 415
            },
            {
                "refListItemId": 19734,
                "enabled": true,
                "defaultString": "W048",
                "sequenceNumber": 416
            },
            {
                "refListItemId": 19735,
                "enabled": true,
                "defaultString": "W049",
                "sequenceNumber": 417
            },
            {
                "refListItemId": 19736,
                "enabled": true,
                "defaultString": "W050",
                "sequenceNumber": 418
            },
            {
                "refListItemId": 19737,
                "enabled": true,
                "defaultString": "W051",
                "sequenceNumber": 419
            },
            {
                "refListItemId": 19738,
                "enabled": true,
                "defaultString": "W052",
                "sequenceNumber": 420
            },
            {
                "refListItemId": 19739,
                "enabled": true,
                "defaultString": "W053",
                "sequenceNumber": 421
            },
            {
                "refListItemId": 19740,
                "enabled": true,
                "defaultString": "W054",
                "sequenceNumber": 422
            },
            {
                "refListItemId": 19741,
                "enabled": true,
                "defaultString": "W055",
                "sequenceNumber": 423
            },
            {
                "refListItemId": 19742,
                "enabled": true,
                "defaultString": "W056",
                "sequenceNumber": 424
            },
            {
                "refListItemId": 19743,
                "enabled": true,
                "defaultString": "W057",
                "sequenceNumber": 425
            },
            {
                "refListItemId": 19744,
                "enabled": true,
                "defaultString": "W058",
                "sequenceNumber": 426
            },
            {
                "refListItemId": 19745,
                "enabled": true,
                "defaultString": "W059",
                "sequenceNumber": 427
            },
            {
                "refListItemId": 19746,
                "enabled": true,
                "defaultString": "W060",
                "sequenceNumber": 428
            },
            {
                "refListItemId": 19747,
                "enabled": true,
                "defaultString": "W061",
                "sequenceNumber": 429
            },
            {
                "refListItemId": 19748,
                "enabled": true,
                "defaultString": "W062",
                "sequenceNumber": 430
            },
            {
                "refListItemId": 19749,
                "enabled": true,
                "defaultString": "W063",
                "sequenceNumber": 431
            },
            {
                "refListItemId": 19750,
                "enabled": true,
                "defaultString": "W064",
                "sequenceNumber": 432
            },
            {
                "refListItemId": 19751,
                "enabled": true,
                "defaultString": "W065",
                "sequenceNumber": 433
            },
            {
                "refListItemId": 19752,
                "enabled": true,
                "defaultString": "W066",
                "sequenceNumber": 434
            },
            {
                "refListItemId": 19753,
                "enabled": true,
                "defaultString": "W067",
                "sequenceNumber": 435
            },
            {
                "refListItemId": 19754,
                "enabled": true,
                "defaultString": "W068",
                "sequenceNumber": 436
            },
            {
                "refListItemId": 19755,
                "enabled": true,
                "defaultString": "W069",
                "sequenceNumber": 437
            },
            {
                "refListItemId": 19756,
                "enabled": true,
                "defaultString": "W070",
                "sequenceNumber": 438
            },
            {
                "refListItemId": 19757,
                "enabled": true,
                "defaultString": "W071",
                "sequenceNumber": 439
            },
            {
                "refListItemId": 19758,
                "enabled": true,
                "defaultString": "W072",
                "sequenceNumber": 440
            },
            {
                "refListItemId": 19759,
                "enabled": true,
                "defaultString": "W073",
                "sequenceNumber": 441
            },
            {
                "refListItemId": 19760,
                "enabled": true,
                "defaultString": "W074",
                "sequenceNumber": 442
            },
            {
                "refListItemId": 19761,
                "enabled": true,
                "defaultString": "W075",
                "sequenceNumber": 443
            },
            {
                "refListItemId": 19762,
                "enabled": true,
                "defaultString": "W076",
                "sequenceNumber": 444
            },
            {
                "refListItemId": 19763,
                "enabled": true,
                "defaultString": "W077",
                "sequenceNumber": 445
            },
            {
                "refListItemId": 19764,
                "enabled": true,
                "defaultString": "W078",
                "sequenceNumber": 446
            },
            {
                "refListItemId": 19765,
                "enabled": true,
                "defaultString": "W079",
                "sequenceNumber": 447
            },
            {
                "refListItemId": 19766,
                "enabled": true,
                "defaultString": "W080",
                "sequenceNumber": 448
            },
            {
                "refListItemId": 19767,
                "enabled": true,
                "defaultString": "W081",
                "sequenceNumber": 449
            },
            {
                "refListItemId": 19768,
                "enabled": true,
                "defaultString": "W082",
                "sequenceNumber": 450
            },
            {
                "refListItemId": 19769,
                "enabled": true,
                "defaultString": "W083",
                "sequenceNumber": 451
            },
            {
                "refListItemId": 19770,
                "enabled": true,
                "defaultString": "W084",
                "sequenceNumber": 452
            },
            {
                "refListItemId": 19771,
                "enabled": true,
                "defaultString": "W085",
                "sequenceNumber": 453
            },
            {
                "refListItemId": 19772,
                "enabled": true,
                "defaultString": "W086",
                "sequenceNumber": 454
            },
            {
                "refListItemId": 19773,
                "enabled": true,
                "defaultString": "W087",
                "sequenceNumber": 455
            },
            {
                "refListItemId": 19774,
                "enabled": true,
                "defaultString": "W088",
                "sequenceNumber": 456
            },
            {
                "refListItemId": 19775,
                "enabled": true,
                "defaultString": "W089",
                "sequenceNumber": 457
            },
            {
                "refListItemId": 19776,
                "enabled": true,
                "defaultString": "W090",
                "sequenceNumber": 458
            },
            {
                "refListItemId": 19777,
                "enabled": true,
                "defaultString": "W091",
                "sequenceNumber": 459
            },
            {
                "refListItemId": 19778,
                "enabled": true,
                "defaultString": "W092",
                "sequenceNumber": 460
            },
            {
                "refListItemId": 19779,
                "enabled": true,
                "defaultString": "W093",
                "sequenceNumber": 461
            },
            {
                "refListItemId": 19780,
                "enabled": true,
                "defaultString": "W094",
                "sequenceNumber": 462
            },
            {
                "refListItemId": 19781,
                "enabled": true,
                "defaultString": "W095",
                "sequenceNumber": 463
            },
            {
                "refListItemId": 19782,
                "enabled": true,
                "defaultString": "W096",
                "sequenceNumber": 464
            },
            {
                "refListItemId": 19783,
                "enabled": true,
                "defaultString": "W097",
                "sequenceNumber": 465
            },
            {
                "refListItemId": 19784,
                "enabled": true,
                "defaultString": "W098",
                "sequenceNumber": 466
            },
            {
                "refListItemId": 19785,
                "enabled": true,
                "defaultString": "W099",
                "sequenceNumber": 467
            },
            {
                "refListItemId": 19786,
                "enabled": true,
                "defaultString": "W100",
                "sequenceNumber": 468
            },
            {
                "refListItemId": 19787,
                "enabled": true,
                "defaultString": "W101",
                "sequenceNumber": 469
            },
            {
                "refListItemId": 19788,
                "enabled": true,
                "defaultString": "W102",
                "sequenceNumber": 470
            },
            {
                "refListItemId": 19789,
                "enabled": true,
                "defaultString": "W103",
                "sequenceNumber": 471
            },
            {
                "refListItemId": 19790,
                "enabled": true,
                "defaultString": "W104",
                "sequenceNumber": 472
            },
            {
                "refListItemId": 19791,
                "enabled": true,
                "defaultString": "W105",
                "sequenceNumber": 473
            },
            {
                "refListItemId": 19792,
                "enabled": true,
                "defaultString": "W106",
                "sequenceNumber": 474
            },
            {
                "refListItemId": 19793,
                "enabled": true,
                "defaultString": "W107",
                "sequenceNumber": 475
            },
            {
                "refListItemId": 19794,
                "enabled": true,
                "defaultString": "W108",
                "sequenceNumber": 476
            },
            {
                "refListItemId": 19795,
                "enabled": true,
                "defaultString": "W109",
                "sequenceNumber": 477
            },
            {
                "refListItemId": 19796,
                "enabled": true,
                "defaultString": "W110",
                "sequenceNumber": 478
            },
            {
                "refListItemId": 19797,
                "enabled": true,
                "defaultString": "W111",
                "sequenceNumber": 479
            },
            {
                "refListItemId": 19798,
                "enabled": true,
                "defaultString": "W112",
                "sequenceNumber": 480
            },
            {
                "refListItemId": 19799,
                "enabled": true,
                "defaultString": "W113",
                "sequenceNumber": 481
            },
            {
                "refListItemId": 19800,
                "enabled": true,
                "defaultString": "W114",
                "sequenceNumber": 482
            },
            {
                "refListItemId": 19801,
                "enabled": true,
                "defaultString": "W115",
                "sequenceNumber": 483
            },
            {
                "refListItemId": 19802,
                "enabled": true,
                "defaultString": "W116",
                "sequenceNumber": 484
            },
            {
                "refListItemId": 19803,
                "enabled": true,
                "defaultString": "W117",
                "sequenceNumber": 485
            },
            {
                "refListItemId": 19804,
                "enabled": true,
                "defaultString": "W118",
                "sequenceNumber": 486
            },
            {
                "refListItemId": 19805,
                "enabled": true,
                "defaultString": "W119",
                "sequenceNumber": 487
            },
            {
                "refListItemId": 19806,
                "enabled": true,
                "defaultString": "W120",
                "sequenceNumber": 488
            },
            {
                "refListItemId": 19807,
                "enabled": true,
                "defaultString": "W121",
                "sequenceNumber": 489
            },
            {
                "refListItemId": 19808,
                "enabled": true,
                "defaultString": "W122",
                "sequenceNumber": 490
            },
            {
                "refListItemId": 19809,
                "enabled": true,
                "defaultString": "W123",
                "sequenceNumber": 491
            },
            {
                "refListItemId": 19810,
                "enabled": true,
                "defaultString": "W124",
                "sequenceNumber": 492
            },
            {
                "refListItemId": 19811,
                "enabled": true,
                "defaultString": "W125",
                "sequenceNumber": 493
            },
            {
                "refListItemId": 19812,
                "enabled": true,
                "defaultString": "W126",
                "sequenceNumber": 494
            },
            {
                "refListItemId": 19813,
                "enabled": true,
                "defaultString": "W127",
                "sequenceNumber": 495
            },
            {
                "refListItemId": 19814,
                "enabled": true,
                "defaultString": "W128",
                "sequenceNumber": 496
            },
            {
                "refListItemId": 19815,
                "enabled": true,
                "defaultString": "W129",
                "sequenceNumber": 497
            },
            {
                "refListItemId": 19816,
                "enabled": true,
                "defaultString": "W130",
                "sequenceNumber": 498
            },
            {
                "refListItemId": 19817,
                "enabled": true,
                "defaultString": "W131",
                "sequenceNumber": 499
            },
            {
                "refListItemId": 19818,
                "enabled": true,
                "defaultString": "W132",
                "sequenceNumber": 500
            },
            {
                "refListItemId": 19819,
                "enabled": true,
                "defaultString": "W133",
                "sequenceNumber": 501
            },
            {
                "refListItemId": 19820,
                "enabled": true,
                "defaultString": "W134",
                "sequenceNumber": 502
            },
            {
                "refListItemId": 19821,
                "enabled": true,
                "defaultString": "W135",
                "sequenceNumber": 503
            },
            {
                "refListItemId": 19822,
                "enabled": true,
                "defaultString": "W136",
                "sequenceNumber": 504
            },
            {
                "refListItemId": 19823,
                "enabled": true,
                "defaultString": "W137",
                "sequenceNumber": 505
            },
            {
                "refListItemId": 19824,
                "enabled": true,
                "defaultString": "W138",
                "sequenceNumber": 506
            },
            {
                "refListItemId": 19825,
                "enabled": true,
                "defaultString": "W139",
                "sequenceNumber": 507
            },
            {
                "refListItemId": 19826,
                "enabled": true,
                "defaultString": "W140",
                "sequenceNumber": 508
            },
            {
                "refListItemId": 19827,
                "enabled": true,
                "defaultString": "W141",
                "sequenceNumber": 509
            },
            {
                "refListItemId": 19828,
                "enabled": true,
                "defaultString": "W142",
                "sequenceNumber": 510
            },
            {
                "refListItemId": 19829,
                "enabled": true,
                "defaultString": "W143",
                "sequenceNumber": 511
            },
            {
                "refListItemId": 19830,
                "enabled": true,
                "defaultString": "W144",
                "sequenceNumber": 512
            },
            {
                "refListItemId": 19831,
                "enabled": true,
                "defaultString": "W145",
                "sequenceNumber": 513
            },
            {
                "refListItemId": 19832,
                "enabled": true,
                "defaultString": "W146",
                "sequenceNumber": 514
            },
            {
                "refListItemId": 19833,
                "enabled": true,
                "defaultString": "W147",
                "sequenceNumber": 515
            },
            {
                "refListItemId": 19834,
                "enabled": true,
                "defaultString": "W148",
                "sequenceNumber": 516
            },
            {
                "refListItemId": 19835,
                "enabled": true,
                "defaultString": "W149",
                "sequenceNumber": 517
            },
            {
                "refListItemId": 19836,
                "enabled": true,
                "defaultString": "W150",
                "sequenceNumber": 518
            },
            {
                "refListItemId": 19837,
                "enabled": true,
                "defaultString": "W151",
                "sequenceNumber": 519
            },
            {
                "refListItemId": 19838,
                "enabled": true,
                "defaultString": "W152",
                "sequenceNumber": 520
            },
            {
                "refListItemId": 19839,
                "enabled": true,
                "defaultString": "W153",
                "sequenceNumber": 521
            },
            {
                "refListItemId": 19840,
                "enabled": true,
                "defaultString": "W154",
                "sequenceNumber": 522
            },
            {
                "refListItemId": 19841,
                "enabled": true,
                "defaultString": "W155",
                "sequenceNumber": 523
            },
            {
                "refListItemId": 19842,
                "enabled": true,
                "defaultString": "W156",
                "sequenceNumber": 524
            },
            {
                "refListItemId": 19843,
                "enabled": true,
                "defaultString": "W157",
                "sequenceNumber": 525
            },
            {
                "refListItemId": 19844,
                "enabled": true,
                "defaultString": "W158",
                "sequenceNumber": 526
            },
            {
                "refListItemId": 19845,
                "enabled": true,
                "defaultString": "W159",
                "sequenceNumber": 527
            },
            {
                "refListItemId": 19846,
                "enabled": true,
                "defaultString": "W160",
                "sequenceNumber": 528
            },
            {
                "refListItemId": 19847,
                "enabled": true,
                "defaultString": "W161",
                "sequenceNumber": 529
            },
            {
                "refListItemId": 19848,
                "enabled": true,
                "defaultString": "W162",
                "sequenceNumber": 530
            },
            {
                "refListItemId": 19849,
                "enabled": true,
                "defaultString": "W163",
                "sequenceNumber": 531
            },
            {
                "refListItemId": 19850,
                "enabled": true,
                "defaultString": "W164",
                "sequenceNumber": 532
            },
            {
                "refListItemId": 19851,
                "enabled": true,
                "defaultString": "W165",
                "sequenceNumber": 533
            },
            {
                "refListItemId": 19852,
                "enabled": true,
                "defaultString": "W166",
                "sequenceNumber": 534
            },
            {
                "refListItemId": 19853,
                "enabled": true,
                "defaultString": "W167",
                "sequenceNumber": 535
            },
            {
                "refListItemId": 19854,
                "enabled": true,
                "defaultString": "W168",
                "sequenceNumber": 536
            },
            {
                "refListItemId": 19855,
                "enabled": true,
                "defaultString": "W169",
                "sequenceNumber": 537
            },
            {
                "refListItemId": 19856,
                "enabled": true,
                "defaultString": "W170",
                "sequenceNumber": 538
            },
            {
                "refListItemId": 19857,
                "enabled": true,
                "defaultString": "W171",
                "sequenceNumber": 539
            },
            {
                "refListItemId": 19858,
                "enabled": true,
                "defaultString": "W172",
                "sequenceNumber": 540
            },
            {
                "refListItemId": 19859,
                "enabled": true,
                "defaultString": "W173",
                "sequenceNumber": 541
            },
            {
                "refListItemId": 19860,
                "enabled": true,
                "defaultString": "W174",
                "sequenceNumber": 542
            },
            {
                "refListItemId": 19861,
                "enabled": true,
                "defaultString": "W175",
                "sequenceNumber": 543
            },
            {
                "refListItemId": 19862,
                "enabled": true,
                "defaultString": "W176",
                "sequenceNumber": 544
            },
            {
                "refListItemId": 19863,
                "enabled": true,
                "defaultString": "W177",
                "sequenceNumber": 545
            },
            {
                "refListItemId": 19864,
                "enabled": true,
                "defaultString": "W178",
                "sequenceNumber": 546
            },
            {
                "refListItemId": 19865,
                "enabled": true,
                "defaultString": "W179",
                "sequenceNumber": 547
            },
            {
                "refListItemId": 19866,
                "enabled": true,
                "defaultString": "W180",
                "sequenceNumber": 548
            },
            {
                "refListItemId": 19867,
                "enabled": true,
                "defaultString": "W181",
                "sequenceNumber": 549
            },
            {
                "refListItemId": 19868,
                "enabled": true,
                "defaultString": "W182",
                "sequenceNumber": 550
            },
            {
                "refListItemId": 19869,
                "enabled": true,
                "defaultString": "W183",
                "sequenceNumber": 551
            },
            {
                "refListItemId": 19870,
                "enabled": true,
                "defaultString": "W184",
                "sequenceNumber": 552
            },
            {
                "refListItemId": 19871,
                "enabled": true,
                "defaultString": "W185",
                "sequenceNumber": 553
            },
            {
                "refListItemId": 19872,
                "enabled": true,
                "defaultString": "W186",
                "sequenceNumber": 554
            },
            {
                "refListItemId": 19873,
                "enabled": true,
                "defaultString": "W187",
                "sequenceNumber": 555
            },
            {
                "refListItemId": 19874,
                "enabled": true,
                "defaultString": "W188",
                "sequenceNumber": 556
            },
            {
                "refListItemId": 19875,
                "enabled": true,
                "defaultString": "W189",
                "sequenceNumber": 557
            },
            {
                "refListItemId": 19876,
                "enabled": true,
                "defaultString": "W190",
                "sequenceNumber": 558
            },
            {
                "refListItemId": 19877,
                "enabled": true,
                "defaultString": "W191",
                "sequenceNumber": 559
            },
            {
                "refListItemId": 19878,
                "enabled": true,
                "defaultString": "W192",
                "sequenceNumber": 560
            },
            {
                "refListItemId": 19879,
                "enabled": true,
                "defaultString": "W193",
                "sequenceNumber": 561
            },
            {
                "refListItemId": 19880,
                "enabled": true,
                "defaultString": "W194",
                "sequenceNumber": 562
            },
            {
                "refListItemId": 19881,
                "enabled": true,
                "defaultString": "W195",
                "sequenceNumber": 563
            },
            {
                "refListItemId": 19882,
                "enabled": true,
                "defaultString": "W196",
                "sequenceNumber": 564
            },
            {
                "refListItemId": 19883,
                "enabled": true,
                "defaultString": "W197",
                "sequenceNumber": 565
            },
            {
                "refListItemId": 19884,
                "enabled": true,
                "defaultString": "W198",
                "sequenceNumber": 566
            },
            {
                "refListItemId": 19885,
                "enabled": true,
                "defaultString": "W199",
                "sequenceNumber": 567
            },
            {
                "refListItemId": 19886,
                "enabled": true,
                "defaultString": "W200",
                "sequenceNumber": 568
            },
            {
                "refListItemId": 19887,
                "enabled": true,
                "defaultString": "Hospital",
                "sequenceNumber": 569
            },
            {
                "refListItemId": 19888,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 570
            },
            {
                "refListItemId": 19889,
                "enabled": true,
                "defaultString": "Sherburne",
                "sequenceNumber": 571
            },
            {
                "refListItemId": 19571,
                "enabled": true,
                "defaultString": "0253",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 19414,
                "enabled": true,
                "defaultString": "0096",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 19415,
                "enabled": true,
                "defaultString": "0097",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 19416,
                "enabled": true,
                "defaultString": "0098",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 19417,
                "enabled": true,
                "defaultString": "0099",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 19418,
                "enabled": true,
                "defaultString": "0100",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 19419,
                "enabled": true,
                "defaultString": "0101",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 19420,
                "enabled": true,
                "defaultString": "0102",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 19421,
                "enabled": true,
                "defaultString": "0103",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 19422,
                "enabled": true,
                "defaultString": "0104",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 19423,
                "enabled": true,
                "defaultString": "0105",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 19424,
                "enabled": true,
                "defaultString": "0106",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 19425,
                "enabled": true,
                "defaultString": "0107",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 19426,
                "enabled": true,
                "defaultString": "0108",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 19427,
                "enabled": true,
                "defaultString": "0109",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 19428,
                "enabled": true,
                "defaultString": "0110",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 19429,
                "enabled": true,
                "defaultString": "0111",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 19430,
                "enabled": true,
                "defaultString": "0112",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 19431,
                "enabled": true,
                "defaultString": "0113",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 19432,
                "enabled": true,
                "defaultString": "0114",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 19433,
                "enabled": true,
                "defaultString": "0115",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 19434,
                "enabled": true,
                "defaultString": "0116",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 19435,
                "enabled": true,
                "defaultString": "0117",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 19436,
                "enabled": true,
                "defaultString": "0118",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 19437,
                "enabled": true,
                "defaultString": "0119",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 19438,
                "enabled": true,
                "defaultString": "0120",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 19439,
                "enabled": true,
                "defaultString": "0121",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 19440,
                "enabled": true,
                "defaultString": "0122",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 19441,
                "enabled": true,
                "defaultString": "0123",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 19442,
                "enabled": true,
                "defaultString": "0124",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 19443,
                "enabled": true,
                "defaultString": "0125",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 19444,
                "enabled": true,
                "defaultString": "0126",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 19445,
                "enabled": true,
                "defaultString": "0127",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 19446,
                "enabled": true,
                "defaultString": "0128",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 19447,
                "enabled": true,
                "defaultString": "0129",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 19448,
                "enabled": true,
                "defaultString": "0130",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 19449,
                "enabled": true,
                "defaultString": "0131",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 19450,
                "enabled": true,
                "defaultString": "0132",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 19451,
                "enabled": true,
                "defaultString": "0133",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 19452,
                "enabled": true,
                "defaultString": "0134",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 19453,
                "enabled": true,
                "defaultString": "0135",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 19454,
                "enabled": true,
                "defaultString": "0136",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 19455,
                "enabled": true,
                "defaultString": "0137",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 19456,
                "enabled": true,
                "defaultString": "0138",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 19457,
                "enabled": true,
                "defaultString": "0139",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 19458,
                "enabled": true,
                "defaultString": "0140",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 19459,
                "enabled": true,
                "defaultString": "0141",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 19460,
                "enabled": true,
                "defaultString": "0142",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 19461,
                "enabled": true,
                "defaultString": "0143",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 19462,
                "enabled": true,
                "defaultString": "0144",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 19463,
                "enabled": true,
                "defaultString": "0145",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 19464,
                "enabled": true,
                "defaultString": "0146",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 19465,
                "enabled": true,
                "defaultString": "0147",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 19466,
                "enabled": true,
                "defaultString": "0148",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 19467,
                "enabled": true,
                "defaultString": "0149",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 19468,
                "enabled": true,
                "defaultString": "0150",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 19469,
                "enabled": true,
                "defaultString": "0151",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 19470,
                "enabled": true,
                "defaultString": "0152",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 19471,
                "enabled": true,
                "defaultString": "0153",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 19472,
                "enabled": true,
                "defaultString": "0154",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 19473,
                "enabled": true,
                "defaultString": "0155",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 19474,
                "enabled": true,
                "defaultString": "0156",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 19475,
                "enabled": true,
                "defaultString": "0157",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 19476,
                "enabled": true,
                "defaultString": "0158",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 19477,
                "enabled": true,
                "defaultString": "0159",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 19478,
                "enabled": true,
                "defaultString": "0160",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 19479,
                "enabled": true,
                "defaultString": "0161",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 19480,
                "enabled": true,
                "defaultString": "0162",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 19481,
                "enabled": true,
                "defaultString": "0163",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 19482,
                "enabled": true,
                "defaultString": "0164",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 19483,
                "enabled": true,
                "defaultString": "0165",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 19484,
                "enabled": true,
                "defaultString": "0166",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 19485,
                "enabled": true,
                "defaultString": "0167",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 19486,
                "enabled": true,
                "defaultString": "0168",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 19487,
                "enabled": true,
                "defaultString": "0169",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 19488,
                "enabled": true,
                "defaultString": "0170",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 19489,
                "enabled": true,
                "defaultString": "0171",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 19490,
                "enabled": true,
                "defaultString": "0172",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 19491,
                "enabled": true,
                "defaultString": "0173",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 19492,
                "enabled": true,
                "defaultString": "0174",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 19493,
                "enabled": true,
                "defaultString": "0175",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 19494,
                "enabled": true,
                "defaultString": "0176",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 19495,
                "enabled": true,
                "defaultString": "0177",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 19496,
                "enabled": true,
                "defaultString": "0178",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 19497,
                "enabled": true,
                "defaultString": "0179",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 19498,
                "enabled": true,
                "defaultString": "0180",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 19499,
                "enabled": true,
                "defaultString": "0181",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 19500,
                "enabled": true,
                "defaultString": "0182",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 19501,
                "enabled": true,
                "defaultString": "0183",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 19502,
                "enabled": true,
                "defaultString": "0184",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 19503,
                "enabled": true,
                "defaultString": "0185",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 19504,
                "enabled": true,
                "defaultString": "0186",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 19505,
                "enabled": true,
                "defaultString": "0187",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 19506,
                "enabled": true,
                "defaultString": "0188",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 19507,
                "enabled": true,
                "defaultString": "0189",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 19508,
                "enabled": true,
                "defaultString": "0190",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 19509,
                "enabled": true,
                "defaultString": "0191",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 19510,
                "enabled": true,
                "defaultString": "0192",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 19511,
                "enabled": true,
                "defaultString": "0193",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 19512,
                "enabled": true,
                "defaultString": "0194",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 19513,
                "enabled": true,
                "defaultString": "0195",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 19514,
                "enabled": true,
                "defaultString": "0196",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 19515,
                "enabled": true,
                "defaultString": "0197",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 19516,
                "enabled": true,
                "defaultString": "0198",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 19517,
                "enabled": true,
                "defaultString": "0199",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 19518,
                "enabled": true,
                "defaultString": "0200",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 19519,
                "enabled": true,
                "defaultString": "0201",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 19520,
                "enabled": true,
                "defaultString": "0202",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 19521,
                "enabled": true,
                "defaultString": "0203",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 19522,
                "enabled": true,
                "defaultString": "0204",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 19523,
                "enabled": true,
                "defaultString": "0205",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 19524,
                "enabled": true,
                "defaultString": "0206",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 19525,
                "enabled": true,
                "defaultString": "0207",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 19526,
                "enabled": true,
                "defaultString": "0208",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 19527,
                "enabled": true,
                "defaultString": "0209",
                "sequenceNumber": 209
            },
            {
                "refListItemId": 19528,
                "enabled": true,
                "defaultString": "0210",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 19529,
                "enabled": true,
                "defaultString": "0211",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 19530,
                "enabled": true,
                "defaultString": "0212",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 19531,
                "enabled": true,
                "defaultString": "0213",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 19532,
                "enabled": true,
                "defaultString": "0214",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 19533,
                "enabled": true,
                "defaultString": "0215",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 19534,
                "enabled": true,
                "defaultString": "0216",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 19535,
                "enabled": true,
                "defaultString": "0217",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 19536,
                "enabled": true,
                "defaultString": "0218",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 19537,
                "enabled": true,
                "defaultString": "0219",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 19538,
                "enabled": true,
                "defaultString": "0220",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 19539,
                "enabled": true,
                "defaultString": "0221",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 19540,
                "enabled": true,
                "defaultString": "0222",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 19541,
                "enabled": true,
                "defaultString": "0223",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 19542,
                "enabled": true,
                "defaultString": "0224",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 19543,
                "enabled": true,
                "defaultString": "0225",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 19544,
                "enabled": true,
                "defaultString": "0226",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 19545,
                "enabled": true,
                "defaultString": "0227",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 19546,
                "enabled": true,
                "defaultString": "0228",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 19547,
                "enabled": true,
                "defaultString": "0229",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 19548,
                "enabled": true,
                "defaultString": "0230",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 19549,
                "enabled": true,
                "defaultString": "0231",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 19550,
                "enabled": true,
                "defaultString": "0232",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 19551,
                "enabled": true,
                "defaultString": "0233",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 19552,
                "enabled": true,
                "defaultString": "0234",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 19553,
                "enabled": true,
                "defaultString": "0235",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 19554,
                "enabled": true,
                "defaultString": "0236",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 19555,
                "enabled": true,
                "defaultString": "0237",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 19556,
                "enabled": true,
                "defaultString": "0238",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 19557,
                "enabled": true,
                "defaultString": "0239",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 19558,
                "enabled": true,
                "defaultString": "0240",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 19559,
                "enabled": true,
                "defaultString": "0241",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 19560,
                "enabled": true,
                "defaultString": "0242",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 19561,
                "enabled": true,
                "defaultString": "0243",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 19562,
                "enabled": true,
                "defaultString": "0244",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 19563,
                "enabled": true,
                "defaultString": "0245",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 19564,
                "enabled": true,
                "defaultString": "0246",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 19565,
                "enabled": true,
                "defaultString": "0247",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 19566,
                "enabled": true,
                "defaultString": "0248",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 19567,
                "enabled": true,
                "defaultString": "0249",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 19319,
                "enabled": true,
                "defaultString": "0001",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 19320,
                "enabled": true,
                "defaultString": "0002",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 19321,
                "enabled": true,
                "defaultString": "0003",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 19322,
                "enabled": true,
                "defaultString": "0004",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 19323,
                "enabled": true,
                "defaultString": "0005",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 19324,
                "enabled": true,
                "defaultString": "0006",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 19325,
                "enabled": true,
                "defaultString": "0007",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 19326,
                "enabled": true,
                "defaultString": "0008",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 19327,
                "enabled": true,
                "defaultString": "0009",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 19328,
                "enabled": true,
                "defaultString": "0010",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 19329,
                "enabled": true,
                "defaultString": "0011",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 19330,
                "enabled": true,
                "defaultString": "0012",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 19331,
                "enabled": true,
                "defaultString": "0013",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 19332,
                "enabled": true,
                "defaultString": "0014",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 19333,
                "enabled": true,
                "defaultString": "0015",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 19334,
                "enabled": true,
                "defaultString": "0016",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 19335,
                "enabled": true,
                "defaultString": "0017",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 19336,
                "enabled": true,
                "defaultString": "0018",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 19337,
                "enabled": true,
                "defaultString": "0019",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 19338,
                "enabled": true,
                "defaultString": "0020",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 19339,
                "enabled": true,
                "defaultString": "0021",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 19340,
                "enabled": true,
                "defaultString": "0022",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 19341,
                "enabled": true,
                "defaultString": "0023",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 19342,
                "enabled": true,
                "defaultString": "0024",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 19343,
                "enabled": true,
                "defaultString": "0025",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 19344,
                "enabled": true,
                "defaultString": "0026",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 19345,
                "enabled": true,
                "defaultString": "0027",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 19346,
                "enabled": true,
                "defaultString": "0028",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 19347,
                "enabled": true,
                "defaultString": "0029",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 19348,
                "enabled": true,
                "defaultString": "0030",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 19349,
                "enabled": true,
                "defaultString": "0031",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 19350,
                "enabled": true,
                "defaultString": "0032",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 19351,
                "enabled": true,
                "defaultString": "0033",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 19352,
                "enabled": true,
                "defaultString": "0034",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 19353,
                "enabled": true,
                "defaultString": "0035",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 19354,
                "enabled": true,
                "defaultString": "0036",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 19355,
                "enabled": true,
                "defaultString": "0037",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 19356,
                "enabled": true,
                "defaultString": "0038",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 19357,
                "enabled": true,
                "defaultString": "0039",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 19358,
                "enabled": true,
                "defaultString": "0040",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 19359,
                "enabled": true,
                "defaultString": "0041",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 19360,
                "enabled": true,
                "defaultString": "0042",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 19361,
                "enabled": true,
                "defaultString": "0043",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 19362,
                "enabled": true,
                "defaultString": "0044",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 19363,
                "enabled": true,
                "defaultString": "0045",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 19364,
                "enabled": true,
                "defaultString": "0046",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 19365,
                "enabled": true,
                "defaultString": "0047",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 19366,
                "enabled": true,
                "defaultString": "0048",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 19367,
                "enabled": true,
                "defaultString": "0049",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 19368,
                "enabled": true,
                "defaultString": "0050",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 19369,
                "enabled": true,
                "defaultString": "0051",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 19370,
                "enabled": true,
                "defaultString": "0052",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 19371,
                "enabled": true,
                "defaultString": "0053",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 19372,
                "enabled": true,
                "defaultString": "0054",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 19373,
                "enabled": true,
                "defaultString": "0055",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 19374,
                "enabled": true,
                "defaultString": "0056",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 19375,
                "enabled": true,
                "defaultString": "0057",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 19376,
                "enabled": true,
                "defaultString": "0058",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 19377,
                "enabled": true,
                "defaultString": "0059",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 19378,
                "enabled": true,
                "defaultString": "0060",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 19379,
                "enabled": true,
                "defaultString": "0061",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 19380,
                "enabled": true,
                "defaultString": "0062",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 19381,
                "enabled": true,
                "defaultString": "0063",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 19382,
                "enabled": true,
                "defaultString": "0064",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 19383,
                "enabled": true,
                "defaultString": "0065",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 19384,
                "enabled": true,
                "defaultString": "0066",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 19385,
                "enabled": true,
                "defaultString": "0067",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 19386,
                "enabled": true,
                "defaultString": "0068",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 19387,
                "enabled": true,
                "defaultString": "0069",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 19388,
                "enabled": true,
                "defaultString": "0070",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 19389,
                "enabled": true,
                "defaultString": "0071",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 19390,
                "enabled": true,
                "defaultString": "0072",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 19391,
                "enabled": true,
                "defaultString": "0073",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 19392,
                "enabled": true,
                "defaultString": "0074",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 19393,
                "enabled": true,
                "defaultString": "0075",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 19394,
                "enabled": true,
                "defaultString": "0076",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 19395,
                "enabled": true,
                "defaultString": "0077",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 19396,
                "enabled": true,
                "defaultString": "0078",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 19397,
                "enabled": true,
                "defaultString": "0079",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 19398,
                "enabled": true,
                "defaultString": "0080",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 19399,
                "enabled": true,
                "defaultString": "0081",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 19400,
                "enabled": true,
                "defaultString": "0082",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 19401,
                "enabled": true,
                "defaultString": "0083",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 19402,
                "enabled": true,
                "defaultString": "0084",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 19403,
                "enabled": true,
                "defaultString": "0085",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 19404,
                "enabled": true,
                "defaultString": "0086",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 19405,
                "enabled": true,
                "defaultString": "0087",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 19406,
                "enabled": true,
                "defaultString": "0088",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 19407,
                "enabled": true,
                "defaultString": "0089",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 19408,
                "enabled": true,
                "defaultString": "0090",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 19409,
                "enabled": true,
                "defaultString": "0091",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 19410,
                "enabled": true,
                "defaultString": "0092",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 19411,
                "enabled": true,
                "defaultString": "0093",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 19412,
                "enabled": true,
                "defaultString": "0094",
                "sequenceNumber": 94
            }
        ]
    },
    {
        "RefListID": 409,
        "refListName": "Search_Technique",
        "refListItems": [
            {
                "refListItemId": 4197,
                "enabled": true,
                "defaultString": "Cavity",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4198,
                "enabled": true,
                "defaultString": "Pat",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4199,
                "enabled": true,
                "defaultString": "Strip",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 499,
        "refListName": "Controlling_Agency",
        "refListItems": [
            {
                "refListItemId": 17964,
                "enabled": false,
                "defaultString": "OR0000000",
                "longString": "OR0000000 - Oregon",
                "sequenceNumber": 751
            },
            {
                "refListItemId": 24383,
                "enabled": true,
                "defaultString": "OR0010000",
                "longString": "OR0010000 - Baker County SO"
            },
            {
                "refListItemId": 24384,
                "enabled": true,
                "defaultString": "OR0020000",
                "longString": "OR0020000 - Benton County SO"
            },
            {
                "refListItemId": 24365,
                "enabled": true,
                "defaultString": "OR0240000",
                "longString": "OR0240000 - Marion County SO"
            },
            {
                "refListItemId": 24366,
                "enabled": true,
                "defaultString": "OR0240200",
                "longString": "OR0240200 - Salem PD"
            },
            {
                "refListItemId": 24367,
                "enabled": true,
                "defaultString": "OR0240300",
                "longString": "OR0240300 - Silverton PD"
            },
            {
                "refListItemId": 24368,
                "enabled": true,
                "defaultString": "OR0240400",
                "longString": "OR0240400 - Stayton City PD"
            },
            {
                "refListItemId": 24369,
                "enabled": true,
                "defaultString": "OR0240500",
                "longString": "OR0240500 - Woodburn PD"
            },
            {
                "refListItemId": 24370,
                "enabled": true,
                "defaultString": "OR0240600",
                "longString": "OR0240600 - Gervais PD"
            },
            {
                "refListItemId": 24371,
                "enabled": true,
                "defaultString": "OR0240700",
                "longString": "OR0240700 - Hubbard PD"
            },
            {
                "refListItemId": 24372,
                "enabled": true,
                "defaultString": "OR0240800",
                "longString": "OR0240800 - Mount Angel PD"
            },
            {
                "refListItemId": 24373,
                "enabled": true,
                "defaultString": "OR0241000",
                "longString": "OR0241000 - Turner PD"
            },
            {
                "refListItemId": 24374,
                "enabled": true,
                "defaultString": "OR0241200",
                "longString": "OR0241200 - Aumsville PD"
            },
            {
                "refListItemId": 24375,
                "enabled": true,
                "defaultString": "OR0241700",
                "longString": "OR0241700 - Keizer City PD"
            },
            {
                "refListItemId": 24385,
                "enabled": true,
                "defaultString": "OR0250000",
                "longString": "OR0250000 - Morrow County SO"
            },
            {
                "refListItemId": 24386,
                "enabled": true,
                "defaultString": "OR0260000",
                "longString": "OR0260000 - Multnomah County SO"
            },
            {
                "refListItemId": 24387,
                "enabled": true,
                "defaultString": "OR0270000",
                "longString": "OR0270000 - Polk County SO"
            },
            {
                "refListItemId": 24388,
                "enabled": true,
                "defaultString": "OR0280000",
                "longString": "OR0280000 - Sherman County SO"
            },
            {
                "refListItemId": 24389,
                "enabled": true,
                "defaultString": "OR0290000",
                "longString": "OR0290000 - Tillamook County SO"
            },
            {
                "refListItemId": 10503,
                "enabled": false,
                "defaultString": "ORIG_CNTY",
                "longString": "ORIG_CNTY",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 6859,
                "enabled": false,
                "defaultString": "ORIGLCNTY",
                "longString": "Originating County",
                "sequenceNumber": 126
            }
        ]
    },
    {
        "RefListID": 411,
        "refListName": "Speech",
        "refListItems": [
            {
                "refListItemId": 4201,
                "enabled": true,
                "defaultString": "Accent",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4202,
                "enabled": true,
                "defaultString": "Apologetic",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4203,
                "enabled": true,
                "defaultString": "Deep",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4204,
                "enabled": true,
                "defaultString": "Effeminate",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4205,
                "enabled": true,
                "defaultString": "High",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4206,
                "enabled": true,
                "defaultString": "Lisp",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4207,
                "enabled": true,
                "defaultString": "Normal",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4208,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4209,
                "enabled": true,
                "defaultString": "Profane Abusive",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4210,
                "enabled": true,
                "defaultString": "Soft",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4211,
                "enabled": true,
                "defaultString": "Stutter",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4212,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 446,
        "refListName": "StateOrCountry",
        "refListItems": [
            {
                "refListItemId": 4515,
                "enabled": true,
                "defaultString": "AF",
                "longString": "Afghanistan",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4516,
                "enabled": true,
                "defaultString": "AG",
                "longString": "Aguascalientes (MM)",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4511,
                "enabled": true,
                "defaultString": "AA",
                "longString": "Albania",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4512,
                "enabled": true,
                "defaultString": "AB",
                "longString": "Alberta (CD)",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4513,
                "enabled": true,
                "defaultString": "AD",
                "longString": "Andorra",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4514,
                "enabled": true,
                "defaultString": "AE",
                "longString": "Anguilla",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4519,
                "enabled": true,
                "defaultString": "AJ",
                "longString": "Aruba",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4520,
                "enabled": true,
                "defaultString": "AK",
                "longString": "Alaska",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4521,
                "enabled": true,
                "defaultString": "AL",
                "longString": "Alabama",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4522,
                "enabled": true,
                "defaultString": "AM",
                "longString": "American Samoa",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4523,
                "enabled": true,
                "defaultString": "AN",
                "longString": "Algeria",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4524,
                "enabled": true,
                "defaultString": "AO",
                "longString": "Angola",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4517,
                "enabled": true,
                "defaultString": "AH",
                "longString": "Ashmore and Cartier Islands",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4518,
                "enabled": true,
                "defaultString": "AI",
                "longString": "Barbuda and Antigua",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4525,
                "enabled": true,
                "defaultString": "AP",
                "longString": "Armenia",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4526,
                "enabled": true,
                "defaultString": "AQ",
                "longString": "Azores Islands",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4527,
                "enabled": true,
                "defaultString": "AR",
                "longString": "Arkansas",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4528,
                "enabled": true,
                "defaultString": "AS",
                "longString": "Australia",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4529,
                "enabled": true,
                "defaultString": "AT",
                "longString": "Argentina",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4530,
                "enabled": true,
                "defaultString": "AU",
                "longString": "Austria",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4531,
                "enabled": true,
                "defaultString": "AV",
                "longString": "Azerbaijan",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 4532,
                "enabled": true,
                "defaultString": "AZ",
                "longString": "Arizona",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 4533,
                "enabled": true,
                "defaultString": "BA",
                "longString": "Baja California (MM)",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 4534,
                "enabled": true,
                "defaultString": "BB",
                "longString": "Barbados",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 4535,
                "enabled": true,
                "defaultString": "BC",
                "longString": "British Columbia (CD)",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 4536,
                "enabled": true,
                "defaultString": "BD",
                "longString": "Bahamas",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 4537,
                "enabled": true,
                "defaultString": "BE",
                "longString": "Bahrain",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 4538,
                "enabled": true,
                "defaultString": "BG",
                "longString": "Belgium",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 4539,
                "enabled": true,
                "defaultString": "BH",
                "longString": "Belize",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 4540,
                "enabled": true,
                "defaultString": "BI",
                "longString": "Burundi",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 4541,
                "enabled": true,
                "defaultString": "BJ",
                "longString": "Baja California Sur (MM)",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 4542,
                "enabled": true,
                "defaultString": "BK",
                "longString": "Baker Island",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 4543,
                "enabled": true,
                "defaultString": "BL",
                "longString": "Bangladesh",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 4544,
                "enabled": true,
                "defaultString": "BM",
                "longString": "Bermuda",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 4545,
                "enabled": true,
                "defaultString": "BN",
                "longString": "Bhutan",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 4546,
                "enabled": true,
                "defaultString": "BO",
                "longString": "British Indian Ocean Territory",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 4547,
                "enabled": true,
                "defaultString": "BP",
                "longString": "Bosnia and Herzegovina",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 4548,
                "enabled": true,
                "defaultString": "BQ",
                "longString": "Bouvet Island (Norwegian territory)",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 4549,
                "enabled": true,
                "defaultString": "BR",
                "longString": "Burma",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 4550,
                "enabled": true,
                "defaultString": "BS",
                "longString": "Solomon Islands",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 4551,
                "enabled": true,
                "defaultString": "BT",
                "longString": "Botswana",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 4552,
                "enabled": true,
                "defaultString": "BU",
                "longString": "Bulgaria",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 4553,
                "enabled": true,
                "defaultString": "BV",
                "longString": "Bolivia",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 4554,
                "enabled": true,
                "defaultString": "BW",
                "longString": "Balearic Islands",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 4555,
                "enabled": true,
                "defaultString": "BX",
                "longString": "Brunei",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 4556,
                "enabled": true,
                "defaultString": "BY",
                "longString": "Byelarus",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 4557,
                "enabled": true,
                "defaultString": "BZ",
                "longString": "Brazil",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 4558,
                "enabled": true,
                "defaultString": "CA",
                "longString": "California",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 4559,
                "enabled": true,
                "defaultString": "CB",
                "longString": "Colombia",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 4560,
                "enabled": true,
                "defaultString": "CC",
                "longString": "Cuba",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 4561,
                "enabled": true,
                "defaultString": "CD",
                "longString": "Canada",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 4562,
                "enabled": true,
                "defaultString": "CE",
                "longString": "Campeche (MM)",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 4563,
                "enabled": true,
                "defaultString": "CF",
                "longString": "Chad",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 4564,
                "enabled": true,
                "defaultString": "CG",
                "longString": "Caroline Islands",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 4565,
                "enabled": true,
                "defaultString": "CH",
                "longString": "Chihuahua (MM)",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 4566,
                "enabled": true,
                "defaultString": "CI",
                "longString": "Chiapas (MM)",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 4567,
                "enabled": true,
                "defaultString": "CJ",
                "longString": "Cambodia",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 4568,
                "enabled": true,
                "defaultString": "CL",
                "longString": "Colima (MM)",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 4569,
                "enabled": true,
                "defaultString": "CM",
                "longString": "Cameroon",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 4570,
                "enabled": true,
                "defaultString": "CO",
                "longString": "Colorado",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 4571,
                "enabled": true,
                "defaultString": "CP",
                "longString": "Cayman Islands",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 4572,
                "enabled": true,
                "defaultString": "CQ",
                "longString": "Chile",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 4573,
                "enabled": true,
                "defaultString": "CR",
                "longString": "Costa Rica",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 4574,
                "enabled": true,
                "defaultString": "CS",
                "longString": "Cyprus",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 4575,
                "enabled": true,
                "defaultString": "CT",
                "longString": "Connecticut",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 4576,
                "enabled": true,
                "defaultString": "CU",
                "longString": "Coahuila (MM)",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 4577,
                "enabled": true,
                "defaultString": "CV",
                "longString": "Cape Verde Islands",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 4578,
                "enabled": true,
                "defaultString": "CW",
                "longString": "Central African Republic",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 4579,
                "enabled": true,
                "defaultString": "CY",
                "longString": "Sri Lanka (formerly Ceylon)",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 4580,
                "enabled": true,
                "defaultString": "CZ",
                "longString": "Canal Zone",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 4581,
                "enabled": true,
                "defaultString": "DB",
                "longString": "Clipperton Island",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 4582,
                "enabled": true,
                "defaultString": "DC",
                "longString": "District of Columbia",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 4583,
                "enabled": true,
                "defaultString": "DD",
                "longString": "Cocos (Keeling) Islands",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 4584,
                "enabled": true,
                "defaultString": "DE",
                "longString": "Delaware",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 4585,
                "enabled": true,
                "defaultString": "DF",
                "longString": "Distrito Federal (MM)",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 4586,
                "enabled": true,
                "defaultString": "DG",
                "longString": "Comoros",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 4587,
                "enabled": true,
                "defaultString": "DH",
                "longString": "Benin",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 4588,
                "enabled": true,
                "defaultString": "DI",
                "longString": "Cook Islands",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 4589,
                "enabled": true,
                "defaultString": "DJ",
                "longString": "Coral Sea Islands",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 4590,
                "enabled": true,
                "defaultString": "DK",
                "longString": "Denmark",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 4591,
                "enabled": true,
                "defaultString": "DM",
                "longString": "Dominica",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 4592,
                "enabled": true,
                "defaultString": "DN",
                "longString": "Djibouti",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 4593,
                "enabled": true,
                "defaultString": "DO",
                "longString": "Durango (MM)",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 4594,
                "enabled": true,
                "defaultString": "DR",
                "longString": "Dominican Republic",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 4595,
                "enabled": false,
                "defaultString": "ds",
                "longString": "South Sandwich Isla",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 4596,
                "enabled": true,
                "defaultString": "EK",
                "longString": "Equatorial Guinea",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 4597,
                "enabled": true,
                "defaultString": "EL",
                "longString": "El Salvador",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 4598,
                "enabled": false,
                "defaultString": "EM",
                "longString": "East Germany",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 4599,
                "enabled": true,
                "defaultString": "EN",
                "longString": "England",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 4600,
                "enabled": true,
                "defaultString": "EO",
                "longString": "Ethiopia",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 4601,
                "enabled": true,
                "defaultString": "ER",
                "longString": "Europa Island (French possession)",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 4602,
                "enabled": true,
                "defaultString": "ES",
                "longString": "Estonia",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 4603,
                "enabled": true,
                "defaultString": "ET",
                "longString": "Eritrea",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 4604,
                "enabled": true,
                "defaultString": "EU",
                "longString": "Ecuador",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 4605,
                "enabled": true,
                "defaultString": "EY",
                "longString": "Egypt",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 4606,
                "enabled": true,
                "defaultString": "EZ",
                "longString": "Czech Republic",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 4607,
                "enabled": true,
                "defaultString": "FA",
                "longString": "Falkland Islands",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 4608,
                "enabled": true,
                "defaultString": "FD",
                "longString": "Finland",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 4609,
                "enabled": true,
                "defaultString": "FG",
                "longString": "French Guiana",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 4610,
                "enabled": true,
                "defaultString": "FJ",
                "longString": "Fiji",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 4611,
                "enabled": true,
                "defaultString": "FL",
                "longString": "Florida",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 4612,
                "enabled": true,
                "defaultString": "FN",
                "longString": "France",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 4613,
                "enabled": true,
                "defaultString": "FO",
                "longString": "Faroe Islands",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 4614,
                "enabled": true,
                "defaultString": "FP",
                "longString": "French Polynesia",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 4615,
                "enabled": true,
                "defaultString": "FR",
                "longString": "French Southern and Antarctic Lands,",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 4616,
                "enabled": true,
                "defaultString": "FS",
                "longString": "Micronesia, Federated States of",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 4617,
                "enabled": true,
                "defaultString": "GA",
                "longString": "Georgia",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 4618,
                "enabled": true,
                "defaultString": "GB",
                "longString": "Gabon",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 4619,
                "enabled": true,
                "defaultString": "GC",
                "longString": "Greece",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 4620,
                "enabled": true,
                "defaultString": "GD",
                "longString": "Georgia (formerly Gruzinskaya)",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 4621,
                "enabled": true,
                "defaultString": "GE",
                "longString": "Germany",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 4622,
                "enabled": true,
                "defaultString": "GF",
                "longString": "Guernsey",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 4623,
                "enabled": true,
                "defaultString": "GG",
                "longString": "Ghana",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 4624,
                "enabled": true,
                "defaultString": "GI",
                "longString": "Guinea",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 4625,
                "enabled": true,
                "defaultString": "GJ",
                "longString": "Grenada",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 4626,
                "enabled": true,
                "defaultString": "GK",
                "longString": "Gambia",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 4627,
                "enabled": true,
                "defaultString": "GM",
                "longString": "Guam",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 4628,
                "enabled": true,
                "defaultString": "GN",
                "longString": "Greenland",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 4629,
                "enabled": true,
                "defaultString": "GO",
                "longString": "Glorioso Islands (French possession)",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 4630,
                "enabled": true,
                "defaultString": "GP",
                "longString": "Guadeloupe",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 4631,
                "enabled": true,
                "defaultString": "GR",
                "longString": "Guerrero (MM)",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 4632,
                "enabled": true,
                "defaultString": "GS",
                "longString": "South Georgia, South Sandwich Islands",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 4633,
                "enabled": true,
                "defaultString": "GT",
                "longString": "Guatemala",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 4634,
                "enabled": true,
                "defaultString": "GU",
                "longString": "Guanajuato (MM)",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 4635,
                "enabled": true,
                "defaultString": "GY",
                "longString": "Guyana",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 4636,
                "enabled": true,
                "defaultString": "GZ",
                "longString": "Gaza",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 4637,
                "enabled": true,
                "defaultString": "HD",
                "longString": "Honduras",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 4638,
                "enabled": true,
                "defaultString": "HE",
                "longString": "Heard Island and McDonald Islands",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 4639,
                "enabled": true,
                "defaultString": "HI",
                "longString": "Hawaii",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 4640,
                "enabled": true,
                "defaultString": "HK",
                "longString": "Hong Kong",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 4641,
                "enabled": true,
                "defaultString": "HL",
                "longString": "Hidalgo (MM)",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 4642,
                "enabled": true,
                "defaultString": "HN",
                "longString": "Vanuatu, Republic of",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 4643,
                "enabled": true,
                "defaultString": "HO",
                "longString": "Howland Island",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 4644,
                "enabled": true,
                "defaultString": "HR",
                "longString": "Christmas Island",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 4645,
                "enabled": true,
                "defaultString": "HS",
                "longString": "Saint Helena (dependent territory of",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 4646,
                "enabled": true,
                "defaultString": "HT",
                "longString": "Haiti",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 4647,
                "enabled": true,
                "defaultString": "HU",
                "longString": "Hungary",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 4648,
                "enabled": true,
                "defaultString": "IA",
                "longString": "Iowa",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 4649,
                "enabled": true,
                "defaultString": "IB",
                "longString": "Isle of Man",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 4650,
                "enabled": true,
                "defaultString": "IC",
                "longString": "Iceland",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 4651,
                "enabled": true,
                "defaultString": "ID",
                "longString": "Idaho",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 4652,
                "enabled": true,
                "defaultString": "IE",
                "longString": "Ireland",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 4653,
                "enabled": true,
                "defaultString": "II",
                "longString": "India",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 4654,
                "enabled": true,
                "defaultString": "IL",
                "longString": "Illinois",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 4655,
                "enabled": true,
                "defaultString": "IM",
                "longString": "Madeira Islands",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 4656,
                "enabled": true,
                "defaultString": "IN",
                "longString": "Indiana",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 4657,
                "enabled": true,
                "defaultString": "IO",
                "longString": "Indonesia",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 4658,
                "enabled": true,
                "defaultString": "IQ",
                "longString": "Iraq",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 4659,
                "enabled": true,
                "defaultString": "IR",
                "longString": "Iran",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 4660,
                "enabled": true,
                "defaultString": "IS",
                "longString": "Israel",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 4661,
                "enabled": true,
                "defaultString": "IT",
                "longString": "Italy (includes Sicily and Sardinia)",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 4662,
                "enabled": true,
                "defaultString": "IU",
                "longString": "Niue",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 4663,
                "enabled": true,
                "defaultString": "IY",
                "longString": "Ivory Coast (C(te dÆIvoire)",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 4664,
                "enabled": true,
                "defaultString": "JA",
                "longString": "Japan",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 4665,
                "enabled": true,
                "defaultString": "JE",
                "longString": "Jersey, Bailiwick of (British Crown",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 4666,
                "enabled": true,
                "defaultString": "JL",
                "longString": "Jalisco (MM)",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 4667,
                "enabled": true,
                "defaultString": "JM",
                "longString": "Jamaica",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 4668,
                "enabled": true,
                "defaultString": "JN",
                "longString": "Jan Mayen (Norweigan territory)",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 4669,
                "enabled": true,
                "defaultString": "JO",
                "longString": "Jordan",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 4670,
                "enabled": true,
                "defaultString": "JU",
                "longString": "Juan de Nova Island",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 4671,
                "enabled": true,
                "defaultString": "KB",
                "longString": "Kiribati (formerly Gilbert Islands)",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 4672,
                "enabled": true,
                "defaultString": "KC",
                "longString": "Croatia",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 4673,
                "enabled": true,
                "defaultString": "KE",
                "longString": "Kenya",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 4674,
                "enabled": true,
                "defaultString": "KH",
                "longString": "Manahiki Island",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 4675,
                "enabled": true,
                "defaultString": "KN",
                "longString": "North Korea",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 4676,
                "enabled": true,
                "defaultString": "KO",
                "longString": "South Korea",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 4677,
                "enabled": true,
                "defaultString": "KS",
                "longString": "Kansas",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 4678,
                "enabled": true,
                "defaultString": "KT",
                "longString": "Kazakhstan",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 4679,
                "enabled": true,
                "defaultString": "KU",
                "longString": "Kuwait",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 4680,
                "enabled": true,
                "defaultString": "KY",
                "longString": "Kentucky",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 4681,
                "enabled": true,
                "defaultString": "KZ",
                "longString": "Kyrgyzstan",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 4682,
                "enabled": true,
                "defaultString": "LA",
                "longString": "Louisiana",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 4683,
                "enabled": true,
                "defaultString": "LB",
                "longString": "Liberia",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 4684,
                "enabled": true,
                "defaultString": "LD",
                "longString": "Moldova",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 4685,
                "enabled": true,
                "defaultString": "LE",
                "longString": "Lesotho",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 4686,
                "enabled": true,
                "defaultString": "LF",
                "longString": "Slovakia",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 4687,
                "enabled": true,
                "defaultString": "LH",
                "longString": "Lithuania",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 4688,
                "enabled": true,
                "defaultString": "LI",
                "longString": "Liechtenstein",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 4689,
                "enabled": true,
                "defaultString": "LN",
                "longString": "Lebanon",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 4690,
                "enabled": true,
                "defaultString": "LO",
                "longString": "Slovenia",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 4691,
                "enabled": true,
                "defaultString": "LS",
                "longString": "Laos",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 4692,
                "enabled": true,
                "defaultString": "LT",
                "longString": "Latvia",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 4693,
                "enabled": true,
                "defaultString": "LU",
                "longString": "Saint Lucia",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 4694,
                "enabled": true,
                "defaultString": "LX",
                "longString": "Luxembourg",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 4695,
                "enabled": true,
                "defaultString": "LY",
                "longString": "Libya",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 4696,
                "enabled": true,
                "defaultString": "MA",
                "longString": "Massachusetts",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 4697,
                "enabled": true,
                "defaultString": "MB",
                "longString": "Manitoba (CD)",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 4698,
                "enabled": true,
                "defaultString": "MC",
                "longString": "Michoacan (MM)",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 4699,
                "enabled": true,
                "defaultString": "MD",
                "longString": "Maryland",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 4700,
                "enabled": true,
                "defaultString": "ME",
                "longString": "Maine",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 4701,
                "enabled": true,
                "defaultString": "MF",
                "longString": "Malawi",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 4702,
                "enabled": true,
                "defaultString": "MG",
                "longString": "Mongolia",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 4703,
                "enabled": true,
                "defaultString": "MH",
                "longString": "Marshall Islands",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 4704,
                "enabled": true,
                "defaultString": "MI",
                "longString": "Michigan",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 4705,
                "enabled": true,
                "defaultString": "MJ",
                "longString": "Monaco",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 4706,
                "enabled": true,
                "defaultString": "MK",
                "longString": "Mariana Islands",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 4707,
                "enabled": true,
                "defaultString": "ML",
                "longString": "Mali",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 4708,
                "enabled": true,
                "defaultString": "MM",
                "longString": "Mexico",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 4709,
                "enabled": true,
                "defaultString": "MN",
                "longString": "Minnesota",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 4710,
                "enabled": true,
                "defaultString": "MO",
                "longString": "Missouri",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 4711,
                "enabled": true,
                "defaultString": "MP",
                "longString": "Malagasy Republic",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 4712,
                "enabled": true,
                "defaultString": "MQ",
                "longString": "Morocco",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 4713,
                "enabled": true,
                "defaultString": "MR",
                "longString": "Morelos (MM)",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 4714,
                "enabled": true,
                "defaultString": "MS",
                "longString": "Mississippi",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 4715,
                "enabled": true,
                "defaultString": "MT",
                "longString": "Montana",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 4716,
                "enabled": true,
                "defaultString": "MU",
                "longString": "Mauritania",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 4717,
                "enabled": true,
                "defaultString": "MV",
                "longString": "Maldives",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 4718,
                "enabled": true,
                "defaultString": "MW",
                "longString": "Midway Islands",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 4719,
                "enabled": true,
                "defaultString": "MX",
                "longString": "Mexico (MM)",
                "sequenceNumber": 209
            },
            {
                "refListItemId": 4720,
                "enabled": true,
                "defaultString": "MY",
                "longString": "Malta",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 4721,
                "enabled": true,
                "defaultString": "MZ",
                "longString": "Malaysia",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 4722,
                "enabled": true,
                "defaultString": "NA",
                "longString": "Nayarit (MM)",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 4723,
                "enabled": true,
                "defaultString": "NB",
                "longString": "Nebraska",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 4724,
                "enabled": true,
                "defaultString": "NC",
                "longString": "North Carolina",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 4725,
                "enabled": true,
                "defaultString": "ND",
                "longString": "North Dakota",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 4726,
                "enabled": true,
                "defaultString": "NE",
                "longString": "Netherlands (Holland)",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 4727,
                "enabled": true,
                "defaultString": "NF",
                "longString": "Newfoundland (CD)",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 4728,
                "enabled": true,
                "defaultString": "NG",
                "longString": "Nigeria",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 4729,
                "enabled": true,
                "defaultString": "NH",
                "longString": "New Hampshire",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 4730,
                "enabled": true,
                "defaultString": "NI",
                "longString": "Northern Ireland",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 4731,
                "enabled": true,
                "defaultString": "NJ",
                "longString": "New Jersey",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 4732,
                "enabled": true,
                "defaultString": "NK",
                "longString": "New Brunswick (CD)",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 4733,
                "enabled": true,
                "defaultString": "NL",
                "longString": "Nuevo Leon (MM)",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 4734,
                "enabled": true,
                "defaultString": "NM",
                "longString": "New Mexico",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 4735,
                "enabled": true,
                "defaultString": "NN",
                "longString": "Niger",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 4736,
                "enabled": true,
                "defaultString": "NO",
                "longString": "Papua New Guinea",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 4737,
                "enabled": true,
                "defaultString": "NP",
                "longString": "Nepal",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 4738,
                "enabled": true,
                "defaultString": "NQ",
                "longString": "New Caledonia and Dependencies,",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 4739,
                "enabled": true,
                "defaultString": "NR",
                "longString": "Nauru",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 4740,
                "enabled": true,
                "defaultString": "NS",
                "longString": "Nova Scotia (CD)",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 4741,
                "enabled": true,
                "defaultString": "NT",
                "longString": "Northwest Territories (CD)",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 4742,
                "enabled": true,
                "defaultString": "NU",
                "longString": "Nicaragua",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 4743,
                "enabled": true,
                "defaultString": "NV",
                "longString": "Nevada",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 4744,
                "enabled": true,
                "defaultString": "NW",
                "longString": "Norway",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 4745,
                "enabled": true,
                "defaultString": "NX",
                "longString": "Netherlands Antilles",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 4746,
                "enabled": true,
                "defaultString": "NY",
                "longString": "New York",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 4747,
                "enabled": true,
                "defaultString": "NZ",
                "longString": "New Zealand",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 4748,
                "enabled": true,
                "defaultString": "OA",
                "longString": "Oaxaca (MM)",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 4749,
                "enabled": true,
                "defaultString": "OC",
                "longString": "Macau (formerly spelled Macao)",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 4750,
                "enabled": true,
                "defaultString": "OF",
                "longString": "Norfolk Island",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 4751,
                "enabled": true,
                "defaultString": "OH",
                "longString": "Ohio",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 4752,
                "enabled": true,
                "defaultString": "OI",
                "longString": "Okinawa",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 4753,
                "enabled": true,
                "defaultString": "OK",
                "longString": "Oklahoma",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 4754,
                "enabled": true,
                "defaultString": "OM",
                "longString": "Oman",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 4755,
                "enabled": true,
                "defaultString": "ON",
                "longString": "Ontario (CD)",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 4756,
                "enabled": true,
                "defaultString": "OR",
                "longString": "Oregon",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 4757,
                "enabled": true,
                "defaultString": "PA",
                "longString": "Pennsylvania",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 4758,
                "enabled": true,
                "defaultString": "PB",
                "longString": "Puebla (MM)",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 4759,
                "enabled": true,
                "defaultString": "PC",
                "longString": "Ducie Islands",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 4760,
                "enabled": true,
                "defaultString": "PD",
                "longString": "Palau, Republic of",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 4761,
                "enabled": true,
                "defaultString": "PE",
                "longString": "Prince Edward Island (CD)",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 4762,
                "enabled": true,
                "defaultString": "PF",
                "longString": "Paracel Islands",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 4763,
                "enabled": true,
                "defaultString": "PG",
                "longString": "Guinea-Bissau",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 4764,
                "enabled": true,
                "defaultString": "PI",
                "longString": "Philippines",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 4765,
                "enabled": true,
                "defaultString": "PK",
                "longString": "Pakistan",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 4766,
                "enabled": true,
                "defaultString": "PL",
                "longString": "Palmyra Atoll",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 4767,
                "enabled": true,
                "defaultString": "PM",
                "longString": "Panama",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 4768,
                "enabled": true,
                "defaultString": "PO",
                "longString": "Poland",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 4769,
                "enabled": true,
                "defaultString": "PQ",
                "longString": "Quebec (CD)",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 4770,
                "enabled": true,
                "defaultString": "PS",
                "longString": "Saint Pierre and Miquelon, Territorial",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 4771,
                "enabled": true,
                "defaultString": "PT",
                "longString": "Portugal",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 4772,
                "enabled": true,
                "defaultString": "PU",
                "longString": "Peru",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 4773,
                "enabled": true,
                "defaultString": "PV",
                "longString": "Paraguay",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 4774,
                "enabled": true,
                "defaultString": "QA",
                "longString": "Qatar",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 4775,
                "enabled": true,
                "defaultString": "QR",
                "longString": "Quintana Roo (MM)",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 4776,
                "enabled": true,
                "defaultString": "QU",
                "longString": "Queretaro (MM)",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 4777,
                "enabled": true,
                "defaultString": "RA",
                "longString": "Russia",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 4778,
                "enabled": true,
                "defaultString": "RB",
                "longString": "Congo",
                "sequenceNumber": 268
            },
            {
                "refListItemId": 4779,
                "enabled": true,
                "defaultString": "RC",
                "longString": "PeopleÆs Republic of China",
                "sequenceNumber": 269
            },
            {
                "refListItemId": 4780,
                "enabled": true,
                "defaultString": "RE",
                "longString": "Reunion, Department of",
                "sequenceNumber": 270
            },
            {
                "refListItemId": 4781,
                "enabled": true,
                "defaultString": "RF",
                "longString": "Russian Federation",
                "sequenceNumber": 271
            },
            {
                "refListItemId": 4782,
                "enabled": true,
                "defaultString": "RG",
                "longString": "Gibraltar",
                "sequenceNumber": 272
            },
            {
                "refListItemId": 4783,
                "enabled": true,
                "defaultString": "RH",
                "longString": "Zimbabwe, Republic of",
                "sequenceNumber": 273
            },
            {
                "refListItemId": 4784,
                "enabled": true,
                "defaultString": "RI",
                "longString": "Rhode Island",
                "sequenceNumber": 274
            },
            {
                "refListItemId": 4785,
                "enabled": true,
                "defaultString": "RR",
                "longString": "Montserrat",
                "sequenceNumber": 275
            },
            {
                "refListItemId": 4786,
                "enabled": true,
                "defaultString": "RS",
                "longString": "Western Sahara, Independent State of",
                "sequenceNumber": 276
            },
            {
                "refListItemId": 4787,
                "enabled": true,
                "defaultString": "RU",
                "longString": "Romania/Rumania",
                "sequenceNumber": 277
            },
            {
                "refListItemId": 4788,
                "enabled": true,
                "defaultString": "RV",
                "longString": "Vietnam",
                "sequenceNumber": 278
            },
            {
                "refListItemId": 4789,
                "enabled": true,
                "defaultString": "RW",
                "longString": "Rwanda",
                "sequenceNumber": 279
            },
            {
                "refListItemId": 4790,
                "enabled": true,
                "defaultString": "RY",
                "longString": "Republic of Yemen",
                "sequenceNumber": 280
            },
            {
                "refListItemId": 4791,
                "enabled": true,
                "defaultString": "SA",
                "longString": "Sierre Leone/Sierra Leone",
                "sequenceNumber": 281
            },
            {
                "refListItemId": 4792,
                "enabled": true,
                "defaultString": "SB",
                "longString": "Saudi Arabia",
                "sequenceNumber": 282
            },
            {
                "refListItemId": 4793,
                "enabled": true,
                "defaultString": "SC",
                "longString": "South Carolina",
                "sequenceNumber": 283
            },
            {
                "refListItemId": 4794,
                "enabled": true,
                "defaultString": "SD",
                "longString": "South Dakota",
                "sequenceNumber": 284
            },
            {
                "refListItemId": 4795,
                "enabled": true,
                "defaultString": "SE",
                "longString": "Seychelles",
                "sequenceNumber": 285
            },
            {
                "refListItemId": 4796,
                "enabled": true,
                "defaultString": "SF",
                "longString": "South Africa",
                "sequenceNumber": 286
            },
            {
                "refListItemId": 4797,
                "enabled": true,
                "defaultString": "SG",
                "longString": "Senegal",
                "sequenceNumber": 287
            },
            {
                "refListItemId": 4798,
                "enabled": true,
                "defaultString": "SH",
                "longString": "San Marino",
                "sequenceNumber": 288
            },
            {
                "refListItemId": 4799,
                "enabled": true,
                "defaultString": "SI",
                "longString": "Sinaloa (MM)",
                "sequenceNumber": 289
            },
            {
                "refListItemId": 4800,
                "enabled": true,
                "defaultString": "SJ",
                "longString": "Namibia (South-West Africa)",
                "sequenceNumber": 290
            },
            {
                "refListItemId": 4801,
                "enabled": true,
                "defaultString": "SL",
                "longString": "San Luis Potosi (MM)",
                "sequenceNumber": 291
            },
            {
                "refListItemId": 4802,
                "enabled": true,
                "defaultString": "SM",
                "longString": "Somalia",
                "sequenceNumber": 292
            },
            {
                "refListItemId": 4803,
                "enabled": true,
                "defaultString": "SN",
                "longString": "Saskatchewan (CD)",
                "sequenceNumber": 293
            },
            {
                "refListItemId": 4804,
                "enabled": true,
                "defaultString": "SO",
                "longString": "Sonora (MM)",
                "sequenceNumber": 294
            },
            {
                "refListItemId": 4805,
                "enabled": true,
                "defaultString": "SP",
                "longString": "Spain",
                "sequenceNumber": 295
            },
            {
                "refListItemId": 4806,
                "enabled": true,
                "defaultString": "SQ",
                "longString": "Sweden",
                "sequenceNumber": 296
            },
            {
                "refListItemId": 4807,
                "enabled": true,
                "defaultString": "SR",
                "longString": "Singapore",
                "sequenceNumber": 297
            },
            {
                "refListItemId": 4808,
                "enabled": true,
                "defaultString": "SS",
                "longString": "Scotland",
                "sequenceNumber": 298
            },
            {
                "refListItemId": 4809,
                "enabled": false,
                "defaultString": "ST",
                "longString": "Southern Yemen",
                "sequenceNumber": 299
            },
            {
                "refListItemId": 4810,
                "enabled": true,
                "defaultString": "SU",
                "longString": "Sudan",
                "sequenceNumber": 300
            },
            {
                "refListItemId": 4811,
                "enabled": true,
                "defaultString": "SV",
                "longString": "Svalbard (Norweigan territory)",
                "sequenceNumber": 301
            },
            {
                "refListItemId": 4812,
                "enabled": true,
                "defaultString": "SW",
                "longString": "Swaziland",
                "sequenceNumber": 302
            },
            {
                "refListItemId": 4813,
                "enabled": false,
                "defaultString": "SX",
                "longString": "Soviet Union (USSR)",
                "sequenceNumber": 303
            },
            {
                "refListItemId": 4814,
                "enabled": true,
                "defaultString": "SY",
                "longString": "Syria",
                "sequenceNumber": 304
            },
            {
                "refListItemId": 4815,
                "enabled": true,
                "defaultString": "SZ",
                "longString": "Switzerland",
                "sequenceNumber": 305
            },
            {
                "refListItemId": 4816,
                "enabled": true,
                "defaultString": "TA",
                "longString": "Tamaulipas (MM)",
                "sequenceNumber": 306
            },
            {
                "refListItemId": 4817,
                "enabled": true,
                "defaultString": "TB",
                "longString": "Tabasco (MM)",
                "sequenceNumber": 307
            },
            {
                "refListItemId": 4818,
                "enabled": true,
                "defaultString": "TC",
                "longString": "United Arab Emirates",
                "sequenceNumber": 308
            },
            {
                "refListItemId": 4819,
                "enabled": true,
                "defaultString": "TD",
                "longString": "Trust Territory of the Pacific Islands",
                "sequenceNumber": 309
            },
            {
                "refListItemId": 4820,
                "enabled": true,
                "defaultString": "TE",
                "longString": "Spratly Islands",
                "sequenceNumber": 310
            },
            {
                "refListItemId": 4821,
                "enabled": true,
                "defaultString": "TF",
                "longString": "Tuamotu Archipelago",
                "sequenceNumber": 311
            },
            {
                "refListItemId": 4822,
                "enabled": true,
                "defaultString": "TG",
                "longString": "Tonga",
                "sequenceNumber": 312
            },
            {
                "refListItemId": 4823,
                "enabled": true,
                "defaultString": "TH",
                "longString": "Thailand",
                "sequenceNumber": 313
            },
            {
                "refListItemId": 4824,
                "enabled": true,
                "defaultString": "TJ",
                "longString": "Tajikistan",
                "sequenceNumber": 314
            },
            {
                "refListItemId": 4825,
                "enabled": true,
                "defaultString": "TK",
                "longString": "Tokelau (New Zealand territory)",
                "sequenceNumber": 315
            },
            {
                "refListItemId": 4826,
                "enabled": true,
                "defaultString": "TL",
                "longString": "Tlaxcala (MM)",
                "sequenceNumber": 316
            },
            {
                "refListItemId": 4827,
                "enabled": true,
                "defaultString": "TM",
                "longString": "Tromelin Island (French possession)",
                "sequenceNumber": 317
            },
            {
                "refListItemId": 4828,
                "enabled": true,
                "defaultString": "TN",
                "longString": "Tennessee",
                "sequenceNumber": 318
            },
            {
                "refListItemId": 4829,
                "enabled": true,
                "defaultString": "TO",
                "longString": "Togo",
                "sequenceNumber": 319
            },
            {
                "refListItemId": 4830,
                "enabled": true,
                "defaultString": "TP",
                "longString": "Sao Tome and Principe",
                "sequenceNumber": 320
            },
            {
                "refListItemId": 4831,
                "enabled": true,
                "defaultString": "TQ",
                "longString": "Tongareva",
                "sequenceNumber": 321
            },
            {
                "refListItemId": 4832,
                "enabled": true,
                "defaultString": "TR",
                "longString": "Turks and Caicos Islands",
                "sequenceNumber": 322
            },
            {
                "refListItemId": 4833,
                "enabled": true,
                "defaultString": "TS",
                "longString": "Nevis and Saint Christopher",
                "sequenceNumber": 323
            },
            {
                "refListItemId": 4834,
                "enabled": true,
                "defaultString": "TT",
                "longString": "Trinidad and Tobago",
                "sequenceNumber": 324
            },
            {
                "refListItemId": 4835,
                "enabled": true,
                "defaultString": "TU",
                "longString": "Tunisia",
                "sequenceNumber": 325
            },
            {
                "refListItemId": 4836,
                "enabled": true,
                "defaultString": "TV",
                "longString": "Tuvalu",
                "sequenceNumber": 326
            },
            {
                "refListItemId": 4837,
                "enabled": true,
                "defaultString": "TW",
                "longString": "Taiwan",
                "sequenceNumber": 327
            },
            {
                "refListItemId": 4838,
                "enabled": true,
                "defaultString": "TX",
                "longString": "Texas",
                "sequenceNumber": 328
            },
            {
                "refListItemId": 4839,
                "enabled": true,
                "defaultString": "TY",
                "longString": "Turkey",
                "sequenceNumber": 329
            },
            {
                "refListItemId": 4840,
                "enabled": true,
                "defaultString": "TZ",
                "longString": "Tanzania, United Republic of",
                "sequenceNumber": 330
            },
            {
                "refListItemId": 4841,
                "enabled": true,
                "defaultString": "UG",
                "longString": "Uganda",
                "sequenceNumber": 331
            },
            {
                "refListItemId": 4842,
                "enabled": true,
                "defaultString": "UK",
                "longString": "Ukraine",
                "sequenceNumber": 332
            },
            {
                "refListItemId": 4843,
                "enabled": true,
                "defaultString": "UM",
                "longString": "Mauritius",
                "sequenceNumber": 333
            },
            {
                "refListItemId": 4844,
                "enabled": true,
                "defaultString": "UR",
                "longString": "Turkmenistan",
                "sequenceNumber": 334
            },
            {
                "refListItemId": 4845,
                "enabled": true,
                "defaultString": "US",
                "longString": "United States of America",
                "sequenceNumber": 335
            },
            {
                "refListItemId": 4846,
                "enabled": true,
                "defaultString": "UT",
                "longString": "Utah",
                "sequenceNumber": 336
            },
            {
                "refListItemId": 4847,
                "enabled": true,
                "defaultString": "UV",
                "longString": "Burkina Faso",
                "sequenceNumber": 337
            },
            {
                "refListItemId": 4848,
                "enabled": true,
                "defaultString": "UY",
                "longString": "Uruguay",
                "sequenceNumber": 338
            },
            {
                "refListItemId": 4849,
                "enabled": true,
                "defaultString": "UZ",
                "longString": "Uzbekistan, Republic of",
                "sequenceNumber": 339
            },
            {
                "refListItemId": 4850,
                "enabled": true,
                "defaultString": "VA",
                "longString": "Virginia",
                "sequenceNumber": 340
            },
            {
                "refListItemId": 4851,
                "enabled": true,
                "defaultString": "VB",
                "longString": "British Virgin Islands",
                "sequenceNumber": 341
            },
            {
                "refListItemId": 4852,
                "enabled": true,
                "defaultString": "VC",
                "longString": "Veracruz (MM)",
                "sequenceNumber": 342
            },
            {
                "refListItemId": 4853,
                "enabled": true,
                "defaultString": "VL",
                "longString": "Navassa Island",
                "sequenceNumber": 343
            },
            {
                "refListItemId": 4854,
                "enabled": true,
                "defaultString": "VT",
                "longString": "Vermont",
                "sequenceNumber": 344
            },
            {
                "refListItemId": 4855,
                "enabled": true,
                "defaultString": "VV",
                "longString": "Saint Vincent and the Grenadines",
                "sequenceNumber": 345
            },
            {
                "refListItemId": 4856,
                "enabled": true,
                "defaultString": "VY",
                "longString": "Vatican City",
                "sequenceNumber": 346
            },
            {
                "refListItemId": 4857,
                "enabled": true,
                "defaultString": "VZ",
                "longString": "Venezuela, Republic of",
                "sequenceNumber": 347
            },
            {
                "refListItemId": 4858,
                "enabled": true,
                "defaultString": "WA",
                "longString": "Washington",
                "sequenceNumber": 348
            },
            {
                "refListItemId": 4859,
                "enabled": true,
                "defaultString": "WB",
                "longString": "West Bank",
                "sequenceNumber": 349
            },
            {
                "refListItemId": 4860,
                "enabled": true,
                "defaultString": "WF",
                "longString": "Wallis and Futuna, Territory of the",
                "sequenceNumber": 350
            },
            {
                "refListItemId": 4861,
                "enabled": false,
                "defaultString": "WG",
                "longString": "West Germany",
                "sequenceNumber": 351
            },
            {
                "refListItemId": 4862,
                "enabled": true,
                "defaultString": "WI",
                "longString": "Wisconsin",
                "sequenceNumber": 352
            },
            {
                "refListItemId": 4863,
                "enabled": true,
                "defaultString": "WL",
                "longString": "Wales",
                "sequenceNumber": 353
            },
            {
                "refListItemId": 4864,
                "enabled": true,
                "defaultString": "WN",
                "longString": "West Indies",
                "sequenceNumber": 354
            },
            {
                "refListItemId": 4865,
                "enabled": true,
                "defaultString": "WS",
                "longString": "Western Samoa",
                "sequenceNumber": 355
            },
            {
                "refListItemId": 4866,
                "enabled": true,
                "defaultString": "WV",
                "longString": "West Virginia",
                "sequenceNumber": 356
            },
            {
                "refListItemId": 4867,
                "enabled": true,
                "defaultString": "WY",
                "longString": "Wyoming",
                "sequenceNumber": 357
            },
            {
                "refListItemId": 4868,
                "enabled": true,
                "defaultString": "XX",
                "longString": "Unknown Place of Birth",
                "sequenceNumber": 358
            },
            {
                "refListItemId": 4869,
                "enabled": false,
                "defaultString": "YE",
                "longString": "Yemen Arab Republic",
                "sequenceNumber": 359
            },
            {
                "refListItemId": 4870,
                "enabled": true,
                "defaultString": "YG",
                "longString": "Yugoslavia",
                "sequenceNumber": 360
            },
            {
                "refListItemId": 4871,
                "enabled": true,
                "defaultString": "YO",
                "longString": "Mayotte",
                "sequenceNumber": 361
            },
            {
                "refListItemId": 4872,
                "enabled": true,
                "defaultString": "YT",
                "longString": "Yukon Territory (CD)",
                "sequenceNumber": 362
            },
            {
                "refListItemId": 4873,
                "enabled": true,
                "defaultString": "YU",
                "longString": "Yucatan (MM)",
                "sequenceNumber": 363
            },
            {
                "refListItemId": 4874,
                "enabled": true,
                "defaultString": "YY",
                "longString": "All others",
                "sequenceNumber": 364
            },
            {
                "refListItemId": 4875,
                "enabled": true,
                "defaultString": "ZA",
                "longString": "Zacatecas (MM)",
                "sequenceNumber": 365
            },
            {
                "refListItemId": 4876,
                "enabled": true,
                "defaultString": "ZB",
                "longString": "Martinique",
                "sequenceNumber": 366
            },
            {
                "refListItemId": 4877,
                "enabled": true,
                "defaultString": "ZC",
                "longString": "Surinam",
                "sequenceNumber": 367
            },
            {
                "refListItemId": 4878,
                "enabled": true,
                "defaultString": "ZD",
                "longString": "Macedonia",
                "sequenceNumber": 368
            },
            {
                "refListItemId": 4879,
                "enabled": true,
                "defaultString": "ZI",
                "longString": "Canary Islands",
                "sequenceNumber": 369
            },
            {
                "refListItemId": 4880,
                "enabled": true,
                "defaultString": "ZM",
                "longString": "Zambia, Republic of",
                "sequenceNumber": 370
            },
            {
                "refListItemId": 4881,
                "enabled": true,
                "defaultString": "ZO",
                "longString": "Mozambique",
                "sequenceNumber": 371
            },
            {
                "refListItemId": 4882,
                "enabled": true,
                "defaultString": "ZR",
                "longString": "Zaire",
                "sequenceNumber": 372
            },
            {
                "refListItemId": 4967,
                "enabled": true,
                "defaultString": "VI",
                "longString": "Virgin Islands",
                "sequenceNumber": 373
            },
            {
                "refListItemId": 4968,
                "enabled": true,
                "defaultString": "BF",
                "longString": "Bassas Da India",
                "sequenceNumber": 374
            },
            {
                "refListItemId": 4969,
                "enabled": true,
                "defaultString": "JI",
                "longString": "Johnson Atoll",
                "sequenceNumber": 375
            },
            {
                "refListItemId": 4970,
                "enabled": true,
                "defaultString": "JR",
                "longString": "Jarvis Island",
                "sequenceNumber": 376
            },
            {
                "refListItemId": 4971,
                "enabled": true,
                "defaultString": "KI",
                "longString": "Kingman Reef",
                "sequenceNumber": 377
            },
            {
                "refListItemId": 4972,
                "enabled": true,
                "defaultString": "PR",
                "longString": "Puerto Rico",
                "sequenceNumber": 378
            },
            {
                "refListItemId": 4973,
                "enabled": true,
                "defaultString": "WK",
                "longString": "Wake Island",
                "sequenceNumber": 379
            }
        ]
    },
    {
        "RefListID": 553,
        "refListName": "UCRPremiseType",
        "refListItems": [
            {
                "refListItemId": 16069,
                "enabled": true,
                "defaultString": "Bank",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16070,
                "enabled": true,
                "defaultString": "Commercial",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16071,
                "enabled": true,
                "defaultString": "Convenience Store",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16072,
                "enabled": true,
                "defaultString": "Highway",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16073,
                "enabled": true,
                "defaultString": "Residence",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 16074,
                "enabled": true,
                "defaultString": "Service Station",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 16075,
                "enabled": true,
                "defaultString": "School/Campus",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 16076,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 453,
        "refListName": "YesNoUnknownBig",
        "refListItems": [
            {
                "refListItemId": 4892,
                "enabled": true,
                "defaultString": "Yes",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4893,
                "enabled": true,
                "defaultString": "No",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4894,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 454,
        "refListName": "NumberComplaint",
        "refListItems": [
            {
                "refListItemId": 4895,
                "enabled": true,
                "defaultString": "0",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4896,
                "enabled": true,
                "defaultString": "1-5",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4897,
                "enabled": true,
                "defaultString": "6-10",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4898,
                "enabled": true,
                "defaultString": "MORE THAN 10",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4899,
                "enabled": true,
                "defaultString": "UNKNOWN",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 455,
        "refListName": "PropertyTypeGA",
        "refListItems": [
            {
                "refListItemId": 4900,
                "enabled": true,
                "defaultString": "Clothing",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4901,
                "enabled": true,
                "defaultString": "Consumable Goods",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4902,
                "enabled": true,
                "defaultString": "Currency, Notes,etc.",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4903,
                "enabled": true,
                "defaultString": "Firearms",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4904,
                "enabled": true,
                "defaultString": "Furs",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4905,
                "enabled": true,
                "defaultString": "Household Goods",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4906,
                "enabled": true,
                "defaultString": "Jewelry,Prec.Metals",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4907,
                "enabled": true,
                "defaultString": "Livestock",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4908,
                "enabled": true,
                "defaultString": "Office Equip",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4909,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4910,
                "enabled": true,
                "defaultString": "TV,Radio,etc.",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4911,
                "enabled": true,
                "defaultString": "Vehicles",
                "longString": "",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 456,
        "refListName": "Monuments",
        "refListItems": [
            {
                "refListItemId": 4912,
                "enabled": true,
                "defaultString": "Confederate Monument",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4913,
                "enabled": true,
                "defaultString": "Spanish-American War Monument",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4914,
                "enabled": true,
                "defaultString": "Gun from the Battleship Maine",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4915,
                "enabled": true,
                "defaultString": "Jefferson Davis Highway Marker",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4916,
                "enabled": true,
                "defaultString": "James F. Brynes Monument",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4917,
                "enabled": true,
                "defaultString": "Revolutionary War Generals Monument",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4918,
                "enabled": true,
                "defaultString": "Time Capsule",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4919,
                "enabled": true,
                "defaultString": "African American History Monument",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4920,
                "enabled": true,
                "defaultString": "General Wade Hampton Monument",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4921,
                "enabled": true,
                "defaultString": "Liberty Bell Replica",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4922,
                "enabled": true,
                "defaultString": "Confederate Womens Monument",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4923,
                "enabled": true,
                "defaultString": "Capitol Complex McNair Monument",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4924,
                "enabled": true,
                "defaultString": "Strom Thurmond Monument",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4925,
                "enabled": true,
                "defaultString": "Richardson Monument",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4926,
                "enabled": true,
                "defaultString": "Grave of Captain Lunsford",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4927,
                "enabled": true,
                "defaultString": "Dr. J. Marion Sims Monument",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4928,
                "enabled": true,
                "defaultString": "Benjamin Ryan Tillman Monument",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4929,
                "enabled": true,
                "defaultString": "Old Statehouse Marker",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4930,
                "enabled": true,
                "defaultString": "Palmetto Regiment Monument",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4931,
                "enabled": true,
                "defaultString": "George Washington Statue",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4932,
                "enabled": true,
                "defaultString": "Law Enforcement Monument",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 4933,
                "enabled": true,
                "defaultString": "Veterans Monument",
                "sequenceNumber": 22
            }
        ]
    },
    {
        "RefListID": 572,
        "refListName": "Gang_Type",
        "refListItems": [
            {
                "refListItemId": 19891,
                "enabled": true,
                "defaultString": "Gangster Disciples"
            },
            {
                "refListItemId": 16196,
                "enabled": true,
                "defaultString": "Bloods",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16197,
                "enabled": true,
                "defaultString": "Crips",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16198,
                "enabled": true,
                "defaultString": "Italian Mafia",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16199,
                "enabled": true,
                "defaultString": "Mexican Mafia",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16200,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 458,
        "refListName": "NIBRS_Approval",
        "refListItems": [
            {
                "refListItemId": 4957,
                "enabled": true,
                "defaultString": "Not Reviewed",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4958,
                "enabled": true,
                "defaultString": "Approved",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4959,
                "enabled": true,
                "defaultString": "Not Approved",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 459,
        "refListName": "District_Justice",
        "refListItems": [
            {
                "refListItemId": 4960,
                "enabled": true,
                "defaultString": "Ralph",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 460,
        "refListName": "Case_Status",
        "refListItems": [
            {
                "refListItemId": 4962,
                "enabled": true,
                "defaultString": "Open",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4963,
                "enabled": true,
                "defaultString": "Closed",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 461,
        "refListName": "Address_City",
        "refListItems": [
            {
                "refListItemId": 4974,
                "enabled": true,
                "defaultString": "OTHER",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 502,
        "refListName": "AnokaReferredToRN",
        "refListItems": [
            {
                "refListItemId": 6999,
                "enabled": true,
                "defaultString": "N/A",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7000,
                "enabled": true,
                "defaultString": "Routine",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7001,
                "enabled": true,
                "defaultString": "Urgent",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7002,
                "enabled": true,
                "defaultString": "Emergency",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 503,
        "refListName": "AnokaTransportedTo",
        "refListItems": [
            {
                "refListItemId": 7003,
                "enabled": true,
                "defaultString": "N/A",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7004,
                "enabled": true,
                "defaultString": "Clinic",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7005,
                "enabled": true,
                "defaultString": "Urgent Care",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7006,
                "enabled": true,
                "defaultString": "ER",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 464,
        "refListName": "Class_Abuse",
        "refListItems": [
            {
                "refListItemId": 5000,
                "enabled": true,
                "defaultString": "Abuse ==> assaultive behavior",
                "longString": "Abuse resulting in assaultive behavior",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5001,
                "enabled": true,
                "defaultString": "Abuse ==> social, economic, or legal problems",
                "longString": "Abuse resulting in social, economic, or legal problems",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5002,
                "enabled": true,
                "defaultString": "No social, economic, or legal problems related to abuse",
                "longString": "No social, economic, or legal problems related to abuse",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 465,
        "refListName": "Class_Custody_Level",
        "refListItems": [
            {
                "refListItemId": 5003,
                "enabled": true,
                "defaultString": "1 Minimum",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5004,
                "enabled": true,
                "defaultString": "2 Medium",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5005,
                "enabled": true,
                "defaultString": "3 Maximum",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 466,
        "refListName": "Class_Discipline",
        "refListItems": [
            {
                "refListItemId": 4995,
                "enabled": true,
                "defaultString": "None/minor / no segregation time",
                "longString": "None or minor with no segregation time",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4996,
                "enabled": true,
                "defaultString": "One/more major repts / segregation time",
                "longString": "One or more major disciplinary reports and/or time in segregation",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 467,
        "refListName": "Class_Escape",
        "refListItems": [
            {
                "refListItemId": 4991,
                "enabled": true,
                "defaultString": "Failure to return from authorized absence",
                "longString": "Failure to return from authorized absence",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4992,
                "enabled": true,
                "defaultString": "MED / MAX security escape or attempted escape",
                "longString": "Escape or attempted escape from MEDIUM or MAXIMUM security setting",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4993,
                "enabled": true,
                "defaultString": "MIN Security walkaway or attempted escape",
                "longString": "Walkaway or attempted escape from minimum security facility",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4994,
                "enabled": true,
                "defaultString": "No escape or attempts",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 468,
        "refListName": "Class_Priors",
        "refListItems": [
            {
                "refListItemId": 4997,
                "enabled": true,
                "defaultString": "None",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4998,
                "enabled": true,
                "defaultString": "One",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4999,
                "enabled": true,
                "defaultString": "Two or more",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 469,
        "refListName": "Class_Severity_Current",
        "refListItems": [
            {
                "refListItemId": 4983,
                "enabled": true,
                "defaultString": "High",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4984,
                "enabled": true,
                "defaultString": "Highest",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4985,
                "enabled": true,
                "defaultString": "Low",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4986,
                "enabled": true,
                "defaultString": "Moderate",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 470,
        "refListName": "Class_Severity_History",
        "refListItems": [
            {
                "refListItemId": 4987,
                "enabled": true,
                "defaultString": "High",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4988,
                "enabled": true,
                "defaultString": "Highest",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4989,
                "enabled": true,
                "defaultString": "Low or none",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4990,
                "enabled": true,
                "defaultString": "Moderate",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 471,
        "refListName": "Class_Supv_Approval",
        "refListItems": [
            {
                "refListItemId": 5006,
                "enabled": true,
                "defaultString": "Approved",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5007,
                "enabled": true,
                "defaultString": "Disapproved",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 472,
        "refListName": "State_Statute",
        "refListItems": [
            {
                "refListItemId": 5008,
                "enabled": true,
                "defaultString": "Statute 1 {3803} [013A-13-0005]",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5009,
                "enabled": true,
                "defaultString": "Statute 2 {9999} [013A-11-0013]",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 473,
        "refListName": "AnokaCommitmentAgency",
        "refListItems": [
            {
                "refListItemId": 5010,
                "enabled": true,
                "defaultString": "State Work Release",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5011,
                "enabled": true,
                "defaultString": "US Marshal",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5012,
                "enabled": true,
                "defaultString": "INS",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5013,
                "enabled": true,
                "defaultString": "Federal",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24958,
                "enabled": true,
                "defaultString": "Boarding"
            },
            {
                "refListItemId": 25015,
                "enabled": true,
                "defaultString": "NDSP"
            },
            {
                "refListItemId": 25016,
                "enabled": true,
                "defaultString": "NDSP (ICC)"
            },
            {
                "refListItemId": 15437,
                "enabled": true,
                "defaultString": "Short-term Offender",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 15438,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 474,
        "refListName": "AnokaShift",
        "refListItems": [
            {
                "refListItemId": 5014,
                "enabled": true,
                "defaultString": "First",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5015,
                "enabled": true,
                "defaultString": "Second",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5016,
                "enabled": true,
                "defaultString": "Third",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 475,
        "refListName": "AnokaClassifications",
        "refListItems": [
            {
                "refListItemId": 5017,
                "enabled": true,
                "defaultString": "Maximum",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5018,
                "enabled": true,
                "defaultString": "Minimum",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5019,
                "enabled": true,
                "defaultString": "Medium",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 476,
        "refListName": "Sentencing_County",
        "refListItems": [
            {
                "refListItemId": 5119,
                "enabled": true,
                "defaultString": "71 Sherburne",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 5120,
                "enabled": true,
                "defaultString": "72 Sibley",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 5121,
                "enabled": true,
                "defaultString": "69 St. Louis",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 5122,
                "enabled": true,
                "defaultString": "73 Stearns",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 5123,
                "enabled": true,
                "defaultString": "74 Steele",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 5124,
                "enabled": true,
                "defaultString": "75 Stevens",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 5125,
                "enabled": true,
                "defaultString": "76 Swift",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 5126,
                "enabled": true,
                "defaultString": "77 Todd",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 5127,
                "enabled": true,
                "defaultString": "78 Traverse",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 5128,
                "enabled": true,
                "defaultString": "79 Wabasha",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 5129,
                "enabled": true,
                "defaultString": "80 Wadena",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 5130,
                "enabled": true,
                "defaultString": "81 Waseca",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 5131,
                "enabled": true,
                "defaultString": "82 Washington",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 5132,
                "enabled": true,
                "defaultString": "83 Watonwan",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 5133,
                "enabled": true,
                "defaultString": "84 Wilkin",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 5134,
                "enabled": true,
                "defaultString": "85 Winona",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 5135,
                "enabled": true,
                "defaultString": "86 Wright",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 5136,
                "enabled": true,
                "defaultString": "87 Yellow Medicine",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 5137,
                "enabled": true,
                "defaultString": "89 OUT-OF-COUNTRY",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 5138,
                "enabled": true,
                "defaultString": "90 OUT-OF-STATE",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 5139,
                "enabled": true,
                "defaultString": "91 UNKNOWN",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 5049,
                "enabled": true,
                "defaultString": "01 Aitkin",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5050,
                "enabled": true,
                "defaultString": "02 Anoka",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5051,
                "enabled": true,
                "defaultString": "03 Becker",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5052,
                "enabled": true,
                "defaultString": "04 Beltrami",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5053,
                "enabled": true,
                "defaultString": "05 Benton",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5054,
                "enabled": true,
                "defaultString": "06 Bigstone",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5055,
                "enabled": true,
                "defaultString": "07 Blue Earth",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5056,
                "enabled": true,
                "defaultString": "08 Brown",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5057,
                "enabled": true,
                "defaultString": "09 Carlton",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5058,
                "enabled": true,
                "defaultString": "10 Carver",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5059,
                "enabled": true,
                "defaultString": "11 Cass",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5060,
                "enabled": true,
                "defaultString": "12 Chippewa",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5061,
                "enabled": true,
                "defaultString": "13 Chisago",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5062,
                "enabled": true,
                "defaultString": "14 Clay",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5063,
                "enabled": true,
                "defaultString": "15 Clearwater",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5064,
                "enabled": true,
                "defaultString": "16 Cook",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5065,
                "enabled": true,
                "defaultString": "17 Cottonwood",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5066,
                "enabled": true,
                "defaultString": "18 Crow Wing",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5067,
                "enabled": true,
                "defaultString": "19 Dakota",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5068,
                "enabled": true,
                "defaultString": "20 Dodge",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5069,
                "enabled": true,
                "defaultString": "21 Douglas",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5070,
                "enabled": true,
                "defaultString": "22 Faribault",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5071,
                "enabled": true,
                "defaultString": "23 Fillmore",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5072,
                "enabled": true,
                "defaultString": "24 Freeborn",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5073,
                "enabled": true,
                "defaultString": "25 Goodhue",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5074,
                "enabled": true,
                "defaultString": "26 Grant",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5075,
                "enabled": true,
                "defaultString": "27 Hennepin",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5076,
                "enabled": true,
                "defaultString": "28 Houston",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5077,
                "enabled": true,
                "defaultString": "29 Hubbard",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 5078,
                "enabled": true,
                "defaultString": "30 Isanti",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5079,
                "enabled": true,
                "defaultString": "31 Itasca",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5080,
                "enabled": true,
                "defaultString": "32 Jackson",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 5081,
                "enabled": true,
                "defaultString": "33 Kanabec",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 5082,
                "enabled": true,
                "defaultString": "34 Kandiyohi",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 5083,
                "enabled": true,
                "defaultString": "35 Kittson",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 5084,
                "enabled": true,
                "defaultString": "36 Koochiching",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 5085,
                "enabled": true,
                "defaultString": "37 Lac Qui Parle",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 5086,
                "enabled": true,
                "defaultString": "38 Lake",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 5087,
                "enabled": true,
                "defaultString": "39 Lake Of The Woods",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 5088,
                "enabled": true,
                "defaultString": "40 Le Sueur",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 5089,
                "enabled": true,
                "defaultString": "41 Lincoln",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 5090,
                "enabled": true,
                "defaultString": "42 Lyon",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 5091,
                "enabled": true,
                "defaultString": "44 Mahnomen",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 5092,
                "enabled": true,
                "defaultString": "45 Marshall",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 5093,
                "enabled": true,
                "defaultString": "46 Martin",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 5094,
                "enabled": true,
                "defaultString": "43 McLeod",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 5095,
                "enabled": true,
                "defaultString": "47 Meeker",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 5096,
                "enabled": true,
                "defaultString": "48 Mille Lacs",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 5097,
                "enabled": true,
                "defaultString": "49 Morrison",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 5098,
                "enabled": true,
                "defaultString": "50 Mower",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 5099,
                "enabled": true,
                "defaultString": "51 Murray",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 5100,
                "enabled": true,
                "defaultString": "52 Nicollet",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 5101,
                "enabled": true,
                "defaultString": "53 Nobles",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 5102,
                "enabled": false,
                "defaultString": "88 Non-Minnesota Cty.",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 5103,
                "enabled": true,
                "defaultString": "54 Norman",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 5104,
                "enabled": true,
                "defaultString": "55 Olmsted",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 5105,
                "enabled": true,
                "defaultString": "56 Otter Tail",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 5106,
                "enabled": true,
                "defaultString": "57 Pennington",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 5107,
                "enabled": true,
                "defaultString": "58 Pine",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 5108,
                "enabled": true,
                "defaultString": "59 Pipestone",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 5109,
                "enabled": true,
                "defaultString": "60 Polk",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 5110,
                "enabled": true,
                "defaultString": "61 Pope",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 5111,
                "enabled": true,
                "defaultString": "62 Ramsey",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 5112,
                "enabled": true,
                "defaultString": "63 Red Lake",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 5113,
                "enabled": true,
                "defaultString": "64 Redwood",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 5114,
                "enabled": true,
                "defaultString": "65 Renville",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 5115,
                "enabled": true,
                "defaultString": "66 Rice",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 5116,
                "enabled": true,
                "defaultString": "67 Rock",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 5117,
                "enabled": true,
                "defaultString": "68 Roseau",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 5118,
                "enabled": true,
                "defaultString": "70 Scott",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 24401,
                "enabled": true,
                "defaultString": "92 Marion"
            }
        ]
    },
    {
        "RefListID": 638,
        "refListName": "Hold_Reason",
        "refListItems": [
            {
                "refListItemId": 19894,
                "enabled": true,
                "defaultString": "Writ",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 19895,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 19896,
                "enabled": true,
                "defaultString": "Dept. of Corr.",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 19897,
                "enabled": true,
                "defaultString": "Other County",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 19898,
                "enabled": true,
                "defaultString": "Fed. Agency",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 19899,
                "enabled": true,
                "defaultString": "State Agency",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 19900,
                "enabled": true,
                "defaultString": "Probation",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 19901,
                "enabled": true,
                "defaultString": "Out of State",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 478,
        "refListName": "Court",
        "refListItems": [
            {
                "refListItemId": 5148,
                "enabled": false,
                "defaultString": "Federal",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5149,
                "enabled": true,
                "defaultString": "Juvenile",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 5150,
                "enabled": false,
                "defaultString": "INS",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5151,
                "enabled": false,
                "defaultString": "US Marshal",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5152,
                "enabled": false,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5153,
                "enabled": false,
                "defaultString": "Probate",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 5154,
                "enabled": false,
                "defaultString": "State",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5155,
                "enabled": false,
                "defaultString": "Superior",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 5156,
                "enabled": true,
                "defaultString": "Family",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 5157,
                "enabled": false,
                "defaultString": "Paterson",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5158,
                "enabled": false,
                "defaultString": "Passaic",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5159,
                "enabled": false,
                "defaultString": "Clifton",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5160,
                "enabled": false,
                "defaultString": "Wayne",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5161,
                "enabled": false,
                "defaultString": "Little Falls",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5162,
                "enabled": false,
                "defaultString": "Pompton Lakes",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5163,
                "enabled": false,
                "defaultString": "Ringwood",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5164,
                "enabled": false,
                "defaultString": "Bloomingdale",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5165,
                "enabled": false,
                "defaultString": "Wanaque",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5166,
                "enabled": false,
                "defaultString": "W Milford",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5167,
                "enabled": false,
                "defaultString": "W Paterson",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5168,
                "enabled": false,
                "defaultString": "Totowa",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5169,
                "enabled": false,
                "defaultString": "Haledon",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5170,
                "enabled": false,
                "defaultString": "Prospect Park",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5171,
                "enabled": false,
                "defaultString": "N Haledon",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5172,
                "enabled": false,
                "defaultString": "Hawthorne",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5173,
                "enabled": false,
                "defaultString": "Writ",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5419,
                "enabled": false,
                "defaultString": "Conciliation",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5420,
                "enabled": false,
                "defaultString": "District",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5421,
                "enabled": false,
                "defaultString": "United States Court",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 5422,
                "enabled": false,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 24397,
                "enabled": false,
                "defaultString": "Salem Municipal"
            },
            {
                "refListItemId": 24398,
                "enabled": false,
                "defaultString": "East Marion Justice"
            },
            {
                "refListItemId": 24399,
                "enabled": false,
                "defaultString": "North Marion Justice"
            },
            {
                "refListItemId": 24409,
                "enabled": true,
                "defaultString": "Circuit"
            },
            {
                "refListItemId": 24410,
                "enabled": true,
                "defaultString": "28 North Clark"
            },
            {
                "refListItemId": 24411,
                "enabled": true,
                "defaultString": "District 2"
            },
            {
                "refListItemId": 24412,
                "enabled": true,
                "defaultString": "District 3"
            },
            {
                "refListItemId": 24413,
                "enabled": true,
                "defaultString": "District 4"
            },
            {
                "refListItemId": 24414,
                "enabled": true,
                "defaultString": "District 5"
            },
            {
                "refListItemId": 24415,
                "enabled": true,
                "defaultString": "District 6"
            }
        ]
    },
    {
        "RefListID": 479,
        "refListName": "Trustee_Assignment",
        "refListItems": [
            {
                "refListItemId": 5174,
                "enabled": true,
                "defaultString": "Trustee",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5175,
                "enabled": true,
                "defaultString": "Laundry",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5176,
                "enabled": true,
                "defaultString": "Commissary",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5177,
                "enabled": true,
                "defaultString": "Law Library",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5178,
                "enabled": true,
                "defaultString": "Weight Gym",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 480,
        "refListName": "Loan_Type",
        "refListItems": [
            {
                "refListItemId": 5195,
                "enabled": true,
                "defaultString": "Work Crew",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5196,
                "enabled": true,
                "defaultString": "Job",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5197,
                "enabled": false,
                "defaultString": "UA",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5191,
                "enabled": false,
                "defaultString": "Fingerprinting",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5192,
                "enabled": true,
                "defaultString": "Program",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5193,
                "enabled": false,
                "defaultString": "Facility",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5194,
                "enabled": true,
                "defaultString": "Activity Request",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5185,
                "enabled": false,
                "defaultString": "Conditional Release",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5186,
                "enabled": true,
                "defaultString": "Court",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5187,
                "enabled": true,
                "defaultString": "Medical",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5188,
                "enabled": false,
                "defaultString": "Personal Emergency",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5189,
                "enabled": false,
                "defaultString": "Recreation",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5190,
                "enabled": false,
                "defaultString": "Video Conference",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 15436,
                "enabled": true,
                "defaultString": "Visiting",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 15518,
                "enabled": false,
                "defaultString": "Law Library",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 15519,
                "enabled": false,
                "defaultString": "Library",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 15520,
                "enabled": false,
                "defaultString": "Rec",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 15521,
                "enabled": false,
                "defaultString": "AA",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 15522,
                "enabled": false,
                "defaultString": "NA",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 15523,
                "enabled": false,
                "defaultString": "Bible Study",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 15524,
                "enabled": false,
                "defaultString": "CD Awareness",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 15525,
                "enabled": false,
                "defaultString": "Teen Challenge",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 15526,
                "enabled": false,
                "defaultString": "Salvation Army",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 15527,
                "enabled": false,
                "defaultString": "Indiv Spirit Counsel",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 15528,
                "enabled": false,
                "defaultString": "Drawing Lab",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 15529,
                "enabled": false,
                "defaultString": "Parenting",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 15530,
                "enabled": false,
                "defaultString": "Journaling",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 15531,
                "enabled": false,
                "defaultString": "Choice and Change",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 15532,
                "enabled": false,
                "defaultString": "MRT",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 15533,
                "enabled": false,
                "defaultString": "ESL",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 15534,
                "enabled": false,
                "defaultString": "Orientation",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 15981,
                "enabled": true,
                "defaultString": "Writ",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 16309,
                "enabled": true,
                "defaultString": "Dental",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 16310,
                "enabled": true,
                "defaultString": "Furlough",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 16311,
                "enabled": true,
                "defaultString": "Hospital",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 16312,
                "enabled": true,
                "defaultString": "Religious Activities",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 16313,
                "enabled": true,
                "defaultString": "Booking",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 16314,
                "enabled": true,
                "defaultString": "Treatment",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 16315,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 16316,
                "enabled": true,
                "defaultString": "Medical/Out Patient",
                "longString": "",
                "sequenceNumber": 39
            }
        ]
    },
    {
        "RefListID": 481,
        "refListName": "VictimNotification_Method",
        "refListItems": [
            {
                "refListItemId": 5198,
                "enabled": true,
                "defaultString": "Telephone",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5199,
                "enabled": true,
                "defaultString": "Squad Car",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5200,
                "enabled": true,
                "defaultString": "Mail",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 15439,
                "enabled": true,
                "defaultString": "In Person",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 15440,
                "enabled": false,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 15441,
                "enabled": true,
                "defaultString": "Message Left",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 482,
        "refListName": "Status",
        "refListItems": [
            {
                "refListItemId": 5211,
                "enabled": true,
                "defaultString": "OUTSTANDING",
                "longString": "A",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5212,
                "enabled": true,
                "defaultString": "CLEARED",
                "longString": "C",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5213,
                "enabled": true,
                "defaultString": "HOLD",
                "longString": "H",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5214,
                "enabled": true,
                "defaultString": "WAIT FOR BODY",
                "longString": "L",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5215,
                "enabled": true,
                "defaultString": "WAIT FOR $",
                "longString": "L",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 483,
        "refListName": "PatrolZones",
        "refListItems": [
            {
                "refListItemId": 5216,
                "enabled": true,
                "defaultString": "andover bunker hills co park",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5217,
                "enabled": true,
                "defaultString": "andover bunker lake",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5218,
                "enabled": true,
                "defaultString": "andover co 9- co 7,-- 157th",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5219,
                "enabled": true,
                "defaultString": "andover co 9-co 7,157th-181st",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5220,
                "enabled": true,
                "defaultString": "andover co 9-hanson,--161st",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5221,
                "enabled": true,
                "defaultString": "andover co rd 7-rum riv",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5222,
                "enabled": true,
                "defaultString": "Andover Coon Creek",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5223,
                "enabled": true,
                "defaultString": "andover crooked lake",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5224,
                "enabled": true,
                "defaultString": "andover dwntwn ctr shppng ctr",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5225,
                "enabled": true,
                "defaultString": "andover hanson-co 9,161st-181s",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5226,
                "enabled": true,
                "defaultString": "andover hanson-univ,--161st",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5227,
                "enabled": true,
                "defaultString": "andover hanson-univ,161st-181s",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5228,
                "enabled": true,
                "defaultString": "andover round lake",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5229,
                "enabled": true,
                "defaultString": "andover rum river",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5230,
                "enabled": true,
                "defaultString": "andover ward lake",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5231,
                "enabled": true,
                "defaultString": "andover",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5232,
                "enabled": true,
                "defaultString": "anoka fairgrounds",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5233,
                "enabled": true,
                "defaultString": "anoka mississippi river",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5234,
                "enabled": true,
                "defaultString": "anoka patrol station",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5235,
                "enabled": true,
                "defaultString": "anoka rum river canoe access",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5236,
                "enabled": true,
                "defaultString": "anoka rum river",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5237,
                "enabled": true,
                "defaultString": "anoka",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5238,
                "enabled": true,
                "defaultString": "bethel whiskey road house",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5239,
                "enabled": true,
                "defaultString": "bethel",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5240,
                "enabled": true,
                "defaultString": "blaine",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5241,
                "enabled": true,
                "defaultString": "burns twin lakes co park",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5242,
                "enabled": true,
                "defaultString": "burns",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5243,
                "enabled": true,
                "defaultString": "burns,nowthen",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5244,
                "enabled": true,
                "defaultString": "centerville lake",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 5245,
                "enabled": true,
                "defaultString": "Centerville Peltier Lake",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5246,
                "enabled": true,
                "defaultString": "centerville",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5247,
                "enabled": true,
                "defaultString": "circle pines golden lake park",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 5248,
                "enabled": true,
                "defaultString": "circle pines",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 5249,
                "enabled": true,
                "defaultString": "columbia heights kordiak park",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 5250,
                "enabled": true,
                "defaultString": "columbia heights",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 5251,
                "enabled": true,
                "defaultString": "columbus broadway-197,lxgtn--",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 5252,
                "enabled": true,
                "defaultString": "columbus carlos avery wild ref",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 5253,
                "enabled": true,
                "defaultString": "columbus coon lake co park",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 5254,
                "enabled": true,
                "defaultString": "columbus coon lake",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 5255,
                "enabled": true,
                "defaultString": "columbus interstate 35w",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 5256,
                "enabled": true,
                "defaultString": "columbus waldoch collision",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 5257,
                "enabled": true,
                "defaultString": "columbus waldoch crafts",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 5258,
                "enabled": true,
                "defaultString": "columbus waldoch sports",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 5259,
                "enabled": true,
                "defaultString": "columbus",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 5260,
                "enabled": true,
                "defaultString": "Coon Lake Public Access",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 5261,
                "enabled": true,
                "defaultString": "coon rapids bunker hills park",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 5262,
                "enabled": true,
                "defaultString": "coon rapids crooked lake",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 5263,
                "enabled": true,
                "defaultString": "coon rapids mississippi river",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 5264,
                "enabled": true,
                "defaultString": "coon rapids regional dam park",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 5265,
                "enabled": true,
                "defaultString": "coon rapids",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 5266,
                "enabled": true,
                "defaultString": "Courthouse-1st Fl-Central Wing",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 5267,
                "enabled": true,
                "defaultString": "Courthouse-1st Fl-East Wing",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 5268,
                "enabled": true,
                "defaultString": "Courthouse-1st Fl-West Wing",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 5269,
                "enabled": true,
                "defaultString": "Courthouse-2nd Fl-Central Wing",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 5270,
                "enabled": true,
                "defaultString": "Courthouse-2nd Fl-East Wing",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 5271,
                "enabled": true,
                "defaultString": "Courthouse-2nd Fl-West Wing",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 5272,
                "enabled": true,
                "defaultString": "Courthouse-3rd Fl-Central Wing",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 5273,
                "enabled": true,
                "defaultString": "Courthouse-3rd Fl-East Wing",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 5274,
                "enabled": true,
                "defaultString": "Courthouse-3rd Fl-West Wing",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 5275,
                "enabled": true,
                "defaultString": "Courthouse-4th Fl-West Wing",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 5276,
                "enabled": true,
                "defaultString": "Crooked Lake Public Access",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 5277,
                "enabled": true,
                "defaultString": "east bethel coon lake",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 5278,
                "enabled": true,
                "defaultString": "East Bethel Cooper Lake",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 5279,
                "enabled": true,
                "defaultString": "east bethel cstl twrs trlr pk",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 5280,
                "enabled": true,
                "defaultString": "east bethel hidden haven",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 5281,
                "enabled": true,
                "defaultString": "east bethel hwy 65 & business",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 5282,
                "enabled": true,
                "defaultString": "east bethel john anderson park",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 5283,
                "enabled": true,
                "defaultString": "east bethel kc's bar",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 5284,
                "enabled": true,
                "defaultString": "east bethel ramblers inn",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 5285,
                "enabled": true,
                "defaultString": "east bethel village green",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 5286,
                "enabled": true,
                "defaultString": "east bethel",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 5287,
                "enabled": true,
                "defaultString": "fridley island of peace co pk",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 5288,
                "enabled": true,
                "defaultString": "fridley menomin park",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 5289,
                "enabled": true,
                "defaultString": "fridley mississippi river",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 5290,
                "enabled": true,
                "defaultString": "fridley",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 5291,
                "enabled": true,
                "defaultString": "Gov't. Center - 1st Floor",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 5292,
                "enabled": true,
                "defaultString": "Gov't. Center - 2nd Floor",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 5293,
                "enabled": true,
                "defaultString": "Gov't. Center - 3rd Floor",
                "longString": "",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 5294,
                "enabled": true,
                "defaultString": "Gov't. Center - 4th Floor",
                "longString": "",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 5295,
                "enabled": true,
                "defaultString": "Gov't. Center - 5th Floor",
                "longString": "",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 5296,
                "enabled": true,
                "defaultString": "Gov't. Center - 6th Floor",
                "longString": "",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 5297,
                "enabled": true,
                "defaultString": "Gov't. Center - 7th Floor",
                "longString": "",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 5298,
                "enabled": true,
                "defaultString": "ham lake (the lake)",
                "longString": "",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 5299,
                "enabled": true,
                "defaultString": "ham lake brynwood motel",
                "longString": "",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 5300,
                "enabled": true,
                "defaultString": "ham lake coon lake",
                "longString": "",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 5301,
                "enabled": true,
                "defaultString": "ham lake cross town mall",
                "longString": "",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 5302,
                "enabled": true,
                "defaultString": "ham lake flamingo terrace",
                "longString": "",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 5303,
                "enabled": true,
                "defaultString": "ham lake holiday gas",
                "longString": "",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 5304,
                "enabled": true,
                "defaultString": "ham lake lake netta",
                "longString": "",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 5305,
                "enabled": true,
                "defaultString": "Ham Lake Public Access",
                "longString": "",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 5306,
                "enabled": true,
                "defaultString": "ham lake soderquists",
                "longString": "",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 5307,
                "enabled": true,
                "defaultString": "ham lake",
                "longString": "",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 5308,
                "enabled": true,
                "defaultString": "hilltop",
                "longString": "",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 5309,
                "enabled": true,
                "defaultString": "jail",
                "longString": "",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 5310,
                "enabled": true,
                "defaultString": "Lake George Public Access",
                "longString": "",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 5311,
                "enabled": true,
                "defaultString": "lexington",
                "longString": "",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 5312,
                "enabled": true,
                "defaultString": "lino lakes chain/lakes pk res",
                "longString": "",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 5313,
                "enabled": true,
                "defaultString": "lino lakes",
                "longString": "",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 5314,
                "enabled": true,
                "defaultString": "Linwood - Island Lake",
                "longString": "",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 5315,
                "enabled": true,
                "defaultString": "linwood carlos avery wild ref",
                "longString": "",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 5316,
                "enabled": true,
                "defaultString": "linwood fawn lake",
                "longString": "",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 5317,
                "enabled": true,
                "defaultString": "Linwood Lake Public Access",
                "longString": "",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 5318,
                "enabled": true,
                "defaultString": "linwood lake",
                "longString": "",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 5319,
                "enabled": true,
                "defaultString": "linwood martin lake",
                "longString": "",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 5320,
                "enabled": true,
                "defaultString": "linwood",
                "longString": "",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 5321,
                "enabled": true,
                "defaultString": "Martin Lake Public Access",
                "longString": "",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 5322,
                "enabled": true,
                "defaultString": "no entry",
                "longString": "",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 5323,
                "enabled": true,
                "defaultString": "oak grove lake george co park",
                "longString": "",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 5324,
                "enabled": true,
                "defaultString": "oak grove lake george",
                "longString": "",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 5325,
                "enabled": true,
                "defaultString": "oak grove rum river",
                "longString": "",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 5326,
                "enabled": true,
                "defaultString": "oak grove",
                "longString": "",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 5327,
                "enabled": true,
                "defaultString": "outside of the county",
                "longString": "",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 5328,
                "enabled": true,
                "defaultString": "Povlitski's",
                "longString": "",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 5329,
                "enabled": true,
                "defaultString": "ramsey mississippi river",
                "longString": "",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 5330,
                "enabled": true,
                "defaultString": "ramsey rum river central park",
                "longString": "",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 5331,
                "enabled": true,
                "defaultString": "ramsey rum river",
                "longString": "",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 5332,
                "enabled": true,
                "defaultString": "ramsey",
                "longString": "",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 5333,
                "enabled": true,
                "defaultString": "Round Lake Public Access",
                "longString": "",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 5334,
                "enabled": true,
                "defaultString": "Shoreside",
                "longString": "",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 5335,
                "enabled": true,
                "defaultString": "spring lake park",
                "longString": "",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 5336,
                "enabled": true,
                "defaultString": "st. francis rum river co park",
                "longString": "",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 5337,
                "enabled": true,
                "defaultString": "st. francis rum river",
                "longString": "",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 5338,
                "enabled": true,
                "defaultString": "st. francis",
                "longString": "",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 5339,
                "enabled": true,
                "defaultString": "Thielen Beach Access",
                "longString": "",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 5340,
                "enabled": true,
                "defaultString": "Twin Lake",
                "longString": "",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 5341,
                "enabled": true,
                "defaultString": "Twin Lakes Public Access",
                "longString": "",
                "sequenceNumber": 126
            }
        ]
    },
    {
        "RefListID": 484,
        "refListName": "Hair_Length",
        "refListItems": [
            {
                "refListItemId": 5342,
                "enabled": true,
                "defaultString": "NOH",
                "longString": "No Hair",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5343,
                "enabled": true,
                "defaultString": "LNG",
                "longString": "Long",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5344,
                "enabled": true,
                "defaultString": "MED",
                "longString": "Medium",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5345,
                "enabled": true,
                "defaultString": "SHT",
                "longString": "Short",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5346,
                "enabled": true,
                "defaultString": "SHL",
                "longString": "Shoulder Length",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5347,
                "enabled": false,
                "defaultString": "UNK",
                "longString": "Unknown",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 485,
        "refListName": "Facial_Hair",
        "refListItems": [
            {
                "refListItemId": 5348,
                "enabled": true,
                "defaultString": "BRD",
                "longString": "Beard",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5349,
                "enabled": true,
                "defaultString": "GOT",
                "longString": "Goatee",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5350,
                "enabled": true,
                "defaultString": "MST",
                "longString": "Mustache",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5351,
                "enabled": true,
                "defaultString": "NON",
                "longString": "None",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5352,
                "enabled": false,
                "defaultString": "S",
                "longString": "Sideburns",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5353,
                "enabled": true,
                "defaultString": "B&M",
                "longString": "Beard & Moustache",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5354,
                "enabled": true,
                "defaultString": "UNS",
                "longString": "Unshaven",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 486,
        "refListName": "Glasses",
        "refListItems": [
            {
                "refListItemId": 5355,
                "enabled": true,
                "defaultString": "C",
                "longString": "Contacts",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5356,
                "enabled": true,
                "defaultString": "N",
                "longString": "None",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5357,
                "enabled": false,
                "defaultString": "Y",
                "longString": "Yes",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5358,
                "enabled": true,
                "defaultString": "S",
                "longString": "Sun Glasess",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5359,
                "enabled": false,
                "defaultString": "U",
                "longString": "Unknown",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5360,
                "enabled": true,
                "defaultString": "O",
                "longString": "Other",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5361,
                "enabled": true,
                "defaultString": "G",
                "longString": "Glasses (Plain)",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 487,
        "refListName": "Teeth",
        "refListItems": [
            {
                "refListItemId": 5368,
                "enabled": true,
                "defaultString": "VW",
                "longString": "VW   - Very White",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5369,
                "enabled": true,
                "defaultString": "LG",
                "longString": "LG   - Unusually Large",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5370,
                "enabled": true,
                "defaultString": "BR",
                "longString": "BR   - Braces",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5371,
                "enabled": true,
                "defaultString": "DE",
                "longString": "DE   - False Teeth / Bridge",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5372,
                "enabled": true,
                "defaultString": "NL",
                "longString": "NL   - Normal",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5373,
                "enabled": true,
                "defaultString": "UK",
                "longString": "UK   - Unknown",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5374,
                "enabled": true,
                "defaultString": "NT",
                "longString": "NT   - No Teeth",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5375,
                "enabled": true,
                "defaultString": "OT",
                "longString": "OT   - Other",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5362,
                "enabled": true,
                "defaultString": "GO",
                "longString": "GO   - Gold",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5363,
                "enabled": true,
                "defaultString": "SI",
                "longString": "SI   - Silver",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5364,
                "enabled": true,
                "defaultString": "MI",
                "longString": "MI   - Missing",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5365,
                "enabled": true,
                "defaultString": "PR",
                "longString": "PR   - Protruding",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5366,
                "enabled": true,
                "defaultString": "DS",
                "longString": "DS   - Decaying/Dirty",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5367,
                "enabled": true,
                "defaultString": "BK",
                "longString": "BK   - Broken",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 488,
        "refListName": "Build",
        "refListItems": [
            {
                "refListItemId": 5376,
                "enabled": true,
                "defaultString": "SML",
                "longString": "Small",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5377,
                "enabled": true,
                "defaultString": "SLN",
                "longString": "Slender",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5378,
                "enabled": true,
                "defaultString": "MED",
                "longString": "Medium",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5379,
                "enabled": true,
                "defaultString": "LRG",
                "longString": "Large",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5380,
                "enabled": true,
                "defaultString": "HVY",
                "longString": "Heavy",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5381,
                "enabled": true,
                "defaultString": "STK",
                "longString": "Stocky",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 489,
        "refListName": "Levy_Type",
        "refListItems": [
            {
                "refListItemId": 5438,
                "enabled": true,
                "defaultString": "Bank Levy",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5439,
                "enabled": true,
                "defaultString": "Other Levy",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5440,
                "enabled": true,
                "defaultString": "Real Estate Levy",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5441,
                "enabled": true,
                "defaultString": "Third Party Levy",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5442,
                "enabled": true,
                "defaultString": "Vehicle Levy",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5443,
                "enabled": true,
                "defaultString": "Wage Levy",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 490,
        "refListName": "Charge_Level",
        "refListItems": [
            {
                "refListItemId": 5444,
                "enabled": true,
                "defaultString": "FE",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5445,
                "enabled": false,
                "defaultString": "MI",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5446,
                "enabled": true,
                "defaultString": "GM",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5447,
                "enabled": true,
                "defaultString": "PM",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5448,
                "enabled": false,
                "defaultString": "U",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 15277,
                "enabled": true,
                "defaultString": "MS",
                "longString": "",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 491,
        "refListName": "OffenderDisposition",
        "refListItems": [
            {
                "refListItemId": 5455,
                "enabled": true,
                "defaultString": "Arrested, felon p.c.",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5456,
                "enabled": true,
                "defaultString": "Arrested, pend. gm cit.",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5457,
                "enabled": true,
                "defaultString": "Arrested, misd., bail set",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5458,
                "enabled": true,
                "defaultString": "Arrested, misd., no bail",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5459,
                "enabled": true,
                "defaultString": "Arrested, sign tag b/4 rls",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5460,
                "enabled": true,
                "defaultString": "Arrested, wt., bail/fine",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5461,
                "enabled": true,
                "defaultString": "Arrested, wt., body only",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5462,
                "enabled": true,
                "defaultString": "Arrested, walk-in wt.",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5463,
                "enabled": true,
                "defaultString": "Ct-rule 5 hearing",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5464,
                "enabled": true,
                "defaultString": "Ct-rule 8 hearing",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5465,
                "enabled": true,
                "defaultString": "Found guilty, await sentencing",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5466,
                "enabled": true,
                "defaultString": "Family court matter",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5467,
                "enabled": true,
                "defaultString": "Huber-bk & rls",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5468,
                "enabled": true,
                "defaultString": "Ct-omnibus hearing",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5469,
                "enabled": true,
                "defaultString": "Ct-pre trial hrg",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5470,
                "enabled": true,
                "defaultString": "Ct-revocation hrg",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5471,
                "enabled": true,
                "defaultString": "Ct-fine/restitution hrg",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5472,
                "enabled": true,
                "defaultString": "Ct-rule 20 hrg",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5473,
                "enabled": true,
                "defaultString": "Ct-trial",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5474,
                "enabled": true,
                "defaultString": "Hold Comm. of Corrections",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5475,
                "enabled": true,
                "defaultString": "Hold DEA",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5476,
                "enabled": true,
                "defaultString": "Hold DOC",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5477,
                "enabled": true,
                "defaultString": "Hold FBI",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5478,
                "enabled": false,
                "defaultString": "Hold Hennepin housing",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 5479,
                "enabled": true,
                "defaultString": "Boarded In - Other County/INS",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 5480,
                "enabled": true,
                "defaultString": "Hold/judge-no bail",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 5481,
                "enabled": true,
                "defaultString": "Juv/certified as adult",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 5482,
                "enabled": true,
                "defaultString": "Hold other cnty",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 5483,
                "enabled": true,
                "defaultString": "Hold Out of State WT-Extrad.",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 5484,
                "enabled": true,
                "defaultString": "Hold corrections",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 5485,
                "enabled": true,
                "defaultString": "Hold for paper service",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 5486,
                "enabled": true,
                "defaultString": "Hold US Marshal",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 5487,
                "enabled": true,
                "defaultString": "Hold writ for court",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 5488,
                "enabled": true,
                "defaultString": "Sent. straight time",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 5489,
                "enabled": true,
                "defaultString": "Sent. pay fine",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 5490,
                "enabled": true,
                "defaultString": "Sent. STO",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 5491,
                "enabled": true,
                "defaultString": "Sent. violated huber law",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 5492,
                "enabled": true,
                "defaultString": "Sent. walk in commitment",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 5493,
                "enabled": true,
                "defaultString": "Sent. weekends",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 5494,
                "enabled": true,
                "defaultString": "Sent. home electronic monitoring",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 5495,
                "enabled": true,
                "defaultString": "Sent. HUBER - County",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 5496,
                "enabled": true,
                "defaultString": "Sent. to workhouse",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 5497,
                "enabled": true,
                "defaultString": "Sent. absconder",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 5498,
                "enabled": true,
                "defaultString": "Sent. HUBER - Out of County",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 5499,
                "enabled": true,
                "defaultString": "Sent. state work release",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 5500,
                "enabled": true,
                "defaultString": "Sent. federal work release",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 5501,
                "enabled": false,
                "defaultString": "Arrested, incarcerated",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 15278,
                "enabled": false,
                "defaultString": "Released, 104 form",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 15279,
                "enabled": false,
                "defaultString": "Released, 36/48 rule lapsed",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 15280,
                "enabled": false,
                "defaultString": "Fail to return fr furlough",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 15281,
                "enabled": false,
                "defaultString": "Released to other agency",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 15282,
                "enabled": false,
                "defaultString": "Released, pd. bail",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 15283,
                "enabled": false,
                "defaultString": "Released, 102 form filed",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 15284,
                "enabled": false,
                "defaultString": "Released by courts",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 15285,
                "enabled": false,
                "defaultString": "Released, charges dismissed",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 15286,
                "enabled": false,
                "defaultString": "Released, pd. fine",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 15287,
                "enabled": false,
                "defaultString": "Released to huber",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 15288,
                "enabled": false,
                "defaultString": "Released-ISAP program",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 15289,
                "enabled": false,
                "defaultString": "Released, judge order",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 15290,
                "enabled": false,
                "defaultString": "Released to responsible adult",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 15291,
                "enabled": false,
                "defaultString": "Released, sentence complete",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 15292,
                "enabled": false,
                "defaultString": "Released, sign tag",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 15293,
                "enabled": false,
                "defaultString": "Released, temporary/weekenders",
                "longString": "",
                "sequenceNumber": 49
            }
        ]
    },
    {
        "RefListID": 492,
        "refListName": "Court_Disposition",
        "refListItems": [
            {
                "refListItemId": 5503,
                "enabled": true,
                "defaultString": "Ct-omnibus hearing",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5504,
                "enabled": true,
                "defaultString": "Ct-pre trial hrg",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5505,
                "enabled": true,
                "defaultString": "Ct-revocation hrg",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5506,
                "enabled": true,
                "defaultString": "Ct-rule 20 hrg",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5507,
                "enabled": true,
                "defaultString": "Ct-rule 5 hearing",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5508,
                "enabled": true,
                "defaultString": "Ct-rule 8 hearing",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5509,
                "enabled": true,
                "defaultString": "Ct-trial",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5510,
                "enabled": true,
                "defaultString": "Family court matter",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5511,
                "enabled": true,
                "defaultString": "Found guilty, await sentencing",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5512,
                "enabled": true,
                "defaultString": "Hold Comm. of Corrections",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5513,
                "enabled": true,
                "defaultString": "Hold/judge-no bail",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5514,
                "enabled": true,
                "defaultString": "Hold writ for court",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5515,
                "enabled": true,
                "defaultString": "Juv/certified as adult",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5516,
                "enabled": true,
                "defaultString": "Sent. STO",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5517,
                "enabled": true,
                "defaultString": "Sent. home electronic monitoring",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5518,
                "enabled": true,
                "defaultString": "Sent. HUBER - County",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5519,
                "enabled": true,
                "defaultString": "Sent. pay fine",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5520,
                "enabled": true,
                "defaultString": "Sent. straight time",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5521,
                "enabled": true,
                "defaultString": "Sent. to workhouse",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5522,
                "enabled": true,
                "defaultString": "Sent. violated huber law",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5523,
                "enabled": true,
                "defaultString": "Sent. walk in commitment",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5524,
                "enabled": true,
                "defaultString": "Sent. weekends",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 5502,
                "enabled": true,
                "defaultString": "Ct-fine/restitution hrg",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16712,
                "enabled": true,
                "defaultString": "First Appearance",
                "sequenceNumber": 24
            }
        ]
    },
    {
        "RefListID": 622,
        "refListName": "JailIncidentsReportType",
        "refListItems": [
            {
                "refListItemId": 18163,
                "enabled": true,
                "defaultString": "Criminal",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18164,
                "enabled": true,
                "defaultString": "Non Criminal",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 494,
        "refListName": "Anoka_Warrant_Service_Type",
        "refListItems": [
            {
                "refListItemId": 5539,
                "enabled": true,
                "defaultString": "Attemped",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5540,
                "enabled": true,
                "defaultString": "Faxed to Anoka PD",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5541,
                "enabled": true,
                "defaultString": "Faxed to Blaine PD",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5542,
                "enabled": true,
                "defaultString": "Faxed to Circle/Lex/Center PD",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5543,
                "enabled": true,
                "defaultString": "Faxed to Columbia Heights PD",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5544,
                "enabled": true,
                "defaultString": "Faxed to Coon Rapids PD",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5545,
                "enabled": true,
                "defaultString": "Faxed to Fridley PD",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5546,
                "enabled": true,
                "defaultString": "Faxed to Lino Lakes PD",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5547,
                "enabled": true,
                "defaultString": "Faxed to Spring Lake Park PD",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5548,
                "enabled": true,
                "defaultString": "Moved",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5549,
                "enabled": true,
                "defaultString": "No Action",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 5550,
                "enabled": true,
                "defaultString": "Returned to Other County",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5551,
                "enabled": true,
                "defaultString": "Sent to Civil",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5552,
                "enabled": true,
                "defaultString": "Sent to Patrol",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5553,
                "enabled": true,
                "defaultString": "Sent to Ramsey PD",
                "longString": "",
                "sequenceNumber": 15
            }
        ]
    },
    {
        "RefListID": 495,
        "refListName": "Release_to_Authority",
        "refListItems": ""
    },
    {
        "RefListID": 496,
        "refListName": "DaysOfWeek",
        "refListItems": [
            {
                "refListItemId": 6410,
                "enabled": true,
                "defaultString": "Sunday",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 6411,
                "enabled": true,
                "defaultString": "Monday",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 6412,
                "enabled": true,
                "defaultString": "Tuesday",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 6413,
                "enabled": true,
                "defaultString": "Wednesday",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 6414,
                "enabled": true,
                "defaultString": "Thursday",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 6415,
                "enabled": true,
                "defaultString": "Friday",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 6416,
                "enabled": true,
                "defaultString": "Saturday",
                "longString": "",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 497,
        "refListName": "Release_to_Facility",
        "refListItems": [
            {
                "refListItemId": 6491,
                "enabled": true,
                "defaultString": "Eden Prairie PD",
                "longString": "",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 6492,
                "enabled": true,
                "defaultString": "Edina PD",
                "longString": "",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 6493,
                "enabled": true,
                "defaultString": "Elmore Academy",
                "longString": "",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 6494,
                "enabled": true,
                "defaultString": "Ely PD",
                "longString": "",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 6495,
                "enabled": true,
                "defaultString": "Faribault Cnty Jail",
                "longString": "",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 6496,
                "enabled": true,
                "defaultString": "Farmington PD",
                "longString": "",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 6497,
                "enabled": true,
                "defaultString": "Federal Community Programs Office - Mpls.",
                "longString": "",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 6498,
                "enabled": true,
                "defaultString": "Federal Prison Camp - Duluth",
                "longString": "",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 6499,
                "enabled": true,
                "defaultString": "Fillmore Cnty Jail",
                "longString": "",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 6417,
                "enabled": true,
                "defaultString": "180 Degrees Inc.",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 6418,
                "enabled": true,
                "defaultString": "After Today Group Foster Home",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 6419,
                "enabled": true,
                "defaultString": "Aitkin Cnty Jail",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 6420,
                "enabled": true,
                "defaultString": "Anoka Cnty Medium",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 6421,
                "enabled": true,
                "defaultString": "Anoka Cnty Jail",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 6422,
                "enabled": true,
                "defaultString": "Anoka Cnty Juv Cntr - Non-Secure",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 6423,
                "enabled": true,
                "defaultString": "Anoka Cnty Juv Cntr - Secure",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 6424,
                "enabled": true,
                "defaultString": "Anoka Cnty Workhouse",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 6425,
                "enabled": true,
                "defaultString": "Anoka PD",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 6426,
                "enabled": true,
                "defaultString": "Apple Valley PD",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 6427,
                "enabled": true,
                "defaultString": "Appleton PD",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 6428,
                "enabled": true,
                "defaultString": "Arrowhead Juv Cntr",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 6429,
                "enabled": true,
                "defaultString": "Bagley PD",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 6430,
                "enabled": true,
                "defaultString": "Bar-None Res Trtmnt Svcs",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 6431,
                "enabled": true,
                "defaultString": "BaxterYth Shelter",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 6432,
                "enabled": true,
                "defaultString": "Becker Cnty Jail",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 6433,
                "enabled": true,
                "defaultString": "Beltrami Cnty Jail",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 6434,
                "enabled": true,
                "defaultString": "Bemidji Satellite Home I/NMJC",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 6435,
                "enabled": true,
                "defaultString": "Bemidji Satellite Home II/NMJC",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 6436,
                "enabled": true,
                "defaultString": "Benson PD",
                "longString": "",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 6437,
                "enabled": true,
                "defaultString": "Benton Cnty Jail",
                "longString": "",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 6438,
                "enabled": true,
                "defaultString": "Biwabik PD",
                "longString": "",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 6439,
                "enabled": true,
                "defaultString": "Blaine PD",
                "longString": "",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 6440,
                "enabled": true,
                "defaultString": "Bloomington PD",
                "longString": "",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 6441,
                "enabled": true,
                "defaultString": "Blue Earth Cnty Annex",
                "longString": "",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 6442,
                "enabled": true,
                "defaultString": "Blue Earth Cnty Jail",
                "longString": "",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 6443,
                "enabled": true,
                "defaultString": "Boys Totem Town",
                "longString": "",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 6444,
                "enabled": true,
                "defaultString": "Brancato Group Foster Home",
                "longString": "",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 6445,
                "enabled": true,
                "defaultString": "Breckenridge PD",
                "longString": "",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 6446,
                "enabled": true,
                "defaultString": "Bright Horizons of Grand Rapids, Inc.",
                "longString": "",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 6447,
                "enabled": true,
                "defaultString": "Brooklyn Center PD",
                "longString": "",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 6448,
                "enabled": true,
                "defaultString": "Brooklyn Park PD",
                "longString": "",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 6449,
                "enabled": true,
                "defaultString": "Brown Cnty Jail",
                "longString": "",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 6450,
                "enabled": true,
                "defaultString": "Buckeye Catholic Charities",
                "longString": "",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 6451,
                "enabled": true,
                "defaultString": "Burnsville PD",
                "longString": "",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 6452,
                "enabled": true,
                "defaultString": "Cambridge PD",
                "longString": "",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 6453,
                "enabled": true,
                "defaultString": "Carlbom Group Foster Home",
                "longString": "",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 6454,
                "enabled": true,
                "defaultString": "Carlton Cnty Jail",
                "longString": "",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 6455,
                "enabled": true,
                "defaultString": "Carlton Yth Shelter/Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 6456,
                "enabled": true,
                "defaultString": "Carver Cnty Jail",
                "longString": "",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 6457,
                "enabled": true,
                "defaultString": "Carver Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 6458,
                "enabled": true,
                "defaultString": "Cass Cnty Jail",
                "longString": "",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 6459,
                "enabled": true,
                "defaultString": "Cass Lake PD",
                "longString": "",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 6460,
                "enabled": true,
                "defaultString": "Central MN Juv Cntr",
                "longString": "",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 6461,
                "enabled": true,
                "defaultString": "Champlin PD",
                "longString": "",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 6462,
                "enabled": true,
                "defaultString": "Chippewa Cnty Jail",
                "longString": "",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 6463,
                "enabled": true,
                "defaultString": "Chisago Cnty Jail",
                "longString": "",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 6464,
                "enabled": true,
                "defaultString": "Chisholm PD",
                "longString": "",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 6465,
                "enabled": true,
                "defaultString": "Clay Cnty Jail",
                "longString": "",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 6466,
                "enabled": true,
                "defaultString": "Clay Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 6467,
                "enabled": true,
                "defaultString": "Clearwater Cnty Jail",
                "longString": "",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 6468,
                "enabled": true,
                "defaultString": "Columbia Heights PD",
                "longString": "",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 6469,
                "enabled": true,
                "defaultString": "Cook Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 6470,
                "enabled": true,
                "defaultString": "Coon Rapids PD",
                "longString": "",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 6471,
                "enabled": true,
                "defaultString": "Cottage Grove PD",
                "longString": "",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 6472,
                "enabled": true,
                "defaultString": "Cottonwood Cnty Jail",
                "longString": "",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 6473,
                "enabled": true,
                "defaultString": "Crow Wing Cnty Jail",
                "longString": "",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 6474,
                "enabled": true,
                "defaultString": "Crow Wing Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 6475,
                "enabled": true,
                "defaultString": "Crystal PD",
                "longString": "",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 6476,
                "enabled": true,
                "defaultString": "Cuyuna PD",
                "longString": "",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 6477,
                "enabled": true,
                "defaultString": "Dakota Cnty Jail",
                "longString": "",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 6478,
                "enabled": true,
                "defaultString": "Dakota Cnty Juv Srvcs Cnter",
                "longString": "",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 6479,
                "enabled": true,
                "defaultString": "Damascas Way",
                "longString": "",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 6480,
                "enabled": true,
                "defaultString": "Dayspring",
                "longString": "",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 6481,
                "enabled": true,
                "defaultString": "Detox/ Hospital / Trtmnt Cntr",
                "longString": "",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 6482,
                "enabled": true,
                "defaultString": "Dierenfeld",
                "longString": "",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 6483,
                "enabled": true,
                "defaultString": "Dodge Cnty Jail",
                "longString": "",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 6484,
                "enabled": true,
                "defaultString": "Douglas Cnty Jail",
                "longString": "",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 6485,
                "enabled": true,
                "defaultString": "Douglas Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 6486,
                "enabled": true,
                "defaultString": "Douglas Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 6487,
                "enabled": true,
                "defaultString": "Eagan PD",
                "longString": "",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 6488,
                "enabled": true,
                "defaultString": "Eagle Ridge Boys Ranch",
                "longString": "",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 6489,
                "enabled": true,
                "defaultString": "East Central Regional Juv Cntr",
                "longString": "",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 6490,
                "enabled": true,
                "defaultString": "East Grand Forks PD",
                "longString": "",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 6500,
                "enabled": true,
                "defaultString": "Fillmore Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 6501,
                "enabled": true,
                "defaultString": "Forest Lake PD",
                "longString": "",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 6502,
                "enabled": true,
                "defaultString": "Foster / Group Home",
                "longString": "",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 6503,
                "enabled": true,
                "defaultString": "Franklin House for Girls",
                "longString": "",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 6504,
                "enabled": true,
                "defaultString": "Freeborn Cnty Jail",
                "longString": "",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 6505,
                "enabled": true,
                "defaultString": "Freeborn Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 6506,
                "enabled": true,
                "defaultString": "Fridley PD",
                "longString": "",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 6507,
                "enabled": true,
                "defaultString": "Gateway Group Foster Home (Inst)",
                "longString": "",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 6508,
                "enabled": true,
                "defaultString": "Golden Valley PD",
                "longString": "",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 6509,
                "enabled": true,
                "defaultString": "Goodhue Cnty Jail",
                "longString": "",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 6510,
                "enabled": true,
                "defaultString": "Great Plains Academy",
                "longString": "",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 6511,
                "enabled": true,
                "defaultString": "Hagre Group Foster Home",
                "longString": "",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 6512,
                "enabled": true,
                "defaultString": "Harbor Home",
                "longString": "",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 6513,
                "enabled": true,
                "defaultString": "Hayward Group Home",
                "longString": "",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 6514,
                "enabled": true,
                "defaultString": "Heartland Girls, Inc.",
                "longString": "",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 6515,
                "enabled": true,
                "defaultString": "Hennepin Cnty ACF - Men",
                "longString": "",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 6516,
                "enabled": true,
                "defaultString": "Hennepin Cnty ACF North - Wk Rel",
                "longString": "",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 6517,
                "enabled": true,
                "defaultString": "Hennepin Cnty ACF North - Wm",
                "longString": "",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 6518,
                "enabled": true,
                "defaultString": "Hennepin Cnty ADC",
                "longString": "",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 6519,
                "enabled": true,
                "defaultString": "Hennepin Cnty Home School",
                "longString": "",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 6520,
                "enabled": true,
                "defaultString": "Hennepin Cnty Juv Det Cntr",
                "longString": "",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 6521,
                "enabled": true,
                "defaultString": "Hibbing PD",
                "longString": "",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 6522,
                "enabled": true,
                "defaultString": "Hopkins PD",
                "longString": "",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 6523,
                "enabled": true,
                "defaultString": "Houston Cnty 90 Day Lockup",
                "longString": "",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 6524,
                "enabled": true,
                "defaultString": "Hubbard Cnty Jail",
                "longString": "",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 6525,
                "enabled": true,
                "defaultString": "Hudson House Group Foster Home",
                "longString": "",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 6526,
                "enabled": true,
                "defaultString": "Hutchinson PD",
                "longString": "",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 6527,
                "enabled": true,
                "defaultString": "I.T.A.S.K.I.N. Juv Cntr (Secure Unit)",
                "longString": "",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 6528,
                "enabled": true,
                "defaultString": "Inver Grove Heights PD",
                "longString": "",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 6529,
                "enabled": true,
                "defaultString": "Isanti Cnty Jail",
                "longString": "",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 6530,
                "enabled": true,
                "defaultString": "Isanti Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 6531,
                "enabled": true,
                "defaultString": "Isanti Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 6532,
                "enabled": true,
                "defaultString": "Itasca Cnty Jail",
                "longString": "",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 6533,
                "enabled": true,
                "defaultString": "Itasca Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 6534,
                "enabled": true,
                "defaultString": "Jackson Cnty Jail",
                "longString": "",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 6535,
                "enabled": true,
                "defaultString": "Jesmer Group Foster Home",
                "longString": "",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 6536,
                "enabled": true,
                "defaultString": "Kanabec Cnty Jail",
                "longString": "",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 6537,
                "enabled": true,
                "defaultString": "Kandi Boys Group Home",
                "longString": "",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 6538,
                "enabled": true,
                "defaultString": "Kandiyohi Cnty Jail",
                "longString": "",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 6539,
                "enabled": true,
                "defaultString": "Kekedakis Group Foster Home",
                "longString": "",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 6540,
                "enabled": true,
                "defaultString": "KidsPeace Mesabi Academy",
                "longString": "",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 6541,
                "enabled": true,
                "defaultString": "KidsPeace Prairie Academy",
                "longString": "",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 6542,
                "enabled": true,
                "defaultString": "Kittson Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 6543,
                "enabled": true,
                "defaultString": "Kohler Hall",
                "longString": "",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 6544,
                "enabled": true,
                "defaultString": "Koochiching Cnty Jail",
                "longString": "",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 6545,
                "enabled": true,
                "defaultString": "Lac Qui Parle Cnty 90 Day Lockup",
                "longString": "",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 6546,
                "enabled": true,
                "defaultString": "Lake Cnty Jail",
                "longString": "",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 6547,
                "enabled": true,
                "defaultString": "Lake Of The Woods Cnty 90 Day Lockup",
                "longString": "",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 6548,
                "enabled": true,
                "defaultString": "Lakes Area PD",
                "longString": "",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 6549,
                "enabled": true,
                "defaultString": "Lakeville PD",
                "longString": "",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 6550,
                "enabled": true,
                "defaultString": "Lawrence/Reed Group Foster Home",
                "longString": "",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 6551,
                "enabled": true,
                "defaultString": "Le Sueur Cnty Jail",
                "longString": "",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 6552,
                "enabled": true,
                "defaultString": "Leech Lake Satellite Home/NMJC",
                "longString": "",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 6553,
                "enabled": true,
                "defaultString": "Leo A. Hoffmann Center",
                "longString": "",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 6554,
                "enabled": true,
                "defaultString": "Lincoln Cnty Jail",
                "longString": "",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 6555,
                "enabled": true,
                "defaultString": "Luskey Group Foster Home",
                "longString": "",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 6556,
                "enabled": true,
                "defaultString": "Lyon Cnty Group Foster Home for Boys",
                "longString": "",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 6557,
                "enabled": true,
                "defaultString": "Lyon Cnty Jail",
                "longString": "",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 6558,
                "enabled": true,
                "defaultString": "Mahnomen Cnty 72h Holding",
                "longString": "",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 6559,
                "enabled": true,
                "defaultString": "Many Rivers Juv Det Cntr",
                "longString": "",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 6560,
                "enabled": true,
                "defaultString": "Many Rivers Res Trtmnt Cntr",
                "longString": "",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 6561,
                "enabled": true,
                "defaultString": "Maple Grove PD",
                "longString": "",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 6562,
                "enabled": true,
                "defaultString": "Maplewood PD",
                "longString": "",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 6563,
                "enabled": true,
                "defaultString": "Marshall Cnty Jail",
                "longString": "",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 6564,
                "enabled": true,
                "defaultString": "Marshall Dept of Public Safety",
                "longString": "",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 6565,
                "enabled": true,
                "defaultString": "Martin Cnty Jail",
                "longString": "",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 6566,
                "enabled": true,
                "defaultString": "Martin Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 6567,
                "enabled": true,
                "defaultString": "MCF - Faribault",
                "longString": "",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 6568,
                "enabled": true,
                "defaultString": "MCF - Lino Lakes",
                "longString": "",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 6569,
                "enabled": true,
                "defaultString": "MCF - Moose Lake",
                "longString": "",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 6570,
                "enabled": true,
                "defaultString": "MCF - Oak Park Heights",
                "longString": "",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 6571,
                "enabled": true,
                "defaultString": "MCF - Red Wing",
                "longString": "",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 6572,
                "enabled": true,
                "defaultString": "MCF - Rush City",
                "longString": "",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 6573,
                "enabled": true,
                "defaultString": "MCF - Sauk Centre",
                "longString": "",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 6574,
                "enabled": true,
                "defaultString": "MCF - Shakopee",
                "longString": "",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 6575,
                "enabled": true,
                "defaultString": "MCF - St. Cloud",
                "longString": "",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 6576,
                "enabled": true,
                "defaultString": "MCF - St. Croix Camp",
                "longString": "",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 6577,
                "enabled": true,
                "defaultString": "MCF - Stillwater",
                "longString": "",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 6578,
                "enabled": true,
                "defaultString": "MCF - Willow River Camp",
                "longString": "",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 6579,
                "enabled": true,
                "defaultString": "McLeod Cnty Jail",
                "longString": "",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 6580,
                "enabled": true,
                "defaultString": "Meeker Cnty Jail",
                "longString": "",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 6581,
                "enabled": true,
                "defaultString": "Mendota Heights PD",
                "longString": "",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 6582,
                "enabled": true,
                "defaultString": "Metro Airport PD",
                "longString": "",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 6583,
                "enabled": true,
                "defaultString": "Mille Lacs Academy",
                "longString": "",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 6584,
                "enabled": true,
                "defaultString": "Mille Lacs Cnty Jail",
                "longString": "",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 6585,
                "enabled": true,
                "defaultString": "MN Corr Fac",
                "longString": "",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 6586,
                "enabled": true,
                "defaultString": "Minnetonka PD",
                "longString": "",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 6587,
                "enabled": true,
                "defaultString": "MN River Valley Det Cntr",
                "longString": "",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 6588,
                "enabled": true,
                "defaultString": "Montevideo PD",
                "longString": "",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 6589,
                "enabled": true,
                "defaultString": "Morrison Cnty Jail",
                "longString": "",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 6590,
                "enabled": true,
                "defaultString": "Mower Cnty Jail",
                "longString": "",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 6591,
                "enabled": true,
                "defaultString": "Murray Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 6592,
                "enabled": true,
                "defaultString": "New Brighton PD",
                "longString": "",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 6593,
                "enabled": true,
                "defaultString": "New Hope PD",
                "longString": "",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 6594,
                "enabled": true,
                "defaultString": "New Ulm PD",
                "longString": "",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 6595,
                "enabled": true,
                "defaultString": "Nicollet Cnty Jail",
                "longString": "",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 6596,
                "enabled": true,
                "defaultString": "Nobles Cnty Jail",
                "longString": "",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 6597,
                "enabled": true,
                "defaultString": "Norman Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 6598,
                "enabled": true,
                "defaultString": "Northeast Regional Corrections Center",
                "longString": "",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 6599,
                "enabled": true,
                "defaultString": "Northfield PD",
                "longString": "",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 6600,
                "enabled": true,
                "defaultString": "Northstar Group Home",
                "longString": "",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 6601,
                "enabled": true,
                "defaultString": "Northwestern MN Juv Cntr",
                "longString": "",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 6602,
                "enabled": true,
                "defaultString": "OK House, Inc.",
                "longString": "",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 6603,
                "enabled": true,
                "defaultString": "Olmsted Cnty Jail",
                "longString": "",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 6604,
                "enabled": true,
                "defaultString": "Olmsted Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 6605,
                "enabled": true,
                "defaultString": "Orono PD",
                "longString": "",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 6606,
                "enabled": true,
                "defaultString": "Ortonville PD",
                "longString": "",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 6607,
                "enabled": true,
                "defaultString": "Otter Tail Cnty Jail",
                "longString": "",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 6608,
                "enabled": true,
                "defaultString": "Otter Tail Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 6609,
                "enabled": true,
                "defaultString": "Pennington Cnty Jail",
                "longString": "",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 6610,
                "enabled": true,
                "defaultString": "Pennington Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 6611,
                "enabled": true,
                "defaultString": "Phoenix Group Foster Home",
                "longString": "",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 6612,
                "enabled": true,
                "defaultString": "Pine Cnty Jail",
                "longString": "",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 6613,
                "enabled": true,
                "defaultString": "Pipestone Cnty Jail",
                "longString": "",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 6614,
                "enabled": true,
                "defaultString": "Plymouth PD",
                "longString": "",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 6615,
                "enabled": true,
                "defaultString": "Pope Cnty Municipal Fac",
                "longString": "",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 6616,
                "enabled": true,
                "defaultString": "PORT of Crow Wing Juv Res Fac",
                "longString": "",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 6617,
                "enabled": true,
                "defaultString": "Prairie Lakes Juv Det Cntr",
                "longString": "",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 6618,
                "enabled": true,
                "defaultString": "Progressive Individual Resources, Inc.",
                "longString": "",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 6619,
                "enabled": true,
                "defaultString": "Project Turnabout",
                "longString": "",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 6620,
                "enabled": true,
                "defaultString": "Ramsey Cnty ACF",
                "longString": "",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 6621,
                "enabled": true,
                "defaultString": "Ramsey Cnty ADC",
                "longString": "",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 6622,
                "enabled": true,
                "defaultString": "Ramsey Cnty Annex (72h Hold)",
                "longString": "",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 6623,
                "enabled": true,
                "defaultString": "Ramsey Cnty Juv Det Cntr",
                "longString": "",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 6624,
                "enabled": true,
                "defaultString": "Red River Valley Juv Cntr",
                "longString": "",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 6625,
                "enabled": true,
                "defaultString": "Redwood Cnty Jail",
                "longString": "",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 6626,
                "enabled": true,
                "defaultString": "Reflections for Change, Inc.",
                "longString": "",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 6627,
                "enabled": true,
                "defaultString": "Renville Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 6628,
                "enabled": true,
                "defaultString": "Rice Cnty Jail",
                "longString": "",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 6629,
                "enabled": true,
                "defaultString": "Rice Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 6630,
                "enabled": true,
                "defaultString": "Richfield PD",
                "longString": "",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 6631,
                "enabled": true,
                "defaultString": "Robbinsdale PD",
                "longString": "",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 6632,
                "enabled": true,
                "defaultString": "Rock Cnty Jail",
                "longString": "",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 6633,
                "enabled": true,
                "defaultString": "Roseau Cnty Jail",
                "longString": "",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 6634,
                "enabled": true,
                "defaultString": "Roseau Satellite Home/NMJC",
                "longString": "",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 6635,
                "enabled": true,
                "defaultString": "Rosemount PD",
                "longString": "",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 6636,
                "enabled": true,
                "defaultString": "S.T.E.P.",
                "longString": "",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 6637,
                "enabled": true,
                "defaultString": "Sauk Center PD",
                "longString": "",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 6638,
                "enabled": true,
                "defaultString": "Scott Cnty Jail",
                "longString": "",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 6639,
                "enabled": true,
                "defaultString": "Scott Cnty Jail Annex",
                "longString": "",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 6640,
                "enabled": true,
                "defaultString": "Scott Cnty Juv Alternative Fac",
                "longString": "",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 6641,
                "enabled": true,
                "defaultString": "Sheppard House",
                "longString": "",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 6642,
                "enabled": true,
                "defaultString": "Sherburne Cnty Jail",
                "longString": "",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 6643,
                "enabled": true,
                "defaultString": "Sherburne Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 6644,
                "enabled": true,
                "defaultString": "Sheriffs Yth Program - Austin Campus",
                "longString": "",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 6645,
                "enabled": true,
                "defaultString": "Sheriffs Yth Program - Isanti Campus",
                "longString": "",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 6646,
                "enabled": true,
                "defaultString": "Sibley Cnty Jail",
                "longString": "",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 6647,
                "enabled": true,
                "defaultString": "Silver Bay PD",
                "longString": "",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 6648,
                "enabled": true,
                "defaultString": "Snake River Home For Boys, Inc.",
                "longString": "",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 6649,
                "enabled": true,
                "defaultString": "Snyders Group Foster Home",
                "longString": "",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 6650,
                "enabled": true,
                "defaultString": "South St. Paul PD",
                "longString": "",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 6651,
                "enabled": true,
                "defaultString": "Southwestern Yth Services",
                "longString": "",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 6652,
                "enabled": true,
                "defaultString": "Spring Lake Park PD",
                "longString": "",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 6653,
                "enabled": true,
                "defaultString": "St. Cloud PD",
                "longString": "",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 6654,
                "enabled": true,
                "defaultString": "St. Croix Boys Camp",
                "longString": "",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 6655,
                "enabled": true,
                "defaultString": "St. Croix Girls Camp",
                "longString": "",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 6656,
                "enabled": true,
                "defaultString": "St. Louis Cnty 72h Hold - Hibbing",
                "longString": "",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 6657,
                "enabled": true,
                "defaultString": "St. Louis Cnty 72h Hold - Virginia",
                "longString": "",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 6658,
                "enabled": true,
                "defaultString": "St. Louis Cnty Jail",
                "longString": "",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 6659,
                "enabled": true,
                "defaultString": "St. Louis Park PD",
                "longString": "",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 6660,
                "enabled": true,
                "defaultString": "St. Peter PD",
                "longString": "",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 6661,
                "enabled": true,
                "defaultString": "Stearns Cnty Jail",
                "longString": "",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 6662,
                "enabled": true,
                "defaultString": "Stearns Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 6663,
                "enabled": true,
                "defaultString": "Steele Cnty Jail",
                "longString": "",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 6664,
                "enabled": true,
                "defaultString": "Steps of Success-Andover",
                "longString": "",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 6665,
                "enabled": true,
                "defaultString": "Steps to Success-Grand Rapids",
                "longString": "",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 6666,
                "enabled": true,
                "defaultString": "Stevens Cnty Hold",
                "longString": "",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 6667,
                "enabled": true,
                "defaultString": "STRIDE",
                "longString": "",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 6668,
                "enabled": true,
                "defaultString": "Swift Cnty Jail",
                "longString": "",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 6669,
                "enabled": true,
                "defaultString": "Talbot Group Foster Home",
                "longString": "",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 6670,
                "enabled": true,
                "defaultString": "The Bridge Group Foster Home",
                "longString": "",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 6671,
                "enabled": true,
                "defaultString": "Thief River Falls Satellite Home/NMJC",
                "longString": "",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 6672,
                "enabled": true,
                "defaultString": "Thistledew Camp",
                "longString": "",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 6673,
                "enabled": true,
                "defaultString": "Todd Cnty Jail",
                "longString": "",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 6674,
                "enabled": true,
                "defaultString": "Tracy PD",
                "longString": "",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 6675,
                "enabled": true,
                "defaultString": "Traverse Cnty 72h Hold",
                "longString": "",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 6676,
                "enabled": true,
                "defaultString": "Tri-Cnty Regional Corr. Center (Annex)",
                "longString": "",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 6677,
                "enabled": true,
                "defaultString": "Tri-Cnty Regional Corr Center",
                "longString": "",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 6678,
                "enabled": true,
                "defaultString": "Trott Group Foster Home",
                "longString": "",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 6679,
                "enabled": true,
                "defaultString": "US Corr Inst - Sandstone",
                "longString": "",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 6680,
                "enabled": true,
                "defaultString": "US Medical Center - Rochester",
                "longString": "",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 6681,
                "enabled": true,
                "defaultString": "Valley-Lake Boys Home, Inc.",
                "longString": "",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 6682,
                "enabled": true,
                "defaultString": "Village Ranch Res Fac",
                "longString": "",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 6683,
                "enabled": true,
                "defaultString": "Virginia PD",
                "longString": "",
                "sequenceNumber": 268
            },
            {
                "refListItemId": 6684,
                "enabled": true,
                "defaultString": "Virginia Temp Holdover Fac/AJC",
                "longString": "",
                "sequenceNumber": 269
            },
            {
                "refListItemId": 6685,
                "enabled": true,
                "defaultString": "VOA-Lake Street Halfway",
                "longString": "",
                "sequenceNumber": 270
            },
            {
                "refListItemId": 6686,
                "enabled": true,
                "defaultString": "VOA-Woodview Jail",
                "longString": "",
                "sequenceNumber": 271
            },
            {
                "refListItemId": 6687,
                "enabled": true,
                "defaultString": "Wabasha Cnty Jail",
                "longString": "",
                "sequenceNumber": 272
            },
            {
                "refListItemId": 6688,
                "enabled": true,
                "defaultString": "Wadena Cnty Jail",
                "longString": "",
                "sequenceNumber": 273
            },
            {
                "refListItemId": 6689,
                "enabled": true,
                "defaultString": "Waldoch Group Foster Home",
                "longString": "",
                "sequenceNumber": 274
            },
            {
                "refListItemId": 6690,
                "enabled": true,
                "defaultString": "Waseca Cnty Jail",
                "longString": "",
                "sequenceNumber": 275
            },
            {
                "refListItemId": 6691,
                "enabled": true,
                "defaultString": "Waseca Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 276
            },
            {
                "refListItemId": 6692,
                "enabled": true,
                "defaultString": "Washington Cnty Jail",
                "longString": "",
                "sequenceNumber": 277
            },
            {
                "refListItemId": 6693,
                "enabled": true,
                "defaultString": "Washington Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 278
            },
            {
                "refListItemId": 6694,
                "enabled": true,
                "defaultString": "Watonwan Cnty 90 Day Lockup",
                "longString": "",
                "sequenceNumber": 279
            },
            {
                "refListItemId": 6695,
                "enabled": true,
                "defaultString": "Wells PD",
                "longString": "",
                "sequenceNumber": 280
            },
            {
                "refListItemId": 6696,
                "enabled": true,
                "defaultString": "West Central Regional Juv Cntr",
                "longString": "",
                "sequenceNumber": 281
            },
            {
                "refListItemId": 6697,
                "enabled": true,
                "defaultString": "West St. Paul PD",
                "longString": "",
                "sequenceNumber": 282
            },
            {
                "refListItemId": 6698,
                "enabled": true,
                "defaultString": "White Bear Lake PD",
                "longString": "",
                "sequenceNumber": 283
            },
            {
                "refListItemId": 6699,
                "enabled": true,
                "defaultString": "Wills Family Foster Care",
                "longString": "",
                "sequenceNumber": 284
            },
            {
                "refListItemId": 6700,
                "enabled": true,
                "defaultString": "Winona Cnty Jail",
                "longString": "",
                "sequenceNumber": 285
            },
            {
                "refListItemId": 6701,
                "enabled": true,
                "defaultString": "Winona Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 286
            },
            {
                "refListItemId": 6702,
                "enabled": true,
                "defaultString": "Woodbury PD",
                "longString": "",
                "sequenceNumber": 287
            },
            {
                "refListItemId": 6703,
                "enabled": true,
                "defaultString": "Woodland Hills Res Trtmnt Cntr",
                "longString": "",
                "sequenceNumber": 288
            },
            {
                "refListItemId": 6704,
                "enabled": true,
                "defaultString": "Wright Cnty Jail",
                "longString": "",
                "sequenceNumber": 289
            },
            {
                "refListItemId": 6705,
                "enabled": true,
                "defaultString": "Wright Cnty Temp Holdover Fac",
                "longString": "",
                "sequenceNumber": 290
            },
            {
                "refListItemId": 6706,
                "enabled": true,
                "defaultString": "Yellow Medicine Cnty Jail",
                "longString": "",
                "sequenceNumber": 291
            },
            {
                "refListItemId": 6707,
                "enabled": true,
                "defaultString": "Yth Vocational Center - Rochester",
                "longString": "",
                "sequenceNumber": 292
            }
        ]
    },
    {
        "RefListID": 498,
        "refListName": "Towing_Company",
        "refListItems": [
            {
                "refListItemId": 23999,
                "enabled": true,
                "defaultString": "Schmit Towing 763-253-1568"
            },
            {
                "refListItemId": 16110,
                "enabled": true,
                "defaultString": "Jeff, Bobby, Steve's 763-788-1113"
            },
            {
                "refListItemId": 6708,
                "enabled": true,
                "defaultString": "Auto Medics 784-7501",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 6709,
                "enabled": true,
                "defaultString": "Blue Tow 786-9020",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 6710,
                "enabled": true,
                "defaultString": "Bourke Bros Tow 784-1528",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 6711,
                "enabled": true,
                "defaultString": "Central Tow 434-5421",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 6712,
                "enabled": true,
                "defaultString": "Champlin Towing 763-421-5501",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 6713,
                "enabled": true,
                "defaultString": "City Wide Tow 434-3059",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 6714,
                "enabled": true,
                "defaultString": "Coveys Tow 786-8707",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 6715,
                "enabled": true,
                "defaultString": "Dan's Towing",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 6716,
                "enabled": true,
                "defaultString": "Duanes Towing 759-7383",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 6717,
                "enabled": true,
                "defaultString": "Eaton's Towing 763-424-4900",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 6718,
                "enabled": true,
                "defaultString": "Freeway Tow 633-5525",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 6719,
                "enabled": true,
                "defaultString": "Genes Tow 784-9421",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 6720,
                "enabled": true,
                "defaultString": "Gerdin's Towing 434-5198",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 6721,
                "enabled": true,
                "defaultString": "Harold's Tow",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 6722,
                "enabled": true,
                "defaultString": "Isanti Tow 434-4722",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 6723,
                "enabled": true,
                "defaultString": "Lone Star Tow 753-1959",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 6724,
                "enabled": true,
                "defaultString": "Lund Towing 754-7577",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 6725,
                "enabled": true,
                "defaultString": "Midstate Towing 434-7140",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 6726,
                "enabled": true,
                "defaultString": "Moen Towing 753-3739",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 6727,
                "enabled": true,
                "defaultString": "North Star Tow 427-4160",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 6728,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 6729,
                "enabled": true,
                "defaultString": "Palans Tow 757-8018",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 6730,
                "enabled": true,
                "defaultString": "Shortys Tow 784-1411",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 6731,
                "enabled": true,
                "defaultString": "Spankys Tow 434-4526",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 6732,
                "enabled": true,
                "defaultString": "Srogas Tow 789-4245",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 6733,
                "enabled": true,
                "defaultString": "Twin City Towing",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 16016,
                "enabled": true,
                "defaultString": "Bobby and Steve's Tow 651-294-2094"
            }
        ]
    },
    {
        "RefListID": 500,
        "refListName": "Prisoner_Risk",
        "refListItems": [
            {
                "refListItemId": 6864,
                "enabled": true,
                "defaultString": "Assault-escape",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 6865,
                "enabled": true,
                "defaultString": "Assault-psych",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 6866,
                "enabled": true,
                "defaultString": "Assaultive",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 6867,
                "enabled": true,
                "defaultString": "Chronic Illness",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 6868,
                "enabled": true,
                "defaultString": "Contagious",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 6869,
                "enabled": true,
                "defaultString": "Escape Risk",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 6870,
                "enabled": true,
                "defaultString": "Medical Problems",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 6871,
                "enabled": true,
                "defaultString": "Non-cooperative",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 6872,
                "enabled": true,
                "defaultString": "Predatory Behavior",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 6873,
                "enabled": true,
                "defaultString": "\"see asn/pcs\"",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 6874,
                "enabled": true,
                "defaultString": "Mental Problems",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 6875,
                "enabled": true,
                "defaultString": "Sensitive Charges",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 6876,
                "enabled": true,
                "defaultString": "\"see asn/flg\"",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 6877,
                "enabled": true,
                "defaultString": "Suicide Risk",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 6878,
                "enabled": true,
                "defaultString": "Suicide",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 6879,
                "enabled": true,
                "defaultString": "Vulnerable Adul",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 6880,
                "enabled": true,
                "defaultString": "withdrawals",
                "sequenceNumber": 17
            }
        ]
    },
    {
        "RefListID": 501,
        "refListName": "Anoka_S3_Country_Code",
        "refListItems": [
            {
                "refListItemId": 6996,
                "enabled": true,
                "defaultString": "CA",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 6997,
                "enabled": true,
                "defaultString": "MX",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 6998,
                "enabled": true,
                "defaultString": "US",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 504,
        "refListName": "DistinguishingCharacteristics",
        "refListItems": [
            {
                "refListItemId": 7049,
                "enabled": true,
                "defaultString": "Deafness",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7055,
                "enabled": true,
                "defaultString": "Deformity",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 7013,
                "enabled": true,
                "defaultString": "Artificial Limb",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 7097,
                "enabled": true,
                "defaultString": "Drugs Abuse",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 7110,
                "enabled": true,
                "defaultString": "Eye Disorders",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 7120,
                "enabled": true,
                "defaultString": "Fracture",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 7175,
                "enabled": true,
                "defaultString": "Fracture Healed",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 7176,
                "enabled": true,
                "defaultString": "Cond./Disease",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 7204,
                "enabled": true,
                "defaultString": "Device/Implant",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 7230,
                "enabled": true,
                "defaultString": "Missing Limb",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7270,
                "enabled": true,
                "defaultString": "Mole",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 7296,
                "enabled": true,
                "defaultString": "Needle Mark",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 7297,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 7326,
                "enabled": true,
                "defaultString": "Scar",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7346,
                "enabled": true,
                "defaultString": "Mark/Discoloration",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7348,
                "enabled": true,
                "defaultString": "Tattoo",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 7350,
                "enabled": true,
                "defaultString": "Tattoo Removed",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 7351,
                "enabled": true,
                "defaultString": "Drug Medical",
                "longString": "",
                "sequenceNumber": 18
            }
        ]
    },
    {
        "RefListID": 505,
        "refListName": "CharacteristicLocation",
        "refListItems": [
            {
                "refListItemId": 7031,
                "enabled": true,
                "defaultString": "Denture, lower only",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 7032,
                "enabled": true,
                "defaultString": "Denture, upper only",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 7033,
                "enabled": true,
                "defaultString": "Denture, upper and lower",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 7034,
                "enabled": true,
                "defaultString": "Ear, left, artificial",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 7035,
                "enabled": true,
                "defaultString": "Ear, right, artificial",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 7036,
                "enabled": true,
                "defaultString": "Eye, left, artificial",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 7037,
                "enabled": true,
                "defaultString": "Eye, right, artificial",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 7038,
                "enabled": true,
                "defaultString": "Foot, left, artificial",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 7039,
                "enabled": true,
                "defaultString": "Foot, right, artificial",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 7040,
                "enabled": true,
                "defaultString": "Glasses",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 7041,
                "enabled": true,
                "defaultString": "Gold tooth",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 7042,
                "enabled": true,
                "defaultString": "Hand, left, artificial",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 7043,
                "enabled": true,
                "defaultString": "Hand, right, artificial",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 7044,
                "enabled": true,
                "defaultString": "Hearing aid",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 7045,
                "enabled": true,
                "defaultString": "Leg, left, artificial",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 7046,
                "enabled": true,
                "defaultString": "Leg, right, artificial",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 7047,
                "enabled": true,
                "defaultString": "Silver tooth",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 7048,
                "enabled": true,
                "defaultString": "Wheelchair",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 7050,
                "enabled": true,
                "defaultString": "Deaf, one ear, nonspecific",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 7051,
                "enabled": true,
                "defaultString": "Deaf, left ear",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 7052,
                "enabled": true,
                "defaultString": "Deaf, right ear",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 7053,
                "enabled": true,
                "defaultString": "Deaf, left and right ears",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 7054,
                "enabled": true,
                "defaultString": "Deaf-mute",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 7056,
                "enabled": true,
                "defaultString": "Cauliflower ear, left",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 7057,
                "enabled": true,
                "defaultString": "Cauliflower ear, right",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 7058,
                "enabled": true,
                "defaultString": "Cleft lip",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 7059,
                "enabled": true,
                "defaultString": "Cleft palate",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 7060,
                "enabled": true,
                "defaultString": "Crippled arm, left",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 7061,
                "enabled": true,
                "defaultString": "Crippled arm, right",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 7062,
                "enabled": true,
                "defaultString": "Crippled finger(s), left hand",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 7063,
                "enabled": true,
                "defaultString": "Crippled finger(s), right hand",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 7064,
                "enabled": true,
                "defaultString": "Crippled foot, left",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 7065,
                "enabled": true,
                "defaultString": "Crippled foot, right",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 7066,
                "enabled": true,
                "defaultString": "Crippled hand, left",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 7067,
                "enabled": true,
                "defaultString": "Crippled hand, right",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 7068,
                "enabled": true,
                "defaultString": "Crippled leg, left",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 7069,
                "enabled": true,
                "defaultString": "Crippled leg, right",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 7070,
                "enabled": true,
                "defaultString": "Crippled toe(s), left",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 7071,
                "enabled": true,
                "defaultString": "Crippled toe(s), right",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 7072,
                "enabled": true,
                "defaultString": "Deviated septum",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 7073,
                "enabled": true,
                "defaultString": "Extra breast, nonspecific",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 7074,
                "enabled": true,
                "defaultString": "Extra breast, center",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 7075,
                "enabled": true,
                "defaultString": "Extra breast, left",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 7027,
                "enabled": true,
                "defaultString": "Braces, teeth",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 7028,
                "enabled": true,
                "defaultString": "Cane",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 7029,
                "enabled": true,
                "defaultString": "Contact lenses",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 7016,
                "enabled": true,
                "defaultString": "Arm, right, artificial",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7017,
                "enabled": true,
                "defaultString": "Brace, left and right arms",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 7018,
                "enabled": true,
                "defaultString": "Brace, left arm",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 7019,
                "enabled": true,
                "defaultString": "Brace, one arm, nonspecific",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7020,
                "enabled": true,
                "defaultString": "Brace, right arm",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 7021,
                "enabled": true,
                "defaultString": "Brace, back",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 7022,
                "enabled": true,
                "defaultString": "Brace, left and right legs",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 7023,
                "enabled": true,
                "defaultString": "Brace, left leg",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 7024,
                "enabled": true,
                "defaultString": "Brace, one leg, nonspecific",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 7025,
                "enabled": true,
                "defaultString": "Brace, right leg",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 7026,
                "enabled": true,
                "defaultString": "Brace, neck",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 7030,
                "enabled": true,
                "defaultString": "Crutches",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 7014,
                "enabled": true,
                "defaultString": "Arm, left, artificial",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7015,
                "enabled": true,
                "defaultString": "Arm, nonspecific, artificial",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7076,
                "enabled": true,
                "defaultString": "Extra breast, right",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 7077,
                "enabled": true,
                "defaultString": "Extra finger(s), left hand",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 7078,
                "enabled": true,
                "defaultString": "Extra finger(s), right hand",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 7079,
                "enabled": true,
                "defaultString": "Extra nipple, nonspecific",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 7080,
                "enabled": true,
                "defaultString": "Extra nipple, center",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 7081,
                "enabled": true,
                "defaultString": "Extra nipple, left",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 7082,
                "enabled": true,
                "defaultString": "Extra nipple, right",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 7083,
                "enabled": true,
                "defaultString": "Extra toe(s), left",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 7084,
                "enabled": true,
                "defaultString": "Extra toe(s), right",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 7085,
                "enabled": true,
                "defaultString": "Extra tooth/teeth (upper jaw)",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 7086,
                "enabled": true,
                "defaultString": "Extra tooth/teeth (lower jaw)",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 7087,
                "enabled": true,
                "defaultString": "Extra vertebra(e), nonspecific",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 7088,
                "enabled": true,
                "defaultString": "Extra cervical vertebra(e)",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 7089,
                "enabled": true,
                "defaultString": "Extra lumbar vertebra(e)",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 7090,
                "enabled": true,
                "defaultString": "Harelip",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 7091,
                "enabled": true,
                "defaultString": "Humpbacked",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 7092,
                "enabled": true,
                "defaultString": "Mute",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 7093,
                "enabled": true,
                "defaultString": "Protruding lower jaw",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 7094,
                "enabled": true,
                "defaultString": "Protruding upper jaw",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 7095,
                "enabled": true,
                "defaultString": "Shorter left leg",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 7096,
                "enabled": true,
                "defaultString": "Shorter right leg",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 7098,
                "enabled": true,
                "defaultString": "Alcohol",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 7099,
                "enabled": true,
                "defaultString": "Amphetamines",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 7100,
                "enabled": true,
                "defaultString": "Barbiturates",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 7101,
                "enabled": true,
                "defaultString": "Cocaine",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 7102,
                "enabled": true,
                "defaultString": "Glue",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 7103,
                "enabled": true,
                "defaultString": "Hallucinogens",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 7104,
                "enabled": true,
                "defaultString": "Marijuana",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 7105,
                "enabled": true,
                "defaultString": "Narcotics",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 7106,
                "enabled": true,
                "defaultString": "Paint",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 7107,
                "enabled": true,
                "defaultString": "Ritalin",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 7108,
                "enabled": true,
                "defaultString": "Rohypnol",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 7109,
                "enabled": true,
                "defaultString": "Other drugs",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 7111,
                "enabled": true,
                "defaultString": "Blind, one eye, nonspecific",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 7112,
                "enabled": true,
                "defaultString": "Blind, left eye",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 7113,
                "enabled": true,
                "defaultString": "Blind, right eye",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 7114,
                "enabled": true,
                "defaultString": "Blind, both eyes",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 7115,
                "enabled": true,
                "defaultString": "Cataract",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 7116,
                "enabled": true,
                "defaultString": "Cataract, left eye",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 7117,
                "enabled": true,
                "defaultString": "Cataract, right eye",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 7118,
                "enabled": true,
                "defaultString": "Cross-eyed",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 7119,
                "enabled": true,
                "defaultString": "Glaucoma",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 7121,
                "enabled": true,
                "defaultString": "Ankle, nonspecific",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 7122,
                "enabled": true,
                "defaultString": "Ankle, left",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 7123,
                "enabled": true,
                "defaultString": "Ankle, right",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 7124,
                "enabled": true,
                "defaultString": "Arm, nonspecific",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 7125,
                "enabled": true,
                "defaultString": "Arm, upper left",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 7126,
                "enabled": true,
                "defaultString": "Arm, lower left",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 7127,
                "enabled": true,
                "defaultString": "Arm, upper right",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 7128,
                "enabled": true,
                "defaultString": "Arm, lower right",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 7129,
                "enabled": true,
                "defaultString": "Back",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 7130,
                "enabled": true,
                "defaultString": "Clavicle, nonspecific",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 7131,
                "enabled": true,
                "defaultString": "Clavicle, left",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 7132,
                "enabled": true,
                "defaultString": "Clavicle, right",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 7133,
                "enabled": true,
                "defaultString": "Finger(s), nonspecific",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 7134,
                "enabled": true,
                "defaultString": "Finger(s), left hand",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 7135,
                "enabled": true,
                "defaultString": "Finger(s), right hand",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 7136,
                "enabled": true,
                "defaultString": "Foot, nonspecific",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 7137,
                "enabled": true,
                "defaultString": "Foot, left",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 7138,
                "enabled": true,
                "defaultString": "Foot, right",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 7139,
                "enabled": true,
                "defaultString": "Hand, nonspecific",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 7140,
                "enabled": true,
                "defaultString": "Hand, left",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 7141,
                "enabled": true,
                "defaultString": "Hand, right",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 7142,
                "enabled": true,
                "defaultString": "Jaw, nonspecific",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 7143,
                "enabled": true,
                "defaultString": "Jaw, upper left",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 7144,
                "enabled": true,
                "defaultString": "Jaw, lower left",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 7145,
                "enabled": true,
                "defaultString": "Jaw, upper right",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 7146,
                "enabled": true,
                "defaultString": "Jaw, lower right",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 7147,
                "enabled": true,
                "defaultString": "Knee, nonspecific",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 7148,
                "enabled": true,
                "defaultString": "Knee, left",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 7149,
                "enabled": true,
                "defaultString": "Knee, right",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 7150,
                "enabled": true,
                "defaultString": "Leg, nonspecific",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 7151,
                "enabled": true,
                "defaultString": "Leg, upper left",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 7152,
                "enabled": true,
                "defaultString": "Leg, lower left",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 7153,
                "enabled": true,
                "defaultString": "Leg, upper right",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 7154,
                "enabled": true,
                "defaultString": "Leg, lower right",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 7155,
                "enabled": true,
                "defaultString": "Neck",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 7156,
                "enabled": true,
                "defaultString": "Nose",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 7157,
                "enabled": true,
                "defaultString": "Pelvis, nonspecific",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 7158,
                "enabled": true,
                "defaultString": "Pelvic bone, left",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 7159,
                "enabled": true,
                "defaultString": "Pelvic bone, right",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 7160,
                "enabled": true,
                "defaultString": "Rib(s), nonspecific",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 7161,
                "enabled": true,
                "defaultString": "Rib(s), left",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 7162,
                "enabled": true,
                "defaultString": "Rib(s), right",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 7163,
                "enabled": true,
                "defaultString": "Shoulder, nonspecific",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 7164,
                "enabled": true,
                "defaultString": "Shoulder, left",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 7165,
                "enabled": true,
                "defaultString": "Shoulder, right",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 7166,
                "enabled": true,
                "defaultString": "Skull",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 7167,
                "enabled": true,
                "defaultString": "Spine",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 7168,
                "enabled": true,
                "defaultString": "Sternum",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 7169,
                "enabled": true,
                "defaultString": "Toe(s), nonspecific",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 7170,
                "enabled": true,
                "defaultString": "Toe(s), left foot",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 7171,
                "enabled": true,
                "defaultString": "Toe(s), right foot",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 7172,
                "enabled": true,
                "defaultString": "Wrist, nonspecific",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 7173,
                "enabled": true,
                "defaultString": "Wrist, left",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 7174,
                "enabled": true,
                "defaultString": "Wrist, right",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 7177,
                "enabled": true,
                "defaultString": "Acne",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 7178,
                "enabled": true,
                "defaultString": "Alcoholism",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 7179,
                "enabled": true,
                "defaultString": "Allergies",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 7180,
                "enabled": true,
                "defaultString": "AlzheimerÆs disease",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 7181,
                "enabled": true,
                "defaultString": "Arthritis",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 7182,
                "enabled": true,
                "defaultString": "Attention Def./Disorder",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 7183,
                "enabled": true,
                "defaultString": "Behavior disorder",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 7184,
                "enabled": true,
                "defaultString": "Cancer",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 7185,
                "enabled": true,
                "defaultString": "Diabetic",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 7186,
                "enabled": true,
                "defaultString": "Down Syndrome",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 7187,
                "enabled": true,
                "defaultString": "Drug abuse",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 7188,
                "enabled": true,
                "defaultString": "Eating disorders",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 7189,
                "enabled": true,
                "defaultString": "Heart diseases",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 7190,
                "enabled": true,
                "defaultString": "Hematological diseases",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 7191,
                "enabled": true,
                "defaultString": "Kidney diseases",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 7192,
                "enabled": true,
                "defaultString": "Liver disease",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 7193,
                "enabled": true,
                "defaultString": "Nervous conditions",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 7194,
                "enabled": true,
                "defaultString": "Neurological diseases",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 7195,
                "enabled": true,
                "defaultString": "Paraplegic",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 7196,
                "enabled": true,
                "defaultString": "Pregnancy - past",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 7197,
                "enabled": true,
                "defaultString": "Pregnancy - present",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 7198,
                "enabled": true,
                "defaultString": "Quadriplegic",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 7199,
                "enabled": true,
                "defaultString": "Skin disorders",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 7200,
                "enabled": true,
                "defaultString": "Thyroid diseases",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 7201,
                "enabled": true,
                "defaultString": "Tuberculosis",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 7202,
                "enabled": true,
                "defaultString": "TouretteÆs syndrome",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 7203,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 7205,
                "enabled": true,
                "defaultString": "Artificial elbow joint",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 7206,
                "enabled": true,
                "defaultString": "Artificial hip joint",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 7207,
                "enabled": true,
                "defaultString": "Artificial knee joint",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 7208,
                "enabled": true,
                "defaultString": "Artificial larynx",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 7209,
                "enabled": true,
                "defaultString": "Artificial shoulder joint",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 7210,
                "enabled": true,
                "defaultString": "Breast implant,left&right",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 7211,
                "enabled": true,
                "defaultString": "Breast implant,left",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 7212,
                "enabled": true,
                "defaultString": "Breast implant,right",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 7213,
                "enabled": true,
                "defaultString": "Cardiac pacemaker",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 7214,
                "enabled": true,
                "defaultString": "Colostomy appliances",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 7215,
                "enabled": true,
                "defaultString": "Intramedullary rod",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 7216,
                "enabled": true,
                "defaultString": "Intrauterine device",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 7217,
                "enabled": true,
                "defaultString": "Orthopedic nail/pin",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 7218,
                "enabled": true,
                "defaultString": "Orthopedic plate",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 7219,
                "enabled": true,
                "defaultString": "Orthopedic screw",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 7220,
                "enabled": true,
                "defaultString": "Penile implant",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 7221,
                "enabled": true,
                "defaultString": "Shunt, arterial vascular",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 7222,
                "enabled": true,
                "defaultString": "Shunt, cerebral ventricle",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 7223,
                "enabled": true,
                "defaultString": "Skull plate",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 7224,
                "enabled": true,
                "defaultString": "Staples",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 7225,
                "enabled": true,
                "defaultString": "Tubes in ears, left&right",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 7226,
                "enabled": true,
                "defaultString": "Tube in left ear",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 7227,
                "enabled": true,
                "defaultString": "Tube in right ear",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 7228,
                "enabled": true,
                "defaultString": "Vascular prosthesi",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 7229,
                "enabled": true,
                "defaultString": "Wire sutures",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 7231,
                "enabled": true,
                "defaultString": "Adenoids",
                "sequenceNumber": 209
            },
            {
                "refListItemId": 7232,
                "enabled": true,
                "defaultString": "Appendix",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 7233,
                "enabled": true,
                "defaultString": "Arm, left",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 7234,
                "enabled": true,
                "defaultString": "Arm, right",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 7235,
                "enabled": true,
                "defaultString": "Breasts",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 7236,
                "enabled": true,
                "defaultString": "Breast, left",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 7237,
                "enabled": true,
                "defaultString": "Breast, right",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 7238,
                "enabled": true,
                "defaultString": "Ear, left",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 7239,
                "enabled": true,
                "defaultString": "Ear, right",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 7240,
                "enabled": true,
                "defaultString": "Eye, left",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 7241,
                "enabled": true,
                "defaultString": "Eye, right",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 7242,
                "enabled": true,
                "defaultString": "Finger joint(s), left hand",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 7243,
                "enabled": true,
                "defaultString": "Finger joint(s), right hand",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 7244,
                "enabled": true,
                "defaultString": "Gallbladder",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 7245,
                "enabled": true,
                "defaultString": "Intestines",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 7246,
                "enabled": true,
                "defaultString": "Kidney, left",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 7247,
                "enabled": true,
                "defaultString": "Kidney, right",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 7248,
                "enabled": true,
                "defaultString": "Larynx",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 7249,
                "enabled": true,
                "defaultString": "Leg, left",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 7250,
                "enabled": true,
                "defaultString": "Leg, right",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 7251,
                "enabled": true,
                "defaultString": "Lung, left",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 7252,
                "enabled": true,
                "defaultString": "Lung, right",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 7253,
                "enabled": true,
                "defaultString": "Ovaries",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 7254,
                "enabled": true,
                "defaultString": "Ovary, lef",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 7255,
                "enabled": true,
                "defaultString": "Ovary, right",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 7256,
                "enabled": true,
                "defaultString": "Pancreas",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 7257,
                "enabled": true,
                "defaultString": "Penis",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 7258,
                "enabled": true,
                "defaultString": "Prostate gland",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 7259,
                "enabled": true,
                "defaultString": "Spleen",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 7260,
                "enabled": true,
                "defaultString": "Stomach",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 7261,
                "enabled": true,
                "defaultString": "Testis, left",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 7262,
                "enabled": true,
                "defaultString": "Testis, right",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 7263,
                "enabled": true,
                "defaultString": "Thyroid",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 7264,
                "enabled": true,
                "defaultString": "Tongue",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 7265,
                "enabled": true,
                "defaultString": "Tonsils",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 7266,
                "enabled": true,
                "defaultString": "Uterus",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 7267,
                "enabled": true,
                "defaultString": "Cervical vertebra(e)",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 7268,
                "enabled": true,
                "defaultString": "Lumbar vertebra(e)",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 7269,
                "enabled": true,
                "defaultString": "Vertebra(e), nonspecific",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 7271,
                "enabled": true,
                "defaultString": "Abdomen",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 7272,
                "enabled": true,
                "defaultString": "Breast, nonspecific",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 7273,
                "enabled": true,
                "defaultString": "Buttocks, nonspecific",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 7274,
                "enabled": true,
                "defaultString": "Buttock, left",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 7275,
                "enabled": true,
                "defaultString": "Buttock, right",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 7276,
                "enabled": true,
                "defaultString": "Cheek (face), nonspecific",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 7277,
                "enabled": true,
                "defaultString": "Cheek (face), left",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 7278,
                "enabled": true,
                "defaultString": "Cheek (face), right",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 7279,
                "enabled": true,
                "defaultString": "Chest",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 7280,
                "enabled": true,
                "defaultString": "Chin",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 7281,
                "enabled": true,
                "defaultString": "Ear, nonspecific",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 7282,
                "enabled": true,
                "defaultString": "Eyebrow, left/left eye area",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 7283,
                "enabled": true,
                "defaultString": "Eyebrow, right/right eye area",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 7284,
                "enabled": true,
                "defaultString": "Forehead",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 7285,
                "enabled": true,
                "defaultString": "Groin area",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 7286,
                "enabled": true,
                "defaultString": "Head, nonspecific",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 7287,
                "enabled": true,
                "defaultString": "Hip, nonspecific",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 7288,
                "enabled": true,
                "defaultString": "Hip, lef",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 7289,
                "enabled": true,
                "defaultString": "Hip, right",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 7290,
                "enabled": true,
                "defaultString": "Lip, nonspecific",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 7291,
                "enabled": true,
                "defaultString": "Lip, lower",
                "sequenceNumber": 268
            },
            {
                "refListItemId": 7292,
                "enabled": true,
                "defaultString": "Lip, upper",
                "sequenceNumber": 269
            },
            {
                "refListItemId": 7293,
                "enabled": true,
                "defaultString": "Thigh, nonspecific",
                "sequenceNumber": 270
            },
            {
                "refListItemId": 7294,
                "enabled": true,
                "defaultString": "Thigh, left",
                "sequenceNumber": 271
            },
            {
                "refListItemId": 7295,
                "enabled": true,
                "defaultString": "Thigh, right",
                "sequenceNumber": 272
            },
            {
                "refListItemId": 7298,
                "enabled": true,
                "defaultString": "Bald/balding",
                "sequenceNumber": 273
            },
            {
                "refListItemId": 7299,
                "enabled": true,
                "defaultString": "Cleft chin",
                "sequenceNumber": 274
            },
            {
                "refListItemId": 7300,
                "enabled": true,
                "defaultString": "Dimple, chin",
                "sequenceNumber": 275
            },
            {
                "refListItemId": 7301,
                "enabled": true,
                "defaultString": "Dimples, left cheek",
                "sequenceNumber": 276
            },
            {
                "refListItemId": 7302,
                "enabled": true,
                "defaultString": "Dimples, right cheek",
                "sequenceNumber": 277
            },
            {
                "refListItemId": 7303,
                "enabled": true,
                "defaultString": "Freckles",
                "sequenceNumber": 278
            },
            {
                "refListItemId": 7304,
                "enabled": true,
                "defaultString": "Hair implants",
                "sequenceNumber": 279
            },
            {
                "refListItemId": 7305,
                "enabled": true,
                "defaultString": "Pierced abdomen",
                "sequenceNumber": 280
            },
            {
                "refListItemId": 7306,
                "enabled": true,
                "defaultString": "Pierced back",
                "sequenceNumber": 281
            },
            {
                "refListItemId": 7307,
                "enabled": true,
                "defaultString": "Pierced ear,nonspecific",
                "sequenceNumber": 282
            },
            {
                "refListItemId": 7308,
                "enabled": true,
                "defaultString": "Pierced ears",
                "sequenceNumber": 283
            },
            {
                "refListItemId": 7309,
                "enabled": true,
                "defaultString": "Pierced ear, left",
                "sequenceNumber": 284
            },
            {
                "refListItemId": 7310,
                "enabled": true,
                "defaultString": "Pierced ear, right",
                "sequenceNumber": 285
            },
            {
                "refListItemId": 7311,
                "enabled": true,
                "defaultString": "Pierced eyebrow, nonspecific",
                "sequenceNumber": 286
            },
            {
                "refListItemId": 7312,
                "enabled": true,
                "defaultString": "Pierced eyebrow, left",
                "sequenceNumber": 287
            },
            {
                "refListItemId": 7313,
                "enabled": true,
                "defaultString": "Pierced eyebrow, right",
                "sequenceNumber": 288
            },
            {
                "refListItemId": 7314,
                "enabled": true,
                "defaultString": "Pierced genitalia",
                "sequenceNumber": 289
            },
            {
                "refListItemId": 7315,
                "enabled": true,
                "defaultString": "Pierced lip, nonspecific",
                "sequenceNumber": 290
            },
            {
                "refListItemId": 7316,
                "enabled": true,
                "defaultString": "Pierced lip, upper",
                "sequenceNumber": 291
            },
            {
                "refListItemId": 7317,
                "enabled": true,
                "defaultString": "Pierced lip, lower",
                "sequenceNumber": 292
            },
            {
                "refListItemId": 7318,
                "enabled": true,
                "defaultString": "Pierced nipple, nonspecific",
                "sequenceNumber": 293
            },
            {
                "refListItemId": 7319,
                "enabled": true,
                "defaultString": "Pierced nipple, left",
                "sequenceNumber": 294
            },
            {
                "refListItemId": 7320,
                "enabled": true,
                "defaultString": "Pierced nipple, right",
                "sequenceNumber": 295
            },
            {
                "refListItemId": 7321,
                "enabled": true,
                "defaultString": "Pierced nose",
                "sequenceNumber": 296
            },
            {
                "refListItemId": 7322,
                "enabled": true,
                "defaultString": "Pierced tongue",
                "sequenceNumber": 297
            },
            {
                "refListItemId": 7323,
                "enabled": true,
                "defaultString": "Stutters",
                "sequenceNumber": 298
            },
            {
                "refListItemId": 7324,
                "enabled": true,
                "defaultString": "Transsexual",
                "sequenceNumber": 299
            },
            {
                "refListItemId": 7325,
                "enabled": true,
                "defaultString": "Transvestite",
                "sequenceNumber": 300
            },
            {
                "refListItemId": 7327,
                "enabled": true,
                "defaultString": "Arm, left upper",
                "sequenceNumber": 301
            },
            {
                "refListItemId": 7328,
                "enabled": true,
                "defaultString": "Arm, right upper",
                "sequenceNumber": 302
            },
            {
                "refListItemId": 7329,
                "enabled": true,
                "defaultString": "Buttock, nonspecific",
                "sequenceNumber": 303
            },
            {
                "refListItemId": 7330,
                "enabled": true,
                "defaultString": "Calf, nonspecific",
                "sequenceNumber": 304
            },
            {
                "refListItemId": 7331,
                "enabled": true,
                "defaultString": "Calf, left",
                "sequenceNumber": 305
            },
            {
                "refListItemId": 7332,
                "enabled": true,
                "defaultString": "Calf, right",
                "sequenceNumber": 306
            },
            {
                "refListItemId": 7333,
                "enabled": true,
                "defaultString": "Elbow, nonspecific",
                "sequenceNumber": 307
            },
            {
                "refListItemId": 7334,
                "enabled": true,
                "defaultString": "Elbow, left",
                "sequenceNumber": 308
            },
            {
                "refListItemId": 7335,
                "enabled": true,
                "defaultString": "Elbow, right",
                "sequenceNumber": 309
            },
            {
                "refListItemId": 7336,
                "enabled": true,
                "defaultString": "Eyebrow, nonspecific",
                "sequenceNumber": 310
            },
            {
                "refListItemId": 7337,
                "enabled": true,
                "defaultString": "Face, nonspecific",
                "sequenceNumber": 311
            },
            {
                "refListItemId": 7338,
                "enabled": true,
                "defaultString": "Finger, nonspecific",
                "sequenceNumber": 312
            },
            {
                "refListItemId": 7339,
                "enabled": true,
                "defaultString": "Forearm, nonspecific",
                "sequenceNumber": 313
            },
            {
                "refListItemId": 7340,
                "enabled": true,
                "defaultString": "Forearm, left",
                "sequenceNumber": 314
            },
            {
                "refListItemId": 7341,
                "enabled": true,
                "defaultString": "Forearm, right",
                "sequenceNumber": 315
            },
            {
                "refListItemId": 7342,
                "enabled": true,
                "defaultString": "Hip, left",
                "sequenceNumber": 316
            },
            {
                "refListItemId": 7343,
                "enabled": true,
                "defaultString": "Leg, left, nonspecific",
                "sequenceNumber": 317
            },
            {
                "refListItemId": 7344,
                "enabled": true,
                "defaultString": "Leg, right, nonspecific",
                "sequenceNumber": 318
            },
            {
                "refListItemId": 7345,
                "enabled": true,
                "defaultString": "Pockmarks",
                "sequenceNumber": 319
            },
            {
                "refListItemId": 7347,
                "enabled": true,
                "defaultString": "Head",
                "sequenceNumber": 320
            },
            {
                "refListItemId": 7349,
                "enabled": true,
                "defaultString": "Full body",
                "sequenceNumber": 321
            },
            {
                "refListItemId": 7352,
                "enabled": true,
                "defaultString": "Analgesics",
                "sequenceNumber": 322
            },
            {
                "refListItemId": 7353,
                "enabled": true,
                "defaultString": "Antibiotics",
                "sequenceNumber": 323
            },
            {
                "refListItemId": 7354,
                "enabled": true,
                "defaultString": "Anticonvulsants",
                "sequenceNumber": 324
            },
            {
                "refListItemId": 7355,
                "enabled": true,
                "defaultString": "Antidepressants",
                "sequenceNumber": 325
            },
            {
                "refListItemId": 7356,
                "enabled": true,
                "defaultString": "Anti-inflammatory medicatio",
                "sequenceNumber": 326
            },
            {
                "refListItemId": 7357,
                "enabled": true,
                "defaultString": "Bronchial dilators",
                "sequenceNumber": 327
            },
            {
                "refListItemId": 7358,
                "enabled": true,
                "defaultString": "Cardiac",
                "sequenceNumber": 328
            },
            {
                "refListItemId": 7359,
                "enabled": true,
                "defaultString": "Hypnotics",
                "sequenceNumber": 329
            },
            {
                "refListItemId": 7360,
                "enabled": true,
                "defaultString": "Insulin",
                "sequenceNumber": 330
            },
            {
                "refListItemId": 7361,
                "enabled": true,
                "defaultString": "Tranquilizers",
                "sequenceNumber": 331
            },
            {
                "refListItemId": 24047,
                "enabled": true,
                "defaultString": "Artificial shoulder, right",
                "sequenceNumber": 339
            },
            {
                "refListItemId": 24048,
                "enabled": true,
                "defaultString": "Dimple, face",
                "sequenceNumber": 340
            },
            {
                "refListItemId": 24040,
                "enabled": true,
                "defaultString": "Artificial elbow, left",
                "sequenceNumber": 332
            },
            {
                "refListItemId": 24041,
                "enabled": true,
                "defaultString": "Artificial hip, left",
                "sequenceNumber": 333
            },
            {
                "refListItemId": 24042,
                "enabled": true,
                "defaultString": "Artificial knee, left",
                "sequenceNumber": 334
            },
            {
                "refListItemId": 24043,
                "enabled": true,
                "defaultString": "Artificial shoulder, left",
                "sequenceNumber": 335
            },
            {
                "refListItemId": 24044,
                "enabled": true,
                "defaultString": "Artificial elbow, right",
                "sequenceNumber": 336
            },
            {
                "refListItemId": 24045,
                "enabled": true,
                "defaultString": "Artificial hip, right",
                "sequenceNumber": 337
            },
            {
                "refListItemId": 24046,
                "enabled": true,
                "defaultString": "Artificial knee, right",
                "sequenceNumber": 338
            },
            {
                "refListItemId": 24049,
                "enabled": true,
                "defaultString": "Groin",
                "sequenceNumber": 341
            },
            {
                "refListItemId": 24050,
                "enabled": true,
                "defaultString": "Pulminary diseases",
                "sequenceNumber": 342
            },
            {
                "refListItemId": 24051,
                "enabled": true,
                "defaultString": "Toe, left",
                "sequenceNumber": 343
            },
            {
                "refListItemId": 24052,
                "enabled": true,
                "defaultString": "Toe, right",
                "sequenceNumber": 344
            }
        ]
    },
    {
        "RefListID": 506,
        "refListName": "Housing_Agency",
        "refListItems": [
            {
                "refListItemId": 7362,
                "enabled": true,
                "defaultString": "Jail",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7363,
                "enabled": false,
                "defaultString": "Medium",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7364,
                "enabled": true,
                "defaultString": "Workhouse",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 19307,
                "enabled": true,
                "defaultString": "Boarding",
                "longString": "",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 507,
        "refListName": "Block",
        "refListItems": [
            {
                "refListItemId": 7365,
                "enabled": true,
                "defaultString": "Unit 1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7458,
                "enabled": true,
                "defaultString": "Unit 2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7551,
                "enabled": true,
                "defaultString": "Unit 3",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7587,
                "enabled": true,
                "defaultString": "Unit 4",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 7604,
                "enabled": true,
                "defaultString": "Unit 5",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 7659,
                "enabled": true,
                "defaultString": "Unit 6",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7752,
                "enabled": true,
                "defaultString": "Admin Seg",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 7761,
                "enabled": true,
                "defaultString": "Court Hold",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 7829,
                "enabled": true,
                "defaultString": "Hold",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 7908,
                "enabled": true,
                "defaultString": "Wet",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 7917,
                "enabled": true,
                "defaultString": "Safety",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 7920,
                "enabled": false,
                "defaultString": "NIC",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 7933,
                "enabled": true,
                "defaultString": "K Pod",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 7980,
                "enabled": true,
                "defaultString": "L Pod",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 8029,
                "enabled": true,
                "defaultString": "Seg A",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 8036,
                "enabled": true,
                "defaultString": "Seg D",
                "sequenceNumber": 9029
            },
            {
                "refListItemId": 8043,
                "enabled": true,
                "defaultString": "C Pod",
                "sequenceNumber": 9027
            },
            {
                "refListItemId": 8092,
                "enabled": true,
                "defaultString": "B Pod",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 8139,
                "enabled": true,
                "defaultString": "Seg C",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 8148,
                "enabled": true,
                "defaultString": "FL1",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 8221,
                "enabled": true,
                "defaultString": "FL2",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 8345,
                "enabled": true,
                "defaultString": "FL3",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 8438,
                "enabled": false,
                "defaultString": "In Transit",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 15454,
                "enabled": true,
                "defaultString": "Booking",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 15463,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 16523,
                "enabled": true,
                "defaultString": "D Pod",
                "sequenceNumber": 9028
            },
            {
                "refListItemId": 19308,
                "enabled": true,
                "defaultString": "Hospital",
                "sequenceNumber": 9029
            },
            {
                "refListItemId": 19309,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 9030
            },
            {
                "refListItemId": 19310,
                "enabled": true,
                "defaultString": "Sherburne",
                "sequenceNumber": 9031
            }
        ]
    },
    {
        "RefListID": 508,
        "refListName": "Cell",
        "refListItems": [
            {
                "refListItemId": 7484,
                "enabled": true,
                "defaultString": "326",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 7485,
                "enabled": true,
                "defaultString": "327",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 7486,
                "enabled": true,
                "defaultString": "328",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 7487,
                "enabled": true,
                "defaultString": "329",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 7488,
                "enabled": true,
                "defaultString": "330",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 7489,
                "enabled": true,
                "defaultString": "331",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 7490,
                "enabled": true,
                "defaultString": "332",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 7477,
                "enabled": true,
                "defaultString": "319",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 7478,
                "enabled": true,
                "defaultString": "320",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 7479,
                "enabled": true,
                "defaultString": "321",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 7480,
                "enabled": true,
                "defaultString": "322",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 7481,
                "enabled": true,
                "defaultString": "323",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 7482,
                "enabled": true,
                "defaultString": "324",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 7483,
                "enabled": true,
                "defaultString": "325",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 7366,
                "enabled": true,
                "defaultString": "201",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 7367,
                "enabled": true,
                "defaultString": "202",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 7368,
                "enabled": true,
                "defaultString": "203",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 7369,
                "enabled": true,
                "defaultString": "204",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 7370,
                "enabled": true,
                "defaultString": "205",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 7371,
                "enabled": true,
                "defaultString": "206",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7372,
                "enabled": true,
                "defaultString": "207",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 7373,
                "enabled": true,
                "defaultString": "208",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 7374,
                "enabled": true,
                "defaultString": "209",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 7375,
                "enabled": true,
                "defaultString": "210",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 7376,
                "enabled": true,
                "defaultString": "211",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 7377,
                "enabled": true,
                "defaultString": "212",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 7378,
                "enabled": true,
                "defaultString": "213",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 7379,
                "enabled": true,
                "defaultString": "214",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 7380,
                "enabled": true,
                "defaultString": "215",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 7381,
                "enabled": true,
                "defaultString": "216",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 7382,
                "enabled": true,
                "defaultString": "301",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 7383,
                "enabled": true,
                "defaultString": "302",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 7384,
                "enabled": true,
                "defaultString": "303",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 7385,
                "enabled": true,
                "defaultString": "304",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 7386,
                "enabled": true,
                "defaultString": "305",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 7387,
                "enabled": true,
                "defaultString": "306",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 7388,
                "enabled": true,
                "defaultString": "307",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 7389,
                "enabled": true,
                "defaultString": "308",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 7390,
                "enabled": true,
                "defaultString": "309",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 7391,
                "enabled": true,
                "defaultString": "310",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 7392,
                "enabled": true,
                "defaultString": "311",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 7393,
                "enabled": true,
                "defaultString": "312",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 7394,
                "enabled": true,
                "defaultString": "313",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 7395,
                "enabled": true,
                "defaultString": "314",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 7396,
                "enabled": true,
                "defaultString": "315",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 7397,
                "enabled": true,
                "defaultString": "316",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 7459,
                "enabled": true,
                "defaultString": "217",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 7460,
                "enabled": true,
                "defaultString": "218",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 7461,
                "enabled": true,
                "defaultString": "219",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 7462,
                "enabled": true,
                "defaultString": "220",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 7463,
                "enabled": true,
                "defaultString": "221",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 7464,
                "enabled": true,
                "defaultString": "222",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 7465,
                "enabled": true,
                "defaultString": "223",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 7466,
                "enabled": true,
                "defaultString": "224",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 7467,
                "enabled": true,
                "defaultString": "225",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 7468,
                "enabled": true,
                "defaultString": "226",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 7469,
                "enabled": true,
                "defaultString": "227",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 7470,
                "enabled": true,
                "defaultString": "228",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 7471,
                "enabled": true,
                "defaultString": "229",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 7472,
                "enabled": true,
                "defaultString": "230",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 7473,
                "enabled": true,
                "defaultString": "231",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 7474,
                "enabled": true,
                "defaultString": "232",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 7475,
                "enabled": true,
                "defaultString": "317",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 7476,
                "enabled": true,
                "defaultString": "318",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 7552,
                "enabled": true,
                "defaultString": "251",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 7553,
                "enabled": true,
                "defaultString": "252",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 7554,
                "enabled": true,
                "defaultString": "253",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 7555,
                "enabled": true,
                "defaultString": "254",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 7556,
                "enabled": true,
                "defaultString": "255",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 7557,
                "enabled": true,
                "defaultString": "256",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 7558,
                "enabled": true,
                "defaultString": "257",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 7559,
                "enabled": true,
                "defaultString": "258",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 7560,
                "enabled": true,
                "defaultString": "259",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 7561,
                "enabled": true,
                "defaultString": "260",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 7562,
                "enabled": true,
                "defaultString": "261",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 7563,
                "enabled": true,
                "defaultString": "262",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 7564,
                "enabled": true,
                "defaultString": "263",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 7588,
                "enabled": true,
                "defaultString": "401",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 7589,
                "enabled": true,
                "defaultString": "402",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 7590,
                "enabled": true,
                "defaultString": "403",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 7591,
                "enabled": true,
                "defaultString": "404",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 7592,
                "enabled": true,
                "defaultString": "501",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 7593,
                "enabled": true,
                "defaultString": "502",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 7594,
                "enabled": true,
                "defaultString": "503",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 7595,
                "enabled": true,
                "defaultString": "504",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 7605,
                "enabled": true,
                "defaultString": "405",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 7606,
                "enabled": true,
                "defaultString": "406",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 7607,
                "enabled": true,
                "defaultString": "407",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 7608,
                "enabled": true,
                "defaultString": "408",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 7609,
                "enabled": true,
                "defaultString": "409",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 7610,
                "enabled": true,
                "defaultString": "410",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 7611,
                "enabled": true,
                "defaultString": "411",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 7612,
                "enabled": true,
                "defaultString": "412",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 7613,
                "enabled": true,
                "defaultString": "413",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 7614,
                "enabled": true,
                "defaultString": "414",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 7615,
                "enabled": true,
                "defaultString": "415",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 7616,
                "enabled": true,
                "defaultString": "416",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 7617,
                "enabled": true,
                "defaultString": "505",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 7618,
                "enabled": true,
                "defaultString": "506",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 7619,
                "enabled": true,
                "defaultString": "507",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 7620,
                "enabled": true,
                "defaultString": "508",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 7621,
                "enabled": true,
                "defaultString": "509",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 7622,
                "enabled": true,
                "defaultString": "510",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 7623,
                "enabled": true,
                "defaultString": "511",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 7624,
                "enabled": true,
                "defaultString": "512",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 7625,
                "enabled": true,
                "defaultString": "513",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 7626,
                "enabled": true,
                "defaultString": "514",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 7627,
                "enabled": true,
                "defaultString": "515",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 7628,
                "enabled": true,
                "defaultString": "516",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 7660,
                "enabled": true,
                "defaultString": "417",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 7661,
                "enabled": true,
                "defaultString": "418",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 7662,
                "enabled": true,
                "defaultString": "419",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 7663,
                "enabled": true,
                "defaultString": "420",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 7664,
                "enabled": true,
                "defaultString": "421",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 7665,
                "enabled": true,
                "defaultString": "422",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 7666,
                "enabled": true,
                "defaultString": "423",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 7667,
                "enabled": true,
                "defaultString": "424",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 7668,
                "enabled": true,
                "defaultString": "425",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 7669,
                "enabled": true,
                "defaultString": "426",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 7670,
                "enabled": true,
                "defaultString": "427",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 7671,
                "enabled": true,
                "defaultString": "428",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 7672,
                "enabled": true,
                "defaultString": "429",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 7673,
                "enabled": true,
                "defaultString": "430",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 7674,
                "enabled": true,
                "defaultString": "431",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 7675,
                "enabled": true,
                "defaultString": "432",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 7676,
                "enabled": true,
                "defaultString": "517",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 7677,
                "enabled": true,
                "defaultString": "518",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 7678,
                "enabled": true,
                "defaultString": "519",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 7679,
                "enabled": true,
                "defaultString": "520",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 7680,
                "enabled": true,
                "defaultString": "521",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 7681,
                "enabled": true,
                "defaultString": "522",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 7682,
                "enabled": true,
                "defaultString": "523",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 7683,
                "enabled": true,
                "defaultString": "524",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 7684,
                "enabled": true,
                "defaultString": "525",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 7685,
                "enabled": true,
                "defaultString": "526",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 7686,
                "enabled": true,
                "defaultString": "527",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 7687,
                "enabled": true,
                "defaultString": "528",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 7688,
                "enabled": true,
                "defaultString": "529",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 7689,
                "enabled": true,
                "defaultString": "530",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 7690,
                "enabled": true,
                "defaultString": "531",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 7691,
                "enabled": true,
                "defaultString": "532",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 7753,
                "enabled": true,
                "defaultString": "146",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 7754,
                "enabled": true,
                "defaultString": "147",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 7755,
                "enabled": true,
                "defaultString": "148",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 7756,
                "enabled": true,
                "defaultString": "149",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 7762,
                "enabled": true,
                "defaultString": "349",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 7763,
                "enabled": true,
                "defaultString": "357",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 7764,
                "enabled": true,
                "defaultString": "358",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 7765,
                "enabled": true,
                "defaultString": "359",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 7766,
                "enabled": true,
                "defaultString": "360",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 7830,
                "enabled": true,
                "defaultString": "H1",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 7831,
                "enabled": true,
                "defaultString": "H2",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 7832,
                "enabled": true,
                "defaultString": "H3",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 7909,
                "enabled": true,
                "defaultString": "141",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 7910,
                "enabled": true,
                "defaultString": "142",
                "sequenceNumber": 155
            },
            {
                "refListItemId": 7911,
                "enabled": true,
                "defaultString": "143",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 7912,
                "enabled": true,
                "defaultString": "144",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 7918,
                "enabled": true,
                "defaultString": "145",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 7921,
                "enabled": true,
                "defaultString": "NIC-1",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 7922,
                "enabled": true,
                "defaultString": "NIC-2",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 7923,
                "enabled": true,
                "defaultString": "NIC-3",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 7924,
                "enabled": true,
                "defaultString": "NIC-4",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 7925,
                "enabled": true,
                "defaultString": "NIC-5",
                "sequenceNumber": 163
            },
            {
                "refListItemId": 7926,
                "enabled": true,
                "defaultString": "NIC-6",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 7934,
                "enabled": true,
                "defaultString": "K01",
                "sequenceNumber": 165
            },
            {
                "refListItemId": 7935,
                "enabled": true,
                "defaultString": "K02",
                "sequenceNumber": 166
            },
            {
                "refListItemId": 7936,
                "enabled": true,
                "defaultString": "K03",
                "sequenceNumber": 167
            },
            {
                "refListItemId": 7937,
                "enabled": true,
                "defaultString": "K04",
                "sequenceNumber": 168
            },
            {
                "refListItemId": 7938,
                "enabled": true,
                "defaultString": "K05",
                "sequenceNumber": 169
            },
            {
                "refListItemId": 7939,
                "enabled": true,
                "defaultString": "K06",
                "sequenceNumber": 170
            },
            {
                "refListItemId": 7940,
                "enabled": true,
                "defaultString": "K07",
                "sequenceNumber": 171
            },
            {
                "refListItemId": 7941,
                "enabled": true,
                "defaultString": "K08",
                "sequenceNumber": 172
            },
            {
                "refListItemId": 7942,
                "enabled": true,
                "defaultString": "K09",
                "sequenceNumber": 173
            },
            {
                "refListItemId": 7943,
                "enabled": true,
                "defaultString": "K10",
                "sequenceNumber": 174
            },
            {
                "refListItemId": 7944,
                "enabled": true,
                "defaultString": "K11",
                "sequenceNumber": 175
            },
            {
                "refListItemId": 7945,
                "enabled": true,
                "defaultString": "K12",
                "sequenceNumber": 176
            },
            {
                "refListItemId": 7946,
                "enabled": true,
                "defaultString": "K13",
                "sequenceNumber": 177
            },
            {
                "refListItemId": 7947,
                "enabled": true,
                "defaultString": "K14",
                "sequenceNumber": 178
            },
            {
                "refListItemId": 7948,
                "enabled": true,
                "defaultString": "K15",
                "sequenceNumber": 179
            },
            {
                "refListItemId": 7949,
                "enabled": true,
                "defaultString": "K16",
                "sequenceNumber": 180
            },
            {
                "refListItemId": 7981,
                "enabled": true,
                "defaultString": "L01",
                "sequenceNumber": 181
            },
            {
                "refListItemId": 7982,
                "enabled": true,
                "defaultString": "L02",
                "sequenceNumber": 182
            },
            {
                "refListItemId": 7983,
                "enabled": true,
                "defaultString": "L03",
                "sequenceNumber": 183
            },
            {
                "refListItemId": 7984,
                "enabled": true,
                "defaultString": "L04",
                "sequenceNumber": 184
            },
            {
                "refListItemId": 7985,
                "enabled": true,
                "defaultString": "L05",
                "sequenceNumber": 185
            },
            {
                "refListItemId": 7986,
                "enabled": true,
                "defaultString": "L06",
                "sequenceNumber": 186
            },
            {
                "refListItemId": 7987,
                "enabled": true,
                "defaultString": "L07",
                "sequenceNumber": 187
            },
            {
                "refListItemId": 7988,
                "enabled": true,
                "defaultString": "L08",
                "sequenceNumber": 188
            },
            {
                "refListItemId": 7989,
                "enabled": true,
                "defaultString": "L09",
                "sequenceNumber": 189
            },
            {
                "refListItemId": 7990,
                "enabled": true,
                "defaultString": "L10",
                "sequenceNumber": 190
            },
            {
                "refListItemId": 7991,
                "enabled": true,
                "defaultString": "L11",
                "sequenceNumber": 191
            },
            {
                "refListItemId": 7992,
                "enabled": true,
                "defaultString": "L12",
                "sequenceNumber": 192
            },
            {
                "refListItemId": 7993,
                "enabled": true,
                "defaultString": "L13",
                "sequenceNumber": 193
            },
            {
                "refListItemId": 7994,
                "enabled": true,
                "defaultString": "L14",
                "sequenceNumber": 194
            },
            {
                "refListItemId": 7995,
                "enabled": true,
                "defaultString": "L15",
                "sequenceNumber": 195
            },
            {
                "refListItemId": 7996,
                "enabled": true,
                "defaultString": "L16",
                "sequenceNumber": 196
            },
            {
                "refListItemId": 8030,
                "enabled": false,
                "defaultString": "SA1",
                "sequenceNumber": 197
            },
            {
                "refListItemId": 8031,
                "enabled": true,
                "defaultString": "SA2",
                "sequenceNumber": 198
            },
            {
                "refListItemId": 8032,
                "enabled": true,
                "defaultString": "SA3",
                "sequenceNumber": 199
            },
            {
                "refListItemId": 8037,
                "enabled": false,
                "defaultString": "SB1",
                "sequenceNumber": 200
            },
            {
                "refListItemId": 8038,
                "enabled": true,
                "defaultString": "SD2",
                "sequenceNumber": 201
            },
            {
                "refListItemId": 8039,
                "enabled": true,
                "defaultString": "SD3",
                "sequenceNumber": 202
            },
            {
                "refListItemId": 8044,
                "enabled": true,
                "defaultString": "C01",
                "sequenceNumber": 203
            },
            {
                "refListItemId": 8045,
                "enabled": true,
                "defaultString": "C02",
                "sequenceNumber": 204
            },
            {
                "refListItemId": 8046,
                "enabled": true,
                "defaultString": "C03",
                "sequenceNumber": 205
            },
            {
                "refListItemId": 8047,
                "enabled": true,
                "defaultString": "C04",
                "sequenceNumber": 206
            },
            {
                "refListItemId": 8048,
                "enabled": true,
                "defaultString": "C05",
                "sequenceNumber": 207
            },
            {
                "refListItemId": 8049,
                "enabled": true,
                "defaultString": "C06",
                "sequenceNumber": 208
            },
            {
                "refListItemId": 8050,
                "enabled": true,
                "defaultString": "C07",
                "sequenceNumber": 209
            },
            {
                "refListItemId": 8051,
                "enabled": true,
                "defaultString": "C08",
                "sequenceNumber": 210
            },
            {
                "refListItemId": 8052,
                "enabled": true,
                "defaultString": "C09",
                "sequenceNumber": 211
            },
            {
                "refListItemId": 8053,
                "enabled": true,
                "defaultString": "C10",
                "sequenceNumber": 212
            },
            {
                "refListItemId": 8054,
                "enabled": true,
                "defaultString": "C011",
                "sequenceNumber": 213
            },
            {
                "refListItemId": 8055,
                "enabled": true,
                "defaultString": "C012",
                "sequenceNumber": 214
            },
            {
                "refListItemId": 8056,
                "enabled": true,
                "defaultString": "C013",
                "sequenceNumber": 215
            },
            {
                "refListItemId": 8057,
                "enabled": true,
                "defaultString": "C014",
                "sequenceNumber": 216
            },
            {
                "refListItemId": 8058,
                "enabled": true,
                "defaultString": "C015",
                "sequenceNumber": 217
            },
            {
                "refListItemId": 8059,
                "enabled": true,
                "defaultString": "C016",
                "sequenceNumber": 218
            },
            {
                "refListItemId": 8093,
                "enabled": true,
                "defaultString": "B01",
                "sequenceNumber": 219
            },
            {
                "refListItemId": 8094,
                "enabled": true,
                "defaultString": "B02",
                "sequenceNumber": 220
            },
            {
                "refListItemId": 8095,
                "enabled": true,
                "defaultString": "B03",
                "sequenceNumber": 221
            },
            {
                "refListItemId": 8096,
                "enabled": true,
                "defaultString": "B04",
                "sequenceNumber": 222
            },
            {
                "refListItemId": 8097,
                "enabled": true,
                "defaultString": "B05",
                "sequenceNumber": 223
            },
            {
                "refListItemId": 8098,
                "enabled": true,
                "defaultString": "B06",
                "sequenceNumber": 224
            },
            {
                "refListItemId": 8099,
                "enabled": true,
                "defaultString": "B07",
                "sequenceNumber": 225
            },
            {
                "refListItemId": 8100,
                "enabled": true,
                "defaultString": "B08",
                "sequenceNumber": 226
            },
            {
                "refListItemId": 8101,
                "enabled": true,
                "defaultString": "B09",
                "sequenceNumber": 227
            },
            {
                "refListItemId": 8102,
                "enabled": true,
                "defaultString": "B10",
                "sequenceNumber": 228
            },
            {
                "refListItemId": 8103,
                "enabled": true,
                "defaultString": "B11",
                "sequenceNumber": 229
            },
            {
                "refListItemId": 8104,
                "enabled": true,
                "defaultString": "B12",
                "sequenceNumber": 230
            },
            {
                "refListItemId": 8105,
                "enabled": true,
                "defaultString": "B13",
                "sequenceNumber": 231
            },
            {
                "refListItemId": 8106,
                "enabled": true,
                "defaultString": "B14",
                "sequenceNumber": 232
            },
            {
                "refListItemId": 8107,
                "enabled": true,
                "defaultString": "B15",
                "sequenceNumber": 233
            },
            {
                "refListItemId": 8108,
                "enabled": true,
                "defaultString": "B16",
                "sequenceNumber": 234
            },
            {
                "refListItemId": 8140,
                "enabled": true,
                "defaultString": "SCD3",
                "sequenceNumber": 235
            },
            {
                "refListItemId": 8141,
                "enabled": true,
                "defaultString": "SC1",
                "sequenceNumber": 236
            },
            {
                "refListItemId": 8142,
                "enabled": true,
                "defaultString": "SC2",
                "sequenceNumber": 237
            },
            {
                "refListItemId": 8143,
                "enabled": true,
                "defaultString": "SC3",
                "sequenceNumber": 238
            },
            {
                "refListItemId": 8149,
                "enabled": true,
                "defaultString": "E108",
                "sequenceNumber": 239
            },
            {
                "refListItemId": 8159,
                "enabled": true,
                "defaultString": "E110",
                "sequenceNumber": 240
            },
            {
                "refListItemId": 8169,
                "enabled": true,
                "defaultString": "E111",
                "sequenceNumber": 241
            },
            {
                "refListItemId": 8176,
                "enabled": true,
                "defaultString": "E112",
                "sequenceNumber": 242
            },
            {
                "refListItemId": 8183,
                "enabled": true,
                "defaultString": "W126",
                "sequenceNumber": 243
            },
            {
                "refListItemId": 8191,
                "enabled": true,
                "defaultString": "W130",
                "sequenceNumber": 244
            },
            {
                "refListItemId": 8199,
                "enabled": true,
                "defaultString": "W131",
                "sequenceNumber": 245
            },
            {
                "refListItemId": 8203,
                "enabled": true,
                "defaultString": "W132",
                "sequenceNumber": 246
            },
            {
                "refListItemId": 8207,
                "enabled": true,
                "defaultString": "W133",
                "sequenceNumber": 247
            },
            {
                "refListItemId": 8214,
                "enabled": true,
                "defaultString": "W135",
                "sequenceNumber": 248
            },
            {
                "refListItemId": 8222,
                "enabled": true,
                "defaultString": "E202",
                "sequenceNumber": 249
            },
            {
                "refListItemId": 8229,
                "enabled": true,
                "defaultString": "E210",
                "sequenceNumber": 250
            },
            {
                "refListItemId": 8236,
                "enabled": true,
                "defaultString": "E211",
                "sequenceNumber": 251
            },
            {
                "refListItemId": 8245,
                "enabled": true,
                "defaultString": "E212",
                "sequenceNumber": 252
            },
            {
                "refListItemId": 8253,
                "enabled": true,
                "defaultString": "E213",
                "sequenceNumber": 253
            },
            {
                "refListItemId": 8260,
                "enabled": true,
                "defaultString": "E214",
                "sequenceNumber": 254
            },
            {
                "refListItemId": 8267,
                "enabled": true,
                "defaultString": "E215",
                "sequenceNumber": 255
            },
            {
                "refListItemId": 8274,
                "enabled": true,
                "defaultString": "E216",
                "sequenceNumber": 256
            },
            {
                "refListItemId": 8281,
                "enabled": true,
                "defaultString": "E217",
                "sequenceNumber": 257
            },
            {
                "refListItemId": 8288,
                "enabled": true,
                "defaultString": "W227",
                "sequenceNumber": 258
            },
            {
                "refListItemId": 8295,
                "enabled": true,
                "defaultString": "W228",
                "sequenceNumber": 259
            },
            {
                "refListItemId": 8302,
                "enabled": true,
                "defaultString": "W229",
                "sequenceNumber": 260
            },
            {
                "refListItemId": 8309,
                "enabled": true,
                "defaultString": "W230",
                "sequenceNumber": 261
            },
            {
                "refListItemId": 8316,
                "enabled": true,
                "defaultString": "W231",
                "sequenceNumber": 262
            },
            {
                "refListItemId": 8323,
                "enabled": true,
                "defaultString": "W232",
                "sequenceNumber": 263
            },
            {
                "refListItemId": 8331,
                "enabled": true,
                "defaultString": "W233",
                "sequenceNumber": 264
            },
            {
                "refListItemId": 8338,
                "enabled": true,
                "defaultString": "W234",
                "sequenceNumber": 265
            },
            {
                "refListItemId": 8346,
                "enabled": true,
                "defaultString": "E308",
                "sequenceNumber": 266
            },
            {
                "refListItemId": 8355,
                "enabled": true,
                "defaultString": "E310",
                "sequenceNumber": 267
            },
            {
                "refListItemId": 8364,
                "enabled": true,
                "defaultString": "E311",
                "sequenceNumber": 268
            },
            {
                "refListItemId": 8371,
                "enabled": true,
                "defaultString": "E313",
                "sequenceNumber": 269
            },
            {
                "refListItemId": 8378,
                "enabled": true,
                "defaultString": "E314",
                "sequenceNumber": 3027
            },
            {
                "refListItemId": 8387,
                "enabled": true,
                "defaultString": "E315",
                "sequenceNumber": 271
            },
            {
                "refListItemId": 8396,
                "enabled": true,
                "defaultString": "W328",
                "sequenceNumber": 272
            },
            {
                "refListItemId": 8403,
                "enabled": true,
                "defaultString": "W330",
                "sequenceNumber": 273
            },
            {
                "refListItemId": 8412,
                "enabled": true,
                "defaultString": "W331",
                "sequenceNumber": 274
            },
            {
                "refListItemId": 8418,
                "enabled": true,
                "defaultString": "W333",
                "sequenceNumber": 275
            },
            {
                "refListItemId": 8424,
                "enabled": true,
                "defaultString": "W334",
                "sequenceNumber": 276
            },
            {
                "refListItemId": 8431,
                "enabled": true,
                "defaultString": "W335",
                "sequenceNumber": 277
            },
            {
                "refListItemId": 15453,
                "enabled": true,
                "defaultString": "361",
                "sequenceNumber": 3038
            },
            {
                "refListItemId": 15455,
                "enabled": true,
                "defaultString": "132",
                "sequenceNumber": 3039
            },
            {
                "refListItemId": 15456,
                "enabled": true,
                "defaultString": "138",
                "sequenceNumber": 3040
            },
            {
                "refListItemId": 15457,
                "enabled": true,
                "defaultString": "139",
                "sequenceNumber": 3041
            },
            {
                "refListItemId": 15458,
                "enabled": true,
                "defaultString": "140",
                "sequenceNumber": 3042
            },
            {
                "refListItemId": 15459,
                "enabled": true,
                "defaultString": "155A",
                "sequenceNumber": 3043
            },
            {
                "refListItemId": 15460,
                "enabled": true,
                "defaultString": "156A",
                "sequenceNumber": 3044
            },
            {
                "refListItemId": 15461,
                "enabled": true,
                "defaultString": "RWR",
                "sequenceNumber": 3045
            },
            {
                "refListItemId": 15462,
                "enabled": true,
                "defaultString": "VISIT",
                "sequenceNumber": 3046
            },
            {
                "refListItemId": 15464,
                "enabled": true,
                "defaultString": "MED",
                "sequenceNumber": 3047
            },
            {
                "refListItemId": 15465,
                "enabled": true,
                "defaultString": "351",
                "sequenceNumber": 3048
            },
            {
                "refListItemId": 15466,
                "enabled": true,
                "defaultString": "352",
                "sequenceNumber": 3049
            },
            {
                "refListItemId": 15467,
                "enabled": true,
                "defaultString": "353",
                "sequenceNumber": 3050
            },
            {
                "refListItemId": 15970,
                "enabled": true,
                "defaultString": "355",
                "sequenceNumber": 3051
            },
            {
                "refListItemId": 15975,
                "enabled": true,
                "defaultString": "ESB",
                "sequenceNumber": 3053
            }
        ]
    },
    {
        "RefListID": 509,
        "refListName": "Bed",
        "refListItems": ""
    },
    {
        "RefListID": 623,
        "refListName": "JailIncidentsSubType",
        "refListItems": [
            {
                "refListItemId": 18165,
                "enabled": true,
                "defaultString": "Assaults",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18166,
                "enabled": true,
                "defaultString": "Escape",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18167,
                "enabled": true,
                "defaultString": "Inmate Violation",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18168,
                "enabled": true,
                "defaultString": "Medical",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18169,
                "enabled": true,
                "defaultString": "Use of Force",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18170,
                "enabled": true,
                "defaultString": "Maintenance Issue",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18171,
                "enabled": true,
                "defaultString": "Facility Report",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18172,
                "enabled": true,
                "defaultString": "Sergeant Report",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18173,
                "enabled": true,
                "defaultString": "Informational",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18174,
                "enabled": true,
                "defaultString": "No Contact",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 624,
        "refListName": "JailIncidentsQualifier",
        "refListItems": [
            {
                "refListItemId": 18175,
                "enabled": true,
                "defaultString": "Inmate on Inmate",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18176,
                "enabled": true,
                "defaultString": "Inmate on Staff",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18177,
                "enabled": true,
                "defaultString": "Inmate on Visitor/Volunteer",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18178,
                "enabled": true,
                "defaultString": "Staff on Inmate",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18179,
                "enabled": true,
                "defaultString": "Escape",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18180,
                "enabled": true,
                "defaultString": "Attempted Escape",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18181,
                "enabled": true,
                "defaultString": "Fail to Return/Abscond",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18182,
                "enabled": true,
                "defaultString": "Class I",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18183,
                "enabled": true,
                "defaultString": "Class II",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18184,
                "enabled": true,
                "defaultString": "Class III",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18185,
                "enabled": true,
                "defaultString": "Illness/Injury",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18186,
                "enabled": true,
                "defaultString": "Medical Emergency",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18187,
                "enabled": true,
                "defaultString": "Mental Health Issue",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 18188,
                "enabled": true,
                "defaultString": "Self Injury",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18189,
                "enabled": true,
                "defaultString": "Suicide Attempt",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 18190,
                "enabled": true,
                "defaultString": "Suicide",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 18191,
                "enabled": true,
                "defaultString": "Dialogue",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 18192,
                "enabled": true,
                "defaultString": "Pain Compliance",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 18193,
                "enabled": true,
                "defaultString": "Escort Compliance",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 18194,
                "enabled": true,
                "defaultString": "Chemical Agent",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 18195,
                "enabled": true,
                "defaultString": "Unarmed Countermeasures",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 18196,
                "enabled": true,
                "defaultString": "Baton",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 18197,
                "enabled": true,
                "defaultString": "Firearms",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 18198,
                "enabled": true,
                "defaultString": "Pepperball",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 18199,
                "enabled": true,
                "defaultString": "Taser",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 18200,
                "enabled": true,
                "defaultString": "K-9",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 18201,
                "enabled": true,
                "defaultString": "Missing/Damaged Equipment",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 18202,
                "enabled": true,
                "defaultString": "Security Issue",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 18203,
                "enabled": true,
                "defaultString": "Safety Issue",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 18204,
                "enabled": true,
                "defaultString": "Health/Sanitation Issue",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 18205,
                "enabled": true,
                "defaultString": "Jail Issue",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 18206,
                "enabled": true,
                "defaultString": "Public Issue",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 18207,
                "enabled": true,
                "defaultString": "Law Enforcement Issue",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 18208,
                "enabled": true,
                "defaultString": "Courts Issue",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 18209,
                "enabled": true,
                "defaultString": "Misc Issue",
                "sequenceNumber": 40
            }
        ]
    },
    {
        "RefListID": 625,
        "refListName": "JailIncidentsMinorViolations",
        "refListItems": [
            {
                "refListItemId": 18210,
                "enabled": true,
                "defaultString": "01 Refusing to lockdown",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18211,
                "enabled": true,
                "defaultString": "02 Threats",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18212,
                "enabled": true,
                "defaultString": "03 Profanity and derogatory remarks",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18213,
                "enabled": true,
                "defaultString": "04 Disobeying Direct Order / Argue",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18214,
                "enabled": true,
                "defaultString": "05 Out of control behavior",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18215,
                "enabled": true,
                "defaultString": "06 Refusing cell assignment",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18216,
                "enabled": true,
                "defaultString": "07 Accumulation of class I violations",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18217,
                "enabled": true,
                "defaultString": "08 Disruptive behavior",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18218,
                "enabled": true,
                "defaultString": "09 Physical contact",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18219,
                "enabled": true,
                "defaultString": "10 Misuse of property",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18220,
                "enabled": true,
                "defaultString": "11 Conspiracy",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18221,
                "enabled": true,
                "defaultString": "12 Unauthorized contacts",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18222,
                "enabled": true,
                "defaultString": "13 Unauthorized areas/within facility",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 18223,
                "enabled": true,
                "defaultString": "14 Failure to report or perform",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18224,
                "enabled": true,
                "defaultString": "15 Failure to sign in or out",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 18225,
                "enabled": true,
                "defaultString": "16 Excessive work hours",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 18226,
                "enabled": true,
                "defaultString": "17 Return late or leave early",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 18227,
                "enabled": true,
                "defaultString": "18 Failure to report",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 18228,
                "enabled": true,
                "defaultString": "19 Staff desk",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 18229,
                "enabled": true,
                "defaultString": "20 False report",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 18230,
                "enabled": true,
                "defaultString": "21 General Rule Expectation",
                "sequenceNumber": 21
            }
        ]
    },
    {
        "RefListID": 626,
        "refListName": "JailIncidentsMajorViolations",
        "refListItems": [
            {
                "refListItemId": 18231,
                "enabled": true,
                "defaultString": "01 Tobacco or intoxicants",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18232,
                "enabled": true,
                "defaultString": "02 Damaging property",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18233,
                "enabled": true,
                "defaultString": "03 Committing Felony, GM, or MS",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18234,
                "enabled": true,
                "defaultString": "04 Hostage",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18235,
                "enabled": true,
                "defaultString": "05 Out of control behavior",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18236,
                "enabled": true,
                "defaultString": "06 Contraband",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18237,
                "enabled": true,
                "defaultString": "07 Accumulating medications",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18238,
                "enabled": true,
                "defaultString": "08 Tampering with security devices",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18239,
                "enabled": true,
                "defaultString": "09 Lying or false statements",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18240,
                "enabled": true,
                "defaultString": "10 Escape",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18241,
                "enabled": true,
                "defaultString": "11 Fighting",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18242,
                "enabled": true,
                "defaultString": "12 Theft",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18243,
                "enabled": true,
                "defaultString": "13 Riot",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 18244,
                "enabled": true,
                "defaultString": "14 Unauthorized organization",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18245,
                "enabled": true,
                "defaultString": "15 Arson",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 18246,
                "enabled": true,
                "defaultString": "16 Drug/alcohol tests",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 18247,
                "enabled": true,
                "defaultString": "17 Unauthorized contacts",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 18248,
                "enabled": true,
                "defaultString": "18 Sexual Misconduct",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 18249,
                "enabled": true,
                "defaultString": "19 Failure to return/abscond.",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 18250,
                "enabled": true,
                "defaultString": "20 Not following emergency plans",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 18251,
                "enabled": true,
                "defaultString": "21 Violating no contact order",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 18252,
                "enabled": true,
                "defaultString": "22 Flooding",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 18253,
                "enabled": true,
                "defaultString": "23 Tattooing",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 18254,
                "enabled": true,
                "defaultString": "24 Threats",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 18255,
                "enabled": true,
                "defaultString": "25 Refusing a search",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 18256,
                "enabled": true,
                "defaultString": "26 Inflicting self harm",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 18257,
                "enabled": true,
                "defaultString": "27 Public safety dog",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 18258,
                "enabled": true,
                "defaultString": "28 Accumulation of class II violations",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 18259,
                "enabled": true,
                "defaultString": "29 Unauthorized areas/outside facility",
                "sequenceNumber": 29
            }
        ]
    },
    {
        "RefListID": 632,
        "refListName": "JailIncidentsResults",
        "refListItems": [
            {
                "refListItemId": 18288,
                "enabled": true,
                "defaultString": "Guilty",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18289,
                "enabled": true,
                "defaultString": "Not Guilty",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18290,
                "enabled": true,
                "defaultString": "Deferred to Administration",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 629,
        "refListName": "JailIncidentsSanctions",
        "refListItems": [
            {
                "refListItemId": 18263,
                "enabled": true,
                "defaultString": "01 Lose Huber",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18264,
                "enabled": true,
                "defaultString": "02 No Library",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18265,
                "enabled": true,
                "defaultString": "03 Tran to Jail",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18266,
                "enabled": true,
                "defaultString": "04 Lockdown",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18267,
                "enabled": true,
                "defaultString": "05 Lose Visitation Rights",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18268,
                "enabled": true,
                "defaultString": "06 No Recreation",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18269,
                "enabled": true,
                "defaultString": "07 Lose Good Time",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18270,
                "enabled": true,
                "defaultString": "08 Lose Canteen",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18271,
                "enabled": true,
                "defaultString": "09 Bay Restriction",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18272,
                "enabled": true,
                "defaultString": "10 Other (see comments)",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18273,
                "enabled": true,
                "defaultString": "11 Delayed Release",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18274,
                "enabled": true,
                "defaultString": "12 Restitution",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 630,
        "refListName": "JailIncidentsCorrectiveMeasures",
        "refListItems": [
            {
                "refListItemId": 18275,
                "enabled": true,
                "defaultString": "Attend Anger Mgt",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18276,
                "enabled": true,
                "defaultString": "Meet w/ Counselor",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18277,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 631,
        "refListName": "JailIncidentsInvolvementType",
        "refListItems": [
            {
                "refListItemId": 18278,
                "enabled": true,
                "defaultString": "Suspect",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18279,
                "enabled": true,
                "defaultString": "Violator",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18280,
                "enabled": true,
                "defaultString": "Victim",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18281,
                "enabled": true,
                "defaultString": "Primary Officer",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18282,
                "enabled": true,
                "defaultString": "Assisting Officer",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18283,
                "enabled": true,
                "defaultString": "Investigator",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18284,
                "enabled": true,
                "defaultString": "Responding Police",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18285,
                "enabled": true,
                "defaultString": "Witness/Bystander",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18286,
                "enabled": true,
                "defaultString": "Contract Staff/Vendor",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18287,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 587,
        "refListName": "JailIncidentsStatus",
        "refListItems": [
            {
                "refListItemId": 16216,
                "enabled": true,
                "defaultString": "Approved",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16217,
                "enabled": true,
                "defaultString": "Rejected",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16218,
                "enabled": true,
                "defaultString": "Pending",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 588,
        "refListName": "JailIncidentsStatusAdministrator",
        "refListItems": [
            {
                "refListItemId": 16219,
                "enabled": true,
                "defaultString": "Approved",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16220,
                "enabled": true,
                "defaultString": "Rejected",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16221,
                "enabled": true,
                "defaultString": "Pending",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 589,
        "refListName": "BatchMovementSearchType",
        "refListItems": [
            {
                "refListItemId": 16222,
                "enabled": true,
                "defaultString": "Scheduled",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16223,
                "enabled": true,
                "defaultString": "Unscheduled",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16224,
                "enabled": true,
                "defaultString": "Batch Return",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16225,
                "enabled": true,
                "defaultString": "Batch Receive",
                "sequenceNumber": 4
            }
        ]
    },
    {
        "RefListID": 590,
        "refListName": "ReleaseQueueStep",
        "refListItems": [
            {
                "refListItemId": 16226,
                "enabled": true,
                "defaultString": "01 Charges Cleared",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16708,
                "enabled": true,
                "defaultString": "02 Warrant Check",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16709,
                "enabled": true,
                "defaultString": "03 Victim Notification",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16710,
                "enabled": true,
                "defaultString": "04 Flag Review",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16711,
                "enabled": true,
                "defaultString": "05 Supervisor Approved",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 591,
        "refListName": "IntakeQueueStatus",
        "refListItems": [
            {
                "refListItemId": 16227,
                "enabled": true,
                "defaultString": "En Route",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16228,
                "enabled": true,
                "defaultString": "Approved AR",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16229,
                "enabled": true,
                "defaultString": "Rejected AR",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16230,
                "enabled": true,
                "defaultString": "Approved Medical",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16231,
                "enabled": true,
                "defaultString": "Rejected Medical",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 16232,
                "enabled": true,
                "defaultString": "Scheduled",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 16233,
                "enabled": true,
                "defaultString": "Completed Medical Screening",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 592,
        "refListName": "Discipline_Required",
        "refListItems": [
            {
                "refListItemId": 16234,
                "enabled": true,
                "defaultString": "Discipline Required",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16235,
                "enabled": true,
                "defaultString": "Verbal Warning",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 593,
        "refListName": "PendingHearing",
        "refListItems": [
            {
                "refListItemId": 16236,
                "enabled": true,
                "defaultString": "Lockdown Pending Hearing",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16237,
                "enabled": true,
                "defaultString": "Hold-in Pending Hearing",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 594,
        "refListName": "JailIncidentsInvolvementType_Contexts",
        "refListItems": [
            {
                "refListItemId": 16238,
                "enabled": true,
                "defaultString": "Inmates",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 595,
        "refListName": "Occupation",
        "refListItems": [
            {
                "refListItemId": 16239,
                "enabled": true,
                "defaultString": "Accountant/Auditor",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 596,
        "refListName": "Religion",
        "refListItems": [
            {
                "refListItemId": 16241,
                "enabled": true,
                "defaultString": "Atheist",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16242,
                "enabled": true,
                "defaultString": "Baptist",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16243,
                "enabled": true,
                "defaultString": "Buddhist",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16244,
                "enabled": true,
                "defaultString": "Catholic",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16245,
                "enabled": true,
                "defaultString": "Christian",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 16246,
                "enabled": true,
                "defaultString": "Episcopalian",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 16247,
                "enabled": true,
                "defaultString": "Greek Orthodox",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 16248,
                "enabled": true,
                "defaultString": "Hindu",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 16249,
                "enabled": true,
                "defaultString": "Islam/Muslim",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 16250,
                "enabled": true,
                "defaultString": "Jehovahs Witnesses",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 16251,
                "enabled": true,
                "defaultString": "Jewish",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 16252,
                "enabled": true,
                "defaultString": "Lutheran",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 16253,
                "enabled": true,
                "defaultString": "Methodist",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 16254,
                "enabled": true,
                "defaultString": "Mormon",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 16255,
                "enabled": true,
                "defaultString": "Non-Denominational",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 16256,
                "enabled": true,
                "defaultString": "None",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 16257,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 16258,
                "enabled": true,
                "defaultString": "Pagan/Wiccan",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 16259,
                "enabled": true,
                "defaultString": "Pentecostal/Evangelical",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 16260,
                "enabled": true,
                "defaultString": "Presbyterian",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 16261,
                "enabled": true,
                "defaultString": "Protestant",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 16262,
                "enabled": true,
                "defaultString": "Seventh-day Adventist",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 16263,
                "enabled": true,
                "defaultString": "Native American",
                "sequenceNumber": 23
            }
        ]
    },
    {
        "RefListID": 620,
        "refListName": "Loan_Destination",
        "refListItems": [
            {
                "refListItemId": 19902,
                "enabled": true,
                "defaultString": "Other - See Comments",
                "sequenceNumber": 164
            },
            {
                "refListItemId": 17990,
                "enabled": true,
                "defaultString": "Job Search",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 17991,
                "enabled": true,
                "defaultString": "P/O Visit",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 17992,
                "enabled": true,
                "defaultString": "Rule 25",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 17993,
                "enabled": true,
                "defaultString": "Workforce Center",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 17994,
                "enabled": true,
                "defaultString": "Other -see comments",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 17995,
                "enabled": true,
                "defaultString": "District",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 17996,
                "enabled": true,
                "defaultString": "Family",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 17997,
                "enabled": true,
                "defaultString": "Juvenile",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 17998,
                "enabled": true,
                "defaultString": "United States Court",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 17999,
                "enabled": true,
                "defaultString": "5th Ave Dental",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18000,
                "enabled": true,
                "defaultString": "Smile Center",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18001,
                "enabled": true,
                "defaultString": "See Comments",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18002,
                "enabled": true,
                "defaultString": "CD Assessment",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 18003,
                "enabled": true,
                "defaultString": "Dr. Appointment",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 18004,
                "enabled": true,
                "defaultString": "HCMC - Medical",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 18005,
                "enabled": true,
                "defaultString": "B/S Checks",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 18006,
                "enabled": true,
                "defaultString": "Sick Call",
                "sequenceNumber": 157
            },
            {
                "refListItemId": 18007,
                "enabled": true,
                "defaultString": "Mercy",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 18008,
                "enabled": true,
                "defaultString": "Unity",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 18009,
                "enabled": true,
                "defaultString": "HCMC - Hospital",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 18010,
                "enabled": true,
                "defaultString": "Other-see comments",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 18011,
                "enabled": true,
                "defaultString": "Church",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 18012,
                "enabled": true,
                "defaultString": "Catholic Services",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 18013,
                "enabled": true,
                "defaultString": "Individual Spiritual Counseling",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 18014,
                "enabled": true,
                "defaultString": "Bible Study",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 18015,
                "enabled": true,
                "defaultString": "UA",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 18016,
                "enabled": true,
                "defaultString": "Bonding Company Interview",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 18017,
                "enabled": true,
                "defaultString": "DNA",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 18018,
                "enabled": true,
                "defaultString": "Notary",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 18019,
                "enabled": true,
                "defaultString": "Phone Call",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 18020,
                "enabled": true,
                "defaultString": "Interview",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 18021,
                "enabled": true,
                "defaultString": "Observation",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 18022,
                "enabled": true,
                "defaultString": "Re-Book",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 18023,
                "enabled": true,
                "defaultString": "Social Visiting",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 18024,
                "enabled": true,
                "defaultString": "Professional Visiting",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 18025,
                "enabled": true,
                "defaultString": "Aitkin County",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 18026,
                "enabled": true,
                "defaultString": "Anoka County",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 18027,
                "enabled": true,
                "defaultString": "Becker County",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 18028,
                "enabled": true,
                "defaultString": "Beltrami County",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 18029,
                "enabled": true,
                "defaultString": "Benton County",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 18030,
                "enabled": true,
                "defaultString": "Big Stone County",
                "sequenceNumber": 44
            },
            {
                "refListItemId": 18031,
                "enabled": true,
                "defaultString": "Blue Earth County",
                "sequenceNumber": 45
            },
            {
                "refListItemId": 18032,
                "enabled": true,
                "defaultString": "Brown County",
                "sequenceNumber": 46
            },
            {
                "refListItemId": 18033,
                "enabled": true,
                "defaultString": "Carlton County",
                "sequenceNumber": 47
            },
            {
                "refListItemId": 18034,
                "enabled": true,
                "defaultString": "Carver County",
                "sequenceNumber": 48
            },
            {
                "refListItemId": 18035,
                "enabled": true,
                "defaultString": "Cass County",
                "sequenceNumber": 49
            },
            {
                "refListItemId": 18036,
                "enabled": true,
                "defaultString": "Chippewa County",
                "sequenceNumber": 50
            },
            {
                "refListItemId": 18037,
                "enabled": true,
                "defaultString": "Chisago County",
                "sequenceNumber": 51
            },
            {
                "refListItemId": 18038,
                "enabled": true,
                "defaultString": "Clay County",
                "sequenceNumber": 52
            },
            {
                "refListItemId": 18039,
                "enabled": true,
                "defaultString": "Clearwater County",
                "sequenceNumber": 53
            },
            {
                "refListItemId": 18040,
                "enabled": true,
                "defaultString": "Cook County",
                "sequenceNumber": 54
            },
            {
                "refListItemId": 18041,
                "enabled": true,
                "defaultString": "Cottonwood County",
                "sequenceNumber": 55
            },
            {
                "refListItemId": 18042,
                "enabled": true,
                "defaultString": "Crow Wing County",
                "sequenceNumber": 56
            },
            {
                "refListItemId": 18043,
                "enabled": true,
                "defaultString": "Dakota County",
                "sequenceNumber": 57
            },
            {
                "refListItemId": 18044,
                "enabled": true,
                "defaultString": "Dodge County",
                "sequenceNumber": 58
            },
            {
                "refListItemId": 18045,
                "enabled": true,
                "defaultString": "Douglas County",
                "sequenceNumber": 59
            },
            {
                "refListItemId": 18046,
                "enabled": true,
                "defaultString": "Faribault County",
                "sequenceNumber": 60
            },
            {
                "refListItemId": 18047,
                "enabled": true,
                "defaultString": "Fillmore County",
                "sequenceNumber": 61
            },
            {
                "refListItemId": 18048,
                "enabled": true,
                "defaultString": "Freeborn County",
                "sequenceNumber": 62
            },
            {
                "refListItemId": 18049,
                "enabled": true,
                "defaultString": "Goodhue County",
                "sequenceNumber": 63
            },
            {
                "refListItemId": 18050,
                "enabled": true,
                "defaultString": "Grant County",
                "sequenceNumber": 64
            },
            {
                "refListItemId": 18051,
                "enabled": true,
                "defaultString": "Hennepin County",
                "sequenceNumber": 65
            },
            {
                "refListItemId": 18052,
                "enabled": true,
                "defaultString": "Houston County",
                "sequenceNumber": 66
            },
            {
                "refListItemId": 18053,
                "enabled": true,
                "defaultString": "Hubbard County",
                "sequenceNumber": 67
            },
            {
                "refListItemId": 18054,
                "enabled": true,
                "defaultString": "Isanti County",
                "sequenceNumber": 68
            },
            {
                "refListItemId": 18055,
                "enabled": true,
                "defaultString": "Itasca County",
                "sequenceNumber": 69
            },
            {
                "refListItemId": 18056,
                "enabled": true,
                "defaultString": "Jackson County",
                "sequenceNumber": 70
            },
            {
                "refListItemId": 18057,
                "enabled": true,
                "defaultString": "Kanabec County",
                "sequenceNumber": 71
            },
            {
                "refListItemId": 18058,
                "enabled": true,
                "defaultString": "Kandiyohi County",
                "sequenceNumber": 72
            },
            {
                "refListItemId": 18059,
                "enabled": true,
                "defaultString": "Kittson County",
                "sequenceNumber": 73
            },
            {
                "refListItemId": 18060,
                "enabled": true,
                "defaultString": "Koochiching County",
                "sequenceNumber": 74
            },
            {
                "refListItemId": 18061,
                "enabled": true,
                "defaultString": "Lac Qui Parle County",
                "sequenceNumber": 75
            },
            {
                "refListItemId": 18062,
                "enabled": true,
                "defaultString": "Lake County",
                "sequenceNumber": 76
            },
            {
                "refListItemId": 18063,
                "enabled": true,
                "defaultString": "Lake Of The Woods County",
                "sequenceNumber": 77
            },
            {
                "refListItemId": 18064,
                "enabled": true,
                "defaultString": "Le Sueur County",
                "sequenceNumber": 78
            },
            {
                "refListItemId": 18065,
                "enabled": true,
                "defaultString": "Lincoln County",
                "sequenceNumber": 79
            },
            {
                "refListItemId": 18066,
                "enabled": true,
                "defaultString": "Lyon County",
                "sequenceNumber": 80
            },
            {
                "refListItemId": 18067,
                "enabled": true,
                "defaultString": "Mahnomen County",
                "sequenceNumber": 81
            },
            {
                "refListItemId": 18068,
                "enabled": true,
                "defaultString": "Marshall County",
                "sequenceNumber": 82
            },
            {
                "refListItemId": 18069,
                "enabled": true,
                "defaultString": "Martin County",
                "sequenceNumber": 83
            },
            {
                "refListItemId": 18070,
                "enabled": true,
                "defaultString": "McLeod County",
                "sequenceNumber": 84
            },
            {
                "refListItemId": 18071,
                "enabled": true,
                "defaultString": "Meeker County",
                "sequenceNumber": 85
            },
            {
                "refListItemId": 18072,
                "enabled": true,
                "defaultString": "Mille Lacs County",
                "sequenceNumber": 86
            },
            {
                "refListItemId": 18073,
                "enabled": true,
                "defaultString": "Morrison County",
                "sequenceNumber": 87
            },
            {
                "refListItemId": 18074,
                "enabled": true,
                "defaultString": "Mower County",
                "sequenceNumber": 88
            },
            {
                "refListItemId": 18075,
                "enabled": true,
                "defaultString": "Murray County",
                "sequenceNumber": 89
            },
            {
                "refListItemId": 18076,
                "enabled": true,
                "defaultString": "Nicollet County",
                "sequenceNumber": 90
            },
            {
                "refListItemId": 18077,
                "enabled": true,
                "defaultString": "Nobles County",
                "sequenceNumber": 91
            },
            {
                "refListItemId": 18078,
                "enabled": true,
                "defaultString": "Norman County",
                "sequenceNumber": 92
            },
            {
                "refListItemId": 18079,
                "enabled": true,
                "defaultString": "Olmsted County",
                "sequenceNumber": 93
            },
            {
                "refListItemId": 18080,
                "enabled": true,
                "defaultString": "Otter Tail County",
                "sequenceNumber": 94
            },
            {
                "refListItemId": 18081,
                "enabled": true,
                "defaultString": "Pennington County",
                "sequenceNumber": 95
            },
            {
                "refListItemId": 18082,
                "enabled": true,
                "defaultString": "Pine County",
                "sequenceNumber": 96
            },
            {
                "refListItemId": 18083,
                "enabled": true,
                "defaultString": "Pipestone County",
                "sequenceNumber": 97
            },
            {
                "refListItemId": 18084,
                "enabled": true,
                "defaultString": "Polk County",
                "sequenceNumber": 98
            },
            {
                "refListItemId": 18085,
                "enabled": true,
                "defaultString": "Pope County",
                "sequenceNumber": 99
            },
            {
                "refListItemId": 18086,
                "enabled": true,
                "defaultString": "Ramsey County",
                "sequenceNumber": 100
            },
            {
                "refListItemId": 18087,
                "enabled": true,
                "defaultString": "Red Lake County",
                "sequenceNumber": 101
            },
            {
                "refListItemId": 18088,
                "enabled": true,
                "defaultString": "Redwood County",
                "sequenceNumber": 102
            },
            {
                "refListItemId": 18089,
                "enabled": true,
                "defaultString": "Renville County",
                "sequenceNumber": 103
            },
            {
                "refListItemId": 18090,
                "enabled": true,
                "defaultString": "Rice County",
                "sequenceNumber": 104
            },
            {
                "refListItemId": 18091,
                "enabled": true,
                "defaultString": "Rock County",
                "sequenceNumber": 105
            },
            {
                "refListItemId": 18092,
                "enabled": true,
                "defaultString": "Roseau County",
                "sequenceNumber": 106
            },
            {
                "refListItemId": 18093,
                "enabled": true,
                "defaultString": "Scott County",
                "sequenceNumber": 107
            },
            {
                "refListItemId": 18094,
                "enabled": true,
                "defaultString": "Sherburne County",
                "sequenceNumber": 108
            },
            {
                "refListItemId": 18095,
                "enabled": true,
                "defaultString": "Sibley County",
                "sequenceNumber": 109
            },
            {
                "refListItemId": 18096,
                "enabled": true,
                "defaultString": "St. Louis County",
                "sequenceNumber": 110
            },
            {
                "refListItemId": 18097,
                "enabled": true,
                "defaultString": "Stearns County",
                "sequenceNumber": 111
            },
            {
                "refListItemId": 18098,
                "enabled": true,
                "defaultString": "Steele County",
                "sequenceNumber": 112
            },
            {
                "refListItemId": 18099,
                "enabled": true,
                "defaultString": "Stevens County",
                "sequenceNumber": 113
            },
            {
                "refListItemId": 18100,
                "enabled": true,
                "defaultString": "Swift County",
                "sequenceNumber": 114
            },
            {
                "refListItemId": 18101,
                "enabled": true,
                "defaultString": "Todd County",
                "sequenceNumber": 115
            },
            {
                "refListItemId": 18102,
                "enabled": true,
                "defaultString": "Traverse County",
                "sequenceNumber": 116
            },
            {
                "refListItemId": 18103,
                "enabled": true,
                "defaultString": "Wabasha County",
                "sequenceNumber": 117
            },
            {
                "refListItemId": 18104,
                "enabled": true,
                "defaultString": "Wadena County",
                "sequenceNumber": 118
            },
            {
                "refListItemId": 18105,
                "enabled": true,
                "defaultString": "Waseca County",
                "sequenceNumber": 119
            },
            {
                "refListItemId": 18106,
                "enabled": true,
                "defaultString": "Washington County",
                "sequenceNumber": 120
            },
            {
                "refListItemId": 18107,
                "enabled": true,
                "defaultString": "Watonwan County",
                "sequenceNumber": 121
            },
            {
                "refListItemId": 18108,
                "enabled": true,
                "defaultString": "Wilkin County",
                "sequenceNumber": 122
            },
            {
                "refListItemId": 18109,
                "enabled": true,
                "defaultString": "Winona County",
                "sequenceNumber": 123
            },
            {
                "refListItemId": 18110,
                "enabled": true,
                "defaultString": "Wright County",
                "sequenceNumber": 124
            },
            {
                "refListItemId": 18111,
                "enabled": true,
                "defaultString": "Yellow Medicine County",
                "sequenceNumber": 125
            },
            {
                "refListItemId": 18112,
                "enabled": true,
                "defaultString": "ACCAP",
                "sequenceNumber": 126
            },
            {
                "refListItemId": 18113,
                "enabled": true,
                "defaultString": "ACCAP Extra",
                "sequenceNumber": 127
            },
            {
                "refListItemId": 18114,
                "enabled": true,
                "defaultString": "Anoka City",
                "sequenceNumber": 128
            },
            {
                "refListItemId": 18115,
                "enabled": true,
                "defaultString": "Aviand AM",
                "sequenceNumber": 129
            },
            {
                "refListItemId": 18116,
                "enabled": true,
                "defaultString": "Aviand PM",
                "sequenceNumber": 130
            },
            {
                "refListItemId": 18117,
                "enabled": true,
                "defaultString": "Canteen",
                "sequenceNumber": 131
            },
            {
                "refListItemId": 18118,
                "enabled": true,
                "defaultString": "CEAP",
                "sequenceNumber": 132
            },
            {
                "refListItemId": 18119,
                "enabled": true,
                "defaultString": "Cleaning Crew",
                "sequenceNumber": 133
            },
            {
                "refListItemId": 18120,
                "enabled": true,
                "defaultString": "Facility Mgmt. SPELLing",
                "sequenceNumber": 134
            },
            {
                "refListItemId": 18121,
                "enabled": true,
                "defaultString": "Fair Grounds",
                "sequenceNumber": 135
            },
            {
                "refListItemId": 18122,
                "enabled": true,
                "defaultString": "GED Testing",
                "sequenceNumber": 136
            },
            {
                "refListItemId": 18123,
                "enabled": true,
                "defaultString": "Highway",
                "sequenceNumber": 137
            },
            {
                "refListItemId": 18124,
                "enabled": true,
                "defaultString": "House Crew",
                "sequenceNumber": 138
            },
            {
                "refListItemId": 18125,
                "enabled": true,
                "defaultString": "Lawns Crew",
                "sequenceNumber": 139
            },
            {
                "refListItemId": 18126,
                "enabled": true,
                "defaultString": "Lino AM",
                "sequenceNumber": 140
            },
            {
                "refListItemId": 18127,
                "enabled": true,
                "defaultString": "Lino PM",
                "sequenceNumber": 141
            },
            {
                "refListItemId": 18128,
                "enabled": true,
                "defaultString": "Parks 2",
                "sequenceNumber": 142
            },
            {
                "refListItemId": 18129,
                "enabled": true,
                "defaultString": "Parks Crew",
                "sequenceNumber": 143
            },
            {
                "refListItemId": 18130,
                "enabled": true,
                "defaultString": "Property Mgmt.",
                "sequenceNumber": 144
            },
            {
                "refListItemId": 18131,
                "enabled": true,
                "defaultString": "Recycle Crew",
                "sequenceNumber": 145
            },
            {
                "refListItemId": 18132,
                "enabled": true,
                "defaultString": "Snow Crew",
                "sequenceNumber": 146
            },
            {
                "refListItemId": 18133,
                "enabled": true,
                "defaultString": "Special Crew",
                "sequenceNumber": 147
            },
            {
                "refListItemId": 18134,
                "enabled": true,
                "defaultString": "State Crew",
                "sequenceNumber": 148
            },
            {
                "refListItemId": 18135,
                "enabled": true,
                "defaultString": "Aftercare",
                "sequenceNumber": 149
            },
            {
                "refListItemId": 18136,
                "enabled": true,
                "defaultString": "Cronin Plus",
                "sequenceNumber": 150
            },
            {
                "refListItemId": 18137,
                "enabled": true,
                "defaultString": "Grace Counseling",
                "sequenceNumber": 151
            },
            {
                "refListItemId": 18138,
                "enabled": true,
                "defaultString": "Riverplace",
                "sequenceNumber": 152
            },
            {
                "refListItemId": 18139,
                "enabled": true,
                "defaultString": "Riverwood",
                "sequenceNumber": 153
            },
            {
                "refListItemId": 18140,
                "enabled": true,
                "defaultString": "Other- see comments",
                "sequenceNumber": 154
            },
            {
                "refListItemId": 18141,
                "enabled": true,
                "defaultString": "Blood Sugar Check",
                "sequenceNumber": 156
            },
            {
                "refListItemId": 18142,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 158
            },
            {
                "refListItemId": 18143,
                "enabled": true,
                "defaultString": "CD Appointment",
                "sequenceNumber": 159
            },
            {
                "refListItemId": 18144,
                "enabled": true,
                "defaultString": "Doctor Appointment",
                "sequenceNumber": 160
            },
            {
                "refListItemId": 18145,
                "enabled": true,
                "defaultString": "HCMC - Out Patient",
                "sequenceNumber": 161
            },
            {
                "refListItemId": 18146,
                "enabled": true,
                "defaultString": "Medical Appointment",
                "sequenceNumber": 162
            },
            {
                "refListItemId": 18147,
                "enabled": true,
                "defaultString": "Other see comments",
                "sequenceNumber": 163
            }
        ]
    },
    {
        "RefListID": 633,
        "refListName": "TransportToFrom",
        "refListItems": [
            {
                "refListItemId": 19890,
                "enabled": true,
                "defaultString": "Boarding",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18291,
                "enabled": true,
                "defaultString": "5th Ave Dental",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18292,
                "enabled": true,
                "defaultString": "District",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18293,
                "enabled": true,
                "defaultString": "Family",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18294,
                "enabled": true,
                "defaultString": "HCMC",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18295,
                "enabled": true,
                "defaultString": "Jail",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 18296,
                "enabled": true,
                "defaultString": "Medium",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18297,
                "enabled": true,
                "defaultString": "Mercy",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18298,
                "enabled": true,
                "defaultString": "Other -see comments",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18299,
                "enabled": true,
                "defaultString": "Other-see comments",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18300,
                "enabled": true,
                "defaultString": "Smile Center",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18301,
                "enabled": true,
                "defaultString": "United States Court",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18302,
                "enabled": true,
                "defaultString": "Unity",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18303,
                "enabled": true,
                "defaultString": "Workhouse",
                "sequenceNumber": 13
            }
        ]
    },
    {
        "RefListID": 599,
        "refListName": "Comment_Log_Scope_Context",
        "refListItems": [
            {
                "refListItemId": 16265,
                "enabled": true,
                "defaultString": "Supervisor",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16266,
                "enabled": true,
                "defaultString": "NonSupervisor",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 634,
        "refListName": "Transport_Reason",
        "refListItems": [
            {
                "refListItemId": 18309,
                "enabled": true,
                "defaultString": "Initial Booking",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 18310,
                "enabled": true,
                "defaultString": "Initial Classification",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 18311,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 18312,
                "enabled": true,
                "defaultString": "Pending Pickup",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 18313,
                "enabled": true,
                "defaultString": "Personal Request",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 18314,
                "enabled": true,
                "defaultString": "Receive Inmate",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 18315,
                "enabled": true,
                "defaultString": "Reclassification",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 18316,
                "enabled": true,
                "defaultString": "Routine Move",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 18317,
                "enabled": true,
                "defaultString": "Suicide Watch",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 18318,
                "enabled": true,
                "defaultString": "Transport Cancelled",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 18304,
                "enabled": true,
                "defaultString": "Cell Assignment",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 18305,
                "enabled": true,
                "defaultString": "Court",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 18306,
                "enabled": true,
                "defaultString": "Discplinary",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 18307,
                "enabled": true,
                "defaultString": "Fingerprint/Court",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 18308,
                "enabled": true,
                "defaultString": "Fingerprinting",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 601,
        "refListName": "Document_Name",
        "refListItems": [
            {
                "refListItemId": 16268,
                "enabled": true,
                "defaultString": "Stub",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 627,
        "refListName": "JailIncidentsClassIViolations",
        "refListItems": [
            {
                "refListItemId": 18260,
                "enabled": true,
                "defaultString": "Other - See Comments",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 618,
        "refListName": "BookingQueueStepContext",
        "refListItems": [
            {
                "refListItemId": 16696,
                "enabled": true,
                "defaultString": "BookingStep",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16697,
                "enabled": true,
                "defaultString": "ManualStep",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 619,
        "refListName": "Court_Reason",
        "refListItems": "NULL"
    },
    {
        "RefListID": 639,
        "refListName": "JailIncidentsSanctions2",
        "refListItems": [
            {
                "refListItemId": 19903,
                "enabled": true,
                "defaultString": "Stub",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 640,
        "refListName": "JailIncidentsSanctions3",
        "refListItems": [
            {
                "refListItemId": 19904,
                "enabled": true,
                "defaultString": "Stub",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 641,
        "refListName": "JailIncidentsSanctions4",
        "refListItems": [
            {
                "refListItemId": 19905,
                "enabled": true,
                "defaultString": "Stub",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 642,
        "refListName": "Relationship",
        "refListItems": [
            {
                "refListItemId": 19906,
                "enabled": false,
                "defaultString": "Stub",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 23951,
                "enabled": true,
                "defaultString": "Mother",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 23952,
                "enabled": true,
                "defaultString": "Father",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 23953,
                "enabled": true,
                "defaultString": "Spouse",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 23954,
                "enabled": true,
                "defaultString": "Friend",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 23955,
                "enabled": true,
                "defaultString": "Son",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 23956,
                "enabled": true,
                "defaultString": "Daughter",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 23957,
                "enabled": true,
                "defaultString": "Relative",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 23958,
                "enabled": true,
                "defaultString": "Attorney",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 23959,
                "enabled": true,
                "defaultString": "Aunt",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 23960,
                "enabled": true,
                "defaultString": "Babysitter",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 23961,
                "enabled": true,
                "defaultString": "Boyfriend",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 23962,
                "enabled": true,
                "defaultString": "Brother",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 23963,
                "enabled": true,
                "defaultString": "Case Manager",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 23964,
                "enabled": true,
                "defaultString": "Clergy",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 23965,
                "enabled": true,
                "defaultString": "Counselor",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 23966,
                "enabled": true,
                "defaultString": "Co-Worker",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 23967,
                "enabled": true,
                "defaultString": "Doctor",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 23968,
                "enabled": true,
                "defaultString": "Employee",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 23969,
                "enabled": true,
                "defaultString": "Employer",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 23970,
                "enabled": true,
                "defaultString": "Fiance",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 23971,
                "enabled": true,
                "defaultString": "Girlfriend",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 23972,
                "enabled": true,
                "defaultString": "Godfather",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 23973,
                "enabled": true,
                "defaultString": "Godmother",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 23974,
                "enabled": true,
                "defaultString": "Granddaughter",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 23975,
                "enabled": true,
                "defaultString": "Grandfather",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 23976,
                "enabled": true,
                "defaultString": "Grandmother",
                "sequenceNumber": 27
            },
            {
                "refListItemId": 23977,
                "enabled": true,
                "defaultString": "Grandson",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 23978,
                "enabled": true,
                "defaultString": "Landlord",
                "sequenceNumber": 29
            },
            {
                "refListItemId": 23979,
                "enabled": true,
                "defaultString": "Neice",
                "sequenceNumber": 30
            },
            {
                "refListItemId": 23980,
                "enabled": true,
                "defaultString": "Neighbor",
                "sequenceNumber": 31
            },
            {
                "refListItemId": 23981,
                "enabled": true,
                "defaultString": "Nephew",
                "sequenceNumber": 32
            },
            {
                "refListItemId": 23982,
                "enabled": true,
                "defaultString": "Parole Officer",
                "sequenceNumber": 33
            },
            {
                "refListItemId": 23983,
                "enabled": true,
                "defaultString": "Partner",
                "sequenceNumber": 34
            },
            {
                "refListItemId": 23984,
                "enabled": true,
                "defaultString": "Roommate",
                "sequenceNumber": 35
            },
            {
                "refListItemId": 23985,
                "enabled": true,
                "defaultString": "Sister",
                "sequenceNumber": 36
            },
            {
                "refListItemId": 23986,
                "enabled": true,
                "defaultString": "Uncle",
                "sequenceNumber": 37
            },
            {
                "refListItemId": 23987,
                "enabled": true,
                "defaultString": "Victim",
                "sequenceNumber": 38
            },
            {
                "refListItemId": 23988,
                "enabled": true,
                "defaultString": "X Boyfriend",
                "sequenceNumber": 39
            },
            {
                "refListItemId": 23989,
                "enabled": true,
                "defaultString": "X Employee",
                "sequenceNumber": 40
            },
            {
                "refListItemId": 23990,
                "enabled": true,
                "defaultString": "X Employer",
                "sequenceNumber": 41
            },
            {
                "refListItemId": 23991,
                "enabled": true,
                "defaultString": "X Girlfriend",
                "sequenceNumber": 42
            },
            {
                "refListItemId": 23992,
                "enabled": true,
                "defaultString": "X Partner",
                "sequenceNumber": 43
            },
            {
                "refListItemId": 23993,
                "enabled": true,
                "defaultString": "X Spouse",
                "sequenceNumber": 44
            }
        ]
    },
    {
        "RefListID": 676,
        "refListName": "CPNCPermit_Type",
        "refListItems": "NULL"
    },
    {
        "RefListID": 677,
        "refListName": "CPNCStatus",
        "refListItems": "NULL"
    },
    {
        "RefListID": 412,
        "refListName": "Hearing_Outcome",
        "refListItems": [
            {
                "refListItemId": 4217,
                "enabled": true,
                "defaultString": "Guilty",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4218,
                "enabled": true,
                "defaultString": "Not Guilty",
                "longString": "",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 554,
        "refListName": "Month",
        "refListItems": [
            {
                "refListItemId": 16077,
                "enabled": true,
                "defaultString": "January",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 16078,
                "enabled": true,
                "defaultString": "February",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 16079,
                "enabled": true,
                "defaultString": "March",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 16080,
                "enabled": true,
                "defaultString": "April",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 16081,
                "enabled": true,
                "defaultString": "May",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 16082,
                "enabled": true,
                "defaultString": "June",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 16083,
                "enabled": true,
                "defaultString": "July",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 16084,
                "enabled": true,
                "defaultString": "August",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 16085,
                "enabled": true,
                "defaultString": "September",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 16086,
                "enabled": true,
                "defaultString": "October",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 16087,
                "enabled": true,
                "defaultString": "November",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 16088,
                "enabled": true,
                "defaultString": "December",
                "sequenceNumber": 12
            }
        ]
    },
    {
        "RefListID": 414,
        "refListName": "Locate_Hit_Result",
        "refListItems": [
            {
                "refListItemId": 4230,
                "enabled": true,
                "defaultString": "Subject arrested on charge in wanted person record",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4231,
                "enabled": true,
                "defaultString": "Subject arrested on charge other than charge in hit record",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4232,
                "enabled": true,
                "defaultString": "Detainer filed",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4233,
                "enabled": true,
                "defaultString": "Held for extradition",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4234,
                "enabled": true,
                "defaultString": "Held for another jurisdiction after confirmation of missing status",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4235,
                "enabled": true,
                "defaultString": "Released - wanting agency refused to extradite",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4236,
                "enabled": true,
                "defaultString": "Released - agency holding missing person report can provide no follow-up in case of adult or where parents refuse to take custody of missing juvenile",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4237,
                "enabled": true,
                "defaultString": "Released - unable to confirm missing status",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4238,
                "enabled": true,
                "defaultString": "Released - unable to confirm wanted status",
                "sequenceNumber": 9
            }
        ]
    },
    {
        "RefListID": 573,
        "refListName": "Medical_Type",
        "refListItems": [
            {
                "refListItemId": 24003,
                "enabled": true,
                "defaultString": "Suicide Risk",
                "longString": "1",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24004,
                "enabled": true,
                "defaultString": "Diabetic",
                "longString": "1",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24005,
                "enabled": true,
                "defaultString": "Asthmatic",
                "longString": "1",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24006,
                "enabled": true,
                "defaultString": "Seizures",
                "longString": "1",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 24007,
                "enabled": true,
                "defaultString": "Tuberculosis - Isolation",
                "longString": "1",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 24008,
                "enabled": true,
                "defaultString": "Pregnant",
                "longString": "1",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 24009,
                "enabled": true,
                "defaultString": "Other",
                "longString": "1",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 24010,
                "enabled": true,
                "defaultString": "Heart Problems",
                "longString": "1",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 24011,
                "enabled": true,
                "defaultString": "Inmate Watch",
                "longString": "1",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 24012,
                "enabled": true,
                "defaultString": "Severe Allergic Reaction",
                "longString": "1",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 24965,
                "enabled": true,
                "defaultString": "* MHI Mental Health Inmate",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 24966,
                "enabled": true,
                "defaultString": "* MHG Grant Participant",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 16201,
                "enabled": false,
                "defaultString": "Placeholder",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 637,
        "refListName": "JailIncidentsClass4Violations",
        "refListItems": "NULL"
    },
    {
        "RefListID": 417,
        "refListName": "Signature_Reason",
        "refListItems": [
            {
                "refListItemId": 4241,
                "enabled": true,
                "defaultString": "Property Intake",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4242,
                "enabled": true,
                "defaultString": "Property Release",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4250,
                "enabled": true,
                "defaultString": "Medical Questionnaire",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 418,
        "refListName": "Black_List_Reason",
        "refListItems": [
            {
                "refListItemId": 4251,
                "enabled": true,
                "defaultString": "Behavioral",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4252,
                "enabled": true,
                "defaultString": "Poor Worker",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 15974,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 643,
        "refListName": "Location_PPD",
        "refListItems": "NULL"
    },
    {
        "RefListID": 644,
        "refListName": "PPD_Result",
        "refListItems": "NULL"
    },
    {
        "RefListID": 421,
        "refListName": "Detention_Type",
        "refListItems": [
            {
                "refListItemId": 4270,
                "enabled": true,
                "defaultString": "Community Work Release",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4271,
                "enabled": true,
                "defaultString": "Home Monitored",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4272,
                "enabled": true,
                "defaultString": "Sentenced To Service",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4273,
                "enabled": true,
                "defaultString": "Straight Time",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4274,
                "enabled": true,
                "defaultString": "Weekender",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4275,
                "enabled": true,
                "defaultString": "Work Release ",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 645,
        "refListName": "Chest_Result",
        "refListItems": "NULL"
    },
    {
        "RefListID": 423,
        "refListName": "Alias_Type",
        "refListItems": [
            {
                "refListItemId": 4279,
                "enabled": true,
                "defaultString": "A/K/A",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4280,
                "enabled": true,
                "defaultString": "D/B/A",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 646,
        "refListName": "Bondsman",
        "refListItems": "NULL"
    },
    {
        "RefListID": 647,
        "refListName": "UCROffenderDisposition",
        "refListItems": [
            {
                "refListItemId": 24948,
                "enabled": true,
                "defaultString": "Stub Value",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 648,
        "refListName": "UCRJuvenileDisposition",
        "refListItems": [
            {
                "refListItemId": 24949,
                "enabled": true,
                "defaultString": "Stub Value",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 649,
        "refListName": "WarrantCheckRequestType",
        "refListItems": [
            {
                "refListItemId": 24000,
                "enabled": true,
                "defaultString": "Intake",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24001,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24002,
                "enabled": true,
                "defaultString": "Release",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 650,
        "refListName": "Reason_for_Appt",
        "refListItems": [
            {
                "refListItemId": 24013,
                "enabled": true,
                "defaultString": "Abdominal Pain",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24014,
                "enabled": true,
                "defaultString": "Alcohol Withdrawal",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24015,
                "enabled": true,
                "defaultString": "Asthma",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24016,
                "enabled": true,
                "defaultString": "Back Pain",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24017,
                "enabled": true,
                "defaultString": "Constipation",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 24018,
                "enabled": true,
                "defaultString": "Chest Pain",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 24019,
                "enabled": true,
                "defaultString": "Conjunctivitis",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 24020,
                "enabled": true,
                "defaultString": "Diabetes",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 24021,
                "enabled": true,
                "defaultString": "Ectoparasite  Control/Lice",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 24022,
                "enabled": true,
                "defaultString": "Earache",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 24023,
                "enabled": true,
                "defaultString": "Genito/Urinary Complaints",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 24024,
                "enabled": true,
                "defaultString": "Head Aches",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 24025,
                "enabled": true,
                "defaultString": "HIV/AIDS",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 24026,
                "enabled": true,
                "defaultString": "Hypertension",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 24027,
                "enabled": true,
                "defaultString": "Nausea and Vomiting",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 24028,
                "enabled": true,
                "defaultString": "Nosebleeds",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 24029,
                "enabled": true,
                "defaultString": "Pelvis Pain",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 24030,
                "enabled": true,
                "defaultString": "Pregnancy",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 24031,
                "enabled": true,
                "defaultString": "Rectal/Perineal Pain",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 24032,
                "enabled": true,
                "defaultString": "Seizure",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 24033,
                "enabled": true,
                "defaultString": "Skin Rash",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 24034,
                "enabled": true,
                "defaultString": "Toothache/Facial Pain",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 24035,
                "enabled": true,
                "defaultString": "Upper Respiratory Tract Infection",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 24036,
                "enabled": true,
                "defaultString": "Sexual Assault",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 24037,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 25
            }
        ]
    },
    {
        "RefListID": 651,
        "refListName": "Type_of_Appointment",
        "refListItems": [
            {
                "refListItemId": 24038,
                "enabled": true,
                "defaultString": "Medical",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24039,
                "enabled": true,
                "defaultString": "Mental Health",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 652,
        "refListName": "BatchMovement_Status",
        "refListItems": [
            {
                "refListItemId": 24054,
                "enabled": true,
                "defaultString": "Complete",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24055,
                "enabled": true,
                "defaultString": "Cancelled",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 653,
        "refListName": "ORIMapping",
        "refListItems": [
            {
                "refListItemId": 24390,
                "enabled": true,
                "defaultString": "192.168.1.4",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24391,
                "enabled": true,
                "defaultString": "fe80::dc92:5194:8e5f:c75f%10",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24402,
                "enabled": true,
                "defaultString": "10.0.1.64",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24404,
                "enabled": true,
                "defaultString": "10.0.1.29",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24406,
                "enabled": true,
                "defaultString": "fe80::fcde:f29b:7d82:f9c3%11",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 24407,
                "enabled": true,
                "defaultString": "fe80::25b8:a240:e47e:18c2%11",
                "sequenceNumber": 6
            }
        ]
    },
    {
        "RefListID": 654,
        "refListName": "NCIC_Scope",
        "refListItems": [
            {
                "refListItemId": 24338,
                "enabled": true,
                "defaultString": "Inmate",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24339,
                "enabled": true,
                "defaultString": "Facility",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 655,
        "refListName": "NCIC_Modules",
        "refListItems": [
            {
                "refListItemId": 24340,
                "enabled": true,
                "defaultString": "Bookings",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 656,
        "refListName": "NCIC_Disposition",
        "refListItems": [
            {
                "refListItemId": 24341,
                "enabled": true,
                "defaultString": "RELD",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24342,
                "enabled": true,
                "defaultString": "DETN",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 657,
        "refListName": "NCIC_Extradition",
        "refListItems": [
            {
                "refListItemId": 24343,
                "enabled": true,
                "defaultString": "EXTR",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24344,
                "enabled": true,
                "defaultString": "DETN",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24345,
                "enabled": true,
                "defaultString": "NOEX",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 658,
        "refListName": "NCIC_Purpose",
        "refListItems": [
            {
                "refListItemId": 24346,
                "enabled": true,
                "defaultString": "C",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24347,
                "enabled": true,
                "defaultString": "J",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24348,
                "enabled": true,
                "defaultString": "Z",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 659,
        "refListName": "NCIC_RequestNumber",
        "refListItems": [
            {
                "refListItemId": 24349,
                "enabled": true,
                "defaultString": "1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24350,
                "enabled": true,
                "defaultString": "2",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24351,
                "enabled": true,
                "defaultString": "3",
                "sequenceNumber": 3
            }
        ]
    },
    {
        "RefListID": 660,
        "refListName": "NCIC_Priority",
        "refListItems": [
            {
                "refListItemId": 24352,
                "enabled": true,
                "defaultString": "U",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24353,
                "enabled": true,
                "defaultString": "R",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 661,
        "refListName": "Misc_Number",
        "refListItems": [
            {
                "refListItemId": 24416,
                "enabled": true,
                "defaultString": "Air Force Serial Number",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24942,
                "enabled": true,
                "defaultString": "Driver's License Number",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 24417,
                "enabled": true,
                "defaultString": "Air National Guard Serial Number (8)",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24418,
                "enabled": true,
                "defaultString": "Alien Registration Number",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24419,
                "enabled": true,
                "defaultString": "Army Serial Number (8)",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24420,
                "enabled": true,
                "defaultString": "Canadian Social Insurance Number",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 24421,
                "enabled": true,
                "defaultString": "Marine Corps Serial Number",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 24422,
                "enabled": true,
                "defaultString": "Mariner's Document or Identification Number",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 24423,
                "enabled": true,
                "defaultString": "National Agency Case Number-Military",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 24424,
                "enabled": true,
                "defaultString": "National Guard Serial Number (8)",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 24425,
                "enabled": true,
                "defaultString": "Navy Serial Number",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 24426,
                "enabled": true,
                "defaultString": "Nonimmigration Admission Number",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 24427,
                "enabled": true,
                "defaultString": "Originating Agency Police or State Identification Number (NCIC only)",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 24428,
                "enabled": true,
                "defaultString": "Passport Number",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 24429,
                "enabled": true,
                "defaultString": "Personal Identification Number (applies only to state or foreign government issued numbers)",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 24430,
                "enabled": true,
                "defaultString": "Port Security Card Number",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 24431,
                "enabled": true,
                "defaultString": "Royal Canadian Mounted Police Identification or Fingerprint Section (FPS) Number",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 24432,
                "enabled": true,
                "defaultString": "Selective Service Number",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 24433,
                "enabled": true,
                "defaultString": "U.S. Coast Guard Serial Number",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 24434,
                "enabled": true,
                "defaultString": "Veterans Administration Claim Number",
                "sequenceNumber": 19
            }
        ]
    },
    {
        "RefListID": 662,
        "refListName": "POBType",
        "refListItems": [
            {
                "refListItemId": 24435,
                "enabled": true,
                "defaultString": "Canadian Provinces",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24436,
                "enabled": true,
                "defaultString": "Countries",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 24437,
                "enabled": true,
                "defaultString": "Indian Nations",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 24438,
                "enabled": true,
                "defaultString": "Mexican States",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 24439,
                "enabled": true,
                "defaultString": "U.S. States",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 663,
        "refListName": "POBNCIC",
        "refListItems": ""
    },
    {
        "RefListID": 664,
        "refListName": "POBNCIC_Context",
        "refListItems": [
            {
                "refListItemId": 24943,
                "enabled": true,
                "defaultString": "POB_NCIC",
                "longString": "1",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24944,
                "enabled": true,
                "defaultString": "Country_Of_Citizenship",
                "longString": "1",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 665,
        "refListName": "Warrant_WaitFor_Reason",
        "refListItems": [
            {
                "refListItemId": 24945,
                "enabled": true,
                "defaultString": "Wait For Body",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24946,
                "enabled": true,
                "defaultString": "Wait For $",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 666,
        "refListName": "Assignment",
        "refListItems": [
            {
                "refListItemId": 24951,
                "enabled": true,
                "defaultString": "One",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24952,
                "enabled": true,
                "defaultString": "Two",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 667,
        "refListName": "Agency_Vehicle",
        "refListItems": [
            {
                "refListItemId": 24953,
                "enabled": true,
                "defaultString": "One",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 24954,
                "enabled": true,
                "defaultString": "Two",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 668,
        "refListName": "Event_Code",
        "refListItems": [
            {
                "refListItemId": 24955,
                "enabled": true,
                "defaultString": "Stub",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 669,
        "refListName": "Signal",
        "refListItems": [
            {
                "refListItemId": 24956,
                "enabled": true,
                "defaultString": "Stub",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 670,
        "refListName": "Service_Area",
        "refListItems": [
            {
                "refListItemId": 25007,
                "enabled": true,
                "defaultString": "Other",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 25008,
                "enabled": true,
                "defaultString": "N/A",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 671,
        "refListName": "Department",
        "refListItems": "NULL"
    },
    {
        "RefListID": 672,
        "refListName": "Assign_To_ORI",
        "refListItems": "NULL"
    },
    {
        "RefListID": 673,
        "refListName": "TrainingCategory",
        "refListItems": "NULL"
    },
    {
        "RefListID": 674,
        "refListName": "Housed_For",
        "refListItems": "NULL"
    },
    {
        "RefListID": 675,
        "refListName": "Medical_Type_Context",
        "refListItems": [
            {
                "refListItemId": 24964,
                "enabled": true,
                "defaultString": "Common",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 679,
        "refListName": "AppealStatus",
        "refListItems": [
            {
                "refListItemId": 25156,
                "enabled": true,
                "defaultString": "Denied",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 25157,
                "enabled": true,
                "defaultString": "Granted",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 424,
        "refListName": "ChargeClearance",
        "refListItems": [
            {
                "refListItemId": 15958,
                "enabled": true,
                "defaultString": "Abscond-Fail to Return",
                "sequenceNumber": 25
            },
            {
                "refListItemId": 4281,
                "enabled": true,
                "defaultString": "Bond Posted",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4282,
                "enabled": true,
                "defaultString": "Charge Dropped",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4283,
                "enabled": false,
                "defaultString": "Other/See Comments",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4284,
                "enabled": true,
                "defaultString": "Judge Order",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4285,
                "enabled": true,
                "defaultString": "Sentence Complete",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4286,
                "enabled": true,
                "defaultString": "RPR",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4287,
                "enabled": true,
                "defaultString": "Bail Paid",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5027,
                "enabled": true,
                "defaultString": "Transfer to State Prison",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 5028,
                "enabled": true,
                "defaultString": "Transfer to USM",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 5029,
                "enabled": true,
                "defaultString": "Transfer to INS",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 5030,
                "enabled": true,
                "defaultString": "Transfer to Other Institution",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 5031,
                "enabled": true,
                "defaultString": "102 Form",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 5032,
                "enabled": true,
                "defaultString": "104 Form",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 5033,
                "enabled": true,
                "defaultString": "36/48 Rule Lapsed",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 5034,
                "enabled": true,
                "defaultString": "ISAP Program",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 5035,
                "enabled": true,
                "defaultString": "Rel to Other Agency",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 5036,
                "enabled": true,
                "defaultString": "Rel by Courts",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 5037,
                "enabled": true,
                "defaultString": "Rel to Responsible Adult",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 5038,
                "enabled": true,
                "defaultString": "Rel to Huber",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 5039,
                "enabled": true,
                "defaultString": "Sign Tag",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 5040,
                "enabled": true,
                "defaultString": "Temporary/Weekenders",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 5041,
                "enabled": true,
                "defaultString": "Paid Fine",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 5042,
                "enabled": true,
                "defaultString": "Fail to return fr furlough",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 5043,
                "enabled": true,
                "defaultString": "Case Dismissed",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 5044,
                "enabled": true,
                "defaultString": "Death - Homicide",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 5045,
                "enabled": true,
                "defaultString": "Death - Other",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 5046,
                "enabled": true,
                "defaultString": "Death - Suicide",
                "sequenceNumber": 21
            },
            {
                "refListItemId": 5047,
                "enabled": true,
                "defaultString": "Escape from Facility",
                "sequenceNumber": 22
            },
            {
                "refListItemId": 5048,
                "enabled": true,
                "defaultString": "Not Guilty",
                "sequenceNumber": 23
            },
            {
                "refListItemId": 19892,
                "enabled": true,
                "defaultString": "Release to Other State",
                "sequenceNumber": 28
            },
            {
                "refListItemId": 15940,
                "enabled": true,
                "defaultString": "Boarded out of County",
                "sequenceNumber": 24
            },
            {
                "refListItemId": 15982,
                "enabled": true,
                "defaultString": "Ill Moved to Hospital",
                "sequenceNumber": 26
            },
            {
                "refListItemId": 15989,
                "enabled": true,
                "defaultString": "Furloughed",
                "longString": "Furloughed",
                "sequenceNumber": 27
            }
        ]
    },
    {
        "RefListID": 425,
        "refListName": "DOBSpecial",
        "refListItems": [
            {
                "refListItemId": 4293,
                "enabled": true,
                "defaultString": "Age Range",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4294,
                "enabled": true,
                "defaultString": "Baby",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4295,
                "enabled": true,
                "defaultString": "Neonatal",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4296,
                "enabled": true,
                "defaultString": "Newborn",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4297,
                "enabled": true,
                "defaultString": "Unknown",
                "longString": "",
                "sequenceNumber": 5
            }
        ]
    },
    {
        "RefListID": 426,
        "refListName": "PersonToServeType",
        "refListItems": [
            {
                "refListItemId": 4298,
                "enabled": true,
                "defaultString": "Defendant",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4299,
                "enabled": false,
                "defaultString": "Garnishee",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4300,
                "enabled": true,
                "defaultString": "Plaintiff",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4301,
                "enabled": false,
                "defaultString": "Witness",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4302,
                "enabled": true,
                "defaultString": "Attorney",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 7007,
                "enabled": false,
                "defaultString": "Judgement Creditor",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 7008,
                "enabled": false,
                "defaultString": "Judgement Debtor",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 7009,
                "enabled": false,
                "defaultString": "Occupant",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 7010,
                "enabled": false,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 7011,
                "enabled": false,
                "defaultString": "Petitioner",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 7012,
                "enabled": false,
                "defaultString": "Respondent",
                "longString": "",
                "sequenceNumber": 11
            }
        ]
    },
    {
        "RefListID": 428,
        "refListName": "Inmate_Status",
        "refListItems": [
            {
                "refListItemId": 4317,
                "enabled": true,
                "defaultString": "STO - HUBER",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4318,
                "enabled": true,
                "defaultString": "County - Trustee",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4319,
                "enabled": true,
                "defaultString": "County - HUBER",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4316,
                "enabled": true,
                "defaultString": "State Work Release",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4320,
                "enabled": true,
                "defaultString": "Out of County - HUBER",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4321,
                "enabled": true,
                "defaultString": "Student",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4322,
                "enabled": true,
                "defaultString": "Straight Time",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4323,
                "enabled": true,
                "defaultString": "Weekender - HUBER",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4324,
                "enabled": true,
                "defaultString": "Weekender",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4325,
                "enabled": true,
                "defaultString": "STO",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4326,
                "enabled": true,
                "defaultString": "HEM Violator",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4327,
                "enabled": true,
                "defaultString": "Federal Work Release",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4328,
                "enabled": true,
                "defaultString": "Work House",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 25005,
                "enabled": true,
                "defaultString": "Transitional Housing",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 15296,
                "enabled": true,
                "defaultString": "Boarded - US Marshal",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 15297,
                "enabled": true,
                "defaultString": "Writ",
                "longString": "",
                "sequenceNumber": 16
            }
        ]
    },
    {
        "RefListID": 429,
        "refListName": "Note_Type",
        "refListItems": [
            {
                "refListItemId": 4329,
                "enabled": true,
                "defaultString": "Suicide Risk",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4330,
                "enabled": false,
                "defaultString": "Kitchen",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4331,
                "enabled": true,
                "defaultString": "Escape Threat",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4332,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4333,
                "enabled": true,
                "defaultString": "Behavioral",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4334,
                "enabled": true,
                "defaultString": "Visitation Block",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4335,
                "enabled": true,
                "defaultString": "Serious Violence Threat",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4336,
                "enabled": true,
                "defaultString": "Protective Custody",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4337,
                "enabled": true,
                "defaultString": "Uncooperative",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4338,
                "enabled": true,
                "defaultString": "Sensitive Charges",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4339,
                "enabled": true,
                "defaultString": "Psychological Impairment",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4340,
                "enabled": true,
                "defaultString": "Predatory",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4341,
                "enabled": true,
                "defaultString": "Vulnerable",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4342,
                "enabled": true,
                "defaultString": "Mental Deficiency",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4343,
                "enabled": true,
                "defaultString": "Substance Abuse Problem",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4344,
                "enabled": true,
                "defaultString": "Known Management Problem",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4345,
                "enabled": true,
                "defaultString": "Suspected Drug Trafficker",
                "longString": "",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4346,
                "enabled": true,
                "defaultString": "Physical Impairment",
                "longString": "",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 16053,
                "enabled": true,
                "defaultString": "Crisis Hold"
            },
            {
                "refListItemId": 15429,
                "enabled": true,
                "defaultString": "Assaultive Behavior",
                "longString": "",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 16272,
                "enabled": true,
                "defaultString": "Inmate Watch",
                "longString": "",
                "sequenceNumber": 21
            }
        ]
    },
    {
        "RefListID": 430,
        "refListName": "Inmate_Hold_Reason",
        "refListItems": [
            {
                "refListItemId": 4347,
                "enabled": false,
                "defaultString": "Administrative Hold",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4348,
                "enabled": false,
                "defaultString": "Bench Warrant",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4349,
                "enabled": false,
                "defaultString": "Hold for another Agency",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4350,
                "enabled": false,
                "defaultString": "In-Transit Stay",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4351,
                "enabled": false,
                "defaultString": "Other Hold Reason",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4352,
                "enabled": true,
                "defaultString": "Pending Charge/Investigation",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4353,
                "enabled": true,
                "defaultString": "Pending Court Disposition",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4354,
                "enabled": false,
                "defaultString": "Pending Sentence(02)",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4355,
                "enabled": false,
                "defaultString": "Pending Trial",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4356,
                "enabled": false,
                "defaultString": "Supervision Violation (A&D order)",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4357,
                "enabled": false,
                "defaultString": "Transferred from Another Agency",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4358,
                "enabled": true,
                "defaultString": "Under Sentence",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4359,
                "enabled": true,
                "defaultString": "Unknown",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4360,
                "enabled": true,
                "defaultString": "Short Term Offender",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4361,
                "enabled": true,
                "defaultString": "Hold - COC",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4362,
                "enabled": true,
                "defaultString": "Hold - DOC",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4363,
                "enabled": true,
                "defaultString": "Hold - INS",
                "sequenceNumber": 17
            },
            {
                "refListItemId": 4364,
                "enabled": true,
                "defaultString": "Hold - Other County",
                "sequenceNumber": 18
            },
            {
                "refListItemId": 4365,
                "enabled": true,
                "defaultString": "Hold - Other State",
                "sequenceNumber": 19
            },
            {
                "refListItemId": 4366,
                "enabled": true,
                "defaultString": "Hold - US Marshal",
                "sequenceNumber": 20
            },
            {
                "refListItemId": 4367,
                "enabled": true,
                "defaultString": "Hold - Writ",
                "sequenceNumber": 21
            }
        ]
    },
    {
        "RefListID": 431,
        "refListName": "Inmate_Type",
        "refListItems": [
            {
                "refListItemId": 4368,
                "enabled": true,
                "defaultString": "Adult",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4369,
                "enabled": true,
                "defaultString": "Certified Adult",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4370,
                "enabled": true,
                "defaultString": "EJJ-Adult",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4371,
                "enabled": false,
                "defaultString": "EJJ-Juvenile",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4372,
                "enabled": true,
                "defaultString": "Juvenile",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4373,
                "enabled": false,
                "defaultString": "Non-Delinquent - CHIPS",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4374,
                "enabled": false,
                "defaultString": "Non-Delinquent - Other",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4375,
                "enabled": false,
                "defaultString": "Non-Delinquent - Status Offender",
                "sequenceNumber": 8
            }
        ]
    },
    {
        "RefListID": 432,
        "refListName": "Emergency_Contact_Relationship",
        "refListItems": ""
    },
    {
        "RefListID": 433,
        "refListName": "LiveScan_Export_Menu",
        "refListItems": [
            {
                "refListItemId": 4419,
                "enabled": true,
                "defaultString": "All Charges",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4420,
                "enabled": true,
                "defaultString": "Current Charge",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 434,
        "refListName": "Danger_Type",
        "refListItems": [
            {
                "refListItemId": 4421,
                "enabled": false,
                "defaultString": "Armed and dangerous",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4422,
                "enabled": true,
                "defaultString": "Violent tendencies",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4423,
                "enabled": true,
                "defaultString": "Martial arts expert",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4424,
                "enabled": true,
                "defaultString": "Explosive expertise",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4425,
                "enabled": false,
                "defaultString": "Known to abuse drugs",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4426,
                "enabled": true,
                "defaultString": "Escape risk",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4427,
                "enabled": true,
                "defaultString": "Sexually violent predator",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4428,
                "enabled": false,
                "defaultString": "Heart condition",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4429,
                "enabled": false,
                "defaultString": "Alcoholic",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4430,
                "enabled": false,
                "defaultString": "Allergies",
                "longString": "",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4431,
                "enabled": false,
                "defaultString": "Epilepsy",
                "longString": "",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4432,
                "enabled": false,
                "defaultString": "Suicidal",
                "longString": "",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4433,
                "enabled": false,
                "defaultString": "Medication required",
                "longString": "",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4434,
                "enabled": false,
                "defaultString": "Hemophiliac",
                "longString": "",
                "sequenceNumber": 14
            },
            {
                "refListItemId": 4435,
                "enabled": false,
                "defaultString": "Diabetic",
                "longString": "",
                "sequenceNumber": 15
            },
            {
                "refListItemId": 4436,
                "enabled": true,
                "defaultString": "Other",
                "longString": "",
                "sequenceNumber": 16
            },
            {
                "refListItemId": 4473,
                "enabled": false,
                "defaultString": "Aids Carrier",
                "longString": "",
                "sequenceNumber": 17
            }
        ]
    },
    {
        "RefListID": 435,
        "refListName": "Street_Suffix",
        "refListItems": [
            {
                "refListItemId": 4437,
                "enabled": true,
                "defaultString": "Ln",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4438,
                "enabled": true,
                "defaultString": "Ave",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4439,
                "enabled": true,
                "defaultString": "Rd",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4440,
                "enabled": true,
                "defaultString": "Pkwy",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4441,
                "enabled": true,
                "defaultString": "St",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4442,
                "enabled": true,
                "defaultString": "Ct",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4443,
                "enabled": true,
                "defaultString": "Cir",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4941,
                "enabled": true,
                "defaultString": "Dr",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4965,
                "enabled": true,
                "defaultString": "Blvd",
                "longString": "",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4966,
                "enabled": true,
                "defaultString": "Way",
                "longString": "",
                "sequenceNumber": 10
            }
        ]
    },
    {
        "RefListID": 437,
        "refListName": "WarrantSubmissionType",
        "refListItems": [
            {
                "refListItemId": 4446,
                "enabled": true,
                "defaultString": "ModifyWarrant",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4447,
                "enabled": true,
                "defaultString": "ClearWarrant",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4448,
                "enabled": true,
                "defaultString": "NewAlias",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4449,
                "enabled": true,
                "defaultString": "CancelAlias",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4450,
                "enabled": true,
                "defaultString": "NewDistinguishingCharacteristic",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4451,
                "enabled": true,
                "defaultString": "CancelDistinguishingCharacteristic",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4452,
                "enabled": true,
                "defaultString": "CancelWarrant",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4445,
                "enabled": true,
                "defaultString": "NewWarrant",
                "longString": "",
                "sequenceNumber": 1
            }
        ]
    },
    {
        "RefListID": 438,
        "refListName": "Inmate_Violation",
        "refListItems": [
            {
                "refListItemId": 4453,
                "enabled": true,
                "defaultString": "Other (see comments)",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4454,
                "enabled": false,
                "defaultString": "Fighting",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4455,
                "enabled": false,
                "defaultString": "Threats",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4456,
                "enabled": false,
                "defaultString": "Vandalism",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 15496,
                "enabled": true,
                "defaultString": "Class I Violation",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 15497,
                "enabled": true,
                "defaultString": "Class II Violation",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 15498,
                "enabled": true,
                "defaultString": "Class III Violation",
                "sequenceNumber": 7
            }
        ]
    },
    {
        "RefListID": 439,
        "refListName": "Payment_Type",
        "refListItems": [
            {
                "refListItemId": 4457,
                "enabled": true,
                "defaultString": "Cash",
                "longString": "",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4458,
                "enabled": true,
                "defaultString": "Check",
                "longString": "",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4459,
                "enabled": true,
                "defaultString": "Bond",
                "longString": "",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4942,
                "enabled": true,
                "defaultString": "Recognizance",
                "longString": "",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4943,
                "enabled": true,
                "defaultString": "Bail Dropped",
                "longString": "",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4944,
                "enabled": true,
                "defaultString": "Bond Reinstated",
                "longString": "",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 15495,
                "enabled": true,
                "defaultString": "Sentenced",
                "longString": "",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 15988,
                "enabled": true,
                "defaultString": "Transferred",
                "longString": "",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 16009,
                "enabled": true,
                "defaultString": "Credit Card"
            },
            {
                "refListItemId": 19247,
                "enabled": true,
                "defaultString": "Paid Fine"
            }
        ]
    },
    {
        "RefListID": 442,
        "refListName": "Hair_Style",
        "refListItems": [
            {
                "refListItemId": 4474,
                "enabled": true,
                "defaultString": "AFR",
                "longString": "AFR   - Afro",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4475,
                "enabled": true,
                "defaultString": "CLY",
                "longString": "CLY   - Curly",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 4476,
                "enabled": true,
                "defaultString": "STR",
                "longString": "STR   - Straight",
                "sequenceNumber": 3
            },
            {
                "refListItemId": 4477,
                "enabled": true,
                "defaultString": "BRD",
                "longString": "BRD   - Braided",
                "sequenceNumber": 4
            },
            {
                "refListItemId": 4478,
                "enabled": true,
                "defaultString": "UNK",
                "longString": "UNK   - Unkempt",
                "sequenceNumber": 5
            },
            {
                "refListItemId": 4479,
                "enabled": true,
                "defaultString": "DRD",
                "longString": "DRD   - Dread Locks",
                "sequenceNumber": 6
            },
            {
                "refListItemId": 4480,
                "enabled": true,
                "defaultString": "SPK",
                "longString": "SPK   - Spiked",
                "sequenceNumber": 7
            },
            {
                "refListItemId": 4481,
                "enabled": true,
                "defaultString": "BSH",
                "longString": "BSH   - Bushy",
                "sequenceNumber": 8
            },
            {
                "refListItemId": 4482,
                "enabled": true,
                "defaultString": "PHY",
                "longString": "PHY   - Pony Tail",
                "sequenceNumber": 9
            },
            {
                "refListItemId": 4483,
                "enabled": true,
                "defaultString": "STY",
                "longString": "STY   - Styled",
                "sequenceNumber": 10
            },
            {
                "refListItemId": 4484,
                "enabled": true,
                "defaultString": "MIL",
                "longString": "MIL   - Military",
                "sequenceNumber": 11
            },
            {
                "refListItemId": 4485,
                "enabled": true,
                "defaultString": "NOH",
                "longString": "NOH   - No Hair",
                "sequenceNumber": 12
            },
            {
                "refListItemId": 4486,
                "enabled": true,
                "defaultString": "BDG",
                "longString": "BDG   - Balding",
                "sequenceNumber": 13
            },
            {
                "refListItemId": 4487,
                "enabled": true,
                "defaultString": "OTH",
                "longString": "OTH   - Other",
                "sequenceNumber": 14
            }
        ]
    },
    {
        "RefListID": 443,
        "refListName": "NewOptions_Generic",
        "refListItems": [
            {
                "refListItemId": 4488,
                "enabled": true,
                "defaultString": "Blank Record",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 4489,
                "enabled": true,
                "defaultString": "Based On Current",
                "sequenceNumber": 2
            }
        ]
    },
    {
        "RefListID": 678,
        "refListName": "YesNoRefused",
        "refListItems": [
            {
                "refListItemId": 25012,
                "enabled": true,
                "defaultString": "Yes",
                "sequenceNumber": 1
            },
            {
                "refListItemId": 25013,
                "enabled": true,
                "defaultString": "No",
                "sequenceNumber": 2
            },
            {
                "refListItemId": 25014,
                "enabled": true,
                "defaultString": "Refused",
                "sequenceNumber": 3
            }
        ]
    }
];

export const CONFIG_STAGES = {
    INITIAL: 0,
    SELECTED: 1,
    DEPLOY: 2,
    STAGE: 3,
    SAVE_CONFIG: 4,
    SEARCHING: 5,
    MAP_VIEW: 6,
    TABLE_VIEW: 7,
};
