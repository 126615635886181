import { Dropdown, DropdownButton } from "react-bootstrap";
import styled from "styled-components";
import { btnDark, btnPrimary } from "../Button/styles";

export const StyledDropdown = styled(Dropdown)`
  height: 100%;
`;

export const StyledDropdownButton = styled(DropdownButton)`
  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-dark {
    background-color: #0b3d5b;
    border: 4px solid var(--background-color-secondary);

    &:hover:enabled {
      background-color: #07678c;
      border: 4px solid #0c79a4;
    }
  }

  .btn-text,
  .btn-text-blue {
    background-color: unset;
    padding: unset;
    border: unset;
    color: white;

    &.disabled {
      opacity: 0.3;
    }
  }

  .btn-text-blue {
    color: var(--text-blue);
  }

  .btn-primary-btn {
    ${btnPrimary};
  }

  .btn-primary-dark-btn {
    ${btnDark};
  }
`;
