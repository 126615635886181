import { Col, Row, Typography, Flex as AntFlex, List } from "antd";
import styled, { css } from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: ${({ align_items }) => align_items};
  justify-content: ${({ justify_content }) => justify_content};
  gap: ${({ gap }) => gap};
`;

export const ActionHeader = styled(Flex)`
  position: sticky;
  /* top: ${({ headerHeight }) => `calc(${headerHeight}px + 5px)`}; */
  top: ${({ headerHeight }) => `${headerHeight}px`};
  padding-bottom: 5px;
  background-color: var(--background-color);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 999;
  gap: 15px;
  margin-bottom: 15px;

  .left,
  .right {
    gap: 15px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex: 1; */
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    /* white-space: nowrap; */
    /* gap: 10px; */
    /* flex: 1; */
    /* text-align: right; */
    /* flex-basis: 35%; */
    /* flex: 1 1 auto; */

    & > * {
      /* flex: 1; */
    }
  }
`;

export const Strong = styled.div`
  font-weight: bold;
`;

export const AccentBorder = styled.div`
  border: ${({ accentBorder = false }) => accentBorder && "2px solid var(--accent)"};
  border-radius: ${({ borderRadius = false }) => borderRadius && "var(--border-radius)"};
`;
export const InmateDetailsContainer = styled(AccentBorder)`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageTabsWrapper = styled.div`
  background-color: white;
  flex: 1;
  color: var(--text-black);
`;
export const IntakeSectionHeader = styled.h6`
  position: relative;
  margin: 30px 0 15px 0;
  line-height: 20px;
  color: rgba(2, 87, 147, 0.9);
  font-weight: 600;
  text-transform: uppercase;

  & span {
    background-color: white;
    padding: 0 15px;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /*right: 0;*/
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: rgba(82, 140, 181, 0.3);
  }
`;

export const PageContainer = styled.div`
  position: relative;
`;

export const StyledGridRow = styled(Row)`

`
export const StyledGridCol = styled(Col)`

`

export const StyledTypographyText = styled(Typography.Text).withConfig({
  shouldForwardProp: (prop) => prop !== 'textTransform' && prop !== 'color',
})`
  color: ${({ color }) => color || 'var(--text-color-white)'};
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
`;

export const StyledBgDarkBlueMixin = css`
  background-color: var(--bg-dark-container);
  box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset,
              0px -1px 0px rgba(5, 92, 126, 0.49) inset,
              2px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

export const StyledPanelContainer = styled.div`
${StyledBgDarkBlueMixin}
`;

export const StyledFlex = styled(AntFlex)`

`
export const StyledList = styled(List)`

`