import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import { Col, Row } from "react-bootstrap";
import { INMATE_DETAILS_MODE } from ".";
// import { INMATE_PROGRESS_MODE } from "../InmateProgress";
// import AddressModal from "./AddressModal";
// import AliasModal from "./AliasModal";
import CommonDatePicker from "@/common/elements/DatePicker";
import Select from "@/common/elements/InputElements/Select";
import { IntakeSectionHeader } from "@/common/styles";
import {
  arrayToObject,
  checkField,
  checkRequiredField,
  getOptionsFromRefList,
  getSQLTypeToHTMLType,
  handleFormForSetTouched,
} from "@/util/common_function";
import { staticFields } from "@/util/common_variable";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import React, { forwardRef, useEffect, useState } from "react";
import * as Yup from "yup";
import { StyledDate } from "../InmateDetails/styles";
import { FormContainer } from "./styles";
import DynamicForm from "@/common/DynamicForm";

export const PERSON = { INMATE: "INMATE", VISITOR: "VISITOR" };

const Personal = forwardRef(
  ({ formData = {}, mode = INMATE_DETAILS_MODE.EDIT, header, person = PERSON.INMATE }, ref) => {
    const [personalValidationSchema, setPersonalValidationSchema] = useState(null);

    useEffect(() => {
      initValidate();
    }, []);

    useEffect(() => {
      personalValidationSchema?._nodes?.forEach((n) => {
        ref?.current?.setFieldValue(n, "");
      });
    }, [personalValidationSchema]);

    const initValidate = async () => {
      const va = await arrayToObject(staticFields?.personalInfo[person]);
      const validate = Yup.object().shape(va);
      setPersonalValidationSchema(validate);
    };

    return (
      <FormContainer>
        {header && (
          <IntakeSectionHeader>
            <span>{header}</span>
          </IntakeSectionHeader>
        )}
        <Formik
          initialValues={formData}
          validationSchema={personalValidationSchema}
          innerRef={ref}
          validateOnBlur
        >
          {({ getFieldProps, setFieldValue, values, errors }) => {
            return (
              <Form >
                <Row gutter={[16]}>
                  {
                    staticFields?.personalInfo[person]?.map((sf, index) => {
                      return <DynamicForm formAttributes={sf} key={index} formRef={ref} mode={mode} getFieldProps={getFieldProps} setFieldValue={setFieldValue} values={values} errors={errors} />
                    })
                  }
                </Row>
              </Form>
            )
          }
          }
        </Formik>
      </FormContainer>
    );
  }
);

export default Personal;
