import React from "react";
import dayjs from 'dayjs';
import { Col } from "antd";
import { ErrorMessage } from "formik";
import styled from "styled-components";
import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import FormItem from "@/common/elements/InputElements/FormItem.jsx/index.jsx";
import Select from "@/common/elements/InputElements/Select";
import CommonDatePicker from '@/common/elements/DatePicker';
import { StyledDate } from "@/components/InmateDetails/styles";
import { checkField, getSQLTypeToHTMLType, checkRequiredField, getOptionsFromRefList, handleFormForSetTouched } from "@/util/common_function";
import { INMATE_DETAILS_MODE } from "@/components/InmateDetails";



const StyledColumn = styled(Col)`
.ant-col{
  min-height: auto !important
}`

const DynamicForm = ({ formAttributes, key, formRef, getFieldProps, setFieldValue, values, errors, mode }) => {
    const ref = formRef;
    const { label, columnName, tableName, type } = formAttributes;
    const htmlType = getSQLTypeToHTMLType(type);
    const paramContext = tableName + ':' + columnName;
    return <React.Fragment key={key}>
        {
            // checkField(paramContext) &&
            <StyledColumn span={24 / 4}>
                {
                    (htmlType === 'text' || htmlType === 'number') &&
                    <>
                        <FormItem
                            id={key}
                            label={label}
                            validateStatus={
                                values[columnName]?.error && "error"
                            }
                            help={
                                <ErrorMessage name={columnName} component="span" style={{ color: "red" }} />
                            }
                        >
                            <TextField
                                type={htmlType}
                                {...getFieldProps(columnName)}
                                placeholder={label}
                                // variant={INPUT_VARIANT.INTAKE_FORM}
                                readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                                value={values?.[columnName] || ''}
                                onKeyDown={() => handleFormForSetTouched(ref)}
                                required={checkRequiredField(paramContext)}
                            />
                        </FormItem>
                    </>
                }
                {
                    (htmlType === 'select') &&
                    <>
                        <FormItem
                            id={key}
                            label={label}
                            validateStatus={
                                values[columnName]?.error && "error"
                            }
                            help={
                                <ErrorMessage name={columnName} component="span" style={{ color: "red" }} />
                            }
                        >
                            <Select
                                options={getOptionsFromRefList(columnName)}
                                {...getFieldProps(columnName)}
                                placeholder={label}
                                defaultValue='-1'
                                onChange={(value) => { console.log(value), setFieldValue(columnName, value) }}
                                value={values?.[columnName] || ''}
                                variant="outlined"
                                readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                                required={checkRequiredField(paramContext)}
                            >
                            </Select>
                        </FormItem>
                    </>
                }
                {
                    htmlType === 'datetime-local' &&
                    <StyledDate required>
                        <div>
                            <label htmlFor={columnName}>{label}</label>
                        </div>
                        <CommonDatePicker
                            style={{
                                border: "2px solid rgba(82, 140, 181, 0.27)",
                                top: "5px"
                            }}
                            placeholder="MM/DD/YYYY"
                            format={{
                                format: 'MM/DD/YYYY'
                            }}
                            inputProps={{
                                maxLength: 10,
                            }}
                            disableFutureDates={true}
                            value={values[columnName] ? dayjs(values?.[columnName]) : null}
                            onChange={(date) => { setFieldValue(columnName, date), handleFormForSetTouched(ref) }}
                        />
                    </StyledDate>
                }

            </StyledColumn>
        }
    </React.Fragment>
};

export default DynamicForm;