// FolderSearchContext.js
import { createContext, useContext, useState } from "react";

const FolderSearchContext = createContext();

export const FolderSearchProvider = ({ children }) => {
  const [requestFilter, setRequestFilter] = useState([]);

  return (
    <FolderSearchContext.Provider value={{ requestFilter, setRequestFilter }}>
      {children}
    </FolderSearchContext.Provider>
  );
};

export const useFilterSearchContext = () => useContext(FolderSearchContext);
