import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { INPUT_VARIANT } from "../InputElements/const";
import { StyledTable, PaginationControls, Pagination, PaginationInfo, PaginationButtonGroup, PaginationRowLimit, InputPageChange, NoDataFound, NoDataFound as Loading } from "./styles";
import Select from "../InputElements/Select";
import Button from "../Button";
import { generateArray } from "@/util/common_function";
import Loader from "../Loader";

const columnHelper = createColumnHelper();

// headers = [{key: "headerKeyForData", text: "Header Text"}];
// data = [{...}, {...}];
const Table = ({ headers = [], data = [], totalRecords = 0, selected = false, isAPiCallLoading = false, onRowClick: onRowClickFn, variant = INPUT_VARIANT._, style = {}, pagination = null, setPagination }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);
  const [tableState, setTableState] = useState(null);

  useEffect(() => {
    if (pagination !== null) {
      setTableState({
        rowSelection,
        sorting,
        pagination
      })
    } else {
      setTableState({
        rowSelection,
        sorting
      })
    }

  }, [pagination])

  useEffect(() => {
    if (selected) {
      setRowSelection({})
    }
  }, [selected])

  const columns = useMemo(() => {
    const columns = headers.map(({ key, text }) => ({
      accessorKey: key,
      cell: (info) => info.getValue(),
      header: text,
      sortingFn: "alphanumeric", // use built-in sorting function by name
    }));

    return columns;
  }, [headers]);

  const table = useReactTable({
    state: tableState,
    rowCount: totalRecords,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    enableSorting: true,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true
  });

  const onRowClick = (event, row) => {
    onRowClickFn && onRowClickFn(row.original);
    row.getToggleSelectedHandler()(event);
  };

  const getHeaderlength = () => {
    const keyWithArray = 'headers';
    const totalLength = table.getHeaderGroups()?.reduce((sum, obj) => {
      return sum + (Array.isArray(obj[keyWithArray]) ? obj[keyWithArray].length : 0);
    }, 0);
    return totalLength;
  }

  const changePage = (e) => {
    const page = parseInt(e.target.value) - 1;
    table.setPageIndex(page)
    setPagination((prev) => ({ ...prev, pageIndex: page }))
  }

  return (
    <>
      {/* {JSON.stringify({ sorting, rowSelection })} */}
      <StyledTable striped bordered responsive hover className={variant} style={style}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: " 🔼",
                    desc: " 🔽",
                  }[header.column.getIsSorted()] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!isAPiCallLoading && table.getRowModel().rows?.length !== 0 && table.getRowModel().rows.map((row, tbindex) => (
            <tr key={row.id} onClick={(event) => onRowClick(event, row)} className={rowSelection[tbindex] && "selected"}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}

          {
            !isAPiCallLoading && table.getRowModel().rows?.length === 0 &&
            <NoDataFound>
              <td colSpan={getHeaderlength()}>
                No Folder found
              </td>
            </NoDataFound>
          }
          {
            isAPiCallLoading &&
            <Loading>
              <td colSpan={getHeaderlength()}><Loader /></td>
            </Loading>
          }
        </tbody>
      </StyledTable>
      {
        !isAPiCallLoading && totalRecords !== 0 &&
        <Pagination>
          <PaginationInfo>
            <span>
              Showing {table.getRowModel().rows.length.toLocaleString()} of{' '}
              {table.getRowCount().toLocaleString()} Rows {' '}
            </span>
            <span className="flex items-center gap-1">
              | Page
              <span className="ps-1">
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount().toLocaleString()}
              </span>
            </span>
          </PaginationInfo>
          {
            (pagination !== null && table.getPageCount() > 1) ?
              <>
                <PaginationRowLimit>
                  <span className="me-2">
                    per page
                  </span>
                  <Select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 25, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Select>
                  <span className="ms-2">
                    records
                  </span>

                </PaginationRowLimit>
                <PaginationControls>
                  <PaginationButtonGroup>
                    <Button
                      className="border rounded p-1"
                      onClick={() => table.firstPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {'<<'}
                    </Button>
                    <Button
                      className="border rounded p-1"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {'<'}
                    </Button>
                    <Button
                      className="border rounded p-1"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      {'>'}
                    </Button>
                    <Button
                      className="border rounded p-1"
                      onClick={() => table.lastPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      {'>>'}
                    </Button>
                  </PaginationButtonGroup>
                  <InputPageChange>
                    <div className="d-flex align-items-baseline">
                      <span className="me-2">| Go to page:</span>
                      <span>
                        <Select
                          variant={INPUT_VARIANT.INTAKE_FORM}
                          value={pagination.pageIndex + 1}
                          onChange={e => {
                            changePage(e)
                          }}
                          disabled={totalRecords < table.getPageCount()}
                        >
                          {generateArray(table.getPageCount())?.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Select>
                      </span>
                    </div>
                  </InputPageChange>
                </PaginationControls>
              </>
              : ''
          }

        </Pagination>
      }
    </>
  );
};

export default Table;
