import { ReactLenis } from "lenis/react";

export const lenisOptions = {
  lerp: 0.1,
  duration: 0.5,
  smoothTouch: false, //smooth scroll for touch devices
  smooth: true,
};

const SmoothScrolling = ({ root = true, children, ...rest }) => {
  return (
    <>
      <ReactLenis root={root} options={lenisOptions} {...rest}>
        {children}
      </ReactLenis>
    </>
  );
};

export default SmoothScrolling;
