import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import Select from "@/common/elements/InputElements/Select";
import TextField from "@/common/elements/InputElements/TextField";
import { Row, Col, Form } from "antd";
import { FormContainer } from "./styles";
import { forwardRef } from "react";
import { Formik, ErrorMessage } from "formik";
import { INMATE_DETAILS_MODE } from ".";
import * as Yup from "yup";
import CommonDatePicker from '@/common/elements/DatePicker';
import { StyledDate } from '../InmateDetails/styles';
import { labels } from "@/util/label";
import dayjs from 'dayjs';
import { staticFields, MOVE_OPTIONS, JAIL_OPTIONS, STATUS_OPTIONS, REASON_OPTIONS, LOAN_OPTIONS, DESTINATIONS } from "@/util/common_variable";
import React, { useState } from "react";
import { arrayToObject, checkRequiredField, getOptionsFromRefList, getSQLTypeToHTMLType, handleFormForSetTouched, isEmptyObject } from "@/util/common_function";
import FormItem from "antd/es/form/FormItem";



const housetrackValidationSchema = Yup.object().shape({
  moveTo: Yup.string().required(),
  status: Yup.string().required(),
  jailType: Yup.string().optional(),
  holdingCell: Yup.string().optional(),
  block: Yup.string()
    .when("jailType", {
      is: (jailType) => jailType === "Cell",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  cell: Yup.string().optional(),
  bed: Yup.string().optional(),
  loanType: Yup.string().optional(),
  destination: Yup.string().optional(),
  expectedDateTime: Yup.date().nullable(),
  scheduleDateTime: Yup.date()
    .nullable()
    .when("status", {
      is: (status) => status !== "Completed" && status !== "Cancelled",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  actualDateTime: Yup.date().nullable().required(),
  approvalOfficer: Yup.string().optional(),
  transportingOfficer: Yup.string().optional(),
  reasonForMove: Yup.string().optional(),
  houseTrackComments: Yup.string().optional(),
});

const HouseTrack = forwardRef(
  (
    {
      formData = {
        moveTo: "",
        status: "",
        jailType: "",
        holdingCell: "",
        block: "",
        cell: "",
        bed: "",
        loanType: "",
        destination: "",
        expectedDateTime: "",
        scheduleDateTime: "",
        actualDateTime: "",
        approvalOfficer: "",
        transportingOfficer: "",
        houseTrackComments: "",
        reasonForMove: "",

      },
      onUpdateForm,
      mode = INMATE_DETAILS_MODE.EDIT,
    },
    ref
  ) => {

    const [houseTrackValidationSchema, setHouseTrackValidationSchema] = useState(null);

    const initValidate = async () => {
      const va = await arrayToObject(staticFields?.arrest_book_info);
      const validate = Yup.object().shape(va);
      setHouseTrackValidationSchema(validate);
    }


    return (
      <>
        <FormContainer>
          <Formik
            initialValues={formData}
            validationSchema={housetrackValidationSchema}
            innerRef={ref}
          >
            {({ getFieldProps, setFieldValue, values, errors }) => (
              <Form>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                  <FormItem
                     label={labels.moveTo}
                     layout="vertical">
                    <Select                    
                      value={values.moveTo}
                      onChange={(value) => setFieldValue("moveTo", value)}
                      variant="outlined"
                      options={MOVE_OPTIONS.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    />   
                    </FormItem>                 
                    <ErrorMessage
                      name='moveTo'
                      component='div'
                      className='error'
                      style={{ color: "red" }}
                    />
                  </Col>               


                  <Col span={6}>
                  <FormItem
                    label={labels.status}
                    layout="vertical"
                  >
                    <Select                     
                      // {...getFieldProps("status")}
                      value={values.status}
                      onChange={(value) => setFieldValue("status", value)}
                      variant="outlined"
                      defaultValue=''
                      required
                      isInvalid={!!errors.status}
                      options={STATUS_OPTIONS.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    /> 
                    </FormItem>                
                    <ErrorMessage
                      name='status'
                      component='div'
                      className='error'
                      style={{ color: "red" }}
                    />
                  </Col>                 

                  <Col span={6}>
                  <FormItem 
                    label={labels.approvalOfficer}
                    layout="vertical">
                    <TextField                     
                      {...getFieldProps("approvalOfficer")}
                      placeholder='Approval Officer'
                      variant="outlined"
                    />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                  <FormItem
                  label={labels.transportingOfficer}
                  layout="vertical">
                    <TextField                      
                      {...getFieldProps("transportingOfficer")}
                      placeholder='Transporting Officer'
                      variant="outlined"
                    />
                    </FormItem>
                  </Col>

                  {values.moveTo === "In Jail" && (
                       <Row gutter={[16,16]}>
                        <Col span={8}>
                          <FormItem 
                          label={labels.jailType}
                          layout="vertical">
                            <Select
                              // {...getFieldProps("jailType")}
                              value={values.jailType}
                              onChange={(value) => setFieldValue("jailType", value)}
                              variant="outlined"
                              defaultValue=""
                              options={JAIL_OPTIONS.map((item) => ({
                                value: item.value,
                                label: item.label,
                              }))}
                            />
                             </FormItem>
                            </Col> 

                            {values.jailType === "Holding" && (                        
                            <Col span={14}>
                              <FormItem 
                                label={labels.holdingIslolationCell}
                                layout="vertical"
                              >
                              <TextField
                              // {...getFieldProps("holdingCell")}
                              value={values.holdingCell}
                              onChange={(value) => setFieldValue("holdingCell", value)}
                              placeholder="Holding/Isolation Cell"
                              variant="outlined"
                              />
                          </FormItem>
                          </Col>                     
                          )} 
                            {values.jailType == "Cell" && (
                            <Row gutter={[16,16]}>
                            <Col span={8}>
                            <FormItem 
                            label={labels.block}
                            layout="vertical">
                          <TextField
                            {...getFieldProps("block")}
                            placeholder='Block'
                            variant="outlined"
                            required
                            isInvalid={!!errors.block} 
                          />
                          </FormItem>
                          {/* <ErrorMessage
                            name='block'
                            component='div'
                            className='error'
                            style={{ color: "red" }}
                          /> */}
                        </Col>
                        <Col span={8}>
                        <FormItem 
                            label='Cell'
                            layout="vertical">
                          <TextField                        
                            {...getFieldProps("cell")}
                            placeholder='Cell'
                            variant="outlined"
                          />
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                          label={labels.bed}
                          layout="vertical">
                          <TextField                        
                            // {...getFieldProps("bed")}
                            value={values.bed}
                            onChange={(value) => setFieldValue("bed", value)}
                            placeholder='Bed'
                            variant="outlined"
                          />
                          </FormItem>
                            </Col>
                          </Row>
                          )}
                      
                          </Row>                                       
                        )} 

                  {values.moveTo ==="Loan" && (
                  <Row gutter={[16,16]}>
                    <Col span={8}>
                     <FormItem 
                     label={labels.loanType}
                     layout="vertical"
                     >
                      <Select                       
                        // {...getFieldProps("loanType")}
                        placeholder='Loan Type'
                        value={values.loanType}
                       onChange={(value) => setFieldValue("loanType", value)}
                        variant="outlined"
                        defaultValue=''
                        options={LOAN_OPTIONS.map((item) => ({
                          value : item.value,
                          label : item.label
                        }))}
                      />
                      </FormItem>
                    </Col>

                    <Col span={8}>
                      <FormItem
                        label={labels.destination}
                        layout="vertical">
                      <Select                        
                        // {...getFieldProps("destination")}
                        value={values.destination}
                        onChange={(value) => setFieldValue("destination", value)}
                        placeholder='Destination'
                        variant="outlined"
                        defaultValue=''
                        options={DESTINATIONS.map((item) => ({
                          value : item.value,
                          label : item.label
                        }))}
                      />                       
                       </FormItem>
                    </Col>

                    <Col span={8}>
                     <FormItem 
                     label={labels.expectedDateTime}
                     layout="vertical"
                     >
                    <StyledDate  >                  
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)"
                    }}
                      value={values.expectedDateTime ? dayjs(values.expectedDateTime) : null} 
                      onChange={(date) => setFieldValue("expectedDateTime", date)} 
                      placeholder="MM/DD/YYYY"
                      format="MM/DD/YYYY HH:mm"                       
                    />
                    </StyledDate>
                    </FormItem>
                    </Col>                    
                  </Row>
                  )}

                  {values.moveTo === "Perm Housing Change" && (
                   <Row gutter={[16,16]}>
                    <Col span={12}>
                     <FormItem
                     label={labels.destination}
                     layout="vertical">
                      <Select                      
                        {...getFieldProps("destination")}
                        placeholder='Destination'
                        variant="outlined"
                        defaultValue=''
                        options={DESTINATIONS.map((item) => ({
                          value : item.value,
                          label : item.label
                        }))}
                      />                     
                      </FormItem>
                    </Col>

                    <Col span={12}>
                      <FormItem 
                      label={labels.transportDocument}
                      layout="vertical">
                      <TextField
                        type='file'                        
                        {...getFieldProps("transDoc")}
                        placeholder='Transport Document'
                        variant="outlined"
                      />
                      </FormItem>
                    </Col>
                  </Row>
                  )}

                 {values.moveTo == "Transport" && (
                  <Row gutter={[16,16]}>
                    <Col span={8}>
                      <FormItem
                      label={labels.destination}
                      layout="vertical">
                      <Select                       
                        {...getFieldProps("destination")}
                        placeholder='Destination'
                        variant="outlined"
                        defaultValue=''
                        options={DESTINATIONS.map((item) => ({
                          value : item.value,
                          label : item.label
                        }))}
                      />
                      </FormItem>                       
                    </Col>
                    <Col span={8}>
                      <FormItem
                      label={labels.transportDocument}
                      layout="vertical">
                      <TextField
                        type='file'                        
                        {...getFieldProps("transDoc")}
                        placeholder='Transport Document'
                        variant="outlined"
                      />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                    <FormItem
                    label={labels.expectedDateTime}
                    layout="vertical">
                    <StyledDate  >
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)"
                    }}
                    value={values.expectedDateTime ? dayjs(values.expectedDateTime) : null} 
                    onChange={(date) => setFieldValue("expectedDateTime", date)} 
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY HH:mm"                       
                    />
                    </StyledDate>
                    </FormItem>
                    </Col>
                  </Row>
                 )}

                  {values.status === "Pending" && (
                    <Row gutter={[16,16]}>
                    <Col span={12}>                   
                    <FormItem 
                    label={labels.schduleDateTime}
                    layout="vertical">
                    <StyledDate required >                    
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)"
                    }}
                    value={values.scheduleDateTime ? dayjs(values.scheduleDateTime) : null} 
                    onChange={(date) => setFieldValue("scheduleDateTime", date)} 
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY HH:mm"                       
                    />
                    </StyledDate>
                    </FormItem> 
                    {/* <TextField
                    label={labels.schduleDateTime}
                    {...getFieldProps("scheduleDateTime")}
                    type='datetime-local'                    
                    variant="outlined"
                    readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                    required={true}
                    isInvalid={!!errors.scheduleDateTime}                     
                    /> */}

                      {/* <ErrorMessage
                        name='scheduleDateTime'
                        component='div'
                        className='error'
                        style={{ color: "red" }}
                      />                                       */}
                    </Col>                     
                    <Col span={12}>
                      <FormItem 
                      label={labels.actualDateTime}
                      layout="vertical">
                    <StyledDate required >                    
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)"
                    }}
                    value={values.actualDateTime ? dayjs(values.actualDateTime) : null} 
                    onChange={(date) => setFieldValue("actualDateTime", date)} 
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY HH:mm" 
                    />
                    </StyledDate>

                    {/* <TextField
                    label={labels.actualDateTime}
                    {...getFieldProps("actualDateTime")}
                    type='datetime-local'                    
                    variant="outlined"
                    readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                    required={true}
                    isInvalid={!!errors.actualDateTime}                     
                    /> */}

                      <ErrorMessage
                        name='actualDateTime'
                        component='div'
                        className='error'
                        style={{ color: "red" }}
                      />
                      </FormItem>
                       
                    </Col>
                    </Row>
                  )}  

                 {(values.status == "Completed" ||
                  values.status == "Cancelled") && (
                  <Row gutter={[16,16]}>
                    <Col span={24}>
                     <FormItem 
                     label={labels.actualDateTime}
                     layout="vertical">
                    <StyledDate required >
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)"
                    }}
                    value={values.actualDateTime ? dayjs(values.actualDateTime) : null} 
                    onChange={(date) => setFieldValue("actualDateTime", date)} 
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY HH:mm" 
                    />
                    </StyledDate>
                    </FormItem>
                      <ErrorMessage
                        name='actualDateTime'
                        component='div'
                        className='error'
                        style={{ color: "red" }}
                      />

                    {/* <TextField
                    label={labels.actualDateTime}
                    {...getFieldProps("actualDateTime")}
                    type='datetime-local'                    
                    variant="outlined"
                    readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                    required={true}
                    isInvalid={!!errors.actualDateTime}                     
                    /> */}

                    </Col>
                  </Row>
                )}
          

                 
                  
                  <Col span={8}>
                   <FormItem
                    label={labels.reasonForMove}
                    layout="vertical">
                    <Select                     
                      {...getFieldProps("reasonForMove")}
                      placeholder='Reason for Move'
                      variant="outlined"
                      defaultValue=''
                      options={REASON_OPTIONS.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    />
                    </FormItem>
                  
                  </Col> 
                   
                  <Col span={12}>
                  <FormItem
                    label={labels.comments}
                    layout="vertical">                    
                    <TextField
                      inputType="textarea"                 
                      {...getFieldProps("houseTrackComments")}
                      placeholder='Comments'
                      variant="outlined"
                    />
                    </FormItem>
                  </Col>                          

                </Row>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </>
    );
  }
);

export default HouseTrack;
