export const responsive_num = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const responsive = {
  sm: `${responsive_num.sm}px`,
  md: `${responsive_num.md}px`,
  lg: `${responsive_num.lg}px`,
  xl: `${responsive_num.xl}px`,
  xxl: `${responsive_num.xxl}px`,
};
