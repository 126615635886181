import { Flex } from "@/common/styles";
import { Container, Tabs } from "react-bootstrap";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  --bs-nav-tabs-border-radius: var(--border-radius);
  background-color: white;

  .nav-item {
    background: #eef0f2;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    color: var(--text-black);
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.23);
    position: relative;

    .nav-link {
      color: rgba(21, 33, 41, 0.35);
      border: unset;
      margin-bottom: unset;
      cursor: pointer;

      &.active {
        color: var(--text-black);
        --bs-nav-tabs-link-active-bg: #fefefe;

        &:after {
          height: 4px;
          width: 50%;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: var(--border-radius);
          background-color: var(--accent);
        }
      }
    }

    &:hover {
      background: #f7f8f9;
    }
  }
`;

export const ContentContainer = styled(Container)`
  padding: ${({ contentFullWidth = false }) => (contentFullWidth ? "0" : "5px 30px")};
`;

export const TabActions = styled(Flex)`
  align-items: stretch;
  justify-content: space-between;
  height: 40px;
  margin: 15px 20px 10px 20px;
`;
