import { ANT_FormSelect } from "../styles";

const Select = ({
  options = [],
  variant = 'outlined',
  ...rest
}) => {

  return (
    <ANT_FormSelect
      variant={variant}
      options={options}
      className={`${rest?.className}`}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      {...rest}
    >
    </ANT_FormSelect>
  );
};
export default Select;