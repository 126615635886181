/* eslint-disable react/prop-types */
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken'); // Simplified example. Use proper auth check.
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (location.pathname === '/login' && isAuthenticated) {
    // Redirect to CREATE_BOOKING if accessing login while authenticated
    return <Navigate to={'/'} replace />;
  }

  return children;
};

export default AuthGuard;
