import  styled from 'styled-components'
import { Cascader } from 'antd';

export const CascaderMenuToggler = styled.button`
  display: flex;
  width:100%;
  color:var(--text-color-blue);
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  height:100%;
  align-items:center;
  padding: 5px 10px;
  background: var(--bg-container-main); 
  border: 4px solid var(--border-main);
  border-radius:6px;
  text-align: left;
  justify-content: start;

.selection-text{flex-grow:1;text-overflow: ellipsis;overflow: hidden;margin-right:9px;}

&.highlight-green-btn{
.selection-text{color: var(--text-color-green-light);}
}

&.highlight-yellow-btn{
.selection-text{color: var(--text-color-yellow);}
}

&.ant-dropdown-open{
  .arrow-img{
    position: relative;
    transform: rotate(180deg);
    vertical-align: 1px;
  }
}

`
export const StyledCascaderLabel =styled.label`
color: var(--text-color-blue);
font-size: 14px;
font-weight: 700;
text-transform:uppercase;
text-align: left;
margin-bottom:10px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
`
export const StyledCascaderMenu = styled.div`

`
export const StyledCascaderMenu2 = styled.div`
 .ant-dropdown-menu{
  border: 6px solid var(--core-highlight-color);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
  background-color: rgba(0, 36, 62, 1);
 }
 &.cascader-menu-default{
  width:100%;
  .ant-select-selector{
    min-height:46px;
    background: var(--bg-container-main); 
    border: 4px solid var(--border-main);
  }
  .ant-select-selection-placeholder{
  color:var(--text-color-blue);
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  }
 }
 .ant-select-dropdown{ 
  border: 6px solid var(--core-highlight-color);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
  background-color: rgba(0, 36, 62, 1);
   width:auto;
   z-index: 1034;
 }
 &.cascader-primary {
.ant-select-selection-item{color:var(--text-color-white) !important;
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
}
  .ant-cascader-menu{
    border-right:1px solid #000;
  
  position:relative;
    border-radius: 0px;
    // border: 6px solid var(--core-highlight-color);
    // box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
    // background-color: rgba(0, 36, 62, 1);
    
    flex-grow: inherit;
    padding: 0;
    .ant-cascader-menu-item-expand-icon {
  img{min-width:13px;}

  }
    .ant-cascader-menu-item{z-index:1;position:relative;
    background-color: rgba(0, 36, 62, 1); border-radius:0px !important;
    }
    .ant-cascader-menu-item-active{
      background:var(--core-active-color);
      color:var(--text-color-white) !important;
         .ant-cascader-menu-item-content{
         color:var(--text-color-white) !important;|
         }
    }
    .ant-cascader-menu-item-content{
    color:var(--text-color-blue);
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  padding: 0 15px;
    }
  }
  }

 &.ant-select-focused{
  .ant-select-selector{
    background: var(--bg-container-main) !important; 
    border-color:var(--border-main) !important;
  }
 }
  .ant-select-dropdown{
   position:fixed;
   inset:auto !important;
   left: auto;
   top:auto !important;
   z-index:9999;
  }
`
export const StyledCascader = styled(Cascader)`
 .ant-dropdown-menu{
  border: 6px solid var(--core-highlight-color);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
  background-color: rgba(0, 36, 62, 1);
 }
 &.cascader-menu-default{
  width:100%;
  .ant-select-selector{
    min-height:46px;
    background: var(--bg-container-main); 
    border: 4px solid var(--border-main);
  }
  .ant-select-selection-placeholder{
  color:var(--text-color-blue);
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  }
 }
 .ant-select-dropdown{ 
  border: 6px solid var(--core-highlight-color);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
  background-color: rgba(0, 36, 62, 1);
   width:auto;
   z-index: 1034;
 }
 &.cascader-primary {
.ant-select-selection-item{color:var(--text-color-white) !important;
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
}
  .ant-cascader-menu{
    border-right:1px solid #000;
  
  position:relative;
    border-radius: 0px;
    // border: 6px solid var(--core-highlight-color);
    // box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.46), 0px 0px 2px rgba(0, 0, 0, 0.32);
    // background-color: rgba(0, 36, 62, 1);
    
    flex-grow: inherit;
    padding: 0;
    .ant-cascader-menu-item-expand-icon {
  img{min-width:13px;}

  }
    .ant-cascader-menu-item{z-index:1;position:relative;
    background-color: rgba(0, 36, 62, 1); border-radius:0px !important;
    }
    .ant-cascader-menu-item-active{
      background:var(--core-active-color);
      color:var(--text-color-white) !important;
         .ant-cascader-menu-item-content{
         color:var(--text-color-white) !important;|
         }
    }
    .ant-cascader-menu-item-content{
    color:var(--text-color-blue);
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  padding: 0 15px;
    }
  }
  }

 &.ant-select-focused{
  .ant-select-selector{
    background: var(--bg-container-main) !important; 
    border-color:var(--border-main) !important;
  }
 }
  .ant-select-dropdown{
   position:fixed;
   inset:auto !important;
   left: auto;
   top:auto !important;
   z-index:9999;
  }
`