import arrowRightBlueModern from "@/assets/arrow-right-blue-modern.svg";
import bookingQueue from "@/assets/booking_queue.svg";
import dashboard from "@/assets/dashboard.svg";
import movement from "@/assets/movement.svg";
import recentFiles from "@/assets/recent_files.svg";

import Accordion from "@/common/elements/Accordion";
import { ROUTES_PATH } from "@/routes";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItems, NavigateToPage, QuickMenuContainer, StyledAccordion } from "./styles";

const QuickMenu = (props) => {
  const [menuVisible, setMenuVisible] = useState(true);
  return (
    <>
      <QuickMenuContainer>
        <div className="menu-toggle" onClick={() => setMenuVisible((prev) => !prev)}>
          <span>Preview or go to</span>
          <span>hide</span>
        </div>
        <MenuItems isVisible={menuVisible}>
          <StyledAccordion>
            <Link className="accordion-header-link" eventKey="dashboard">
              <div style={{ display: "flex" }}>
                <div className="icon">
                  <img alt="Booking Queue Icon" src={dashboard} />
                </div>
                <div className="text">Dashboard</div>
              </div>
              <NavigateToPage>
                <img alt="Arrow Right Blue" src={arrowRightBlueModern} />
              </NavigateToPage>
            </Link>
            <Accordion.Item eventKey="booking-queue">
              <div className="accordion-header-container">
                <Accordion.Header>
                  <div className="icon">
                    <img alt="Booking Queue Icon" src={bookingQueue} />
                  </div>
                  <div className="text">Booking Queue</div>
                </Accordion.Header>
                <NavigateToPage to={ROUTES_PATH.BOOKING_QUEUE}>
                  <img alt="Arrow Right Blue" src={arrowRightBlueModern} />
                </NavigateToPage>
              </div>
              <Accordion.Body>test body sdadd </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="movement">
              <div className="accordion-header-container">
                <Accordion.Header>
                  <div className="icon">
                    <img alt="Movement Icon" src={movement} />
                  </div>
                  <div className="text">Movement</div>
                </Accordion.Header>
                <NavigateToPage>
                  <img alt="Arrow Right Blue" src={arrowRightBlueModern} />
                </NavigateToPage>
              </div>
              <Accordion.Body>test body sdadd </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="recent-files">
              <div className="accordion-header-container">
                <Accordion.Header>
                  <div className="icon">
                    <img alt="Recent Files Icon" src={recentFiles} />
                  </div>
                  <div className="text">Recent Files</div>
                </Accordion.Header>
                <NavigateToPage>
                  <img alt="Arrow Right Blue" src={arrowRightBlueModern} />
                </NavigateToPage>
              </div>
              <Accordion.Body>test body sdadd </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </MenuItems>
      </QuickMenuContainer>
    </>
  );
};

export default QuickMenu;
