import { Flex } from "@/common/styles";
import styled, { css } from "styled-components";

const modalList = css`
  list-style: none;
  padding: unset;
  margin: unset;
`;
const modalListItem = css`
  font-weight: 300;
  text-transform: none;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 5px 5px 10px;
  margin-bottom: 15px;
  width: 100%;
  color: black;
  background: rgb(255, 255, 255);
  border: 2px solid rgba(82, 140, 181, 0.27);
  border-radius: var(--border-radius);
  transition: border 0.3s ease-in-out;
  cursor: pointer;

  pre {
    font-family: inherit;
  }

  &:hover {
    border-color: rgba(82, 140, 181, 0.72);
  }
`;

export const FormContainer = styled.div``;

export const AddressList = styled.ul`
  ${modalList};
`;

export const AddressItem = styled.li`
  ${modalListItem};
`;

export const AliasList = styled.ul`
  ${modalList};
`;

export const AliasItem = styled.li`
  ${modalListItem};
`;

export const InmateDetailsTableContainer = styled.div`
  --background-color: #f1f1f1;
  margin-top: 13px;
  padding: 15px 7px;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
`;

export const TableHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: unset;
  padding-bottom: 0.5rem;
  border-bottom: thin solid rgba(82, 140, 181, 0.27);

  h6 {
    text-transform: uppercase;
    color: rgba(2, 87, 147, 0.7);
    font-weight: 700;
    margin-bottom: unset;
  }

  .add-btn {
    font-size: 14px;

    & img {
      width: 15px;
      margin-right: 10px;
    }
  }
`;

export const MedicalInfoContainer = styled.div`
  h4 {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const QuestionnairesContainer = styled.ul`
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QuestionnaireItem = styled.li`
  text-align: center;
  margin-bottom: 25px;

  button {
    font-size: 20px;
    width: 100%;
    height: 50px;
    font-weight: normal;
  }
  .last-done {
    color: #006990;
    font-weight: 500;
    position: relative;
    display: flex;
    margin: 15px 0;
    font-size: 14px;

    &:before {
      content: url("src/assets/clock.svg");
      margin-right: 15px;
    }
  }
`;

export const MedicalModalHeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  background-color: unset !important;
  color: rgb(33, 37, 41) !important;
  padding: var(--bs-modal-header-padding);
  border-bottom: 1px solid var(--bs-modal-footer-border-color);

  .questionnaire-scores-container {
    flex: 1;
    margin: 0 15px;

    .main-score-container {
      background-color: #057ca9;
      color: white;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    .section-score-container {
      background-color: #c8f6ff;
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);

      .score {
        background-color: #9dcddf;
      }
    }

    .score-container {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 55px;
      letter-spacing: 0;
      overflow: hidden;

      .score-name {
        text-transform: uppercase;
        padding: 10px;
      }

      .score {
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
      }

      .points-caption {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .time {
    color: var(--text-blue-dark);
    font-size: 12px;
    align-self: center;
  }
`;

export const QuestionnaireModalBodyContainer = styled.div`
  padding: var(--bs-modal-padding);

  .questions {
    list-style: none;
    padding: unset;
    margin: unset;

    .question-item {
      background-color: rgba(172, 220, 250, 0.13);
      font-size: 18px;
      font-weight: 500;
      color: #041c2d;
      display: flex;
      align-items: stretch;
      min-height: 85px;
      border-radius: var(--border-radius);
      overflow: hidden;
      margin-bottom: 15px;

      & select {
        width: 200px;
      }

      .question,
      .options-container {
        padding-top: 25px;
        padding-left: 15px;
        padding-bottom: 20px;
      }

      .question,
      .options-container {
        flex: 1;
      }

      .question,
      .point-container {
        background-color: rgba(172, 220, 250, 0.13);
      }

      .question {
        display: flex;
        align-items: flex-start;

        .number {
          margin-right: 10px;
        }
      }

      .options-container {
        padding-left: 35px;
      }

      .point-container {
        flex-basis: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;

        .points {
          flex: 1;
        }

        .pts {
          font-size: 14px;
        }

        .icon-container {
          width: 24px;
          height: 24px;
        }
      }
    }

    .question-item.done {
      background-color: rgba(2, 190, 0, 0.1);

      .question,
      .point-container {
        background-color: rgba(2, 190, 0, 0.1);
      }

      .point-container .icon-container {
        background: url("src/assets/done.svg");
      }
    }

    .question-item.pending {
      background-color: rgba(255, 128, 0, 0.1);

      .question,
      .point-container {
        background-color: rgba(255, 128, 0, 0.1);
      }

      .point-container .icon-container {
        background: url("src/assets/wip.svg");
      }
    }
  }
`;

export const SectionHeading = styled.div`

.label{
  display: block;
  margin-top: 10px;
  color: #006990;
  text-align: center;
  padding: 5px 5px;
}
  `

  export const CommentField = styled.div`
   display: block;
    marginTop: 1rem;
  `

  export const StyledDate = styled.div`
  color: #8bddff;
  font-size: 14px;
  font-weight: 700;
  `


