import { Dropdown as RBDropdown } from "react-bootstrap";
import { StyledDropdownButton } from "./styles";

export const DROPDOWN_VARIANT = {
  PRIMARY: "primary",
  TEXT: "text",
  TEXT_BLUE: "text-blue",
  PRIMARY_BTN: "primary-btn",
  PRIMARY_DARK_BTN: "primary-dark-btn",
};

const Dropdown = ({ variant = "primary", id = "dropdown-custom", label, children, ...props }) => {
  return (
    <>
      <StyledDropdownButton variant={variant} id={id} title={label} {...props}>
        {children}
      </StyledDropdownButton>
    </>
  );
};

Dropdown.Item = ({ children, ...props }) => <RBDropdown.Item as="button" {...props}>{children}</RBDropdown.Item>;

export default Dropdown;
