import { LockFilled, StarFilled,ClockCircleOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  CodeOutlined
 } from "@ant-design/icons";

export const multiLevelOptions = [
    {
      key: 'starred',
      label: 'Starred Items',
      icon:<span className="starred"><StarFilled /></span>,
      children: [
        {
          key: 'starred-component-1',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-2',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-3',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-4',
          label: 'Category/Subcategory/View-Edit Only',
          icon:<div className="cascader-icon-group"><span className="starred"><StarFilled /></span>
          <LockFilled/>
          </div>,
          disabled:true,
        },
        {
          key: 'starred-component-5',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>
        },
      ]
    },
    {
      key: 'reference-lists',
      label: 'Reference Lists',
      children:[
        {
          key: 'reference-component-1',
          label: 'Component Name'
        },
        {
          key: 'reference-component-2',
          label: 'Component Name'
        },
        {
          key: 'reference-component-3',
          label: 'Component Name'
        }
      ]
    },
    {
      key:'questionnaires',
      label: 'Questionnaires',
      children:[
        {
          key: 'questionnaires-component-1',
          label: 'Component Name'
        },
        {
          key: 'questionnaires-component-2',
          label: 'Component Name'
        },
        {
          key: 'questionnaires-component-3',
          label: 'Component Name'
        }
      ]
    },
    {key:'components',label:'Components',
      children:[
        {
          key: 'components-component-1',
          label: 'Component Name'
        },
        {
          key: 'components-component-2',
          label: 'Component Name'
        },
        {
          key: 'components-component-3',
          label: 'Component Name'
        }
      ]
    },
    { key:'view-or-Test-only-Item',
      label:'View/Test-Only Item',
      icon:<LockFilled/>,
      disabled:true,
      children:[]
    },
    {key:'category-name',label:"Category Name",
      children:[
        {
          key: 'category-1',
          label: 'Category Name'
        },
        {
          key: 'category-2',
          label: 'Category Name'
        },
        {
          key: 'category-3',
          label: 'Category Name'
        }
      ]
    }
  ];
  export const configMenuItems = [
    {
      key: 'starred',
      label: 'Starred Items',
      icon:<span className="starred"><StarFilled /></span>,
      children: [
        {
          key: 'starred-component-1',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-2',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-3',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>,
        },
        {
          key: 'starred-component-4',
          label: 'Category/Subcategory/View-Edit Only',
          icon:<div className="cascader-icon-group"><span className="starred"><StarFilled /></span>
          <LockFilled/>
          </div>,
          disabled:true,
        },
        {
          key: 'starred-component-5',
          label: 'Category/Subcategory/Component',
          icon:<span className="starred"><StarFilled /></span>
        },
      ]
    },
    {
      key: 'my-edit-history',
      label: 'My Edit History',
      icon:<ClockCircleOutlined/>,
      children: [
        {
          key: 'edit-history-recent-components',
          label: 'Recent components',
          children:[
            {key:"edit-history-recent-1",
              label:"Category / Subcategory / Component",
              icon:<span className="starred"><StarFilled /></span>,
            },
            {key:"edit-history-recent-2",
              label:"Category / Subcategory / Component",
              icon:<span className="starred"><StarFilled /></span>,
            },
            {key:"edit-history-recent-3",
              label:"Category / Subcategory / Component",
              icon:<span className="starred"><StarFilled /></span>,
            }
          ]
        },
        {
          key: 'edit-history-2',
          label: 'Audit Logs',
          children:[]
        },
      ]
    },
    {
      key:"common-tasks",
      label:"Common Tasks",
      icon:<SettingOutlined />,
      children:[]
    },
    {
      key:"how-do",
      label:"How Do I ?",
      icon:<CodeOutlined />,
      children:[]
    },
    {
      key:"help-documentation",
      label:"Help Documentation",
      icon:<QuestionCircleOutlined />,
      children:[]
    },
    {
      key:"config-modules",
      label:"Config Modules",
      children:[]
    },
    {
      key: 'reference-lists',
      label: 'Reference Lists',
      children:[
        {
          key: 'reference-component-1',
          label: 'Component Name'
        },
        {
          key: 'reference-component-2',
          label: 'Component Name'
        },
        {
          key: 'reference-component-3',
          label: 'Component Name'
        }
      ]
    },
    {
      key:'questionnaires',
      label: 'Questionnaires',
      children:[
        {
          key: 'questionnaires-component-1',
          label: 'Component Name'
        },
        {
          key: 'questionnaires-component-2',
          label: 'Component Name'
        },
        {
          key: 'questionnaires-component-3',
          label: 'Component Name'
        }
      ]
    },
    {key:'components',label:'Components',
      children:[
        {
          key: 'components-component-1',
          label: 'Component Name'
        },
        {
          key: 'components-component-2',
          label: 'Component Name'
        },
        {
          key: 'components-component-3',
          label: 'Component Name'
        }
      ]
    },
    {key:'category-name',label:"Category Name",
      children:[
        {
          key: 'category-1',
          label: 'Category Name'
        },
        {
          key: 'category-2',
          label: 'Category Name'
        },
        {
          key: 'category-3',
          label: 'Category Name'
        }
      ]
    }
  ];