import TextField from "@/common/elements/InputElements/TextField";
import React from 'react';
import clock from "@/assets/clock.svg";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";
import Select from "@/common/elements/InputElements/Select";
import { MedicalModalHeaderContainer, QuestionnaireModalBodyContainer } from "./styles";
import Modal from "@/common/elements/Modal";
import quiz from './MedicalQuestions';
import { SectionHeading, CommentField } from "./styles";
import { useFormik } from "formik";



const ModalHeader = ({ onHide }) => {
  return (
    <>
      <Button variant="dark" onClick={onHide}>
        Exit
      </Button>
      <div className="questionnaire-scores-container">
        <div className="main-score-container score-container">
          <div className="score-name">Mental Health Appraisal Questionnaire</div>
          <div className="score">
            <span className="points-caption">Total Points: </span>35
          </div>
        </div>
        <div className="section-score-container score-container">
          <div className="score-name">A: MENTAL HEALTH STATUS, LETHALITY AND BEHAVIOR</div>
          <div className="score">
            <span className="points-caption">Total Points: </span>35
          </div>
        </div>
      </div>
      <div className="time">
        <img alt="Clock Icon" src={clock} /> N / A
      </div>
    </>
  );
};

const MedicalQuestionnaireModal = ({ show, onHide, selectedQuiz }) => {

  const formik = useFormik({
    initialValues: {
      answers: {}, // Stores answers keyed by question IDs
      deputyName: "", // For mental quiz deputy name field
    },
    onSubmit: (values) => {
      console.log("Form Submitted:", values);
    },
  });

  const handleSelectChange = (e, questionId) => {
    console.log(formik.setFieldValue(`answers.${questionId}`, e.target.value));
  };


  const onModalHide = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onModalHide}
      size="xl"
      footerCloseAction
      footerCloseActionText="Close"
      bodyStyle={{ padding: "unset" }}
    >

      <form onSubmit={formik.handleSubmit}>
        <MedicalModalHeaderContainer>
          <ModalHeader onHide={onModalHide} />
        </MedicalModalHeaderContainer>

        {selectedQuiz === "Appraisal" && (
          <QuestionnaireModalBodyContainer>

            <SectionHeading className="section-heading">
              <h3>Inmate Questions</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.inmateQuiz.map((item) => (
                <li key={item.id} >
                  <div className="question-item">
                    <div className="question">
                      <span className="number">{item.id}.</span> {item.question}
                    </div>
                    <div className="options-container">
                      <Select
                        variant="intake-form"
                        id={`question-${item.id}`}
                        name={`question-${item.id}`}
                        onChange={(e) => handleSelectChange(e, item.id)}
                      >
                        <option disabled selected value="-">Please select</option>
                        {item.options.map((option, index) => (
                          <option key={index} value={option.toLowerCase()}>{option}</option>
                        ))}
                      </Select>
                    </div>
                    <div className="point-container">
                      <div className="points">
                        10 {/* Adjust point value as needed */}
                        <div className="pts">pts</div>
                      </div>
                      <div className="icon-container"></div>
                    </div>
                  </div>

                  {item.id === 1 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="Describe the accident"
                        name="accident"
                        onChange={formik.handleChange}
                        value={formik.values.accident}
                      />
                    </CommentField>
                  )}

                  {item.id === 2 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="Which medications"
                        name="medications"
                        onChange={formik.handleChange}
                        value={formik.values.medications}
                      />

                      <TextField
                        label="Describe the allergic reaction to medicine"
                        name="medicine"
                        onChange={formik.handleChange}
                        value={formik.values.medicine}
                      />
                    </CommentField>
                  )}

                  {item.id === 3 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="Which foods?"
                        name="foods"
                        onChange={formik.handleChange}
                        value={formik.values.foods}
                      />

                      <TextField
                        label="Describe the allergic reaction to foods"
                        name="allergicFoods"
                        onChange={formik.handleChange}
                        value={formik.values.allergicFoods}
                      />
                    </CommentField>
                  )}

                  {item.id === 4 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="what medications do you take?"
                        name="medicationsTaking"
                        defaultValue=""
                        onChange={formik.handleChange}
                        value={formik.values.medicationsTaking}
                      />

                      <Select
                        label="Do you have medications with you?"
                        name="medicationsInHand"
                        onChange={formik.handleChange}
                        value={formik.values.medicationsInHand}
                      > <option disabled selected value="-">Please select</option>
                        {item.options.map((option, index) => (
                          <option key={index} value={option.toLowerCase()}>{option}</option>
                        ))}</Select>
                    </CommentField>
                  )}

                  {item.id === 31 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>

                      <Select
                        label="Do you have a plan for killing youself?"
                        name="plan"
                        onChange={formik.handleChange}
                        value={formik.values.plan}
                      >
                        <option disabled selected value="-">Please select</option>
                        {item.options.map((option, index) => (
                          <option key={index} value={option.toLowerCase()}>{option}</option>
                        ))}</Select>

                      <TextField
                        label="Please give details"
                        as="textarea"
                        name="killingIdeas"
                        defaultValue=""
                        onChange={formik.handleChange}
                        value={formik.values.killingIdeas}
                      />


                    </CommentField>
                  )}
                </li>
              ))}
            </ul>

            <TextField
              label="Please enter any comments about your medical condition"
              name="medicalCondition"
              defaultValue=""
              onChange={formik.handleChange}
              value={formik.values.medicalCondition}
            />



            <SectionHeading className="section-heading">
              <h3>Female-Specific Questions</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.femaleProblems.map((item) => (
                <li key={item.id} >
                  <div className="question-item">
                    <div className="question">
                      <span className="number">{item.id}.</span> {item.question}
                    </div>
                    <div className="options-container">
                      <Select
                        variant="intake-form"
                        id={`question-${item.id}`}
                        name={`question-${item.id}`}
                        onChange={(e) => handleSelectChange(e, item.id)}
                      >
                        <option disabled selected value="-">Please select</option>
                        {item.options.map((option, index) => (
                          <option key={index} value={option.toLowerCase()}>{option}</option>
                        ))}
                      </Select>
                    </div>
                  </div>

                  {item.id === 32 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="What kind of female problems?"
                        name="femaleProblem"
                        onChange={formik.handleChange}
                        value={formik.values.femaleProblem}
                      />
                    </CommentField>
                  )}

                  {item.id === 33 && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        label="When is your due date?"
                        name="dueDate"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.dueDate}
                      />

                      <TextField
                        label="Are you having ant problems with your pregnancy"
                        name="pregnancyProblem"
                        onChange={formik.handleChange}
                        value={formik.values.pregnancyProblem}
                      />
                    </CommentField>
                  )}

                  <div className="point-container">
                    <div className="points">
                      10 {/* Adjust point value as needed */}
                      <div className="pts">pts</div>
                    </div>
                    <div className="icon-container"></div>
                  </div>
                </li>
              ))}
            </ul>

            <SectionHeading className="section-heading">
              <h3>Direct Observations</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.directObservations.map((item) => (
                <li key={item.id} className="question-item">
                  <div className="question">
                    <span className="number">{item.id}.</span> {item.question}
                  </div>
                  <div className="options-container">
                    <Select variant="intake-form" id={`question-${item.id}`} name={`question-${item.id}`}>
                      <option disabled selected value="-">Please select</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.toLowerCase()}>{option}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="point-container">
                    <div className="points">
                      10 {/* Adjust point value as needed */}
                      <div className="pts">pts</div>
                    </div>
                    <div className="icon-container"></div>
                  </div>
                </li>
              ))}
            </ul>

            <SectionHeading className="section-heading">
              <h3>General</h3>
            </SectionHeading>

            <TextField
              as="textarea"
              label='Please enter any other general comments,issues,concerns'
              placeholder=''
              required
            />


          </QuestionnaireModalBodyContainer>
        )}

        {selectedQuiz === "Mental" && (
          <QuestionnaireModalBodyContainer>

            <SectionHeading className="section-heading">
              <h3>Questions</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.medicalQuiz.map((item) => (
                <li key={item.id}>
                  <div className="question-item">
                    <div className="question">
                      <span className="number">{item.id}.</span> {item.question}
                    </div>
                    <div className="options-container">
                      <Select
                        variant="intake-form"
                        id={`question-${item.id}`}
                        name={`question-${item.id}`}
                        onChange={(e) => handleSelectChange(e, item.id)}
                      >
                        <option disabled selected value="-">Please select</option>
                        {item.options.map((option, index) => (
                          <option key={index} value={option.toLowerCase()}>{option}</option>
                        ))}
                      </Select>
                    </div>
                    <div className="point-container">
                      <div className="points">
                        10 {/* Adjust point value as needed */}
                        <div className="pts">pts</div>
                      </div>
                      <div className="icon-container"></div>
                    </div>
                  </div>
                  {item.id && formik.values.answers[item.id] === "yes" && (
                    <CommentField>
                      <TextField
                        as="textarea"
                        label="Comments"
                        name="additionalInfo"
                        onChange={formik.handleChange}
                        value={formik.values.additionalInfo}
                      />
                    </CommentField>
                  )}

                </li>
              ))}
            </ul>

            <SectionHeading className="section-heading">
              <h3>Deputy's Comments/Impressions</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.DeputyCommentsImpressions.map((item) => (
                <li key={item.id} className="question-item">
                  <div className="question">
                    <span className="number">{item.id}.</span> {item.question}
                  </div>
                  <div className="options-container">
                    <Select variant="intake-form" id={`question-${item.id}`} name={`question-${item.id}`}>
                      <option disabled selected value="-">Please select</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.toLowerCase()}>{option}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="point-container">
                    <div className="points">
                      10 {/* Adjust point value as needed */}
                      <div className="pts">pts</div>
                    </div>
                    <div className="icon-container"></div>
                  </div>
                </li>
              ))}
            </ul>

            <TextField
              as="textarea"
              label='Comments'
              placeholder=''
              onChange={formik.handleChange}
              value={formik.values.deputyName}
            />

            <SectionHeading className="section-heading">
              <h3>Recommendations</h3>
            </SectionHeading>

            <ul className="questions">
              {quiz.Recommendation.map((item) => (
                <li key={item.id} className="question-item">
                  <div className="question">
                    <span className="number">{item.id}.</span> {item.question}
                  </div>
                  <div className="options-container">
                    <Select variant="intake-form" id={`question-${item.id}`} name={`question-${item.id}`}>
                      <option disabled selected value="-">Please select</option>
                      {item.options.map((option, index) => (
                        <option key={index} value={option.toLowerCase()}>{option}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="point-container">
                    <div className="points">
                      10 {/* Adjust point value as needed */}
                      <div className="pts">pts</div>
                    </div>
                    <div className="icon-container"></div>
                  </div>
                </li>
              ))}
            </ul>

            <TextField
              label='Deputy Name'
              placeholder=''
              required
              onChange={formik.handleChange}
              value={formik.values.deputyName}
            />
          </QuestionnaireModalBodyContainer>

        )}

      </form>
    </Modal>
  );
};

export default MedicalQuestionnaireModal;
