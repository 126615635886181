import { useState } from "react";
import { Accordion as RBAccordion } from "react-bootstrap";

const Accordion = ({ children, ...props }) => {
  const [selectedKey, setSelectedKey] = useState();

  const onSelect = (key) => {
    setSelectedKey(key);
  };

  return (
    <>
      <RBAccordion activeKey={selectedKey} onSelect={onSelect} {...props}>
        {children}
      </RBAccordion>
    </>
  );
};

Accordion.Item = ({ eventKey, children, ...props }) => {
  return (
    <>
      <RBAccordion.Item eventKey={eventKey} {...props}>
        {children}
      </RBAccordion.Item>
    </>
  );
};

Accordion.Body = ({ children, ...props }) => <RBAccordion.Body {...props}>{children}</RBAccordion.Body>;

Accordion.Header = ({ children, isExpandable, ...props }) => (
  <RBAccordion.Header as={"div"} {...props} onClick={(event) => {}}>
    {children}
  </RBAccordion.Header>
);

export default Accordion;
