import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import { INPUT_VARIANT } from "../const";
import { FormCheck } from "../styles";

const Checkbox = ({ id, name, label, labelStyle, style, children, variant = INPUT_VARIANT._, ...rest }) => {
  return (
    <>
      <FormCheck
        id={id}
        name={name}
        style={style}
        className={variant}
        {...rest}
      >
        {children}
      </FormCheck>
    </>
  );
};

export default Checkbox;