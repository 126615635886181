import Checkbox from "@/common/elements/InputElements/CheckBox";
import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import Modal from "@/common/elements/Modal";
import _ from "lodash";
import { forwardRef, useState } from "react";
import { Button, Col, Row, Form } from "antd";
import { FormContainer } from "./styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { labels } from "@/util/label";
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "@/common/elements/Modal/styles";
import FormItem from "antd/es/form/FormItem";




export const MODE = { NEW: "new", EDIT: 'edit'}; 


const propertyValidationSchema = Yup.object().shape({
  itemNumber : Yup.string().required(),
  propType: Yup.string().required(),
  location: Yup.string().required(),
  serialNumber: Yup.string().required(),
  itemStatus: Yup.string().required(),
  itemColor: Yup.string().required(),
  quantity: Yup.string().matches(/^\d*$/, "Quantity must be a number").required(),
  isWeapon: Yup.boolean(),
  description: Yup.string() 
})


const PropertyModal = forwardRef(
  (
    {
      modalData = {
        itemNumber: "",
        propType: "",
        location: "",
        serialNumber: "",
        itemStatus: "",
        itemColor: "",
        quantity: "",
        isWeapon: false,
        description: "",
      },
      mode = MODE.EDIT,
      show,
      onHide
    },
    ref
  ) => {  
    
   
    
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"

      >
        <ModalHeader closeButton>
          <ModalTitle>Add Item</ModalTitle>
        </ModalHeader>

        <ModalBody>
    
        <FormContainer>
          <Formik 
          initialValues={modalData}
          validationSchema={propertyValidationSchema}
          innerRef={ref}
          onSubmit={(values, { setSubmitting }) => {
            console.log("Validated values:", values);
            setSubmitting(false);
            onHide
          }}>
            {({     getFieldProps,
                    setFieldValue,
                    handleSubmit,
                    errors,
              }) => (
            <Form noValidate onSubmit={handleSubmit}>            
            <Row gutter={[16,16]}>

            <Col span={6}>
              <FormItem 
              label={labels.item}
              >
              <TextField
                name="itemNumber"       
                {...getFieldProps("itemNumber")}
                placeholder="Item #"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.itemNumber}
              />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem label={labels.propType}>
              <TextField
                name="propType"     
                {...getFieldProps("propType")}
                placeholder="Prop Type"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.propType}
              />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem>
              <TextField
               label={labels.location}
                name="location"   
                {...getFieldProps("location")}
                placeholder="Location"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.location}
              />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem 
              label={labels.serialNumber}
              >
              <TextField
                name="serialNumber"        
                {...getFieldProps("serialNumber")}
                placeholder="Serial #"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.serialNumber}
              />
              </FormItem>
            </Col>

    
            <Col span={6}>
              <FormItem 
              label={labels.itemStatus}
              >
              <TextField
                name="itemStatus"            
                {...getFieldProps("itemStatus")}
                placeholder="Item Status"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.itemStatus}
              />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem label={labels.itemColor}>
              <TextField            
                name="itemColor"            
                {...getFieldProps("itemColor")}
                placeholder="Item Color"
                required
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.itemColor}
              />
              </FormItem>
            </Col>

            <Col span={6}>
             <FormItem 
             label={labels.qunatity}
             >
              <TextField
                 placeholder="Quantity"
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  setFieldValue("quantity", sanitizedValue); 
                }}
                onKeyDown={(e) => {
                  if (e.key.match(/[^0-9]/) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
                name="quantity"
                required                       
                variant={INPUT_VARIANT.INTAKE_FORM}
                isInvalid={!!errors.quantity}

              />
              </FormItem>
              {errors.quantity && <div className="error-message">{errors.quantity}</div>}              
            </Col>


            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
             <FormItem>
              <Checkbox
                label={labels.weapon}
                name="isWeapon"             
                {...getFieldProps("isWeapon")}
                variant={INPUT_VARIANT.INTAKE_FORM}
                style={{ flex: "1" }}
              />
              </FormItem>
            </Col>
        
            <Col span={6}>
              <FormItem 
              label={labels.description}>
              <TextField
                as="textarea"
                rows={5}                
                name="description"          
                {...getFieldProps("description")}
                placeholder="Description"
                variant={INPUT_VARIANT.INTAKE_FORM}
              />
              </FormItem>
            </Col>

          </Row>

          <ModalFooter>
          <Button type="submit" variant="primary" >Save Changes</Button>
          <Button variant="secondary" onClick={onHide}>Close</Button>
        </ModalFooter>
          </Form>
           )}
          </Formik>
        </FormContainer>

        </ModalBody>

       

        
      </Modal>
    </>
  );
}
);

export default PropertyModal;
