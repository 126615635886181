import { INPUT_VARIANT } from "../const";
import { FormControl, FormGroup } from "../styles";

const TextField = ({ id, name, label, inputType = "", variant = INPUT_VARIANT._, required, ...rest }) => {
  return (
    <>
      {
        inputType == "" && <FormControl id={id} name={name} className={`${rest?.className} ${variant}`}  {...rest} />
      }
      {
        inputType == "password" &&
        <FormControl.Password id={id} name={name} className={`${rest?.className} ${variant}`}  {...rest} />
      }
      {
        inputType == "textarea" &&
        <FormControl.TextArea id={id} name={name} className={`${rest?.className} ${variant}`}  {...rest} />
      }

    </>
  )
};

export default TextField;