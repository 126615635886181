import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import { Col, Row, Form } from "antd";
import { INMATE_DETAILS_MODE } from ".";
import { FormContainer } from "./styles";
import { StyledDate } from '../InmateDetails/styles';
import Select from "@/common/elements/InputElements/Select";
import React, { forwardRef, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import CommonDatePicker from '@/common/elements/DatePicker';
import dayjs from 'dayjs';
import { arrayToObject, checkRequiredField, getOptionsFromRefList, getSQLTypeToHTMLType, handleFormForSetTouched, isEmptyObject } from "@/util/common_function";
import { staticFields } from "@/util/common_variable";
import FormItem from "antd/es/form/FormItem";

const ArrestBook = forwardRef(
  (
    { formData = {}, mode = INMATE_DETAILS_MODE.EDIT },
    ref
  ) => {

    const [arrestBookValidationSchema, setArrestBookValidationSchema] = useState(null);

    useEffect(() => {
      initValidate();
    }, [])

    useEffect(() => {
      arrestBookValidationSchema?._nodes?.forEach((n) => {
        ref.current.setFieldValue(n, '')
      })
    }, [arrestBookValidationSchema])

    const initValidate = async () => {
      const va = await arrayToObject(staticFields?.arrest_book_info);
      const validate = Yup.object().shape(va);
      setArrestBookValidationSchema(validate);
    }



    return (
      <FormContainer>
        <Formik
          initialValues={formData}
          validationSchema={arrestBookValidationSchema}
          innerRef={ref}
          validateOnBlur
        >
          {({ getFieldProps, setFieldValue, values }) => {
            return (
              <Form >
                <Row gutter={[16, 16]}>

                  {
                    staticFields?.arrest_book_info?.map((sf, index) => {
                      const { label, columnName, tableName, type, refListName } = sf;
                      const htmlType = getSQLTypeToHTMLType(type);
                      const paramContext = tableName + ':' + columnName;
                      return <React.Fragment key={index}>
                        {
                          // checkField(paramContext) &&
                          <Col span={6}>
                            <FormItem
                              label={label}
                              layout="vertical"
                            >
                              {
                                (htmlType === 'text' || htmlType === 'number') &&

                                <TextField
                                  label={label}
                                  type={htmlType}
                                  {...getFieldProps(columnName)}
                                  placeholder={label}
                                  variant={INPUT_VARIANT.INTAKE_FORM}
                                  readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                                  value={values?.[columnName] || ''}
                                  onKeyDown={() => handleFormForSetTouched(ref)}
                                  required={checkRequiredField(paramContext)}
                                />

                              }
                              {
                                (htmlType === 'select') &&
                                <>
                                  <Select
                                    label={label}
                                    {...getFieldProps(columnName)}
                                    placeholder={label}
                                    value={values?.[columnName] || 'Select'}
                                    variant="outlined"
                                    readOnly={mode === INMATE_DETAILS_MODE.VIEW}
                                    onChange={(value) => { setFieldValue(columnName, value) }}
                                    required={checkRequiredField(paramContext)}
                                    options={getOptionsFromRefList(refListName || columnName)}
                                  />
                                </>
                              }
                              {
                                htmlType === 'datetime-local' &&
                                <StyledDate required>
                                  {/* <div>
                                  <label htmlFor={columnName}>{label}</label>
                                </div> */}
                                  <CommonDatePicker
                                    style={{
                                      border: "2px solid rgba(82, 140, 181, 0.27)"
                                    }}
                                    placeholder="MM/DD/YYYY"
                                    format={{
                                      format: 'MM/DD/YYYY'
                                    }}
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    disableFutureDates={true}
                                    value={values[columnName] ? dayjs(values?.[columnName]) : null}
                                    onChange={(date) => {
                                      setFieldValue(columnName, date),
                                        handleFormForSetTouched(ref)
                                    }}
                                  />
                                </StyledDate>
                              }
                              <ErrorMessage
                                name={columnName}
                                component='div'
                                className='error'
                                style={{ color: "red" }}
                              />
                            </FormItem>
                          </Col>
                        }
                      </React.Fragment>
                    })
                  }

                </Row>
              </Form>
            )
          }
          }
        </Formik>
      </FormContainer >
    );
  }
);

export default ArrestBook;
