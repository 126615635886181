import { Spin } from "antd";
import { LoaderContainer } from "./styles";

const Loader = (props) => {
  return (
    <>
      <LoaderContainer>
        <Spin /> Loading
      </LoaderContainer>
    </>
  );
};

export default Loader;
