import backTriangleBlue from "@/assets/back-triangle-blue.svg";
import continueTriangleOrange from "@/assets/continue-triangle-orange.svg";
import Button from "@/common/elements/Button";
import { useEffect, useMemo, useState } from "react";
import { Tab } from "react-bootstrap";
import { ContentContainer, StyledTabs, TabActions } from "./styles";
import { handleFormForSetTouched } from "@/util/common_function";
import { CONSTANT } from "@/util/ant_var";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

const getIndex = (tabs = [], toFindKey) => {
  return tabs.findIndex((tab) => tab.key === toFindKey);
};

const Tabs = ({
  defaultActiveTab = "",
  tabs = [],

  navControls = false,
  tabProps = {},
  contentFullWidth = false,
  activeTab: activeTabFromProps,
  onChange,
  onNextButtonClick
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [activeTabIndex, setActiveTabIndex] = useState(
    getIndex(tabs, defaultActiveTab)
  );

  useEffect(() => {
    if (activeTabFromProps) {
      setActiveTab(activeTabFromProps);
      setActiveTabIndex(getIndex(tabs, activeTabFromProps));
    }
  }, [activeTabFromProps]);

  const canPrev = useMemo(() => {
    if (activeTabIndex <= 0) return false;
    return true;
  }, [activeTabIndex, tabs]);

  const canNext = useMemo(() => {
    if (activeTabIndex >= tabs.length - 1) return false;
    return true;
  }, [activeTabIndex, tabs]);

  const onTabChange = (key) => {
    setActiveTab(key);
    setActiveTabIndex(getIndex(tabs, key));
    onChange && onChange(key);
  };

  const onBackClick = () => {
    if (navControls && activeTabIndex > 0) {
      setActiveTabIndex((prev) => {
        const newIndex = --prev;
        setActiveTab(tabs[newIndex].key);
        return newIndex;
      });
    }
  };

  const onNextClick = async (formRef, key) => {
    if (navControls && activeTabIndex < tabs.length - 1) {
      handleFormForSetTouched(formRef);

      // Manually trigger validation
      if (formRef != undefined) {
        const errors = await formRef.current.validateForm();
        if (Object.keys(errors).length > 0) {
          return;
        }
      }

      onNextButtonClick(key, formRef);

      setActiveTabIndex((prev) => {
        const newIndex = ++prev;
        setActiveTab(tabs[newIndex].key);
        tabs[newIndex].disabled = false;
        return newIndex;
      });
    }
  };

  return (
    <>
      <StyledTabs fill activeKey={activeTab} onSelect={onTabChange}>
        {tabs.map(
          ({ key, title, component: Component, disabled, ref: formRef }) => (
            <Tab key={key} eventKey={key} title={title} disabled={disabled}>
              {navControls && (
                <TabActions>
                  <Button
                    className="icon-size-xx-lg"
                    type="text"
                    variant="text"
                    disabled={!canPrev}
                    onClick={onBackClick}
                    icon={<CaretLeftOutlined />}
                  >
                    {/* <img alt='Back Icon' src={backTriangleBlue} /> */}
                    Back
                  </Button>

                  <Button
                    className="icon-size-xx-lg"
                    style={{ 'color': canNext ? CONSTANT.WARNING : '' }}
                    type="link"
                    variant="link"
                    disabled={!canNext}
                    onClick={() => onNextClick(formRef, key)}
                    icon={<><CaretRightOutlined /><CaretRightOutlined style={{ marginLeft: '-18px' }} /></>}
                    iconPosition="end"
                  >
                    Continue
                    {/* <img alt='Continue Icon' src={continueTriangleOrange} /> */}
                  </Button>
                </TabActions>
              )}
              <ContentContainer contentFullWidth={contentFullWidth}>
                <Component
                  ref={formRef}
                  {...(tabProps.hasOwnProperty(key) ? tabProps[key] : {})}
                />
              </ContentContainer>
            </Tab>
          )
        )}
      </StyledTabs>
    </>
  );
};

export default Tabs;
