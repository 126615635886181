import { createContext,useContext, useState } from "react";

const BookingQueueSearchContext = createContext({});

export const BookingQueueSearchContextProvider = ({ children }) => {
  const [searchFilters, setSearchFilters] = useState([]);

  return (
    <BookingQueueSearchContext.Provider value={{searchFilters, setSearchFilters}}>
      {children}
    </BookingQueueSearchContext.Provider>
  );
};

export const useBookingSearchContext = () => useContext(BookingQueueSearchContext);
