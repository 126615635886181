// export const API_URL = 'http://35.169.152.152:8080';
export const API_URL = 'http://44.221.173.239:8082/api';
// export const API_URL = 'https://api.imatsbi2.com/api';
export const API_TIMEOUT = 10000;

const API_ENDPOINTS = {
  before_login: {
    login: `${API_URL}/login`,
    verify: `${API_URL}/verify`,
  },
  folderSearch: `${API_URL}/bookings/jackets/search`,
  bookingQueue: `${API_URL}/bookingsQueue/search`,
  clientParams: `${API_URL}/clientParams/all`,
  refList: `${API_URL}/ref-list/getRefList`,
};

export default API_ENDPOINTS;