import React, { createContext, useContext, useState } from "react";

const RefListContext = createContext();

export const ReflistProvider = ({ children }) => {
  const [refList, setRefList] = useState(null);

  return (
    <RefListContext.Provider value={{ refList, setRefList }}>
      {children}
    </RefListContext.Provider>
  );
};

export const useRefContext = () => useContext(RefListContext);
