import { PageTabsWrapper } from "@/common/styles";
import { useMemo, useState, useRef } from "react";
import Tabs from "../Tabs";
import ArrestBook from "./ArrestBook";
import Docs from "./Docs";
import HouseTrack from "./HouseTrack";
import MedicalInfo from "./MedicalInfo";
import Personal from "./Personal";
import Property from "./Property";
import { useEffect } from "react";

export const INMATE_DETAILS_MODE = { EDIT: "EDIT", VIEW: "VIEW" };

const InmateDetails = ({ mode = INMATE_DETAILS_MODE.EDIT, dataChange }) => {
  const [personalForm, setPersonalForm] = useState({});
  const [arrestBookForm, setArrestBookForm] = useState({});
  const [houseTrackForm, setHouseTrackForm] = useState({});
  const [medicalForm, setMedicalForm] = useState({});
  const [propertyForm, setPropertyForm] = useState({});
  const [docsForm, setDocsForm] = useState({});
  const personalFormRef = useRef();
  const arrestbookFormRef = useRef();
  const houseFormRef = useRef();
  const medicalRef = useRef;

  useEffect(() => {
    const form = {
      personalForm,
      arrestBookForm,
      houseTrackForm,
      medicalForm,
      propertyForm,
      docsForm,
    };


    console.log(form);

    dataChange(form);
  }, [
    personalForm,
    arrestBookForm,
    houseTrackForm,
    medicalForm,
    propertyForm,
    docsForm,
  ]);

  const handleHouseTrackUpdate = (
    { target: { name, value } },
    options = {}
  ) => {
    setHouseTrackForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleMedicalInfo = ({ target: { name, value } }, options = {}) => {
    setMedicalForm((prev) => ({ ...prev, [name]: value }));
  };

  const handlePropertyFormUpdate = (
    { target: { name, value } },
    options = {}
  ) => {
    const { isMulti = false, isEdit = false, editIndex = -1 } = options;
    if (isMulti && isEdit && editIndex > -1) {
      setPropertyForm((prev) => ({
        ...prev,
        [name]: [
          ...prev[name].slice(0, editIndex),
          { ...value },
          ...prev[name].slice(editIndex + 1),
        ],
      }));
      return;
    }
    setPropertyForm((prev) =>
      isMulti
        ? {
          ...prev,
          [name]: [...(prev.hasOwnProperty(name) ? prev[name] : []), value],
        }
        : { ...prev, [name]: value }
    );
  };

  const handleDocsFormUpdate = ({ target: { name, value } }, options = {}) => {
    const { isMulti = false, isEdit = false, editIndex = -1 } = options;
    if (isMulti && isEdit && editIndex > -1) {
      setDocsForm((prev) => ({
        ...prev,
        [name]: [
          ...prev[name].slice(0, editIndex),
          { ...value },
          ...prev[name].slice(editIndex + 1),
        ],
      }));
      return;
    }
    setDocsForm((prev) =>
      isMulti
        ? {
          ...prev,
          [name]: [...(prev.hasOwnProperty(name) ? prev[name] : []), value],
        }
        : { ...prev, [name]: value }
    );
  };

  const tabs = useMemo(
    () => [
      {
        key: "personal",
        title: "Personal",
        disabled: false,
        component: Personal,
        ref: personalFormRef,
      },
      {
        key: "arrest-book",
        title: "Arrest & Book",
        component: ArrestBook,
        disabled: true,
        ref: arrestbookFormRef,
      },
      {
        key: "house-track",
        title: "House & Track",
        disabled: true,
        component: HouseTrack,
        ref: houseFormRef,
      },
      {
        key: "medical-info",
        title: "Medical Info",
        component: MedicalInfo,
        disabled: true,
        // ref: medicalRef,
      },
      {
        key: "property",
        title: "Property",
        component: Property,
        disabled: true,
      },
      { key: "docs", title: "Docs", component: Docs, disabled: true },
    ],
    []
  );

  const nextButtonClick = (key, formRef) => {
    if (typeof formRef !== 'undefined') {
      const { current: { values } } = formRef;

      switch (key) {
        case 'personal': setPersonalForm(prev => ({ ...prev, ...values })); break;
        case 'arrest-book': setArrestBookForm(prev => ({ ...prev, ...values })); break;
        default:
          break;
      }
    }
  }

  return (
    <>
      <PageTabsWrapper>
        <Tabs
          tabs={tabs}
          defaultActiveTab={tabs[0]?.key}
          navControls
          onNextButtonClick={nextButtonClick}
          tabProps={{
            personal: {
              formData: personalForm
            },
            "arrest-book": {
              formData: arrestBookForm,
            },
            "house-track": {
              formData: houseTrackForm,
              onUpdateForm: handleHouseTrackUpdate,
            },
            "medical-info": {
              formData: medicalForm,
              onUpdateForm: handleMedicalInfo,
            },
            property: {
              formData: propertyForm,
              onUpdateForm: handlePropertyFormUpdate,
            },
            docs: { formData: docsForm, onUpdateForm: handleDocsFormUpdate },
          }}
        />
      </PageTabsWrapper>
    </>
  );
};

export default InmateDetails;
