import descSm from "@/assets/desc-sm.svg";

import Accordion from "@/common/elements/Accordion";
import { responsive } from "@/common/responsive";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const backgroundEffect = css`
  box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset, 0px -1px 0px rgba(5, 92, 126, 0.49) inset,
    2px 2px 7px rgba(0, 0, 0, 0.2);
  background-color: rgb(4, 42, 67);
  /*margin-right: 15px;*/
  border-radius: var(--border-radius);
`;

export const SidebarContainer = styled.div`
  height: ${({ headerHeight }) => `calc(100dvh - ${headerHeight}px - 10px)`};
  position: sticky;
  top: ${({ headerHeight }) => `${headerHeight}px`};
  left: 0;
  border-radius: var(--border-radius);
  min-width: 280px;
  width: 280px;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99998;
  background-color: var(--background-color);

  .footer-logo {
    background-color: white;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding:15px 20px 10px 20px;
    /*margin-right: 15px;*/
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  @media (max-width: ${responsive.md}) {
    min-width: 200px;
    width: 230px;
  }
`;

export const QuickMenuContainer = styled.div`
  ${backgroundEffect};
  margin-bottom: 20px;

  .menu-toggle {
    text-transform: uppercase;
    color: #32b2e6;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    height: 32px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  /* &:first-child {
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    .icon {
      border-radius: var(--border-radius) 0 0 0;
    }
  }

  &:last-child {
    .accordion-item,
    .accordion-button {
      border-radius: 0 0 var(--border-radius) var(--border-radius);

      .icon {
        border-radius: 0 0 0 var(--border-radius);
      }
    }
  } */

  .accordion-header-link {
    --collapse-icon-opacity: 0.5;
    --collapse-icon-opacity-hover: 0.7;
    display: flex;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    background-color: #0b3d5b;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2), inset 0 -1px 0 0 rgba(5, 92, 126, 0.49),
      inset 0 1px 1px 0 rgba(17, 115, 153, 0.5);
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--text-blue);
    text-decoration: none;
    align-items: stretch;
    justify-content: space-between;

    .icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(44, 194, 255, 0.25);
    }

    .text {
      margin-left: 10px;
      display: flex;
      align-items: center;
      /*justify-content: center;*/
      text-transform: uppercase;
      color: var(--text-blue);
    }

    &:hover {
      background-color: rgba(44, 194, 255, 0.25);
    }
  }

  .accordion-item {
    --bs-accordion-border-color: transparent;

    /*display: flex;*/
    /*align-items: stretch;*/
    background-color: #0b3d5b;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2), inset 0 -1px 0 0 rgba(5, 92, 126, 0.49),
      inset 0 1px 1px 0 rgba(17, 115, 153, 0.5);
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--text-blue);

    .icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(44, 194, 255, 0.25);
    }

    .text {
      margin-left: 10px;
      display: flex;
      align-items: center;
      /*justify-content: center;*/
      text-transform: uppercase;
      color: var(--text-blue);
    }

    .accordion-header-container {
      display: flex;
      align-items: stretch;
      gap: 5px;
    }

    .accordion-header {
      --collapse-icon-opacity: 0.5;
      --collapse-icon-opacity-hover: 0.7;
      display: flex;
      flex: 1;
      position: relative;
      transition: background-color 0.3s ease-in-out;

      &.collapsed:after {
        content: url("assets/desc-sm.svg");
        font-size: 2px;
        position: absolute;
        right: 5px;
        opacity: var(--collapse-icon-opacity);
        top: 50%;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-50%) rotate(0);
        /*transform: rotate(180deg);*/
      }

      &:not(.collapsed):after {
        content: url("assets/desc-sm.svg");
        font-size: 2px;
        position: absolute;
        right: 5px;
        opacity: var(--collapse-icon-opacity);
        top: 50%;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-50%) rotate(-180deg);
        /*transform: rotate(180deg);*/
      }

      &:hover {
        background-color: rgba(44, 194, 255, 0.25);

        &.collapsed:after,
        &:not(.collapsed):after {
          opacity: var(--collapse-icon-opacity-hover);
        }
      }

      .accordion-button {
        padding: unset;
        background-color: unset;
        color: inherit;
        outline: 0px;
        outline-color: transparent;

        &:after {
          --bs-accordion-btn-icon-width: 12px;
          background-image: url(${descSm});
          opacity: var(--collapse-icon-opacity);
        }

        &:focus-visible,
        &:focus {
          box-shadow: unset;
        }
      }
    }
  }
`;

export const NavigateToPage = styled(Link)`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(44, 194, 255, 0.25);
  }
`;

export const ContentContainer = styled.div`
  &.container-alt{
  height: 100%;
  overflow: auto;
  }
  &.container-card{
  ${backgroundEffect}
  flex: 1;
  overflow-y: auto;
  padding: 5px 5px 5px 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height:auto;
  max-height:100%;
  }
  .btn.disabled, .btn:disabled, fieldset:disabled .btn{
    background-color: #07678c;
    border-color:#0c79a4;
  }
`;

export const MenuItems = styled.div`
  ${({ isVisible }) => (isVisible ? `height: unset; overflow: auto;` : `height: 0; overflow: hidden;`)}
`;

export const SidebarHeader = styled.h4`
  text-align: center;
  text-transform: uppercase;
  color: white;
  width: 100%;
  font-size: 14px;
  display: inline-block;
  align-self: stretch;
  margin-top: 10px;
`;
