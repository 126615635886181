import { ClientparamsProvider } from "./ClientparamsContext";
import DocumentControlContextProvider from "./DocumentControlContext";
import { FolderSearchProvider } from "./FolderSearchContext";
import { ReflistProvider } from "./RefListContext";
import { BookingQueueSearchContextProvider } from "./SearchContexts/BookingQueue";


const GlobalContextProvider = ({ children }) => {
  return (
    <>
      <ClientparamsProvider>
        <ReflistProvider>
      <BookingQueueSearchContextProvider>
        <DocumentControlContextProvider>
          <FolderSearchProvider>{children}</FolderSearchProvider>
        </DocumentControlContextProvider>
        </BookingQueueSearchContextProvider>
        </ReflistProvider>
        </ClientparamsProvider>

    </>
  );
};

export default GlobalContextProvider;
