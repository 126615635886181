import Button from "@/common/elements/Button";
import { useState } from "react";
import MedicalQuestionnaireModal from "./MedicalQuestionnaireModal";
import { MedicalInfoContainer, QuestionnaireItem, QuestionnairesContainer } from "./styles";


const MedicalInfo = (props) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectQuiz, setSelectQuiz] = useState("");

  const onModalHide = () => {
    setIsModalShown(false);
  };

  const onModalShow = () => {
    setIsModalShown(true);
  };

  const onAppraisalQuestionnaireClick = () => {
    setSelectQuiz("Appraisal");
    onModalShow();
  };

  const onSecondQuestionnaireClick = () => {
    setSelectQuiz("Mental");
    onModalShow();
  };

  // const onThirdQuestionnaireClick = () => {
  //   onModalShow();
  // };

  return (
    <>
      <MedicalQuestionnaireModal show={isModalShown} onHide={onModalHide} selectedQuiz={selectQuiz}  />
      <MedicalInfoContainer>
        <h4>Available Questionnaire</h4>
        <QuestionnairesContainer>
          <QuestionnaireItem className="col-lg-8">
            <Button onClick={onAppraisalQuestionnaireClick}>Mental Health Appraisal Questionnaire</Button>
            <div className="last-done">01/02/24 @ 12:25 AM</div>
          </QuestionnaireItem>
          <QuestionnaireItem className="col-lg-8">
            <Button onClick={onSecondQuestionnaireClick}>New Mental Health</Button>
            <div className="last-done">N/A</div>
          </QuestionnaireItem>
          {/* <QuestionnaireItem className="col-lg-8">
            <Button onClick={onThirdQuestionnaireClick}>Third Questionnaire</Button>
            <div className="last-done">N/A</div>
          </QuestionnaireItem> */}
        </QuestionnairesContainer>
      </MedicalInfoContainer>
    </>
  );
};

export default MedicalInfo;
