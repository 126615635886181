 const quiz = {

    medicalQuiz : [

    { id:1, 
      question:"Do you currently believe that someone can control your mind by putting thoughts into your head or taking thoughts out of your head?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:2, 
      question:"Do you currently feel that other people know your thoughts and can read your mind?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:3, 
      question:"Have you currently lost or gained as much as two pounds a week for several weeks without even trying?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:4, 
      question:"Have you or your family or friends noticed that you are currently much more active than you usually are?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:5, 
      question:"Do you currently feel like you have to talk or move more slowly than you usually do?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:6, 
      question:"Have there currently been a few weeks when you felt like  you were useless or sinful?", 
      options:["Yes", "No", "Maybe"]
    },

    { id:7, 
      question:"Are you currently taking any medication prescribed for you by a physician for any emotional or mental health problems?", 
      options:["Yes", "No", "Maybe"]
    },
      
    { id:8, 
      question:"Have you ever been in a hospital for emotional or mental health problems?", 
      options:["Yes", "No", "Maybe"]
    }
],

 DeputyCommentsImpressions : [

    { id:9, 
      question:"Language Barrier", 
      options:["Yes", "No", "Maybe"]
    },

    { id:10, 
     question:"Under the influence of drugs/alcohol", 
     options:["Yes", "No", "Maybe"]
    },

    { id:11, 
      question:"Non-cooperative", 
      options:["Yes", "No", "Maybe"]
    },

    { id:12, 
      question:"Difficulty understanding questions", 
      options:["Yes", "No", "Maybe"]
    }
],

Recommendation : [
    { id:13, 
      question:"Recommend Further Evaluation?", 
      options:["Yes", "No", "Maybe"]
    }
],

 inmateQuiz : [
    {
     id:1, 
     question:"Have you been injured or involved in an accident within the last 24 hours?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:2, 
     question:"Are you allergic to medications?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:3, 
     question:"Are you allergic to foods?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:4, 
     question:"Do you take medications on a regular basis?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:5, 
     question:"Have you ever been told that you have HIV?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:6, 
     question:"Have you ever been told that you have Asthma?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:7, 
     question:"Have you ever been told that you have Diabetes?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:8, 
     question:"Have you ever been told that you have Hepatitis?", 
     options:["Yes", "No", "Maybe"]
    },

    {
     id:9, 
     question:"Have you ever been told that you have Seizures?", 
     options:["Yes", "No", "Maybe"]
    },

    {
      id:10, 
      question:"Have you ever been told that you have Seizures?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:11, 
      question:"Have you ever been told that you have Tuberculosis?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:12, 
      question:"Have you ever been told that you have Heart Disease?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:13, 
      question:"Have you ever been told that you have Sexually Transmitted Diseases?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:14, 
      question:"Have you ever been told that you have High Blood Pressure?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:15, 
      question:"Have you recently experienced a Persistent Cough?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:16, 
      question:"Have you recently experienced Bloody Sputum?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:17, 
      question:"Have you recently experienced Hoarseness?", 
       options:["Yes", "No", "Maybe"]
    },
    
    {
      id:18, 
      question:"Have you recently experienced Low Grade Fever?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:19, 
      question:"Have you recently experienced Fatigue?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:20, 
      question:"Have you recently experienced Chills?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:21, 
      question:"Have you recently evnerienced Night Sweats?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:22, 
      question:"Have you recently experienced Poor Appetite?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:23, 
      question:"Have you recently experienced Weight Loss?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:24, 
      question:"Does your doctor want you to eat a special diet?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:25, 
      question:"Do you have any skin problems?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:26, 
      question:"Have you been hospitalized within the last 6 months?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:27, 
      question:"Do you have any dental problems?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:28, 
      question:"Do you use alcohol, methadone or street drugs?", 
      options:["Yes", "No", "Maybe"]
    },

    {
      id:29, 
      question:"Have you ever had withdrawal from alcohol or drug?", 
      options:["Yes", "No", "Maybe"]
    },

    {
       id:30, 
       question:"Have you ever thought about or tried to kill yourself?", 
       options:["Yes", "No", "Maybe"]
    },

    {
       id:31, 
       question:"Are you thinking about killing yourself now?", 
       options:["Yes", "No", "Maybe"]
    }
  ],


 femaleProblems :  [
    {
        id:32, 
        question:"Do you have any female problems?", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:33, 
        question:"Are you pregnant?", 
        options:["Yes", "No", "Maybe"]
     },
   ],

 directObservations : [
    {
        id:34, 
        question:"Confused", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:35, 
        question:"Body Deformities", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:36, 
        question:"Sweating", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:37, 
        question:"Alert and Oriented", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:38, 
        question:"Tremors", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:39, 
        question:"Anxious", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:40, 
        question:"Difficulty Breathing", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:41, 
        question:"Difficulty Walking", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:42, 
        question:"Follows Commands", 
        options:["Yes", "No", "Maybe"]
     },
 
     {
        id:43, 
        question:"Uncontrolled Behavior", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:44, 
        question:"General population?", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:45, 
        question:"Medical Cell", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:46, 
        question:"Segregation?", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:47, 
        question:"Suicide precautions?", 
        options:["Yes", "No", "Maybe"]
     },

     {
        id:48, 
        question:"Referred to RN", 
        options:["Emergency", "N/A","Routine","Urgent"]
     },

     {
        id:49, 
        question:"Transported to", 
        options:["Clinic", "ER","N/A","Urgent Care"]
     },
   ]
 }




export default quiz;