import React, { createContext, useContext, useState } from "react";

const ClientparamsContext = createContext();

export const ClientparamsProvider = ({ children }) => {
  const [clientParam, setClientParam] = useState(null);

  return (
    <ClientparamsContext.Provider value={{ clientParam, setClientParam }}>
      {children}
    </ClientparamsContext.Provider>
  );
};

export const useClientContext = () => useContext(ClientparamsContext);
