import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import Modal from "@/common/elements/Modal";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormContainer } from "./styles";
import { labels } from "@/util/label";



const validateData = (formData) => {
  let isValid = true;
  let errors = [];

  if (!formData.documentName) {
    isValid = false;
    errors.push("documentName");
  }

  if (!formData.documentFile) {
    isValid = false;
    errors.push("documentFile");
  }

  return { isValid, errors };
};

export const MODE = { NEW: "new", EDIT: "edit" };

const DocsModal = ({ show, onHide, onChange, mode = MODE.NEW, document = {}, documentIndex = -1 }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);

  const onUpdateForm = ({ target: { name, value, files, filename } }, options = {}) => {
    const { isFileUpload = false } = options;
    if (isFileUpload) {
      console.log("files[0]", files[0].filename, value, filename);
      setFormData((prev) => ({ ...prev, [name]: files[0], [`${name}FileName`]: value }));
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => _.pull(prev, name));
  };

  useEffect(() => {
    if (mode === MODE.EDIT) setFormData(document);
  }, [mode]);

  const onModalHide = () => {
    setFormData({});
    setErrors([]);
    onHide();
  };

  const onDocumentSaveClick = () => {
    const { isValid, errors } = validateData(formData);
    if (isValid) {
      let options = {};
      if (mode === MODE.EDIT) {
        options.isEdit = true;
        options.editIndex = documentIndex;
      }
      onChange(formData, options);
      onModalHide();
    } else setErrors(errors);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onModalHide}
        header="Add Document"
        size="xl"
        footerActions={[
          { key: "save", text: mode === MODE.EDIT ? "Save Changes" : "Add Document", onClick: onDocumentSaveClick },
        ]}
        footerCloseAction
        footerCloseActionText="Close"
      >
        <FormContainer>
          <Row>
            <Col lg>
              <TextField
                label={labels.documentName}
                name="documentName"
                id="documentName"
                value={formData.documentName}
                onChange={onUpdateForm}
                placeholder="Document Name"
                variant={INPUT_VARIANT.INTAKE_FORM}
                required
                isInvalid={errors.includes("documentName")}
              />
            </Col>
            <Col lg>
              {/* <span style={{ color: "red" }}>{JSON.stringify({fileName: formData.documentFileFileName})}</span> */}
              <TextField
                type="file"
                label={labels.upload}
                name="documentFile"
                id="documentFile"
                // value={formData.documentFileFileName}
                onChange={(event) => onUpdateForm(event, { isFileUpload: true })}
                placeholder="Upload"
                variant={INPUT_VARIANT.INTAKE_FORM}
                required
                multiple={false}
                isInvalid={errors.includes("documentFile")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <TextField
                as="textarea"
                label={labels.comments}
                name="comments"
                id="comments"
                value={formData.comments}
                onChange={onUpdateForm}
                placeholder="Comments"
                variant={INPUT_VARIANT.INTAKE_FORM}
                rows={5}
              />
            </Col>
          </Row>
        </FormContainer>
      </Modal>
    </>
  );
};

export default DocsModal;
