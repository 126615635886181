import dayjs from 'dayjs';
import { StyledDatePicker } from './styles';


const CommonDatePicker = ({
  value,
  onChange,
  placeholder = 'Select date',
  format = 'YYYY-MM-DD',
  disabled = false,
  allowClear = true,
  disableFutureDates = false,
}) => {

    const disabledDate = (current) => {
        if (!disableFutureDates) return false; 
        return current && current > dayjs().endOf('day'); 
      };

  return (
    <StyledDatePicker
      width="100%"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      disabled={disabled}
      allowClear={allowClear}
      disabledDate={disabledDate}
      showTime
    />
  );
};

export default CommonDatePicker;
