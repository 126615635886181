import { createContext, useMemo, useState } from "react";

export const DocumentControlContext = createContext({});

const DocumentControlContextProvider = ({ children }) => {
  const [undoAction, setUndoAction] = useState(() => () => {
    alert("undoAction");
  });
  const [redoAction, setRedoAction] = useState(() => () => {
    alert("redoAction");
  });
  const [saveAction, setSaveAction] = useState(() => () => {
    alert("saveAction");
  });
  const [cancelAction, setCancelAction] = useState(() => () => {
    alert("cancelAction");
  });

  const actions = useMemo(() => ({ undoAction, redoAction, saveAction, cancelAction }), []);

  const setActions = useMemo(
    () => ({
      undoAction: setUndoAction,
      redoAction: setRedoAction,
      saveAction: setSaveAction,
      cancelAction: setCancelAction,
    }),
    []
  );

  return <DocumentControlContext.Provider value={[actions, setActions]}>{children}</DocumentControlContext.Provider>;
};

export default DocumentControlContextProvider;
