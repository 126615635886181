import add from "@/assets/add.svg";
import edit from "@/assets/edit.svg";
import trashSolid from "@/assets/trash-solid.svg";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";
import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import Table from "@/common/elements/Table";
import { Flex } from "@/common/styles";
import { useState } from "react";
import { Col, Row, Form} from "antd";
import PropertyModal, { MODE } from "./PropertyModal";
import {
  FormContainer,
  InmateDetailsTableContainer,
  TableHeader,
} from "./styles";
import { forwardRef } from "react";
import { Formik, ErrorMessage } from "formik";
import { INMATE_DETAILS_MODE } from ".";
import * as Yup from "yup";
import Select from "@/common/elements/InputElements/Select";
import CommonDatePicker from '@/common/elements/DatePicker';
import { StyledDate } from '../InmateDetails/styles';
import dayjs from 'dayjs';
import { labels } from "@/util/label";
import FormItem from "antd/es/form/FormItem";


const propertyValidationSchema = Yup.object().shape({
  searchedBy: Yup.string().optional(),
  searchTechnique: Yup.string().optional(),
  takenBy: Yup.string().optional(),
  releasedTo: Yup.string().optional(),
  propertyDate: Yup.date().optional(),
  cash: Yup.string().optional(),
  propertyComments: Yup.string().optional(),
});

const PropertyTableHeaders = [
  { key: "itemNumber", text: "Item #" },
  { key: "propType", text: "Prop Type" },
  { key: "location", text: "Location" },
  { key: "serialNumber", text: "Serial #" },
  { key: "itemStatus", text: "Item Status" },
  { key: "itemColor", text: "Item Color" },
  { key: "quantity", text: "Quantity" },
  { key: "isWeapon", text: "Is a Weapon" },
  { key: "description", text: "Description" },
  { key: "actions", text: "Actions" },
];

const Property = forwardRef(
  (
    {
      formData = {
        searchedBy: "",
        searchTechnique: "",
        takenBy: "",
        releasedTo: "",
        propertyDate: "",
        cash: "",
        propertyComments: "",
      },

      mode = INMATE_DETAILS_MODE.EDIT,
    },
    ref
  ) => {
    const [isModalShown, setIsModalShown] = useState(false);
    const [propertyModalMode, setPropertyModalMode] = useState(MODE.NEW);
    const [propertyEditData, setPropertyEditData] = useState({});
    const [editIndex, setEditIndex] = useState(-1);

    const onModalHide = () => {
      setIsModalShown(false);
      setPropertyModalMode(MODE.NEW);
      setPropertyEditData({});
      setEditIndex(-1);
    };

    const onAddClick = () => {
      setIsModalShown(true);
    };

    const onPropertyEditClick = (property, index) => {
      setPropertyModalMode(MODE.EDIT);
      setPropertyEditData(property);
      setEditIndex(index);
      setIsModalShown(true);
    };

    const onPropertyDeleteClick = () => {
      window.confirm(
        "Are you sure you want to delete a property from the inmate record"
      );
    };

    const searchedOptions = [
      { value: "", label: "Select Search", disabled: true },
      { value: "ALDERINK JUSTIN", label: "ALDERINK JUSTIN" },
      { value: "ALLEN GREG", label: "ALLEN GREG" },
      { value: "ALLEN TAMI", label: "ALLEN TAMI" },
    ];

    const techniqueOpt = [
      { value: "", label: "Select Technique", disabled: true },
      { value: "Cavity", label: "Cavity" },
      { value: "Pat", label: "Pat" },
      { value: "Strip", label: "Strip" },
    ];

    const takenOptions = [
      { value: "", label: "Select TakenBy", disabled: true },
      { value: "ALDERINK JUSTIN", label: "ALDERINK JUSTIN" },
      { value: "ALLEN GREG", label: "ALLEN GREG" },
      { value: "ALLEN TAMI", label: "ALLEN TAMI" },
    ];

    return (
      <>
        <FormContainer>
          <Formik initialValues={formData}
            validationSchema={propertyValidationSchema}
            innerRef={ref}
          >
            {({ getFieldProps, values }) => (
              <Form>
                <Row>

                  <Col span={6}>
                    <FormItem label={labels.searchedBy}>
                    <Select                      
                      {...getFieldProps("searchedBy")}
                      placeholder='Searched By'
                      variant={INPUT_VARIANT.INTAKE_FORM}
                      options={searchedOptions.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                   <FormItem label={labels.searchTechnique}>
                    <Select                       
                      {...getFieldProps("searchTechnique")}
                      placeholder='Search Technique'
                      variant={INPUT_VARIANT.INTAKE_FORM}
                      options={techniqueOpt.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    />
                    </FormItem>                   
                  </Col>

                  <Col span={6}>
                   <FormItem label={labels.takenBy}>
                    <Select
                      {...getFieldProps("takenBy")}
                      placeholder='Taken By'
                      defaultValue=''
                      variant={INPUT_VARIANT.INTAKE_FORM}
                      options={takenOptions.map((item) => ({
                        value : item.value,
                        label : item.label
                      }))}
                    />
                  
                    </FormItem>
                  </Col>
                  
                  <Col span={6}>
                   <FormItem label={labels.releasedTo}>
                    <TextField
                      {...getFieldProps("releasedTo")}
                      placeholder='Released To'
                      variant={INPUT_VARIANT.INTAKE_FORM}
                    />
                    </FormItem>
                  </Col>
                  
              
                  <Col span={6}>
                  <FormItem label={labels.propertyDate}>
                  <StyledDate  >
                    <CommonDatePicker
                     style={{ border: "2px solid rgba(82, 140, 181, 0.27)",
                      top: "5px"
                    }}
                    value={values.propertyDate ? dayjs(values.propertyDate) : null} 
                    onChange={(date) => setFieldValue("propertyDate", date)} 
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY HH:mm"                       
                    />
                    </StyledDate>
                    </FormItem>
                  </Col>

                  <Col span={6}>
                  <FormItem label={labels.cash}>
                    <TextField                     
                      {...getFieldProps("cash")}
                      placeholder='Cash'
                      variant={INPUT_VARIANT.INTAKE_FORM}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                        setFieldValue("quantity", sanitizedValue);
                      }}
                      onKeyDown={(e) => {
                        if (e.key.match(/[^0-9]/) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem>
                    <TextField
                      as='textarea'
                      label={labels.comments}
                      {...getFieldProps("propertyComments")}
                      placeholder='Comments'
                      variant={INPUT_VARIANT.INTAKE_FORM}
                      rows={5}
                    />
                    </FormItem>
                  </Col>
                </Row>
                
                <InmateDetailsTableContainer>
                  <TableHeader>
                    <h6>List of items taken</h6>
                    <Button
                      variant='dark'
                      className='add-btn'
                      onClick={onAddClick}
                    >
                      <img alt='Add Icon' src={add} /> &nbsp; Add
                    </Button>
                  </TableHeader>
                  <Table
                    headers={PropertyTableHeaders}
                    data={
                      formData?.properties?.map((property, index) => ({
                        ...property,
                        isWeapon: property.isWeapon ? "Yes" : "No",
                        actions: (
                          <Flex gap='10px'>
                            <Button
                              variant={BUTTON_VARIANT.TEXT_DARK}
                              style={{ padding: "unset" }}
                              onClick={() =>
                                onPropertyEditClick(property, index)
                              }
                            >
                              <img alt='Edit Icon' src={edit} />
                            </Button>
                            <Button
                              variant={BUTTON_VARIANT.TEXT_DARK}
                              style={{ padding: "unset" }}
                              onClick={onPropertyDeleteClick}
                            >
                              <img alt='Edit Icon' src={trashSolid} />
                            </Button>
                          </Flex>
                        ),
                      })) || []
                    }
                    variant={INPUT_VARIANT.INTAKE_FORM}
                    style={{ padding: "unset" }}
                  />
                </InmateDetailsTableContainer>
              </Form>
            )}
          </Formik>
        </FormContainer>

        <PropertyModal
          show={isModalShown}
          mode={propertyModalMode}
          property={propertyEditData}
          propertyIndex={editIndex}
          onHide={onModalHide}
          onChange={(value, options) =>
            onUpdateForm(
              { target: { name: "properties", value } },
              { ...options, isMulti: true }
            )
          }
        />
      </>
    );
  }
);

export default Property;
