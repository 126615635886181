/* eslint-disable react/prop-types */
import { INPUT_VARIANT } from "../const";
import { ANT_FormItem } from "../styles";

const FormItem = ({
    id,
    label,
    variant = INPUT_VARIANT._,
    minWidth,
    margin = "default",
    children,
    layout = "vertical",
    colon=false,
    ...rest
}) => {
    return (
        <ANT_FormItem
            layout={layout}
            // controlId={id}
            className={variant}
            minWidth={minWidth}
            margin={margin}
            label={label}
            colon={colon}
            {...rest}
        >
            {children}
        </ANT_FormItem>
    );
};
export default FormItem;

