import { CONSTANT } from "@/util/ant_var";
import { Button } from "antd";
import styled, { css } from "styled-components";

export const btnPrimary = css`
  font-size: 14px;
  padding: 5px 20px;
  color: white;
  height: 32px;
  border-radius: ${CONSTANT.BORDER_RADIUS};
  transition: all 0.3s ease-in-out;
`;

export const btnDark = css`
  background-color: #0b3d5b;
  border: 4px solid var(--background-color-secondary);
  color: white;

  &:hover:enabled {
    background-color: #07678c;
    border: 4px solid #0c79a4;
  }
`;

export const StyledButton = styled(Button)`  
  text-transform:uppercase;
  font-weight:500;
  font-size:14px;
  &.ant-btn-dark{
    color: ${CONSTANT.BLACK};
    font-weight: 500;
  }
  
  &.ant-btn-primary.ant-btn-color-primary.ant-btn-variant-solid{
  border-color:${CONSTANT.BUTTON_PRIMARY_BORDER};
  }
  &.ant-btn-default.ant-btn-color-default.ant-btn-variant-solid{
    border-color:${CONSTANT.BUTTON_DEFAULT_BORDER};
    background:${CONSTANT.BUTTON_DEFAULT_BG};
    &:hover:not(:disabled){
    background:${CONSTANT.BUTTON_DEFAULT_BORDER} !important;
    border-color:${CONSTANT.BUTTON_DEFAULT_BG} !important;
    }
  }
  &.ant-btn-secondary.ant-btn-color-secondary.ant-btn-variant-solid{
      color:${CONSTANT.WHITE};
      background: ${CONSTANT.BUTTON_SECONDARY_BG};
      border-color:${CONSTANT.BUTTON_SECONDARY_BORDER};
      &:hover{
      background: ${CONSTANT.BUTTON_SECONDARY_BG_HOVER};
      border-color:${CONSTANT.BUTTON_SECONDARY_BORDER_HOVER};
      }
  }
  &.ant-btn-default.ant-btn-color-default.ant-btn-variant-filled{
    background:${CONSTANT.BUTTON_DEFAULT_BORDER} !important;
    &:hover:not(:disabled){
    background:${CONSTANT.BUTTON_DEFAULT_BORDER} !important;
    }
  }
  &.icon-size-xx-lg>.ant-btn-icon{
    font-size: xx-large !important;
  }
  &.ant-btn-variant-solid:disabled{
  opacity:0.5;
  }
  &.warning{
    background-color: ${CONSTANT.WARNING};
    color: ${CONSTANT.WHITE};
    border-color: ${CONSTANT.WARNING_BORDER} !important;

    &:hover:not(:disabled){
      background-color: ${CONSTANT.WARNING_HOVER} !important;
      border-color: ${CONSTANT.WARNING_HOVER} !important;
    }

    &:disabled{
      background-color: ${CONSTANT.WARNING_DISABLED} !important;
      color: ${CONSTANT.WHITE}B !important;
      border-color: ${CONSTANT.WARNING_BORDER_DISABLED}
    }
  }
  &.btn-back{
  position:relative;
  &::before{
  content: "";
  position: absolute;
  border-right: 16px solid ${CONSTANT.BUTTON_DEFAULT_BORDER};
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  left: -16px;
  border-radius: 0;
  }
  }
`;

export const OldStyledButton = styled(Button)`
   ${btnPrimary};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* font-size: initial; */

 /* &.ant-btn-dark {
    ${btnDark};
  }

  &.ant-btn-text {
    background-color: unset;
    border: unset;
    color: var(--text-blue);

    &.disabled {
      opacity: 0.1;
    }

    &:hover {
      color: var(--text-blue-hover);
    }
  }

  &.ant-btn-text-dark {
    background-color: unset;
    border: unset;
    color: var(--text-blue-dark);

    &.disabled {
      opacity: 0.3;
    }

    &:hover {
      color: var(--text-blue-dark-hover);
    }
  }

  &.ant-btn-text-accent {
    background-color: unset;
    border: unset;
    color: var(--text-accent);

    &.disabled {
      opacity: 0.3;
    }

    &:hover {
      color: var(--text-accent-hover);
    }
  }

  &.ant-btn-accent {
    background: var(--accent);
    border: 4px solid #ff7d1c;
    box-shadow: 2px 2px 7px 0 rgba(10, 36, 65, 0.4), inset 0 1px 0 0 #ff9c59, inset 0 -1px 0 0 rgba(197, 47, 30, 0.36);
    border-radius: 5px;

    &:hover {
      background-color: #ff7d1c;
      border-color: #ff9925;
      box-shadow: 2px 2px 7px 0 rgba(10, 36, 65, 0.4), inset 0 1px 0 0 #ff9c59, inset 0 -1px 0 0 rgba(197, 47, 30, 0.36);
      border-radius: 5px;
    }

    &:active {
      background-color: #ca2c14;
      border-color: #ff5618;
      box-shadow: 2px 2px 7px 0 rgba(10, 36, 65, 0.4), inset 0 1px 0 0 #ff9c59, inset 0 -1px 0 0 rgba(197, 47, 30, 0.36);
      border-radius: 5px;
    }
  }

  &.ant-btn-secondary {
    background: #6c757d;
    border: 4px solid #7e8c93;
    /* box-shadow: 2px 2px 7px 0 rgba(10, 36, 65, 0.4), inset 0 1px 0 0 #6c757d, inset 0 -1px 0 0 rgba(46, 54, 61, 0.36); */
    border-radius: 5px;

    &:hover {
      background: #5e6670;
      border: 4px solid #6e7a81;
    }
  } */

  &.login-form {
    background-color: #07678C !important;
    color: white !important;
    border: 4px solid #0c79a4 !important;

    &.disabled {
      opacity: 0.3;
    }

    &:hover{
    }
  }
`;


