import bI2Official from "@/assets/BI-Official-logo.svg";
import { PrivateRoutes } from "@/routes";
import { Route, Routes, useLocation } from "react-router-dom";
import QuickMenu from "./QuickMenu";
import { ContentContainer, SidebarContainer } from "./styles";
import { useEffect, useState } from "react";
import { StyledFlashAlert, StyledFlashAlertConent } from "../ConfigFeatures/styles";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";

const Sidebar = ({ headerHeight }) => {
  const location = useLocation();
  const [quickMenuVisible,setQuickMenuVisible] = useState(true);
  const [configFlashAlert,setConfigFlashAlert] = useState(true);
  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    if (pathSegments[0] === "config") {
        setQuickMenuVisible(false);
        console.log(pathSegments[0])
    } else {
        setQuickMenuVisible(true);
    }
  }, [location.pathname]); 
  
  const renderRoutes = (routes) =>
    routes.map(({ path, element, sidebarComponent = () => {}, children = [] }) => (
      <Route key={path} path={path} element={element}>
        {children.length > 0 && renderRoutes(children)}
      </Route>
    ));
  return (
    <SidebarContainer headerHeight={headerHeight}>
      {!quickMenuVisible && configFlashAlert && ( 
      <StyledFlashAlert className="flash-alert">
             <div className="arrow-top"></div>
             <StyledFlashAlertConent>
             exit config mode <Button variant="link" type="link" 
             onClick={()=>setConfigFlashAlert(false)}>Okay</Button>
             </StyledFlashAlertConent>
      </StyledFlashAlert>)
      }

      {quickMenuVisible && <QuickMenu />}
      <ContentContainer className={quickMenuVisible ? 'container-card':'container-alt'} 
      data-lenis-prevent>
        <Routes>
        {PrivateRoutes.map(({ path, sidebarComponent = () => {}, children = [] }) => (
        <Route key={path} path={path} element={sidebarComponent ? sidebarComponent:null}>
          {children.length > 0 && renderRoutes(children)}
        </Route>
      ))}
        </Routes>
      </ContentContainer>

      <img alt="BI2 Logo" className="footer-logo company-logo" src={bI2Official} />
    </SidebarContainer>
  );
};

export default Sidebar;
