import styled from "styled-components";

export const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 5px;
  margin: 5px;
`;
