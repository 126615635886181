/* eslint-disable no-unused-vars */
// src/services/api.js

import axios from 'axios';
import { API_URL, API_TIMEOUT } from '@/util/api';


// 1. Create an instance with common configurations
const api = axios.create({
  baseURL: API_URL, // Set your API base URL here
  //   timeout: API_TIMEOUT, // Optional: set a request timeout
});

// Flag to prevent multiple refresh calls at the same time
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

// 2. Request interceptor for adding auth token and other headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Example of getting auth token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    console.log(error);

    if (error?.response?.config?.url?.includes('login') || error?.response?.config?.url?.includes('verify')) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 403 && error && !originalRequest._retry) {
      if (isRefreshing) {
        // Queue the failed request until the token is refreshed
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        // Call your refresh token API

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/refresh_token`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('refreshToken')}`
          }
        };

        axios.request(config)
          .then(({ data }) => {
            localStorage.setItem('authToken', data?.access_token);
            localStorage.setItem('refreshToken', data?.refresh_token);
            api.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);
            resolve(api(originalRequest));
          })
          .catch((err) => {
            // processQueue(err, null);
            // reject(err);
            logout()
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);



// Helper function for logout
const logout = () => {
  // Removing localStorage data 
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  window.location.href = '/login';
};
export default api;
