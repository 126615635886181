export const labels = {
    // personal info
    jacketID: "Jacket ID",
    lastName: "Last Name",
    firstName: "First Name",
    middleName: "Middle Name",
    suffix: "Suffix",
    race: "Race",
    gender: "Gender",
    ssn: "SSN",
    dlNumber: "DL Number",
    dlState: "DL State",
    state: "State",
    dob: "DOB",
    chn: "CHN",
    sid: "SID",
    street1: "Street 1",
    height: 'Height',
    weight: "Weight",
    hair: "Hair",
    eyes: "Eyes",
    glasses: "Glasses",
    length: "H Length",
    face: "Face H.",
    complex: "Complex",
    driversLicense: "Drivers License",
    expires: "Expires",
    ssn: "SSN",
    addresses: "Address(es)",
    aliases: "Aliases",
    oldCRH: "Old CRH",
    irisID: "Iris ID",
    ori: "Arresting Ori",
    arrestingOfficer: "Arresting Officer",
    arrestDateTime: "ARREST DATE & TIME",
    arrestLocation: "Arrest Location",
    score: 'Score',
    arrestCity: "Arrest City",
    bookingOfficer: "Booking Officer",
    incarcerationDateTime: "INCARCERATION DATE & TIME",
    inmateType: "Inmate Type",
    controllingAgencyCase: "Controlling Agency Case",
    controllingAgency: "Controlling Agency",
    moveTo: "Move To",
    status: "Status",
    jailType: "Jail Type",
    loanType: "Loan Type",
    destination: "Destination",
    transportDocument: "Transport Document",
    block: "Block",
    cell: "Cell",
    bed: "Bed",
    holdingIslolationCell: "Holding / Isolation Cell",
    expectedDateTime: "EXPECTED DATE & TIME",
    schduleDateTime: "SCHDULE DATE & TIME",
    actualDateTime: "ACTUAL DATE TIME",
    approvalOfficer: "Approval Officer",
    transportingOfficer: "Transporting Officer",
    reasonForMove: "Reason For Move",
    comments: "Comments",
    searchedBy: "Searched By",
    searchTechnique: "Search Technique",
    takenBy: "Taken By",
    releasedTo: "Released To",
    propertyDate: "DATE",
    cash: "Cash",
    item: "Item",
    propType: "Prop Type",
    location: "Location",
    serialNumber: "Serial Number",
    itemStatus: "Item Status",
    itemColor: "Item Color",
    qunatity: "Quantity",
    weapon: "Is a Weapon",
    description: "Description",
    documentName: "Document Name",
    upload: "Upload",

    //Booking Queue
    booking: "Booking#",
    name: "Name",
    bkDate: "Bk Date",
    currentLocation: "Current Location",
    med: "Med",
    cls: "CLS",
    chg: "Chg",
    asn:"ASN",
    bc: "BC",
    ms: "MS",
    hsg: "HSG",
    bookingID: "Booking Id",
    filterType: "Filter Type",
    incarDate: "Incar Date",
    custodyStatus: "Custody Status",
    needsdqSetup: "Needs DQ Setup",
    includeAlias: "Include Alias",

    // Actions - Button, checkbox, radio
    search: "Search",
    searchCriteria: "Search Criteria",
    reset: "Reset",
    openSelected: "Open Selected",
    createNew: "Create New",
    cancel: "Cancel",
    previewSelected: 'Preview Selected'
}