import Button from "@/common/elements/Button";
import styled from "styled-components";

export const MainHeader = styled.header`
  position: sticky;
  top: 0;
  padding-bottom: 15px;
  z-index: 99999;
`

export const HeaderContainer = styled.div`
  position: relative;
  display:flex;
  padding-top: 25px;
  background-color: var(--background-color);
  button.action-btn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main,
  .tasks-right {
    display: flex;
    align-items: stretch;
  }

  .center {
    flex-basis: 100%;
    border-radius: var(--border-radius);
    box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset, 0px -1px 0px rgba(5, 92, 126, 0.49) inset,
      2px 2px 7px rgba(0, 0, 0, 0.2);
    background-color: rgba(4, 42, 67, 1);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .tasks-right {
    display: flex;
    align-items: stretch;
    gap: 10px;
  }

  .right {
    margin-left: 15px;
    display: flex;
    align-items: stretch;
  }
  .ant-dropdown-trigger .selection-text{
  font-size:22px;
  font-weight: 500;
  }
`;

export const SearchButton = styled(Button)`
  margin-right: 15px;
  background: var(--bg-container-main);
  border: 4px solid var(--border-main);
`;

export const GoButton = styled(Button)`
  width: 90px !important;
  font-size: 22px;
  color: var(--text-blue) !important;
`;

export const TaskButton = styled(Button)`
border: 4px solid var(--border-main);
`;

export const ReleaseQueueButton = styled(Button)`
  padding-top: unset;
  padding-bottom: unset;
  height: unset;
`;

export const NotificationButton = styled(Button)`
  position: relative;
  margin-right: 15px;
  border: 4px solid var(--border-main);
  .notification-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff3380;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
`;

export const TasksNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: 700;

  & span {
  }

  .green {
    color: #4bff8d;
    border-bottom: thin solid #0c79a4;
  }

  .red {
    color: #ff658b;
    border-top: thin solid #0c79a4;
  }
`;

export const SherifLogo = styled.img`
  width: 56px;
  height: 50px;
`;
