import { Modal as RBModal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(RBModal)`
  --bs-modal-zindex: 9999999;
  --modal-primary-color: #518bb4;

  .modal-content {
    border: 4px solid var(--modal-primary-color);
    background: #fefefe;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.32), 10px 10px 30px 0 rgba(0, 0, 0, 0.4);
    border-radius: 6px;
  }
`;

export const ModalHeader = styled(RBModal.Header)`
  background-color: var(--modal-primary-color);
  border-radius: unset;
  color: white;
  text-transform: uppercase;
  --bs-modal-header-padding: 0.5rem 1rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
`;

export const ModalTitle = styled(RBModal.Title)``;

export const ModalBody = styled(RBModal.Body)``;

export const ModalFooter = styled(RBModal.Footer)`
  gap: 10px;
`;
