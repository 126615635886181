import Button from "../Button";
import { ModalBody, ModalFooter, ModalHeader, ModalTitle, StyledModal } from "./styles";

const Modal = ({
  show = false,
  onHide,
  header,
  size = "lg",
  children,
  footerActions = [],
  footerCloseAction,
  footerCloseActionText,
  bodyStyle = {},
  ...rest
}) => {
  return (
    <>
      <StyledModal show={show} onHide={onHide} size={size} centered {...rest}>
        {header && (
          <ModalHeader closeButton closeVariant="white">
            <ModalTitle>{header}</ModalTitle>
          </ModalHeader>
        )}
        <ModalBody style={bodyStyle} data-lenis-prevent>{children}</ModalBody>
        <ModalFooter>
          {footerActions.map(({ text, key, onClick, ...rest }) => (
            <Button color="primary" variant="solid" type="primary" key={key} onClick={onClick} {...rest}>
              {text}
            </Button>
          ))}
          {footerCloseAction && (
            <Button onClick={onHide} type="secondary" color="secondary" >
              {footerCloseActionText}
            </Button>
          )}
        </ModalFooter>
      </StyledModal>
    </>
  );
};

export default Modal;
